<template>
  <router-link
    :to="{ name: 'edit-recipient', params: { id: client_id }}"
    target="_blank">
  <i aria-hidden="true" class="far fa-fw fa-file-export"></i>{{$t('recipient_profile')}}
  </router-link>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { i18nMessages } from '@/i18n';

@Component({
  ...i18nMessages([
    require('../_locales/common.json'),
    require('@/components/shared/_locales/single.json'),
  ]),
})
export default class RecipientLink extends Vue {
  // Properties
  @Prop({ required: true }) client_id!: string;
}
</script>

