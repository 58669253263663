<template>
  <fieldset>
    <template v-if="editState">
      <div class="row">
        <div class="standard-form-group">
          <text-input
            ruleKey="patient_contact_detail.email"
            inputId="contact-detail-email"
            :name="$t('contact_detail_field.email')"
            v-model="editState.email"
            :mask="getMaskFromRules('patient_contact_detail.email')"
            :placeholder="$t('contact_detail_placeholder.email')"
          />
        </div>
        <div class="standard-form-group">
          <checkbox-input
            inputId="contact-detail-preferred"
            :labelName="$t('contact_detail_field.preferred')"
            v-model="editState.preferred"
            :label="$t('yes')"
          />
        </div>
      </div>
      <div class="row">
        <div class="standard-full-width-group">
          <text-area-input
            ruleKey="patient_contact_detail.comments"
            inputId="contact-detail-comments"
            :name="$t('contact_detail_field.comments')"
            v-model="editState.comments">
          </text-area-input>
        </div>
      </div>
    </template>
  </fieldset>
</template>

<script lang="ts">
import { Getter } from "vuex-facing-decorator";
import { IdLookup } from '@/store/validations/types';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import { UIContactDetail } from "@/UIModels/contactDetail";
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { IdLookupProvider } from '@/types';
import { i18nMessages } from "@/i18n";

@Component({
  components: {
    SubSection,
    SelectInput,
    TextInput,
    CheckboxInput,
    TextAreaInput,
  },
  ...i18nMessages([
    require('@/components/recipients/contactInformation/_locales/PatientContactDetails.json'),
  ]),
})
export default class PatientEmailFormat extends Vue implements IdLookupProvider {
  @Getter('getMaskFromRules', { namespace: 'validations' }) private getMaskFromRules!: (ruleKey: string) => string|null;

  @Prop({ required: true }) editState!: UIContactDetail;

  // Validation mapping
  public idLookup(): IdLookup {
    return {
      'patient_contact_detail.email':     'contact-detail-email',
      'patient_contact_detail.preferred': 'contact-detail-preferred',
      'patient_contact_detail.comments':  'contact-detail-comments',
    };
  }
}
</script>
