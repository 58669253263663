export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pancreas_specific_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pancreas (Whole) Specific Details"])},
        "pancreas_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pancreas (Whole) Information"])},
        "save_pancreas_specific_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Pancreas (Whole) Information"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "vessels_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vessels Required?"])},
        "general_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Comments"])}
      },
      "fr": {
        "pancreas_specific_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails spécifiques du Pancréas entier"])},
        "pancreas_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information du Pancréas entier"])},
        "save_pancreas_specific_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les informations du pancréas entier"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "vessels_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vessels Required?"])},
        "general_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires généraux"])}
      }
    }
  })
}
