<template>
  <validation-provider
    ref="provider"
    :name="inputId"
    :vid="validationId"
  >
    <input
      :id="inputId"
      type="hidden"
      :value="modelValue"
    />
  </validation-provider>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component
export default class HiddenInput extends Vue {
  // Props
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'

  // V-model
  @Prop() modelValue!: string;

  // Standard properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
}
</script>
