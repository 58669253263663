import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "nav-block-wrap" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "nav-link" }
const _hoisted_4 = {
  key: 0,
  class: "nav flex-column"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", {
      class: _normalizeClass(["nav flex-column nav-block", { active: _ctx.isActive }])
    }, [
      _createElementVNode("li", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_router_link, {
            class: "no-scroll",
            style: {"color":"#2e2e2e !important"},
            to: { name: 'edit-organ', params: { organ_id: _ctx.journeyId } }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                class: "nav-caret",
                icon: ['fas', (_ctx.show ? 'chevron-down' : 'chevron-right')],
                "fixed-width": "",
                onClick: _withModifiers(_ctx.toggleNav, ["prevent"])
              }, null, 8, ["icon", "onClick"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.organName)), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              _createElementVNode("li", {
                class: "nav-item",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
              }, [
                _createElementVNode("a", {
                  class: "nav-link",
                  href: _ctx.getUrl('organ-details-section')
                }, _toDisplayString(_ctx.$t('journey_overview', {organ: _ctx.$t(_ctx.organName+"1").toString()})), 9, _hoisted_5)
              ]),
              (_ctx.isClinicalNotesEnabled)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 0,
                    class: "nav-item",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                  }, [
                    _createElementVNode("a", {
                      class: "nav-link",
                      href: _ctx.getUrl('notes-section')
                    }, _toDisplayString(_ctx.$t("patient_notes")), 9, _hoisted_6)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isReferralDetailsEnabled)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 1,
                    class: "nav-item",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                  }, [
                    _createElementVNode("a", {
                      class: "nav-link",
                      href: _ctx.getUrl('referral-section')
                    }, _toDisplayString(_ctx.$t('referral')), 9, _hoisted_7)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isChecklistsEnabled)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 2,
                    class: "nav-item",
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                  }, [
                    _createElementVNode("a", {
                      class: "nav-link",
                      href: _ctx.getUrl('checklist-section')
                    }, _toDisplayString(_ctx.$t("checklists")), 9, _hoisted_8)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.showOrganSpecificDetails)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 3,
                    class: "nav-item",
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                  }, [
                    _createElementVNode("a", {
                      class: "nav-link",
                      href: _ctx.getUrl(`${_ctx.organRoute}-specific-details`)
                    }, _toDisplayString(_ctx.$t('spec', { specific_details: _ctx.$t('specific_details'), organ: _ctx.$t(_ctx.organName+"1").toString() } )), 9, _hoisted_9)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isDonorAcceptabilityEnabled)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 4,
                    class: "nav-item",
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                  }, [
                    _createElementVNode("a", {
                      class: "nav-link",
                      href: _ctx.getUrl('donor-acceptability-criteria')
                    }, _toDisplayString(_ctx.$t("donor_acceptability_criteria")), 9, _hoisted_10)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isTransplantDetailsEnabled)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 5,
                    class: "nav-item",
                    onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                  }, [
                    _createElementVNode("a", {
                      class: "nav-link",
                      href: _ctx.getUrl('transplant-details')
                    }, _toDisplayString(_ctx.$t("transplant_details")), 9, _hoisted_11)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}