import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, createBlock as _createBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  href: "#allocation-offer-responses",
  class: "allocation-jump-link"
}
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}
const _hoisted_3 = { id: "allocation-listing" }
const _hoisted_4 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_5 = { class: "nav action-row mb-0" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-sm-12" }
const _hoisted_8 = {
  key: 0,
  class: "table-responsive-md allocationTable"
}
const _hoisted_9 = { class: "fixed-head" }
const _hoisted_10 = {
  key: 0,
  scope: "col"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["colspan"]
const _hoisted_14 = ["id"]
const _hoisted_15 = {
  key: 0,
  class: "td-check"
}
const _hoisted_16 = ["aria-checked", "id", "value", "checked", "onChange", "disabled"]
const _hoisted_17 = ["href", "title"]
const _hoisted_18 = ["onClick", "disabled"]
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = {
  key: 0,
  class: "text-center"
}
const _hoisted_22 = { class: "highlight-tip" }
const _hoisted_23 = {
  key: 0,
  slot: "foot"
}
const _hoisted_24 = { class: "no-hover action-table-row sticky-row" }
const _hoisted_25 = { colspan: "100%" }
const _hoisted_26 = { class: "nav action-row flex-align-end" }
const _hoisted_27 = { class: "ml-1" }
const _hoisted_28 = { class: "p-label w-100" }
const _hoisted_29 = { class: "offer-button-wrap" }
const _hoisted_30 = ["disabled"]
const _hoisted_31 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_32 = ["disabled", "title"]
const _hoisted_33 = {
  key: 0,
  class: "pl-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_offer_modal = _resolveComponent("offer-modal")!
  const _component_offer_history_modal = _resolveComponent("offer-history-modal")!
  const _component_discontinue_modal = _resolveComponent("discontinue-modal")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_compare_modal = _resolveComponent("compare-modal")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_offer_icon = _resolveComponent("offer-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_sorted_table = _resolveComponent("sorted-table")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: _ctx.$t('allocation_recommendation_listing'),
    "sub-section-id": "allocation-recommendation-listing",
    ref: "allocationRecommendationListing",
    "save-button": false,
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[11] || (_cache[11] = ($event: any) => (_ctx.loaded()))
  }, _createSlots({ _: 2 }, [
    (_ctx.allocation)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            (_ctx.allPrimaryBackupOffers.length > 0)
              ? (_openBlock(), _createElementBlock("a", _hoisted_1, [
                  _createTextVNode(_toDisplayString(`${_ctx.$t('offer_responses')}`) + " ", 1),
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'arrow-down'],
                    "fixed-width": ""
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.showControls)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_offer_modal, {
                    ref: "offerModal",
                    onCloseModal: _ctx.closeModal
                  }, null, 8, ["onCloseModal"]),
                  _createVNode(_component_offer_history_modal, { ref: "offerHistoryModal" }, null, 512),
                  _createVNode(_component_discontinue_modal, {
                    ref: "discontinueModal",
                    onCloseModal: _ctx.closeModal
                  }, null, 8, ["onCloseModal"]),
                  _createVNode(_component_modal_section, {
                    "modal-id": "excluded-recipients-view-modal",
                    wide: true,
                    ref: "excludedRecipientsListModal",
                    class: "modal-sticky-header"
                  }, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('excluded_recipients')), 1)
                    ]),
                    body: _withCtx(() => [
                      (_ctx.loadingExcludedRecipients)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('loading_excluded_recipients')), 1))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [], 64))
                    ]),
                    _: 1
                  }, 512)
                ], 64))
              : _createCommentVNode("", true),
            _createVNode(_component_compare_modal, { ref: "compareModal" }, null, 512),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.showControls)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("nav", _hoisted_5, [
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPrimaryOfferHistory && _ctx.openPrimaryOfferHistory(...args))),
                        "data-toggle": "modal",
                        "data-target": "#primaryOfferHistory",
                        class: _normalizeClass(`btn btn-sm btn-light ${(_ctx.primaryOffers.length) == 0 ? 'disabled' : ''}`)
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['fas', 'history'],
                          "fixed-width": ""
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('primary_offer_history')), 1)
                      ], 2),
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openBackupOfferHistory && _ctx.openBackupOfferHistory(...args))),
                        "data-toggle": "modal",
                        "data-target": "#backupOfferHistory",
                        class: _normalizeClass(`btn btn-sm btn-light ml-2 ${_ctx.backupOffers.length == 0 ? 'disabled' : ''}`)
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['fas', 'history'],
                          "fixed-width": ""
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('backup_offer_history')), 1)
                      ], 2),
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openNoOfferHistory && _ctx.openNoOfferHistory(...args))),
                        "data-toggle": "modal",
                        "data-target": "#noOfferHistory",
                        class: _normalizeClass(`btn btn-sm btn-light ml-2 ${_ctx.noOffers.length == 0 ? 'disabled' : ''}`)
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['fas', 'history'],
                          "fixed-width": ""
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('no_offer_history')), 1)
                      ], 2),
                      (_ctx.canViewExcludedRecipients)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showExcludedRecipients())),
                            class: "btn btn-sm btn-light ml-auto"
                          }, _toDisplayString(_ctx.$t('show_excluded_recipients')), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.editState)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_sorted_table, {
                          values: _ctx.editState.records || [],
                          ascIcon: "<span class=\"sorting-asc\" />",
                          descIcon: "<span class=\"sorting-desc\" />",
                          class: "table table-bordered table-hover table-allocation",
                          sort: "effective_rank"
                        }, {
                          body: _withCtx((sort) => [
                            _createElementVNode("tbody", null, [
                              _createElementVNode("tr", null, [
                                (_ctx.showControls)
                                  ? (_openBlock(), _createElementBlock("td", _hoisted_11, [
                                      _createVNode(_component_checkbox_input, {
                                        "input-id": "offer-response-select-all-matching-rows-recommended",
                                        label: _ctx.$t('select_all'),
                                        modelValue: _ctx.editState.selectAllMatchingRows,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.selectAllMatchingRows) = $event)),
                                        onChange: _ctx.selectAllMatchingRows,
                                        disabled: _ctx.isLoadingAllocation
                                      }, null, 8, ["label", "modelValue", "onChange", "disabled"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnsByOrgan, (rowName) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                                    (rowName === 'offer')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-offer`
                                        }, [
                                          _createVNode(_component_text_input, {
                                            class: "form-group",
                                            hideLabel: true,
                                            inputId: `${rowName}_filter`,
                                            name: "offer",
                                            type: "text",
                                            value: _ctx.searchParams[rowName] ? _ctx.searchParams[rowName].value : '',
                                            onInput: ($event: any) => (_ctx.updateFilters($event, rowName)),
                                            disabled: _ctx.isLoadingAllocation
                                          }, null, 8, ["inputId", "value", "onInput", "disabled"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (rowName === 'organ_spec_offered')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-organ_spec_offered`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'response')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-response`
                                        }, [
                                          _createVNode(_component_text_input, {
                                            class: "form-group",
                                            inputId: `${rowName}_filter`,
                                            hideLabel: true,
                                            name: _ctx.$t('response'),
                                            type: "text",
                                            value: _ctx.searchParams['response_value'] ? _ctx.searchParams['response_value'].value : '',
                                            onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateFilters($event, 'response_value'))),
                                            disabled: _ctx.isLoadingAllocation
                                          }, null, 8, ["inputId", "name", "value", "disabled"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (rowName === 'last_name')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-last_name`
                                        }, [
                                          _createVNode(_component_text_input, {
                                            class: "form-group",
                                            inputId: `${rowName}_filter`,
                                            hideLabel: true,
                                            name: _ctx.$t('last_name'),
                                            type: "text",
                                            value: _ctx.searchParams[rowName] ? _ctx.searchParams[rowName].value : '',
                                            onInput: ($event: any) => (_ctx.updateFilters($event, rowName)),
                                            disabled: _ctx.isLoadingAllocation
                                          }, null, 8, ["inputId", "name", "value", "onInput", "disabled"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (rowName === 'hospital_abbreviation')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-hospital_abbreviation`
                                        }, [
                                          _createVNode(_component_select_input, {
                                            "select-id": "offer-program-search-by",
                                            class: "form-group",
                                            name: _ctx.$t('program'),
                                            hideLabel: true,
                                            undefinedText: _ctx.$t('all'),
                                            modelValue: _ctx.editState.programFilterValue,
                                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.programFilterValue) = $event)),
                                            options: _ctx.editState.programOptions,
                                            onChange: ($event: any) => (_ctx.updateFilters($event, rowName, true)),
                                            disabled: _ctx.isLoadingAllocation
                                          }, null, 8, ["name", "undefinedText", "modelValue", "options", "onChange", "disabled"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (rowName === 'province_code')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-province_code`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'medical_status')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-medical_status`
                                        }, [
                                          _createVNode(_component_text_input, {
                                            class: "form-group",
                                            inputId: `${rowName}_filter`,
                                            hideLabel: true,
                                            name: _ctx.$t('medical_status'),
                                            type: "text",
                                            value: _ctx.searchParams[rowName] ? _ctx.searchParams[rowName].value : '',
                                            onInput: ($event: any) => (_ctx.updateFilters($event, rowName)),
                                            disabled: _ctx.isLoadingAllocation
                                          }, null, 8, ["inputId", "name", "value", "onInput", "disabled"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (rowName === 'effective_rank')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-effective_rank`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'client_id')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-client_id`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'sec_status')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-sec_status`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'hsp')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-hsp`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'allocation_points')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-allocation_points`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'mpe_score')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-mpe_score`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'abo')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-abo`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'organ')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-organ`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'listed_for')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-listed_for`
                                        }, [
                                          _createVNode(_component_select_input, {
                                            "select-id": "listedfor-search-by",
                                            class: "form-group",
                                            name: _ctx.$t('listed_for'),
                                            hideLabel: true,
                                            undefinedText: "All",
                                            modelValue: _ctx.editState.listedForFilterValue,
                                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.listedForFilterValue) = $event)),
                                            options: _ctx.getListedForOptions,
                                            onChange: ($event: any) => (_ctx.updateFilters($event, rowName)),
                                            disabled: _ctx.isLoadingAllocation
                                          }, null, 8, ["name", "modelValue", "options", "onChange", "disabled"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (rowName === 'registration_type')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-registration_type`
                                        }, [
                                          _createVNode(_component_select_input, {
                                            "select-id": "registration_type_for-search-by",
                                            class: "form-group",
                                            name: _ctx.$t('registration_type'),
                                            hideLabel: true,
                                            undefinedText: "All",
                                            modelValue: _ctx.editState.registrationTypeFilterValue,
                                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.registrationTypeFilterValue) = $event)),
                                            options: _ctx.getRegistrationTypeOptions,
                                            onChange: ($event: any) => (_ctx.updateFilters($event, rowName)),
                                            disabled: _ctx.isLoadingAllocation
                                          }, null, 8, ["name", "modelValue", "options", "onChange", "disabled"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (rowName === 'sex')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-sex`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'age')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-age`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'height')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-height`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'weight')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-weight`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'cpra')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-cpra`
                                        }, " "))
                                      : _createCommentVNode("", true),
                                    (rowName === 'rec_status')
                                      ? (_openBlock(), _createElementBlock("td", {
                                          key: `${rowName}-rec_status`
                                        }, " "))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                }), 256))
                              ]),
                              (sort.values.length === 0)
                                ? (_openBlock(), _createElementBlock("tr", _hoisted_12, [
                                    _createElementVNode("td", {
                                      class: "text-center",
                                      colspan: _ctx.numColumns
                                    }, [
                                      _createVNode(_component_font_awesome_icon, {
                                        icon: ['far', 'exclamation-circle'],
                                        "fixed-width": ""
                                      }),
                                      _createTextVNode(" " + _toDisplayString(_ctx.$t('allocation_recipients.no_recipients_found')), 1)
                                    ], 8, _hoisted_13)
                                  ]))
                                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(sort.values, (row, idx) => {
                                    return (_openBlock(), _createElementBlock("tr", {
                                      id: `recommendation_${row.rank}`,
                                      class: _normalizeClass(_ctx.getAllocationRowStyle(row)),
                                      key: idx
                                    }, [
                                      (_ctx.showControls)
                                        ? (_openBlock(), _createElementBlock("td", _hoisted_15, [
                                            _createElementVNode("input", {
                                              role: "checkbox",
                                              "aria-checked": row.selected,
                                              type: "checkbox",
                                              id: `recommendation_select-${idx}`,
                                              class: "table-check-input",
                                              value: row.selected,
                                              checked: !!row.selected,
                                              onChange: ($event: any) => (_ctx.checkRow($event, row)),
                                              disabled: _ctx.isLoadingAllocation || row.disableOfferActions
                                            }, null, 40, _hoisted_16)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnsByOrgan, (rowName) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                                          (rowName === 'offer')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-offer`,
                                                class: "text-center"
                                              }, [
                                                _createVNode(_component_offer_icon, {
                                                  offer: row.offer,
                                                  response: row.response
                                                }, null, 8, ["offer", "response"])
                                              ]))
                                            : _createCommentVNode("", true),
                                          (rowName === 'organ_spec_offered')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-organ_spec_offered`,
                                                class: "text-center"
                                              }, _toDisplayString(_ctx.$t(row.organ_spec_offered)), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'response')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-response`,
                                                class: _normalizeClass([_ctx.getAllocationCellStyle(row), "text-center font-weight-bold"])
                                              }, _toDisplayString(_ctx.offerResponseCodeValue(row)), 3))
                                            : _createCommentVNode("", true),
                                          (rowName === 'effective_rank')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-effective_rank`,
                                                class: "text-center font-weight-bold"
                                              }, _toDisplayString(row.display_rank), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'client_id' && _ctx.isLoadingAllocation)
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-client_id-plaintext`
                                              }, _toDisplayString(row.client_id), 1))
                                            : (rowName === 'client_id')
                                              ? (_openBlock(), _createElementBlock("td", {
                                                  key: `${rowName}-client_id`
                                                }, [
                                                  _createElementVNode("a", {
                                                    href: row.isOffered ? `#offer_${row.client_id}` : '#offer',
                                                    title: _ctx.$t('link_recipient_responses_table'),
                                                    class: "table-link"
                                                  }, _toDisplayString(row.client_id), 9, _hoisted_17)
                                                ]))
                                              : _createCommentVNode("", true),
                                          (rowName === 'last_name' && (_ctx.isLoadingAllocation || row.disableCompareModal))
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-disableCompareModal`
                                              }, _toDisplayString(row.last_name), 1))
                                            : (rowName === 'last_name')
                                              ? (_openBlock(), _createElementBlock("td", {
                                                  key: `${rowName}-last_name`,
                                                  class: "set-link font-weight-bold"
                                                }, [
                                                  _createElementVNode("a", {
                                                    href: "#",
                                                    "data-toggle": "modal",
                                                    "data-target": "#recipientDetailDemo",
                                                    onClick: ($event: any) => (_ctx.openCompareModal(row._id)),
                                                    disabled: _ctx.isLoadingAllocation
                                                  }, _toDisplayString(row.last_name), 9, _hoisted_18)
                                                ]))
                                              : _createCommentVNode("", true),
                                          (rowName === 'hospital_abbreviation')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-hospital_abbreviation`
                                              }, _toDisplayString(row.hospital_abbreviation), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'province_code')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-province_code`
                                              }, _toDisplayString(row.province_code), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'medical_status')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-medical_status`
                                              }, _toDisplayString(row.medical_status), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'sec_status')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-sec_status`
                                              }, _toDisplayString(row.sec_status), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'hsp')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-hsp`,
                                                class: "text-center font-weight-bold"
                                              }, [
                                                _createElementVNode("em", null, _toDisplayString(row.hsp), 1)
                                              ]))
                                            : _createCommentVNode("", true),
                                          (rowName === 'allocation_points')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-allocation_points`
                                              }, _toDisplayString(row.allocation_points), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'mpe_score')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-mpe_score`
                                              }, _toDisplayString(row.mpe_score), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'abo')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-abo`
                                              }, _toDisplayString(row.abo), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'organ')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-organ`
                                              }, _toDisplayString(row.organ), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'listed_for')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-listed_for`
                                              }, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.listed_for, (organ, idx) => {
                                                  return (_openBlock(), _createElementBlock("div", { key: idx }, [
                                                    (_ctx.highlightOrgan(row.organ_code, row.cluster_organ_code, row.listed_for_codes[idx], row.listed_for_codes))
                                                      ? (_openBlock(), _createElementBlock("strong", _hoisted_19, _toDisplayString(organ), 1))
                                                      : (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(organ), 1))
                                                  ]))
                                                }), 128))
                                              ]))
                                            : _createCommentVNode("", true),
                                          (rowName === 'registration_type')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-registration_type`
                                              }, _toDisplayString(row.registration_type), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'sex')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-sex`,
                                                class: "text-center"
                                              }, _toDisplayString(row.sex), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'age')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-age`
                                              }, _toDisplayString(row.age), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'height')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-height`
                                              }, _toDisplayString(!isNaN(row.height) ? row.height.toFixed(1) : '-'), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'weight')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-weight`
                                              }, _toDisplayString(!isNaN(row.weight) ? row.weight.toFixed(1) : '-'), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'cpra')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-cpra`,
                                                class: "text-center"
                                              }, _toDisplayString(row.cpra), 1))
                                            : _createCommentVNode("", true),
                                          (rowName === 'rec_status')
                                            ? (_openBlock(), _createElementBlock("td", {
                                                key: `${rowName}-rec_status`,
                                                class: "font-weight-bold"
                                              }, [
                                                (row.tip_donor_client_id)
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                                      _createElementVNode("mark", _hoisted_22, _toDisplayString(_ctx.$t('transplant_in_progress')), 1),
                                                      (_ctx.isLoadingAllocation)
                                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                            _createTextVNode(" #" + _toDisplayString(row.tip_deceased_donor_id), 1)
                                                          ], 64))
                                                        : (_openBlock(), _createBlock(_component_router_link, {
                                                            key: 1,
                                                            to: { name: 'edit-deceased-donor', params: { id: row.tip_donor_client_id} }
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createTextVNode(" #" + _toDisplayString(row.tip_deceased_donor_id), 1)
                                                            ]),
                                                            _: 2
                                                          }, 1032, ["to"]))
                                                    ]))
                                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                      _createTextVNode(_toDisplayString(row.rec_status), 1)
                                                    ], 64))
                                              ]))
                                            : _createCommentVNode("", true)
                                        ], 64))
                                      }), 256))
                                    ], 10, _hoisted_14))
                                  }), 128))
                            ])
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("thead", null, [
                              _createElementVNode("tr", _hoisted_9, [
                                (_ctx.showControls)
                                  ? (_openBlock(), _createElementBlock("th", _hoisted_10, [
                                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('select')), 1)
                                    ]))
                                  : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnsByOrgan, (rowName) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                                    (rowName === 'response')
                                      ? (_openBlock(), _createElementBlock("th", {
                                          key: `${rowName}-response`,
                                          scope: "col",
                                          class: "text-center",
                                          style: {"min-width":"110px"}
                                        }, _toDisplayString(_ctx.$t('response')), 1))
                                      : (_openBlock(), _createElementBlock("th", {
                                          key: `${rowName}-rowName`,
                                          scope: "col",
                                          class: "text-center"
                                        }, _toDisplayString(_ctx.$t(rowName)), 1))
                                  ], 64))
                                }), 256))
                              ])
                            ]),
                            (_ctx.showControls)
                              ? (_openBlock(), _createElementBlock("tfoot", _hoisted_23, [
                                  _createElementVNode("tr", _hoisted_24, [
                                    _createElementVNode("td", _hoisted_25, [
                                      _createElementVNode("nav", _hoisted_26, [
                                        _createElementVNode("div", _hoisted_27, [
                                          _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.$t('with_selected')), 1),
                                          _createElementVNode("div", _hoisted_29, [
                                            _createElementVNode("button", {
                                              type: "button",
                                              "data-toggle": "modal",
                                              disabled: !_ctx.isAllocationOfferable || _ctx.isLoadingAllocation || _ctx.isMakingOffer,
                                              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.makeOffer())),
                                              class: "btn btn-wide btn-sm btn-success w-auto"
                                            }, [
                                              _createTextVNode(_toDisplayString(_ctx.$t('make_offer')) + " ", 1),
                                              (_ctx.isMakingOffer)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_31, [
                                                    _createVNode(_component_font_awesome_icon, {
                                                      class: "fa-spin",
                                                      icon: ['far', 'spinner-third']
                                                    })
                                                  ]))
                                                : _createCommentVNode("", true)
                                            ], 8, _hoisted_30),
                                            _createElementVNode("button", {
                                              type: "button",
                                              "data-toggle": "modal",
                                              disabled: !_ctx.discontinueAble || _ctx.isLoadingAllocation || _ctx.isDiscontinuingOneOffer,
                                              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.discontinueOffer())),
                                              class: "btn btn-wide btn-sm btn-dark w-auto",
                                              title: _ctx.$t('discontinue_selected_offers')
                                            }, [
                                              _createTextVNode(_toDisplayString(_ctx.$t('discontinue_offer')) + " ", 1),
                                              (_ctx.isDiscontinuingOneOffer)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_33, [
                                                    _createVNode(_component_font_awesome_icon, {
                                                      class: "fa-spin",
                                                      icon: ['far', 'spinner-third']
                                                    })
                                                  ]))
                                                : _createCommentVNode("", true)
                                            ], 8, _hoisted_32)
                                          ])
                                        ])
                                      ])
                                    ])
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["values"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["title", "lookupsToLoad"]))
}