<template>
  <div>
    <template v-if="!journey" />
    <router-view v-else />
  </div>
</template>

<script lang="ts">
import { State } from 'vuex-facing-decorator';
import { Component, Vue } from 'vue-facing-decorator';
import { RecipientJourney } from '@/store/recipientJourney/types';

@Component
export default class BaseOrgan extends Vue {
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
}
</script>
