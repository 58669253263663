import { APIChecklistInterface, APIChecklist, APIChecklistGroup, APIChecklistTask, UIFilterAssignments } from '@/store/recipientJourney/types';
import { SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APISaveChecklistResponse, APISaveChecklistTaskResponse } from '@/types';
import { UIRecipient } from '@/UIModels/recipient';
import { ObjectId } from '@/store/types';
import { UIJourney } from './journey';
import { useCurrentPageStore } from '@/stores/currentPage';
import i18n from '@/i18n';

export class UIChecklistGroup {

  public apiSource: APIChecklistGroup|null = null;

  public id: string|null = null;

  public name: string|null = null;
  public sortRank: number|null = null;
  public updated_at: string|null = null;
  public updated_by: string|null = null;

  public tasks: UIChecklistTask[] = [];

  public active = false;
  public showNew = false;
  public showHiddenMessage: string|null; // text for show hidden message
  public showHiddenMessageNear: string|null; // id hidden message be shown near

  public newTask: UIChecklistTask;

  // Group Class constructer
  public constructor() {
    this.active = true;
    this.newTask = UIChecklistTask.buildNew();
    this.showHiddenMessage = null;
    this.showHiddenMessageNear = null;
  }

  // Define empty/default/new UI model structure
  public static buildNew() {
    const uiModel = new UIChecklistGroup();

    return uiModel;
  }

  // Class constructor using api group as base
  public static buildFromAPIChecklistGroup(apiChecklistGroup: APIChecklistGroup) {
    const uiModel = new UIChecklistGroup;

    uiModel.apiSource = apiChecklistGroup;
    uiModel.name = apiChecklistGroup.name || null;
    uiModel.sortRank = apiChecklistGroup.sort_rank || null;
    uiModel.updated_at = apiChecklistGroup.updated_at || null;
    uiModel.updated_by = apiChecklistGroup.updated_by || null;
    uiModel.id = apiChecklistGroup._id?.$oid || null;

    if (apiChecklistGroup.tasks) {
      uiModel.tasks = apiChecklistGroup.tasks.map((apiTask: APIChecklistTask) => {
        if (!apiTask) return UIChecklistTask.buildNew();

        const uiTask = UIChecklistTask.buildFromAPIChecklistTask(apiTask);
        return uiTask;
      });
    }

    return uiModel;
  }

  // Method to return number of tasks in a group
  public get getNumberOfTasks(): number {
    return this.tasks.length;
  }

  // Method to return number of completed tasks in a group
  public get getNumberOfCompletedTasks(): number {
    const completed = this.tasks.filter((task: UIChecklistTask) => { 
      return task.completed === true;
    });
    return completed.length;
  }

  // Build a copy of the checklist group for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModel(): UIChecklistGroup {
    const apiSource = this.apiSource as APIChecklistGroup;
    if (!apiSource) return UIChecklistGroup.buildNew();

    return UIChecklistGroup.buildFromAPIChecklistGroup(apiSource);
  }  

  // find the task that closely matches the original task's sort rank
  public findClosestMatch(originalTask: UIChecklistTask): UIChecklistTask|null {
    // find a task with a similar sortRank
    let match: UIChecklistTask|null = null;
    this.tasks.map((task: UIChecklistTask) => {
      // if the sort rank isn't higher than the original copy it
      if (task.sortRank && originalTask.sortRank && task.sortRank <= originalTask.sortRank) {
        match = task;
      }
    });
    return match ? match : null;
  }

  // clears the show hidden message from view
  // should be done when a new task is created
  public resetShowHiddenMessage(): void {
    this.showHiddenMessage = null;
    this.showHiddenMessageNear = null;
  }

  // find task by id
  public findTaskById(taskId: string|null): UIChecklistTask|null {
    if (!taskId) return null;
    const found = this.tasks.find((task: UIChecklistTask) => {
      return task.id === taskId;
    });
    return found ? found : null;
  }


}

export class UIChecklistTask {

  public apiSource: APIChecklistTask|null = null;

  public id: string|null = null;
  public checklist_id: string|null = null;
  public group_id: string|null = null;

  public assigned_user_id: string|null = null;
  public completed = false;
  public completed_by_user_id: string|null = null;
  public completed_date: string|null = null;
  public completed_timestamp: string|null = null;
  public external = false;
  public name: string|null = null;
  public sortRank: string|null = null;
  public status: string|null = null;
  public status_class: string|null = null;
  public updated_at: string|null = null;
  public updated_by: string|null = null;
  public permitted_actions: string[] = [];

  public active = false;
  public isNew = false;

  // Task Class constructor
  public constructor() {
    this.active = true;
  }

  // Define empty/default/new UI model structure
  public static buildNew() {
    const uiModel = new UIChecklistTask();
    uiModel.isNew = true;
    // make unique id for 'new' task
    // convert . in 1718909625675.1636 to - so validation is not broken 
    uiModel.id = ((Date.now() + Math.random()).toString()).replace('.', '-');

    return uiModel;
  }

  // Task construter using API checklist task as base
  public static buildFromAPIChecklistTask(apiChecklistTask: APIChecklistTask) {
    const uiModel = new UIChecklistTask();

    uiModel.apiSource = apiChecklistTask;
    uiModel.id = apiChecklistTask._id?.$oid || null;
    uiModel.assigned_user_id = apiChecklistTask.assigned_user_id || null;
    uiModel.checklist_id = apiChecklistTask.checklist_id?.$oid || null;
    uiModel.group_id = apiChecklistTask.group_id?.$oid || null;

    uiModel.completed_by_user_id = apiChecklistTask.completed_by_user_id || null;
    uiModel.completed_date = apiChecklistTask.completed_date || null;
    uiModel.completed_timestamp = apiChecklistTask.completed_timestamp || null;
    
    uiModel.completed = apiChecklistTask.completed_by_user_id ? true : false;

    uiModel.external = apiChecklistTask.external || false;

    uiModel.name = apiChecklistTask.name || null;
    uiModel.sortRank = apiChecklistTask.sort_rank || null;
    uiModel.status = apiChecklistTask.status || null;
    uiModel.status_class = apiChecklistTask.status_class || null;
    uiModel.updated_at = apiChecklistTask.updated_at || null;
    uiModel.updated_by = apiChecklistTask.updated_by || null;
    uiModel.permitted_actions = apiChecklistTask.permitted_actions || [];

    uiModel.active = false;
    return uiModel;
  }

  public replaceWithAPIChecklistTask(apiChecklistTask: APIChecklistTask) {
    this.apiSource = apiChecklistTask;
    this.id = apiChecklistTask._id?.$oid || null;
    this.assigned_user_id = apiChecklistTask.assigned_user_id || null;
    this.checklist_id = apiChecklistTask.checklist_id?.$oid || null;
    this.group_id = apiChecklistTask.group_id?.$oid || null;

    this.completed_by_user_id = apiChecklistTask.completed_by_user_id || null;
    this.completed_date = apiChecklistTask.completed_date || null;
    this.completed_timestamp = apiChecklistTask.completed_timestamp || null;
    
    this.completed = apiChecklistTask.completed_by_user_id ? true : false;

    this.external = apiChecklistTask.external || false;

    this.name = apiChecklistTask.name || null;
    this.sortRank = apiChecklistTask.sort_rank || null;
    this.status = apiChecklistTask.status || null;
    this.status_class = apiChecklistTask.status_class || null;
    this.updated_at = apiChecklistTask.updated_at || null;
    this.updated_by = apiChecklistTask.updated_by || null;
    this.permitted_actions = apiChecklistTask.permitted_actions || [];

    this.active = true;
  }

  // Returns UI class that relates to task status
  public get getStatusClass(): string {
    switch (this.status) {
      case 'complete': 
        return 'complete';
        break;
      case 'new':
        return 'new';
        break;
      default:
        return 'in-progress';
        break;
    }
  }

  // Returns task completed styling class name
  public get getCompletedStyle(): string {
    return this.completed ? 'd-flex task-completed' : 'd-flex';
  }

  // Returns task completed styling class name
  public get getSelectedStyle(): string {
    return this.active ? 'd-flex task-selected' : 'd-flex';
  }

  // Method to flag when a task is expanded or closed
  public toggle(): void {
    if (!this.active) { this.active = false; }
    this.active = !this.active;
  }

  // Generate request payload parameters to provide to API as part of Create or Update activity
  private extractPatch(): APIChecklistTask {
    const result: APIChecklistTask = {
      name: this.name,
      external: this.external || null,
      status: this.status || null,
    };

    if (this.checklist_id) { result.checklist_id = { $oid: this.checklist_id }; }
    if (this.group_id) { result.group_id = { $oid: this.group_id }; }

    // don't send id if new
    if (!this.isNew) {
      if (this.id) { result._id = { $oid: this.id }; }
    }

    return result;
  }

  // Make task save request
  public save(opts: { selected: UIChecklistTask, recipient: UIRecipient, journey: UIJourney }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      const journeyId = opts.journey.journeyId;

      if (!recipientId) reject((new UIError('checklist_error')));
      if (!journeyId) reject((new UIError('checklist_error')));

      const checklist_id = opts.selected.checklist_id;

      const payload = {
        task: this.extractPatch()
      };

      // check for payload _id
      const _id = payload.task && payload.task._id ? payload.task._id : null;

      // Use edit state ID instead of selection ID
      const editStateId = this.id;

      let method: any;
      let ep: string;
      if (_id && editStateId) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.journeys.checklists.tasks.update, [[':recipient_id', recipientId as string], [':journey_id', journeyId as string], [':checklist_id', checklist_id as string], [':id', editStateId]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.journeys.checklists.tasks.create, [[':recipient_id', recipientId as string], [':journey_id', journeyId as string], [':checklist_id', checklist_id as string]]);
      }
      method(ep, payload).then((response: any) => {
        if (response.data.errors) {
          reject((new UIError(`checklist_${editStateId}`, response)).errorResult);
        } else {
          // Success! We now update the task
          const apiTask = response.data && response.data.task ? response.data.task : null; 
          if (apiTask) {
            const newResponse: any = {
              data: apiTask
            };
            resolve((new UISuccess(newResponse)).getSaveResult());
          } else {
            reject((new UIError(`checklist_${editStateId}`, 'An error occured')).errorResult);
          }
        }
      }).catch((errorResponse: any) => {
        reject((new UIError(`checklist_${editStateId}`, errorResponse)).errorResult);
      });
    });
  }

  public replaceSource(apiTask: APIChecklistTask): any {
    this.apiSource = apiTask;
    this.name = apiTask.name as any;
  }

  // Build a copy of the checklist task for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModel(): UIChecklistTask {
    const apiSource = this.apiSource as APIChecklistTask;
    if (!apiSource) return UIChecklistTask.buildNew();

    return UIChecklistTask.buildFromAPIChecklistTask(apiSource);
  }

  // check if task would be hidden by checklist filters
  public isTaskHiddenByFilters(filters: UIFilterAssignments): boolean {
    let response = false;
    // if status does not match filter status, return true, task will be hidden
    if (this.status !== filters.checklist_status && filters.checklist_status !== null) { response = true; }
    // if task not completed and filter excluding completed, return true, task will be hidden
    if (this.completed && filters.exclude_completed) { response = true; }
    return response;
  }

  public get getTaskReferenceId(): string {
    return `checklist-task-${this.id}`;
  }
}

export class UIChecklist {
  private loaded: boolean;

  public apiSource: APIChecklist|null = null;

  public id: string|null = null;
  public name: string|null = null;
  public permitted_actions: string[] = [];
  public sortRank: number|null = 0;
  public updated_at: string|null = null;
  public updated_by: string|null = null;

  public groups: UIChecklistGroup[] = [];

  // Checklist class constructor
  public constructor() {
    this.loaded = false;
  }

  // Define empty/default/new UI model structure
  public static buildNew() {
    const uiModel = new UIChecklist();

    uiModel.loaded = true;
    return uiModel;
  }

  // Map from API data structure to UI model structure
  public static buildFromAPIChecklist(apiChecklist: APIChecklist) {
    const uiModel = new UIChecklist();

    uiModel.apiSource = apiChecklist;
    uiModel.id = apiChecklist._id?.$oid || null;

    uiModel.name = apiChecklist.name || null;
    uiModel.permitted_actions = apiChecklist.permitted_actions || [];
    uiModel.sortRank = apiChecklist.sort_rank || null;
    uiModel.updated_at = apiChecklist.updated_at || null;
    uiModel.updated_by = apiChecklist.updated_by || null;

    if (apiChecklist.groups) {
      uiModel.groups = apiChecklist.groups.map((apiGroup: APIChecklistGroup) => {
        if (!apiGroup) { return UIChecklistGroup.buildNew(); }

        return UIChecklistGroup.buildFromAPIChecklistGroup(apiGroup);
      });
    }

    uiModel.loaded = true;
    return uiModel;
  }

  // Build a copy of the checklist for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModel(): UIChecklist {
    const apiSource = this.apiSource as APIChecklist;
    if (!apiSource) return UIChecklist.buildNew();

    return UIChecklist.buildFromAPIChecklist(apiSource);
  }

  // Build a copy of the checklist for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModelAndRetainNewItems(existingChecklist: UIChecklist): UIChecklist {
    const apiSource = this.apiSource as APIChecklist;
    if (!apiSource) return UIChecklist.buildNew();

    const newChecklist = UIChecklist.buildFromAPIChecklist(apiSource);

    existingChecklist.groups.map((existingGroup: UIChecklistGroup) => {
      // fix 'new' task in existing groups
      if (existingGroup.showNew) {
        // if so find the location to attach
        newChecklist.groups.map((newGroup: UIChecklistGroup) => {
          // if find a match copy new task over
          if (newGroup.id === existingGroup.id) {
            newGroup.showNew = existingGroup.showNew; // preserve 'new task' show flag
            newGroup.newTask = existingGroup.newTask; // preserve 'new task' contents
          }
        });
      }
    });

    return newChecklist;    
  }

  // set group's showHiddenMessage by task
  public setShowHiddenMessage(task: UIChecklistTask): void {
    if (!task.group_id) return;
    // find group referenced in task and generate showHiddenMessage
    const group = this.groupAssociatedWithTask(task);
    if (!group) return;

    const name = task.name;
    const message = i18n.tc('validation.messages.checklist_hidden_task_message').toString();
    const showHiddenMessage = message.replace('__', name as string);
    group.showHiddenMessage = showHiddenMessage;
  }

  public groupAssociatedWithTask(task: UIChecklistTask): UIChecklistGroup|null {
    if (!task.group_id) return null;

    return (this.groups || []).find((group: UIChecklistGroup) => { return group.id === task.group_id; }) || null;
  }

  // find the task's that's closest to the one that's now hidden
  public setShowHiddenMessageNear(uiTask: UIChecklistTask): void {
    if (uiTask) {
      const group = this.findGroupById(uiTask.group_id);
      if (group) {
        const closestMatch = group.findClosestMatch(uiTask);
        group.showHiddenMessageNear = closestMatch ? closestMatch.id : null;
      }
    }
  }

  // find group by id
  public findGroupById(groupId: string|null): UIChecklistGroup|null {
    if (!groupId) return null;
    const found = this.groups.find((group: UIChecklistGroup) => {
      return group.id === groupId;
    });
    return found ? found : null;
  }

  // find task in checklist
  public findTaskInChecklist(uiTask: UIChecklistTask): UIChecklistTask|null {
    if (!uiTask) return null;
    const foundGroup = this.findGroupById(uiTask.group_id);
    if (!foundGroup) return null;

    const foundTask = foundGroup.findTaskById(uiTask.id);

    return foundTask ? foundTask : null;
  }
}
