<template>
  <card-section
    section-id="organ-packaging-forms"
    ref="packagingForms"
    :save-button="true"
    :disabled="!(canSave && isOneSelected)"
    :save-button-text="`${$t('create_forms')}`"
    @save="savePatch()"
    @loaded="loaded()"
  >
    <template v-slot:header>
      {{$t('organ_packaging_forms')}}
    </template>
    <template v-slot:body>
      <sub-section
        :title="$t('available_forms')"
        sub-section-id="available-forms"
      >
        <template v-slot:contents v-if="editState">
          <div class="table-responsive-md">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" width="15%">
                    {{$t('organ_packaging_organ_consents')}}
                  </th>
                  <th scope="col" class="text-center" width="10.625%">
                    {{$t('organ_packaging_ODSIF')}}
                  </th>
                  <th scope="col" class="text-center" width="10.625%">
                    {{$t('organ_packaging_cooler_sheet')}}
                  </th>
                  <th scope="col" class="text-center" width="10.625%">
                    {{$t('organ_packaging_interior_organ_labels')}}
                  </th>
                  <th scope="col" class="text-center" width="10.625%">
                    {{$t('organ_packaging_exterior_organ_labels')}}
                  </th>
                  <th scope="col" class="text-center" width="10.625%">
                    {{$t('organ_packaging_all')}}
                  </th>
                  <th scope="col" class="text-center" width="10.625%">
                    {{$t('organ_packaging_intended_research')}}
                  </th>
                  <th scope="col" class="text-center" width="10.625%">
                    {{$t('organ_packaging_transplant_program')}}
                  </th>
                  <th scope="col" class="text-center" width="10.625%">
                    {{$t('organ_packaging_recipient_id')}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, idx) in editState" :key="idx" class="tr">
                  <th scope="row">
                    {{row.organLabel}}
                  </th>
                  <td class="td-check">
                    <div class="checklist border-0">
                      <div class="form-check">
                        <label :for="`forms-osdif-${idx}`" class="form-check-label">
                          <span class="sr-only">{{$t('toggle_selection')}}</span>
                          <input
                            :id="`forms-osdif-${idx}`"
                            type="checkbox"
                            class="form-check-input"
                            v-model="row.osdif"
                            @click="checkRow($event, row)"
                            :disabled="!isGroupWriteable('organ_packaging_odsif')"
                          />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td class="td-check">
                    <div class="checklist border-0">
                      <div class="form-check">
                        <label :for="`forms-cooler-${idx}`" class="form-check-label">
                          <span class="sr-only">{{$t('toggle_selection')}}</span>
                           <input
                            :id="`forms-cooler-${idx}`"
                            type="checkbox"
                            class="form-check-input"
                            role="checkbox"
                            :aria-checked="row.coolerSheet"
                            tabindex="0"
                            v-model="row.coolerSheet"
                            :aria-labelledby="`forms-cooler-${idx}`"
                            @click="checkRow($event, row)"
                            :disabled="isVesselsForBanking(row) || !isGroupWriteable('organ_packaging')"
                          />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td class="td-check">
                    <div class="checklist border-0">
                      <div class="form-check">
                        <label :for="`forms-interior-${idx}`" class="form-check-label">
                          <span class="sr-only">{{$t('toggle_selection')}}</span>
                          <input
                            :id="`forms-interior-${idx}`"
                            type="checkbox"
                            class="form-check-input"
                            role="checkbox"
                            :aria-checked="row.interiorLabel"
                            tabindex="0"
                            v-model="row.interiorLabel"
                            :aria-labelledby="`forms-interior-${idx}`"
                            @click="checkRow($event, row)"
                            :disabled="!isGroupWriteable('organ_packaging')"
                          />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td class="td-check">
                    <div class="checklist border-0">
                      <div class="form-check">
                        <label :for="`forms-exterior-${idx}`" class="form-check-label">
                          <span class="sr-only">{{$t('toggle_selection')}}</span>
                          <input
                            :id="`forms-exterior-${idx}`"
                            type="checkbox"
                            class="form-check-input"
                            role="checkbox"
                            :aria-checked="row.exteriorLabel"
                            tabindex="0"
                            v-model="row.exteriorLabel"
                            :aria-labelledby="`forms-exterior-${idx}`"
                            @click="checkRow($event, row)"
                            :disabled="!isGroupWriteable('organ_packaging')"
                          />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td class="td-check">
                    <div class="checklist border-0">
                      <div class="form-check">
                        <label :for="`forms-all-${idx}`" class="form-check-label">
                          <span class="sr-only">{{$t('toggle_selection')}}</span>
                          <input
                            :id="`forms-all-${idx}`"
                            type="checkbox"
                            class="form-check-input"
                            role="checkbox"
                            :aria-checked="row.all"
                            tabindex="0"
                            v-model="row.all"
                            :aria-labelledby="`forms-all-${idx}`"
                            @click="checkRow($event, row)"
                            :disabled="!isGroupWriteable('organ_packaging')"
                          />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td class="td-check">
                    <div class="checklist border-0">
                      <div class="form-check">
                        <label :for="`for-research-${idx}`" class="form-check-label">
                          <span class="sr-only">{{$t('toggle_selection')}}</span>
                          <input
                            :id="`for-research-${idx}`"
                            type="checkbox"
                            class="form-check-input"
                            role="checkbox"
                            aria-checked="false"
                            tabindex="0"
                            :aria-labelledby="`for-research-${idx}`"
                            v-model="row.forResearch"
                            @click="checkRow($event, row)"
                            :disabled="!isConsentedForResearch && !isGroupWriteable('organ_packaging')"
                          />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td>
                    {{row.transplantProgram}}
                  </td>
                  <td>
                    {{row.recipientClientId}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<i18n src="./_locales/OrganPackagingForms.json"></i18n>
<i18n src="../../components/_locales/Organs.json"></i18n>

<script lang="ts">
import { EP } from '@/api-endpoints';
import { Getter, State } from 'vuex-facing-decorator';
import { SaveResult, SaveProvider } from '@/types';
import { OrganSpecification, OrganCodeValue } from '@/store/lookups/types';
import { prefixErrors, buildErrorResult } from '@/utils';
import SubSection from '@/components/shared/SubSection.vue';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Watch, Prop } from 'vue-facing-decorator';

interface PackagingFormsPageState {
  organDonationId: string;
  organCode: number;
  organName: string;
  organSpecificationCode: number;
  osdif: boolean;
  coolerSheet: boolean;
  interiorLabel: boolean;
  exteriorLabel: boolean;
  all: boolean;
  forResearch: boolean;
  transplantProgram: string;
  recipientClientId: number;
  organLabel: string;
}

@Component({
  components: {
    SubSection,
    CardSection,
  }
})
export default class OrganPackagingForms extends Vue {
  // State
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.packagingForms) private editState!: any;

  // Getters
  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string|undefined;
  @Getter('donorOrganPackagingForms', { namespace: 'deceasedDonors'}) private donorOrganPackagingForms!: any[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('getOrganSpecificationName', { namespace: 'lookups' }) getOrganSpecificationName!: (organCode?: number|null, organSpecificationCode?: number|null) => string;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  @Prop({ default: false }) canSave!: boolean;


  get isOneSelected(): boolean {
    if(this.editState && this.editState.length > 0) {
      return true;
    }

    return this.editState.map((item: any) =>{
      return Object.keys(item).map((value) => item[value])
                              .filter((value) => typeof value === 'boolean')
                              .reduce((t,i) => t || i) === true;
    }).reduce((t: boolean, i: boolean) => t || i);
  }

  // TODO: Tech debt - update the input fields with checkbox-input

  /**
   * Gets the value for consented_for_research
   *
   * @returns {boolean} true if this donor consented_for_research
   */
  get isConsentedForResearch(): boolean {
    return !!this.deceasedDonor.consented_for_research;
  }

  /**
   * Check if current organ donation row is a vessels for banking organ
   *
   * @returns {boolean} true if this organ donation is a vessels for banking
   */
  public isVesselsForBanking(row: PackagingFormsPageState): boolean {
    return row.organCode === OrganCodeValue.VesselsForBanking;
  }

  // Event handlers
  public mounted(): void {
    this.initializeForm();
  }

  // Loaded
  public loaded(): void {
    this.$emit('loaded', 'organPackagingForms');
  }

  /**
   * Gets changes from the edit state as a patch for the packaging form request
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    if (this.editState) {
      return this.extractPackagingFormPatch(this.editState);
    } else {
      return {};
    }
  }

  // PRIVATE

  // Initialize card and set pageState
  @Watch('deceasedDonor', { immediate: true, deep: true })
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'packagingForms',
      value: this.buildPackagingFormState(this.donorOrganPackagingForms),
    });
  }

  // Update rows on click
  private checkRow(event: any, row: PackagingFormsPageState): void {
    const rowId: string = event.target.id;
    if (/osdif/.test(rowId)) {
      row.osdif = !row.osdif;
    } else if (/cooler/.test(rowId)) {
      row.coolerSheet = !row.coolerSheet;
    } else if (/interior/.test(rowId)) {
      row.interiorLabel = !row.interiorLabel;
    } else if (/exterior/.test(rowId)) {
      row.exteriorLabel = !row.exteriorLabel;
    } else if (/all/.test(rowId)) {
      const keys: string[] = ['osdif', 'coolerSheet', 'interiorLabel', 'exteriorLabel'];
      if (this.isConsentedForResearch) keys.push('forResearch');
      keys.forEach((item) => {
        if(!this.isVesselsForBanking(row) || item !== 'coolerSheet') {
          // TODO: TECH_DEBT: remove 'any' in favour of strict typing
          (row as any)[item] = !row.all;
        }
      });
      row.all = !row.all;
    } else if (/research/.test(rowId)) {
      row.forResearch = !row.forResearch;
    }
  }

  // build pageState from getter
  private buildPackagingFormState(packagingForms: any): PackagingFormsPageState[] {
    let result: PackagingFormsPageState[] = [];
    packagingForms.forEach((item: PackagingFormsPageState) => {
      // TODO: Is organ spec still needed here, only Lung has this value now
      result.push({
        organDonationId: item.organDonationId,
        organCode: item.organCode,
        organName: `${this.$t(item.organName)}`,
        organSpecificationCode: item.organSpecificationCode,
        osdif: false,
        coolerSheet: false,
        interiorLabel: false,
        exteriorLabel: false,
        all: false,
        forResearch: false,
        transplantProgram: item.transplantProgram,
        recipientClientId: item.recipientClientId,
        organLabel : item.organSpecificationCode ? `${this.$t(this.getOrganSpecificationName(item.organCode, item.organSpecificationCode))}` : `${this.$t(item.organName)}`,
      });
    });
    return result;
  }

  /**
   * Returns a journey patch object containing changes from a Referral Details form
   *
   * @returns {any}
   */
  private extractPackagingFormPatch(packagingForms: PackagingFormsPageState[]): any {
    let result: any[] = [];
    packagingForms.forEach((item: PackagingFormsPageState) => {
      result.push({
        organ_donation_id: item.organDonationId,
        organ_code: item.organCode,
        organ_specification_code: item.organSpecificationCode,
        osdif: item.osdif,
        cooler_sheet: item.coolerSheet,
        interior_label: item.interiorLabel,
        exterior_label: item.exteriorLabel,
        all: item.all,
        for_research: item.forResearch,
      });
    });
    return result;
  }

  /**
   * Saves the form edit state.
   *
   * Prepares a payload for the Organ Packaging Forms request, dispatches the save action and register the save result.
   *
   * @emits save
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.packagingForms as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'packagingForms');
    // Generate payloads based on current edit state
    const payload = {
      donorId: this.clientId,
      payload: this.extractPatch(),
    };
    // Dispatch save actions in parallel and register the responses
    this.$store.dispatch('deceasedDonors/createPackagingForms', payload).then((response: SaveResult) => {
      // Get the filename
      const fileName = response.data.download_url;
      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
        saveProvider.registerSaveResult({success: true});
      } else {
        // There was no filename
        const saveResult = buildErrorResult(response.data.errors);
        saveProvider.registerSaveResult(saveResult);
      }
    }).catch((error: SaveResult) => {
      // this.$emit('handleErrors', error);
      const saveResult = buildErrorResult(error);
      saveProvider.registerSaveResult(saveResult);
    });
  }
}
</script>
