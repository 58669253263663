import { Component, Vue } from 'vue-facing-decorator';

@Component
export class RemotePaginationMixin extends Vue {
  public pageNumber = 1;
  public perPage: number|null = null;

  // on page number change
  public onPageNumber(pageNumber: number): void {
    this.pageNumber = pageNumber;
  }

  // on page size change
  public onPageSize(rows: number): void {
    this.perPage = rows;
    this.pageNumber = 1;
  }
}
