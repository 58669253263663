import Vue from 'vue';
import { MutationTree } from 'vuex';
import { Coordinator, CoordinatorsState } from '@/store/coordinators/types';

export const mutations: MutationTree<CoordinatorsState> = {
  set(state, opts) {
    (state as any)[opts.key] = opts.data;
  },
  clear(state, key) {
    (state as any)[key] = [];
  },
};
