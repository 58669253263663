import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "donor-acceptability-criteria",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('donor_acceptability_criteria')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        title: _ctx.$t('donor_acceptability_criteria'),
        "sub-section-id": "sub_donor_acceptability_criteria"
      }, {
        contents: _withCtx(() => []),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}