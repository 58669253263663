import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group-large" }
const _hoisted_4 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: "HLA Comments",
    "sub-section-id": "hla-comments",
    saveButtonText: "Save HLA Comments",
    ref: "saveHlaComments",
    saveButton: true,
    disabled: !_ctx.canSave,
    onSave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.savePatch()))
  }, {
    contents: _withCtx(() => [
      (!_ctx.editState)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(" Loading... " + _toDisplayString(_ctx.editState), 1)
          ], 64))
        : (_openBlock(), _createElementBlock("fieldset", {
            key: 1,
            disabled: !_ctx.canSave
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_text_area_input, {
                  inputId: "hla-comments-comments",
                  name: "Comments",
                  modelValue: _ctx.editState.hlaAntibodiesComments,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.hlaAntibodiesComments) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_date_input, {
                  inputId: "hla-comments-updated-at",
                  name: "Updated At",
                  readonly: true,
                  modelValue: _ctx.editState.hlaCommentsUpdatedAt,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.hlaCommentsUpdatedAt) = $event))
                }, null, 8, ["modelValue"])
              ])
            ])
          ], 8, _hoisted_1))
    ]),
    _: 1
  }, 8, ["disabled"]))
}