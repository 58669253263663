import Vue from 'vue';
import { MutationTree } from 'vuex';
import { ResponsiblePhysiciansState } from './types';

export const mutations: MutationTree<ResponsiblePhysiciansState> = {
  setResponsiblePhysicians(state, physicians) {
    state.responsiblePhysicians = physicians;
  },
  setFilteredResponsiblePhysicians(state, physicians) {
    state.filteredResponsiblePhysicians = physicians;
  }
};
