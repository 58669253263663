export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "save_link_to_recipient_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Link"])},
        "link_to_recipient_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Recipient"])},
        "link_to_recipient_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Recipient History"])},
        "new_link_to_recipient_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Link to Recipient"])},
        "selected_link_to_recipient_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Link to Recipient"])},
        "link_date_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Date"])},
        "removed_date_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed Date"])},
        "recipient_id_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Afflo ID"])},
        "recipient_first_name_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient First Name"])},
        "recipient_last_name_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Last Name"])},
        "donor_relationship_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Relationship"])},
        "link_history_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use the form below to create a link to a recipient"])},
        "create_new_recipient_link_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Recipient Link"])},
        "recipient_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Search"])},
        "link_to_recipient_search_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for Recipient"])},
        "recipient_search_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Afflo ID, First Name, or Last Name"])},
        "recipient_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Details"])},
        "link_date_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Date"])},
        "recipient_id_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])},
        "recipient_first_name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "recipient_last_name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "recipient_sex_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
        "recipient_date_of_birth_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
        "recipient_insurance_number_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Number"])},
        "recipient_referring_organization_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referring Organization Name"])},
        "recipient_donor_relationship_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Relationship to Recipient"])},
        "unlink_recipient_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink Recipient"])},
        "unlink_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink Donor and Recipient"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "remove_date_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Date"])},
        "reason_for_unlinking_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for Unlinking"])},
        "reason_for_unlinking_other_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If Other"])}
      },
      "fr": {
        "save_link_to_recipient_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder le lien"])},
        "link_to_recipient_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers le patient"])},
        "link_to_recipient_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers l'historique du patient"])},
        "new_link_to_recipient_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau lien vers le patient"])},
        "selected_link_to_recipient_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien sélectionné vers le patient"])},
        "link_date_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du lien"])},
        "removed_date_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de suppression"])},
        "recipient_id_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo"])},
        "recipient_first_name_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom du patient"])},
        "recipient_last_name_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du patient"])},
        "donor_relationship_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation avec le donneur"])},
        "link_history_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisez le formulaire ci-dessous pour créer un lien vers un patient"])},
        "create_new_recipient_link_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau lien de patient"])},
        "recipient_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de patient"])},
        "link_to_recipient_search_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un patient"])},
        "recipient_search_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le numéro Afflo, le prénom ou le nom."])},
        "recipient_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du patient"])},
        "link_date_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du lien"])},
        "recipient_id_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo"])},
        "recipient_first_name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "recipient_last_name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "recipient_sex_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "recipient_date_of_birth_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
        "recipient_insurance_number_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'assurance"])},
        "recipient_referring_organization_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'organisme de référence"])},
        "recipient_donor_relationship_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation entre le donneur et le patient"])},
        "unlink_recipient_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler le lien du patient"])},
        "unlink_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler le lien entre le donneur et le patient"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "remove_date_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la date"])},
        "reason_for_unlinking_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison de l'annulation du lien"])},
        "reason_for_unlinking_other_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si autre"])}
      },
      "en_tgln": {
        "recipient_id_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient TGLN ID"])},
        "recipient_search_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter TGLN ID, First Name, or Last Name"])},
        "recipient_id_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID"])}
      },
      "fr_tgln": {
        "recipient_id_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])},
        "recipient_search_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter TGLN ID, First Name, or Last Name"])},
        "recipient_id_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])}
      },
      "en_tq": {
        "recipient_id_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])},
        "recipient_search_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter TQ Number, First Name, or Last Name"])},
        "recipient_id_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])}
      },
      "fr_tq": {
        "recipient_id_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])},
        "recipient_search_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter TQ Number, First Name, or Last Name"])},
        "recipient_id_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])}
      }
    }
  })
}
