<template>
  <login-container>
    <template v-slot:content>
      <template v-if="loading">
        {{$t('loading')}}
      </template>
      <template v-else>
        <user-list :fusionAuth="true" :userToken="userToken"/>
      </template>
    </template>
  </login-container>
</template>

<script lang="ts">
import { State } from 'vuex-facing-decorator';
import { Component, } from 'vue-facing-decorator';
import UserList from '@/components/login/UserList.vue';
import { mixins } from 'vue-facing-decorator';
import {UrlUtilsMixin} from "@/mixins/url-utils-mixin";
import LoginContainer from '@/components/shared/LoginContainer.vue';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    LoginContainer,
    UserList
  },
  ...i18nMessages([
    require('@/components/login/_locales/common.json'),
  ]),
})
export default class FusionRequest extends mixins(UrlUtilsMixin) {
  @State(state => state.pageState.currentPage.login) private editState!: any;

  userToken: string|undefined = undefined;
  loading = true;

  storeToken(): void {
    const fromUrl = this.getUrlQueryValue('access_token');
    const fromStorage = localStorage.getItem('user_token');
    if (fromUrl) {
      localStorage.setItem('user_token', fromUrl);
      this.userToken = fromUrl;
    } else if (fromStorage) {
      this.userToken = fromStorage;
    }
  }

  private mounted(): void {
    this.$store.commit('setPageTitle', 'Log in');
    // get access token
    Promise.all([
      this.storeToken()
    ]).finally(() => {
      this.loading = false;
    });
  }
}

</script>
