<template>
  <form autocomplete="off" v-if="isLoaded">
    <!-- Organ Details -->
    <organ-details-section
      ref="organDetailsSection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      :canSave="isGroupWriteable('journey_referral')"
    />

    <!-- Clinical Notes -->
    <clinical-notes-section
      ref="clinicalNotesSection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      :canSave="isGroupWriteable('journey_referral')"
      v-if="isClinicalNotesEnabled && !newJourney"
    />

    <!-- Referral -->
    <referral-section
      ref="referralSection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      v-if="isReferralDetailsEnabled && !newJourney"
    />

    <!-- Checklist -->
    <checklist-section
      ref="checklistSection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      :canSave="isGroupWriteable('journey_referral')"
      v-if="isChecklistsEnabled && !newJourney"
    />

    <!-- Donor Acceptability Criteria -->
    <donor-acceptability-details
      ref="donorAcceptabilityDetails"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
    />

    <!-- Transplant Details -->
    <transplant-details
      ref="transplantDetails"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      :canSave="isGroupWriteable('journey_medical')"
      v-if="isTransplantDetailsEnabled && !newJourney"
    />
  </form>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { RecipientJourney } from '@/store/recipientJourney/types';
import OrganDetailsSection from '@/components/organs/shared/_OrganDetailsSection.vue';
import ClinicalNotesSection from '@/components/organs/shared/ClinicalNotesSection.vue';
import ReferralSection from '@/components/organs/shared/_ReferralSection.vue';
import ChecklistSection from '@/components/organs/shared/ChecklistSection.vue';
import TransplantDetails from '@/components/organs/shared/TransplantDetails.vue';
import DonorAcceptabilityDetails from '@/components/organs/shared/DonorAcceptabilityDetails.vue';
import { UIRecipient } from '@/UIModels/recipient';
import { UIJourney } from '@/UIModels/journey';
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  components: {
    OrganDetailsSection,
    ClinicalNotesSection,
    ReferralSection,
    ChecklistSection,
    TransplantDetails,
    DonorAcceptabilityDetails
  },
  emits: [
    'sectionsToLoad',
    'loaded',
  ],
})
export default class Liver extends Vue {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney|undefined;

  // Getters
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;
  @Getter('isReferralDetailsEnabled', { namespace: 'configuration' }) private isReferralDetailsEnabled!: boolean;
  @Getter('isClinicalNotesEnabled', { namespace: 'configuration' }) private isClinicalNotesEnabled!: boolean;
  @Getter('isTransplantDetailsEnabled', { namespace: 'configuration' }) private isTransplantDetailsEnabled!: boolean;
  @Getter('isChecklistsEnabled', { namespace: 'configuration' }) private isChecklistsEnabled!: boolean;
  @Getter('isDonorAcceptabilityEnabled', { namespace: 'configuration' }) private isDonorAcceptabilityEnabled!: boolean;

  @Getter('clientId', { namespace: 'recipients' }) private clientId!: string|undefined;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  private isLoaded = false;

  // PRIVATE

  // Loading processes unique to this component
  private async mounted(): Promise<void> {
    try {
      const recipientIdentifier = this.clientId as string;
      if (!recipientIdentifier) {
        console.warn('missing recipient client_id');
      }

      // NOTE: journey _id will not exist on New Journey page
      const journeyIdentifier = this.journeyId as string;

      const currentPageStore = useCurrentPageStore();
      const uiRecipient = new UIRecipient(recipientIdentifier);
      await uiRecipient.load();

      const uiJourney = new UIJourney(recipientIdentifier, journeyIdentifier);
      
      currentPageStore.setCurrentRecipient(uiRecipient);
      currentPageStore.setCurrentJourney(uiJourney);

      if (!uiJourney.isNew) await currentPageStore.currentJourney?.load();

      this.isLoaded = true;

      // Collect all sections we're loading
      const sectionsToLoad: string[] = Array.from(Object.keys(this.$refs));
      // Emit to parent all sections we're loading
      this.$emit('sectionsToLoad', sectionsToLoad);
    } catch (error: unknown) {
      this.isLoaded = false;
      console.warn(error);
    }
  }
  
  // Emit a loaded event with the component name
  private loaded(ref: string): void {
    this.$emit('loaded', ref);
  }
}
</script>
