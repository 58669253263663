import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "modal-footer-body" }
const _hoisted_6 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_openBlock(), _createBlock(_component_modal_section, {
    "modal-id": "allocation-audit-messages-modal",
    ref: "allocationAuditMessagesModal",
    class: "modal-sticky-header",
    centered: true
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('allocation_audit_messages_heading')), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('allocation_audit_messages_explanation')), 1)
      ]),
      _createVNode(_component_sub_section, {
        "sub-section-id": "allocation-audit-messages-modal-warning-section",
        title: _ctx.$tc('allocation_warning_messages_heading', _ctx.filteredWarningMessages.length, { count: _ctx.filteredWarningMessages.length }),
        collapsible: true
      }, {
        contents: _withCtx(() => [
          (_ctx.filteredWarningMessages.length === 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('no_allocation_audit_warning_messages')), 1))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filteredWarningMessages, (warningMessage, warningIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `warning-message-${warningIndex}`,
                  class: "alert alert-warning"
                }, _toDisplayString(warningMessage), 1))
              }), 128))
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_sub_section, {
        "sub-section-id": "allocation-audit-messages-modal-warning-section",
        title: _ctx.$tc('allocation_error_messages_heading', _ctx.filteredWarningMessages.length, { count: _ctx.filteredErrorMessages.length }),
        collapsible: true
      }, {
        contents: _withCtx(() => [
          (_ctx.filteredErrorMessages.length === 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('no_allocation_audit_error_messages')), 1))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filteredErrorMessages, (errorMessage, errorIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `error-message-${errorIndex}`,
                  class: "alert alert-danger"
                }, _toDisplayString(errorMessage), 1))
              }), 128))
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.$t('cancel')), 1)
      ])
    ]),
    _: 1
  }, 512))
}