<template>
  <card-section 
    section-id="donor-data-history" 
    @loaded="loaded()"
    :overflowVisible="true">
    <template v-slot:header>
      {{$t('donor-data-history-title')}}
    </template>
    <template v-slot:body>
      <sub-section
        sub-section-id="donor-data-historys"
        :tabbableValue="$t('source')"
        :table-config="donorDataHistorysTableConfig"
      >
        <template v-slot:table-cell="props">
          <!-- Significant Change cells -->
          <template v-if="props.column.field == 'significant_change'">
            <span
              :title="props.row.changes"
            >
              {{props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- Other table cells -->
          <span v-else>
            {{props.formattedRow[props.column.field] != null ? props.formattedRow[props.column.field] : '-'}}
          </span>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>


<i18n src="./_locales/DonorDataHistory.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-facing-decorator';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { DeceasedDonor, DonorSignificantEvent } from '@/store/deceasedDonors/types';
import { DONOR_DATA_HISTORY_ENABLED } from "@/store/administration/types";

// Form schemas
interface DonorDataHistoryEntry {
  source?: string;
  date?: string;
  time?: string;
  clinical_service_coordinator?: string;
  significant_change?: string;
  changes?: string;
}

@Component({
  components: {
    SubSection,
    CardSection,
  }
})
export default class DonorDataHistory extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.deceasedDonors.significantEvents) private significantEvents!: DonorSignificantEvent[];

  // Getters
  @Getter('clientId', { namespace: 'deceasedDonors' }) private donorId!: number;
  @Getter('describeDonorEventSource', { namespace: 'deceasedDonors' }) private describeDonorEventSource!: (item: DonorSignificantEvent) => string;
  @Getter('describeDonorEventChanges', { namespace: 'deceasedDonors' }) private describeDonorEventChanges!: (item: DonorSignificantEvent) => string;
  @Getter('describeDonorEventCoordinator', { namespace: 'deceasedDonors' }) private describeDonorEventCoordinator!: (item: DonorSignificantEvent) => string;
  @Getter('describeDonorSignificantChange', { namespace: 'deceasedDonors' }) private describeDonorSignificantChange!: (item: DonorSignificantEvent) => string;

  /**
   * Gets configuration for the Donor Data History Table
   *
   * @returns {TableConfig} Details Table configuration
   */
  get donorDataHistorysTableConfig(): TableConfig {
    return {
      data: this.donorDataHistoryRows,
      columns: [
        { label: `${this.$t('source')}`,
          field: 'source' },
        { label: `${this.$t('date')}`,
          field: 'date'},
        { label: `${this.$t('time')}`,
          field: 'time'},
        { label: `${this.$t('user_name')}`,
          field: 'clinical_service_coordinator' },
        { label: `${this.$t('significant_change')}`,
          field: 'significant_change' },
      ],
      empty: this.emptyMessage,
      createButton: false,
      pagination: true
    };
  }


  // Show loading state or empty message
  get emptyMessage(): string {
    if (!this.significantEvents) {
      return 'Loading...';
    } else {
      return `${this.$t('no-data-history')}`;
    }
  }

  // Map data loaded from API to array of UI rows
  get donorDataHistoryRows(): DonorDataHistoryEntry[] {
    if (!this.significantEvents) {
      return [];
    }
    
    const collection = this.significantEvents || [];
    const rows = collection.map((item: DonorSignificantEvent): DonorDataHistoryEntry => {
      const source: string = this.describeDonorEventSource(item);
      const clinical_service_coordinator: string = this.describeDonorEventCoordinator(item);
      const significant_change = this.describeDonorSignificantChange(item);
      const changes = this.describeDonorEventChanges(item);
      return {
        source,
        date: this.parseDisplayDateUiFromDateTime(item.event_date),
        time: this.parseTimeUiFromDateTime(item.event_date),
        clinical_service_coordinator,
        significant_change,
        changes,
      };
    });
    return rows;
  }

  // Reload when donor data changes
  @Watch('deceasedDonor', { immediate: true, deep: true })
  private onDeceasedDonorChanged(): void {
    this.loaded();
  }

  // Load donor significant events
  private loaded(): void {
    if (!DONOR_DATA_HISTORY_ENABLED) return;

    this.$store.dispatch('deceasedDonors/loadSignificantEvents', this.donorId).then(() => {
      this.$emit('loaded', 'donorDataHistory');
    });
  }

  // Reload donor significant events
  public reload(): void {
    if (!DONOR_DATA_HISTORY_ENABLED) return;

    this.$store.dispatch('deceasedDonors/loadSignificantEvents', this.donorId);
  }
}
</script>
