import { MutationTree } from 'vuex';
import { DashboardSettingState, DashboardState } from '@/store/dashboard/types';
import Vue from 'vue';

export const mutations: MutationTree<DashboardState> = {
  setNotifications(state, notifications) {
    // Store notifications
    state.notifications = notifications;
  },
  setAllocations(state, allocations) {
    // Store active allocations
    state.allocations = allocations;
  },
  setPrimaryOffers(state, offers) {
    // Store primary offers
    state.primaryOffers = offers;
  },
  setBackupOffers(state, offers) {
    // Store backup offers
    state.backupOffers = offers;
  },
  setBookmarksEntries(state, entries) {
    // Store bookmarks
    state.bookmarks.entries = entries;
  },
  setActiveDonors(state, donors) {
    // Store active donors
    state.activeDonors = donors;
  },
  setRecentRecipients(state, recipients) {
    // Store active donors
    state.recentRecipients = recipients;
  },
  setLabResults(state, lab_results) {
    // Store active donors
    state.labResults = lab_results;
  },
  setRecentLivingDonors(state, donors) {
    // Store active donors
    state.recentLivingDonors = donors;
  },
  setUrgentListings(state, listings) {
    // Store active donors
    state.urgentListings = listings;
  },
  setExdDonors(state, listings) {
    // Store active donors
    state.exdDonors = listings;
  },
  setTransplantInProgress(state, transplants) {
    // Store transplants in progress
    state.transplantInProgress = transplants;
  },
  setWidgetRolePermissions(state, rolePermissions) {
    // Store dashboard widget permissions by role
    state.widgetRolePermissions = rolePermissions;
  }
};
