<template>
  <ul class="menu-header-nav" aria-label="Toolbar">
    <li class="nav-item">
      <slot name="return-link" />
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#top" :title="$t('return_to_top')" :aria-label="$t('control_returns_to_top')">
        <font-awesome-icon :icon="['fas', 'arrow-up']" fixed-width />
        <span class="sr-only">
          {{$t('return_to_top')}}
        </span>
      </a>
    </li>
    <li class="nav-item toggle-link">
      <a class="nav-link" href="#" :title="$t('toggle_menu_visibility')" @click.prevent="toggleSideNav" :aria-label="$t('control_toggles_visibility')">
        <span class="far fa-w fa-bars">
          <font-awesome-icon :icon="['far', 'bars']" />
        </span>
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
import { i18nMessages } from '@/i18n';
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({
  ...i18nMessages([
    require('@/components/shared/_locales/SideNavToolbar.json'),
  ]),
  emits: [
    'toggle',
  ],
})
export default class SideNavToolbar extends Vue {
  @Prop() returnLink!: string;

  /**
   * Emits an event when the eye is clicked
   *
   * Emits a toggle-sidebar event to the parent component and changes the toggleEye boolean
   *
   * @emits toggle
   */
  public toggleSideNav(): void {
    this.$emit('toggle');
  }
}
</script>
