export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "allocation_multiple_programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot decline multiple offers from different Transplant Programs"])},
        "title_offer_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Responses"])},
        "allocation_recommendation_listing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Recommendation Listing"])},
        "offer_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Type"])},
        "organ_spec_offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Spec. Offered"])},
        "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
        "listed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listed For"])},
        "offer_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Date Time (EST)"])},
        "offered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offered By"])},
        "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response"])},
        "response_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response Category"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
        "reason_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason Category"])},
        "response_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response Reason"])},
        "response_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response Date Time (EST)"])},
        "responsible_physician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible Physician"])},
        "response_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response By"])},
        "recipient_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Status"])},
        "link_recipient_in_offer_recommendation_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Recipient in Offer Recommendation table"])},
        "confirm_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Changes"])},
        "decline_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline Multiple"])},
        "must_have_valid_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All responses must have a valid response code selected."])},
        "must_have_valid_responsible_physician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All responses must include a valid responsible physician"])},
        "higher_ranking_not_responded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer(s) for higher ranking recipient(s) have not been responded yet."])},
        "multiple_primary_offers_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than one primary offer is accepted. Accept only one primary offer and decline all others."])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
      },
      "fr": {
        "allocation_multiple_programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas refuser plusieurs offres de différents programmes de transplantation."])},
        "title_offer_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses aux offres"])},
        "allocation_recommendation_listing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de prescription d’attribution"])},
        "offer_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d’offre"])},
        "organ_spec_offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe spéc. offert"])},
        "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe"])},
        "listed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listé pour"])},
        "offer_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure de l’offre (HNE)"])},
        "offered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offert par"])},
        "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
        "response_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de réponse"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif"])},
        "reason_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de motif"])},
        "response_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif de la réponse"])},
        "response_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure de la réponse (HNE)"])},
        "responsible_physician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecin responsable"])},
        "response_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse par"])},
        "recipient_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut du patient"])},
        "link_recipient_in_offer_recommendation_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers le patient dans la table de prescription de l’offre"])},
        "confirm_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer les modifications"])},
        "decline_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser multiple"])},
        "must_have_valid_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les réponses doivent avoir un code de réponse valide sélectionné."])},
        "must_have_valid_responsible_physician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les réponses doivent inclure un médecin responsable valide"])},
        "higher_ranking_not_responded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'offre/les offres pour le(s) bénéficiaire(s) de rang supérieur(s) n'ont pas encore reçu de réponse."])},
        "multiple_primary_offers_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'une offre primaire est acceptée. Acceptez une seule offre primaire et refusez toutes les autres."])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])}
      },
      "en_tgln": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID"])}
      },
      "fr_tgln": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])}
      },
      "en_tq": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])}
      },
      "fr_tq": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])}
      }
    }
  })
}
