import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["title"]
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "monitor-recovery-outcomes",
    "lookups-to-load": _ctx.lookupsToLoad,
    onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('monitor_recovery_outcomes')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        "sub-section-id": "recovery-outcomes",
        ref: "recoveryOutcomes",
        "table-config": _ctx.recoveryOutcomesTableConfig
      }, {
        "table-cell": _withCtx((props) => [
          (props.column.field == 'recipientId')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (props.row.recipientId)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      class: "table-link",
                      to: {
              name: props.row.isOutOfProvince ? 'edit-recipient-oop' : 'edit-recipient',
              params: { id: props.row.recipientId }
            },
                      title: _ctx.$t('link_to_recipient_page'),
                      alt: props.row.recipientId
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.formattedRow[props.column.field]), 1)
                      ]),
                      _: 2
                    }, 1032, ["to", "title", "alt"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_1, "-"))
              ], 64))
            : (props.column.field == 'recipientName')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (props.row.recipientName)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        class: "table-link",
                        to: {
              name: props.row.isOutOfProvince ? 'edit-recipient-oop' : 'edit-recipient',
              params: { id: props.row.recipientId }
            },
                        title: props.row.recipientName,
                        alt: props.row.recipientName
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.recipientNameShort), 1)
                        ]),
                        _: 2
                      }, 1032, ["to", "title", "alt"]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_2, "-"))
                ], 64))
              : (props.column.field == 'lastUpdate')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    title: props.row.lastUpdateTime
                  }, _toDisplayString(props.formattedRow[props.column.field]), 9, _hoisted_3))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(props.formattedRow[props.column.field] != null ? props.formattedRow[props.column.field] : '-'), 1))
        ]),
        _: 1
      }, 8, ["table-config"])
    ]),
    _: 1
  }, 8, ["lookups-to-load"]))
}