import { APIRecipientDeath } from '@/store/recipients/types';
import { parseDateUi, sanitizeDateApi } from '@/utilities/date-utils';
import { UIRecipient } from '@/UIModels/recipient';
import { SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APISaveDeathResponse } from '@/types';

export class UIDeathDetails {
  private loaded: boolean;

  public apiSource: APIRecipientDeath|null = null;

  public deathDate: string|null = null;
  public estimated: boolean|null = null;
  public causeOfDeathCategory: string|null = null;
  public causeOfDeathType: number|null = null;
  public causeOfDeathTypeOther: string|null = null;
  public relatedToWaitTime: boolean|null = null;
  public comment: string|null = null;

  public constructor() {
    this.loaded = false;
  }

  // Define empty UI model structure
  public static buildNew() {
    const uiModel = new UIDeathDetails();
    return uiModel;
  }

  // Map from API data structure to UI model structure
  public static buildFromAPIRecipientDeath(apiDeath: APIRecipientDeath) {
    const uiModel = new UIDeathDetails();

    uiModel.apiSource = apiDeath;

    uiModel.causeOfDeathCategory = apiDeath.cause_category_code || null;
    uiModel.causeOfDeathType = apiDeath.cause_type_code || null;
    uiModel.causeOfDeathTypeOther = apiDeath.cause_type_other || null;
    uiModel.deathDate = parseDateUi(apiDeath.death_date) || null;
    uiModel.estimated = apiDeath.estimated_death_date || null;
    uiModel.comment = apiDeath.comments || null;
    uiModel.relatedToWaitTime = apiDeath.related_to_waittime == null ? null : apiDeath.related_to_waittime;

    uiModel.loaded = true;
    return uiModel;
  }

  // Build a copy of the view model
  public copyViewModel() {
    const apiDeath = this.apiSource as APIRecipientDeath;
    if (!apiDeath) return UIDeathDetails.buildNew();

    return UIDeathDetails.buildFromAPIRecipientDeath(apiDeath);
  }

  // Generate request payload parameters to provide to API for a Recipient Death patch
  private extractPatch(): APIRecipientDeath {
    const result = {
      cause_category_code: this.causeOfDeathCategory,
      cause_type_code: this.causeOfDeathType,
      cause_type_other: this.causeOfDeathTypeOther,
      death_date: sanitizeDateApi(this.deathDate),
      estimated_death_date: this.estimated,
      comments: this.comment || null,
      related_to_waittime: this.relatedToWaitTime
    };

    return result;
  }

  // Derive 'Deceased' indicator based on presence of Death Date
  get isDeceased(): boolean {
    return !!this.deathDate;
  }

  // Save  edit state to the backend
  public save(opts: { selected: UIDeathDetails|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      if (!recipientId) reject((new UIError('recipient_death')));

      const ep = APIRoute(EP.recipients.death.update, [[':recipientId', recipientId as string]]);
      const payload = {
        death: this.extractPatch()
      };
      axios.patch(ep, payload).then((response: APISaveDeathResponse) => {
        if (response.data.errors) {
          reject((new UIError('recipient_death', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          /// NOTE: here we simply call recipient-level reload to handle everything
          opts.recipient.load({ reload: true }).then(() => {
            resolve((new UISuccess(response)).getSaveResult());
          });
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('recipient_death', errorResponse)).errorResult);
      });
    });
  }
}
