import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group-with-other" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group-large" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_country_input = _resolveComponent("country-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "contactinfo",
    saveButtonText: _ctx.$t('save_contact_info'),
    ref: "saveContactInfo",
    lookupsToLoad: _ctx.lookupsToLoad,
    saveButton: !_ctx.newLivingDonor,
    disabled: !_ctx.canSave,
    onSave: _cache[10] || (_cache[10] = ($event: any) => (_ctx.savePatch(false))),
    onLoaded: _cache[11] || (_cache[11] = ($event: any) => (_ctx.loaded()))
  }, _createSlots({
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('contact_info')), 1)
    ]),
    _: 2
  }, [
    (!_ctx.editState)
      ? {
          name: "body",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
          ]),
          key: "0"
        }
      : {
          name: "body",
          fn: _withCtx(() => [
            _createVNode(_component_sub_section, {
              title: _ctx.$t('permanent_address'),
              "sub-section-id": "contactinfo-permanent"
            }, _createSlots({ _: 2 }, [
              (_ctx.editState.permanent)
                ? {
                    name: "contents",
                    fn: _withCtx(() => [
                      _createElementVNode("fieldset", {
                        disabled: !_ctx.canSave
                      }, [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_country_input, {
                              ruleKey: "patient_profile.addresses.country_code",
                              "select-id": "contactinfo-country",
                              name: _ctx.$t('country'),
                              modelValue: _ctx.editState.permanent.countryDropdown,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.permanent.countryDropdown) = $event)),
                              options: _ctx.countryLookup,
                              onChange: _ctx.clearProvince
                            }, {
                              province: _withCtx(() => [
                                _createVNode(_component_select_input, {
                                  ruleKey: "patient_profile.addresses.province_code",
                                  "select-id": "contactinfo-province",
                                  name: _ctx.$t('province'),
                                  "validation-id": "province-state",
                                  modelValue: _ctx.editState.permanent.province,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.permanent.province) = $event)),
                                  options: _ctx.provinceLookup
                                }, null, 8, ["name", "modelValue", "options"])
                              ]),
                              state: _withCtx(() => [
                                _createVNode(_component_select_input, {
                                  "select-id": "contactinfo-state",
                                  name: _ctx.$t('state'),
                                  "validation-id": "province-state",
                                  modelValue: _ctx.editState.permanent.state,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.permanent.state) = $event)),
                                  options: _ctx.usStateLookup
                                }, null, 8, ["name", "modelValue", "options"])
                              ]),
                              other: _withCtx(() => [
                                _createVNode(_component_text_input, {
                                  ruleKey: "patient_profile.addresses.country_other",
                                  "input-id": "contactinfo-othercountry",
                                  rules: "required",
                                  name: _ctx.$t('other_country'),
                                  modelValue: _ctx.editState.permanent.countryOther,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.permanent.countryOther) = $event))
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              _: 1
                            }, 8, ["name", "modelValue", "options", "onChange"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_text_input, {
                              ruleKey: "patient_profile.addresses.street",
                              inputId: "contactinfo-street",
                              name: _ctx.$t('street_address'),
                              modelValue: _ctx.editState.permanent.street,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.permanent.street) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_text_input, {
                              ruleKey: "patient_profile.addresses.city",
                              inputId: "contactinfo-city",
                              name: _ctx.$t('city'),
                              modelValue: _ctx.editState.permanent.city,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.permanent.city) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_text_input, {
                              ruleKey: "patient_profile.addresses.postal_code",
                              inputId: "contactinfo-postalcode",
                              name: _ctx.getPostalLabelText,
                              modelValue: _ctx.editState.permanent.postalCode,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.permanent.postalCode) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ])
                        ])
                      ], 8, _hoisted_1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["title"]),
            _createVNode(_component_sub_section, {
              title: _ctx.$t('living_donor_contact_details'),
              "sub-section-id": "contactinfo-details"
            }, _createSlots({ _: 2 }, [
              (_ctx.editState.contactDetails)
                ? {
                    name: "contents",
                    fn: _withCtx(() => [
                      _createElementVNode("fieldset", {
                        disabled: !_ctx.canSave
                      }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_text_input, {
                              ruleKey: "patient_profile.contact_details.phone_mobile",
                              inputId: "contactinfo-details-mobilephone",
                              name: _ctx.$t('mobile_phone_num'),
                              mask: _ctx.getTelephoneMask,
                              modelValue: _ctx.editState.contactDetails.mobilePhone,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.contactDetails.mobilePhone) = $event))
                            }, null, 8, ["name", "mask", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_text_input, {
                              ruleKey: "patient_profile.contact_details.phone_primary",
                              inputId: "contactinfo-details-homephone",
                              name: _ctx.$t('home_phone_num'),
                              mask: _ctx.getTelephoneMask,
                              modelValue: _ctx.editState.contactDetails.homePhone,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.contactDetails.homePhone) = $event))
                            }, null, 8, ["name", "mask", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_text_input, {
                              ruleKey: "patient_profile.contact_details.email",
                              inputId: "contactinfo-details-email",
                              name: _ctx.$t('email'),
                              modelValue: _ctx.editState.contactDetails.email,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.contactDetails.email) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ])
                        ])
                      ], 8, _hoisted_8)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["title"])
          ]),
          key: "1"
        }
  ]), 1032, ["saveButtonText", "lookupsToLoad", "saveButton", "disabled"]))
}