import { toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checklist border-0" }
const _hoisted_2 = { class: "form-check" }
const _hoisted_3 = ["id", "aria-labelledby", "v-model"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "pl-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", _mergeProps({
        type: "checkbox",
        class: "form-check-input d-none",
        id: _ctx.inputId,
        "aria-labelledby": _ctx.inputId,
        "v-model": _ctx.isChecked
      }, _toHandlers(_ctx.inputEvents(), true)), null, 16, _hoisted_3),
      _createElementVNode("label", {
        class: "form-check-label selected",
        for: _ctx.inputId
      }, [
        (_ctx.isChecked)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              icon: ['far', 'check-square']
            }))
          : (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 1,
              icon: ['far', 'square']
            })),
        _createElementVNode("span", _hoisted_5, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ])
        ])
      ], 8, _hoisted_4)
    ])
  ]))
}