import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/configuration/actions';
import { getters } from '@/store/configuration/getters';
import { mutations } from '@/store/configuration/mutations';
import { ConfigurationState } from '@/store/configuration/types';

const state: ConfigurationState = {
  configuration: null,
};

const namespaced = true;

export const configuration: Module<ConfigurationState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced,
};
