export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "waiting_list_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting List Report"])},
        "organ_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Type"])},
        "clustered_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clustered with"])},
        "clustered_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clustered List (Max 3)"])},
        "include_non_clustered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include Non clustered"])},
        "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital"])},
        "suspended_patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended patients only"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
        "accept_living_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Living Donor"])},
        "accept_deceased_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Deceased Donor"])},
        "ecd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extended Criteria Donor"])},
        "dac_acceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAC Acceptance"])},
        "hcv_ab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HCV Ab"])},
        "hcv_nat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HCV NAT"])},
        "hep_ab_core": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hep B Core Ab"])},
        "lower_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower Age"])},
        "upper_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper Age"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
        "sort_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort Order"])},
        "generate_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Waiting List Report"])}
      },
      "fr": {
        "waiting_list_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting List Report"])},
        "organ_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Type"])},
        "clustered_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clustered with"])},
        "clustered_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clustered List (Max 3)"])},
        "include_non_clustered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include Non clustered"])},
        "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital"])},
        "suspended_patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended patients only"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
        "accept_living_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Living Donor"])},
        "accept_deceased_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Deceased Donor"])},
        "ecd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extended Criteria Donor"])},
        "dac_acceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAC Acceptance"])},
        "hcv_ab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HCV Ab"])},
        "hcv_nat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HCV NAT"])},
        "hep_ab_core": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hep B Core Ab"])},
        "lower_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower Age"])},
        "upper_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper Age"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
        "sort_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort Order"])},
        "generate_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Waiting List Report"])}
      }
    }
  })
}
