import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-wrap" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  class: "nav-wrapper",
  id: "administration-area"
}
const _hoisted_4 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!
  const _component_side_nav_profile = _resolveComponent("side-nav-profile")!
  const _component_user_profile = _resolveComponent("user-profile")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_page_top, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("user_profile")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_side_nav_profile),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_validation_observer, { ref: "validations" }, {
              default: _withCtx(() => [
                _createVNode(_component_user_profile, { ref: "userProfile" }, null, 512)
              ]),
              _: 1
            }, 512)
          ])
        ])
      ])
    ])
  ]))
}