export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "donation_destination_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation Destination"])},
        "donation_destination_save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Donation Destination"])},
        "donation_destination_donate_to_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donor to donate to"])},
        "pre_selected_recipient_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-selected Recipient"])},
        "to_be_allocated_to_a_recipient_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be allocated to a Recipient"])},
        "directed_donation_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directed Donation"])},
        "non_directed_donation_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Directed Donation"])},
        "pre_selected_recipient_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-selected Recipient"])},
        "intended_recipient_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intended Recipient"])},
        "kidney_paired_donation_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kidney Paired Donation"])},
        "to_be_allocated_to_a_recipient_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be allocated to a Recipient"])},
        "list_exchange_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Exchange"])},
        "non_directed_end_of_chain_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Directed Donation (KPD - End of Chain)"])}
      },
      "fr": {
        "donation_destination_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination du don"])},
        "donation_destination_save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder la destination du don"])},
        "donation_destination_donate_to_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneur vivant pour faire un don"])},
        "pre_selected_recipient_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient présélectionné"])},
        "to_be_allocated_to_a_recipient_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À attribuer à un patient"])},
        "directed_donation_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don dirigée"])},
        "non_directed_donation_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don non dirigée"])},
        "pre_selected_recipient_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient présélectionné"])},
        "intended_recipient_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient prévu"])},
        "kidney_paired_donation_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don de rein par paire"])},
        "to_be_allocated_to_a_recipient_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À attribuer à un patient"])},
        "list_exchange_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange de listes"])},
        "non_directed_end_of_chain_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dons non dirigés (KPD - fin de la chaîne)"])}
      }
    }
  })
}
