import axios from 'axios';
import { ActionTree, Dispatch, Commit } from 'vuex';
import { SystemOrganTypes, RootState, GenericCodeValue } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { LookupsState, Country, Organ, OrganDiseaseCode, OrganSpecification, HlaDictionaryEntry, OrganCauseOfFailure, VirologyResultCodesValue, DispatchType, DispatchEntry, LookupEntry } from '@/store/lookups/types';
import i18n from '@/i18n';

// before making request
// check exists and if so defer to memorized item, otherwise make request

const lookupSubTableHandler: { [key: string]: any } = {
  country: (countries: Country[], commit: Commit) => {
    // loop over each country to retrieve sub_table data then set it
    countries.forEach(country => {
      switch(country.code) {
        case "CAN": {
          // retrieve provinces from sub_tables
          const provinces = country.sub_tables.province;
          commit('setSubTable', {
            id: 'province',
            value: provinces
          });
          break;
        }
        case "USA": {
          // retrieve USA states from sub_tables
          const states = country.sub_tables.province;
          commit('setSubTable', {
            id: 'us_state',
            value: states
          });
          break;
        }
      }
    });
  },

  organ: (organs: Organ[], commit: Commit) => {
    commit('setSystemOrganTypes', organs, { root: true });
  }
};

/**
 * Translate value into current language
 *
 * Every lookup record has a .value attribute and .values array
 * Inside the .values array contains the value in multiple languages.
 * This method takes the current language and uses that to return 
 * the value in the current language
 *
 * @param item lookup object
 * @returns {string} string
 */
function translateValue(item: LookupEntry): string|undefined {
  if (!item.values) return item.value || undefined;

  const lang = getFallbackLocale();

  return item.values[lang] ? item.values[lang] : item.value;
}

function getFallbackLocale(): string {
  const locale = i18n.locale;
  const fallbacks: any = i18n.fallbackLocale;

  // if en or fr return locale
  if (locale == 'en' || locale == 'fr') return locale;

  // otherwise find fallback and return it
  const fallback: string[] = fallbacks[locale];
  return fallback.length > 0 ? fallback[0] : 'en';
}

export const actions: ActionTree<LookupsState, RootState> = {  
  /**
   * Lookup load action
   * requests lookup from api and on finish updates it's status in the dispatch table
   *
   * @param lookup lookup id
   * @param url endpoint url (optional)
   * @param i index of lookup in dispatch table to update status (e.g. 'loaded')
   * @returns {Promise<void>} return function
   */
  load({ commit, dispatch, state }, { lookup, url = null, i = null }) {
    return new Promise<void>((resolve, reject) => {
      const _url = url || EP.lookups.show;
      const loadUrl = APIRoute(_url, [[':id', lookup]]);
      axios.get(loadUrl).then((response: any) => {
        // no longer filter out expired lookups on load, do that separately
        const responseData: any[] = response.data.lookup;
        const parsedLookupName = lookup.toString().toLowerCase();
        // check lookup for translation data, if none found log it to the console for debugging
        if (Array.isArray(responseData)) {
          // generate translated lookup data, replacing .value 
          // attribute with the value in the current language, 
          // repeat this for sub tables if necessary
          const translatedData: any = responseData.map((item: any) => {
            const newItem = item;
            const newValue = translateValue(item);

            if (item.sub_tables) {
              const subTableKeys = Object.keys(item.sub_tables);
              const subTables: any = {};
              subTableKeys.forEach((key: string) => {
                const subTable = item.sub_tables[key].map((subItem: LookupEntry) => {
                  const newSubItem = subItem;
                  const newSubValue = translateValue(newSubItem);
                  return { ...newSubItem, ...{ value: newSubValue } };
                });
                subTables[key] = subTable;
              });
              return { ...newItem, ...{ value: newValue, sub_tables: subTables } };
            } else {
              return { ...newItem, ...{ value: newValue } };
            }
          });
          commit('set', { id: parsedLookupName, value: translatedData });
          // if there are any subtables, load them here
          if(lookup.match(/(\bcountry\b)|(\borgan\b)/)){
            const structuredLookup = lookupSubTableHandler[lookup](translatedData, commit);
          }
        } else {
          commit('set', { id: lookup, value: responseData });
        }
        // update status in dispatch table
        commit('setDispatch', { lookup: lookup, state: DispatchType.loaded, i: i });
        resolve();
      }).catch((error: any) => {
        // update status in dispatch table
        commit('setDispatch', { lookup: lookup, state: DispatchType.failed, i: i });
        reject();
      });  
    });
  },

  /**
   * Adds lookup to dispatch table and promise array
   * dispatch table is used to log the process, status, and index number
   * promise array is used to store the promise
   * Checks if the lookup is already logged in the dispatch table, if found waits till it's
   * status is changed to 'loaded'. otherwise adds the lookup to the two tables for processing
   *
   * @param lookup lookup id
   * @param url endpoint url, optional
   */
   queueLookup({ commit, dispatch, state }, { lookup, url = null }) {    
    return new Promise<void>((resolve, reject) => {
      const lookupName = lookup.toString().toLowerCase();

      const found = state.dispatchTable.find((item: any) => {
        return item.lookup == lookup;
      });

      // if found lookup already running, wait until it's loaded and resolve it
      if (found) {
        const interval = setInterval(() => {
          if (state.dispatchTable[found.index].state == DispatchType.loaded) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      } else {
        const i = state.promiseArray.length;

        state.dispatchTable.push({
          lookup: lookupName,
          index: i,
          state: DispatchType.loading,
        });

        state.promiseArray.push({
          func: 
            dispatch('load', { lookup: lookupName, url: url, i: i})
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            })
        });
      }
    });
  },

  // TODO: Add the remaining sub_tables
  // cause_of_failures, waitlist_medical_statuses, offer_decline_reasons
  getDiseaseCodes({ state }, organCode: number): Promise<OrganDiseaseCode[]> {
    return new Promise<OrganDiseaseCode[]>((resolve, reject) => {
      const organ = (state.organ as Organ[]);
      const code = organ.find(e => e.code === organCode );
      const diseaseCode = code ? code.sub_tables.disease_code : [];
      resolve(diseaseCode);
    });
  },
  getRecipientOrganSpecifications({ state }, organCode: number): Promise<OrganSpecification[]> {
    return new Promise<OrganSpecification[]>((resolve, reject) => {
      const organ = (state.organ as Organ[]);
      const code = organ.find(e => e.code === organCode );
      const organSpecifications: OrganSpecification[] = code?.sub_tables?.organ_specifications || [];
      const recipientOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
        return !!organSpec.recipient;
      });
      resolve(recipientOrganSpec);
    });
  },
  getOfferOrganSpecifications({ state }, organCode: number): Promise<OrganSpecification[]> {
    return new Promise<OrganSpecification[]>((resolve, reject) => {
      const organ = (state.organ as Organ[]);
      const code = organ.find(e => e.code === organCode );
      const organSpecifications: OrganSpecification[] = code?.sub_tables?.organ_specifications || [];
      const offerOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
        return !!organSpec.offer;
      });
      resolve(offerOrganSpec);
    });
  },
  getTransplantOrganSpecifications({ state }, organCode: number): Promise<OrganSpecification[]> {
    return new Promise<OrganSpecification[]>((resolve, reject) => {
      const organ = (state.organ as Organ[]);
      const code = organ.find(e => e.code === organCode );
      const organSpecifications: OrganSpecification[] = code?.sub_tables?.organ_specifications || [];
      const transplantOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
        return !!organSpec.transplant;
      });
      resolve(transplantOrganSpec);
    });
  },
};
