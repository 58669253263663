<template>
  <div class="container login-container">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-10 col-lg-7 col-xl-6">
        <!-- Logo header -->
        <div class="nav-top">
          <h1 class="my-4 brand py-2">
            <a href="/" class="app-logo-login">
              <img v-if="getLogo" :src="getLogo" :alt="$t('application_short_name')">
              <span class="brand-title d-none d-md-flex" id="app-short-name">{{ $t('application_long_name') }}</span>
              <span class="brand-title d-xs-flex d-md-none" id="app-long-name">{{ $t('application_short_name') }}</span>
            </a>
          </h1>
        </div>
        <div class="card shadow-none">
          <div class="card-body">
            <h2 class="login-title">{{$t('log_in')}}</h2>
            <div class="system-title d-inline">
              {{uiEnvVersion}}
            </div>
            <hr>
            <slot name="content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Component, Vue } from 'vue-facing-decorator';
import { i18nMessages } from '@/i18n';

@Component({
  ...i18nMessages([
    require('@/components/_locales/common.json'),
    require('@/components/login/_locales/common.json'),
  ]),
})
export default class LoginContainer extends Vue {
  @State(state => state.uiEnvVersion) private uiEnvVersion!: string|null;

  @Getter('getLogo', { namespace: 'configuration' }) private getLogo!: string;
}
</script>
