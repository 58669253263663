export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "monitor_recovery_outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Summary"])},
        "no_recovery_outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run an allocation and proceed with the eOffer process to generate a Donor Summary"])},
        "allocation_recommendation_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Recommendation Reference"])},
        "outcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outcome"])},
        "disposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposition"])},
        "receiving_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiving Organization"])},
        "recipientID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient ID"])},
        "recipientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Name"])},
        "recipientAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Age"])},
        "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Update"])},
        "Not Offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Offered"])},
        "Offered, Not Recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offered, Not Recovered"])},
        "Offer Accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Accepted"])},
        "Recovered, Not For Transplant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovered, Not For Transplant"])},
        "Recovered For Transplant, Not Transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovered For Transplant, Not Transplanted"])},
        "Transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplanted"])},
        "donorFactorsAndMedicalHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Factors and Medical History"])}
      },
      "fr": {
        "monitor_recovery_outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé du donateur"])},
        "no_recovery_outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exécutez une allocation et poursuivez le processus d'eOffer pour générer un résumé du donateur"])},
        "allocation_recommendation_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de recommandation d'allocation"])},
        "outcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat"])},
        "disposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposition"])},
        "receiving_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation d'accueil"])},
        "recipientID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du destinataire"])},
        "recipientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du destinataire"])},
        "recipientAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge du destinataire"])},
        "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
        "Not Offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas offert"])},
        "Offered, Not Recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offert, non récupéré"])},
        "Offer Accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre acceptée"])},
        "Recovered, Not For Transplant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupéré, pas pour la transplantation"])},
        "Recovered For Transplant, Not Transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupéré pour la transplantation, non transplanté"])},
        "Transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplanté"])},
        "donorFactorsAndMedicalHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facteurs du donneur et antécédents médicaux"])}
      }
    }
  })
}
