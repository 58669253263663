<template>
  <sub-section
    v-if="showControls"
    :title="$t('allocation_controls')"
    sub-section-id="allocation-controls"
    :save-button="false"
  >
    <template v-slot:contents v-if="editState">
      <fieldset :disabled="editState && (isLoadingAllocation || isLoadingAllocations)">
        <template v-if="editState.allocationErrorMessage">
          <div class="alert alert-danger">
            <span v-html="editState.allocationErrorMessage" />
          </div>
        </template>
        <template v-if="running && !getTaskError">
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style='getPercentageCss' :aria-valuenow='getPercentage' aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <br/>
          <div class="alert alert-info">
            <span v-html="getStatus" />
          </div>
        </template>
        <div class="alert alert-danger" v-if="getTaskError">
          {{getTaskError}}
        </div>
        <nav class="nav action-row mt-0">
          <template>
            <button
              type="button"
              class="btn btn-wide btn-lg btn-primary btn-sm mt-2 mr-2"
              v-if="!hasDiscontinuedAllocation"
              :disabled="isLoadingAllocation || isCreatingAllocation || offeringState || recommendedState || running"
              @click="runAllocation()"
            >
              {{ $t('run_allocation_recommendation') }}
              <span class="pl-2" v-if="isCreatingAllocation">
              <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" />
            </span>
            </button>
            <button
              type="button"
              class="btn btn-wide btn-lg btn-primary btn-sm mt-2 mr-2"
              v-if="hasDiscontinuedAllocation"
              :disabled="isLoadingAllocation || isCreatingAllocation || offeringState || recommendedState || running"
              @click="reRunAllocation()"
            >
              {{ $t('re_run_allocation_recommendation') }}
              <span class="pl-2" v-if="isCreatingAllocation">
              <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" />
            </span>
            </button>
            <div class="dropdown">
              <button
                type="button"
                data-toggle="dropdown"
                :disabled="isLoadingAllocation || isCreatingAllocation || isGeneratingAllocationReport || running"

                class="btn btn-wide btn-lg btn-primary btn-sm mt-2 mr-2 dropdown-toggle"
                v-if="allowedAllocationReport"
              >
                {{ allocationReportButtonText }}
                <span class="pl-2" v-if="isGeneratingAllocationReport">
                  <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" />
                </span>
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item"
                   v-for="(row, idx) in allowedAllocationReportHospitals"
                   :key="idx"
                   @click="downloadAllocationReport(row.code)"
                >
                  {{row.value}}
                </a>
              </div>
            </div>
            <button
              type="button"
              v-if="allocation"
              @click="addRecipient()"
              class="btn btn-wide btn-lg btn-primary btn-sm mt-2 mr-2"
              :disabled="isLoadingAllocation || isCreatingAllocation || isAddingRecipient || running"
            >
              {{$t('add_recipient_allocation')}}
            </button>
            <button
              type="button"
              v-if="allocation"
              @click="discontinueAllocation()"
              class="btn btn-wide btn-lg btn-danger btn-sm mt-2 mr-2"
              :title="$t('discontinue_all_offers_in_this_allocation')"
              :disabled="isDiscontinueAllocationDisabled || running"
            >
              {{ $t('discontinue_allocation') }}
              <span class="pl-2" v-if="isDiscontinuingOneAllocation">
              <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" />
            </span>
            </button>
          </template>

          <modal-section
            modalId="expedited-allocation-modal"
            ref="expeditedAllocationModal"
            class="modal-sticky-header"
            :centered="true">
            <template v-slot:title>
              {{ $t('confirm_expedited_allocation_recommendation') }}
            </template>
            <template v-slot:body>
              <p>
                <span v-html="editState.modalErrorMessage"/>
              </p>
              <p>{{ $t('run_expedited_allocation')}}</p>
            </template>
            <template v-slot:footer>
              <div class="modal-footer-body">
                <button type="button" data-dismiss="modal" class="btn btn-secondary">
                  {{ $t('cancel') }}
                </button>
                <button
                  class="btn btn-success"
                  @click="checkForPreviousExpeditedAllocation()"
                  :disabled="isLoadingAllocation || isCreatingAllocation"
                >
                  {{ $t('run_expedited_allocation_recommendation') }}
                  <span class="pl-2" v-if="isCreatingAllocation">
                    <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" />
                  </span>
                </button>
              </div>
            </template>
          </modal-section>

          <modal-section
            modalId="vca-hla-error-modal"
            ref="vcaHlaErrorModal"
            class="modal-sticky-header"
            size="md"
            :wide="false"
            :centered="true">
            <template v-slot:title>
              {{ $t('vca_hla_error_title') }}
            </template>
            <template v-slot:body>
              <span v-html="editState.modalErrorMessage"/>
            </template>
            <template v-slot:footer>
              <button type="button" data-dismiss="modal" class="btn btn-success text-right mr-0">
                {{ $t('ok') }}
              </button>
            </template>
          </modal-section>

          <add-recipient-modal
            ref="addRecipientModal"
            @reloadAllocation="reloadAllocation()"
          />
        </nav>
        <span class="skeleton-box w-75" style="height: 2.5rem" v-if="isLoadingAllocation"/>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="@/components/allocations/_locales/common.json"></i18n>
<i18n src="@/components/allocations/_locales/_AllocationControls.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/commonPatientShared.json"></i18n>


<script lang="ts">
import { ObjectId } from '@/store/types';
import { Getter, State } from 'vuex-facing-decorator';
import { OrganCodeValue } from '@/store/lookups/types';
import { LivingDonor } from '@/store/livingDonors/types';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import SubSection from '@/components/shared/SubSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import AddRecipientModal from '@/components/livingAllocations/_AddRecipientModal.vue';
import { LivingExclusionRules, LivingAllocation, LivingAllocations, SYSTEM_ONLY_ALLOCATION_STATES, RUN_ALLOCATION, RERUN_ALLOCATION, RUN_EXPEDITED_ALLOCATION, RERUN_EXPEDITED_ALLOCATION, DISCONTINUED } from '@/store/livingAllocations/types';
import { TaskType, CategoryType, errorType, TaskState } from '@/store/tasks/types';
import { HospitalOption, ACTIVE_REGION_TRANSPLANT_PROGRAM } from '@/store/hospitals/types';
import { User } from '@/store/users/types';

export interface AllocationControlState {
  disabledExclusionRules: string[];
  offer: {
    offerErrorMessage: string;
  };
  discontinue: any;
  modalErrorMessage: string;
  allocationErrorMessage: string;
  reportLink: string;
}

@Component({
  components: {
    SubSection,
    ModalSection,
    AddRecipientModal
  }
})
export default class AllocationControls extends Vue {
  @State(state => state.pageState.currentPage.livingAllocations) editState!: AllocationControlState;
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.livingAllocations.exclusionRules) private exclusionRules!: LivingExclusionRules[];
  @State(state => state.livingAllocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.livingAllocations.isLoadingAllocations) private isLoadingAllocations!: boolean;
  @State(state => state.livingAllocations.isCreatingAllocation) private isCreatingAllocation!: boolean;
  @State(state => state.livingAllocations.isAddingRecipient) private isAddingRecipient!: boolean;
  @State(state => state.livingAllocations.isGeneratingAllocationReport) private isGeneratingAllocationReport!: boolean;
  @State(state => state.livingAllocations.isDiscontinuingOneAllocation) private isDiscontinuingOneAllocation!: boolean;
  @State(state => state.livingAllocations.lastLoadedAllocationDonorId) private lastLoadedAllocationDonorId!: ObjectId;
  @State(state => state.users.user) user!: User;
  @State(state => state.tasks) taskState!: TaskState;

  @Getter('getUserName', { namespace: 'users' }) private getUserName!: string;
  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('selectedAllocation', { namespace: 'livingAllocations' }) private allocation!: LivingAllocation;
  @Getter('activeAllocations', { namespace: 'livingAllocations' }) private activeAllocations!: LivingAllocations[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;
  @Getter('hospitalOptionsOntarioAll', { namespace: 'hospitals' }) hospitalOptionsOntarioAll!: HospitalOption[];
  @Getter('getCurrentTask', { namespace: 'tasks' }) private getCurrentTask!: TaskType;
  @Getter('getCurrentTaskId', { namespace: 'tasks' }) private getCurrentTaskId!: string|null;
  @Getter('getPercentage', { namespace: 'tasks' }) private getPercentage!: number;
  @Getter('getPercentageCss', { namespace: 'tasks' }) private getPercentageCss!: string;
  @Getter('getStatus', { namespace: 'tasks' }) private getStatus!: string|undefined;
  @Getter('getTaskError', { namespace: 'tasks' }) private getTaskError!: errorType|undefined;

  private running = false;

  // Only need to reload allocations if donor ID is not correct (i.e. navigated between two different donor pages)
  public mounted(): void {
    this.$store.commit('tasks/resetTask');
    this.$store.dispatch('hospitals/load', ACTIVE_REGION_TRANSPLANT_PROGRAM).then(() => {
      this.buildAllocationPageState();
      const runningId = this.getAbandonedTask();
      if (runningId) {
        this.restartAbandonedTask(runningId);
      }
    });
  }

  /**
   * Return true if we can see the Allocation Controls
   *
   * @returns {boolean} true if we have access
   */
  get showControls(): boolean {
    // TODO - disable controls when directed donation
    // otherwise defer to user access
    return this.checkAllowed("/living_donors/:living_donor_id/organs/:organ_id/allocations/:id/rerun", "POST");
  }

  get allowedAllocationReportHospitals(): HospitalOption[]|undefined {
    const hospitals = this.hospitalOptionsOntarioAll || [];
    let allowedHospitals = this.user.all_hospitals ? [{ code: '', value: 'All'}] : [];
    const filterBy = this.user.hospital_organ_codes || {};

    hospitals.forEach((hospital: any) => {
      if(Object.keys(filterBy).includes(hospital.code)){
        allowedHospitals.push(hospital);
      }
    });

    return allowedHospitals;
  }

  /**
   * Return true if we the user can see the Allocation Report
   *
   * @returns {boolean} true if the user has access
   */
  get allowedAllocationReport(): boolean {
    return this.allocation && this.checkAllowed("/reports/allocation_report", "POST");
  }

  /**
   * Return the organ code param from the url
   *
   * @returns {string} organ code
   */
  get organCode(): string {
    return (this.$route.params.organ_code || '').toString();
  }

  /**
   * Return the option param from the url
   *
   * @returns {string} option param from the url
   */
  get organAllocationOption(): string {
    return (this.$route.params.option || '').toString().toLowerCase();
  }

  /*
    Define allocation request options based on route option. E.g. if we are generating a request for a Kidney
    allocation, then the option should be 'local' or 'provincial'. Based on this value, we define parameters expected
    but the API endpoint responsible for allocation POST requests.
  */
  get organAllocationRequestOptions(): any {
    let result;
    switch(this.organAllocationOption) {
      case 'local':
        result = {
          kp_local: true,
        };
        break;
      default:
        result = {};
        break;
    }
    return result;
  }

  /**
   * Return exclusion filters to disable from the allocation run
   *
   * @returns {string[]} array of exclusion filter id's
   */
  get allocationRulesPayload(): string[] {
    return this.editState ? this.editState.disabledExclusionRules : [];
  }

  /**
   * Return if the allocation is in an offering, offer-confirmed or offer-accepted state
   *
   * @returns {boolean} true if allocation is offering, offer-accepted, offer-confirmed
   */
  get offeringState(): boolean {
    const OFFERING_STATES: string[] = ['offering', 'offer-accepted', 'offer-confirmed'];
    return OFFERING_STATES.includes(this.allocation?.state.toLowerCase());
  }

  /**
   * Return if the allocation is in a recommended state
   *
   * @returns {boolean} true if allocation is recommended
   */
  get recommendedState(): boolean {
    return this.allocation?.state.toLowerCase() === 'recommended';
  }

  /**
   * Return a filtered list of exclusions rules
   *
   * @returns {LivingExclusionRules[]} a list of exclusions rules for the selected organ
   */
  get filteredExclusionRules(): LivingExclusionRules[] {
    if (this.exclusionRules && this.exclusionRules.length > 0) {
      return this.exclusionRules.filter((item: LivingExclusionRules) => {
        const organCodes = item.organ_code;
        return organCodes.includes(Number(this.organCode));
      });
    }
    return [];
  }

  /**
   * Return true if there are any rules
   *
   * @returns {boolean} true if there are rules
   */
  get hasExclusionRules(): boolean {
    return this.filteredExclusionRules && this.filteredExclusionRules.length > 0;
  }

  // Can this allocation be discontinued?
  get isDiscontinueable(): boolean {
    const allocationState = this.allocation?.state;
    const hasNonDiscontinueableState = SYSTEM_ONLY_ALLOCATION_STATES.includes(allocationState);
    return !hasNonDiscontinueableState;
  }

  // Do we need to show a disabled version of the Discontinue Allocation button?
  get isDiscontinueAllocationDisabled(): boolean {
    return this.isLoadingAllocation || this.isCreatingAllocation || this.isDiscontinuingOneAllocation || !this.isDiscontinueable;
  }

  /**
   * Return if the last allocation was discontinued
   *
   * @returns {boolean} true if the last allocation was discontinued
   */
  get hasDiscontinuedAllocation(): boolean {
    // Get last rerunnable Allocation for this organCode
    const lastAllocation = this.lastRerunnableAllocationForOrgan;

    // We have no lastAllocation
    if (!lastAllocation) return false;

    // Check if it was discontinued
    return lastAllocation.state.toLowerCase() === DISCONTINUED;
  }

  /**
   * Return if the last allocation was discontinued
   *
   * @returns {boolean} true if the last allocation was discontinued
   */
  get showRunAllocation(): boolean {
    return !this.hasDiscontinuedAllocation;
  }

  /**
   * Return text for the allocation report button
   *
   * @returns {string} text to display for the button
   */
  get allocationReportButtonText(): string {
    return this.isGeneratingAllocationReport ? this.$t('allocation_report_create').toString() : this.$t('allocation_report').toString();
  }

  // Initialize and open the Add Recipient modal
  public addRecipient(): void {
    const addRecipientModal = this.$refs.addRecipientModal as AddRecipientModal;
    addRecipientModal.initialize();
  }

  /**
   * Call the API to generate a download link and forcibly click it
   * for the user.  This link needs to generate every time because
   * the url expires quickly.
   */
  public downloadAllocationReport(hospital_selected: string|undefined): void {
    const payload = {
      allocation_client_id: this.allocation.client_id,
      hospital: hospital_selected,
      organ_code: this.allocation.organ_code,
    };
    // Clear the reportLink url
    this.editState.reportLink = '';
    // Attempt to generate an Allocation Report and download it
    this.$store.dispatch('livingAllocations/generateAllocationReport', { payload }).then((url: any) => {
      // Update the report url
      this.editState.reportLink = url;
      const previousLink = document.getElementById('download-allocation-report');
      // Remove the previous link
      if (previousLink) previousLink.remove();
      // Create and add link to the body
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.setAttribute('target', '_blank');
      downloadLink.setAttribute('id', 'download-allocation-report');
      document.body.appendChild(downloadLink);
      // Click to download in a new tab
      downloadLink.click();
    }).catch((error: any) => {
      console.warn('ERROR: unable to download allocation report', error);
      alert(this.$t('allocation_report_error').toString());
    });
  }

  // Update disabledExclusionRules Set
  public updateExclusionRules(event?: any): void {
    if (event && event.target.value) {
      const rule = event.target.value;
      const disabledExclusionRules = this.editState.disabledExclusionRules;
      if (disabledExclusionRules.includes(rule)) {
        disabledExclusionRules.splice(disabledExclusionRules.indexOf(rule), 1);
      } else {
        disabledExclusionRules.push(rule);
      }
    }
  }

  // Emit event from the Add Recipient modal
  public reloadAllocation(): void {
    this.$emit('reloadAllocations');
  }

  // Build empty allocation pageState when the organ_code param changes
  public buildAllocationPageState() {
    // Commit our initial pageState
    this.$store.commit('pageState/set', {
      pageKey: 'livingAllocations',
      value: {
        disabledExclusionRules: [],
        offer: {
          offerErrorMessage: ''
        },
        discontinue: {},
        modalErrorMessage: '',
        allocationErrorMessage: '',
        reportLink: '',
      }
    });
    this.getExclusionRules();
    this.$store.dispatch('utilities/scrollBehavior');
  }

  // PRIVATE

  // Discontinue allocation and cancel/withdraw all existing offers
  private discontinueAllocation(): void {
    // Before discontinue, provide an alert/confirm dialogue
    const confirmed = confirm(this.$t('discontinue_confirm').toString());
    if (!confirmed) return;
    // Create a payload for discontinuing
    const payload = {
      clientId: this.clientId,
      organCode: this.organCode,
      allocationId: this.allocation._id,
    };
    // Attempt to discontinue the Allocation

    this.$store.dispatch('livingAllocations/discontinueAllocation', payload).then((success: any) => {
      this.buildAllocationPageState();
    }).catch((error: any) => {
      this.editState.allocationErrorMessage = error;
    });
  }

  // Reload all allocations
  private reloadAllocations(): any {
    this.$emit('reloadAllocations');
  }

  // Show confirmation when clicking ok from exclusion filters modal
  private confirmExclusionFilters(): void {
    if (this.hasExclusionRules && this.allocationRulesPayload.length > 0) {
      const confirmed = confirm(this.$t('exclusion_rules_confirm').toString());
      if (!confirmed) return;
    } else if (this.hasExclusionRules && this.allocationRulesPayload.length == 0) {
      const confirmed = confirm(this.$t('no_exclusion_rules_confirm').toString());
      if (!confirmed) return;
    }
    this.toggleModal('exclusionFiltersModal');
  }

  // Toggle a modal based on a ref
  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    if (ref === 'exclusionFiltersModal') {
      // refresh rules
      this.getExclusionRules();
    }
    targetModal.toggleModal();
  }

  // Get Exclusion Rules
  private getExclusionRules() {
    this.$store.dispatch('livingAllocations/getExclusionRules', { clientId: this.clientId, organCode: this.organCode });
  }

  // Return a paylod used to create an Allocation
  private extractAllocationRequestPayload(): any {
    const result = { allocation: { disable_program_exclusion_rules: this.allocationRulesPayload } };
    // Append organ-specific options based on route as needed
    Object.assign(result, this.organAllocationRequestOptions);
    return result;
  }

  /**
   * Return true if Donor Serology is missing and update the modalErrorMessage
   *
   * @param missingSerology string of the missing serology markers
   * @returns {boolean} true if virology markers are missing
   */
  private donorVirologyMissing(missingSerology: string): boolean {
    // TODO: Use lookup for these serology values
    this.editState.modalErrorMessage = `${this.$t('donor_serology_missing', { markers: missingSerology })}`;
    return true;
  }

  /**
   * Return true is Donor HLA Typing isn't complete
   *
   * Check the donor to see if we're missing any HLA.  API provides an array of
   * strings with all the organ codes where HLA typing is incomplete or missing.
   * We also set the modal error message here since the path from incomplete or
   * missing HLA is to run an Expedited Allocation (for specific organs).
   *
   * @returns {boolean} true if HLA is missing or incomplete
   */
  private donorHlaMissing(): boolean {
    const missingHla = this.livingDonor.allocation_must_be_expedited_due_to_missing_hla_typing || [];
    switch(Number(this.organCode)) {
        case OrganCodeValue.Kidney:
        case OrganCodeValue.PancreasWhole:
        case OrganCodeValue.PancreasIslets:
        case OrganCodeValue.Heart:
        case OrganCodeValue.Lung:
          // Check if the missing HLA includes this organCode
          if (missingHla.includes(Number(this.organCode))) {
            // Add missing Hla message to modalErrorMessage
            this.editState.modalErrorMessage = `${this.$t('hla_error')}`;
            return true;
          }
          return false;
          break;
        case OrganCodeValue.VCA:
          // Check if the missing HLA includes this organCode
          if (missingHla.includes(Number(this.organCode))) {
            // Add VCA missing message to modalErrorMessage
            this.editState.modalErrorMessage = `${this.$t('vca_hla_error')}`;
            return true;
          }
          return false;
          break;
      default:
        return false;
        break;
    }
  }

  // Run Standard Allocation
  private runAllocation(): void {
    // Run the allocation
    this.runAllocationService(RUN_ALLOCATION);
  }

  private getAbandonedTask(): string|null {
    const organ_code = this.organCode;
    const organ_consents = this.livingDonor?.organ_consents;
    if (!organ_consents) return null;
    const currentOrgan = organ_consents.find((organ: any) => { return organ.organ_code == organ_code; });
    const runningTasks = currentOrgan ? currentOrgan.running_allocation_tasks : null;
    let runningTask: any;
    let organOption: any;
    Object.keys(runningTasks).forEach((key) => {
      if (runningTasks[key].category == CategoryType.Pending) {
        runningTask = runningTasks[key];
      }

      if(currentOrgan?.organ_code == OrganCodeValue.Kidney) {
        organOption =  runningTasks[key].kp_local ? 'local' : 'provincial'; 
      }
    });
    if (!runningTask) return null;
    if(this.organAllocationOption != organOption) return null;

    const runningTaskId = runningTask?._id;
    return runningTaskId ? runningTaskId.$oid : null;
  }

  private restartAbandonedTask(taskId: string): void {
    this.beginReqest();
    Promise.all([
      this.$store.dispatch('tasks/loadTask', taskId)
    ]).then(() => {
      this.pollAllocationTask().then((response: any) => {
        this.handleTaskCompletion('unknown', response);
        this.buildAllocationPageState();
      }).catch((response: any) => {
        this.handleErrors('unknown', response);
      });
    });
  }

  private pollAllocationTask(): any {
    this.beginReqest();
    const _vm = this as AllocationControls;

    return new Promise(function (resolve, reject) {
      const intervalId = setInterval(() => {
        const currentTaskId = _vm.getCurrentTaskId;
        
        if (!currentTaskId) {
          // stop polling and reject
          _vm.running = false;
          clearInterval(intervalId);
          reject({ success: false, errorMessages: [_vm.$t("validation.messages.missing_task_id")]});
        } else {
          // load task and continue
          _vm.$store.dispatch('tasks/loadTask', currentTaskId)
          .then(() => {
            const task = _vm.getCurrentTask;
            const status = task?.category;
            // if completed, stop otherwise continue
            if (status == CategoryType.Completed) {
              _vm.running = false;
              clearInterval(intervalId);
              resolve(task);
            }
          })
          .catch((error: any) => {
            // stop polling and reject
            _vm.running = false;
            clearInterval(intervalId);
            reject({ success: false, errorMessages: [error.message] });
          });
        }
      }, 1500);
    });
  }

  /**
   * After allocation task has completed, reload allocation summaries and select the allocation.
   *
   * @param actionId actionId string code associated with specific allocation scenario
   * @param task type of task associated with polling allocation recommendation
   */
  private handleTaskCompletion(actionId: string, task: TaskType) {
    if (!this.getTaskError) {
      const responseObject = task && task.response ? JSON.parse(task.response) : null;
      const allocation_id = responseObject.allocation_id;
      this.$store.dispatch('livingAllocations/getAllAllocations', { clientId: this.clientId })
      .then(() => {
        this.$store.commit('tasks/resetTask');
        this.$store.dispatch('livingAllocations/getAllocation', { clientId: this.clientId, organCode: this.organCode, allocationId: allocation_id });
      });
    }
  }

  // Re-run Allocation
  private reRunAllocation(): void {
    // First prompt the user about re-running an Allocation
    const confirmationText = this.$t('rerun_allocation_warning');
    const confirmed = confirm(confirmationText.toString());
    if (!confirmed) return;

    // Re-run the allocation
    this.runAllocationService(RERUN_ALLOCATION);
  }

  /**
   * Derive which vue-x action is needed for the specified allocation type
   *
   * @param allocationType string value representing which allocation type is needed
   * @return {string|null} full path including both module and action if exists, null otherwise
   */
  private getDispatchPath(allocationType: string): string|null {
    let result;
    switch(allocationType) {
      case RUN_ALLOCATION:
        result = 'livingAllocations/runAllocation';
        break;
      case RUN_EXPEDITED_ALLOCATION:
        result = 'livingAllocations/runExpeditedAllocation';
        break;
      case RERUN_ALLOCATION:
        result = 'livingAllocations/reRunAllocation';
        break;
      case RERUN_EXPEDITED_ALLOCATION:
        result = 'livingAllocations/rerunExpeditedAllocation';
        break;
      default:
        result = null;
        break;
    }
    return result;
  }

  /**
   * Initiate an allocation recommendation task
   *
   * NOTE: the specific type of allocation will depend on whether or not the donor has mandatory
   * lab result information (non-expedited vs expedited) and/or has a discontinued allocation for
   * the organ. This function ensures that all of these scenarios will flow throw the same logic.
   *
   * Allocation Types:
   *  - RUN_ALLOCATION
   *  - RUN_EXPEDITED_ALLOCATION
   *  - RERUN_ALLOCATION
   *  - RERUN_EXPEDITED_ALLOCATION
   *
   * @param allocationType string value representing which allocation type is needed
   */  
  private runAllocationService(allocationType: string): void {
    const dispatchPath = this.getDispatchPath(allocationType);
    if (!dispatchPath) return;

    // begin progress bar, reset task and error messages
    this.beginReqest();
    this.$store.commit('tasks/resetTask');
    this.resetErrors();

    // Generate Allocation payload
    const payload = this.extractAllocationRequestPayload();

    const dispatchPayload: any = {
      clientId: this.clientId,
      organCode: this.organCode,
      payload: payload,      
    };

    // if there is a previous re-runnable allocation, attach it's allocation id
    const lastAllocation = this.lastRerunnableAllocationForOrgan;
    if (lastAllocation) {
      dispatchPayload.allocationId = (lastAllocation && lastAllocation._id) ? lastAllocation._id : '';
    }

    this.$store.dispatch(dispatchPath, dispatchPayload).then((response) => {
      this.pollAllocationTask().then((response: any) => {
        this.handleTaskCompletion(allocationType, response);
        this.$store.commit('livingAllocations/stopCreatingAllocation');
        this.buildAllocationPageState();
        this.cleanupAfterReqest();
      }).catch((response: any) => {
        this.$store.commit('livingAllocations/stopCreatingAllocation');
        this.$store.commit('livingAllocations/stopLoading');
        this.handleErrors(allocationType, response);
        this.cleanupAfterReqest();
      });
    }).catch((response) => {
      this.$store.commit('livingAllocations/stopCreatingAllocation');
      this.$store.commit('livingAllocations/stopLoading');
      this.handleErrors(RUN_EXPEDITED_ALLOCATION, response);
      this.cleanupAfterReqest();
    });

    if (allocationType == RUN_EXPEDITED_ALLOCATION || allocationType == RERUN_EXPEDITED_ALLOCATION) {
      // Close the Expedited Allocation Modal
      this.toggleModal('expeditedAllocationModal');
    }
  }

  /**
   * Run whichever type of expedited allocation is needed
   *
   * NOTE: relies on 'lastRerunnableAllocation' getter to determine whether we are
   * in 'run' or 're-run' scenario, based on checking for discontinued allocation
   */
  private checkForPreviousExpeditedAllocation(): void {
    // Get the last rerunnable Allocation
    const lastAllocation = this.lastRerunnableAllocationForOrgan;

    // If there is a prior allocation then re-run instead
    if (lastAllocation) {
      this.runAllocationService(RERUN_EXPEDITED_ALLOCATION);
      return;
    } else {
      this.runAllocationService(RUN_EXPEDITED_ALLOCATION);
    }
  }

  // What do we call the property key from a field-level validation error?
  private translateFieldName(propertyKey: string): string {
    // Check field name in lookup function
    const fieldNameLookup: { [key: string]: string } = this.fieldNameLookup() || {};
    const fieldNameTranslationKey = fieldNameLookup[propertyKey];

    // If no mapping exists, fallback to property key
    if (!fieldNameTranslationKey) return propertyKey;

    // If no translation exists, fallback to the mapped string
    if (!this.$te(fieldNameTranslationKey)) return fieldNameTranslationKey;

    // Return translated display text
    return this.$t(fieldNameTranslationKey).toString();
  }

  // Translate both the key and the value from a field-level validation object entry
  private translateValidationKeyValuePairs(validationErrorObject: { [key: string]: string[] }): string[] {
    const keyValuePairs = Object.entries(validationErrorObject);
    const result = keyValuePairs.map((entry: [string, any]): string => {
      // Translate key
      const translatedFieldName = this.translateFieldName(entry[0]);
      // Translate value
      const translatedError = this.translateError(entry[1], translatedFieldName);
      return `${translatedFieldName}: ${translatedError}`;
    });
    return result;
  }

  // Clear any previously shown error message
  private resetErrors(): void {
    if (!this.editState) return;

    this.editState.allocationErrorMessage = '';
  }

  // Handle errors back from API
  private handleErrors(actionId: string, response: any): void {
    // No response or no response.errorMessages to show
    if (!response && !response?.errorMessages && !response!.validationErrors) return;

    // Validation errors exist within missing_lab_data
    const missingLabData: string[] = response.validationErrors && response.validationErrors.missing_lab_data ? response.validationErrors.missing_lab_data : [];

    // if we have missing lab data, don't show it, defer to popup otherwise show it
    if (missingLabData.length == 0) {
      // Check for field-level validation errors from donor 'validate profile completion' step (see B#15461)
      const validationErrorObject = response.validationErrors || {};
      const translatedErrors = this.translateValidationKeyValuePairs(validationErrorObject);
      if (translatedErrors.length > 0) {
        // Show concatenated error translations
        this.editState.allocationErrorMessage = translatedErrors.join('<br/> ');
      } else {
        // Show top-level error message from Allocation Service
        const responseErrors: string[] = Array.isArray(response.errorMessages) ? response.errorMessages : [response.errorMessages];
        // Do we have any errors
        if (responseErrors.length > 0) {
          // Join error messages with <br>
          this.editState.allocationErrorMessage = responseErrors.join('<br />');
        } else {
          // Show generic error message
          this.editState.allocationErrorMessage = `${this.$t('allocation_service_error')}`;
        }
      }

    }

    // Check for API Service errors first
    if (missingLabData.length > 0) {

      // Translate errors into multiple html lines
      const errors = missingLabData.map((message: string) => {
        return this.translateError(message, null);
      });
      this.editState.modalErrorMessage = errors.join('<br/> ');

      // if includes hla error
      if (missingLabData.includes('must_be_expedited.hla_typing_missing')) {
        // Check if this is a VCA Allocation
        const isVca = Number(this.organCode) === OrganCodeValue.VCA;
        // Show the VCA Error Modal or Expedited Modal
        this.toggleModal(isVca ? 'vcaHlaErrorModal' : 'expeditedAllocationModal');

      // if includes hard stop
      } else if (missingLabData.includes('hard_stop.hbv_surface_ag')) {
        // Donor was missing hpv_surface_ag
        const responseErrors = `${this.$t('hpv_surface_ag_error')}`;
        this.editState.allocationErrorMessage = responseErrors;

      // otherwise
      } else {
        // Show the Expedited Allocation Modal
        this.toggleModal('expeditedAllocationModal');
      }
    }
  }

  public get lastRerunnableAllocationForOrgan(): LivingAllocation|undefined {
    if(!this.$store || !this.$store.state || !this.$store.state.allocations ) {
      return undefined;
    }
    const state = (this.$store.state as any).allocations;
    if (this.organCode == null) return undefined;

    if (state.inactiveAllocations && state.inactiveAllocations.length > 0) {

      // Get all inactive Allocations for the organCode
      const inactiveAllocations = state.inactiveAllocations.find((item: LivingAllocations) => {
        return item.organ_code == Number(this.organCode);
      });

      if (!inactiveAllocations) return undefined;

      const filteredInactiveAllocations = [];

      // For Kindey Allocations we have a mix of Local/Provincial single Kidney or Double Kidney
      switch(Number(this.organCode)) {
        case OrganCodeValue.Kidney:
          // Filter Allocations based on Single or Double Kidney
          const lastKidneyAllocations = inactiveAllocations?.allocations.filter((allocation: LivingAllocation) => {
            // Filter based on the supplied option
            return allocation.allocation_type?.toLowerCase() === this.organAllocationOption;
          });

          // Add our filtered Allocations
          // we only want to add them if we had a kidney allocation, otherwise we end up with
          // an array of array that's empty and messes up provincial/local cause of discounted
          // allocations
          if(lastKidneyAllocations.length > 0) {
            filteredInactiveAllocations.push(lastKidneyAllocations);
          }
          break;
        default:
          // No need to filter any other organs so add all Allocations
          filteredInactiveAllocations.push(inactiveAllocations?.allocations);
          break;
      }

      // We have no allocations
      if (filteredInactiveAllocations.length <= 0) return undefined;

      // Sort by created_at
      const lastAllocation = filteredInactiveAllocations.reduce((prev: any, curr: any) => {
        if (prev && curr) {
          if (prev.created_at && curr.created_at) {
            return prev.created_at < curr.created_at ? curr : prev;
          }
        }
      });
      // Is our last Allocation rerunnable?
      const isAllocationRerunnable = lastAllocation ? !!lastAllocation[0].rerunnable : false;

      return isAllocationRerunnable ? lastAllocation[0] : undefined;
    }
    return undefined;
  }

  public getAllocation() {
    return true;
  }

  // tasks to run before run/re-run/etc of an allocation
  private beginReqest() {
    this.running = true;
    this.$store.commit('tasks/setStatus', this.$t('starting_allocation').toString());
    this.$store.commit('tasks/setPercentage', 0);
  }

  // tasks to run after run/re-run/etc of an allocation
  private cleanupAfterReqest() {
    this.running = false;
  }

  // API property key on the left, field name translation key for UI on the right
  public fieldNameLookup(): { [key: string]: string } {
    return {
      'measurements[0].height' : 'height',
      'measurements[0].weight' : 'weight',
    };
  }
}
</script>
