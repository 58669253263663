import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "donor-summary" }
const _hoisted_2 = { class: "list-inline-item" }
const _hoisted_3 = { class: "list-inline-item" }
const _hoisted_4 = {
  key: 0,
  class: "list-inline-item"
}
const _hoisted_5 = { class: "list-inline-item" }
const _hoisted_6 = { class: "list-inline-item" }
const _hoisted_7 = { class: "list-inline-item" }
const _hoisted_8 = { class: "list-inline-item" }
const _hoisted_9 = { class: "list-inline-item" }
const _hoisted_10 = { class: "list-inline-item" }
const _hoisted_11 = { class: "list-inline-item" }
const _hoisted_12 = { class: "organ-summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_organ_icon = _resolveComponent("organ-icon")!
  const _component_sticky_summary = _resolveComponent("sticky-summary")!

  return (_openBlock(), _createBlock(_component_sticky_summary, null, _createSlots({ _: 2 }, [
    (_ctx.editState)
      ? {
          name: "summaryContent",
          fn: _withCtx(() => [
            _createElementVNode("ul", _hoisted_1, [
              _createElementVNode("li", _hoisted_2, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('name_colon')) + " ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.livingDonorDisplayName), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_3, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('living_donor_id_colon')) + " ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.living_donor_id), 1)
                ])
              ]),
              (_ctx.editState.transplant_program)
                ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                    _createElementVNode("span", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('hospital')) + ": ", 1),
                      _createElementVNode("b", null, _toDisplayString(_ctx.editState.transplant_program), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", _hoisted_5, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('abo')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.abo), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_6, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('sex')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.sex), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_7, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('age')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.age), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_8, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('height')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.height), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_9, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('weight')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.weight), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_10, [
                _createVNode(_component_router_link, {
                  to: { name: 'edit-living-donor', params: { id: _ctx.editState.system_id }},
                  class: "nav-link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('living_donor_information')), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _createElementVNode("li", _hoisted_11, [
                _createVNode(_component_router_link, {
                  to: { name: 'edit-living-donor', params: { id: _ctx.editState.system_id }, hash: '#hla-typing' },
                  class: "nav-link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('hla_information')), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ]),
            _createElementVNode("ul", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedLivingDonorConsentedOrganList, (consentedOrgan, idx) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: idx,
                  class: _normalizeClass(_ctx.listItemClass(consentedOrgan))
                }, [
                  _createVNode(_component_router_link, {
                    to: consentedOrgan.route,
                    class: "nav-link"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_organ_icon, {
                        "organ-code": consentedOrgan.organ_code,
                        "summary-bar-icon": true
                      }, null, 8, ["organ-code"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t(`${consentedOrgan.display_name}`)), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ], 2))
              }), 128))
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}