import Vue from 'vue';
import { MutationTree } from 'vuex';
import { idComparator } from '@/utils';
import { ReportState } from '@/store/reports/types';

export const mutations: MutationTree<ReportState> = {
  setCurrentReport(state, options) {
    state.currentReport = options;
  },
  setTransplantInProgressReportState(state, options) {
    state.TransplantInProgressReportState = options;
  },
  setChangeStatusReport(state, options) {
    state.ChangeStatusReportState = options;
  },
  setCrossmatchReportState(state, options) {
    state.CrossmatchReportState = options;
  },
  setDialysisReportState(state, options) {
    state.DialysisReportState = options;
  },
  setWaitingListReportState(state, options) {
    state.WaitingListReportState = options;
  },
  setAllocationReportState(state, options) {
    state.AllocationReportState = options;
  },
};
