export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "recovery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery Details"])},
        "recovery_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery Information"])},
        "declaration_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaration Hospital"])},
        "recovery_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery Hospital"])},
        "DCD_first_declaration_death_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DCD First Declaration Date of Death"])},
        "DCD_first_declaration_death_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DCD First Declaration Time of Death"])},
        "DCD_second_declaration_death_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DCD Second Declaration Date of Death"])},
        "DCD_second_declaration_death_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DCD Second Declaration Time of Death"])},
        "NDD_first_declaration_death_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NDD First Declaration Date of Death"])},
        "NDD_first_declaration_death_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NDD First Declaration Time of Death"])},
        "NDD_second_declaration_death_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NDD Second Declaration Date of Death"])},
        "NDD_second_declaration_death_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NDD Second Declaration Time of Death"])},
        "cross_clamp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Clamp Date"])},
        "cross_clamp_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Clamp Time"])},
        "organ_recovery_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Recovery Data"])},
        "save_recovery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Recovery Details"])},
        "recovery_organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
        "recovery_recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovered"])},
        "recovery_vivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex Vivo Used"])},
        "recovery_vessels_recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vessels Recovered for Transplant"])},
        "recovery_transplant_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Destination"])},
        "recovery_outofprovince_transplant_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Province Transplant Destination"])},
        "recovery_for_research_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovered for Research Destination"])},
        "recovery_organ_delivery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Delivery Status"])},
        "recovery_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipped"])},
        "recovery_shipping_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Method"])},
        "recovery_shipping_delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
        "recovery_shipping_received_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received by"])},
        "recovery_no_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No organs have been donated yet"])},
        "recovery_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "recovery_organ_transport_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Transport Status"])},
        "recovery_organ_transport_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Shipped"])},
        "recovery_organ_transport_delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Delivered"])},
        "recovery_vessels_for_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vessels for Banking"])},
        "hsp_oop_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSP OOP Export?"])},
        "hsh_oop_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSH OOP Export?"])},
        "export_to_ctr_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to CTR"])},
        "export_to_ctr_success_ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ was successfully shipped to CTR"])},
        "export_to_ctr_success_unship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ was successfully un-shipped to CTR"])},
        "export_to_ctr_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ failed to export to CTR"])},
        "toggle_selection_recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle selection of recovered for"])},
        "for_cooler_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for Cooler Sheet"])},
        "toggle_selection_ex_vivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle selection of ex vivo used for"])},
        "toggle_selection_vessels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle selection of vessels recovered for transplant for"])},
        "drop_down_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop down for"])},
        "out_of_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Province Transplant Destination"])},
        "recovered_for_research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovered for Research Destination"])},
        "date_field_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date field for"])},
        "organ_delivery_status_date_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Delivery Status - Shipped Date"])},
        "time_field_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time field for"])},
        "organ_delivery_status_time_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Delivery Status - Shipped Time (EST)"])},
        "shipping_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Method"])},
        "text_field_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text field for"])},
        "shipping_method_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Method Other"])},
        "organ_delivery_status_date_delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Delivery Status - Delivered Date"])},
        "organ_delivery_status_time_delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Delivery Status - Delivered Time (EST)"])},
        "organ_delivery_received_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Delivery Status - Received By"])},
        "toggle_selection_vessels_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle selection of recovered for Vessels for Banking for Cooler Sheet"])},
        "drop_down_vessels_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop down for Vessels for Banking Destination"])},
        "date_field_transport_shipped_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date field for Vessels for Banking Organ Transport Status - Organ Shipped Date"])},
        "time_field_transport_shipped_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time field for Vessels for Banking Organ Transport Status - Organ Shipped Time (EST)"])},
        "date_field_transport_delivered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date field for Vessels for Banking Organ Transport Status - Organ Delivered Date"])},
        "time_field_transport_delivered_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time field for Vessels for Banking Organ Transport Status - Organ Delivered Time (EST)"])}
      },
      "fr": {
        "recovery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la récupération"])},
        "recovery_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur la récupération"])},
        "declaration_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôpital de déclaration"])},
        "recovery_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôpital de récupération"])},
        "DCD_first_declaration_death_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DCD Première déclaration Date du décès"])},
        "DCD_first_declaration_death_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DCD Première déclaration Heure du décès"])},
        "DCD_second_declaration_death_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DCD Deuxième déclaration Date du décès"])},
        "DCD_second_declaration_death_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DCD Deuxième déclaration Heure du décès"])},
        "NDD_first_declaration_death_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NDD Première déclaration Date du décès"])},
        "NDD_first_declaration_death_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NDD Première déclaration Heure du décès"])},
        "NDD_second_declaration_death_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NDD Deuxième déclaration Date du décès"])},
        "NDD_second_declaration_death_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NDD Deuxième déclaration Heure du décès"])},
        "cross_clamp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la pince croisée"])},
        "cross_clamp_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de serrage croisée"])},
        "organ_recovery_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données sur la récupération des organes"])},
        "save_recovery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les détails de la Récupération"])},
        "recovery_organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe"])},
        "recovery_recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupéré"])},
        "recovery_vivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex Vivo utilisé"])},
        "recovery_vessels_recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaisseaux récupérés pour la transplantation"])},
        "recovery_transplant_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination de la transplantation"])},
        "recovery_outofprovince_transplant_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination de la transplantation en dehors de la province"])},
        "recovery_for_research_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupéré pour la recherche Destination"])},
        "recovery_organ_delivery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État de la livraison des organes"])},
        "recovery_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé à"])},
        "recovery_shipping_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode d'expédition"])},
        "recovery_shipping_delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livré"])},
        "recovery_shipping_received_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu par"])},
        "recovery_no_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun organe n'a encore été donné"])},
        "recovery_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "recovery_organ_transport_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de transport d'Organes"])},
        "recovery_organ_transport_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe expédié"])},
        "recovery_organ_transport_delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe livré"])},
        "recovery_vessels_for_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaisseaux pour le stockage"])},
        "hsp_oop_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter HSP OOP?"])},
        "hsh_oop_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter HSH OOP?"])},
        "export_to_ctr_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter vers le CTR"])},
        "export_to_ctr_success_ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'orgue a été exporté avec succès vers CTR"])},
        "export_to_ctr_success_unship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'organe a été dé-expédié avec succès au CTR"])},
        "export_to_ctr_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'exportation de l'organe vers le CTR"])},
        "toggle_selection_recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la selection des récupérés pour"])},
        "for_cooler_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour feuille de refroidisseur"])},
        "toggle_selection_ex_vivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la selection de ex vivo utilisé pour"])},
        "toggle_selection_vessels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la selection des vaisseaux récupérés pour être transplantés pour"])},
        "drop_down_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste déroulante pour"])},
        "out_of_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination de transplantation hors province"])},
        "recovered_for_research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérés pour la destination de recherche"])},
        "date_field_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ de date pour"])},
        "organ_delivery_status_date_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la livraison de l'oragne - Date d'expédition"])},
        "time_field_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ d'heure pour"])},
        "organ_delivery_status_time_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la livraison de l'oragne - Heure d'expédition"])},
        "shipping_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode d'expédition"])},
        "text_field_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ de texte pour"])},
        "shipping_method_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre méthode d'expédition"])},
        "organ_delivery_status_date_delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la livraison de l'oragne - Date de livraison"])},
        "organ_delivery_status_time_delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la livraison de l'organe - Heure de livraison (EST)"])},
        "organ_delivery_received_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de livraison de l'organe - Reçu par"])},
        "toggle_selection_vessels_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la sélection de récupéré pour les vaisseaux pour la banque pour la feuille de refroidisseur"])},
        "drop_down_vessels_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste déroulante des vaisseaux pour la destination de la banque"])},
        "date_field_transport_shipped_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ de date pour les vaisseaux pour le statut de transport d'organes bancaires - Date d'expédition de l'organe"])},
        "time_field_transport_shipped_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ d'heure pour les vaisseaux pour le statut de transport d'organes bancaires - Heure d'expédition des organes (EST)"])},
        "date_field_transport_delivered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ de date pour les vaisseaux pour le statut de transport d'organes bancaires - Date de livraison de l'organe"])},
        "time_field_transport_delivered_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ d'heure pour les vaisseaux pour le statut de transport d'organes bancaires - Heure de livraison des organes (EST)"])}
      }
    }
  })
}
