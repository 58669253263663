import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    title: _ctx.getOfferAttributes.name,
    class: _normalizeClass(_ctx.getOfferAttributes.style)
  }, [
    (_ctx.getOfferAttributes.faIcon)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: ['fas', _ctx.getOfferAttributes.faIcon],
          "fixed-width": ""
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    (!_ctx.getOfferAttributes.faIcon)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(_toDisplayString(_ctx.getOfferAttributes.text), 1)
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}