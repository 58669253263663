<template>
  <card-section
    section-id="transplant-details"
    :lookupsToLoad="lookupsToLoad"
    @loaded="loaded()"
    >
    <template v-slot:header>
      {{ $t('transplant_details') }}
    </template>
    <template v-slot:body>
      <sub-section
        :title="$t('transplant_details')"
        sub-section-id="sub-transplant-details"
      >
        <template v-slot:contents>
          <loading-fields v-if="isLoading || !editState" />

          <validation-observer ref="validations">
          <form-layout
            :disabled="!enableForm"
            form-id="blood_type_form">
            <template v-slot:contents>
              <div class="row">
                <div class="standard-form-group">
                  <date-input
                    ruleKey="transplant_details.admission_datetime"
                    inputId="admission_date"
                    :name="$t('date_of_admission')"
                    v-model="editState.admission_date"
                  />
                </div>
                <div class="standard-form-group">
                  <time-input
                    ruleKey="transplant_details.admission_datetime"
                    inputId="admission_time"
                    :name="$t('time_of_admission')"
                    v-model="editState.admission_time"
                    />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group">
                  <SelectInput
                    ruleKey="transplant_details.organ_source"
                    selectId="organ_source"
                    :name="$t('organ_source')"
                    v-model="editState.organ_source"
                    :options="organ_source"
                  />
                </div>
                <div class="standard-form-group">
                  <SelectInput
                    ruleKey="transplant_details.specific_organ_transplanted"
                    selectId="specific_organ_transplanted"
                    :name="$t('specific_organ_transplanted')"
                    v-model="editState.specific_organ_transplanted"
                    :options="getSpecificOrganLookup"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group">
                  <text-input
                    ruleKey="transplant_details.odo_donor_id"
                    inputId="donor_identifier"
                    :name="$t('donor_identifier')"
                    v-model="editState.odo_donor_id"
                  />
                </div>
                <div class="standard-form-group">
                  <SelectInput
                    ruleKey="transplant_details.organ_donation_organization"
                    selectId="organ_donation_organization"
                    :name="$t('donor_odo')"
                    v-model="editState.odo"
                    :options="organ_donation_organization"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group">
                  <date-input
                    ruleKey="transplant_details.donor_cross_clamp_datetime"
                    inputId="donor_cross_clamp_date"
                    :name="$t('donor_cross_clamp_date')"
                    v-model="editState.donor_cross_clamp_date"
                  />
                </div>
                <div class="standard-form-group">
                  <time-input
                    ruleKey="transplant_details.donor_cross_clamp_datetime"
                    inputId="donor_cross_clamp_time"
                    :name="$t('donor_cross_clamp_time')"
                    v-model="editState.donor_cross_clamp_time"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group">
                  <date-input
                    ruleKey="transplant_details.cold_preservation_start_datetime"
                    inputId="cold_preservation_start_date"
                    :name="$t('cold_preservation_start_date')"
                    v-model="editState.cold_preservation_start_date"
                  />
                </div>
                <div class="standard-form-group">
                  <time-input
                    ruleKey="transplant_details.cold_preservation_start_datetime"
                    inputId="cold_preservation_start_time"
                    :name="$t('cold_preservation_start_time')"
                    v-model="editState.cold_preservation_start_time"
                  />
                </div>
                <div class="standard-form-group">
                  <date-input
                    ruleKey="transplant_details.cold_preservation_end_datetime"
                    inputId="cold_preservation_end_date"
                    :name="$t('cold_preservation_end_date')"
                    v-model="editState.cold_preservation_end_date"
                  />
                </div>
                <div class="standard-form-group">
                  <time-input
                    ruleKey="transplant_details.cold_preservation_end_datetime"
                    inputId="cold_preservation_end_time"
                    :name="$t('cold_preservation_end_time')"
                    v-model="editState.cold_preservation_end_time"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group-6column-xlarge-only">
                  <text-input
                    inputId="cold_isch_time"
                    :name="$t('cold_ischemia_time')"
                    :disabled="true"
                    :append="true"
                    :append-text="$t('minutes_unit')"
                    :guidingText="true"
                    :showToolTip="true"
                    :toolTipText="$t('cold_ischemia_time_tooltip')"
                    v-model="editState.cold_ischemia_minutes"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group">
                  <SelectInput
                    ruleKey="transplant_details.perfusion_device_status"
                    selectId="perfusion_device_status"
                    :name="$t('perfusion_device_status')"
                    v-model="editState.perfusion_device_status"
                    :options="perfusion_device_status"
                    :onChange="onChangePerfusionDeviceStatus()"
                  />
                </div>
                <div class="standard-form-group" v-if="editState.perfusion_device_status === 'yes'">
                  <SelectInput
                    ruleKey="transplant_details.perfusion_device_used"
                    selectId="perfusion_device_used"
                    :name="$t('perfusion_device_used')"
                    v-model="editState.perfusion_device_used"
                    :options="perfusion_device"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group">
                  <date-input
                    ruleKey="transplant_details.reperfusion_datetime"
                    inputId="reperfusion_date"
                    :name="$t('reperfusion_date')"
                    v-model="editState.reperfusion_date"
                  />
                </div>
                <div class="standard-form-group">
                  <time-input
                    ruleKey="transplant_details.reperfusion_datetime"
                    inputId="reperfusion_time"
                    :name="$t('reperfusion_time')"
                    v-model="editState.reperfusion_time"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group">
                  <date-input
                    ruleKey="transplant_details.transplant_start_datetime"
                    inputId="transplant_start_date"
                    :name="$t('transplant_start_date')"
                    v-model="editState.transplant_start_date"
                  />
                </div>
                <div class="standard-form-group">
                  <time-input
                    ruleKey="transplant_details.transplant_start_datetime"
                    inputId="transplant_start_time"
                    :name="$t('transplant_start_time')"
                    v-model="editState.transplant_start_time"
                  />
                </div>
                <div class="standard-form-group">
                  <date-input
                    ruleKey="transplant_details.transplant_end_datetime"
                    inputId="transplant_end_date"
                    :name="$t('transplant_end_date')"
                    v-model="editState.transplant_end_date"
                  />
                </div>
                <div class="standard-form-group">
                  <time-input
                    ruleKey="transplant_details.transplant_end_datetime"
                    inputId="transplant_end_time"
                    :name="$t('transplant_end_time')"
                    v-model="editState.transplant_end_time"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group">
                  <date-input
                    ruleKey="transplant_details.anastomosis_datetime"
                    inputId="anastomosis_date"
                    :name="$t('anastomosis_date')"
                    v-model="editState.anastomosis_date"
                  />
                </div>
                <div class="standard-form-group">
                  <time-input
                    ruleKey="transplant_details.atastomosis_datetime"
                    inputId="atastomosis_time"
                    :name="$t('anastomosis_time')"
                    v-model="editState.anastomosis_time"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group">
                  <SelectInput
                    ruleKey="transplant_details.transplant_complete"
                    selectId="transplant_complete"
                    :name="$t('organ_transplanted')"
                    v-model="editState.transplant_complete"
                    :options="getYesNoLookup"
                    :onChange="onChangeTransplantComplete()"
                  />
                </div>
                <div class="standard-form-group" v-if="editState.transplant_complete === 'no'">
                  <SelectInput
                    ruleKey="transplant_details.not_transplanted_reason"
                    selectId="not_transplanted_reason"
                    :name="$t('not_transplanted_reason')"
                    v-model="editState.not_transplanted_reason"
                    :options="not_transplanted_reason"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group">
                  <SelectInput
                    ruleKey="transplant_details.surgical_complications"
                    selectId="surgical_complications"
                    :name="$t('surgical_complications')"
                    v-model="editState.surgical_complications"
                    :options="getYesNoLookup"
                    :onChange="onChangeSurgicalComplications()"
                  />
                </div>
                <div class="standard-form-group" v-if="editState.surgical_complications === 'yes'">
                  <SelectInput
                    ruleKey="transplant_details.clavien_dindo_classification"
                    selectId="clavien_dindo_classification"
                    :name="$t('clavien_dindo_classification')"
                    v-model="editState.clavien_dindo_classification"
                    :options="clavien_dindo_classification"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group">
                  <SelectInput
                    ruleKey="transplant_details.intra_operative_death"
                    selectId="intra_operative_death"
                    :name="$t('intra_operative_death')"
                    v-model="editState.intra_operative_death"
                    :options="getYesNoLookup"
                  />
                </div>
                <div class="standard-form-group">
                  <date-input
                    ruleKey="transplant_details.discharge_date"
                    inputId="discharge_date"
                    :name="$t('discharge_date')"
                    v-model="editState.discharge_date"
                  />
                </div>
                <div class="standard-form-group">
                  <SelectInput
                    ruleKey="transplant_details.follow_up_provider"
                    selectId="follow_up_provider"
                    :name="$t('provider_of_followup_care')"
                    v-model="editState.follow_up_provider"
                    :options="follow_up_provider"
                  />
                </div>
              </div>
            </template>

            <template v-slot:save>
              <save-toolbar
                v-if="showSaveToolbar"
                ref="saveTransplantDetails"
                class="card-footer action-row temp-saving row"
                :label="$t('save_transplant_details')"
                :cancelButton="true"
                @save="handleSave()"
                @cancel="handleCancel()"
              />
            </template>      
          </form-layout>      
          </validation-observer>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/TransplantDetails.json"></i18n>

<script lang="ts">
import { Component, Prop, mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { SaveResult, APIPermittedActions, APIResponseWithPermittedActions } from "@/types";
import { IdLookup } from '@/store/validations/types';
import { EP } from '@/api-endpoints';
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from "@/components/shared/SaveToolbar.vue";
import { UIRecipient } from '@/UIModels/recipient';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UITransplantDetails } from "@/UIModels/journey/transplantDetails";
import LoadingFields from '@/components/shared/LoadingFields.vue';
import { i18nMessages } from "@/i18n";
import { parseFormErrors } from '@/utils';
import { UIJourney } from "@/UIModels/journey";
import DateInput from '@/components/shared/DateInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import TimeInput from "@/components/shared/TimeInput.vue";
import TextInput from "@/components/shared/TextInput.vue";

@Component({
  components: {
    CardSection,
    SubSection,
    FormLayout,
    SaveToolbar,
    LoadingFields,
    DateInput,
    SelectInput,
    TimeInput,
    TextInput
  },
  ...i18nMessages([
    require('./_locales/common.json'),
    require('./_locales/TransplantDetails.json'),
  ]),
  emits: [
    'loaded',
  ],
})
export default class TransplantDetails extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.clavien_dindo_classification) private clavien_dindo_classification!: any[];
  @State(state => state.lookups.follow_up_provider) private follow_up_provider!: any[];
  @State(state => state.lookups.not_transplanted_reason) private not_transplanted_reason!: any[];
  @State(state => state.lookups.organ) private organ!: any[];
  @State(state => state.lookups.organ_donation_organization) private organ_donation_organization!: any[];
  @State(state => state.lookups.organ_source) private organ_source!: any[];
  @State(state => state.lookups.perfusion_device) private perfusion_device!: any[];
  @State(state => state.lookups.perfusion_device_status) private perfusion_device_status!: any[];

  @Getter('clientId', { namespace: 'recipients' }) clientId!: string|undefined;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('checkPropExists', { namespace: 'validations' }) checkPropExists!: (ruleKey: string) => boolean;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [
    'clavien_dindo_classification', 
    'follow_up_provider',
    'not_transplanted_reason',
    'organ',
    'organ_donation_organization',
    'organ_source',
    'perfusion_device',
    'perfusion_device_status'
  ];

  private isLoading = true;

  // Immutable view model based on current recipient (e.g for cancel)
  private selection = new UITransplantDetails();

  // Editable view model for the form
  private editState = this.selection.copyViewModel();

  private onChangePerfusionDeviceStatus(): void {
    if (this.editState.perfusion_device_status !== 'yes') {
      this.editState.perfusion_device_used = null;
    }
  }

  private onChangeTransplantComplete(): void {
    if (this.editState.transplant_complete !== 'no') {
      this.editState.not_transplanted_reason = null;
    }
  }

  private onChangeSurgicalComplications(): void {
    if (this.editState.surgical_complications !== 'yes') {
      this.editState.clavien_dindo_classification = null;
    }
  }

  private get getSpecificOrganLookup(): any[] {
    const organCode = this.currentJourney.organ_code;
    if (!this.organ_source) return [];

    const specificOrgan = this.organ.find((organ: any) => {
      return organ.code === organCode;
    });

    const specificOrganLookup = specificOrgan?.sub_tables?.specific_organ || [];
 
    return specificOrganLookup;
  }

  private get getYesNoLookup(): { code: string, value: string }[] {
    const options = [
      { code: 'yes', value: this.$t('yes') },
      { code: 'no', value: this.$t('no') }
    ];
    return options;
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  private get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // Which Journey view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  private get currentJourney(): UIJourney {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentJourney as UIJourney;
  }

  // Can we enable the form?
  private get enableForm(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Can we show the save toolbar?
  private get showSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Check permitted actions list
  private get permittedActionsAllowCreateOrUpdate(): boolean {
    const permitted_actions = this.editState.permitted_actions;
    // First we check special case, on #new endpoint permitted_actions is an empty array
    if (permitted_actions.length === 0) return true;

    // We have a list of permitted actions, so now we can check for "update" keyword
    return permitted_actions.includes(APIPermittedActions.Update);
  }

  // Load #validations
  private queryRules(): Promise<APIResponseWithPermittedActions> {
    return this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.journeys.transplant.show,
      payload: [[':recipientId', this.currentRecipient.clientId], [':journeyId', this.currentJourney.journeyId]],
      prefix: 'transplant_details',
    });
  }

  // Resets Form Errors
  public resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  private async mounted(): Promise<void> {
    if (this.currentJourney) {
      try {
        await this.currentJourney.load();
        const uiTransplantDetails = this.currentJourney.transplantDetails;
        if (uiTransplantDetails) this.selection = uiTransplantDetails.copyViewModel();
        this.initializeForm();
        await this.queryRules();
        this.isLoading = false;
      } catch(err) {
        this.isLoading = false;
        console.warn(err, 'Something unexpected happened when attempting to get blood type information');
      }
    }
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens TransplantDetails#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.$emit('loaded', 'transplant_details');
  }

  // Process save button click event
  private async handleSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();

    const params = {
      recipient: this.currentRecipient,
      journey: this.currentJourney
    };

    try {
      const success: SaveResult = await this.editState.save(params);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Process successful save result
  private handleSuccess(success: SaveResult): void {
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);

    // re-load data
    const uiTransplantDetails = this.currentJourney.transplantDetails || null;
    if (uiTransplantDetails) this.selection = uiTransplantDetails.copyViewModel();

    // reset form errors
    this.resetFormErrors();
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  // Reset edit state based on selected address for cancel button click event
  private handleCancel(): void {
    this.initializeForm();
    this.resetSaveToolbar();
  }

  /**
   * Populates the Recipient Death form state with data from the selected Recipient.
   */
   public initializeForm(): void {
    this.editState = this.selection.copyViewModel();
    this.resetFormErrors();
  }

  // Clears all save notifications shown by the form.
  public resetSaveToolbar(): void {
    this.saveToolbar.reset();
  }
  
  // Reference to the form's save toolbar
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveTransplantDetails as unknown as SaveToolbar;
  }

  public idLookup(): IdLookup {
    const result = {
      'transplant_details.admission_datetime'                : ['admission_date', 'admission_time'],
      'transplant_details.organ_source'                      : 'organ_source',
      'transplant_details.specific_organ_transplanted'       : 'specific_organ_transplanted',
      'transplant_details.odo_donor_id'                      : 'donor_identifier',
      'transplant_details.organ_donation_organization'       : 'organ_donation_organization',
      'transplant_details.donor_cross_clamp_datetime'        : ['donor_cross_clamp_date', 'donor_cross_clamp_time'],
      'transplant_details.cold_preservation_start_datetime'  : ['cold_preservation_start_date', 'cold_preservation_start_time'],
      'transplant_details.cold_preservation_end_datetime'    : ['cold_preservation_end_date', 'cold_preservation_end_time'],
      'transplant_details.perfusion_device_status'           : 'perfusion_device_status',
      'transplant_details.perfusion_device_used'             : 'perfusion_device_used',
      'transplant_details.reperfusion_datetime'              : ['reperfusion_date', 'reperfusion_time'],
      'transplant_details.transplant_start_datetime'         : ['transplant_start_date', 'transplant_start_time'],
      'transplant_details.transplant_end_datetime'           : ['transplant_end_date', 'transplant_end_time'],
      'transplant_details.anastomosis_datetime'              : ['anastomosis_date', 'atastomosis_time'],
      'transplant_details.transplant_complete'               : 'transplant_complete',
      'transplant_details.not_transplanted_reason'           : 'not_transplanted_reason',
      'transplant_details.surgical_complications'            : 'surgical_complications',
      'transplant_details.clavien_dindo_classification'      : 'clavien_dindo_classification',
      'transplant_details.intra_operative_death'             : 'intra_operative_death',
      'transplant_details.discharge_date'                    : 'discharge_date',
      'transplant_details.follow_up_provider'                : 'follow_up_provider',
    };
    return result;
  }
}
</script>

