export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "table": {
          "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
          "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
          "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
          "select_all_matching_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all matching items"])}
        },
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
        "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle selection of this row"])},
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo"])},
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Recipient Page"])}
      },
      "fr": {
        "table": {
          "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
          "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
          "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])},
          "select_all_matching_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner tous les éléments correspondants"])}
        },
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours…"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sélectionner"])},
        "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la sélection de cette ligne"])},
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo"])},
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page du patient"])}
      },
      "en_tgln": {
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Allocation and Transplant System"])}
      },
      "fr_tgln": {
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système d'attribution et de transplantation d'organes"])}
      }
    }
  })
}
