<template>
  <div>
    <template v-if="showNotesFilter">
      <div class="filteritem">
        <span>
          <date-input input-id="added_date_from" :name="$t('date_added_from')" v-model="selectedFilters.date_from" />
        </span>
      </div>
      <div class="filteritem">
        <span>
          <date-input input-id="added_date_to" :name="$t('date_added_to')" v-model="selectedFilters.date_to" />
        </span>
      </div>
      <div class="filteritem">
        <span>
          <select-input selectId="users" :name="$t('author')" v-model="selectedFilters.user_id" :options="authorOptions" />
        </span>        
      </div>
      <div class="filteritem">
        <span>
          <select-input selectId="tags" :name="$t('note_tags')" v-model="selectedFilters.note_tags" :options="tagOptions" />
        </span>        
      </div>
      <div class="filteritem">
        <span>
          <text-input inputId="search" :name="$t('keywords')" v-model="selectedFilters.keywords" :placeHolder="$t('search')" />
        </span>        
      </div>
      
    </template>
    <template v-if="showChecklistFilter">
      <div class="filteritem">
        <span>
          <select-input selectId="checklist_status" :name="$t('status')" v-model="selectedFilters.checklist_status" :options="statusOptions" />
        </span>        
      </div>
      <div class="filteritem last" v-if="excludeCompleted">
        <div class="form-check form-check-inline">
          <input :id="`${fieldID}-completed`" v-model="selectedFilters.exclude_completed" :aria-labelledby="`${fieldID}-completed`"
            type="checkbox" class="form-check-input">
          <label :for="`${fieldID}-completed`" class="form-check-label">
            <span> {{ $t('exclude_completed') }} </span>
          </label>
        </div>
      </div>
    </template>

    <div class="filteritem last" v-if="showArchived">
      <div class="form-check form-check-inline">
        <input :id="`${fieldID}-archived`" v-model="selectedFilters.include_archived" :aria-labelledby="`${fieldID}-archived`"
          type="checkbox" class="form-check-input">
        <label :for="`${fieldID}-archived`" class="form-check-label">
          <span> {{ $t('include_archived') }} </span>
        </label>
      </div>      
    </div>
    <div class="action-row">
    
      <button type="button" class="btn btn-primary-outline btn-sm" @click.prevent="saveFilters()">
        {{ $t('filter') }}
      </button>
      <button type="button" class="btn btn-secondary-outline btn-sm ml-2" @click.prevent="clearFilters()">
        {{ $t('clear_filter') }}
      </button>
    </div>
    <div class="filter-spacer filterShowLarge" v-if="!hideSpacer" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import TextInput from '@/components/shared/TextInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import { GenericCodeValue } from '@/store/types';
import { UIFilterAssignments } from '@/store/recipientJourney/types';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    TextInput,
    SelectInput,
    DateInput
  },
  ...i18nMessages([
    require('@/components/shared/filter/FilterComponent.json'),
  ]),
  emits: [
    'setFilters',
    'clearFilters',
  ]
})
export default class FilterLinks extends Vue {
  @Prop({ required: true }) fieldID!: string;
  @Prop({ default: false }) showNotesFilter!: boolean;
  @Prop({ default: false }) showChecklistFilter!: boolean;
  @Prop({ default: () => { return [] } }) tagOptions!: GenericCodeValue[];
  @Prop({ default: () => { return [] } }) authorOptions!: GenericCodeValue[];
  @Prop({ default: () => { return [] } }) statusOptions!: GenericCodeValue[];
  @Prop({ default: false }) showArchived!: boolean;
  @Prop({ default: false }) excludeCompleted!: boolean;
  @Prop({ default: false }) hideSpacer!: boolean;
  @Prop({ default: () => { return {} } }) selectedFilters!: UIFilterAssignments;

  private saveFilters(): void {
    this.$emit('setFilters', this.selectedFilters);
  }

  private clearFilters(): void {
    this.$emit('clearFilters');
  }
}
</script>
