import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card tip-card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "media align-items-center" }
const _hoisted_4 = { class: "media-left" }
const _hoisted_5 = { class: "media-body flex-row align-items-center" }
const _hoisted_6 = { class: "tip-report-name" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, { subSectionId: "transplant-activity-report" }, _createSlots({ _: 2 }, [
    (_ctx.editState)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'file-chart-line'],
                      "fixed-width": ""
                    })
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('transplant_activity_report')), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_select_input, {
                      selectId: "transplant_activity-organ",
                      name: _ctx.$t('organ'),
                      modelValue: _ctx.editState.organ,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.organ) = $event)),
                      options: _ctx.filterOrgansByUser,
                      multiple: true,
                      placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.organ),
                      "max-tags": 4
                    }, null, 8, ["name", "modelValue", "options", "placeholder"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_select_input, {
                      selectId: "transplant_activity-transplant-program",
                      name: _ctx.$t('transplant_program'),
                      modelValue: _ctx.editState.hospital,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.hospital) = $event)),
                      options: _ctx.filteredHospitalsByUser,
                      multiple: true,
                      placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.hospital)
                    }, null, 8, ["name", "modelValue", "options", "placeholder"])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_date_input, {
                      inputId: "transplant_activity-start_date",
                      name: _ctx.$t('start_date'),
                      modelValue: _ctx.editState.startDate,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.startDate) = $event)),
                      rules: "required"
                    }, null, 8, ["name", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_date_input, {
                      inputId: "transplant_activity-end_date",
                      name: _ctx.$t('end_date'),
                      modelValue: _ctx.editState.endDate,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.endDate) = $event)),
                      rules: "required"
                    }, null, 8, ["name", "modelValue"])
                  ]),
                  _createVNode(_component_save_toolbar, {
                    class: "form-inline col-sm-12 action-row",
                    buttonClass: "btn btn-success",
                    ref: "generateReport",
                    savingText: _ctx.$t('saving_text'),
                    successText: _ctx.$t('success_text'),
                    defaultErrorText: _ctx.$t('default_error_text'),
                    onSave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.generateReport()))
                  }, {
                    label: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['far', 'file-chart-line'],
                        class: "mr-2"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('generate_report')), 1)
                    ]),
                    _: 1
                  }, 8, ["savingText", "successText", "defaultErrorText"])
                ])
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}