<template>
  <div class="content-wrap page-top">
    <div class="container-fluid">
      <h2>
        <slot />
      </h2>
      <div class="page-top-right">
        <div class="system-title d-inline">
          {{ uiEnvVersion }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { State } from 'vuex-facing-decorator';
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class PageTop extends Vue {
  @State(state => state.uiEnvVersion) private uiEnvVersion!: string|null;
}
</script>
