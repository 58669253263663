import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["action"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.config && _ctx.config.url)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        action: _ctx.config.url,
        onSubmit: _cache[0] || (_cache[0] = (event) => _ctx.prepareRequest('oneid')),
        method: "post"
      }, [
        _createElementVNode("input", {
          type: "submit",
          value: _ctx.$t(_ctx.config.title || ''),
          class: "btn btn-success login float-right"
        }, null, 8, _hoisted_2)
      ], 40, _hoisted_1))
    : _createCommentVNode("", true)
}