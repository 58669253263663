import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["title", "onClick", "disabled"]
const _hoisted_3 = { key: 4 }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_table_checkbox_input = _resolveComponent("table-checkbox-input")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_confirm_recipient_consent_modal = _resolveComponent("confirm-recipient-consent-modal")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "exceptional-distribution-disposition",
    "lookups-to-load": _ctx.lookupsToLoad,
    onLoaded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('exceptional_distribution_disposition')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        "sub-section-id": "exceptional-distribution-confirmations",
        ref: "exdConfirmations",
        "table-config": _ctx.exdConfirmationsTableConfig,
        "row-style-class": _ctx.rowStyleClass
      }, {
        "table-cell": _withCtx((props) => [
          (props.column.field == 'selected')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (props.row.expired)
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 0,
                      icon: ['far', 'exclamation-circle'],
                      title: _ctx.$t('expired_post_release'),
                      alt: _ctx.$t('expired_post_release'),
                      "fixed-width": "",
                      class: "text-grey ml-3"
                    }, null, 8, ["title", "alt"]))
                  : _createCommentVNode("", true),
                (_ctx.editState && _ctx.editState.selection && props.row.selectable)
                  ? (_openBlock(), _createBlock(_component_table_checkbox_input, {
                      key: 1,
                      "input-id": `exd-confirmation-select-${props.row.id}`,
                      modelValue: _ctx.editState.selection[props.row.id],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState.selection[props.row.id]) = $event),
                      label: `${_ctx.$t('toggle_selection')} ${props.row.organ} ${_ctx.$t('acceptance')}`
                    }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label"]))
                  : _createCommentVNode("", true)
              ], 64))
            : (props.column.field == 'recipientLinkText' && props.row.recipientClientId)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  class: "table-link",
                  to: { name: 'edit-recipient', params: { id: props.row.recipientClientId } },
                  title: _ctx.$t('link_to_recipient_page'),
                  alt: props.row.recipientLinkText,
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.formattedRow[props.column.field]), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "title", "alt"]))
              : (props.column.field == 'confirmationProvided')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    title: props.row.confirmationProvidedTime
                  }, _toDisplayString(props.formattedRow[props.column.field]), 9, _hoisted_1))
                : (props.column.field == 'save')
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 3,
                      type: "button",
                      class: "btn btn-primary btn-sm",
                      title: props.row.expired ? _ctx.$t('expired_post_release') : `${_ctx.$t('download')} ${props.row.organ} ${_ctx.$t('exd_form')}`,
                      onClick: ($event: any) => (_ctx.printOneForm(props.row.id)),
                      disabled: props.row.expired
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['far', 'download'],
                        "fixed-width": ""
                      })
                    ], 8, _hoisted_2))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(props.formattedRow[props.column.field] != null ? props.formattedRow[props.column.field] : '-'), 1))
        ]),
        contents: _withCtx(() => [
          _createVNode(_component_save_toolbar, {
            class: "action-row row temp-saving card-footer",
            "button-class": "btn btn-wide btn-primary mt-2 mr-2",
            ref: "printExdForms",
            "saving-text": _ctx.$t('generating_exd_forms'),
            "success-text": _ctx.$t('exd_forms_generated'),
            "default-error-text": _ctx.$t('problem_generating_exd_form'),
            onSave: _ctx.printSelectedForms,
            disabled: !_ctx.canPrintSelectedForms,
            "button-title": _ctx.$t('download_all_selected')
          }, {
            actions: _withCtx(() => [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-success mt-2 mr-2",
                disabled: !_ctx.canConfirmExdAcceptance,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirmExdAcceptance && _ctx.confirmExdAcceptance(...args)))
              }, _toDisplayString(_ctx.$t('confirm_recipient_consent')), 9, _hoisted_4)
            ]),
            label: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                class: "mr-2",
                icon: ['far', 'download'],
                "fixed-width": ""
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('save_exd_forms')), 1)
            ]),
            _: 1
          }, 8, ["saving-text", "success-text", "default-error-text", "onSave", "disabled", "button-title"]),
          _createVNode(_component_confirm_recipient_consent_modal, {
            ref: "confirmRecipientConsentModal",
            onReload: _ctx.reload
          }, null, 8, ["onReload"])
        ]),
        _: 1
      }, 8, ["table-config", "row-style-class"])
    ]),
    _: 1
  }, 8, ["lookups-to-load"]))
}