
import { APIReferralAttributes } from '@/store/recipientJourney/types';
import { parseDateUi, sanitizeDateApi } from '@/utilities/date-utils';
import { UIRecipient } from '@/UIModels/recipient';
import { UIJourney } from '@/UIModels/journey';
import { SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APISaveResponse } from '@/types';
import { CountryValue } from '@/store/lookups/types';
import { sanitizePhoneNumber } from '@/utils';

export class UIReferralDetails {
  public apiSource?: APIReferralAttributes;

  public receivedDate: string|null = null;
  public referralSource: string|null = null;

  public primaryDiagnosisCode: string|null = null;
  public primaryDiagnosisDate: string|null = null;
  public secondaryDiagnosisCode: string|null = null;
  public secondaryDiagnosisDate: string|null = null;

  public referringOrganizationName: string|null = null;
  public referringOrganizationType: string|null = null;

  public referringProfessionalType: string|null = null;
  public firstName: string|null = null;
  public lastName: string|null = null;

  public country: string|null = null;
  public otherCountry: string|null = null;
  public province: string|null = null;
  public state: string|null = null;
  public streetAddress: string|null = null;
  public city: string|null = null;
  public postalCode: string|null = null;
  public zipCode: string|null = null;
  public urbanization: string|null = null;

  public phoneNumber: string|null = null;
  public faxNumber: string|null = null;
  public emailAddress: string|null = null;

  public comments: string|null = null;

  // Postal Code is only for Canada
  get showPostalCode(): boolean {
    return this.country === CountryValue.Canada;
  }

  // ZIP Code is only for USA
  get showZipCode(): boolean {
    return this.country === CountryValue.USA;
  }

  // Urbanization is only for Puerto Rico
  get showUrbanization(): boolean {
    return this.state === 'PR';
  }

  public constructor(apiReferral?: APIReferralAttributes) {
    if (apiReferral) this.updateFromAPIResponse(apiReferral);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiReferral: APIReferralAttributes) {
    this.apiSource = apiReferral;

    // Fetch nested documents in a type-safe way
    const apiReferralDetails: APIReferralAttributes = apiReferral || {
      received_date: null,
      referral_source: null,
      primary_diagnosis_code: null,
      primary_diagnosis_date: null,
      secondary_diagnosis_code: null,
      secondary_diagnosis_date: null,
      referrer: {
        professional: {
          type_code: null,
          first_name: null,
          last_name: null,
        },
        organization: {
          type_code: null,
          name: null,
          phone_number: null,
          fax_number: null,
          email: null,
        },
        address: {
          country: null,
          other_country: null,
          province: null,
          city: null,
          street: null,
          postal_code: null,
          urbanization: null,
        },
      },
      comments: null,
    };

    this.receivedDate = parseDateUi(apiReferralDetails.received_date) || null;
    this.referralSource = apiReferralDetails.referral_source;

    this.primaryDiagnosisCode = apiReferralDetails.primary_diagnosis_code;
    this.primaryDiagnosisDate = parseDateUi(apiReferralDetails.primary_diagnosis_date) || null;
    this.secondaryDiagnosisCode = apiReferralDetails.secondary_diagnosis_code;
    this.secondaryDiagnosisDate = parseDateUi(apiReferralDetails.secondary_diagnosis_date) || null;

    this.referringOrganizationName = apiReferralDetails.referrer.organization.name;
    this.referringOrganizationType = apiReferralDetails.referrer.organization.type_code;

    this.referringProfessionalType = apiReferralDetails.referrer.professional.type_code;
    this.firstName = apiReferralDetails.referrer.professional.first_name;
    this.lastName = apiReferralDetails.referrer.professional.last_name;

    this.country = apiReferralDetails.referrer.address.country;
    this.streetAddress = apiReferralDetails.referrer.address.street;
    this.city = apiReferralDetails.referrer.address.city;

    this.phoneNumber = apiReferralDetails.referrer.organization.phone_number;
    this.faxNumber = apiReferralDetails.referrer.organization.fax_number;
    this.emailAddress = apiReferralDetails.referrer.organization.email;

    this.comments = apiReferralDetails.comments;

    switch(this.country) {
      case CountryValue.Canada:
        this.province = apiReferralDetails.referrer.address.province;
        this.postalCode = apiReferralDetails.referrer.address.postal_code;
        break;
      case CountryValue.USA:
        this.state = apiReferralDetails.referrer.address.province;
        this.zipCode = apiReferralDetails.referrer.address.postal_code;
        this.urbanization = apiReferralDetails.referrer.address.urbanization;
        break;
      case CountryValue.Other:
        this.otherCountry = apiReferralDetails.referrer.address.other_country;
        break;
    }
  }

  // Build a copy of the view model
  public copyViewModel() {
    return new UIReferralDetails(this.apiSource);
  }

  // Generate request payload parameters to provide to API for a Referral Attributes patch
  private extractPatch(): APIReferralAttributes {
    const result: APIReferralAttributes = {
      received_date: sanitizeDateApi(this.receivedDate),
      referral_source: this.referralSource,
      primary_diagnosis_code: this.primaryDiagnosisCode,
      primary_diagnosis_date: sanitizeDateApi(this.primaryDiagnosisDate),
      secondary_diagnosis_code: this.secondaryDiagnosisCode,
      secondary_diagnosis_date: sanitizeDateApi(this.secondaryDiagnosisDate),
      referrer: {
        organization: {
          name: this.referringOrganizationName,
          type_code: this.referringOrganizationType,
          phone_number: sanitizePhoneNumber(this.phoneNumber),
          fax_number: sanitizePhoneNumber(this.faxNumber),
          email: this.emailAddress,
        },
        professional: {
          type_code: this.referringProfessionalType,
          first_name: this.firstName,
          last_name: this.lastName,
        },
        address: {
          country: this.country,
          street: this.streetAddress,
          other_country: null,
          city: this.city,
          province: null,
          postal_code: null,
          urbanization: null,
        },
      },
      comments: this.comments,
    };

    switch (this.country) {
      case CountryValue.Canada:
        if (result.referrer.address) Object.assign(result.referrer.address, {
          province: this.province,
          postal_code: this.postalCode,
        });
        break;
      case CountryValue.USA:
        if (result.referrer.address) Object.assign(result.referrer.address, {
          province: this.state,
          urbanization: this.showUrbanization ? this.urbanization : null,
          postal_code: this.zipCode,
        });
        break;
      case CountryValue.Other:
        if (result.referrer.address) Object.assign(result.referrer.address, {
          other_country: this.otherCountry,
        });
        break;
    }

    return result;
  }

  // Save  edit state to the backend
  public save(opts: { recipient: UIRecipient, journey: UIJourney }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId as string;
      const journeyId = opts.journey.journeyId as string;
      if (!recipientId || !journeyId) reject((new UIError('referral_details')));

      const ep = APIRoute(EP.recipients.journeys.referral.update, [[':recipient_id', recipientId], [':journey_id', journeyId]]);
      const payload = {
        referral_attributes: this.extractPatch(),
      };
      axios.patch(ep, payload).then((response: APISaveResponse) => {
        if (response.data.errors) {
          reject((new UIError('referral_details', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          /// NOTE: here we simply call journey-level reload to handle everything
          opts.journey.load({ reload: true }).then(() => {
            resolve((new UISuccess(response)).getSaveResult());
          });
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('referral_details', errorResponse)).errorResult);
      });
    });
  }
}
  