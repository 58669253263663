import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content-wrap page-top" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "page-top-right" }
const _hoisted_4 = { class: "system-title d-inline" }
const _hoisted_5 = {
  key: 0,
  class: "dropdown b-dropdown menu-big page-top-link"
}
const _hoisted_6 = {
  href: "#",
  class: "dropdown-toggle",
  role: "button",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false",
  id: "customizeDash"
}
const _hoisted_7 = {
  class: "dropdown-menu keep-open dropdown-menu-end",
  "aria-labelledby": "customizeDash",
  style: {"max-width":"350px"}
}
const _hoisted_8 = { class: "container" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-sm-12" }
const _hoisted_11 = { class: "menu-big-heading" }
const _hoisted_12 = { class: "col-sm-12" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "checklist border-0" }
const _hoisted_15 = { class: "form-check" }
const _hoisted_16 = ["id", "onUpdate:modelValue", "disabled"]
const _hoisted_17 = ["for"]
const _hoisted_18 = {
  key: 0,
  class: "col-sm-12"
}
const _hoisted_19 = { class: "col-sm-12" }
const _hoisted_20 = { class: "big-menu-actions" }
const _hoisted_21 = { class: "content-wrap dashboard-widgets" }
const _hoisted_22 = { class: "container-fluid" }
const _hoisted_23 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_widget_notifications = _resolveComponent("widget-notifications")!
  const _component_widget_primary_offers = _resolveComponent("widget-primary-offers")!
  const _component_widget_backup_offers = _resolveComponent("widget-backup-offers")!
  const _component_widget_transplant_in_progress = _resolveComponent("widget-transplant-in-progress")!
  const _component_widget_bookmarks = _resolveComponent("widget-bookmarks")!
  const _component_widget_recent_recipients = _resolveComponent("widget-recent-recipients")!
  const _component_widget_recent_living_donors = _resolveComponent("widget-recent-living-donors")!
  const _component_widget_urgent_listings = _resolveComponent("widget-urgent-listings")!
  const _component_widget_exd_donors = _resolveComponent("widget-exd-donors")!
  const _component_widget_lab_results = _resolveComponent("widget-lab-results")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('dashboard')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.uiEnvVersion) + " ", 1),
          (_ctx.editState)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("a", _hoisted_6, [
                  _createVNode(_component_font_awesome_icon, { icon: ['far', 'cog'] }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('customize')), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("form", null, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("h5", _hoisted_11, _toDisplayString(_ctx.$t('available_dashboard_panels')), 1)
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          (_ctx.allowedWidgets.length === 0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: ['far', 'exclamation-circle'],
                                  "fixed-width": ""
                                }),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('none')), 1)
                              ]))
                            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(Object.keys(_ctx.editState).filter((key) => _ctx.widgetAllowedByKey(key)), (key) => {
                                return (_openBlock(), _createElementBlock("ul", {
                                  class: "nav flex-column",
                                  key: key
                                }, [
                                  _createElementVNode("li", null, [
                                    _createElementVNode("div", _hoisted_14, [
                                      _createElementVNode("div", _hoisted_15, [
                                        _withDirectives(_createElementVNode("input", {
                                          type: "checkbox",
                                          class: "form-check-input d-none",
                                          id: key,
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.editState[key]) = $event),
                                          disabled: _ctx.widgetLockedByKey(key)
                                        }, null, 8, _hoisted_16), [
                                          [_vModelCheckbox, _ctx.editState[key]]
                                        ]),
                                        _createElementVNode("label", {
                                          class: "form-check-label",
                                          for: key
                                        }, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: ['far', _ctx.editState[key] ? 'check-square' : 'square']
                                          }, null, 8, ["icon"]),
                                          _createTextVNode(" " + _toDisplayString(_ctx.$t('show')) + " ", 1),
                                          _createElementVNode("span", null, _toDisplayString(_ctx.$t(key)), 1)
                                        ], 8, _hoisted_17)
                                      ])
                                    ])
                                  ])
                                ]))
                              }), 128))
                        ]),
                        (_ctx.errorMessage)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-sm btn-success mt-2 mb-3",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.savePatch()))
                            }, _toDisplayString(_ctx.$t('save_preferences')), 1)
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          (_ctx.isWidgetAllowed('notifications'))
            ? (_openBlock(), _createBlock(_component_widget_notifications, {
                key: 0,
                ref: "dashNotifications",
                requiredWidget: _ctx.isWidgetRequired('notifications'),
                onReloadDashboard: _ctx.initializeWidget
              }, null, 8, ["requiredWidget", "onReloadDashboard"]))
            : _createCommentVNode("", true),
          (_ctx.isWidgetAllowed('active_primary_offers'))
            ? (_openBlock(), _createBlock(_component_widget_primary_offers, {
                key: 1,
                ref: "dashPrimary",
                requiredWidget: _ctx.isWidgetRequired('active_primary_offers'),
                onReloadDashboard: _ctx.initializeWidget
              }, null, 8, ["requiredWidget", "onReloadDashboard"]))
            : _createCommentVNode("", true),
          (_ctx.isWidgetAllowed('active_backup_offers'))
            ? (_openBlock(), _createBlock(_component_widget_backup_offers, {
                key: 2,
                ref: "dashBackup",
                requiredWidget: _ctx.isWidgetRequired('active_backup_offers'),
                onReloadDashboard: _ctx.initializeWidget
              }, null, 8, ["requiredWidget", "onReloadDashboard"]))
            : _createCommentVNode("", true),
          (_ctx.isWidgetAllowed('transplant_in_progress'))
            ? (_openBlock(), _createBlock(_component_widget_transplant_in_progress, {
                key: 3,
                ref: "dashTransplantInProgress",
                requiredWidget: _ctx.isWidgetRequired('transplant_in_progress'),
                onReloadDashboard: _ctx.initializeWidget
              }, null, 8, ["requiredWidget", "onReloadDashboard"]))
            : _createCommentVNode("", true),
          (_ctx.isWidgetAllowed('bookmarks'))
            ? (_openBlock(), _createBlock(_component_widget_bookmarks, {
                key: 4,
                ref: "dashBookmark",
                requiredWidget: _ctx.isWidgetRequired('bookmarks'),
                onReloadDashboard: _ctx.initializeWidget
              }, null, 8, ["requiredWidget", "onReloadDashboard"]))
            : _createCommentVNode("", true),
          (_ctx.isWidgetAllowed('recent_recipient_registrations'))
            ? (_openBlock(), _createBlock(_component_widget_recent_recipients, {
                key: 5,
                ref: "dashRecentRecipients",
                requiredWidget: _ctx.isWidgetRequired('recent_recipient_registrations'),
                onReloadDashboard: _ctx.initializeWidget
              }, null, 8, ["requiredWidget", "onReloadDashboard"]))
            : _createCommentVNode("", true),
          (_ctx.isWidgetAllowed('recent_living_donor_registrations'))
            ? (_openBlock(), _createBlock(_component_widget_recent_living_donors, {
                key: 6,
                ref: "dashRecentLivingDonors",
                requiredWidget: _ctx.isWidgetRequired('recent_living_donor_registrations'),
                onReloadDashboard: _ctx.initializeWidget
              }, null, 8, ["requiredWidget", "onReloadDashboard"]))
            : _createCommentVNode("", true),
          (_ctx.isWidgetAllowed('urgent_listings'))
            ? (_openBlock(), _createBlock(_component_widget_urgent_listings, {
                key: 7,
                ref: "dashUrgentListings",
                requiredWidget: _ctx.isWidgetRequired('urgent_listings'),
                onReloadDashboard: _ctx.initializeWidget
              }, null, 8, ["requiredWidget", "onReloadDashboard"]))
            : _createCommentVNode("", true),
          (_ctx.isWidgetAllowed('exd_donors'))
            ? (_openBlock(), _createBlock(_component_widget_exd_donors, {
                key: 8,
                ref: "dashExdDonors",
                requiredWidget: _ctx.isWidgetRequired('exd_donors'),
                onReloadDashboard: _ctx.initializeWidget
              }, null, 8, ["requiredWidget", "onReloadDashboard"]))
            : _createCommentVNode("", true),
          (_ctx.isWidgetAllowed('donor_lab_results'))
            ? (_openBlock(), _createBlock(_component_widget_lab_results, {
                key: 9,
                ref: "dashLabResults",
                requiredWidget: _ctx.isWidgetRequired('donor_lab_results'),
                onReloadDashboard: _ctx.initializeWidget
              }, null, 8, ["requiredWidget", "onReloadDashboard"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}