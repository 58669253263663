export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "transplant_controls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Controls"])},
        "transplant_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Complete"])},
        "transplant_complete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicking 'Transplant Complete' button will enable organ recovery information to be entered for this Living donor and will enable Transplant Details to be entered for the linked recipient. Both Recovery Information and Transplant Details should be ready to be entered prior to clicking the button."])},
        "confirm_transplant_complete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action will update the linked recipient’s status to Transplant in Progress and enable the entry of Transplant Details on the recipient profile. The recipient will not be able to be unlinked from the living donor and they will no longer be eligible for allocation. The Donation Information will also be locked to prevent editing. Do you wish to continue?"])},
        "confirm_recovery_information_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving organ recovery details will lock the Link to Recipient and Donation Information areas to prevent editing. Do you wish to continue?"])},
        "recovery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery Details"])},
        "recovery_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery Information"])},
        "declaration_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaration Hospital"])},
        "recovery_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery Hospital"])},
        "cross_clamp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Clamp Date"])},
        "cross_clamp_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Clamp Time"])},
        "organ_recovery_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Recovery Data"])},
        "save_recovery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Recovery Details"])},
        "referred_organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referred Organ"])},
        "organ_specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Specification"])},
        "surgeon_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surgeon #1"])},
        "surgeon_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surgeon #2"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery Start Date"])},
        "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery Start Time"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery End Date"])},
        "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery End Time"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
        "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed?"])},
        "flush_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flush Date"])},
        "flush_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flush Time"])},
        "organ_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Shipped"])},
        "transplant_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Date"])},
        "shipped_out_of_Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipped out of Province"])},
        "perfusion_pump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfusion Pump?"])},
        "perfusion_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfusion Date"])},
        "perfusion_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfusion Time"])},
        "organ_successfully_transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Successfully Transplanted"])},
        "liver_graft_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graft Weight (grams)"])},
        "liver_abnormalities_concerns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abnormalities or Concerns"])},
        "save_recovery_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Recovery Information"])},
        "shipped_out_of_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipped out of Province"])},
        "liver_specific_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liver Specific Information"])},
        "organ_shipped_subsection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Shipped Sub-section"])}
      },
      "fr": {
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "transplant_controls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôles de transplantation"])},
        "transplant_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplantation complète"])},
        "transplant_complete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cliquant sur le bouton 'Transplantation complète', les informations relatives à la récupération de l'organe seront saisies pour ce donneur vivant et les détails de la transplantation seront saisis pour le patient lié. Les informations relatives à la récupération et à la transplantation doivent être prêtes à être saisies avant de cliquer sur le bouton."])},
        "confirm_transplant_complete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action will update the linked recipient’s status to Transplant in Progress and enable the entry of Transplant Details on the recipient profile. The recipient will not be able to be unlinked from the living donor and they will no longer be eligible for allocation. The Donation Information will also be locked to prevent editing. Do you wish to continue?"])},
        "confirm_recovery_information_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sauvegarde des détails du don d'organe verrouille les champs Lien vers le destinataire et Informations sur le don pour empêcher toute modification. Voulez-vous continuer ?"])},
        "recovery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la récupération"])},
        "recovery_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur la récupération"])},
        "declaration_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôpital de déclaration"])},
        "recovery_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôpital de récupération"])},
        "cross_clamp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la bride de serrage en croix"])},
        "cross_clamp_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure de la bride de serrage en croix"])},
        "organ_recovery_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données sur la récupération des organes"])},
        "save_recovery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les détails de la récupération"])},
        "referred_organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe référencé"])},
        "organ_specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécification de l'organe"])},
        "surgeon_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgien No 1"])},
        "surgeon_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgien No 2"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
        "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure de début"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
        "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure de fin"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
        "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complété ?"])},
        "flush_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du rinçage"])},
        "flush_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure du rinçage"])},
        "organ_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe expédié"])},
        "transplant_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la transplantation"])},
        "transplant_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure de la transplantation"])},
        "shipped_out_of_Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédié hors de la province"])},
        "perfusion_pump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pompe à perfusion ?"])},
        "perfusion_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de perfusion"])},
        "perfusion_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure de perfusione"])},
        "organ_successfully_transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe a été transplanté avec succès"])},
        "liver_graft_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids de la greffe (grammes)"])},
        "liver_abnormalities_concerns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anomalies ou préoccupations"])},
        "save_recovery_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les informations de récupération"])},
        "shipped_out_of_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédié hors de la province"])},
        "liver_specific_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations spécifiques au foie"])},
        "organ_shipped_subsection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-section des Organes expédiés"])}
      }
    }
  })
}
