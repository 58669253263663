<template>
  <base-side-nav :class="{ closed: sideNavClosed }">
    <template v-slot:toolbar />
    <template v-slot:side-nav-content>
      <side-nav-toolbar @toggle="toggleSideNav">
        <template v-slot:return-link>
          <router-link class="nav-link no-scroll" title="Return to previous page" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: 'administration' }">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
              Return to previous page
            </span>
          </router-link>
        </template>
      </side-nav-toolbar>
      <div class="side-nav-container">
        <div class="nav-block-wrap">
          <ul class="nav nav-block flex-column" :class="{ 'active': true }">
            <li class="nav-item">
              <router-link class="nav-link no-scroll open" :to="{ name: 'profilePage' }">
                <font-awesome-icon :icon="['fas', 'chevron-right']" class="nav-caret" fixed-width />
                {{ $t("user_profile") }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </base-side-nav>
</template>

<script lang="ts">
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import { Component, Vue } from 'vue-facing-decorator';
import UserLinks from '@/components/administration/side-nav/UserLinks.vue';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    UserLinks
  },
  ...i18nMessages([
    require('@/components/_locales/common.json'),
    require('@/components/_locales/ManageAccount.json'),
  ]),
})
export default class SideNavProfile extends Vue {

  public sideNavClosed = false;

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
</script>
