export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "Liver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liver"])},
        "liver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liver"])},
        "Liver (local)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liver (local)"])},
        "Liver - Whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liver - Whole"])},
        "Liver - Right segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liver - Right segment"])},
        "Liver - Left segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liver - Left segment"])},
        "Liver - Unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liver - Unknown"])},
        "Heart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heart"])},
        "heart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heart"])},
        "Kidney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kidney"])},
        "kidney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kidney"])},
        "Kidney - Single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kidney - Single"])},
        "Pancreas - Whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pancreas - Whole"])},
        "Kidney - Single and Pancreas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kidney - Single and Pancreas"])},
        "Kidney - Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kidney - Both"])},
        "Kidney - Both and Pancreas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kidney - Both and Pancreas"])},
        "Kidney - Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kidney - Right"])},
        "Kidney - Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kidney - Left"])},
        "Kidney - Unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kidney - Unknown"])},
        "Kidney (provincial)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kidney (Provincial)"])},
        "Kidney (local)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kidney (Local)"])},
        "Double Kidney (provincial)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double Kidney (Provincial)"])},
        "Double Kidney (local)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double Kidney (Local)"])},
        "Lung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung"])},
        "lung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung"])},
        "Lung (local)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung (local)"])},
        "Lung - Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Both"])},
        "Lung - Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Right"])},
        "Lung - Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Left"])},
        "Lung - Right Lobe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Right Lobe"])},
        "Lung - Left Lobe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Left Lobe"])},
        "Lung - EVLP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - EVLP"])},
        "Lung - Single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Single"])},
        "Lung - Either double or single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Either double or single"])},
        "Lung - Either double or single right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Either double or single right"])},
        "Lung - Either double or single left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Either double or single left"])},
        "Lung - Double or single prefer left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Double or single prefer left"])},
        "Lung - Double or single prefer right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Double or single prefer right"])},
        "Lung - Unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Unknown"])},
        "Pancreas (whole)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pancreas (whole)"])},
        "pancreas-whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pancreas (whole)"])},
        "Pancreas (for islets)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pancreas (for islets)"])},
        "pancreas-islets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pancreas (for islets)"])},
        "Small Bowel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small Bowel"])},
        "Small Bowel + Stomach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small Bowel + Stomach"])},
        "small-bowel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small Bowel"])},
        "small-bowel + Stomach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small Bowel + Stomach"])},
        "Stomach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stomach"])},
        "VCA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VCA"])},
        "vca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VCA"])},
        "Allocation Running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Running"])},
        "Allocation run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation run"])},
        "Allocation Run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Run"])},
        "Offering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offering"])},
        "Offer accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer accepted"])},
        "Offer Accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Accepted"])},
        "Discontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinued"])},
        "Transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplanted"])},
        "Superceded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superceded"])},
        "Concluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluded"])},
        "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "Vessels for Banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vessels for Banking"])}
      },
      "fr": {
        "Liver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foie"])},
        "liver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foie"])},
        "Liver (local)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foie (locale)"])},
        "Liver - Whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foie - entier"])},
        "Liver - Right segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foie - segment droit"])},
        "Liver - Left segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foie - segment gauche"])},
        "Liver - Unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foie - inconnu"])},
        "Heart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coeur"])},
        "heart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coeur"])},
        "Kidney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rein"])},
        "kidney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rein"])},
        "Kidney - Single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rein - seul"])},
        "Pancreas - Whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pancréas - entier"])},
        "Kidney - Single and Pancreas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rein - un seul et le pancréas"])},
        "Kidney - Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reins - les deux"])},
        "Kidney - Both and Pancreas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reins - les deux et le pancréas"])},
        "Kidney - Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rein - droit"])},
        "Kidney - Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rein - gauche"])},
        "Kidney - Unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rein - inconnu"])},
        "Kidney (provincial)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rein (Provincial)"])},
        "Kidney (local)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rein (Local)"])},
        "Double Kidney (provincial)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rein double (Provincial)"])},
        "Double Kidney (local)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rein double (Local)"])},
        "Lung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon"])},
        "lung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon"])},
        "Lung (local)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon (local)"])},
        "Lung - Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon - les deux"])},
        "Lung - Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon - droit"])},
        "Lung - Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon -  gauche"])},
        "Lung - Right Lobe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Right Lobe"])},
        "Lung - Left Lobe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung - Left Lobe"])},
        "Lung - EVLP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfusion ex vivo des poumons"])},
        "Lung - Single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon - seul"])},
        "Lung - Either double or single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon - les deux, ou un seul poumon"])},
        "Lung - Either double or single right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon - les deux, ou le poumon droit"])},
        "Lung - Either double or single left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon - les deux, ou le poumon gauche"])},
        "Lung - Double or single prefer left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon - les deux poumons ou un seul poumon, préférez le gauche"])},
        "Lung - Double or single prefer right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon - les deux poumons ou un seul poumon, préférez le droit"])},
        "Lung - Unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poumon - inconnu"])},
        "Pancreas (whole)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pancréas (entiers)"])},
        "pancreas-whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pancréas (entiers)"])},
        "Pancreas (for islets)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pancréas (pour les îlots)"])},
        "pancreas-islets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pancréas (pour les îlots)"])},
        "Small Bowel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intestin grêle"])},
        "Small Bowel + Stomach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intestin grêle + estomac"])},
        "small-bowel + Stomach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intestin grêle"])},
        "Stomach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estomac"])},
        "VCA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VCA"])},
        "vca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VCA"])},
        "Allocation Running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation en cours"])},
        "Allocation run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycle d'allocation"])},
        "Allocation Run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycle d'allocation"])},
        "Offering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre"])},
        "Offer accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre acceptée"])},
        "Offer Accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre Acceptée"])},
        "Discontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandonné"])},
        "Transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplanté"])},
        "Superceded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacé par"])},
        "Concluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclu"])},
        "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
        "Vessels for Banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaisseaux pour le stockage"])}
      }
    }
  })
}
