<template>
  <div>
    <loading-donor-summary-card />

    <div>
      <span class="skeleton-box skeleton-title w-100" />  
      <div class="card summary-card shadow-none skeleton-padding">               
        <div class="column">
          <span class="skeleton-box w-100" style="height: 100px;" />
        </div>
      </div>
    </div>

    <span class="skeleton-box skeleton-title w-100" />  
    <loading-section/>

    <span class="skeleton-box skeleton-title w-100" />  
    <loading-section/>

    <span class="skeleton-box skeleton-title w-100" />  
    <loading-section/>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import LoadingSection from '@/components/shared/LoadingSection.vue';
import LoadingDonorSummaryCard from '@/components/shared/LoadingDonorSummaryCard.vue';

@Component({
  components: {
    LoadingSection,
    LoadingDonorSummaryCard,
  }
})
export default class LoadingDonorPage extends Vue {}
</script>
