import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content-wrap"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "nav list-patients action-row" }
const _hoisted_4 = {
  key: 0,
  class: "highlight-scroll"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!
  const _component_loading_list_view = _resolveComponent("loading-list-view")!
  const _component_column_config = _resolveComponent("column-config")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_full_page_list_layout = _resolveComponent("full-page-list-layout")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_page_top, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('donors')), 1)
      ]),
      _: 1
    }),
    (!_ctx.donors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_loading_list_view)
          ])
        ]))
      : (_openBlock(), _createBlock(_component_full_page_list_layout, { key: 1 }, {
          content: _withCtx(() => [
            _createElementVNode("nav", _hoisted_3, [
              _createVNode(_component_column_config, {
                columnKey: "donorListColumns",
                optionDefaults: _ctx.getColumnDefaults,
                "config-id": "donor-list-columns",
                options: _ctx.donorColumns,
                modelValue: _ctx.columnsFilter.selectedColumns,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.columnsFilter.selectedColumns) = $event))
              }, null, 8, ["optionDefaults", "options", "modelValue"]),
              _createVNode(_component_router_link, {
                class: _normalizeClass(["btn btn-primary ml-auto", { disabled: !_ctx.canCreate }]),
                to: { name: 'new-deceased-donor' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('add_new_donor')), 1)
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _createElementVNode("template", null, [
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(_ctx.$t("system_returned")) + " ", 1),
                _createElementVNode("b", null, _toDisplayString(_ctx.donors.count), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('donors')), 1)
              ]),
              (_ctx.isOverflowed)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createTextVNode(_toDisplayString(_ctx.$t('scroll_right_to_see_more')) + " ", 1),
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['fas', 'chevron-right'],
                      class: "nav-caret",
                      "fixed-width": ""
                    })
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_sub_section, {
                "sub-section-id": "list-donors",
                title: "",
                mode: "remote",
                tabbableValue: _ctx.$t('deceased_donor_id'),
                "total-records": _ctx.donors.count,
                lookupsToLoad: _ctx.lookupsToLoad,
                isParentLoading: _ctx.isLoading,
                "table-config": _ctx.donorTableConfig(),
                onOnPageChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updatePagination($event))),
                onOnPerPageChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatePagination($event))),
                onOnColumnFilter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filterList($event))),
                onOnSortChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.filterList($event))),
                onTableRowClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectDonor($event)))
              }, null, 8, ["tabbableValue", "total-records", "lookupsToLoad", "isParentLoading", "table-config"])
            ])
          ]),
          _: 1
        }))
  ]))
}