import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-md-2" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("label", { for: _ctx.inputId }, _toDisplayString(_ctx.label || _ctx.name), 9, _hoisted_2)
    ]),
    _createElementVNode("div", {
      id: _ctx.inputId,
      name: _ctx.label,
      class: "col-12 large-checklist-confirmation-display"
    }, _toDisplayString(_ctx.modelValue), 9, _hoisted_3)
  ]))
}