export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System ID"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])},
        "deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])},
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])},
        "national_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National Donor ID"])},
        "nat_short_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nat. ID"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
        "date_of_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
        "abo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABO"])},
        "exd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXD"])},
        "abo_sub_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABO Sub Type"])},
        "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital"])},
        "referral_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Hospital"])},
        "mrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRN"])},
        "rh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RH"])},
        "bmi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMI"])},
        "total_lung_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Lung Capacity (TLC)"])},
        "total_lung_capacity_actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Lung Capacity (TLC) - Actual"])},
        "total_lung_capacity_predicted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Lung Capacity (TLC) - Predicted"])},
        "cause_of_death": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause of Death"])},
        "withdrawal_of_life_support_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal of Life Support Date"])},
        "withdrawal_of_life_support_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal of Life Support Time (EST)"])},
        "declaration_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaration Hospital"])},
        "declaration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaration Date"])},
        "donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Type"])},
        "donor_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Information"])},
        "hla_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA Information"])},
        "allocation_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Reports"])},
        "now_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National Organ Waitlist"])},
        "donor_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Documents"])},
        "affimative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "allocation_eoffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation / eOffer"])},
        "checklists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklists"])},
        "organs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organs"])},
        "national_organ_waitlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National Organ Waitlist"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "insurance_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Number"])},
        "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
      },
      "fr": {
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID système"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo"])},
        "deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo"])},
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo"])},
        "national_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No nationale du donneur"])},
        "nat_short_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Nat."])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge"])},
        "date_of_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de Naissance"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])},
        "abo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABO"])},
        "exd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXD"])},
        "abo_sub_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABO Sub Type"])},
        "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôpital"])},
        "referral_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôpital de référence"])},
        "mrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRN"])},
        "rh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RH"])},
        "bmi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMI"])},
        "total_lung_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacité Pulmonaire Totale (TLC)"])},
        "total_lung_capacity_actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacité pulmonaire totale (CPT) - réelle"])},
        "total_lung_capacity_predicted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacité pulmonaire totale (CPT) - Prévue"])},
        "cause_of_death": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause du décès"])},
        "withdrawal_of_life_support_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de retrait du soutien xtal"])},
        "withdrawal_of_life_support_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai d'arrêt de la réanimation (EST)"])},
        "declaration_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôpital de la Déclaration"])},
        "declaration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la Déclaration"])},
        "donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de Donateur"])},
        "donor_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le donateur"])},
        "hla_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information HLA"])},
        "allocation_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports d’attribution"])},
        "now_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste d’attente nationale pour un organe"])},
        "donor_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents du donateur"])},
        "affimative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "allocation_eoffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation / EOffre"])},
        "checklists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes de contrôle"])},
        "organs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organes"])},
        "national_organ_waitlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste d'attente nationale pour un organe"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "insurance_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Number"])},
        "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
      },
      "en_tgln": {
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OATS ID"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID"])},
        "deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID"])},
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID"])}
      },
      "fr_tgln": {
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No OATS"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])},
        "deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])},
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])}
      },
      "en_tq": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])},
        "deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])},
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])}
      },
      "fr_tq": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])},
        "deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])},
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])}
      }
    }
  })
}
