<template>
  <!-- Sub-section container -->
    <sub-section
      sub-section-id="patient-contact-details"
      :title="$t('contact_details_title')"
      :isParentLoading="isLoading"
    >
    <template v-slot:contents>
      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="showCreateButton"
        :createText="$t('contact_details_create_button')"
        @table-create-row="handleTableCreateRow"
      >
        <template v-slot:button-bar>
          <div class="filter-section-action-row">
            <div class="filter-section-wrapper">
              <filter-component
                fieldID="contactFilter"
                :showFilter="true"
                :showArchived="true"
                @setFilters="handleSetFilters"
              />
            </div>
          </div>
        </template>
      </table-toolbar>

      <!-- List of Items, or History List -->
      <table-list
        ref="patientContactDetailsTable"
        tabbableColumn="lastUpdatedDisplay"
        table-id="patient-contact-details-table"
        :table-config="patientContactDetailsTableConfig"
        @table-row-click="handleTableRowClick"
        :highlightSelection="true"
        :rowStyleClass="rowStyleClass"
      />

      <!-- Form layout -->
      <contact-detail-form
        form-id="patient-contact-details-form"
        ref="patientContactDetailsForm"
        :selection="selection"
        :newRecipient="newRecipient"
        :canSave="canSave"
        @success="handleSuccess"
      />
    </template>
  </sub-section>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { TableConfig } from '@/types';
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Prop } from 'vue-facing-decorator';
import { UIContactDetail } from "@/UIModels/contactDetail";
import { SaveResult } from '@/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from '@/components/shared/filter/FilterComponent.vue';
import TableList from '@/components/shared/TableList.vue';
import ContactDetailForm from '@/components/recipients/contactInformation/patientContactDetails/ContactDetailForm.vue';
import { UIError } from '@/UIModels/error';
import { UIFilterAssignments } from '@/store/recipientJourney/types';
import { ContactDetailCategoryLookup } from '@/store/lookups/types';
import { i18nMessages } from '@/i18n';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { sortByDate } from '@/utils';

interface PatientContactDetailsComponentRow {
  id: string|null;
  lastUpdatedDateObject: Date|null;
  lastUpdatedDisplay: string;
  categoryCode: string|null;
  categoryDisplay: string;
  details: string;
  call: string;
  voicemail: string;
  text: string;
  comments: string|null;
  status: string;
  isArchived: boolean;
  isPreferred: boolean;
}

// When an interaction with the table list requires scrolling to the form, where should it go to?
const SCROLL_TO_ID = "patient-contact-detail-form";

// What are all the possible per-page sizes allowed in the table list pagination bar?
const PAGE_SIZES = [10, 25, 50];

@Component({
  components: {
    SubSection,
    TableToolbar,
    FilterComponent,
    TableList,
    ContactDetailForm,
  },
  ...i18nMessages([
    require('@/components/_locales/common.json'),
    require('@/components/recipients/contactInformation/_locales/PatientContactDetails.json'),
  ]),
})
export default class PatientContactDetails extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.contact_detail_categories) contactDetailCategories!: ContactDetailCategoryLookup[];

  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;

  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Selection instance to provide to child form component
  private selection = new UIListFormSelection();

  // Local component state related to table filters
  private filterAssignments: UIFilterAssignments = {
    include_archived: false,
  }

  private isLoading = true;

  // Reference to Patient Address table list
  get patientContactDetailsTable(): TableList {
    return this.$refs.patientContactDetailsTable as TableList;
  }

  // Do we need to parse any Address view model content for display in the table?
  get patientContactDetailsRows(): PatientContactDetailsComponentRow[] {
    return this.patientContactDetailsListItems.map((uiContactDetail: UIContactDetail): PatientContactDetailsComponentRow => { return this.buildRow(uiContactDetail); });
  }

  // Handle frontend filtering based on current "filterAssignments" values
  get filteredRows(): PatientContactDetailsComponentRow[] {
    return this.patientContactDetailsRows.filter((addressRow: PatientContactDetailsComponentRow) => {
      return this.filterAssignments.include_archived || !addressRow.isArchived;
    });
  }

  /**
   * Get the positional index in the category lookup of the specified category for sorting purposes
   *
   * @param categoryCode string code of the category lookup
   * @returns {number} 0-based index if code found in lookup, -1 if not found
   */
  private categoryIndex(categoryCode: string|null): number {
    if (!this.contactDetailCategories) return -1;

    const result = this.contactDetailCategories.findIndex((categoryLookup: ContactDetailCategoryLookup) => {
      return categoryLookup.code === categoryCode;
    });

    return result;
  }

  // Handle frontend sorting
  // NOTE: sort by category (fixed lookup order) then in reverse chronological order
  get sortedRows(): PatientContactDetailsComponentRow[] {
    const sortedByDate = sortByDate(this.filteredRows, { fieldName: 'lastUpdatedDateObject' });
    const sortedByCategory: PatientContactDetailsComponentRow[] = sortedByDate.sort((a: PatientContactDetailsComponentRow, b: PatientContactDetailsComponentRow): number => {
      return this.categoryIndex(a.categoryCode) - this.categoryIndex(b.categoryCode);
    });
    return sortedByCategory;
  }

  get showCreateButton(): boolean {
    return this.canSave && !this.newRecipient;
  }

  // Configure the list table
  get patientContactDetailsTableConfig(): TableConfig {
    return {
      data: this.sortedRows,
      columns: [
        { label: this.$t('contact_detail_column.last_updated').toString(), field: 'lastUpdatedDisplay', width: '125px'},
        { label: this.$t('contact_detail_column.category_name').toString(), field: 'categoryDisplay',  width: '145px' },
        { label: this.$t('contact_detail_column.details').toString(), field: 'details',  width: '170px' },
        { label: this.$t('contact_detail_column.call').toString(), field: 'call',  width: '65px'},
        { label: this.$t('contact_detail_column.voicemail').toString(), field: 'voicemail',  width: '75px' },
        { label: this.$t('contact_detail_column.text').toString(), field: 'text',  width: '65px' },
        { label: this.$t('contact_detail_column.comments').toString(), field: 'comments', width: '200px' },
        { label: this.$t('contact_detail_column.status').toString(), field: 'status', width: '105px'},
      ],
      empty: this.canSave ? this.$t('contact_detail_table_empty.can_save').toString() : this.$t('contact_detail_table_empty.read_only').toString(),
      sortOptions: {
        enabled: false,
        initialSortBy: [{ field: 'lastUpdatedDateObject', type: 'desc' }]
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  // How many contact details does this Patient have based on the current filter assignments?
  // NOTE: make sure to do filtering before counting records
  get totalRecords(): number {
    if (!this.filteredRows) return 0;

    return this.filteredRows.length;
  }

  // Figure out if we need any extra style class based on the row data
  private rowStyleClass(row: PatientContactDetailsComponentRow): string|null {
    if (!row) return null;

    const rowClasses = [];
    if (row.isArchived) rowClasses.push('highlight-archived');
    if (row.isPreferred) rowClasses.push('emphasize-preferred'); // Contact methods that are Preferred appear in bold font (AP-775)
    return rowClasses.join(' ');
  }

  // Derive 'Yes', 'No', or blank '--' for one of the "Okay to" options in the table
  private parseYesNoBlank(permitted: boolean|null): string {
    if (permitted === null) return '--';

    return permitted ? this.$t('yes').toString() : this.$t('no').toString();
  }

  // Map from UI view model to row interface for this component's table
  private buildRow(uiContactDetail: UIContactDetail): PatientContactDetailsComponentRow {
    return {
      id: uiContactDetail.id,
      lastUpdatedDateObject: uiContactDetail.lastUpdatedDateObject,
      lastUpdatedDisplay: this.parseDisplayDateUiFromDateTime(uiContactDetail.lastUpdated) || '-',
      categoryCode: uiContactDetail.categoryType,
      categoryDisplay: uiContactDetail.categoryType ? this.lookupValue(uiContactDetail.categoryType, 'contact_detail_categories') : '-',
      details: uiContactDetail.details || '-',
      call: this.parseYesNoBlank(uiContactDetail.okayToCall),
      voicemail: this.parseYesNoBlank(uiContactDetail.okayToVoicemail),
      text: this.parseYesNoBlank(uiContactDetail.okayToText),
      comments: uiContactDetail.comments,
      status: uiContactDetail.status ? this.$t(uiContactDetail.status).toString() : '-',
      isArchived: uiContactDetail.status === 'status.archived',
      isPreferred: uiContactDetail.preferred,
    };
  }

  scrollToMethod() {
    if (SCROLL_TO_ID) {
      // perform scrollTo
      const location = document.getElementById(SCROLL_TO_ID) || null;
      if (location) {
        const elementPosition = location.getBoundingClientRect().top;
        const offsetPosition = elementPosition - 105;
        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }
  }

  // Process an update to one of the inputs in the filter component
  // NOTE: here we copy values to ensure table rows only change on "Filter" or "Clear" button clicks
  private handleSetFilters(filterAssignments: UIFilterAssignments): void {
    Object.assign(this.filterAssignments, { ...filterAssignments });
  }

  // Clear selection based on a create button click event
  private handleTableCreateRow(): void {
    this.patientContactDetailsTable.resetSelection();
    this.selection = new UIListFormSelection();
    this.scrollToMethod();
  }

  // Select a UI Model List item from the list based on a row click event
  private handleTableRowClick(event: { row: PatientContactDetailsComponentRow }): void {
    const listItem = this.patientContactDetailsListItems.find((listItem: UIContactDetail) => { return listItem.id == event.row.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem.id);
    this.scrollToMethod();
  }

  // Clear selection
  private handleSuccess(_success: SaveResult): void {
    this.patientContactDetailsTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  // Get list of UI view models from current page pinia store UI Recipient view model
  get patientContactDetailsListItems(): UIContactDetail[] {
    const currentPageStore = useCurrentPageStore();
    const uiRecipient = currentPageStore.currentRecipient;
    if (!uiRecipient) return [];

    return uiRecipient.contactDetails as UIContactDetail[];
  }

  mounted(): void {
    const currentPageStore = useCurrentPageStore();
    const uiRecipient = currentPageStore.currentRecipient;
    if (!uiRecipient) return;

    uiRecipient.load().then(() => {
      this.isLoading = false;
    }).catch((uiError: UIError) => {
      this.isLoading = false;
      console.warn('Something unexpected happen when attempting to get contact details', uiError);
    });
  }
}
</script>
