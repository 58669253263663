import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "standard-form-group-medium" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "standard-form-group-large" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_17 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_select_other_input = _resolveComponent("select-other-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "demographics",
    saveButtonText: _ctx.$t('save_demographics'),
    ref: "saveDemographics",
    "lookups-to-load": _ctx.lookupsToLoad,
    saveButton: !_ctx.newLivingDonor,
    disabled: !_ctx.canSave,
    onSave: _cache[21] || (_cache[21] = ($event: any) => (_ctx.savePatch(false))),
    onLoaded: _cache[22] || (_cache[22] = ($event: any) => (_ctx.loaded()))
  }, _createSlots({
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('demographics')), 1)
    ]),
    _: 2
  }, [
    (_ctx.editState)
      ? {
          name: "body",
          fn: _withCtx(() => [
            _createVNode(_component_sub_section, {
              title: _ctx.$t('registration_information'),
              "sub-section-id": "demographics-internal",
              disabled: !_ctx.canSave
            }, {
              contents: _withCtx(() => [
                (_ctx.editState.internal)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_text_input, {
                          inputId: "demographics-living_donor_id",
                          name: _ctx.$t('living_donor_id'),
                          disabled: true,
                          modelValue: _ctx.editState.internal.living_donor_id,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.internal.living_donor_id) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_date_input, {
                          inputId: "demographics-registration_date",
                          name: _ctx.$t('living_donor_registration_date'),
                          disabled: true,
                          modelValue: _ctx.editState.internal.registration_date,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.internal.registration_date) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_text_input, {
                          inputId: "demographics-registration_time",
                          name: _ctx.$t('living_donor_registration_time'),
                          disabled: true,
                          modelValue: _ctx.editState.internal.registration_time,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.internal.registration_time) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title", "disabled"]),
            _createVNode(_component_sub_section, {
              title: _ctx.$t('personal_information'),
              "sub-section-id": "demographics-personal",
              disabled: !_ctx.canSave
            }, {
              contents: _withCtx(() => [
                (_ctx.editState.personal)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_text_input, {
                            ruleKey: "patient_profile.first_name",
                            inputId: "demographics-firstname",
                            name: _ctx.$t('first_name'),
                            modelValue: _ctx.editState.personal.first_name,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.personal.first_name) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_text_input, {
                            ruleKey: "patient_profile.middle_name",
                            inputId: "demographics-middlename",
                            name: _ctx.$t('middle_name'),
                            modelValue: _ctx.editState.personal.middle_name,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.personal.middle_name) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_text_input, {
                            ruleKey: "patient_profile.last_name",
                            inputId: "demographics-lastname",
                            name: _ctx.$t('last_name'),
                            modelValue: _ctx.editState.personal.last_name,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.personal.last_name) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_date_input, {
                            ruleKey: "patient_profile.birth.date",
                            inputId: "demographics-dob",
                            name: _ctx.$t('dob'),
                            modelValue: _ctx.editState.personal.date_of_birth,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.personal.date_of_birth) = $event)),
                            minDateLimit: _ctx.getDobMinDateLimit,
                            maxDateLimit: _ctx.getDobMaxDateLimit
                          }, null, 8, ["name", "modelValue", "minDateLimit", "maxDateLimit"])
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_text_input, {
                            inputId: "demographics-age",
                            name: _ctx.$t('age'),
                            disabled: true,
                            calculated: true,
                            modelValue: _ctx.calculatedAge,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.calculatedAge) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_select_other_input, {
                          ruleKey: "patient_profile.ethnicity_code",
                          "select-id": "demographics-ethnicity",
                          name: _ctx.$t('ethnicity'),
                          validationId: "ethnicity_code",
                          modelValue: _ctx.editState.personal.ethnicity,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.personal.ethnicity) = $event)),
                          options: _ctx.ethnicityLookup,
                          onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.clearStateValues(['ethnicity_other']))),
                          otherTitle: _ctx.$t('ethnicity_other'),
                          colStyling: "standard-form-group-with-other"
                        }, {
                          other: _withCtx(() => [
                            _createVNode(_component_text_input, {
                              ruleKey: "patient_profile.ethnicity_other",
                              "input-id": "demographics-ethnicityother",
                              rules: "required",
                              name: _ctx.$t('ethnicity_other'),
                              modelValue: _ctx.editState.personal.ethnicity_other,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.personal.ethnicity_other) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _: 1
                        }, 8, ["name", "modelValue", "options", "otherTitle"]),
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_select_input, {
                            ruleKey: "patient_profile.sex",
                            selectId: "demographics-sex",
                            name: _ctx.$t('sex'),
                            modelValue: _ctx.editState.personal.sex,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.personal.sex) = $event)),
                            options: _ctx.sexOptions
                          }, null, 8, ["name", "modelValue", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_checkbox_input, {
                            ruleKey: "patient_profile.gender_sex_different",
                            validationId: "gender_sex_different",
                            "input-id": "demographics-gender-different",
                            labelName: _ctx.$t('gender_is_different'),
                            modelValue: _ctx.editState.personal.gender_sex_different,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.personal.gender_sex_different) = $event)),
                            label: _ctx.$t('yes')
                          }, null, 8, ["labelName", "modelValue", "label"])
                        ]),
                        (_ctx.editState.personal.gender_sex_different)
                          ? (_openBlock(), _createBlock(_component_select_other_input, {
                              key: 0,
                              ruleKey: "patient_profile.gender",
                              "select-id": "demographics-gender",
                              name: _ctx.$t('gender'),
                              validationId: "gender",
                              modelValue: _ctx.editState.personal.gender,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.personal.gender) = $event)),
                              options: _ctx.genderLookup,
                              onChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.clearStateValues(['gender_other']))),
                              otherTitle: _ctx.$t('gender_other'),
                              colStyling: "form-group selectWithOther col-md-12 col-xl-6"
                            }, {
                              other: _withCtx(() => [
                                _createVNode(_component_text_input, {
                                  ruleKey: "patient_profile.gender_other",
                                  "input-id": "demographics-other-gender",
                                  rules: "required",
                                  name: _ctx.$t('gender_other'),
                                  modelValue: _ctx.editState.personal.gender_other,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.personal.gender_other) = $event))
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              _: 1
                            }, 8, ["name", "modelValue", "options", "otherTitle"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_select_other_input, {
                          ruleKey: "patient_profile.insurance.plan_code",
                          selectId: "demographics-insurancetype",
                          name: _ctx.$t('insurance_type'),
                          modelValue: _ctx.editState.personal.insurance_type,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState.personal.insurance_type) = $event)),
                          options: _ctx.insurancePlanCodeLookup,
                          onChange: _cache[18] || (_cache[18] = ($event: any) => (_ctx.clearStateValues(['insurance_number', 'insurance_province', 'insurance_other']))),
                          validationId: "plan_code",
                          reduceColumnWidth: "standard-form-group",
                          colStyling: "standard-form-group-with-other"
                        }, {
                          other: _withCtx(() => [
                            _createVNode(_component_text_input, {
                              ruleKey: "patient_profile.insurance.other_plan",
                              "input-id": "demographics-insuranceother",
                              name: _ctx.$t('other_insurance_details'),
                              modelValue: _ctx.editState.personal.insurance_other,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.personal.insurance_other) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _: 1
                        }, 8, ["name", "modelValue", "options"]),
                        (_ctx.showOtherProvinceInsurance)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                              _createVNode(_component_select_input, {
                                ruleKey: "patient_profile.insurance.other_province_plan",
                                "select-id": "demographics-provinceother",
                                name: _ctx.$t('other_province_insurance'),
                                modelValue: _ctx.editState.personal.insurance_province,
                                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editState.personal.insurance_province) = $event)),
                                options: _ctx.otherProvinceDropdown
                              }, null, 8, ["name", "modelValue", "options"])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_17, [
                          (_ctx.showNumber)
                            ? (_openBlock(), _createBlock(_component_text_input, {
                                key: 0,
                                ruleKey: "patient_profile.insurance.number",
                                inputId: "demographics-insurancenumber",
                                name: _ctx.$t('insurance_number'),
                                modelValue: _ctx.editState.personal.insurance_number,
                                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editState.personal.insurance_number) = $event)),
                                mask: _ctx.getMaskFormatType(_ctx.editState.personal.insurance_type)
                              }, null, 8, ["name", "modelValue", "mask"]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title", "disabled"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["saveButtonText", "lookups-to-load", "saveButton", "disabled"]))
}