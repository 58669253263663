<template>
  <div aria-hidden="true">
    <span v-if="truncated">
      {{ sanitizedText }}{{ clamp }}
    </span>
    <div v-else>
      {{ sanitizedText }}
    </div>
    <button
      v-if="truncatable"
      class="btn btn-sm btn-white"
      type="button"
      @click="toggle"
    >
      {{ label }}
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { i18nMessages } from '@/i18n';

@Component({
  ...i18nMessages([
    require('./_locales/common.json'),
  ]),
})
export default class MoreLessComponent extends Vue {
  @Prop() descriptionText!: string;

  private truncated = true;
  private maxDisplay = 400;

  get sanitizedText(): string {
    if (!this.truncated) return this.descriptionText;

    return this.descriptionText.substring(0, this.maxDisplay);
  }

  get clamp(): string {
    return this.truncated ? '...' : '';
  }

  get label(): string {
    const key = this.truncated ? 'view_more' : 'view_less';
    return this.$t(key).toString();
  }

  get truncatable(): boolean {
    return (this.descriptionText || '').length > this.maxDisplay;
  }

  initialize(): void {
    this.truncated = this.truncatable;
  }

  mounted() {
    this.initialize();
    this.windowResize();
  }

  created() {
    window.addEventListener("resize", this.windowResize);
  }

  windowResize() {
    var w = window.innerWidth;
    if (w <= 550) {
      this.maxDisplay = 220;
    } else if (w >= 551 && w <= 768){
      this.maxDisplay = 280;
    } else if (w >= 769 && w <= 990){
      this.maxDisplay = 380;
    } else if (w >= 991 && w <= 1200){
      this.maxDisplay = 320;
    } else if (w >= 1201 && w <= 1400){
      this.maxDisplay = 400;
    } else if (w >= 1401) { 
      this.maxDisplay = 515;
    }
  }

  toggle(): void {
    this.truncated = !this.truncated;
  }
}
</script>
