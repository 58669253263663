<template>
  <sub-section
    :title="$t('crossmatch_detail')"
    sub-section-id="crossmatch-sample"
    :table-config="crossmatchSampleTableConfig"
    :save-button="!!selectedCrossmatch"
    :disabled="!canSave"
    :save-button-text="$t('save_crossmatch_detail')"
    @save="savePatch()"
    ref="saveCrossmatchSample"
    @table-row-click="selectCrossmatchSample($event)"
    @table-create-row="createCrossmatchSample()"
  >
    <template v-slot:contents>
      <template v-if="!editState || !editState.crossmatchSample">
        {{$t('loading')}}
      </template>
      <fieldset v-else :disabled="!selectedCrossmatch">
        <legend>
          <h5 v-if="!selectedCrossmatchSample" class="legend-title">
            {{$t('new_crossmatch_detail')}}
          </h5>
          <h5 v-else class="legend-title">
            {{$t('selected_crossmatch_detail')}}
          </h5>
        </legend>

        <div class="row">
          <div class="standard-form-group">
            <date-input
              input-id="crossmatch-sample-serum_date"
              rules="required"
              :name="$t('serum_date')"
              v-model="editState.crossmatchSample.serumDate"
            />
          </div>
          <div class="standard-form-group">
            <text-input
              input-id="crossmatch-sample-recipient_id"
              rules="required"
              :cssClasses="{ 'is-valid': !!recipient }"
              @blur="getRecipientInfo($event)"
              :name="$t('recipient_id')"
              v-model="editState.crossmatchSample.recipientClientId"
            />
          </div>
          <div class="standard-form-group">
            <boolean-radio-input
              input-id="crossmatch-sample-type"
              @change="clearAlloValues($event)"
              rules="required"
              :labelName="$t('type')"
              acceptId="allo"
              declineId="auto"
              :acceptLabel="$t('allo')"
              :declineLabel="$t('auto')"
              v-model="editState.crossmatchSample.type"
            />
          </div>
          <div class="standard-form-group">
            <select-input
              select-id="crossmatch-sample-t_cell"
              :name="$t('t_cell_result')"
              v-model="editState.crossmatchSample.tCellResult"
              :options="cellResultLookup"
            />
          </div>
          <div class="standard-form-group">
            <select-input
              select-id="crossmatch-sample-b_cell"
              :name="$t('b_cell_result')"
              v-model="editState.crossmatchSample.bCellResult"
              :options="cellResultLookup"
            />
          </div>
        </div>

        <div class="row" v-if="isAlloSample">
          <div class="standard-form-group">
            <select-input
              select-id="crossmatch-sample-treatment"
              :name="$t('treatment')"
              v-model="editState.crossmatchSample.treatment"
              :options="treatmentLookup"
            />
          </div>
          <div class="standard-form-group">
            <boolean-radio-input
              input-id="crossmatch-sample-pronase"
              :labelName="$t('pronase')"
              :acceptId="true"
              :declineId="false"
              :acceptLabel="$t('yes')"
              :declineLabel="$t('no')"
              v-model="editState.crossmatchSample.pronase"
            />
          </div>
          <div class="standard-form-group">
            <select-input
              select-id="crossmatch-sample-dsa"
              :name="$t('dsa')"
              v-model="editState.crossmatchSample.dsa"
              :options="dsaLookup"
            />
          </div>
        </div>

      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="@/components/deceasedDonors/_locales/common.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/CrossmatchSampleSection.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-facing-decorator';
import { Recipient } from '@/store/recipients/types';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import { xMatchAutologousAllogeneic } from '@/store/lookups/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { ObjectId, NumericCodeValue, GenericCodeValue } from '@/store/types';
import { LabCrossmatch, CrossmatchRecipientSample } from '@/store/labs/types';
import { SerumCrossmatchPageState } from '@/components/deceasedDonors/_SerumCrossmatchSection.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';

export interface CrossmatchSamplePageStage {
  type?: boolean; 
  recipientClientId?: string;
  serumDate?: string;
  tCellResult?: string;
  bCellResult?: string;
  treatment?: string|null;
  pronase?: boolean;
  dsa?: string|null;
}

interface CrossmatchSampleRow {
  _id?: ObjectId;
  serumDate?: string;
  tCellResult?: string;
  bCellResult?: string;
}

// The radio button only returns true/false undefined so
// we need to unpack the value to what API expects: AUTO || ALLO
const ALLO_AUTO_BOOLEAN_VALUES: { [key: string]: string } = {
  'true': xMatchAutologousAllogeneic.Allo,
  'false': xMatchAutologousAllogeneic.Auto
};

@Component({
  components: {
    DateInput,
    TextInput,
    SubSection,
    SelectInput,
    BooleanRadioInput,
  }
})
export default class CrossmatchSampleSection extends mixins(DateUtilsMixin) implements SaveableSection {
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.labs.crossmatchLabs) private crossmatchLabs!: LabCrossmatch[];
  @State(state => state.labs.selectedCrossmatch) private selectedCrossmatch!: LabCrossmatch;
  @State(state => state.labs.selectedCrossmatchSample) private selectedCrossmatchSample!: CrossmatchRecipientSample;
  @State(state => state.pageState.currentPage.serumCrossmatch) editState!: SerumCrossmatchPageState;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.lookups.t_b_cell) private cellResultLookup!: GenericCodeValue[];
  @State(state => state.lookups.crossmatch_treatment_types) private treatmentLookup!: GenericCodeValue[];
  @State(state => state.lookups.crossmatch_dsa_results) private dsaLookup!: GenericCodeValue[];

  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: number;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) private lookupValue!: (code: string|undefined, lookupId: string) => any;

  // Permissions
  get canSave(): boolean {
    return this.checkAllowed('/donors/:donor_id/crossmatch_labs/:crossmatch_lab_id/recipient_samples', 'POST');
  }

  // Save button should only be available if a Crossmatch Lab has been selected
  get saveAllowed(): boolean {
    return this.selectedCrossmatch?._id?.$oid ? true : false;
  }
  
  /**
   * Is the Crossmatch Sample Allo?
   *
   * @returns {boolean} true is the current state is viewing or creating an Allo sample
   */
  get isAlloSample(): boolean {
    if (!this.editState || !this.editState.crossmatchSample) return false;
    return this.editState.crossmatchSample.type === true;
  }

  /**
   * Table data from recipient_samples for the selectedCrossmatch
   *
   * @returns {CrossmatchSampleRow[]} Crossmatch Sample row data
   */
  get crossmatchSampleRows(): CrossmatchSampleRow[] {
    if (!this.selectedCrossmatch || !this.selectedCrossmatch?.recipient_samples) {
      return [];
    }
    const result: CrossmatchSampleRow[] = this.selectedCrossmatch?.recipient_samples.map((crossmatchSample: CrossmatchRecipientSample) => {
      return {
        _id: crossmatchSample._id,
        serumDate: crossmatchSample.test_date ? (this.parseDisplayDateUi(crossmatchSample.test_date) || '-') : '-',
        recipientClientId: crossmatchSample.client_id || '-',
        tCellResult: this.lookupValue(crossmatchSample.t_cell_response_indicator, 't_b_cell') || '-',
        bCellResult: this.lookupValue(crossmatchSample.b_cell_response_indicator, 't_b_cell') || '-',
        type: crossmatchSample.autologous_allogeneic
      };
    });
    return result;
  }

  /**
   * Gets a table configuration for Crossmatch Recipient Samples 
   *
   * @return {TableConfig} Crossmatch Recipient Sample table configuration
   */
  get crossmatchSampleTableConfig(): TableConfig {
    return {
      data: this.crossmatchSampleRows, // TODO: Add data here
      columns: [
        { label: this.$t('serum_date').toString(),    field: 'serumDate',   width: '20%' },
        { label: this.$t('recipient_id').toString(),  field: 'recipientClientId', width: '20%' },
        { label: this.$t('t_cell_result').toString(), field: 'tCellResult', width: '20%' },
        { label: this.$t('b_cell_result').toString(), field: 'bCellResult', width: '20%' },
        { label: this.$t('type').toString(), field: 'type', width: '20%' },
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: !!this.selectedCrossmatch,
      createText: this.$t('create_crossmatch_detail').toString(),
      pagination: true
    };
  }
  
  // Initialize component with or without a record
  public initializeCrossmatchSampleForm(crossmatchSample?: CrossmatchRecipientSample): void {
    // Clear validation errors
    this.$emit('clear');
    // Clear any previous selectedRecipient
    this.$store.commit('recipients/clearRecipient');
    // Commit the selected sample if we have one or clear any selected sample
    if (crossmatchSample) {
      this.$store.commit('labs/selectCrossmatchSample', crossmatchSample);
    } else {
      this.$store.commit('labs/clearCrossmatchSample');
    }
    // Initialize subsection component pageState
    this.$store.commit('pageState/set', {
      pageKey: 'serumCrossmatch',
      componentKey: 'crossmatchSample',
      value: this.buildCrossmatchSamplePageState(crossmatchSample),
    });
  }
  
  /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveCrossmatchSample as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }

  // TODO: FIX SAVE NEXT
  
  public savePatch(): void {
    // Clear errors
    this.$emit('clear');
    // Clear any previous selectedRecipient
    this.$store.commit('recipients/clearRecipient');
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveCrossmatchSample as unknown as SaveProvider;
    // Generate payload based on current edit state
    const payload = {
      donorId: this.clientId,
      crossmatchLabId: this.selectedCrossmatch._id?.$oid,
      id: this.selectedCrossmatchSample?._id?.$oid,
      crossmatchRecipientSampleLab: this.extractPatch(),
    };
    this.$store.dispatch('labs/saveCrossmatchRecipientSample', payload).then((success: SaveResult) => {
      // Fetch crossmatch labs for this donor
      this.$store.dispatch('labs/loadCrossmatchLabs', this.clientId).then((success: any) => {
        // Find the selectedCrossmatch from the new crossmatches
        const selectedCrossmatch = this.crossmatchLabs.find((crossmatch: LabCrossmatch) => {
          return this.selectedCrossmatch._id?.$oid === crossmatch._id?.$oid;
        });
        // If we find it, reload the crossmatch and our detail table
        if (selectedCrossmatch) this.$store.commit('labs/selectCrossmatchLab', selectedCrossmatch);
      });
      // Initialize a blank state
      this.initializeCrossmatchSampleForm();
      // Show success notification
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {CrossmatchRecipientSample} patch object containing field changes
   */
  public extractPatch(): CrossmatchRecipientSample {
    if (!this.editState || !this.editState.crossmatchSample) {
      return {};
    }
    const crossmatchSample = this.editState.crossmatchSample;
    const crossmatchType = crossmatchSample.type?.toString() ? ALLO_AUTO_BOOLEAN_VALUES[crossmatchSample.type.toString()] : null;
    
    return {
      autologous_allogeneic: crossmatchType,
      client_id: crossmatchSample.recipientClientId || null,
      test_date: this.sanitizeDateApi(crossmatchSample.serumDate) || null,
      t_cell_response_indicator: crossmatchSample.tCellResult,
      b_cell_response_indicator: crossmatchSample.bCellResult,
      treatment: crossmatchSample.treatment || null,
      pronase: crossmatchSample.pronase,
      dsa: crossmatchSample.dsa || null
    };
  }

  /**
   * Builds a crossmatchPageState (optional crossmatchSample) 
   *
   * @param crossmatchSample CrossmatchRecipientSample record
   * @returns {CrossmatchSamplePageStage} pageState for this component 
   */ 
  public buildCrossmatchSamplePageState(crossmatchSample?: CrossmatchRecipientSample): CrossmatchSamplePageStage {
    const defaultState: CrossmatchSamplePageStage = {
      serumDate: this.currentDateUi(),
    };

    if (!!crossmatchSample) {
      let crossmatchType: boolean|undefined = undefined;
      if (crossmatchSample.autologous_allogeneic === xMatchAutologousAllogeneic.Allo) crossmatchType = true;
      if (crossmatchSample.autologous_allogeneic === xMatchAutologousAllogeneic.Auto) crossmatchType = false;
      let result: CrossmatchSamplePageStage = {
        type: crossmatchType,
        serumDate: this.parseDateUi(crossmatchSample.test_date || '') || this.currentDateUi(),
        recipientClientId: crossmatchSample.client_id || undefined,
        tCellResult: crossmatchSample.t_cell_response_indicator,
        bCellResult: crossmatchSample.b_cell_response_indicator,
        treatment: crossmatchSample.treatment,
        pronase: crossmatchSample.pronase,
        dsa: crossmatchSample.dsa
      };

      return result;
    }
    return defaultState;
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'test_date'                 : 'crossmatch-sample-serum_date',
    'recipient_id'              : 'crossmatch-sample-recipient_id',
    'autologous_allogeneic'     : 'crossmatch-sample-type',
    't_cell_response_indicator' : 'crossmatch-sample-t_cell',
    'b_cell_response_indicator' : 'crossmatch-sample-b_cell',
    'treatment'                 : 'crossmatch-sample-treatment',
    'pronase'                   : 'crossmatch-sample-pronase',
    'dsa'                       : 'crossmatch-sample-dsa',
  };
  
  // PRIVATE
  
  // Clear Allo values if switching to auto
  private clearAlloValues(event: boolean): void {
    if (ALLO_AUTO_BOOLEAN_VALUES[event.toString()] === xMatchAutologousAllogeneic.Auto) {
      const crossmatchSample = this.editState.crossmatchSample;
      if (crossmatchSample) crossmatchSample.treatment = undefined;
      if (crossmatchSample) crossmatchSample.pronase = undefined;
      if (crossmatchSample) crossmatchSample.dsa = undefined;
    }
  }
  
  // Get the Recipient details or show an error on the recpient field
  private getRecipientInfo(event: { target?: { value?: string } }): void {
    const recipientClientId = event.target?.value;
    // Clear any previous selectedRecipient
    this.$store.commit('recipients/clearRecipient');
    if (!recipientClientId) return;
    this.$store.dispatch('recipients/get', recipientClientId).then((success: any) => {
      return;
    }).catch((error: any) => {
      const recipientIdError = { 
        success: false,
        errorMessages: [this.$t('cannot_save').toString()],
        validationErrors: { client_id: [this.$t('invalid_recipient_id').toString()] }
      };
      this.$emit('handleErrors', recipientIdError);
    });
  }

  /**
   * Builds form edit state based on selected document
   *
   * @param event select event
   */
  private selectCrossmatchSample(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.selectedCrossmatch.recipient_samples) {
      return;
    }
    // Find the selected source document
    const found = this.selectedCrossmatch.recipient_samples.find((each: CrossmatchRecipientSample) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Build form state from selected crossmatch sample
    this.initializeCrossmatchSampleForm(found);
  }

  /**
   * Resets edit state to prepare for entering a new document
   */
  private createCrossmatchSample(): void {
    this.initializeCrossmatchSampleForm();
    this.resetSaveToolbar();
  }
}
</script>
