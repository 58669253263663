export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "name_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "living_donor_id_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID:"])}
      },
      "fr": {
        "name_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom :"])},
        "living_donor_id_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo :"])}
      },
      "en_tgln": {
        "living_donor_id_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID:"])}
      },
      "fr_tgln": {
        "living_donor_id_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN :"])}
      },
      "en_tq": {
        "living_donor_id_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number:"])}
      },
      "fr_tq": {
        "living_donor_id_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ :"])}
      }
    }
  })
}
