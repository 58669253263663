export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "save_donation_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Donation Information"])},
        "donation_information_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation Information"])},
        "transplant_program_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "living_donor_coordinator_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donor Coordinator"])},
        "consented_organs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referred Organ"])},
        "consented_date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Date"])}
      },
      "fr": {
        "save_donation_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les informations sur les donneurs"])},
        "donation_information_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur les donneurs"])},
        "transplant_program_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "living_donor_coordinator_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinateur des donneurs vivants"])},
        "consented_organs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe référencé"])},
        "consented_date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de référence"])}
      }
    }
  })
}
