import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_3 = {
  key: 1,
  class: "legend-title"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: _ctx.$t('xm_info'),
    "sub-section-id": "crossmatch-info",
    tabbableValue: _ctx.$t('test_date'),
    "table-config": _ctx.crossmatchTableConfig,
    "save-button": true,
    disabled: !_ctx.canSave,
    "save-button-text": _ctx.$t('save_crossmatch_info'),
    onSave: _cache[8] || (_cache[8] = ($event: any) => (_ctx.savePatch())),
    ref: "saveCrossmatchInfo",
    onTableRowClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.selectCrossmatchLab($event))),
    onTableCreateRow: _cache[10] || (_cache[10] = ($event: any) => (_ctx.createCrossmatchLab()))
  }, {
    contents: _withCtx(() => [
      (!_ctx.editState || !_ctx.editState.crossmatchInfo)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
          ], 64))
        : (_openBlock(), _createElementBlock("fieldset", {
            key: 1,
            disabled: !_ctx.canSave
          }, [
            _createElementVNode("legend", null, [
              (!_ctx.selectedCrossmatch)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('new_crossmatch')), 1))
                : (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.$t('selected_crossmatch')), 1))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_text_input, {
                  "input-id": "crossmatch-info-donor_id",
                  name: _ctx.$t('donor_id'),
                  modelValue: _ctx.editState.crossmatchInfo.donorId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.crossmatchInfo.donorId) = $event)),
                  disabled: true
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_select_input, {
                  selectId: "crossmatch-info-testing_lab",
                  name: _ctx.$t('testing_lab'),
                  modelValue: _ctx.editState.crossmatchInfo.testingLab,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.crossmatchInfo.testingLab) = $event)),
                  options: _ctx.hlaLaboratoryLookup,
                  "text-key": "name",
                  "value-key": "lab_code",
                  rules: "required"
                }, null, 8, ["name", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_date_input, {
                  ruleKey: "crossmatch_date",
                  "input-id": "crossmatch-info-test_date",
                  rules: "required",
                  name: _ctx.$t('test_date'),
                  modelValue: _ctx.editState.crossmatchInfo.testDate,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.crossmatchInfo.testDate) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_time_input, {
                  ruleKey: "crossmatch_date",
                  inputId: "crossmatch-info-test_time",
                  name: _ctx.$t('test_time'),
                  rules: "required",
                  modelValue: _ctx.editState.crossmatchInfo.testTime,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.crossmatchInfo.testTime) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_text_input, {
                  "input-id": "crossmatch-info-donor_sample_code",
                  name: _ctx.$t('donor_sample_code'),
                  modelValue: _ctx.editState.crossmatchInfo.donorSampleCode,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.crossmatchInfo.donorSampleCode) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_date_input, {
                  "input-id": "crossmatch-info-donor_sample_date",
                  name: _ctx.$t('donor_sample_date'),
                  modelValue: _ctx.editState.crossmatchInfo.donorSampleDate,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.crossmatchInfo.donorSampleDate) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_select_input, {
                  "select-id": "crossmatch-info-sample_type",
                  name: _ctx.$t('sample_type'),
                  modelValue: _ctx.editState.crossmatchInfo.sampleType,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.crossmatchInfo.sampleType) = $event)),
                  options: _ctx.sampleLookup
                }, null, 8, ["name", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_select_input, {
                  "select-id": "crossmatch-info-crossmatch_technique",
                  name: _ctx.$t('method_technique'),
                  modelValue: _ctx.editState.crossmatchInfo.methodTechnique,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.crossmatchInfo.methodTechnique) = $event)),
                  options: _ctx.techniqueLookup
                }, null, 8, ["name", "modelValue", "options"])
              ])
            ])
          ], 8, _hoisted_1))
    ]),
    _: 1
  }, 8, ["title", "tabbableValue", "table-config", "disabled", "save-button-text"]))
}