import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_recovery_information = _resolveComponent("recovery-information")!
  const _component_organ_recovery_data = _resolveComponent("organ-recovery-data")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "recovery-details",
    lookupsToLoad: _ctx.lookupsToLoad,
    overflowVisible: true,
    ref: "recoveryDetails",
    onLoaded: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('recovery_details')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_recovery_information, {
        ref: "recoveryInformation",
        onHandleErrors: _ctx.handleErrors,
        onClear: _ctx.clear,
        onInitializeSection: _cache[0] || (_cache[0] = ($event: any) => (_ctx.initializeForm())),
        "onHook:mounted": _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadedSubsection('recoveryInformation')))
      }, null, 8, ["onHandleErrors", "onClear"]),
      _createVNode(_component_organ_recovery_data, {
        ref: "organRecoveryData",
        onHandleErrors: _ctx.handleErrors,
        onClear: _ctx.clear,
        onInitializeSection: _cache[2] || (_cache[2] = ($event: any) => (_ctx.initializeForm())),
        "onHook:mounted": _cache[3] || (_cache[3] = ($event: any) => (_ctx.loadedSubsection('organRecoveryData'))),
        canSave: _ctx.isGroupWriteable('donor_recovery') || _ctx.isGroupWriteable('donor_recovery_date')
      }, null, 8, ["onHandleErrors", "onClear", "canSave"])
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}