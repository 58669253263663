export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "exd_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXD Donors"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize panel"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse panel"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Options"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Panel"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Style"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor ID"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
        "link_to_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Donor Page"])},
        "donor_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Location"])},
        "change_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Date Time"])},
        "new_exd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New EXD"])},
        "post_release_indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post Release Indicator"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
        "recipient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Name"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient ID"])},
        "link_for_exd_consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link for EXD Consent"])},
        "no_exd_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No EXD Donors"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
      },
      "fr": {
        "exd_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneurs EXD"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le panneau"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire le panneau"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options du panneau"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le panneau"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style du panneau"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Donneur"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "link_to_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page des donneurs"])},
        "donor_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu du donneur"])},
        "change_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure de modifications"])},
        "new_exd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau EXD"])},
        "post_release_indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicateur de post publication"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe"])},
        "recipient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du patient"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Patient"])},
        "link_for_exd_consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien du consentement pour EXD"])},
        "no_exd_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun patient EXD"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
      }
    }
  })
}
