import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["action"]
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "form-group left" }
const _hoisted_6 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_login_one_id = _resolveComponent("login-one-id")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_ctx.editState)
    ? (_openBlock(), _createBlock(_component_validation_observer, {
        key: 0,
        ref: "loginValidations",
        autocomplete: "off",
        tag: "form"
      }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            action: _ctx.getPostUrl,
            method: "post"
          }, [
            (_ctx.showUserSelect)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_select_input, {
                      rules: "required",
                      "select-id": "selected-user",
                      name: _ctx.$t('select_user'),
                      multiple: true,
                      "max-tags": 1,
                      options: _ctx.getFormattedList,
                      modelValue: _ctx.editState.user,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.user) = $event)),
                        _cache[1] || (_cache[1] = (newValue) => _ctx.updateUserId(newValue))
                      ],
                      textKey: "title",
                      valueKey: "value",
                      isLoadingSelectionValues: !_ctx.getFormattedList
                    }, null, 8, ["name", "options", "modelValue", "isLoadingSelectionValues"])
                  ]),
                  (_ctx.fusionAuth)
                    ? (_openBlock(), _createElementBlock("input", {
                        key: 0,
                        type: "hidden",
                        id: "access_token",
                        name: "access_token",
                        value: _ctx.userToken
                      }, null, 8, _hoisted_3))
                    : _createCommentVNode("", true),
                  _createElementVNode("input", {
                    type: "hidden",
                    id: "id",
                    name: "id",
                    value: _ctx.selectedUserId
                  }, null, 8, _hoisted_4),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("input", {
                      type: "submit",
                      value: _ctx.$t('login'),
                      class: "btn btn-primary login",
                      disabled: !_ctx.checkForUser()
                    }, null, 8, _hoisted_6)
                  ]),
                  (_ctx.hasAccessTokenURL && _ctx.oneid)
                    ? (_openBlock(), _createBlock(_component_login_one_id, { key: 1 }))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ], 8, _hoisted_1)
        ]),
        _: 1
      }, 512))
    : _createCommentVNode("", true)
}