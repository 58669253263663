export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "active_allocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Allocations"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Options"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Style"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this panel"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "link_to_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Donor Page"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
        "no_active_allocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Active Allocations"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor ID"])},
        "allocation_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Status"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse Panel"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize Panel"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
      },
      "fr": {
        "active_allocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocations actives"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options du panneau"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style du panneau"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher ce panneau"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "link_to_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page du donateur"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe"])},
        "no_active_allocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune allocation active"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du donneur"])},
        "allocation_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de l'allocation"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau rabattu"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le panneau"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])}
      }
    }
  })
}
