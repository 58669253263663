<template>
  <div>              
    <div class="row skeleton-padding-text">
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>         
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>         
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
    </div>
    <hr/>
    <div class="row skeleton-padding-text">
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>        
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>        
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>        
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class LoadingSection extends Vue {}
</script>
