<template>
 <div class="row">
   <div class="col-sm-12">
    <div class="skeleton-container">
    <table>
      <thead>
        <tr>
          <th v-for="(column,index) in columns" :key="index">  
            <span class="skeleton-box w-100" />
          </th>  
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row,index) in rows" :key="index">
          <td v-for="(column,index) in columns" :key="index">
             <span class="skeleton-box w-100" />
          </td>
        </tr>
      </tbody>
    </table>
    </div>
   </div>
 </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component
export default class LoadingTableView extends Vue {
  @Prop({ default: null }) rows!: number;
  @Prop({ default: null }) columns!: number;
}
</script>
