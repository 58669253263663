import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group-large" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-sm-12" }
const _hoisted_15 = { class: "fieldset" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "standard-form-group-large" }
const _hoisted_18 = { class: "legend-title mb-3" }
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = {
  key: 0,
  class: "form-group"
}
const _hoisted_21 = {
  key: 1,
  class: "form-group"
}
const _hoisted_22 = {
  key: 2,
  class: "form-group"
}
const _hoisted_23 = {
  key: 3,
  class: "form-group"
}
const _hoisted_24 = {
  key: 4,
  class: "form-group"
}
const _hoisted_25 = { class: "standard-form-group-large" }
const _hoisted_26 = { class: "legend-title mb-3" }
const _hoisted_27 = { class: "form-group" }
const _hoisted_28 = {
  key: 0,
  class: "form-group"
}
const _hoisted_29 = {
  key: 1,
  class: "form-group"
}
const _hoisted_30 = {
  key: 2,
  class: "form-group"
}
const _hoisted_31 = {
  key: 3,
  class: "form-group"
}
const _hoisted_32 = {
  key: 4,
  class: "form-group"
}
const _hoisted_33 = { class: "modal-footer-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_hla_input = _resolveComponent("hla-input")!
  const _component_hla_input_group = _resolveComponent("hla-input-group")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_openBlock(), _createBlock(_component_modal_section, {
    "modal-id": `${_ctx.id}-hla-antibody-modal`,
    ref: _ctx.modalSectionRef,
    class: "modal-sticky-header",
    onSwitch: _ctx.onHlaAntibodiesTestModalSwitched
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.hlaAntibodiesModalTitle), 1)
    ]),
    body: _withCtx(() => [
      (!_ctx.editState || !_ctx.editState[_ctx.modalStateComponentKey]!.form)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
          ], 64))
        : (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_text_input, {
                  inputId: `${_ctx.id}-modal-sample_code`,
                  name: _ctx.$t('sample_code'),
                  modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.sample_code,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.sample_code) = $event)),
                  readonly: true
                }, null, 8, ["inputId", "name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_text_input, {
                  inputId: `${_ctx.id}-modal-sample_draw_date`,
                  name: _ctx.$t('sample_draw_date'),
                  modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.sample_draw_date,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.sample_draw_date) = $event)),
                  readonly: true
                }, null, 8, ["inputId", "name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_select_input, {
                  selectId: `${_ctx.id}-modal-hla_lab`,
                  name: _ctx.$t('laboratory'),
                  modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.hla_lab,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.hla_lab) = $event)),
                  options: _ctx.hlaLaboratoryLookup,
                  disabled: true,
                  "text-key": "name",
                  "value-key": "lab_code"
                }, null, 8, ["selectId", "name", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_select_input, {
                  selectId: `${_ctx.id}-modal-testing_method_default`,
                  name: _ctx.$t('testing_method_cap'),
                  modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.testing_method_default,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.testing_method_default) = $event)),
                  options: _ctx.hlaTestingMethodLookup,
                  disabled: true
                }, null, 8, ["selectId", "name", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_text_area_input, {
                  inputId: `${_ctx.id}-modal-comments`,
                  name: _ctx.$t('comments'),
                  modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.comments,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.comments) = $event)),
                  readonly: true
                }, null, 8, ["inputId", "name", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_text_input, {
                  inputId: `${_ctx.id}-modal-sample_tested_date`,
                  name: _ctx.$t('sample_tested_date'),
                  modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.sample_tested_date,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.sample_tested_date) = $event)),
                  readonly: true
                }, null, 8, ["inputId", "name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                (_ctx.showSabClass1)
                  ? (_openBlock(), _createBlock(_component_text_input, {
                      key: 0,
                      inputId: `${_ctx.id}-modal-cpra_class1`,
                      name: `${_ctx.$t('cpras', { class: _ctx.$t('class_i'), cpra: _ctx.$t('cpra') } )}`,
                      modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.cpra_class1,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.cpra_class1) = $event)),
                      calculated: true,
                      readonly: true
                    }, null, 8, ["inputId", "name", "modelValue"]))
                  : (_ctx.showPraClass1)
                    ? (_openBlock(), _createBlock(_component_text_input, {
                        key: 1,
                        inputId: `${_ctx.id}-modal-pra_class1`,
                        name: `${_ctx.$t('cpras', { class: _ctx.$t('class_i'), cpra: _ctx.$t('cpra') } )}`,
                        modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.cpra_class1,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.cpra_class1) = $event)),
                        readonly: true
                      }, null, 8, ["inputId", "name", "modelValue"]))
                    : (_openBlock(), _createBlock(_component_text_input, {
                        key: 2,
                        inputId: `${_ctx.id}-modal-pra_class1`,
                        name: `${_ctx.$t('pras', { class: _ctx.$t('class_i'), pra: _ctx.$t('pra') } )}`,
                        modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.cpra_class1,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.cpra_class1) = $event)),
                        readonly: true
                      }, null, 8, ["inputId", "name", "modelValue"]))
              ]),
              _createElementVNode("div", _hoisted_11, [
                (_ctx.showPraClass2)
                  ? (_openBlock(), _createBlock(_component_text_input, {
                      key: 0,
                      inputId: `${_ctx.id}-modal-pra_class2`,
                      name: `${_ctx.$t('pras', { class: _ctx.$t('class_ii'), pra: _ctx.$t('pra') } )}`,
                      modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.cpra_class2,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.cpra_class2) = $event)),
                      readonly: true
                    }, null, 8, ["inputId", "name", "modelValue"]))
                  : (_ctx.showSabClass2)
                    ? (_openBlock(), _createBlock(_component_text_input, {
                        key: 1,
                        inputId: `${_ctx.id}-modal-cpra_class2`,
                        name: `${_ctx.$t('cpras', { class: _ctx.$t('class_ii'), cpra: _ctx.$t('cpra') } )}`,
                        modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.cpra_class2,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.cpra_class2) = $event)),
                        calculated: true,
                        readonly: true
                      }, null, 8, ["inputId", "name", "modelValue"]))
                    : (_openBlock(), _createBlock(_component_text_input, {
                        key: 2,
                        inputId: `${_ctx.id}-modal-pra_class2`,
                        name: `${_ctx.$t('pras', { class: _ctx.$t('class_ii'), pra: _ctx.$t('pra') } )}`,
                        modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.cpra_class2,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.cpra_class2) = $event)),
                        readonly: true
                      }, null, 8, ["inputId", "name", "modelValue"]))
              ]),
              (_ctx.showCombinedCpra)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_component_text_input, {
                      inputId: `${_ctx.id}-modal-combined_cpra`,
                      name: _ctx.$t('combined_cpra'),
                      modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.combined_cpra,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.combined_cpra) = $event)),
                      calculated: true,
                      readonly: true
                    }, null, 8, ["inputId", "name", "modelValue"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("h6", _hoisted_18, _toDisplayString(_ctx.$t('class_1')), 1),
                      _createElementVNode("div", _hoisted_19, [
                        _createVNode(_component_select_input, {
                          selectId: `${_ctx.id}-modal-testing_method_class1`,
                          name: `${_ctx.$t('testing_methods', { class: _ctx.$t('class_i'), method: _ctx.$t('testing_method') } )}`,
                          modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.testing_method_class1,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.testing_method_class1) = $event)),
                          options: _ctx.hlaTestingMethodLookup,
                          disabled: true
                        }, null, 8, ["selectId", "name", "modelValue", "options"])
                      ]),
                      (_ctx.showSabClass1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                            _createVNode(_component_select_input, {
                              selectId: `${_ctx.id}-modal-testing_kit_class1`,
                              name: `${_ctx.$t('testing_kits', { class: _ctx.$t('class_i'), kit: _ctx.$t('testing_kit') } )}`,
                              modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.testing_kit_class1,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.testing_kit_class1) = $event)),
                              options: _ctx.hlaTestingKitClass1Options,
                              disabled: true,
                              hideExpired: false
                            }, null, 8, ["selectId", "name", "modelValue", "options"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showSabClass1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                            _createVNode(_component_hla_input, {
                              inputId: `${_ctx.id}-modal-antibodies_class1_acceptable`,
                              name: _ctx.$t('acceptable'),
                              modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_acceptable,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_acceptable) = $event)),
                              alleleSpecific: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_allele_specific,
                              alphaBetaSpecific: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_alpha_beta_specific,
                              readonly: true,
                              inputClass: "hla-input hla-acceptable",
                              disabledEmptyText: _ctx.$t('no_acceptable_antibodies_kit_i')
                            }, null, 8, ["inputId", "name", "modelValue", "alleleSpecific", "alphaBetaSpecific", "disabledEmptyText"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showSabClass1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                            _createVNode(_component_hla_input_group, {
                              inputId: `${_ctx.id}-modal-antibodies_class1_unacceptable`,
                              name: _ctx.$t('unacceptable'),
                              modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_unacceptable,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_unacceptable) = $event)),
                              readonly: true,
                              inputClass: "hla-input hla-unacceptable",
                              alleleSpecific: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_unacceptable_allele_specific,
                              alphaBeta: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_unacceptable_alpha_beta,
                              "enable-epitopes": true,
                              epitopes: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.epitopes_unacceptable
                            }, null, 8, ["inputId", "name", "modelValue", "alleleSpecific", "alphaBeta", "epitopes"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showSabClass1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            _createVNode(_component_hla_input_group, {
                              inputId: `${_ctx.id}-modal-antibodies_class1_indeterminate`,
                              name: _ctx.$t('indeterminate'),
                              modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_indeterminate,
                              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_indeterminate) = $event)),
                              readonly: true,
                              inputClass: "hla-input hla-indeterminate",
                              alleleSpecific: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_indeterminate_allele_specific,
                              alphaBeta: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_indeterminate_alpha_beta,
                              "enable-epitopes": true,
                              epitopes: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.epitopes_indeterminate
                            }, null, 8, ["inputId", "name", "modelValue", "alleleSpecific", "alphaBeta", "epitopes"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showSabClass1 && _ctx.showClass1PossibleAlleleSpecific)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                            _createVNode(_component_hla_input, {
                              inputId: `${_ctx.id}-modal-antibodies_class1_possible_allele_specific`,
                              name: _ctx.$t('possible_allele_specific_field'),
                              modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_possible_allele_specific,
                              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class1_possible_allele_specific) = $event)),
                              readonly: true,
                              inputClass: "hla-input hla-possible-allele-specific",
                              calculated: true,
                              calculatedText: _ctx.$t('legacy_data_indicator'),
                              calculatedHoverText: _ctx.$t('legacy_data_explanation')
                            }, null, 8, ["inputId", "name", "modelValue", "calculatedText", "calculatedHoverText"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("h6", _hoisted_26, _toDisplayString(_ctx.$t('class_2')), 1),
                      _createElementVNode("div", _hoisted_27, [
                        _createVNode(_component_select_input, {
                          selectId: `${_ctx.id}-modal-testing_method_class2`,
                          name: `${_ctx.$t('testing_methods', { class: _ctx.$t('class_ii'), method: _ctx.$t('testing_method') } )}`,
                          modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.testing_method_class2,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.testing_method_class2) = $event)),
                          options: _ctx.hlaTestingMethodLookup,
                          disabled: true
                        }, null, 8, ["selectId", "name", "modelValue", "options"])
                      ]),
                      (_ctx.showSabClass2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                            _createVNode(_component_select_input, {
                              selectId: `${_ctx.id}-modal-testing_kit_class2`,
                              name: `${_ctx.$t('testing_kits', { class: _ctx.$t('class_ii'), kit: _ctx.$t('testing_kit') } )}`,
                              modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.testing_kit_class2,
                              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.testing_kit_class2) = $event)),
                              options: _ctx.hlaTestingKitClass2Options,
                              disabled: true,
                              hideExpired: false
                            }, null, 8, ["selectId", "name", "modelValue", "options"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showSabClass2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                            _createVNode(_component_hla_input, {
                              inputId: `${_ctx.id}-modal-antibodies_class2_acceptable`,
                              name: _ctx.$t('acceptable'),
                              modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_acceptable,
                              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_acceptable) = $event)),
                              alleleSpecific: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_allele_specific,
                              alphaBetaSpecific: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_alpha_beta_specific,
                              readonly: true,
                              inputClass: "hla-input hla-acceptable",
                              disabledEmptyText: _ctx.$t('no_acceptable_antibodies_kit_ii')
                            }, null, 8, ["inputId", "name", "modelValue", "alleleSpecific", "alphaBetaSpecific", "disabledEmptyText"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showSabClass2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                            _createVNode(_component_hla_input_group, {
                              inputId: `${_ctx.id}-modal-antibodies_class2_unacceptable`,
                              name: _ctx.$t('unacceptable'),
                              modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_unacceptable,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_unacceptable) = $event)),
                              readonly: true,
                              inputClass: "hla-input hla-unacceptable",
                              alleleSpecific: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_unacceptable_allele_specific,
                              alphaBeta: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_unacceptable_alpha_beta
                            }, null, 8, ["inputId", "name", "modelValue", "alleleSpecific", "alphaBeta"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showSabClass2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                            _createVNode(_component_hla_input_group, {
                              inputId: `${_ctx.id}-modal-antibodies_class2_indeterminate`,
                              name: _ctx.$t('indeterminate'),
                              modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_indeterminate,
                              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_indeterminate) = $event)),
                              readonly: true,
                              inputClass: "hla-input hla-indeterminate",
                              alleleSpecific: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_indeterminate_allele_specific,
                              alphaBeta: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_indeterminate_alpha_beta
                            }, null, 8, ["inputId", "name", "modelValue", "alleleSpecific", "alphaBeta"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showSabClass2 && _ctx.showClass2PossibleAlleleSpecific)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                            _createVNode(_component_hla_input, {
                              inputId: `${_ctx.id}-modal-antibodies_class2_possible_allele_specific`,
                              name: _ctx.$t('possible_allele_specific_field'),
                              modelValue: _ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_possible_allele_specific,
                              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.editState[_ctx.modalStateComponentKey]!.form!.antibodies!.class2_possible_allele_specific) = $event)),
                              readonly: true,
                              inputClass: "hla-input hla-possible-allele-specific",
                              calculated: true,
                              calculatedText: _ctx.$t('legacy_data_indicator'),
                              calculatedHoverText: _ctx.$t('legacy_data_explanation')
                            }, null, 8, ["inputId", "name", "modelValue", "calculatedText", "calculatedHoverText"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ])
          ]))
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("a", {
          type: "button",
          class: "btn btn-primary",
          href: "#${id}-modal",
          onClick: _cache[25] || (_cache[25] = _withModifiers(($event: any) => (_ctx.previousHlaAntibodiesTestModal()), ["prevent"]))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'caret-left'],
            "fixed-width": ""
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('previous_test_result')), 1)
        ]),
        _createElementVNode("a", {
          type: "button",
          class: "btn btn-primary",
          href: "#${id}-modal",
          onClick: _cache[26] || (_cache[26] = _withModifiers(($event: any) => (_ctx.nextHlaAntibodiesTestModal()), ["prevent"]))
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t('next_test_result')) + " ", 1),
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'caret-right'],
            "fixed-width": ""
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modal-id", "onSwitch"]))
}