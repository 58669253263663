import axios from 'axios';
import { ActionTree } from 'vuex';
import { APIResponseWithRules } from '@/types';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { ValidationsState } from '@/store/validations/types';

export const actions: ActionTree<ValidationsState, RootState> = {
  /**
   * Load 'new' validation rules for specified resource 'view'
   * 
   * @param view the endpoint route to the resource we need validation rules for
   * @param action validation route string ('new' or 'edit')
   * @param prefix optional key to wrap around rules
   * @param query optional query parameters to include in edit rules request
   * @returns {Promise} promise resolves when rules are loaded, otherwise promise rejects with error
   */
  loadNew({ commit }, {view, action, prefix, query}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.validations.new_validations, [[':view', view], [':action', action]]);
      axios.get(url, { params: query }).then((response: any) => {
        const responseData = response.data;
        let sanitized = responseData;
        // If 'prefix' is specified in the dispatch, nest the rules under a prefix key within the 'rules' object
        if (prefix) {
          commit('resetPrefix', prefix);
          sanitized = {
            rules: {
              [prefix]: responseData.rules,
            }
          };
        }
        // Pass value to mutation to place in store
        commit('set', sanitized);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  /**
   * Load 'edit' validation rules for specified resource 'view'
   * 
   * @param view the endpoint route to the resource we need validation rules for
   * @param action validation route string ('new' or 'edit')
   * @param clientId identifier in route for the resource we need validation rules for 
   * @param prefix optional key to wrap around rules
   * @param query optional query parameters to include in edit rules request
   * @returns {Promise} promise resolves when rules are loaded, otherwise promise rejects with error
   */
  loadEdit({ commit }, {view, action, clientId, prefix, query}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.validations.edit_validations, [[':view', view], [':action', action], [':id', clientId]]);
      axios.get(url, { params: query }).then((response: any) => {
        const responseData = response.data;
        let sanitized = responseData;
        // If 'prefix' is specified in the dispatch, nest the rules under a prefix key within the 'rules' object
        if (prefix) {
          commit('resetPrefix', prefix);
          sanitized = {
            rules: {
              [prefix]: responseData.rules,
            }
          };
        }
        // Pass value to mutation to place in store
        commit('set', sanitized);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  /**
   * Load validation rules for specified route
   * 
   * @param route the endpoint route to the resource we need validation rules for
   * @param payload object containing key/values for route
   * @returns {Promise} promise resolves when rules are loaded, otherwise promise rejects with error
   */
  loadValidations({ commit }, {route, payload, prefix}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(route, payload);
      axios.get(url).then((response: any) => {
        const responseData = response.data;
        let sanitized = responseData;
        // If 'prefix' is specified in the dispatch, nest the rules under a prefix key within the 'rules' object
        if (prefix) {
          sanitized = {
            rules: {
              [prefix]: responseData.rules,
            }
          };
        }
        commit('set', sanitized);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  /**
   * Load validation rules for specified route
   * 
   * @param route the endpoint route to the resource we need validation rules for
   * @param payload object containing key/values for route
   * @param prefix optional key to wrap around rules
   * @param query optional query parameters to include in edit rules request
   * @returns {Promise} promise resolves when rules are loaded, otherwise promise rejects with error
   */
  loadValidationsWithActions({ commit }, { route, payload, prefix, query }): Promise<void> {
    return new Promise<any>((resolve, reject) => {
      const url = APIRoute(route, payload);
      axios.get(url, { params: query }).then((response: any) => {
        const responseData = response.data;
        let sanitized = responseData;
        // If 'prefix' is specified in the dispatch, nest the rules under a prefix key within the 'rules' object
        if (prefix) {
          commit('resetPrefix', prefix);
          sanitized = {
            rules: {
              [prefix]: responseData.rules,
            }
          };
        }
        commit('set', sanitized);
        const permittedActions = responseData.permitted_actions;
        resolve({ permitted_actions: permittedActions });
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  loadEditDeath({ commit }, {clientId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.validations.death_edit_validations, [[':recipient_id', clientId]]);
      axios.get(url).then((response: any) => {
        const responseData: APIResponseWithRules = response.data;
        commit('resetPrefix', 'recipient_death');
        commit('set', {
          rules: {
            recipient_death: responseData.rules,
          }
        });
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  loadRecoveryInfoEditValidations({ commit }, {livingDonorId, livingDonorJourneyId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.living_donors.recoveryInfo.edit_validations, [[':living_donor_id', livingDonorId], [':living_donor_journey_id', livingDonorJourneyId]]);
      axios.get(url).then((response: any) => {
        const responseData: any[] = response.data;
        // Pass value to mutation to place in store
        commit('setAppendRecoveryInfoRules', responseData);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  loadOrganSpecificEditValidations({ commit }, { recipientId, journeyId }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.organ_specific_details.edit_validations, [[':recipient_id', recipientId], [':journey_id', journeyId]]);
      axios.get(url).then((response: any) => {
        const responseData: APIResponseWithRules = response.data;
        commit('resetPrefix', 'organ_specific_details');
        commit('set', { rules: { organ_specific_details: responseData.rules }});
        resolve();
      }).catch((error) => {
        console.warn(error);
        reject();
      });
    });
  },
  loadNewHeartHemodynamicValidations({ commit }, {recipientId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.recipients.heart_hemodynamic.new_validations, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        const responseData: any[] = response.data;
        // Pass value to mutation to place in store
        commit('set', responseData);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  reset({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('reset');
    });
  }
};
