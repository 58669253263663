import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { sanitizeArray } from '@/utils';
import { ValidationsState, Rules } from '@/store/validations/types';

// Check if key exists in nested object
function propExists(obj: any, path: string): boolean {
  return !!path.split(".").reduce((obj, prop) => {
      return obj && obj[prop] ? obj[prop] : undefined;
  }, obj);
  // Note: returns false if key found but value is undefined, 
  // only returns true if key found with value
}

/**
 * Derive v-mask directive for data entry based on format_mask rule arguments
 * @param ruleOptions format_mask options string
 * @returns {string|null} v-mask if applicable, null otherwise
 */
function maskFromFormatMask(ruleOptions: string): string|null {
  switch (ruleOptions) {
    case 'email address':
      return null;
    default:
      return ruleOptions;
  }
}

function checkForUpdateRights(rootGetters: any): boolean {
  const url: any = window.location || '';

  // check we're on a recipients or donor page && not a hla page
  const recipientsURL = (new RegExp("/recipients(/.*/)").test(url)) && !(new RegExp("/recipients(/.*/)hla(#[a-zA-Z0-9'-]+|)$").test(url));
  const donorsURL = (new RegExp("/donors(/.*/)").test(url)) && !(new RegExp("/donors(/.*/)#hla-typing$").test(url));

  // if we're on a recipients/donors page
  if (recipientsURL || !donorsURL) {
    // check patch permissions (hla will have these turned off)
    if (recipientsURL) {
      return rootGetters["users/checkAllowed"]('/recipients/:id', 'PATCH');
      // recipient hla permission will be determined by /api/v1/recipients/:recipient_id/hla_typing on-page
    } else {
      return rootGetters["users/checkAllowed"]('/donors/:id', 'PATCH');
      // donor hla permission will be determined by /api/v1/donors/:donor_id/hla_typing on-page
    }
  } else {
    // otherwise return true
    return true;
  }

  // why are we doing this? 
  // because due to the monolithic nature of hla permissions it reports 'may_update: true' in 'edit'
  // so we need to also check the patch permissions for edit to make sure they're able to do updates.
}

export const getters: GetterTree<ValidationsState, RootState> = {

  /**
   * check if user save the record by checking ruleSet for may_update value
   * @param newRecord {boolean} based on whether on new record or editing record
   * @returns {boolean} based on may_update, can save true/false
   */
  canSaveGetter(state, getters, rootState, rootGetters) {
    return (newRecord: boolean): any => {
      if (newRecord) {
        // for new pages, you don't need to check patch permissions
        const ruleSet = state.ruleSet || undefined;
        const mayUpdate = ruleSet && ruleSet.may_update !== undefined ? ruleSet.may_update : true;
        return mayUpdate;
      } else {
        // for edit pages also check patch permissions (don't do it for allocations, journeys, or other pages)
        const checkEditPermissions = checkForUpdateRights(rootGetters);
        const ruleSet = state.ruleSet || undefined;
        const mayUpdate = ruleSet && ruleSet.may_update !== undefined ? ruleSet.may_update : true;
        return mayUpdate && checkEditPermissions;
      }
    };
  },

  // get ruleSet from store
  getRuleSet(state): Rules|undefined {
    return state.ruleSet;
  },

  /**
   * Return validation rules by searching ruleSet API object with ruleKey, 
   * or using vee-validate rules attribute
   * 
   * @param ruleSet list of field attributes with their corresponding validation rules
   * @param ruleKey key to search ruleSet by to find relevant validation rules
   * @params vee-validate rules attribute
   * @returns {string} vee-validate rules to apply to form field
   */
  getRules(state: ValidationsState) {
    return (ruleSet: any, ruleKey: string, rules: string): any => {
      if (!rules && ruleSet && ruleKey) {
        if (propExists(ruleSet, ruleKey)) {
          // key with value found in ruleSet, return rule
          const fieldRules = ruleKey.split('.').reduce((o: any, i: any) =>o[i], ruleSet) || {};
          return fieldRules.validation_rules ? fieldRules.validation_rules : undefined;
        } else {
          // return hard-coded rule
          return rules;
        }
      } else {
        return rules;
      }
    };
  },

  /**
   * Used by the ValidationAsterisk control
   * Provides an initial set of values for cross-field validation rules
   * 
   * @returns initial set of cross field validation attributes
   */
  getBaseCrossValues(state, getters, rootState, rootGetters) {
    const currentPage = rootState.pageState.currentPage;
    return {
      urgent: currentPage.demographics ? currentPage.demographics.internal.urgent : rootGetters["recipients/isUrgent"],
      gender_sex_different: currentPage.demographics ? currentPage.demographics.personal.gender_sex_different : null,
      plan_code: currentPage.demographics ? currentPage.demographics.personal.insurance_type : null,
      eligible_assessment : rootGetters["recipients/hasJourneyWithAssessmentEligible"],
      date: currentPage.generalClinical ? currentPage.generalClinical.measurement.date : null,
      height: currentPage.generalClinical ? currentPage.generalClinical.measurement.height : null,
      weight: currentPage.generalClinical ? currentPage.generalClinical.measurement.weight : null,
      lab_other: currentPage.virologyResults ? currentPage.virologyResults.lab_other : null,
      exceptional_distribution: currentPage.donationInformation ? currentPage.donationInformation.exceptional_distribution : null,
    };
  },

  /**
   * Part of user permissions
   * If given readonly value use that, otherwise check the page validations api response
   * (from /new or /edit) and check for a .may_update attribute.
   * If .may_update is true return false (can edit), otherwise return true (readonly).
   * 
   * @param readonly boolean 
   * @returns { boolean }
   */
  isReadOnly(state, getters) {
    return (readonly?: boolean): boolean => {
      if ( readonly ) {
        return readonly;
      } else {
        const ruleSet = getters.getRuleSet || null;
        return ruleSet && ruleSet.may_update ? !ruleSet.may_update : false;
      }
    };
  },

  /**
   * Returns value of may_update from validation endpoint data
   * 
   * @returns { boolean }
   */
  mayUpdate(state, getters) {
    return (prefix: string|null): boolean => {
      const ruleSet = getters.getRuleSet || null;
      const value = prefix ? ruleSet[prefix] : ruleSet;
      return value ? value.may_update : false;
    };
},

  /**
   * Allows to check for particular sections write access
   * .../new & /edit provide groups which describes whether a section
   * should have write access.
   * 
   * 
   * @param groupName name of group, e.g. 'donor_documents' 
   * @returns { boolean }
   */
  isGroupWriteable(state, getters) {
    return (groupName: string): boolean => {
      const groups = getters.getRuleSet && getters.getRuleSet.groups ? getters.getRuleSet.groups : null;
      if (!groupName || !groups) return false;
      return groups[groupName] === 'full';
    };
  },

  /**
   * Allows to check if a particular permission group exists or not
   * 
   * @param groupName name of group, e.g. 'donor_documents' 
   * @returns { boolean }
   */
   groupExists(state, getters) {
    return (groupName: string): boolean => {
      const groups = getters.getRuleSet && getters.getRuleSet.groups ? getters.getRuleSet.groups : null;
      if (!groupName || !groups) return false;
      return groupName in groups;
    };
  },

  /**
   * Part of user permissions
   * Find permission for field
   *
   * @param ruleKey key to search ruleSet by to find relevant validation rules
   * @param fallBackGroup group to check against when the ruleKey doesn't exist
   * @returns { boolean }
   */
   isFieldWriteable(state, getters) {
    return (ruleKey: string|null, fallBackGroup?: string|null): boolean => {

      if (ruleKey) {
        // get ruleSet from store
        const sanitizedRuleKey = typeof ruleKey == 'string' ? ruleKey : null; // make sure ruleKey is a string or null
        const ruleSet = getters.getRuleSet || null; 

        // get fieldRules
        const fieldRules = ruleSet && sanitizedRuleKey && propExists(ruleSet, sanitizedRuleKey) ? sanitizedRuleKey.split('.').reduce((o: any, i: any) =>o[i], ruleSet) || {} : null;

        // get group and find out what permissions it has
        if (fieldRules && fieldRules.group) {
          const groupKeys = fieldRules.group ? fieldRules.group.split(' ') : []; // group can be multiple
          const setGroups = ruleSet.groups || {}; // get the group permissions
          let result = false; // default to read only
          groupKeys.map((group: string) => {
            if (setGroups[group]) {
              result = setGroups[group] == 'full' ? true : false;
            }
          });
          return result;
        } else {
          return false;
        }
      } else {
        // if no ruleKey, rely on fallback group
        return getters.isGroupWriteable(fallBackGroup);
      }
    };
  },

  /**
   * Check if field-level validations contract includes specified field at all.
   * NOTE: this lets the UI hide fields that are not included in the contract
   *
   * @param ruleKey key to search ruleSet by to find validation rules
   * @returns { boolean } true only if the rule is validated in the contract
   */
  checkPropExists(state, getters) {
    return (ruleKey: string): boolean => {
      return propExists(state.ruleSet, ruleKey);
    };
  },

  /**
   * Check if field-level validations contract includes ALL of the specified fields.
   *
   * @param ruleKeys keys to search ruleSet by to find validation rules
   * @returns { boolean } true only if the rule is validated in the contract
   */
  checkAllPropsExist(state, getters) {
    return (ruleKeys: string[]): boolean => {
      const existingRuleKeys: string[] = [];
      ruleKeys.forEach((ruleKey: string) => {
        if (propExists(state.ruleSet, ruleKey)) existingRuleKeys.push(ruleKey);
      });
      return existingRuleKeys.length === ruleKeys.length;
    };
  },

  /**
   * Check if field-level validations contract includes ANY of the specified fields.
   *
   * @param ruleKeys keys to search ruleSet by to find validation rules
   * @returns { boolean } true only if the rule is validated in the contract
   */
  checkAtLeastOnePropExists(state, getters) {
    return (ruleKeys: string[]): boolean => {
      const existingRuleKeys: string[] = [];
      ruleKeys.forEach((ruleKey: string) => {
        if (propExists(state.ruleSet, ruleKey)) existingRuleKeys.push(ruleKey);
      });
      return existingRuleKeys.length > 0;
    };
  },

  getMaskFromRules(state, getters) {
    return (ruleKey: string): string|null => {
      const rulesDefined = getters.getRules(state.ruleSet, ruleKey, '');
      if (!rulesDefined) return null;

      const ruleDefinitions = rulesDefined.split('|');
      if (!ruleDefinitions || ruleDefinitions.length === 0) return null;

      let result: string|null = null;
      ruleDefinitions.forEach((ruleDefinition: string) => {
        const definitionParts = ruleDefinition.split(':');
        const ruleName = definitionParts.length > 0 ? definitionParts[0] : '';
        const ruleOptions = definitionParts.length > 1 ? definitionParts[1] : '';
        switch(ruleName) {
          case 'format_mask':
            result = maskFromFormatMask(ruleOptions);
            break;
        }
      });
      return result;
    };
  },

  /**
   * Check if field-level validation rule indicates a specified field must be blank.
   * NOTE: this lets the UI show or hide fields based on API per-client contracts.
   *
   * @param ruleKey key to search ruleSet by to find relevant validation rules
   * @returns { boolean } true only if rule tells us file must be blank
   */
  mustBeBlank(state, getters) {
    return (ruleKey: string): boolean => {
      let result = false;

      // Load valuesfrom other fields that can affect this field's validity
      const getBaseCrossValues = getters.getBaseCrossValues;

      // Get rules for the specified field
      const getRules = getters.getRules(state.ruleSet, ruleKey, '');

      const ruleDefinitions = getRules.split('|');
      if (!ruleDefinitions || ruleDefinitions.length === 0) return false;

      // Check each rule defined for the field
      ruleDefinitions.forEach((ruleDefinition: string) => {
        const definitionParts = ruleDefinition.split(':');
        const ruleName = definitionParts.length > 0 ? definitionParts[0] : '';
        const ruleOptions = definitionParts.length > 1 ? definitionParts[1] : '';
        switch(ruleName) {
          case 'blank_unless_includes':
            const optionMatches = ruleOptions.match(/@(.*),(\[.*\])/);
            const targetFieldName = (optionMatches && optionMatches.length > 1) ? optionMatches[1] : '';
            const targetFieldValue = getBaseCrossValues[targetFieldName];
            const choices =  (optionMatches && optionMatches.length > 2) ? optionMatches[2] : '';
            const sanitizedChoices = sanitizeArray(choices.split(','));
            const targetFieldIncludes = sanitizedChoices.includes(targetFieldValue);
            if (!targetFieldIncludes) result = true;
            break;
        }
      });

      return result;
    };
  },
};
