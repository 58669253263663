import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_3 = {
  key: 1,
  class: "legend-title"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_10 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "standard-form-group-6column-xlarge-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    "sub-section-id": "policy-responses",
    tabbableValue: _ctx.$t('response'),
    "total-records": _ctx.getResponses.length,
    "table-config": _ctx.responseTableConfig,
    onTableRowClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectResponse($event))),
    onTableCreateRow: _cache[7] || (_cache[7] = ($event: any) => (_ctx.createResponse())),
    saveButton: _ctx.canSave,
    disabled: !_ctx.selectedDecisionId && (_ctx.canSave || _ctx.newJourney),
    "save-button-text": _ctx.$t('save_sub_decision'),
    ref: "saveResponsesSection",
    onSave: _cache[8] || (_cache[8] = ($event: any) => (_ctx.saveResponse())),
    title: _ctx.$t('sub_decisions')
  }, {
    contents: _withCtx(() => [
      (_ctx.editState)
        ? (_openBlock(), _createElementBlock("fieldset", {
            key: 0,
            disabled: !_ctx.canSave
          }, [
            _createElementVNode("legend", null, [
              (!_ctx.editState._id)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('new_sub_decision')), 1))
                : (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.$t('selected_sub_decision')), 1))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_select_input, {
                  selectId: "response",
                  ruleKey: "response_type",
                  name: _ctx.$t('response'),
                  options: _ctx.decisionResponseTypes,
                  modelValue: _ctx.editState.response,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.response) = $event))
                }, null, 8, ["name", "options", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_date_input, {
                  "input-id": "response_date",
                  ruleKey: "response_datetime",
                  name: _ctx.$t('response_date'),
                  modelValue: _ctx.editState.response_date,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.response_date) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_time_input, {
                  inputId: "response_time",
                  name: _ctx.$t('response_time'),
                  rules: "required",
                  modelValue: _ctx.editState.response_time,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.response_time) = $event))
                }, null, 8, ["name", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_select_input, {
                  selectId: "responding_hospital_id",
                  ruleKey: "responding_hospital_id",
                  name: _ctx.$t('responding_transplant_center'),
                  modelValue: _ctx.editState.responding_hospital_id,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.responding_hospital_id) = $event)),
                  options: _ctx.respondingTransplantPrograms
                }, null, 8, ["name", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_text_input, {
                  "input-id": "responding_physician",
                  ruleKey: "responding_physician",
                  name: _ctx.$t('physician'),
                  modelValue: _ctx.editState.responding_physician,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.responding_physician) = $event))
                }, null, 8, ["name", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_text_area_input, {
                  inputId: "responding_comments",
                  name: _ctx.$t('comments'),
                  ruleKey: "comments",
                  modelValue: _ctx.editState.comments,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.comments) = $event))
                }, null, 8, ["name", "modelValue"])
              ])
            ])
          ], 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tabbableValue", "total-records", "table-config", "saveButton", "disabled", "save-button-text", "title"]))
}