export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "Hepatitis B Core Antibody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepatitis B Core Antibody"])},
        "Hepatitis B Surface Antigen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepatitis B Surface Antigen"])},
        "Hepatitis C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepatitis C"])},
        "ctr.connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTR system unexpectedly closed the network connection without sending any information back to Afflo."])},
        "warning": {
          "ipos": {
            "hsp": {
              "runHSPMatchOnDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove donor National Donor ID. HSP Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove donor National Donor ID. HSP Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove donor National Donor ID. HSP Recipients from CTR will not be included in the Allocation"])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTR system was unable to allocate HSP Kidney matches, and returned a generic error to the Afflo system instead. This is usually the result of an incomplete donor profile or incomplete donor HLA typing. HSP Recipients from CTR will not be included in the Allocation."])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are incomplete. HSP Recipients from CTR will not be included in the Allocation"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are not confirmed. HSP Recipients from CTR will not be included in the Allocation."])}
                },
                "hsp": {
                  "missingTest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Missing Donor Serology (", _interpolate(_named("parsedValue")), "). HSP Recipients from CTR will not be included in the Allocation."])},
                  "invalidSerologyResult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid Donor Serology (", _interpolate(_named("parsedValue")), "). HSP Recipients from CTR will not be included in the Allocation."])},
                  "pendingFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pending Donor Serology (", _interpolate(_named("parsedValue")), "). HSP Recipients from CTR will not be included in the Allocation."])}
                }
              },
              "getHSPMatchforDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Out-of-Province donor does not have any offers to an in-province recipient in CTR. Please check National Donor ID field. HSP Recipients from CTR will not be included in the Allocation."])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing CTD Number, please check National Donor ID field (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456)."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456)."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456)."])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Offer will be canceled in Afflo and not in CTR"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. There is an HSP recipient with a higher ranking on the CTR allocation list."])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. HSP recipient already has an active offer for the same organ on the CTR allocation list."])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer acceptance to CTR. HSP recipient already accepted an offer for the same organ in CTR."])}
                }
              }
            },
            "hsh": {
              "runHsHeartMatch": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove donor National Donor ID. HSH Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove donor National Donor ID. HSH Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove donor National Donor ID. HSH Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTR system was unable to allocate HS Heart matches, and returned a generic error to the Afflo system instead. This is usually the result of an incomplete donor profile or incomplete donor HLA typing. HSH Recipients from CTR will not be included in the Allocation."])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are incomplete. HSH Recipients from CTR will not be included in the Allocation"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are not confirmed. HSH Recipients from CTR will not be included in the Allocation."])}
                }
              },
              "getHsHeartMatchResults": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Out-of-Province donor does not have any offers to an in-province recipient in CTR. Please check National Donor ID field. HSH Recipients from CTR will not be included in the Allocation."])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing CTD Number, please check National Donor ID field (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456)."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456)."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456)."])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Offer will be canceled in Afflo and not in CTR"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. There is an HSH recipient with a higher ranking on the CTR allocation list."])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. HSH recipient already has an active offer for the same organ on the CTR allocation list."])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer acceptance to CTR. HSH recipient already accepted an offer for the same organ in CTR."])}
                }
              }
            }
          }
        },
        "instructions": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])},
          "ipos": {
            "hsp": {
              "ctr": {
                "connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for CTR connectivity issue</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])}
              },
              "runHSPMatchOnDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For in-province donors, leave \"National Donor ID\" blank.</li><li>Once the CTD number is removed, re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For in-province donors, leave \"National Donor ID\" blank.</li><li>Once the CTD number is removed, re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For in-province donors, leave \"National Donor ID\" blank.</li><li>Once the CTD number is removed, re-run allocation.</li></ol>"])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Generic Error</h4><ol><li>Verify in Afflo whether or not the donor profile is complete:<ol><li>Kidney must be consented.</li><li>ABO must be entered.</li><li>Height must be entered.</li><li>Weight must be entered.</li><li>Donor Type must be entered: NDD or DCD.</li></ol></li><li>If the donor profile is incomplete, enter the missing information into Afflo.</li><li>Verify in Afflo whether or not the donor HLA typing is complete:<ol><li>HLA typing values must be entered.</li><li>HLA typing \"Typing Incomplete\" checkbox must not be ticked.</li></ol></li><li>If the donor HLA typing is incomplete, notify donor HLA lab and request that they verify and enter the missing HLA typing values into Afflo.</li><li>Once both the donor profile and the donor HLA typing are complete in Afflo, re-run allocation.</li><li>If the error persists, contact Service Desk and review Business Continuity Plan.</li></ol>"])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"Afflo ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into Afflo.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"Afflo ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into Afflo.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])}
                },
                "hsp": {
                  "missingTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Donor Serology</h4><ol><li>Confirm if serology test results are available.</li><li>For Out-of-Province donors, enter results into Afflo.</li><li>If any results are not available, advise serology lab to enter required results.</li><li>Once all results are available, re-run allocation.</li></ol>"])},
                  "invalidSerologyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Donor Serology</h4><ol><li>Confirm if serology test results are available.</li><li>For Out-of-Province donors, enter results into Afflo.</li><li>If any results are not available, advise serology lab to enter required results.</li><li>Once all results are available, re-run allocation.</li></ol>"])},
                  "pendingFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Donor Serology</h4><ol><li>Confirm if serology test results are available.</li><li>For Out-of-Province donors, enter results into Afflo.</li><li>If any results are not available, advise serology lab to enter required results.</li><li>Once all results are available, re-run allocation.</li></ol>"])}
                }
              },
              "getHSPMatchforDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for no offers from donor</h4><ol><li>Confirm if the \"National Donor ID\" number is correct.</li><li>Remove any invalid CTD number from \"National Donor ID\".</li><li>Enter Out-of-Province donor CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li><li>If the error persists, contact the Out-of-Province program to request an offer be sent to CTR for the intended recipient.</li></ol>"])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Missing CTD</h4><ol><li>Confirm if the \"National Donor ID\" number is available.</li><li>For Canadian Out-of-Province donors, enter the CTD number in the correct format.</li><li>After confirming CTD number is displayed in Afflo, re-run allocation.</li><li>For US Donors, select \"Acknowledge\" and then \"Accept\" to proceed to allocate to recipients.</li><li>For any other issues, call Service Desk.</li></ol>"])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Submit a Web service form to CTR to cancel the accepted offer in CTR.</li></ol>"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for manually created offer in CTR interface</h4><ol><li>Login to CTR.</li><li>Navigate to selected Donor’s HSP Kidney allocation.</li><li>Identify the HSP recipient with an offer manually created in the CTR interface.</li><li>Withdraw the offer to the identified HSP recipient in CTR.</li><li>Return to Afflo, and withdraw this offer to the selected HSP recipient.</li><li>In Afflo, re-offer to the selected HSP recipient.</li></ol>"])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])}
                }
              }
            },
            "hsh": {
              "ctr": {
                "connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for CTR connectivity issue</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])}
              },
              "runHsHeartMatch": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For in-province donors, leave \"National Donor ID\" blank.</li><li>Once the CTD number is removed, re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For in-province donors, leave \"National Donor ID\" blank.</li><li>Once the CTD number is removed, re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For in-province donors, leave \"National Donor ID\" blank.</li><li>Once the CTD number is removed, re-run allocation.</li></ol>"])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Generic Error</h4><ol><li>Verify in Afflo whether or not the donor profile is complete:<ol><li>Heart must be consented.</li><li>ABO must be entered.</li><li>Height must be entered.</li><li>Weight must be entered.</li><li>Donor Type must be entered: NDD or DCD.</li></ol></li><li>If the donor profile is incomplete, enter the missing information into Afflo.</li><li>Verify in Afflo whether or not the donor HLA typing is complete:<ol><li>HLA typing values must be entered.</li><li>HLA typing \"Typing Incomplete\" checkbox must not be ticked.</li></ol></li><li>If the donor HLA typing is incomplete, notify donor HLA lab and request that they verify and enter the missing HLA typing values into Afflo.</li><li>Once both the donor profile and the donor HLA typing are complete in Afflo, re-run allocation.</li><li>If the error persists, contact Service Desk and review Business Continuity Plan.</li></ol>"])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"Afflo ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into Afflo.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"Afflo ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into Afflo.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])}
                }
              },
              "getHsHeartMatchResults": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\"</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\"</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\"</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for no offers from donor</h4><ol><li>Confirm if the \"National Donor ID\" number is correct.</li><li>Remove any invalid CTD number from \"National Donor ID\".</li><li>Enter Out-of-Province donor CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li><li>If the error persists, contact the Out-of-Province program to request an offer be sent to CTR for the intended recipient.</li></ol>"])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Missing CTD</h4><ol><li>Confirm if the \"National Donor ID\" number is available.</li><li>For Canadian Out-of-Province donors, enter the CTD number in the correct format.</li><li>After confirming CTD number is displayed in Afflo, re-run allocation.</li><li>For US Donors, select \"Acknowledge\" and then \"Accept\" to proceed to allocate to recipients.</li><li>For any other issues, call Service Desk.</li></ol>"])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Submit a Web service form to CTR to cancel the accepted offer in CTR.</li></ol>"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for manually created offer in CTR interface</h4><ol><li>Login to CTR.</li><li>Navigate to selected Donor’s HS Heart allocation.</li><li>Identify the HSH recipient with an offer manually created in the CTR interface.</li><li>Withdraw the offer to the identified HSH recipient in CTR.</li><li>Return to Afflo, and withdraw this offer to the selected HSH recipient.</li><li>In Afflo, re-offer to the selected HSH recipient.</li></ol>"])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])}
                }
              }
            }
          }
        }
      },
      "fr": {
        "Hepatitis B Core Antibody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepatitis B Core Antibody"])},
        "Hepatitis B Surface Antigen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepatitis B Surface Antigen"])},
        "Hepatitis C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepatitis C"])},
        "ctr.connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTR system unexpectedly closed the network connection without sending any information back to Afflo."])},
        "warning": {
          "ipos": {
            "hsp": {
              "runHSPMatchOnDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove donor National Donor ID. HSP Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove donor National Donor ID. HSP Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove donor National Donor ID. HSP Recipients from CTR will not be included in the Allocation"])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTR system was unable to allocate HSP Kidney matches, and returned a generic error to the Afflo system instead. This is usually the result of an incomplete donor profile or incomplete donor HLA typing. HSP Recipients from CTR will not be included in the Allocation."])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are incomplete. HSP Recipients from CTR will not be included in the Allocation"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are not confirmed. HSP Recipients from CTR will not be included in the Allocation."])}
                },
                "hsp": {
                  "missingTest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Missing Donor Serology (", _interpolate(_named("parsedValue")), "). HSP Recipients from CTR will not be included in the Allocation."])},
                  "invalidSerologyResult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid Donor Serology (", _interpolate(_named("parsedValue")), "). HSP Recipients from CTR will not be included in the Allocation."])},
                  "pendingFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pending Donor Serology (", _interpolate(_named("parsedValue")), "). HSP Recipients from CTR will not be included in the Allocation."])}
                }
              },
              "getHSPMatchforDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Out-of-Province donor does not have any offers to an in-province recipient in CTR. Please check National Donor ID field. HSP Recipients from CTR will not be included in the Allocation."])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing CTD Number, please check National Donor ID field (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456)."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456)."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456)."])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Offer will be canceled in Afflo and not in CTR"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. There is an HSP recipient with a higher ranking on the CTR allocation list."])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. HSP recipient already has an active offer for the same organ on the CTR allocation list."])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer acceptance to CTR. HSP recipient already accepted an offer for the same organ in CTR."])}
                }
              }
            },
            "hsh": {
              "runHsHeartMatch": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove donor National Donor ID. HSH Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove donor National Donor ID. HSH Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove donor National Donor ID. HSH Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTR system was unable to allocate HS Heart matches, and returned a generic error to the Afflo system instead. This is usually the result of an incomplete donor profile or incomplete donor HLA typing. HSH Recipients from CTR will not be included in the Allocation."])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are incomplete. HSH Recipients from CTR will not be included in the Allocation"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are not confirmed. HSH Recipients from CTR will not be included in the Allocation."])}
                }
              },
              "getHsHeartMatchResults": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Out-of-Province donor does not have any offers to an in-province recipient in CTR. Please check National Donor ID field. HSH Recipients from CTR will not be included in the Allocation."])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing CTD Number, please check National Donor ID field (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456)."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456)."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field (format: CTD123456)."])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Offer will be canceled in Afflo and not in CTR"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. There is an HSH recipient with a higher ranking on the CTR allocation list."])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. HSH recipient already has an active offer for the same organ on the CTR allocation list."])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer acceptance to CTR. HSH recipient already accepted an offer for the same organ in CTR."])}
                }
              }
            }
          }
        },
        "instructions": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])},
          "ipos": {
            "hsp": {
              "ctr": {
                "connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for CTR connectivity issue</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])}
              },
              "runHSPMatchOnDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For in-province donors, leave \"National Donor ID\" blank.</li><li>Once the CTD number is removed, re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For in-province donors, leave \"National Donor ID\" blank.</li><li>Once the CTD number is removed, re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For in-province donors, leave \"National Donor ID\" blank.</li><li>Once the CTD number is removed, re-run allocation.</li></ol>"])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Generic Error</h4><ol><li>Verify in Afflo whether or not the donor profile is complete:<ol><li>Kidney must be consented.</li><li>ABO must be entered.</li><li>Height must be entered.</li><li>Weight must be entered.</li><li>Donor Type must be entered: NDD or DCD.</li></ol></li><li>If the donor profile is incomplete, enter the missing information into Afflo.</li><li>Verify in Afflo whether or not the donor HLA typing is complete:<ol><li>HLA typing values must be entered.</li><li>HLA typing \"Typing Incomplete\" checkbox must not be ticked.</li></ol></li><li>If the donor HLA typing is incomplete, notify donor HLA lab and request that they verify and enter the missing HLA typing values into Afflo.</li><li>Once both the donor profile and the donor HLA typing are complete in Afflo, re-run allocation.</li><li>If the error persists, contact Service Desk and review Business Continuity Plan.</li></ol>"])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"Afflo ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into Afflo.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"Afflo ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into Afflo.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])}
                },
                "hsp": {
                  "missingTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Donor Serology</h4><ol><li>Confirm if serology test results are available.</li><li>For Out-of-Province donors, enter results into Afflo.</li><li>If any results are not available, advise serology lab to enter required results.</li><li>Once all results are available, re-run allocation.</li></ol>"])},
                  "invalidSerologyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Donor Serology</h4><ol><li>Confirm if serology test results are available.</li><li>For Out-of-Province donors, enter results into Afflo.</li><li>If any results are not available, advise serology lab to enter required results.</li><li>Once all results are available, re-run allocation.</li></ol>"])},
                  "pendingFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Donor Serology</h4><ol><li>Confirm if serology test results are available.</li><li>For Out-of-Province donors, enter results into Afflo.</li><li>If any results are not available, advise serology lab to enter required results.</li><li>Once all results are available, re-run allocation.</li></ol>"])}
                }
              },
              "getHSPMatchforDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for no offers from donor</h4><ol><li>Confirm if the \"National Donor ID\" number is correct.</li><li>Remove any invalid CTD number from \"National Donor ID\".</li><li>Enter Out-of-Province donor CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li><li>If the error persists, contact the Out-of-Province program to request an offer be sent to CTR for the intended recipient.</li></ol>"])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Missing CTD</h4><ol><li>Confirm if the \"National Donor ID\" number is available.</li><li>For Canadian Out-of-Province donors, enter the CTD number in the correct format.</li><li>After confirming CTD number is displayed in Afflo, re-run allocation.</li><li>For US Donors, select \"Acknowledge\" and then \"Accept\" to proceed to allocate to recipients.</li><li>For any other issues, call Service Desk.</li></ol>"])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Submit a Web service form to CTR to cancel the accepted offer in CTR.</li></ol>"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for manually created offer in CTR interface</h4><ol><li>Login to CTR.</li><li>Navigate to selected Donor’s HSP Kidney allocation.</li><li>Identify the HSP recipient with an offer manually created in the CTR interface.</li><li>Withdraw the offer to the identified HSP recipient in CTR.</li><li>Return to Afflo, and withdraw this offer to the selected HSP recipient.</li><li>In Afflo, re-offer to the selected HSP recipient.</li></ol>"])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])}
                }
              }
            },
            "hsh": {
              "ctr": {
                "connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for CTR connectivity issue</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])}
              },
              "runHsHeartMatch": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For in-province donors, leave \"National Donor ID\" blank.</li><li>Once the CTD number is removed, re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For in-province donors, leave \"National Donor ID\" blank.</li><li>Once the CTD number is removed, re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For in-province donors, leave \"National Donor ID\" blank.</li><li>Once the CTD number is removed, re-run allocation.</li></ol>"])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Generic Error</h4><ol><li>Verify in Afflo whether or not the donor profile is complete:<ol><li>Heart must be consented.</li><li>ABO must be entered.</li><li>Height must be entered.</li><li>Weight must be entered.</li><li>Donor Type must be entered: NDD or DCD.</li></ol></li><li>If the donor profile is incomplete, enter the missing information into Afflo.</li><li>Verify in Afflo whether or not the donor HLA typing is complete:<ol><li>HLA typing values must be entered.</li><li>HLA typing \"Typing Incomplete\" checkbox must not be ticked.</li></ol></li><li>If the donor HLA typing is incomplete, notify donor HLA lab and request that they verify and enter the missing HLA typing values into Afflo.</li><li>Once both the donor profile and the donor HLA typing are complete in Afflo, re-run allocation.</li><li>If the error persists, contact Service Desk and review Business Continuity Plan.</li></ol>"])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"Afflo ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into Afflo.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"Afflo ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into Afflo.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])}
                }
              },
              "getHsHeartMatchResults": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\"</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\"</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\"</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for no offers from donor</h4><ol><li>Confirm if the \"National Donor ID\" number is correct.</li><li>Remove any invalid CTD number from \"National Donor ID\".</li><li>Enter Out-of-Province donor CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li><li>If the error persists, contact the Out-of-Province program to request an offer be sent to CTR for the intended recipient.</li></ol>"])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Missing CTD</h4><ol><li>Confirm if the \"National Donor ID\" number is available.</li><li>For Canadian Out-of-Province donors, enter the CTD number in the correct format.</li><li>After confirming CTD number is displayed in Afflo, re-run allocation.</li><li>For US Donors, select \"Acknowledge\" and then \"Accept\" to proceed to allocate to recipients.</li><li>For any other issues, call Service Desk.</li></ol>"])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\".</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-run allocation.</li></ol>"])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Submit a Web service form to CTR to cancel the accepted offer in CTR.</li></ol>"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for manually created offer in CTR interface</h4><ol><li>Login to CTR.</li><li>Navigate to selected Donor’s HS Heart allocation.</li><li>Identify the HSH recipient with an offer manually created in the CTR interface.</li><li>Withdraw the offer to the identified HSH recipient in CTR.</li><li>Return to Afflo, and withdraw this offer to the selected HSH recipient.</li><li>In Afflo, re-offer to the selected HSH recipient.</li></ol>"])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])}
                }
              }
            }
          }
        }
      },
      "en_tgln": {
        "Hepatitis B Core Antibody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepatitis B Core Antibody"])},
        "Hepatitis B Surface Antigen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepatitis B Surface Antigen"])},
        "Hepatitis C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepatitis C"])},
        "ctr.connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTR system unexpectedly closed the network connection without sending any information back to OATS."])},
        "warning": {
          "ipos": {
            "hsp": {
              "runHSPMatchOnDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove Ontario donor National Donor ID in iTransplant. HSP Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove Ontario donor National Donor ID in iTransplant. HSP Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove Ontario donor National Donor ID in iTransplant. HSP Recipients from CTR will not be included in the Allocation"])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTR system was unable to allocate HSP Kidney matches, and returned a generic error to the OATS system instead. This is usually the result of an incomplete donor profile or incomplete donor HLA typing. HSP Recipients from CTR will not be included in the Allocation."])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are incomplete. HSP Recipients from CTR will not be included in the Allocation"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are not confirmed. HSP Recipients from CTR will not be included in the Allocation."])}
                },
                "hsp": {
                  "missingTest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Missing Donor Serology (", _interpolate(_named("parsedValue")), "). HSP Recipients from CTR will not be included in the Allocation."])},
                  "invalidSerologyResult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid Donor Serology (", _interpolate(_named("parsedValue")), "). HSP Recipients from CTR will not be included in the Allocation."])},
                  "pendingFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pending Donor Serology (", _interpolate(_named("parsedValue")), "). HSP Recipients from CTR will not be included in the Allocation."])}
                }
              },
              "getHSPMatchforDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Out-of-Province donor does not have any offers to an Ontario recipient in CTR. Please check National Donor ID field in iTransplant. HSP Recipients from CTR will not be included in the Allocation."])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing CTD Number, please check National Donor ID field in iTransplant (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456)."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456)."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456)."])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Offer will be canceled in OATS and not in CTR"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. There is an HSP recipient with a higher ranking on the CTR allocation list."])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. HSP recipient already has an active offer for the same organ on the CTR allocation list."])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer acceptance to CTR. HSP recipient already accepted an offer for the same organ in CTR."])}
                }
              }
            },
            "hsh": {
              "runHsHeartMatch": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove Ontario donor National Donor ID in iTransplant. HSH Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove Ontario donor National Donor ID in iTransplant. HSH Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove Ontario donor National Donor ID in iTransplant. HSH Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTR system was unable to allocate HS Heart matches, and returned a generic error to the OATS system instead. This is usually the result of an incomplete donor profile or incomplete donor HLA typing. HSH Recipients from CTR will not be included in the Allocation."])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are incomplete. HSH Recipients from CTR will not be included in the Allocation"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are not confirmed. HSH Recipients from CTR will not be included in the Allocation."])}
                }
              },
              "getHsHeartMatchResults": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Out-of-Province donor does not have any offers to an Ontario recipient in CTR. Please check National Donor ID field in iTransplant. HSH Recipients from CTR will not be included in the Allocation."])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing CTD Number, please check National Donor ID field in iTransplant (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456)."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456)."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456)."])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Offer will be canceled in OATS and not in CTR"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. There is an HSH recipient with a higher ranking on the CTR allocation list."])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. HSH recipient already has an active offer for the same organ on the CTR allocation list."])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer acceptance to CTR. HSH recipient already accepted an offer for the same organ in CTR."])}
                }
              }
            }
          }
        },
        "instructions": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])},
          "ipos": {
            "hsp": {
              "ctr": {
                "connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for CTR connectivity issue</h4><ol><li>Contact TGLN Service Desk and review Business Continuity Plan.</li></ol>"])}
              },
              "runHSPMatchOnDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Ontario donors, leave \"National Donor ID\" blank in iTransplant.</li><li>Once the CTD number is removed, re-push to OATS and re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Ontario donors, leave \"National Donor ID\" blank in iTransplant.</li><li>Once the CTD number is removed, re-push to OATS and re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Ontario donors, leave \"National Donor ID\" blank in iTransplant.</li><li>Once the CTD number is removed, re-push to OATS and re-run allocation.</li></ol>"])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Generic Error</h4><ol><li>Verify in OATS whether or not the donor profile is complete:<ol><li>Kidney must be consented.</li><li>ABO must be entered.</li><li>Height must be entered.</li><li>Weight must be entered.</li><li>Donor Type must be entered: NDD or DCD.</li></ol></li><li>If the donor profile is incomplete, enter the missing information into iTransplant and re-push to OATS.</li><li>Verify in OATS whether or not the donor HLA typing is complete:<ol><li>HLA typing values must be entered.</li><li>HLA typing \"Typing Incomplete\" checkbox must not be ticked.</li></ol></li><li>If the donor HLA typing is incomplete, notify donor HLA lab and request that they verify and enter the missing HLA typing values into OATS.</li><li>Once both the donor profile and the donor HLA typing are complete in OATS, re-run allocation.</li><li>If the error persists, contact Service Desk and review Business Continuity Plan.</li></ol>"])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"TGLN ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into OATS.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"TGLN ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into OATS.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])}
                },
                "hsp": {
                  "missingTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Donor Serology</h4><ol><li>In iTransplant, confirm if serology test results are available.</li><li>For Out-of-Province donors, transcribe results into iTransplant.</li><li>If any results are not available, advise serology lab to enter required results.</li><li>Once all results are available, re-push to OATS and re-run allocation.</li></ol>"])},
                  "invalidSerologyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Donor Serology</h4><ol><li>In iTransplant, confirm if serology test results are available.</li><li>For Out-of-Province donors, transcribe results into iTransplant.</li><li>If any results are not available, advise serology lab to enter required results.</li><li>Once all results are available, re-push to OATS and re-run allocation.</li></ol>"])},
                  "pendingFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Donor Serology</h4><ol><li>In iTransplant, confirm if serology test results are available.</li><li>For Out-of-Province donors, transcribe results into iTransplant.</li><li>If any results are not available, advise serology lab to enter required results.</li><li>Once all results are available, re-push to OATS and re-run allocation.</li></ol>"])}
                }
              },
              "getHSPMatchforDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for no offers from donor</h4><ol><li>In iTransplant, confirm if the \"National Donor ID\" number is correct.</li><li>Remove any invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>Enter Out-of-Province donor CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li><li>If the error persists, contact the Out-of-Province program to request an offer be sent to CTR for the intended Ontario recipient.</li></ol>"])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Missing CTD</h4><ol><li>In iTransplant, confirm if the \"National Donor ID\" number is available.</li><li>For Canadian Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is resolved in iTransplant, re-push to OATS.</li><li>After confirming CTD number is displayed in OATS, re-run allocation.</li><li>For US Donors, select \"Acknowledge\" and then \"Accept\" to proceed to allocate to Ontario recipients.</li><li>For any other issues, call TGLN ServiceDesk.</li></ol>"])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Submit a Web service form to CTR to cancel the accepted offer in CTR.</li></ol>"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact TGLN Service Desk and review Business Continuity Plan.</li></ol>"])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for manually created offer in CTR interface</h4><ol><li>Login to CTR.</li><li>Navigate to selected Donor’s HSP Kidney allocation.</li><li>Identify the Ontario HSP recipient with an offer manually created in the CTR interface.</li><li>Withdraw the offer to the identified Ontario HSP recipient in CTR.</li><li>Return to OATS, and withdraw this offer to the selected HSP recipient.</li><li>In OATS, re-offer to the selected HSP recipient.</li></ol>"])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact TGLN Service Desk and review Business Continuity Plan.</li></ol>"])}
                }
              }
            },
            "hsh": {
              "ctr": {
                "connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for CTR connectivity issue</h4><ol><li>Contact TGLN Service Desk and review Business Continuity Plan.</li></ol>"])}
              },
              "runHsHeartMatch": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Ontario donors, leave \"National Donor ID\" blank in iTransplant.</li><li>Once the CTD number is removed, re-push to OATS and re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Ontario donors, leave \"National Donor ID\" blank in iTransplant.</li><li>Once the CTD number is removed, re-push to OATS and re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Ontario donors, leave \"National Donor ID\" blank in iTransplant.</li><li>Once the CTD number is removed, re-push to OATS and re-run allocation.</li></ol>"])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Generic Error</h4><ol><li>Verify in OATS whether or not the donor profile is complete:<ol><li>Heart must be consented.</li><li>ABO must be entered.</li><li>Height must be entered.</li><li>Weight must be entered.</li><li>Donor Type must be entered: NDD or DCD.</li></ol></li><li>If the donor profile is incomplete, enter the missing information into iTransplant and re-push to OATS.</li><li>Verify in OATS whether or not the donor HLA typing is complete:<ol><li>HLA typing values must be entered.</li><li>HLA typing \"Typing Incomplete\" checkbox must not be ticked.</li></ol></li><li>If the donor HLA typing is incomplete, notify donor HLA lab and request that they verify and enter the missing HLA typing values into OATS.</li><li>Once both the donor profile and the donor HLA typing are complete in OATS, re-run allocation.</li><li>If the error persists, contact Service Desk and review Business Continuity Plan.</li></ol>"])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"TGLN ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into OATS.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"TGLN ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into OATS.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])}
                }
              },
              "getHsHeartMatchResults": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for no offers from donor</h4><ol><li>In iTransplant, confirm if the \"National Donor ID\" number is correct.</li><li>Remove any invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>Enter Out-of-Province donor CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li><li>If the error persists, contact the Out-of-Province program to request an offer be sent to CTR for the intended Ontario recipient.</li></ol>"])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Missing CTD</h4><ol><li>In iTransplant, confirm if the \"National Donor ID\" number is available.</li><li>For Canadian Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is resolved in iTransplant, re-push to OATS.</li><li>After confirming CTD number is displayed in OATS, re-run allocation.</li><li>For US Donors, select \"Acknowledge\" and then \"Accept\" to proceed to allocate to Ontario recipients.</li><li>For any other issues, call TGLN ServiceDesk.</li></ol>"])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Submit a Web service form to CTR to cancel the accepted offer in CTR.</li></ol>"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact TGLN Service Desk and review Business Continuity Plan.</li></ol>"])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for manually created offer in CTR interface</h4><ol><li>Login to CTR.</li><li>Navigate to selected Donor’s HS Heart allocation.</li><li>Identify the Ontario HSH recipient with an offer manually created in the CTR interface.</li><li>Withdraw the offer to the identified Ontario HSH recipient in CTR.</li><li>Return to OATS, and withdraw this offer to the selected HSH recipient.</li><li>In OATS, re-offer to the selected HSH recipient.</li></ol>"])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact TGLN Service Desk and review Business Continuity Plan.</li></ol>"])}
                }
              }
            }
          }
        }
      },
      "fr_tgln": {
        "Hepatitis B Core Antibody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepatitis B Core Antibody"])},
        "Hepatitis B Surface Antigen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepatitis B Surface Antigen"])},
        "Hepatitis C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepatitis C"])},
        "ctr.connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTR system unexpectedly closed the network connection without sending any information back to OATS."])},
        "warning": {
          "ipos": {
            "hsp": {
              "runHSPMatchOnDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove Ontario donor National Donor ID in iTransplant. HSP Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove Ontario donor National Donor ID in iTransplant. HSP Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove Ontario donor National Donor ID in iTransplant. HSP Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTR system was unable to allocate HSP Kidney matches, and returned a generic error to the OATS system instead. This is usually the result of an incomplete donor profile or incomplete donor HLA typing. HSP Recipients from CTR will not be included in the Allocation."])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are incomplete. HSP Recipients from CTR will not be included in the Allocation"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are not confirmed. HSP Recipients from CTR will not be included in the Allocation."])}
                },
                "hsp": {
                  "missingTest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Missing Donor Serology (", _interpolate(_named("parsedValue")), "). HSP Recipients from CTR will not be included in the Allocation."])},
                  "invalidSerologyResult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid Donor Serology (", _interpolate(_named("parsedValue")), "). HSP Recipients from CTR will not be included in the Allocation."])},
                  "pendingFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pending Donor Serology (", _interpolate(_named("parsedValue")), "). HSP Recipients from CTR will not be included in the Allocation."])}
                }
              },
              "getHSPMatchforDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Out-of-Province donor does not have any offers to an Ontario recipient in CTR. Please check National Donor ID field in iTransplant. HSP Recipients from CTR will not be included in the Allocation."])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing CTD Number, please check National Donor ID field in iTransplant (format: CTD123456). HSP Recipients from CTR will not be included in the Allocation."])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456)."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456)."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456)."])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Offer will be canceled in OATS and not in CTR"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de synchroniser l'offre avec le CTR. Il y a un destinataire HSP avec un rang plus élevé sur la liste d'attribution CTR."])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de synchroniser l'offre avec le CTR. Le destinataire HSP a déjà une offre active pour le même organe sur la liste d'attribution CTR."])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer acceptance to CTR. HSP recipient already accepted an offer for the same organ in CTR."])}
                }
              }
            },
            "hsh": {
              "runHsHeartMatch": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove Ontario donor National Donor ID in iTransplant. HSH Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove Ontario donor National Donor ID in iTransplant. HSH Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please remove Ontario donor National Donor ID in iTransplant. HSH Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTR system was unable to allocate HS Heart matches, and returned a generic error to the OATS system instead. This is usually the result of an incomplete donor profile or incomplete donor HLA typing. HSH Recipients from CTR will not be included in the Allocation."])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are incomplete. HSH Recipients from CTR will not be included in the Allocation"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA results are not confirmed. HSH Recipients from CTR will not be included in the Allocation."])}
                }
              },
              "getHsHeartMatchResults": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])}
                  }
                },
                "patient": {
                  "error": {
                    "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])}
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Out-of-Province donor does not have any offers to an Ontario recipient in CTR. Please check National Donor ID field in iTransplant. HSH Recipients from CTR will not be included in the Allocation."])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing CTD Number, please check National Donor ID field in iTransplant (format: CTD123456). HSH Recipients from CTR will not be included in the Allocation."])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456)."])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456)."])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CTD Number, please check Out-of-Province donor National Donor ID field in iTransplant (format: CTD123456)."])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Offer will be canceled in OATS and not in CTR"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de synchroniser l'offre avec le CTR. Il y a un destinataire HSH avec un rang plus élevé sur la liste d'attribution CTR."])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de synchroniser l'offre avec le CTR. Le destinataire HSH a déjà une offre active pour le même organe sur la liste d'attribution CTR."])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer acceptance to CTR. HSH recipient already accepted an offer for the same organ in CTR."])}
                }
              }
            }
          }
        },
        "instructions": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact Service Desk and review Business Continuity Plan.</li></ol>"])},
          "ipos": {
            "hsp": {
              "ctr": {
                "connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for CTR connectivity issue</h4><ol><li>Contact TGLN Service Desk and review Business Continuity Plan.</li></ol>"])}
              },
              "runHSPMatchOnDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Ontario donors, leave \"National Donor ID\" blank in iTransplant.</li><li>Once the CTD number is removed, re-push to OATS and re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Ontario donors, leave \"National Donor ID\" blank in iTransplant.</li><li>Once the CTD number is removed, re-push to OATS and re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Ontario donors, leave \"National Donor ID\" blank in iTransplant.</li><li>Once the CTD number is removed, re-push to OATS and re-run allocation.</li></ol>"])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Generic Error</h4><ol><li>Verify in OATS whether or not the donor profile is complete:<ol><li>Kidney must be consented.</li><li>ABO must be entered.</li><li>Height must be entered.</li><li>Weight must be entered.</li><li>Donor Type must be entered: NDD or DCD.</li></ol></li><li>If the donor profile is incomplete, enter the missing information into iTransplant and re-push to OATS.</li><li>Verify in OATS whether or not the donor HLA typing is complete:<ol><li>HLA typing values must be entered.</li><li>HLA typing \"Typing Incomplete\" checkbox must not be ticked.</li></ol></li><li>If the donor HLA typing is incomplete, notify donor HLA lab and request that they verify and enter the missing HLA typing values into OATS.</li><li>Once both the donor profile and the donor HLA typing are complete in OATS, re-run allocation.</li><li>If the error persists, contact Service Desk and review Business Continuity Plan.</li></ol>"])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"TGLN ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into OATS.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"TGLN ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into OATS.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])}
                },
                "hsp": {
                  "missingTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Donor Serology</h4><ol><li>In iTransplant, confirm if serology test results are available.</li><li>For Out-of-Province donors, transcribe results into iTransplant.</li><li>If any results are not available, advise serology lab to enter required results.</li><li>Once all results are available, re-push to OATS and re-run allocation.</li></ol>"])},
                  "invalidSerologyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Donor Serology</h4><ol><li>In iTransplant, confirm if serology test results are available.</li><li>For Out-of-Province donors, transcribe results into iTransplant.</li><li>If any results are not available, advise serology lab to enter required results.</li><li>Once all results are available, re-push to OATS and re-run allocation.</li></ol>"])},
                  "pendingFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Donor Serology</h4><ol><li>In iTransplant, confirm if serology test results are available.</li><li>For Out-of-Province donors, transcribe results into iTransplant.</li><li>If any results are not available, advise serology lab to enter required results.</li><li>Once all results are available, re-push to OATS and re-run allocation.</li></ol>"])}
                }
              },
              "getHSPMatchforDonor": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])}
                  },
                  "patient": {
                    "error": {
                      "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])}
                    }
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for no offers from donor</h4><ol><li>In iTransplant, confirm if the \"National Donor ID\" number is correct.</li><li>Remove any invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>Enter Out-of-Province donor CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li><li>If the error persists, contact the Out-of-Province program to request an offer be sent to CTR for the intended Ontario recipient.</li></ol>"])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Missing CTD</h4><ol><li>In iTransplant, confirm if the \"National Donor ID\" number is available.</li><li>For Canadian Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is resolved in iTransplant, re-push to OATS.</li><li>After confirming CTD number is displayed in OATS, re-run allocation.</li><li>For US Donors, select \"Acknowledge\" and then \"Accept\" to proceed to allocate to Ontario recipients.</li><li>For any other issues, call TGLN ServiceDesk.</li></ol>"])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Submit a Web service form to CTR to cancel the accepted offer in CTR.</li></ol>"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact TGLN Service Desk and review Business Continuity Plan.</li></ol>"])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for manually created offer in CTR interface</h4><ol><li>Login to CTR.</li><li>Navigate to selected Donor’s HSP Kidney allocation.</li><li>Identify the Ontario HSP recipient with an offer manually created in the CTR interface.</li><li>Withdraw the offer to the identified Ontario HSP recipient in CTR.</li><li>Return to OATS, and withdraw this offer to the selected HSP recipient.</li><li>In OATS, re-offer to the selected HSP recipient.</li></ol>"])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact TGLN Service Desk and review Business Continuity Plan.</li></ol>"])}
                }
              }
            },
            "hsh": {
              "ctr": {
                "connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for CTR connectivity issue</h4><ol><li>Contact TGLN Service Desk and review Business Continuity Plan.</li></ol>"])}
              },
              "runHsHeartMatch": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Ontario donors, leave \"National Donor ID\" blank in iTransplant.</li><li>Once the CTD number is removed, re-push to OATS and re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Ontario donors, leave \"National Donor ID\" blank in iTransplant.</li><li>Once the CTD number is removed, re-push to OATS and re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Ontario donors, leave \"National Donor ID\" blank in iTransplant.</li><li>Once the CTD number is removed, re-push to OATS and re-run allocation.</li></ol>"])}
                  }
                },
                "error": {
                  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Generic Error</h4><ol><li>Verify in OATS whether or not the donor profile is complete:<ol><li>Heart must be consented.</li><li>ABO must be entered.</li><li>Height must be entered.</li><li>Weight must be entered.</li><li>Donor Type must be entered: NDD or DCD.</li></ol></li><li>If the donor profile is incomplete, enter the missing information into iTransplant and re-push to OATS.</li><li>Verify in OATS whether or not the donor HLA typing is complete:<ol><li>HLA typing values must be entered.</li><li>HLA typing \"Typing Incomplete\" checkbox must not be ticked.</li></ol></li><li>If the donor HLA typing is incomplete, notify donor HLA lab and request that they verify and enter the missing HLA typing values into OATS.</li><li>Once both the donor profile and the donor HLA typing are complete in OATS, re-run allocation.</li><li>If the error persists, contact Service Desk and review Business Continuity Plan.</li></ol>"])}
                },
                "vxm": {
                  "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"TGLN ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into OATS.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])},
                  "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for HLA Typing</h4><ol><li>Identify donor \"TGLN ID\" number.</li><li>Notify donor HLA lab and request that they verify and enter the missing HLA typing values into OATS.</li><li>Once the HLA information is available, re-run allocation.</li></ol>"])}
                }
              },
              "getHsHeartMatchResults": {
                "data": {
                  "error": {
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])}
                  },
                  "patient": {
                    "error": {
                      "patientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])}
                    }
                  }
                }
              },
              "getOfferDonorInfo": {
                "data": {
                  "error": {
                    "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for no offers from donor</h4><ol><li>In iTransplant, confirm if the \"National Donor ID\" number is correct.</li><li>Remove any invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>Enter Out-of-Province donor CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li><li>If the error persists, contact the Out-of-Province program to request an offer be sent to CTR for the intended Ontario recipient.</li></ol>"])},
                    "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Missing CTD</h4><ol><li>In iTransplant, confirm if the \"National Donor ID\" number is available.</li><li>For Canadian Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is resolved in iTransplant, re-push to OATS.</li><li>After confirming CTD number is displayed in OATS, re-run allocation.</li><li>For US Donors, select \"Acknowledge\" and then \"Accept\" to proceed to allocate to Ontario recipients.</li><li>For any other issues, call TGLN ServiceDesk.</li></ol>"])},
                    "invalidNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "patientnotexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])},
                    "StringFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for Invalid CTD</h4><ol><li>Remove invalid CTD number from \"National Donor ID\" in iTransplant.</li><li>For Out-of-Province donors, enter the CTD number in the correct format.</li><li>Once the CTD number is valid, re-push to OATS and re-run allocation.</li></ol>"])}
                  }
                }
              },
              "offerdwl": {
                "error": {
                  "cannot": {
                    "inactivate": {
                      "ddoshipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Submit a Web service form to CTR to cancel the accepted offer in CTR.</li></ol>"])}
                    }
                  },
                  "makeoffer": {
                    "validation": {
                      "cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact TGLN Service Desk and review Business Continuity Plan.</li></ol>"])},
                      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action for manually created offer in CTR interface</h4><ol><li>Login to CTR.</li><li>Navigate to selected Donor’s HS Heart allocation.</li><li>Identify the Ontario HSH recipient with an offer manually created in the CTR interface.</li><li>Withdraw the offer to the identified Ontario HSH recipient in CTR.</li><li>Return to OATS, and withdraw this offer to the selected HSH recipient.</li><li>In OATS, re-offer to the selected HSH recipient.</li></ol>"])}
                    }
                  },
                  "multipleOfferonOrganRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Corrective Action</h4><ol><li>Contact TGLN Service Desk and review Business Continuity Plan.</li></ol>"])}
                }
              }
            }
          }
        }
      }
    }
  })
}
