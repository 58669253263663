import { APIBloodType } from '@/store/recipients/types';
import { parseDateUi, sanitizeDateApi } from '@/utilities/date-utils';
import { UIRecipient } from '@/UIModels/recipient';
import { SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APISaveBloodTypeResponse } from '@/types';

export class UIBloodType {
  private loaded: boolean;

  public apiSource: APIBloodType|null = null;

  public type: string|null = null;
  public rh_indicator: string|null = null;

  public constructor() {
    this.loaded = false;
  }

  // Define empty UI model structure
  public static buildNew() {
    const uiModel = new UIBloodType();
    return uiModel;
  }

  // Map from API data structure to UI model structure
  public static buildFromAPIBloodType(apiBloodType: APIBloodType) {
    const uiModel = new UIBloodType();

    uiModel.apiSource = apiBloodType;

    uiModel.type = apiBloodType.type || null;
    uiModel.rh_indicator = apiBloodType.rh_indicator || null;

    uiModel.loaded = true;
    return uiModel;
  }

  // Build a copy of the view model
  public copyViewModel() {
    const apiBloodType = this.apiSource as APIBloodType;
    if (!apiBloodType) return UIBloodType.buildNew();

    return UIBloodType.buildFromAPIBloodType(apiBloodType);
  }

  // Generate request payload parameters to provide to API for a Recipient Death patch
  private extractPatch(): APIBloodType {
    const result = {
      type: this.type,
      rh_indicator: this.rh_indicator,
    };

    return result;
  }

  // Save edit state to the backend
  public save(opts: { selected: UIBloodType|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      if (!recipientId) reject((new UIError('recipient_blood_type')));

      const ep = APIRoute(EP.recipients.update, [[':id', recipientId as string]]);
      const payload = {
        diagnostics: {
          blood: this.extractPatch()
        }
      };
      axios.patch(ep, payload).then((response: APISaveBloodTypeResponse) => {
        if (response.data.errors) {
          reject((new UIError('recipient_blood_type', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          /// NOTE: here we simply call recipient-level reload to handle everything
          opts.recipient.load({ reload: true }).then(() => {
            resolve((new UISuccess(response)).getSaveResult());
          });
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('recipient_blood_type', errorResponse)).errorResult);
      });
    });
  }
}
