<template>
  <base-widget :title="$t('urgent_listings')" :preferences="preferences.urgentListings" v-if="editState">
    <template v-slot:icon>
      <div class="circle dashboard-icon circle-urgent-listing">
        <font-awesome-icon :icon="['fas', 'exclamation']" />
      </div>
    </template>
    <template v-slot:linkID>
      <a href="#idUrgentListings" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'cog']" data-toggle="tooltip" :title="$t('customize_panel')" />
      </a>
      <a href="#bodyUrgentListings" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'caret-down']" data-toggle="tooltip" :title="$t('collapse_panel')" />
      </a>
    </template>
    <template v-slot:options>
      <div class="card-body card-options p-0 collapse" id="idUrgentListings">
        <div class="customize-panel">
          <h4>{{$t('panel_options')}}</h4>
          <!-- Widget options-->
          <slot name="options">
            <form>
              <div class="form-row mb-3">
                <div class="col-sm-6">
                  <select-input
                    name="panel-style"
                    select-id='dashUrgentListingsStyle'
                    v-model="editState.style"
                    :label="$t('panel_style')"
                    :options="getPanelStyles"
                    />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="form-row mb-3">
                <div class="col-sm-12 col-lg-8">
                  <checkbox-input
                    input-id='dashUrgentListingsVisible'
                    v-model="editState.visible"
                    :label="$t('show_this_panel')"
                    :disabled="requiredWidget"
                  />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="col-sm-12" v-if="errorMessage">
                <p>{{errorMessage}}</p>
              </div>
              <button
                type="button"
                class="btn btn-sm btn-wide btn-success"
                data-target="#idUrgentListingsOffers"
                @click="saveSettings()"
              >
                {{$t('save')}}
              </button>
            </form>
          </slot>
        </div>
      </div>
    </template>
    <template v-slot:widget-contents>
      <div class="card-body collapse show" id="bodyUrgentListings">
        <sub-section
          sub-section-id="UrgentListingsTable"
          title=""
          style-class="vgt-table table table-bordered table-hover bordered"
          mode="remote"
          :total-records="getUrgentListings.count"
          :table-config="tableConfig"
          @on-page-change="updatePagination($event)"
          @on-per-page-change="updatePagination($event)"
          @on-column-filter="filterList($event)"
          @on-sort-change="filterList($event)"
          v-if="getUrgentListings"
          >
          <template v-slot:table-cell="props">
            <template v-if="props.column.field == 'client_id'">
              <router-link
                class="table-link"
                :to="{ name: 'edit-recipient', params: { id: props.row.client_id } }"
                :title="$t('link_to_recipient_page')"
                :alt="props.row.client_id"
                target="_blank"
                v-if="props.row.client_id"
              >
                {{props.formattedRow[props.column.field] }}
              </router-link>
            </template>
          </template>
        </sub-section>
      </div>
    </template>
  </base-widget>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="@/components/dashboard/widgets/_locales/WidgetUrgentListings.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import store from '@/store';
import { Getter, State } from 'vuex-facing-decorator';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardState, DashboardSettingState, WidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import { urlParams, isMasked } from "@/utils";
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import {ACTIVE_REGION_TRANSPLANT_PROGRAM, HospitalOption} from "@/store/hospitals/types";
import { Format } from "@/store/utilities/types";

@Component({
  components: {
    BaseWidget,
    TextInput,
    CheckboxInput,
    SelectInput,
    SubSection
  }
})
export default class WidgetUrgentListings extends mixins(DateUtilsMixin) {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashUrgentListings) private editState!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getUrgentListings', { namespace: 'dashboard' }) getUrgentListings!: any[];
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];
  @Getter('getDefaultPaginationOptions', { namespace: 'utilities' }) getDefaultPaginationOptions!: any;
  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string|undefined;
  @Getter('hospitalOptionsOntarioTransplant', { namespace: 'hospitals' }) hospitalOptionsOntarioTransplant!: HospitalOption[];
  @Getter('getActiveTransplantProgram', { namespace: 'hospitals' }) getActiveTransplantProgram!: any;

  public currentPage = 1;
  public perPage = 5;
  public errorMessage = null;

  mounted(): void {
    Promise.all([
      this.loadData(),
      this.$store.dispatch('hospitals/load', ACTIVE_REGION_TRANSPLANT_PROGRAM)
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  public loadData(search='', sort='') {
    const search_params = [search, sort].filter((p) => { return p && p.length >= 0; });

    this.$store.dispatch(
      'dashboard/loadUrgentListings', {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`
    });
  }

  public updatePagination(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;
    this.loadData(search_params, sort_params);
  }

  /**
   * Sets the search terms and sorting options
   *
  */
  public filterList(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.loadData(search_params, sort_params);
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;
    this.$store.commit('pageState/set', {
      pageKey: 'dashUrgentListings',
      value: this.buildUrgentListingsEditState(preferences),
    });
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#idUrgentListings").collapse('toggle');
  }

  // Build edit state based on overall dashboard settings
  buildUrgentListingsEditState(preferences: DashboardSettingState): WidgetSettings {
    return Object.assign({}, preferences.urgentListings);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ urgentListings: {} }, currentSettings);
    newSettings.urgentListings = {
      style: editState.style,
      visible: editState.visible
    };
    return newSettings;
  }

  get tableData() {
    const urgentListings: any = this.getUrgentListings.entries || [];
    const results: any[] = [];

    urgentListings.map((record: any) => {
      let metaData: any = {}; // data to inject

      if (!record.journeys || record.journeys.length === 0 ) {
        metaData = {
          organ_code: 'None',
          transplant_program: 'None',
          referral_date: undefined
        };
      } else {
        const journeys = record.journeys;
        const journeyPrograms = journeys.map((journey: any) => {
          if(journey.program_id && journey.program_id.$oid) {
            const h = this.hospitalOptionsOntarioTransplant.find(h => h.code === journey.program_id.$oid);
            return h ? h.abbr  : 'N/A';
          } else {
            return 'N/A';
          }
        });
        const journeyOrgans = journeys.map((journey: RecipientJourney) => {
          const organCode = journey.organ_code;
          if (organCode === undefined || organCode === null) {
            return 'N/A';
          }
          const organName = this.organName(organCode);
          return organName === undefined ? this.$t('unknown').toString() : organName;
        });
        const journeyReferral = journeys.map((journey: RecipientJourney) => {
          return journey.referral_date ? this.parseDisplayDateUiFromDateTime(journey.referral_date) : undefined;
        });
        metaData = {
          transplant_program: journeyPrograms.join('<br /> '),
          organ_code: journeyOrgans.join('<br /> '),
          referral_date: journeyReferral.join('<br /> ')
        };
      }

      const result = {
        client_id: record.client_id,
        first_name: record.first_name,
        last_name: record.last_name,
        transplant_program: metaData.transplant_program,
        organ_code: metaData.organ_code,
        referral_date: metaData.referral_date,
      };
      results.push(result);
    });
    return results;
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.tableData,
      columns: [
        { label: this.$t('recipient_id').toString(), field: 'client_id', sortable: true, type: 'number', thClass: 'vgt-left-align', tdClass: 'vgt-left-align' },
        { label: this.$t('first_name').toString(), field: 'first_name', sortable: true },
        { label: this.$t('last_name').toString(), field: 'last_name', sortable: true },
        { label: this.$t('transplant_program').toString(), field: 'transplant_program', sortable: false, html: true },
        { label: this.$t('referral_date').toString(), field: 'referral_date', sortable: false, html: true },
        { label: this.$t('organ').toString(), field: 'organ_code', sortable: false, html: true },
      ],
      empty: this.$t('no_urgent_listings').toString(),
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'referral_date', type: 'desc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        setCurrentPage: this.currentPage,
        mode: 'pages',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('results_per_page').toString(),
        position: 'bottom'
      }
    };
  }
}
</script>
