import Vue from 'vue';
import { MutationTree } from 'vuex';
import { ProceduresState } from '@/store/procedures/types';

export const mutations: MutationTree<ProceduresState> = {
  setStemCellTherapy(state, procedure) {
    state.stemCellTherapy = procedure;
  },
  clearStemCellTherapy(state) {
    state.stemCellTherapy = undefined;
  },
};
