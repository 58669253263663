import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-header media" }
const _hoisted_2 = { class: "media-left" }
const _hoisted_3 = { class: "media-body" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "nav card-nav" }
const _hoisted_6 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.getStyle)
  }, [
    (_ctx.preferences && _ctx.preferences.visible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["card", _ctx.preferences.style])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "icon")
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1),
              _createElementVNode("nav", _hoisted_5, [
                _renderSlot(_ctx.$slots, "linkID")
              ])
            ])
          ]),
          _renderSlot(_ctx.$slots, "options"),
          _renderSlot(_ctx.$slots, "widget-contents"),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "widget-modal-link"),
            _renderSlot(_ctx.$slots, "widget-modal-body")
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}