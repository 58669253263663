import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "donor-data-history",
    onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loaded())),
    overflowVisible: true
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('donor-data-history-title')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        "sub-section-id": "donor-data-historys",
        tabbableValue: _ctx.$t('source'),
        "table-config": _ctx.donorDataHistorysTableConfig
      }, {
        "table-cell": _withCtx((props) => [
          (props.column.field == 'significant_change')
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                title: props.row.changes
              }, _toDisplayString(props.formattedRow[props.column.field]), 9, _hoisted_1))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(props.formattedRow[props.column.field] != null ? props.formattedRow[props.column.field] : '-'), 1))
        ]),
        _: 1
      }, 8, ["tabbableValue", "table-config"])
    ]),
    _: 1
  }))
}