export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "donor-data-history-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Data History"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time (EST)"])},
        "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
        "significant_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Significant Change"])},
        "no-data-history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no Donor Data History"])}
      },
      "fr": {
        "donor-data-history-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des données des donateurs"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La source"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure (EST)"])},
        "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
        "significant_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement significatif"])},
        "no-data-history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas d'historique des données des donateurs"])}
      }
    }
  })
}
