<template>
    <card-section
      section-id="recipient-documents"
      :lookupsToLoad="lookupsToLoad"
      @loaded="loaded()"
      >
      <template v-slot:header>
        {{$t('recipient_documents')}}
      </template>
      <template v-slot:body>
        <template v-if="!editState">
          {{$t('loading')}}
        </template>
        <template v-else>
          <sub-section
            ref="uploadDocument"
            :title='$t("uploaded_documents")'
            sub-section-id="recipient-uploaded-files"
            :saveButton="hasWriteAccess"
            :save-button-text="editState.id? `${$t('save_description')}` : `${$t('save_document')}` "
            @save="uploadDocument()"
          >
            <template v-slot:contents>
              <!-- Filter Bar + Create Button -->
              <table-toolbar
                :createButton="hasWriteAccess"
                :createText="tableConfig.createText"
                @table-create-row="onCreateButtonClicked()"
              />

              <!-- List of Items, or History List -->
              <table-list
                ref="recipientDocumentsTable"
                table-id="recipientDocuments-uploadedFiles-table"
                :table-config="tableConfig"
                tabbableColumn="dateUploaded"
                @table-row-click="handleTableRowClick($event.row)"
                @table-row-download="(params: any) => generateDownloadLink(params)"
                :highlightSelection="true"
                :rowStyleClass="rowStyleClass"
              />

              <!-- Form layout -->
              <validation-observer ref="validations">
                <fieldset :disabled="!hasWriteAccess" id="recipientAttachment-form">
                  <legend>
                    <h5 v-if="editState.id" class="legend-title">
                      {{$t('edit_recipient_document')}}
                    </h5>
                    <h5 v-else class="legend-title">
                      {{$t('new_recipient_document')}}
                    </h5>
                  </legend>
                  <div class="row">
                    <!-- Clinical Attachments -->
                    <div class="standard-form-group-large">

                      <!-- TODO: File Input component -->
                      <validation-provider
                        ref="recipientAttachment-clinicalAttachment-provider"
                        rules="required"
                        :label="$t('clinical_attachment')"
                        name="recipientAttachment-clinicalAttachment"
                        v-slot="{ errors }"
                      >
                        <label for="recipientAttachment-clinicalAttachment">{{$t('clinical_attachment')}} <i>*</i></label>
                        <input
                          v-if="!!editState.id"
                          type="text"
                          :disabled="true"
                          class="form-control"
                          :value="editState.originalFileName"
                        />
                        <input
                          v-else
                          input-id="recipientAttachment-clinicalAttachment"
                          ref="fileUploader"
                          type="file"
                          class="form-control"
                          :class="{ 'is-invalid': errors[0] }"
                          @change="onClinicalAttachmentsChanged($event)"
                        />
                        <div class="invalid-feedback" id="recipientAttachment-clinicalAttachment-error" v-if="errors[0]">
                          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                          {{ translateError(errors, $t('clinical_attachment')) }}
                        </div>
                      </validation-provider>

                    </div>
                    <div class="standard-form-group">
                      <select-input
                        select-id="recipientAttachment-categoryCode"
                        rules="required"
                        :name='$t("category")'
                        :options="attachmentCategoryOptions(false)"
                        v-model="editState.categoryCode"
                      >
                      </select-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="standard-full-width-group">
                      <text-input
                        :name='$t("fileName")'
                        input-id="recipientAttachment-fileName"
                        rules="required"
                        v-model="editState.fileName"
                      />
                    </div>
                    <div class="row-break d-none d-xl-block"></div>
                    <div class="standard-full-width-group">
                      <text-area-input
                        input-id="recipientAttachment-description"
                        :name='$t("description")'
                        rows="4"
                        v-model="editState.description">
                      </text-area-input>
                    </div>
                  </div>
                  <div class="row" v-if="hasWriteAccess">
                    <div class="form-group col-md-12">
                      <button class="btn btn-danger float-right" v-show="editState.id" type="button" @click.prevent="onDeleteFileClick()">{{$t('remove_document')}}</button>
                    </div>
                  </div>
                </fieldset>
              </validation-observer>

              <modal-section
                modalId="delete-documents-modal"
                ref="deleteModal"
                class="modal-sticky-header"
                :centered="true">
                <template v-slot:title>
                  {{ $t('delete_document') }}
                </template>
                <template v-slot:body>
                  <div class="form-check form-check-inline row">
                    <div class="col-md-12 m-0 p-0 mb-2">
                      {{ $t('delete_documents_confirmation', { fileName: editState.fileName }) }}
                    </div>
                  </div>
                </template>
                <template v-slot:footer>
                  <div class="modal-footer-body">
                    <button type="button" data-dismiss="modal" class="btn btn-secondary">
                      {{ $t('cancel') }}
                    </button>
                    <a class="btn btn-success" @click="deleteFile()">
                      {{ $t('ok') }}
                    </a>
                  </div>
                </template>
              </modal-section>
            </template>
          </sub-section>
        </template>
      </template>
    </card-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import { TableConfig } from '@/types';
import { Recipient, RecipientAttachment } from '@/store/recipients/types';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { SaveProvider, SaveResult } from '@/types';
import { AttachmentCategory } from '@/store/lookups/types';
import SaveToolbar from "@/components/shared/SaveToolbar.vue";
import { Format } from '@/store/utilities/types';
import { nextTick } from 'vue';
import { i18nMessages } from "@/i18n";
import TableToolbar from '@/components/shared/TableToolbar.vue';
import TableList from '@/components/shared/TableList.vue';
import { parseFormErrors } from '@/utils';
import { UIListFormSelection } from '@/UIModels/listFormSelection';

// Declare all v-model fields used in the form
interface RecipientDocumentForm {
  id?: string;
  originalFileName?: string;
  fileName?: string;
  clinicalAttachments?: FileList;
  description?: string;
  uploadedFiles?: UploadedFile[];
  categoryCode?: number;
}

interface UploadedFile {
  category?: string;
  dateUploaded?: string;
  fileName?: string;
  fileType?: string;
  description?: string;
}

interface TableRow {
  id: string;
  downloadParams: {
    recipientId: string;
    id: string;
  },
  category?: string;
  category_code?: number;
  dateUploaded?: string;
  fileName?: string;
  fileType?: string;
  description?: string;
  uploadedBy?: string;
  editRemove?: string;
  checked?: boolean;
}

const SCROLL_TO_ID = "recipientAttachment-form";

// What are all the possible per-page sizes allowed in the table list pagination bar?
// NOTE: Patient Documents: 10 (default), 25, 50
// See: https://shorecg.atlassian.net/wiki/spaces/AP/pages/1581383697/Paginated+Table
// TODO: TECH_DEBT: can page sizes be moved to application level somehow
const PAGE_SIZES = [10, 25, 50];

@Component({
  components: {
    CardSection,
    SubSection,
    SelectInput,
    TextInput,
    CheckboxInput,
    TextAreaInput,
    ModalSection,
    SaveToolbar,
    TableToolbar,
    TableList,
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/common.json'),
    require('@/components/recipients/_locales/RecipientDocuments.json'),
  ]),
  emits: [
    'loaded',
  ]
})
export default class RecipientDocuments extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.pageState.currentPage.recipientDocuments) editState!: RecipientDocumentForm;
  @State(state => state.recipients.attachments) attachments!: RecipientAttachment[];

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [ 'attachment_category' ];

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) private clientId!: string|undefined;
  @Getter('AttachmentCategoryOptions', { namespace: 'lookups' }) attachmentCategoryOptions!: (enableDonor: boolean) => AttachmentCategory[];
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('getUserName', { namespace: 'users' }) private getUserName!: string;

  // TODO: File Input component (it can handle errors in standard way)
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // Selection instance
  private selection = new UIListFormSelection();

  public selectedDocuments: string[] = [];
  private isTableUpdating = false;
  private taskInProgress = false;

  get hasWriteAccess(): boolean {
    return this.isGroupWriteable("recipient_private");
  }

  // Prepare recipient attachments for presentation in the historical tables.
  get tableRows(): TableRow[] {
    const attachments = this.attachments || [];
    return attachments.map((item: RecipientAttachment) => {
      return {
        id: item._id!['$oid'],
        downloadParams: {
          recipientId: this.clientId || '',
          id: item._id!['$oid'],
        },
        uuid: item.uuid,
        deleted: item.deleted,
        category: this.attachmentCategoryValue(item.category_code) || '-',
        category_code: item.category_code,
        dateUploaded: this.parseDisplayDateUiFromDateTime(item.created_at),
        fileName: item.original_filename || '-',
        fileType: item.mime_type,
        description: item.description || '-',
        uploadedBy: item.updated_by ? item.updated_by : item.created_by,
        checked: false
      };
    });
  }

  /**
  *
  */
  get tableConfig(): TableConfig {
    const tableConfig = [
      { label: `${this.$t('attachment_date')}`,
        field: 'dateUploaded',
        width: '125px',
        sortable: false,
        type: 'date',
        dateInputFormat: Format(this.getDateFormat).DISPLAY_DATE,
        dateOutputFormat: Format(this.getDateFormat).DISPLAY_DATE,
      },
      { label: `${this.$t('fileName')}`,
        field: 'fileName',
        sortable: false,
        download: true,
        tooltip: this.$t('download_this_document').toString(),
      },
      { label: `${this.$t('category')}`,
        field: 'category',
        width: '100px',
        sortable: false
      },
      { label: `${this.$t('fileType')}`,
        field: 'fileType',
        sortable: false
      },
      { label: `${this.$t('description')}`,
        field: 'description',
        sortable: false
      },
      { label: `${this.$t('uploadedBy')}`,
        field: 'uploadedBy',
        width: '135px',
        sortable: false
      },
    ];

    // if (this.hasWriteAccess) { tableConfig.push({label: '', field: 'editRemove', width: '5%',sortable: false}); }

    return {
      data: this.tableRows,
      columns: tableConfig,
      createButton: true,
      createText: `${this.$t('attach_new_document')}`,
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'dateUploaded', type: 'desc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        mode: 'records',
        perPageDropdown: PAGE_SIZES,
        dropdownAllowAll: true,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('table.results'),
        position: 'bottom'
      },
    };
  }

    // Show loading state or empty message
  get emptyMessage(): string {
    if (!this.attachments) {
      return this.$t('loading').toString();
    } else {
      return this.$t('use_form_below').toString();
    }
  }

  // Figure out if we need any extra style class based on the row data
  private rowStyleClass(row: TableRow): string|null {
    return null;
  }

  // Event handlers

  /**
    * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
    *
    * @listens #mounted
    */
  private mounted(): void {
    nextTick(() => {
      this.$store.commit('pageState/set', {
        pageKey: 'recipientDocuments',
        value: {}
      });
    });
    this.$store.dispatch('recipients/loadAttachments', this.clientId);
  }

  // Initialize the form
  private initialize(): void {
      this.$store.commit('pageState/set', {
        pageKey: 'recipientDocuments',
        value: {}
      });
  }

  /**
    * Emits a loaded event after all subcomponents have finished loading.
    *
    * The Recipient Documents card section emits a loaded event when it finishes loading lookup tables. This is the only
    * loading process that this form component is responsible for, so it immediately emits its own loaded event.
    *
    * @listens recipientDocuments#loaded
    * @emits loaded
    */
  public loaded(): void {
    this.$emit('loaded', 'recipientDocuments');
  }

  // Sets the passed files data to the editState and toggles the delete Modal
  private onDeleteFileClick(row?: TableRow): void {
    if (row) {
      this.editState.fileName = row.fileName;
      this.editState.id = row.id;
    }

    this.toggleModal('deleteModal');
  }

  // Handle deleting a file. Called after user accepts delete warning modal dialog popup
  private deleteFile(): void {
    const saveProvider = this.$refs.uploadDocument as unknown as SaveProvider;
    this.$store.dispatch('recipients/deleteFile', {
      id: this.editState.id,
      recipientId: this.clientId,
    }).then((result: SaveResult) => {
      this.$store.dispatch('recipients/loadAttachments', this.clientId).then((response) => {
        this.prepareNewEntry();
        if(response.success) saveProvider.registerSaveResult(result);
        this.initialize();
      }).catch((result: SaveResult) => {
        this.handleErrors(result);
        saveProvider.registerSaveResult(result);
    });
    }).catch((result: SaveResult) => {
      this.handleErrors(result);
      saveProvider.registerSaveResult(result);
    });

    this.toggleModal('deleteModal');
  }

  // Toggle a modal based on a ref
  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    targetModal.toggleModal();
  }

  // Resets Form Errors
  public resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  /**
   * Event handle run when clicking on the edit button on a row in the uploaded files table.
   */
  private handleTableRowClick(row: TableRow) {
    const listItem = this.attachments.find((item: RecipientAttachment) => { return item._id?.$oid === row.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem._id?.$oid);
    this.scrollToMethod();
    this.prepareEditEntry(row);
  }

  private onCreateButtonClicked(): void {
    this.prepareNewEntry();
    this.scrollToMethod();
  }

  public prepareEditEntry(row: TableRow): void {
    this.resetFormErrors();
    const attachments = this.attachments || [];
    const listItem = attachments.find((item: RecipientAttachment) => { return item._id?.$oid === row.id; });
    if (!listItem) return;

    this.$store.commit('pageState/set', {
      pageKey: 'recipientDocuments',
      value: {
        id: listItem._id?.$oid,
        originalFileName: listItem.original_filename,
        fileName: listItem.original_filename,
        categoryCode: listItem.category_code,
        description: listItem.description,
      }
    });
    // TODO: File Input component (this should use v-model)
    this.resetFileUploader();
    this.resetSaveToolbar();
  }

  scrollToMethod() {
    if (SCROLL_TO_ID) {
      // perform scrollTo
      const location = document.getElementById(SCROLL_TO_ID) || null;
      if (location) {
        const elementPosition = location.getBoundingClientRect().top;
        const offsetPosition = elementPosition - 105;
        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth"
        }); 
      }
    }
  }

  /**
    * Updates form state when Clinical Attachments files are uploaded
    *
    * @listens clinicalAttachments#changed
    */
  private onClinicalAttachmentsChanged(event: any) {
    if (!!this.editState && !!event.target) {{
      this.editState.clinicalAttachments = event.target.files;

      if (this.editState.clinicalAttachments && this.editState.clinicalAttachments[0]) {
        this.editState.fileName = this.editState.clinicalAttachments[0].name;
      } else {
        this.editState.fileName = '';
      }
    }}
  }

  // Private methods

  /**
   * Clears the Recipient Documents form state for New Document flow
   */
  public prepareNewEntry(): void {
    this.resetFormErrors();
    this.$store.commit('pageState/set', {
      pageKey: 'recipientDocuments',
      value: {
      }
    });
    (this.$refs.recipientDocumentsTable as TableList).resetSelection();
    this.resetSaveToolbar();

    // TODO: File Input component (this should use v-model)
    this.resetFileUploader();
  }

  private resetFileUploader(): void {
    const fileUploader = this.$refs.fileUploader as any;
    if (fileUploader) fileUploader.value = null;
  }

  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.uploadDocument as SaveProvider;
    if (saveProvider) saveProvider.resetSaveToolbar();
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup);

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);
  }

  /**
   * Event handler triggered when uploading a new document, or editing an existing one.
   */
  public uploadDocument(): void {
    const saveProvider = this.$refs.uploadDocument as unknown as SaveProvider;
    const payload: any = {
      id: this.editState.id,
      recipientId: this.clientId,
      fileList: this.editState.clinicalAttachments,
      fileName: this.editState.fileName || '',
      description: this.editState.description || '',
      categoryCode: this.editState.categoryCode || '',
    };
    this.isTableUpdating = true;

    this.$store.dispatch('recipients/uploadAttachmentsFile', payload).then((result: SaveResult) => {
      this.$store.dispatch('recipients/loadAttachments', this.clientId).then(() => {
        this.isTableUpdating = false;
      }).catch(() => {
        this.isTableUpdating = false;
      });
      this.prepareNewEntry();
      saveProvider.registerSaveResult(result);
      this.initialize();
    }).catch((result: SaveResult) => {
      this.handleErrors(result);
      saveProvider.registerSaveResult(result);
      this.isTableUpdating = false;
    });
  }

  public generateDownloadLink(payload: { recipientId: string, id: string }): void {
    this.$store.dispatch('recipients/downloadFile', payload).then((result: any) => {
      const link = document.createElement('a');
      link.href = result;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    }).catch((result: any) => {
      // Show download error
      alert(this.$t('error_generating_download_link').toString());
    });
  }

  // API responses on the left, UI IDs on the right
  public idLookup: IdLookup = {
    'file': 'recipientAttachment-clinicalAttachment',
    'original_filename': 'recipientAttachment-fileName',
    'category_code': 'recipientAttachment-categoryCode',
    'description': 'recipientAttachment-description'
  }

  // PRIVATE

  /**
   * Use the document_attachment_category lookup table to convert from the code to the value (the visual label to present on the tables).
   *
   * @param categoryCode numeric code from a given attachment representing the Attachment Category
   * @returns {string|undefined} attachment's category as text
   */
  private attachmentCategoryValue(categoryCode?: number): string|undefined {
    const lookupTable = this.attachmentCategoryOptions(false);

    if (!!lookupTable) {
      const entry = lookupTable.find((category: AttachmentCategory) => category.code == categoryCode);

      if (!!entry) {
        return entry.value;
      }
    }

    return undefined;
  }
}
</script>
