import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id", "name", "checked", "onChange", "disabled"]
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_asterisk = _resolveComponent("validation-asterisk")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!

  return (_openBlock(), _createBlock(_component_validation_provider, {
    ref: "provider",
    rules: _ctx.formRules,
    name: _ctx.inputId,
    label: _ctx.name,
    vid: _ctx.validationId ? _ctx.validationId : _ctx.inputId,
    modelValue: _ctx.validatedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.validatedValue) = $event))
  }, {
    default: _withCtx(({ errors }) => [
      (_ctx.name)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(["p-label", { 'sr-only': _ctx.hideLabel }])
          }, [
            _createTextVNode(_toDisplayString(_ctx.name) + " ", 1),
            (!_ctx.disabled)
              ? (_openBlock(), _createBlock(_component_validation_asterisk, {
                  key: 0,
                  rules: _ctx.formRules,
                  crossValues: _ctx.crossValues,
                  ruleKey: _ctx.ruleKey
                }, null, 8, ["rules", "crossValues", "ruleKey"]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["form-check form-check-inline", { 'is-invalid': errors[0] }]),
          key: option.code
        }, [
          _createElementVNode("input", {
            type: "checkbox",
            class: "form-check-input",
            id: `${_ctx.inputId}-${option.code}`,
            name: `${_ctx.inputId}-${option.code}`,
            checked: _ctx.localValue[option.code],
            onChange: ($event: any) => (_ctx.onLocalValueChange(option.code)),
            disabled: option.disabled
          }, null, 40, _hoisted_1),
          _createElementVNode("label", {
            class: "form-check-label",
            for: `${_ctx.inputId}-${option.code}`
          }, _toDisplayString(option.value), 9, _hoisted_2)
        ], 2))
      }), 128)),
      (errors[0] && !_ctx.disabled)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "invalid-feedback",
            id: `${_ctx.inputId}-error`
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['far', 'exclamation-circle'],
              "fixed-width": ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.name)), 1)
          ], 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["rules", "name", "label", "vid", "modelValue"]))
}