<template>
  <sub-section subSectionId="transplant-in-progress-report">
    <template v-slot:contents v-if="editState">
      <div class="card tip-card">
        <div class="card-header">
          <div class="media align-items-center">
            <div class="media-left">
              <font-awesome-icon :icon="['far', 'file-chart-line']" fixed-width />
            </div>
            <div class="media-body flex-row align-items-center">
              <h3 class="tip-report-name">
                Transplant in progress report
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <validation-observer id="transplant-in-progress-report-filters" ref="transplantInProgressReportFilters" autocomplete="off" tag="form" v-slot="{ handleSubmit }">
            <div>
              <div class="standard-form-group sorting-column">
                <select-input
                  select-id="sort-type-report"
                  name="Select default sorting"
                  class="mb-3"
                  v-model="editState.sort"
                  :options="sortOptions"
                  rules="required"
                />
              </div>
              <save-toolbar
                class="form-inline col-sm-12 action-row"
                buttonClass="btn btn-success"
                ref="generateReport"
                savingText="Generating"
                successText="Report generated"
                defaultErrorText="There was a problem generating the report"
                @save="handleSubmit(generateReport)"
              >
                <template v-slot:label>
                  <font-awesome-icon :icon="['far', 'file-chart-line']" class="mr-2" /> Generate Transplant In Progress Report
                </template>
              </save-toolbar>
            </div>
          </validation-observer>
            </div>
 
      </div>
    </template>
  </sub-section>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { Component, Vue } from 'vue-facing-decorator';
import { SaveResult } from '@/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { TransplantInProgressReportState } from '@/store/reports/types';

@Component({
  components: {
    SaveToolbar,
    SelectInput,
    SubSection
  }
})

export default class TransplantInProgressReport extends Vue {
  // State
  @State(state => state.reports.TransplantInProgressReportState) editState!: TransplantInProgressReportState;
  
  // sort options
  public sortOptions = [
    {code:"1", value:"Donor ID"},
    {code:"2", value:"Transplant Program"}
  ];

  public mounted(): void {
    this.$store.dispatch('reports/newTransplantInProgressReport');
  }

  public generateReport(): void {
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    saveToolbar.startSaving();

    // Submit query
    this.$store.dispatch('reports/printTransplantInProgressReport', { sort: this.editState.sort }).then((result: SaveResult) => {

      // Get the filename
      const fileName = result.responseData.report; 

      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
      }
      saveToolbar.stopSaving(result);
      
    }).catch((result: SaveResult) => {
      saveToolbar.stopSaving(result);
    });
  }
}
</script>
