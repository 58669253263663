import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { Recipient, RecipientsState } from '@/store/recipients/types';
import { actions } from '@/store/recipients/actions';
import { mutations } from '@/store/recipients/mutations';
import { getters } from '@/store/recipients/getters';

const state: RecipientsState = {
  selectedRecipient: undefined,
  recipientsList: undefined,
};

const namespaced = true;

export const recipients: Module<RecipientsState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};