import Vue from 'vue';
import { MutationTree } from 'vuex';
import { OrganDetails, VadProcedure, HistoryLiverSmc } from '@/store/organSpecificDetails/types';

export const mutations: MutationTree<OrganDetails> = {
  setLiverMelds(state, liverMelds) {
    state.liverMelds = liverMelds;
  },
  selectLiverMeld(state, liverMeld) {
    state.selectedLiverMeld = liverMeld;
  },
  setLiverPelds(state, liverPelds) {
    state.liverPelds = liverPelds;
  },
  selectLiverPeld(state, liverPeld) {
    state.selectedLiverPeld = liverPeld;
  },
  setLiverHccs(state, liverHccs) {
    state.liverHccs = liverHccs;
  },
  selectLiverHcc(state, liverHcc) {
    state.selectedLiverHcc = liverHcc;
  },
  setHeartMeasurements(state, heartMeasurements) {
    state.heartMeasurements = heartMeasurements;
  },
  setThoracicMeasurements(state, thoracicMeasurements) {
    state.thoracicMeasurements = thoracicMeasurements;
  },
  setHemodynamicInfo(state, hemodynamicInfo) {
    state.hemodynamicInfo = hemodynamicInfo;
  },
  selectKidneyDialysis(state, dialysis) {
    state.selectedDialysis = dialysis;
  },
  setAlcoholicLiverDiseasePrograms(state, aldPrograms) {
    state.aldPrograms = aldPrograms;
  },
  setPancreasInfusions(state, pancreasInfusion) {
    state.pancreasInfusion = pancreasInfusion;
  },
  selectPancreasInfusions(state, pancreasInfusion) {
    state.selectedPancreasInfusion = pancreasInfusion;
  },
  clearPancreasInfusions(state, pancreasInfusion) {
    state.selectedPancreasInfusion = undefined;
  },
  setVadProcedures(state, vadInfo: VadProcedure[]) {
    state.vadProcedures = vadInfo;
  },
  setLiverBridgings(state, liverBridgings) {
    state.liverBridgings = liverBridgings;
  },
  selectLiverBridging(state, liverBridging) {
    state.selectedLiverBridging = liverBridging;
  },
  setLiverDownstagings(state, liverDownstagings) {
    state.liverDownstagings = liverDownstagings;
  },
  selectLiverDownstaging(state, liverDownstaging) {
    state.selectedLiverDownstaging = liverDownstaging;
  },
  setHistoryLiverSmc(state, historyLiverSmc) {
    state.historyLiverSmc = historyLiverSmc;
  },
  setHistoryCriteriaDecisions(state, liverCriteriaDecisions) {
    state.liverCriteriaDecisions = liverCriteriaDecisions;
  },
};
