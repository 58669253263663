export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "potential_duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potential Duplicate Living Donor"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
      },
      "fr": {
        "potential_duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double donneur vivant potentiel"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
      }
    }
  })
}
