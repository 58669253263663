import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "hla-input-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hla_input = _resolveComponent("hla-input")!

  return (_ctx.isVerbose && _ctx.isVxm && _ctx.isEmpty)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.isUntested ? _ctx.$t('untested_vxm_result_long') : _ctx.$t('empty_vxm_result')), 1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_hla_input, {
          "model-value": _ctx.modelValue,
          inputId: _ctx.inputId,
          name: _ctx.name,
          validationId: _ctx.validationId,
          label: _ctx.label,
          disabled: _ctx.disabled,
          placeholder: _ctx.alleleGroupPlaceholder,
          inputClass: _ctx.inputClass,
          disabledEmptyText: _ctx.disabledEmptyText,
          hideLabel: _ctx.hideLabel,
          highlightSelection: _ctx.highlightSelection,
          selectedAntibodies: _ctx.selectedAntibodies,
          readonly: _ctx.readonly,
          inferLocus: _ctx.inferLocus,
          rules: _ctx.rules,
          ruleKey: _ctx.ruleKey,
          crossValues: _ctx.crossValues,
          "onUpdate:modelValue": _ctx.onAlleleGroupInput,
          onSelected: _ctx.onSelected,
          invalidAntibodies: _ctx.invalidAntibodies
        }, null, 8, ["model-value", "inputId", "name", "validationId", "label", "disabled", "placeholder", "inputClass", "disabledEmptyText", "hideLabel", "highlightSelection", "selectedAntibodies", "readonly", "inferLocus", "rules", "ruleKey", "crossValues", "onUpdate:modelValue", "onSelected", "invalidAntibodies"]),
        (_ctx.showAlleleSpecific)
          ? (_openBlock(), _createBlock(_component_hla_input, {
              key: 0,
              hideLabel: true,
              disabled: _ctx.disabled,
              inputClass: _ctx.inputClass,
              disabledEmptyText: _ctx.disabledEmptyText,
              highlightSelection: _ctx.highlightSelection,
              selectedAntibodies: _ctx.selectedAntibodies,
              readonly: _ctx.readonly,
              inferLocus: _ctx.inferLocus,
              "model-value": _ctx.alleleSpecific,
              inputId: _ctx.alleleSpecificId,
              name: _ctx.alleleSpecificName,
              placeholder: _ctx.alleleSpecificPlaceholder,
              "onUpdate:modelValue": _ctx.onAlleleSpecificInput,
              onSelected: _ctx.onSelected,
              invalidAntibodies: _ctx.invalidAlleleSpecifics
            }, null, 8, ["disabled", "inputClass", "disabledEmptyText", "highlightSelection", "selectedAntibodies", "readonly", "inferLocus", "model-value", "inputId", "name", "placeholder", "onUpdate:modelValue", "onSelected", "invalidAntibodies"]))
          : _createCommentVNode("", true),
        (_ctx.showAlphaBeta)
          ? (_openBlock(), _createBlock(_component_hla_input, {
              key: 1,
              hideLabel: true,
              disabled: _ctx.disabled,
              inputClass: _ctx.inputClass,
              disabledEmptyText: _ctx.disabledEmptyText,
              highlightSelection: _ctx.highlightSelection,
              selectedAntibodies: _ctx.selectedAntibodies,
              readonly: _ctx.readonly,
              inferLocus: _ctx.inferLocus,
              "model-value": _ctx.alphaBeta,
              inputId: _ctx.alphaBetaId,
              name: _ctx.alphaBetaName,
              placeholder: _ctx.alphaBetaPlaceholder,
              "onUpdate:modelValue": _ctx.onAlphaBetaInput,
              onSelected: _ctx.onSelected,
              invalidAntibodies: _ctx.invalidAlphaBetas
            }, null, 8, ["disabled", "inputClass", "disabledEmptyText", "highlightSelection", "selectedAntibodies", "readonly", "inferLocus", "model-value", "inputId", "name", "placeholder", "onUpdate:modelValue", "onSelected", "invalidAntibodies"]))
          : _createCommentVNode("", true),
        (_ctx.showEpitopes)
          ? (_openBlock(), _createBlock(_component_hla_input, {
              key: 2,
              hideLabel: true,
              disabled: _ctx.disabled,
              inputClass: _ctx.inputClass,
              disabledEmptyText: _ctx.disabledEmptyText,
              highlightSelection: _ctx.highlightSelection,
              selectedAntibodies: _ctx.selectedAntibodies,
              readonly: _ctx.readonly,
              inferLocus: _ctx.inferLocus,
              "model-value": _ctx.epitopes,
              inputId: _ctx.epitopesId,
              name: _ctx.epitopesName,
              placeholder: _ctx.epitopesPlaceholder,
              "onUpdate:modelValue": _ctx.onEpitopesInput,
              onSelected: _ctx.onSelected,
              invalidAntibodies: _ctx.invalidEpitopes
            }, null, 8, ["disabled", "inputClass", "disabledEmptyText", "highlightSelection", "selectedAntibodies", "readonly", "inferLocus", "model-value", "inputId", "name", "placeholder", "onUpdate:modelValue", "onSelected", "invalidAntibodies"]))
          : _createCommentVNode("", true)
      ]))
}