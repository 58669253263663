export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "recipient_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Information"])},
        "recipient_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Details"])},
        "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare"])},
        "hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA"])},
        "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
        "allocation_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Step"])},
        "manually_added_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually added recipient"])},
        "listed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listed for"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient ID"])},
        "recipient_hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient HLA"])},
        "recipient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Name"])},
        "national_recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National Recipient ID"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "medical_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Status"])},
        "secondary_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Status"])},
        "wait_time_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait Time (Days)"])},
        "list_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Date"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
        "general_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Information"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type (ABO)"])},
        "accept_a2_a2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept A2 or A2B"])},
        "virology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virology"])},
        "cmv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMV"])},
        "ebv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBV"])},
        "hb_ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBs Ag"])},
        "hcv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HCV"])},
        "pra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRA"])},
        "patienbt_hla_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient HLA Comments"])},
        "donor_acceptance_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Acceptance Comments"])},
        "referred_organ_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referred Organ(s) Section:"])},
        "registration_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Type"])},
        "registration_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Status"])},
        "pra_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRA Section:"])},
        "cumulative_cpra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumulative cPRA:"])},
        "current_pra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current PRA"])},
        "months_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Months Old"])},
        "peak_pra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peak PRA"])},
        "class_i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class I"])},
        "class_ii": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class II"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "vxm_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VXM Section:"])},
        "vxm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VXM"])},
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
        "cumulative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumulative"])},
        "no_vxm_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no VXM data."])},
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative"])},
        "unacceptable_dsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unacceptable DSA"])},
        "indeterminate_dsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indeterminate DSA"])},
        "indeterminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indeterminate"])},
        "possible_allele_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possible Allele Specific"])},
        "untested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untested"])},
        "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
        "current_serum_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Serum Date"])},
        "total_serum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total # Serum"])},
        "donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor"])},
        "demographics_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demographics Section:"])},
        "primary_diagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Diagnosis"])},
        "cause_of_death": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause of Death"])},
        "accept_abo_incompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept ABO incompatible?"])},
        "ecd_consented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECD consented?"])},
        "ecd_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECD Donor"])},
        "exeptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution"])},
        "exeptional_distribution_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution reason"])},
        "exeptional_distribution_other_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution other reason"])},
        "exeptional_distribution_travel_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution travel reason"])},
        "exeptional_distribution_increased_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution increased risk for transmission of Hep B/Hep C/HIV other reason"])},
        "minimum_donor_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum acceptable donor weight"])},
        "maximum_donor_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum acceptable donor weight"])},
        "prev_transplants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Transplants"])},
        "recipient_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient comments"])},
        "virology_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virology Results"])},
        "serology_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serology Results"])},
        "nat_hiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAT HIV"])},
        "nat_hcv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAT HCV"])},
        "nat_hbv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAT HBV"])},
        "hep_b_surface_ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hep B Surface Ag"])},
        "hep_b_surface_ab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hep B Surface Ab"])},
        "hep_b_core_ab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hep B Core Ab"])},
        "hiv_i_ii": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIV I/II"])},
        "htlv1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTLV1"])},
        "htlv2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTLV2"])},
        "tp_syphilis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T.P.Syphilis"])},
        "toxoplasma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toxoplasma"])},
        "wnv_pcr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WNV PCR"])},
        "typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typing"])},
        "antibodies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antibodies"])},
        "not_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Done"])},
        "molecular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molecular"])},
        "most_likely_allele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Likely Allele"])},
        "serologic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serologic"])},
        "only_lung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only shown for lung"])},
        "unacceptable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unacceptable"])},
        "epitopes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epitopes"])},
        "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kg"])},
        "cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cm"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])}
      },
      "fr": {
        "recipient_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le patient"])},
        "recipient_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails sur le patient"])},
        "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer"])},
        "hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA"])},
        "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient"])},
        "allocation_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape d'attribution"])},
        "manually_added_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient ajouté manuellement"])},
        "listed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listé pour"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Patient"])},
        "recipient_hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA du patient"])},
        "recipient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du patient"])},
        "national_recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No National du patient"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "medical_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut médical"])},
        "secondary_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut secondaire"])},
        "wait_time_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente (jours)"])},
        "list_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de liste"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
        "general_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe sanguin (ABO)"])},
        "accept_a2_a2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter A2 ou A2B"])},
        "virology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virologie"])},
        "cmv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMV"])},
        "ebv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBV"])},
        "hb_ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBs Ag"])},
        "hcv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HCV"])},
        "pra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRA"])},
        "patienbt_hla_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires sur le HLA du patient"])},
        "donor_acceptance_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires sur l'acceptation du donneur"])},
        "referred_organ_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section Organe(s) référé(s) :"])},
        "registartion_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'enregistrement"])},
        "registration_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut d'enregistrement"])},
        "pra_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section PRA :"])},
        "cumulative_cpra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cPRA cumulé :"])},
        "current_pra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRA actuel"])},
        "months_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois actuels"])},
        "peak_pra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRA de pointe"])},
        "class_i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe I"])},
        "class_ii": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe II"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "vxm_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section VXM :"])},
        "vxm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VXM"])},
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actuel"])},
        "cumulative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumulatif"])},
        "no_vxm_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de données VXM."])},
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Négative"])},
        "unacceptable_dsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DSA Inacceptable"])},
        "unacceptable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inacceptable"])},
        "indeterminate_dsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DSA Indéterminé"])},
        "indeterminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indéterminé"])},
        "possible_allele_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allèle spécifique possible"])},
        "untested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non testé"])},
        "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
        "current_serum_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date actuelle du sérum"])},
        "total_serum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Totale de sérums"])},
        "donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneur"])},
        "demographics_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section des données démographiques :"])},
        "primary_diagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnostic primaire"])},
        "cause_of_death": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause du décès"])},
        "accept_abo_incompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter ABO incompatible ?"])},
        "ecd_consented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPE consenti ?"])},
        "ecd_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneur DPE"])},
        "exeptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution exceptionnelle"])},
        "exeptional_distribution_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison de distribution exceptionnelle"])},
        "exeptional_distribution_other_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre raison de distribution exceptionnelle"])},
        "exeptional_distribution_travel_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution exceptionnelle raison de voyage"])},
        "exeptional_distribution_increased_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution exceptionnelle risque accru de transmission de l'hépatite B/Hépatite C/VIH autre raison"])},
        "minimum_donor_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids minimal acceptable du donneur"])},
        "maximum_donor_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids maximal acceptable du donneur"])},
        "prev_transplants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplantations précédentes"])},
        "recipient_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires du patient"])},
        "virology_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la virologie"])},
        "serology_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la sérologie"])},
        "nat_hiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAT HIV"])},
        "nat_hcv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAT HCV"])},
        "nat_hbv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAT HBV"])},
        "hep_b_surface_ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hep B Surface Ag"])},
        "hep_b_surface_ab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hep B Surface Ab"])},
        "hep_b_core_ab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hep B Core Ab"])},
        "hiv_i_ii": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIV I/II"])},
        "htlv1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTLV1"])},
        "htlv2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTLV2"])},
        "tp_syphilis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T.P.Syphilis"])},
        "toxoplasma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toxoplasme"])},
        "wnv_pcr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WNV PCR"])},
        "typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typage"])},
        "antibodies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anticorps"])},
        "not_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas fait"])},
        "molecular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moleculaire"])},
        "most_likely_allele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allèle le plus probable"])},
        "serologic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serologiques"])},
        "only_lung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniquement affiché pour les poumons"])},
        "epitopes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épitopes"])},
        "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" kg"])},
        "cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" cm"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe"])}
      }
    }
  })
}
