import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_3 = {
  key: 1,
  class: "legend-title"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_10 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "standard-form-group-6column-xlarge-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_other_input = _resolveComponent("select-other-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    "sub-section-id": "new-policy-exemption",
    tabbableValue: _ctx.$t('request_date_time'),
    "table-config": _ctx.decisionTableConfig,
    onTableRowClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.selectDecision($event))),
    onTableCreateRow: _cache[9] || (_cache[9] = ($event: any) => (_ctx.createDecision())),
    saveButton: _ctx.canSave,
    disabled: !_ctx.canSave || _ctx.newJourney,
    "save-button-text": _ctx.$t('save_policy_request'),
    ref: "saveExemptionsSection",
    onSave: _cache[10] || (_cache[10] = ($event: any) => (_ctx.saveDecision()))
  }, {
    contents: _withCtx(() => [
      (_ctx.editState)
        ? (_openBlock(), _createElementBlock("fieldset", {
            key: 0,
            disabled: !_ctx.canSave
          }, [
            _createElementVNode("legend", null, [
              (!_ctx.editState._id)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('new_policy_exemption')), 1))
                : (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.$t('selected_policy_exemption')), 1))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_select_input, {
                  "select-id": "exemption_type",
                  ruleKey: "type",
                  name: _ctx.$t('request_type_form'),
                  options: _ctx.decisionTypes,
                  modelValue: _ctx.editState.type,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.type) = $event)),
                  readonly: _ctx.selectedDecisionId,
                  disabled: _ctx.selectedDecisionId
                }, null, 8, ["name", "options", "modelValue", "readonly", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_date_input, {
                  "input-id": "exemption_request_date",
                  ruleKey: "request_date",
                  name: _ctx.$t('request_date'),
                  modelValue: _ctx.editState.request_date,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.request_date) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_time_input, {
                  "input-id": "exemption_request_time",
                  name: _ctx.$t('request_time'),
                  rules: "required",
                  modelValue: _ctx.editState.request_time,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.request_time) = $event))
                }, null, 8, ["name", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_select_other_input, {
                  selectId: "exemption_requesting_hospital_id",
                  ruleKey: "requesting_hospital_id",
                  name: _ctx.$t('requesting_hospital_id'),
                  modelValue: _ctx.editState.requesting_hospital_id,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.requesting_hospital_id) = $event)),
                  options: _ctx.requestingTransplantPrograms,
                  onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectedRequestingHospitalCode($event))),
                  crossValues: { requesting_hospital_other_id: _ctx.editState.requesting_hospital_id }
                }, {
                  other: _withCtx(() => [
                    _createVNode(_component_text_input, {
                      "input-id": "exemption_requesting_hospital_other",
                      name: _ctx.$t('requesting_hospital_other'),
                      ruleKey: "requesting_hospital_other",
                      modelValue: _ctx.editState.requesting_hospital_other,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.requesting_hospital_other) = $event)),
                      crossValues: { requesting_hospital_other_id: _ctx.editState.requesting_hospital_id }
                    }, null, 8, ["name", "modelValue", "crossValues"])
                  ]),
                  _: 1
                }, 8, ["name", "modelValue", "options", "crossValues"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_text_input, {
                  "input-id": "exemption_requesting_physician",
                  ruleKey: "requesting_physician",
                  name: _ctx.$t('requesting_doctor'),
                  modelValue: _ctx.editState.requesting_physician,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.requesting_physician) = $event))
                }, null, 8, ["name", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_text_area_input, {
                  "input-id": "exemption_comments",
                  name: _ctx.$t('comments'),
                  ruleKey: "comments",
                  modelValue: _ctx.editState.comments,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.comments) = $event))
                }, null, 8, ["name", "modelValue"])
              ])
            ])
          ], 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tabbableValue", "table-config", "saveButton", "disabled", "save-button-text"]))
}