import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 1,
  class: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_warning_banner = _resolveComponent("warning-banner")!
  const _component_site_header = _resolveComponent("site-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_site_footer = _resolveComponent("site-footer")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.getAppBaseClass,
    class: _normalizeClass(`site-wrap ${_ctx.authenticationClass}`)
  }, [
    (_ctx.isConfigurationLoaded && !_ctx.sanityCheck)
      ? (_openBlock(), _createBlock(_component_warning_banner, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.baseInfoLoaded)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.isLoggedIn)
                  ? (_openBlock(), _createBlock(_component_site_header, { key: 0 }))
                  : _createCommentVNode("", true),
                _createVNode(_component_router_view, { role: "main" }),
                (_ctx.isLoggedIn)
                  ? (_openBlock(), _createBlock(_component_site_footer, { key: 1 }))
                  : _createCommentVNode("", true)
              ], 64))
            : (_openBlock(), _createElementBlock("div", _hoisted_2))
        ], 64))
  ], 10, _hoisted_1))
}