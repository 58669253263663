import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "sub-section" }
const _hoisted_2 = ["id", "disabled"]
const _hoisted_3 = ["aria-describedby"]
const _hoisted_4 = ["id"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "mt-2"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "divider"
}
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 2,
  class: "divider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_collapsible_heading = _resolveComponent("collapsible-heading")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("fieldset", {
      id: _ctx.subSectionId,
      disabled: _ctx.disabled
    }, [
      (_ctx.subtitle && _ctx.subtitle.length > 0)
        ? (_openBlock(), _createElementBlock("legend", {
            key: 0,
            "aria-describedby": `${_ctx.subSectionId}-note`
          }, [
            (_ctx.subSubSection)
              ? (_openBlock(), _createBlock(_component_collapsible_heading, {
                  key: 0,
                  tag: "h5",
                  class: "legend-title d-inline",
                  heading: _ctx.title,
                  collapsible: _ctx.collapsible,
                  modelValue: _ctx.collapsed,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.collapsed) = $event))
                }, null, 8, ["heading", "collapsible", "modelValue"]))
              : (_openBlock(), _createBlock(_component_collapsible_heading, {
                  key: 1,
                  class: "legend-title d-inline",
                  heading: _ctx.title,
                  collapsible: _ctx.collapsible,
                  modelValue: _ctx.collapsed,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.collapsed) = $event))
                }, null, 8, ["heading", "collapsible", "modelValue"])),
            _createElementVNode("small", {
              id: `${_ctx.subSectionId}-note`,
              class: "form-text text-muted d-inline"
            }, _toDisplayString(_ctx.subtitle), 9, _hoisted_4)
          ], 8, _hoisted_3))
        : (_ctx.title.length > 0)
          ? (_openBlock(), _createElementBlock("legend", _hoisted_5, [
              (_ctx.subSubSection)
                ? (_openBlock(), _createBlock(_component_collapsible_heading, {
                    key: 0,
                    tag: "h5",
                    heading: _ctx.title,
                    collapsible: _ctx.collapsible,
                    modelValue: _ctx.collapsed,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.collapsed) = $event))
                  }, null, 8, ["heading", "collapsible", "modelValue"]))
                : (_openBlock(), _createBlock(_component_collapsible_heading, {
                    key: 1,
                    heading: _ctx.title,
                    collapsible: _ctx.collapsible,
                    modelValue: _ctx.collapsed,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.collapsed) = $event))
                  }, null, 8, ["heading", "collapsible", "modelValue"]))
            ]))
          : _createCommentVNode("", true),
      (_ctx.hasGuidingText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['far', 'exclamation-circle'],
              "fixed-width": ""
            }),
            _renderSlot(_ctx.$slots, "guiding_text")
          ]))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2),
    (_ctx.isLoading || _ctx.isParentLoading)
      ? _withDirectives((_openBlock(), _createElementBlock("fieldset", _hoisted_7, [
          (!_ctx.hideDivider)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8))
            : _createCommentVNode("", true)
        ], 512)), [
          [_vShow, !_ctx.collapsed]
        ])
      : _withDirectives((_openBlock(), _createElementBlock("fieldset", {
          key: 1,
          disabled: _ctx.disabled
        }, [
          _renderSlot(_ctx.$slots, "contents"),
          (_ctx.saveButton)
            ? (_openBlock(), _createBlock(_component_save_toolbar, {
                key: 0,
                class: _normalizeClass(_ctx.saveToolbarClass),
                buttonClass: _ctx.saveButtonClass,
                disabled: _ctx.disabled,
                label: _ctx.saveButtonText,
                ref: _ctx.saveToolbarRef(),
                onSave: _ctx.performSave,
                cancelButton: _ctx.cancelButton,
                onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.performCancel()))
              }, null, 8, ["class", "buttonClass", "disabled", "label", "onSave", "cancelButton"]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_9)), [
          [_vShow, !_ctx.collapsed]
        ]),
    (!_ctx.hideDivider)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10))
      : _createCommentVNode("", true)
  ]))
}