<template>
  <modal-section
    modalId="ctr-log-modal"
    ref="ctrLogModal"
    class="modal-sticky-header"
    :centered="true"
  >
    <template v-slot:title>
      {{ $t('ctr_integration') }}
    </template>
    <template v-slot:body v-if="this.editState && this.editState.logs">
      <sub-section
        sub-section-id="ctr-integration-section"
        :tabbableValue="$t('date')"
        :title="$t('integration_sync_events')"
        :table-config="ctrLogsTableConfig"
      />
    </template>
    <template v-slot:body v-else>
      {{ $t('loading') }}
    </template>
    <template v-slot:footer>
      <div class="modal-footer-body">
        <button type="button" data-dismiss="modal" class="btn btn-secondary">
          {{ $t('cancel') }}
        </button>
      </div>
    </template>
  </modal-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { State } from 'vuex-facing-decorator';
import { Component } from 'vue-facing-decorator';
import { TableConfig } from '@/types';
import { CtrLogItem } from '@/store/administration/types';
import SubSection from '@/components/shared/SubSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { i18nMessages } from "@/i18n";
import { sortByDate } from "@/utils";

interface CtrLogRow {
  createdAt: string; // used for sorting
  date: string;
  time: string;
  type: string;
  status: string;
}

@Component({
  components: {
    SubSection,
    ModalSection,
  },
  ...i18nMessages([
    require('./_locales/common.json'),
    require('./_locales/CtrLogModal.json'),
  ]),
})
export default class CtrLogModal extends mixins(DateUtilsMixin) {
  @State(state => state.recipients.ctrLogs) ctrLogsRecipents!: CtrLogItem[];
  @State(state => state.deceasedDonors.ctrLogs) ctrLogsDonors!: CtrLogItem[];
  @State(state => state.pageState.currentPage) private currentPage!: any;
  @State(state => state.pageState.currentPage.ctrLogs) private editState!: any;

  /**
   * Get table configuration details for the subsection
   *
   * @returns {TableConfig} CTR Logs table configuration
   */
  get ctrLogsTableConfig(): TableConfig {
    return {
      data: this.ctrLogRows,
      columns: [
        { label: this.$t('date').toString(), field: 'date' },
        { label: this.$t('time').toString(), field: 'time' },
        { label: this.$t('sync_type').toString(), field: 'type' },
        { label: this.$t('status').toString(), field: 'status' },
      ],
      empty: this.$t('empty_ctr_logs').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        mode: 'records',
        perPage: 3,
        perPageDropdown: [10, 25, 50],
        dropdownAllowAll: true,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('table.results'),
      },
    };
  }
  
  /**
   * Get all rows for table data
   *
   * @returns {CtrLogRow[]} Ctr Log data
   */
  get ctrLogRows(): CtrLogRow[] {
    // Our state isn't set yet
    if (!this.editState || !this.editState.logs) return [];
    // We have no data in the logs
    if (this.editState.logs.length <= 0) return [];
    const results: CtrLogRow[] = [];
    this.editState.logs.forEach((record: CtrLogItem) => {
      const row: CtrLogRow = {
        createdAt: record.created_at,
        date: this.parseDateUiFromDateTime(record.created_at) || '-',
        time: this.parseTimeUiFromDateTime(record.created_at) || '-',
        // TODO: This seems unnecessary, might be safe to remove later
        type: this.editState.type === 'recipients' ? this.$t('recipient').toString() : this.$t('donor').toString(),
        status: record.status || '-',
      };
      results.push(row);
    });
    // Sort rows by createdAt
    return sortByDate(results, { fieldName: 'createdAt' });
  }
  
  // Initialize by type (recipients || deceasedDonors)
  public initialize(type: string, clientId: string) {
    // Skip initializing if we have no type or clientId
    if (!type || !clientId) return;
    // Set our initial state
    this.currentPage.ctrLogs = { type, logs: [] };
    // Get CTR logs by type and clientId
    this.$store.dispatch(`${type}/getCtrLogs`, { clientId: clientId }).then(() => {
      if (type === 'recipients') {
        // Set state for recipient logs
        this.editState.logs = this.ctrLogsRecipents;
      } else if (type === 'deceasedDonors') {
        // Set state for donor logs
        this.editState.logs = this.ctrLogsDonors;
      }
      // Toggle the modal
      (this.$refs.ctrLogModal as ModalSection).toggleModal();
    });
  }
}
</script>
