export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "transplant_details_popup_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Details"])},
        "opo-transplant-opo-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPO Code/Short Name"])},
        "opo-transplant-opo-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPO Name"])},
        "opo-transplant-province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "opo-transplant-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "opo-transplant-offered-organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offered Organ"])},
        "opo-transplant-offer-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Time"])},
        "opo-transplant-offer-response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Response"])},
        "opo-transplant-offer-response-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Response Time"])},
        "opo-transplant-donor-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor ID"])},
        "opo-transplant-recipient-age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Age"])},
        "opo-transplant-recipient-gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Gender"])},
        "opo-transplant-transplant-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Date"])},
        "opo-transplant-transplant-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Time"])},
        "opo-transplant-transplant-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Type"])},
        "opo-transplant-comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
        "save_transplant_details_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Transplant Details"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])}
      },
      "fr": {
        "transplant_details_popup_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la transplantation"])},
        "opo-transplant-opo-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code OPO/Nom abrégé"])},
        "opo-transplant-opo-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom OPO"])},
        "opo-transplant-province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "opo-transplant-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
        "opo-transplant-offered-organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe offert"])},
        "opo-transplant-offer-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'offre"])},
        "opo-transplant-offer-response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse à l'offre"])},
        "opo-transplant-offer-response-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de réponse de l'offre"])},
        "opo-transplant-donor-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Donneur"])},
        "opo-transplant-recipient-age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge du patient"])},
        "opo-transplant-recipient-gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre du patient"])},
        "opo-transplant-transplant-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de transplantation"])},
        "opo-transplant-transplant-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transplantation"])},
        "opo-transplant-transplant-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de transplantation"])},
        "opo-transplant-comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
        "save_transplant_details_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les détails de la transplantation"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours..."])}
      }
    }
  })
}
