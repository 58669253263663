<template>
  <card-section
    section-id="hla-antibodies"
    :lookups-to-load="lookupsToLoad"
    :laboratories-to-load="laboratoriesToLoad"
    :saveButton="false"
    ref="HlaAntibodies"
    saveButtonText="Save HLA Antibodies Test"
    @loaded="loaded"
  >
    <template v-slot:header>
      Antibodies
    </template>
    <template v-slot:body>
      <hla-antibodies-comments
        ref="hlaComments"
        @handleErrors="handleErrors"
        @clear="clear"
        @loaded="(sectionName: string) => loadedSubsection(sectionName)"
        :canSave="checkAllowed('/recipients/:recipient_id/diagnostics_hla', 'PATCH')"
      />
      <hla-antibodies-test
        ref="hlaAntibodiesTest"
        @saved="saved"
        @handleErrors="handleErrors"
        @clear="clear"
        @loaded="(sectionName: string) => loadedSubsection(sectionName)"
        :canSave="checkAllowed('/recipients/:recipient_id/hla_antibodies', 'POST')"
      />
    </template>
  </card-section>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import { Recipient } from '@/store/recipients/types';
import HlaAntibodiesTest, { HlaAntibodyRow } from '@/components/hla/HlaAntibodiesTest.vue';
import HlaAntibodiesComments, { HlaCommentsForm } from '@/components/hla/HlaAntibodiesComments.vue';

// Form schema
export interface AntibodiesPageState {
  hlaComments?: HlaCommentsForm;
  hlaAntibodies?: HlaAntibodyRow;
}

@Component({
  components: {
    CardSection,
    SaveToolbar,
    HlaAntibodiesTest,
    HlaAntibodiesComments
  },
  emits: [
    'handleErrors',
    'clear',
    'saved',
  ],
})
export default class HlaAntibodies extends Vue {
  // State
  @State(state => state.pageState.currentPage.hlaAntibodies) editState!: AntibodiesPageState;
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  @Prop({ default: false }) canSave!: boolean;

  private isLoading = true;
  private isFinishedLoadingLookups = false;
  private isFinishedLoadingSubsections = new Set();
  private totalSubSections = 2; // How many sub sections are we mounting

  // Lookup tables to be loaded by the CardSection component
  private lookupsToLoad = [
    'hla_testing_method',
    'hla_dictionary',
    'hla_dictionary_epitopes',
    'laboratory_hla_antibody_test_kits',
    'hla_antibody_allele_group_overrides',
  ];

  // Have we finished loading lookups and mounting the sub-sections?
  get isFinishedLoading(): boolean {
    return this.isFinishedLoadingLookups && this.isFinishedLoadingSubsections.size === this.totalSubSections;
  }

  // Laboratory lookups to be loaded by the CardSection component
  private laboratoriesToLoad = ['hla'];

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result = {};

    // HLA Comments Section
    const hlaCommentsSection = this.$refs.hlaComments as HlaAntibodiesComments;
    if (hlaCommentsSection) {
      Object.assign(result, { ...hlaCommentsSection.idLookup() });
    }
    
    // Antibody Test Section
    const hlaAntibodiesTestSection = this.$refs.hlaAntibodiesTest as HlaAntibodiesTest;
    if (hlaAntibodiesTestSection) {
      Object.assign(result, { ...hlaAntibodiesTestSection.idLookup() });
    }
    
    return result;
  }

  // Event handlers


  // Private methods

  /**
   * Loads all HLA Antibodies Tests for the selected recipient.
   *
   * Called after the Card Section has finished loading any relevant lookups.
   *
   * @listens antibodies#loaded
   */
  private loaded(): void {
    this.isFinishedLoadingLookups = true;
    this.checkIfLoadingComplete();
  }

  // After sub section mounts add the ref to isFinishedLoadingSubsections
  private loadedSubsection(sectionName: string): void {
    this.isFinishedLoadingSubsections.add(sectionName);
    this.checkIfLoadingComplete();
  }

  // Check if all sub sections have been mounted
  private checkIfLoadingComplete(): void {
    if (this.isFinishedLoading) {
      this.isLoading = false;
    }
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }
  
  // Emit event to parent so it can clear validations
  private clear() {
    this.$emit('clear');
  }

  // Handle saved event generated by descendent component
  private saved(): void {
    this.$emit('saved');
  }
}
</script>
