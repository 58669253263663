import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_2 = { class: "form-group col-sm-6" }
const _hoisted_3 = { class: "form-group col-sm-6" }
const _hoisted_4 = { class: "form-group col-sm-6" }
const _hoisted_5 = { class: "form-group col-sm-6" }
const _hoisted_6 = { class: "modal-footer-body" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["onClick", "disabled"]
const _hoisted_9 = {
  key: 0,
  class: "pl-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, {
    ref: "offerResponseDeclineValidations",
    autocomplete: "off",
    tag: "form"
  }, {
    default: _withCtx(({ handleSubmit }) => [
      _createVNode(_component_modal_section, {
        modalId: "offer-response-decline",
        ref: "offerResponseDecline",
        centered: true,
        closeButton: false
      }, _createSlots({
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('decline_multiple_offers')), 1)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              type: "button",
              "data-dismiss": "modal",
              class: "btn btn-secondary",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.closeModal())),
              disabled: _ctx.isLoadingAllocation || _ctx.isRespondingOffer || _ctx.isDecliningMultiple
            }, _toDisplayString(_ctx.$t('cancel')), 9, _hoisted_7),
            _createElementVNode("button", {
              class: "btn btn-success",
              onClick: _withModifiers(($event: any) => (handleSubmit(_ctx.confirmChanges)), ["prevent"]),
              disabled: _ctx.isLoadingAllocation || _ctx.isRespondingOffer || _ctx.isDecliningMultiple
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('confirm_and_save_responses')) + " ", 1),
              (_ctx.isDecliningMultiple)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "fa-spin",
                      icon: ['far', 'spinner-third']
                    })
                  ]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_8)
          ])
        ]),
        _: 2
      }, [
        (!_ctx.editState)
          ? {
              name: "body",
              fn: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
              ]),
              key: "0"
            }
          : {
              name: "body",
              fn: _withCtx(() => [
                (!!_ctx.offerResponseErrorMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.offerResponseErrorMessage), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('decline_reasons_to_send_multiple_offers')), 1),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_select_input, {
                    "select-id": "offer-decline-response",
                    class: "form-group",
                    name: _ctx.$t('response'),
                    value: _ctx.editState.setting.responseCode,
                    options: _ctx.responseOptions(_ctx.editState.setting, _ctx.editState.offerResponses),
                    disabled: _ctx.disableResponseOptions(_ctx.editState.setting),
                    onChange: _cache[0] || (_cache[0] = ($event: any) => {_ctx.updateRow($event, 'responseCode'); _ctx.clearValues(['responseCategoryCode', 'responseReasonCode'])})
                  }, null, 8, ["name", "value", "options", "disabled"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_select_input, {
                    rules: "required",
                    "select-id": "offer-decline-reason-category",
                    class: "form-group",
                    name: _ctx.$t('reason_category'),
                    value: _ctx.editState.setting.responseCategoryCode,
                    options: _ctx.reasonCategoryOptions(_ctx.editState.setting, _ctx.editState.offerResponses, _ctx.editState.organCode),
                    disabled: _ctx.disableResponseCategoryOptions(_ctx.editState.setting),
                    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateRow($event, 'responseCategoryCode')))
                  }, null, 8, ["name", "value", "options", "disabled"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_select_input, {
                    rules: "required",
                    "select-id": "offer-decline-reason-code",
                    class: "form-group",
                    name: _ctx.$t('reason'),
                    value: _ctx.editState.setting.responseReasonCode,
                    options: _ctx.reasonOptions(_ctx.editState.setting, _ctx.editState.offerResponses, _ctx.editState.organCode),
                    disabled: !_ctx.editState.setting.responseCategoryCode ? true : false,
                    onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateRow($event, 'responseReasonCode')))
                  }, null, 8, ["name", "value", "options", "disabled"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_select_input, {
                    rules: !_ctx.outOfProvince ? 'required' : '',
                    "select-id": "offer-decline-responsible-physician",
                    class: "form-group",
                    name: _ctx.$t('responsible_physician'),
                    value: _ctx.editState.setting.responsiblePhysician,
                    options: _ctx.responsiblePhysicianOptions(_ctx.editState.setting.hospitalId, _ctx.editState.organCode),
                    onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateRow($event, 'responsiblePhysician'))),
                    disabled: _ctx.outOfProvince
                  }, null, 8, ["rules", "name", "value", "options", "disabled"])
                ])
              ]),
              key: "1"
            }
      ]), 1536)
    ]),
    _: 1
  }, 512))
}