import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_3 = {
  key: 1,
  class: "legend-title"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group-large" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "standard-form-group" }
const _hoisted_16 = { class: "standard-form-group" }
const _hoisted_17 = {
  key: 0,
  class: "row"
}
const _hoisted_18 = { class: "col-sm-12" }
const _hoisted_19 = { class: "fieldset" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "standard-form-group-large" }
const _hoisted_22 = { class: "legend-title mb-3" }
const _hoisted_23 = { class: "form-group" }
const _hoisted_24 = {
  key: 0,
  class: "form-group"
}
const _hoisted_25 = {
  key: 1,
  class: "form-group"
}
const _hoisted_26 = {
  key: 2,
  class: "form-group"
}
const _hoisted_27 = {
  key: 3,
  class: "form-group"
}
const _hoisted_28 = {
  key: 4,
  class: "form-group"
}
const _hoisted_29 = { class: "standard-form-group-large" }
const _hoisted_30 = { class: "legend-title mb-3" }
const _hoisted_31 = { class: "form-group" }
const _hoisted_32 = {
  key: 0,
  class: "form-group"
}
const _hoisted_33 = {
  key: 1,
  class: "form-group"
}
const _hoisted_34 = {
  key: 2,
  class: "form-group"
}
const _hoisted_35 = {
  key: 3,
  class: "form-group"
}
const _hoisted_36 = {
  key: 4,
  class: "form-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_hla_input = _resolveComponent("hla-input")!
  const _component_hla_input_group = _resolveComponent("hla-input-group")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: _ctx.$t('hla_antibodies_tests'),
    "sub-section-id": "antibodies-tests",
    disabled: !_ctx.canSave,
    onLoaded: _cache[44] || (_cache[44] = ($event: any) => (_ctx.loaded())),
    saveButton: true,
    "save-button-text": _ctx.$t('save_hla_antibodies_test'),
    onSave: _cache[45] || (_cache[45] = ($event: any) => (_ctx.savePatch())),
    ref: "saveHlaAntibodies"
  }, {
    contents: _withCtx(() => [
      _createVNode(_component_table_toolbar, {
        createButton: _ctx.canSave,
        createText: _ctx.$t('create_hla_antibodies_test'),
        onTableCreateRow: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createHlaAntibodies()))
      }, null, 8, ["createButton", "createText"]),
      _createVNode(_component_table_list, {
        ref: "patientAddressTable",
        "table-id": "patient-address-table",
        tabbableColumn: "sample_code",
        "table-config": _ctx.hlaAntibodiesTableConfig,
        onTableRowClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectHlaAntibodies($event))),
        highlightSelection: true
      }, null, 8, ["table-config"]),
      (_ctx.editState && (_ctx.selectedHlaAntibodies || _ctx.canSave))
        ? (_openBlock(), _createElementBlock("fieldset", {
            key: 0,
            disabled: !_ctx.canSave
          }, [
            _createElementVNode("legend", null, [
              (!_ctx.selectedHlaAntibodies)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('new_hla_antibodies_test')), 1))
                : (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.$t('selected_hla_antibodies_test')), 1))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_text_input, {
                  inputId: "antibodies-tests-sample_code",
                  name: _ctx.$t('sample_code'),
                  modelValue: _ctx.editState.sample_code,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.sample_code) = $event)),
                  rules: "required"
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_date_input, {
                  inputId: "antibodies-tests-sample_draw_date",
                  name: _ctx.$t('sample_draw_date'),
                  modelValue: _ctx.editState.sample_draw_date,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.sample_draw_date) = $event)),
                  rules: "required"
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_ctx.selectedHlaAntibodies)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_text_input, {
                        "input-id": "antibodies-tests-hla_lab",
                        name: _ctx.$t('laboratory'),
                        disabled: true,
                        modelValue: _ctx.editState.hla_lab_name,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.hla_lab_name) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_select_input, {
                        selectId: "antibodies-tests-hla_lab",
                        name: _ctx.$t('laboratory'),
                        modelValue: _ctx.editState.hla_lab,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.hla_lab) = $event)),
                        options: _ctx.laboratoryLookup,
                        "text-key": "name",
                        "value-key": "lab_code",
                        onChange: _ctx.changedHlaLaboratory,
                        rules: "required"
                      }, null, 8, ["name", "modelValue", "options", "onChange"])
                    ]))
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_select_input, {
                  selectId: "antibodies-tests-testing_method_default",
                  name: _ctx.$t('testing_method'),
                  modelValue: _ctx.editState.testing_method_default,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.testing_method_default) = $event)),
                  options: _ctx.hlaTestingMethodLookup,
                  onChange: _ctx.changedTestingMethodDefault,
                  numeric: true,
                  rules: "required"
                }, null, 8, ["name", "modelValue", "options", "onChange"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_text_area_input, {
                  inputId: "antibodies-tests-comments",
                  name: _ctx.$t('comments'),
                  modelValue: _ctx.editState.comments,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.comments) = $event))
                }, null, 8, ["name", "modelValue"])
              ])
            ]),
            (_ctx.showNonStorage)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_date_input, {
                        inputId: "antibodies-tests-sample_tested_date",
                        name: _ctx.$t('sample_tested_date'),
                        modelValue: _ctx.editState.sample_tested_date,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.sample_tested_date) = $event)),
                        rules: "required"
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      (_ctx.showSabClass1)
                        ? (_openBlock(), _createBlock(_component_number_input, {
                            key: 0,
                            inputId: "antibodies-tests-cpra_class1",
                            name: `${_ctx.$t('cpras', { class: _ctx.$t('class_i'), cpra: _ctx.$t('cpra') } )}`,
                            modelValue: _ctx.editState.cpra_class1,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.cpra_class1) = $event)),
                            validationId: "cpra_class1",
                            step: "0.01",
                            calculated: true,
                            disabled: true
                          }, null, 8, ["name", "modelValue"]))
                        : (_ctx.showPraClass1)
                          ? (_openBlock(), _createBlock(_component_number_input, {
                              key: 1,
                              inputId: "antibodies-tests-pra_class1",
                              name: `${_ctx.$t('pras', { class: _ctx.$t('class_i'), pra: _ctx.$t('pra') } )}`,
                              modelValue: _ctx.editState.cpra_class1,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.cpra_class1) = $event)),
                              validationId: "cpra_class1",
                              step: "0.01",
                              rules: "required"
                            }, null, 8, ["name", "modelValue"]))
                          : (_openBlock(), _createBlock(_component_number_input, {
                              key: 2,
                              inputId: "antibodies-tests-pra_class1",
                              name: `${_ctx.$t('pras', { class: _ctx.$t('class_i'), pra: _ctx.$t('pra') } )}`,
                              modelValue: _ctx.editState.cpra_class1,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.cpra_class1) = $event)),
                              validationId: "cpra_class1",
                              step: "0.01",
                              disabled: true
                            }, null, 8, ["name", "modelValue"]))
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      (_ctx.showSabClass2)
                        ? (_openBlock(), _createBlock(_component_number_input, {
                            key: 0,
                            inputId: "antibodies-tests-cpra_class2",
                            name: `${_ctx.$t('cpras', { class: _ctx.$t('class_ii'), cpra: _ctx.$t('cpra') } )}`,
                            modelValue: _ctx.editState.cpra_class2,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.cpra_class2) = $event)),
                            validationId: "cpra_class2",
                            step: "0.01",
                            calculated: true,
                            disabled: true
                          }, null, 8, ["name", "modelValue"]))
                        : (_ctx.showPraClass2)
                          ? (_openBlock(), _createBlock(_component_number_input, {
                              key: 1,
                              inputId: "antibodies-tests-pra_class2",
                              name: `${_ctx.$t('pras', { class: _ctx.$t('class_ii'), pra: _ctx.$t('pra') } )}`,
                              modelValue: _ctx.editState.cpra_class2,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.cpra_class2) = $event)),
                              validationId: "cpra_class2",
                              step: "0.01",
                              rules: "required"
                            }, null, 8, ["name", "modelValue"]))
                          : (_openBlock(), _createBlock(_component_number_input, {
                              key: 2,
                              inputId: "antibodies-tests-pra_class2",
                              name: `${_ctx.$t('pras', { class: _ctx.$t('class_ii'), pra: _ctx.$t('pra') } )}`,
                              modelValue: _ctx.editState.cpra_class2,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.cpra_class2) = $event)),
                              validationId: "cpra_class2",
                              step: "0.01",
                              disabled: true
                            }, null, 8, ["name", "modelValue"]))
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      (_ctx.showCombinedCpra)
                        ? (_openBlock(), _createBlock(_component_number_input, {
                            key: 0,
                            inputId: "antibodies-tests-combined_cpra",
                            name: _ctx.$t('combined_cpra'),
                            modelValue: _ctx.editState.combined_cpra,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.combined_cpra) = $event)),
                            validationId: "combined_cpra",
                            step: "0.01",
                            calculated: true,
                            disabled: true
                          }, null, 8, ["name", "modelValue"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  (_ctx.editState && _ctx.editState.antibodies)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("h6", _hoisted_22, _toDisplayString(_ctx.$t('class_1')), 1),
                                _createElementVNode("div", _hoisted_23, [
                                  _createVNode(_component_select_input, {
                                    selectId: "antibodies-tests-testing_method_class1",
                                    name: `${_ctx.$t('testing_methods', { class: _ctx.$t('class_i'), method: _ctx.$t('testing_method_lower') } )}`,
                                    modelValue: _ctx.editState.testing_method_class1,
                                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.testing_method_class1) = $event)),
                                    options: _ctx.hlaTestingMethodLookup,
                                    onChange: _ctx.onTestingMethodClass1Change,
                                    numeric: true,
                                    rules: "required"
                                  }, null, 8, ["name", "modelValue", "options", "onChange"])
                                ]),
                                (_ctx.showSabClass1)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                      _createVNode(_component_select_input, {
                                        selectId: "antibodies-tests-testing_kit_class1",
                                        name: `${_ctx.$t('testing_kits', { class: _ctx.$t('class_i'), kit: _ctx.$t('testing_kit_lower') } )}`,
                                        modelValue: _ctx.editState.testing_kit_class1,
                                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState.testing_kit_class1) = $event)),
                                        options: _ctx.hlaTestingKitClass1Options,
                                        onChange: _ctx.onTestingKitClass1Change,
                                        rules: "required",
                                        hideExpired: false
                                      }, null, 8, ["name", "modelValue", "options", "onChange"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.showSabClass1)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                      _createVNode(_component_hla_input, {
                                        inputId: "antibodies-tests-antibodies_class1_acceptable",
                                        name: _ctx.$t('acceptable'),
                                        modelValue: _ctx.editState.antibodies.class1_acceptable,
                                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editState.antibodies.class1_acceptable) = $event)),
                                        alleleSpecific: _ctx.editState.antibodies.class1_allele_specific,
                                        alphaBetaSpecific: _ctx.editState.antibodies.class1_alpha_beta_specific,
                                        disabled: true,
                                        inputClass: "hla-input hla-acceptable",
                                        disabledEmptyText: _ctx.$t('no_acceptable_antibodies_kit_i')
                                      }, null, 8, ["name", "modelValue", "alleleSpecific", "alphaBetaSpecific", "disabledEmptyText"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.showSabClass1)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                      _createVNode(_component_hla_input_group, {
                                        inputId: "antibodies-tests-antibodies_class1_unacceptable",
                                        name: _ctx.$t('unacceptable'),
                                        modelValue: _ctx.editState.antibodies.class1_unacceptable,
                                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editState.antibodies.class1_unacceptable) = $event)),
                                        inputClass: "hla-input hla-unacceptable",
                                        placeholder: _ctx.$t('add_antibody'),
                                        onInput: _cache[20] || (_cache[20] = ($event: any) => (_ctx.onSabAntibodiesInput(_ctx.sabClass.Class1, _ctx.sabCategory.Unacceptable))),
                                        "infer-locus": true,
                                        alleleSpecific: _ctx.editState.antibodies.class1_unacceptable_allele_specific,
                                        alphaBeta: _ctx.editState.antibodies.class1_unacceptable_alpha_beta,
                                        "enable-epitopes": true,
                                        epitopes: _ctx.editState.antibodies.epitopes_unacceptable,
                                        onAlleleSpecificInput: _cache[21] || (_cache[21] = (newValue) => _ctx.onClass1AlleleSpecificInput(newValue, _ctx.sabCategory.Unacceptable)),
                                        onAlphaBetaInput: _cache[22] || (_cache[22] = (newValue) => _ctx.onClass1AlphaBetaInput(newValue, _ctx.sabCategory.Unacceptable)),
                                        onEpitopesInput: _cache[23] || (_cache[23] = (newValue) => _ctx.onEpitopesInput(newValue, _ctx.sabCategory.Unacceptable)),
                                        invalidAntibodies: _ctx.editState.invalidAntibodies.class1_unacceptable_allele_group,
                                        invalidAlleleSpecifics: _ctx.editState.invalidAntibodies.class1_unacceptable_allele_specific,
                                        invalidAlphaBetas: _ctx.editState.invalidAntibodies.class1_unacceptable_alpha_beta,
                                        invalidEpitopes: _ctx.editState.invalidAntibodies.epitopes_unacceptable,
                                        disabled: !_ctx.canSave
                                      }, null, 8, ["name", "modelValue", "placeholder", "alleleSpecific", "alphaBeta", "epitopes", "invalidAntibodies", "invalidAlleleSpecifics", "invalidAlphaBetas", "invalidEpitopes", "disabled"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.showSabClass1)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                      _createVNode(_component_hla_input_group, {
                                        inputId: "antibodies-tests-antibodies_class1_indeterminate",
                                        name: _ctx.$t('indeterminate'),
                                        modelValue: _ctx.editState.antibodies.class1_indeterminate,
                                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.editState.antibodies.class1_indeterminate) = $event)),
                                        inputClass: "hla-input hla-indeterminate",
                                        placeholder: _ctx.$t('add_antibody'),
                                        onInput: _cache[25] || (_cache[25] = ($event: any) => (_ctx.onSabAntibodiesInput(_ctx.sabClass.Class1, _ctx.sabCategory.Indeterminate))),
                                        "infer-locus": true,
                                        alleleSpecific: _ctx.editState.antibodies.class1_indeterminate_allele_specific,
                                        alphaBeta: _ctx.editState.antibodies.class1_indeterminate_alpha_beta,
                                        "enable-epitopes": true,
                                        epitopes: _ctx.editState.antibodies.epitopes_indeterminate,
                                        onAlleleSpecificInput: _cache[26] || (_cache[26] = (newValue) => _ctx.onClass1AlleleSpecificInput(newValue, _ctx.sabCategory.Indeterminate)),
                                        onAlphaBetaInput: _cache[27] || (_cache[27] = (newValue) => _ctx.onClass1AlphaBetaInput(newValue, _ctx.sabCategory.Indeterminate)),
                                        onEpitopesInput: _cache[28] || (_cache[28] = (newValue) => _ctx.onEpitopesInput(newValue, _ctx.sabCategory.Indeterminate)),
                                        invalidAntibodies: _ctx.editState.invalidAntibodies.class1_indeterminate_allele_group,
                                        invalidAlleleSpecifics: _ctx.editState.invalidAntibodies.class1_indeterminate_allele_specific,
                                        invalidAlphaBetas: _ctx.editState.invalidAntibodies.class1_indeterminate_alpha_beta,
                                        invalidEpitopes: _ctx.editState.invalidAntibodies.epitopes_indeterminate,
                                        disabled: !_ctx.canSave
                                      }, null, 8, ["name", "modelValue", "placeholder", "alleleSpecific", "alphaBeta", "epitopes", "invalidAntibodies", "invalidAlleleSpecifics", "invalidAlphaBetas", "invalidEpitopes", "disabled"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.showSabClass1 && _ctx.showClass1PossibleAlleleSpecific)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                      _createVNode(_component_hla_input, {
                                        inputId: "antibodies-tests-antibodies_class1_possible_allele_specific",
                                        name: _ctx.$t('possible_allele_specific_field'),
                                        modelValue: _ctx.editState.antibodies.class1_possible_allele_specific,
                                        "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.editState.antibodies.class1_possible_allele_specific) = $event)),
                                        inputClass: "hla-input hla-possible-allele-specific",
                                        calculated: true,
                                        calculatedText: _ctx.$t('legacy_data_indicator'),
                                        calculatedHoverText: _ctx.$t('legacy_data_explanation'),
                                        disabled: !_ctx.canSave,
                                        onInput: _cache[30] || (_cache[30] = ($event: any) => (_ctx.onSabAntibodiesInput(_ctx.sabClass.Class1, _ctx.sabCategory.PossibleAlleleSpecific))),
                                        invalidAntibodies: _ctx.editState.invalidAntibodies.class1_possible_allele_specific
                                      }, null, 8, ["name", "modelValue", "calculatedText", "calculatedHoverText", "disabled", "invalidAntibodies"])
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("div", _hoisted_29, [
                                _createElementVNode("h6", _hoisted_30, _toDisplayString(_ctx.$t('class_2')), 1),
                                _createElementVNode("div", _hoisted_31, [
                                  _createVNode(_component_select_input, {
                                    selectId: "antibodies-tests-testing_method_class2",
                                    name: `${_ctx.$t('testing_methods', { class: _ctx.$t('class_ii'), method: _ctx.$t('testing_method_lower') } )}`,
                                    modelValue: _ctx.editState.testing_method_class2,
                                    "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.editState.testing_method_class2) = $event)),
                                    options: _ctx.hlaTestingMethodLookup,
                                    onChange: _ctx.onTestingMethodClass2Change,
                                    numeric: true,
                                    rules: "required"
                                  }, null, 8, ["name", "modelValue", "options", "onChange"])
                                ]),
                                (_ctx.showSabClass2)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                      _createVNode(_component_select_input, {
                                        selectId: "antibodies-tests-testing_kit_class2",
                                        name: `${_ctx.$t('testing_kits', { class: _ctx.$t('class_ii'), kit: _ctx.$t('testing_kit_lower') } )}`,
                                        modelValue: _ctx.editState.testing_kit_class2,
                                        "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.editState.testing_kit_class2) = $event)),
                                        options: _ctx.hlaTestingKitClass2Options,
                                        onChange: _ctx.onTestingKitClass2Change,
                                        rules: "required",
                                        hideExpired: false
                                      }, null, 8, ["name", "modelValue", "options", "onChange"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.showSabClass2)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                      _createVNode(_component_hla_input, {
                                        inputId: "antibodies-tests-antibodies_class2_acceptable",
                                        name: _ctx.$t('acceptable'),
                                        modelValue: _ctx.editState.antibodies.class2_acceptable,
                                        "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.editState.antibodies.class2_acceptable) = $event)),
                                        alleleSpecific: _ctx.editState.antibodies.class2_allele_specific,
                                        alphaBetaSpecific: _ctx.editState.antibodies.class2_alpha_beta_specific,
                                        disabled: true,
                                        inputClass: "hla-input hla-acceptable",
                                        disabledEmptyText: _ctx.$t('no_acceptable_antibodies_kit_ii')
                                      }, null, 8, ["name", "modelValue", "alleleSpecific", "alphaBetaSpecific", "disabledEmptyText"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.showSabClass2)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                      _createVNode(_component_hla_input_group, {
                                        inputId: "antibodies-tests-antibodies_class2_unacceptable",
                                        name: _ctx.$t('unacceptable'),
                                        modelValue: _ctx.editState.antibodies.class2_unacceptable,
                                        "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.editState.antibodies.class2_unacceptable) = $event)),
                                        inputClass: "hla-input hla-unacceptable",
                                        placeholder: _ctx.$t('add_antibody'),
                                        onInput: _cache[35] || (_cache[35] = ($event: any) => (_ctx.onSabAntibodiesInput(_ctx.sabClass.Class2, _ctx.sabCategory.Unacceptable))),
                                        "infer-locus": true,
                                        alleleSpecific: _ctx.editState.antibodies.class2_unacceptable_allele_specific,
                                        alphaBeta: _ctx.editState.antibodies.class2_unacceptable_alpha_beta,
                                        onAlleleSpecificInput: _cache[36] || (_cache[36] = (newValue) => _ctx.onClass2AlleleSpecificInput(newValue, _ctx.sabCategory.Unacceptable)),
                                        onAlphaBetaInput: _cache[37] || (_cache[37] = (newValue) => _ctx.onClass2AlphaBetaInput(newValue, _ctx.sabCategory.Unacceptable)),
                                        invalidAntibodies: _ctx.editState.invalidAntibodies.class2_unacceptable_allele_group,
                                        invalidAlleleSpecifics: _ctx.editState.invalidAntibodies.class2_unacceptable_allele_specific,
                                        invalidAlphaBetas: _ctx.editState.invalidAntibodies.class2_unacceptable_alpha_beta,
                                        disabled: !_ctx.canSave
                                      }, null, 8, ["name", "modelValue", "placeholder", "alleleSpecific", "alphaBeta", "invalidAntibodies", "invalidAlleleSpecifics", "invalidAlphaBetas", "disabled"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.showSabClass2)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                      _createVNode(_component_hla_input_group, {
                                        inputId: "antibodies-tests-antibodies_class2_indeterminate",
                                        name: _ctx.$t('indeterminate'),
                                        modelValue: _ctx.editState.antibodies.class2_indeterminate,
                                        "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.editState.antibodies.class2_indeterminate) = $event)),
                                        inputClass: "hla-input hla-indeterminate",
                                        placeholder: _ctx.$t('add_antibody'),
                                        onInput: _cache[39] || (_cache[39] = ($event: any) => (_ctx.onSabAntibodiesInput(_ctx.sabClass.Class2, _ctx.sabCategory.Indeterminate))),
                                        "infer-locus": true,
                                        alleleSpecific: _ctx.editState.antibodies.class2_indeterminate_allele_specific,
                                        alphaBeta: _ctx.editState.antibodies.class2_indeterminate_alpha_beta,
                                        onAlleleSpecificInput: _cache[40] || (_cache[40] = (newValue) => _ctx.onClass2AlleleSpecificInput(newValue, _ctx.sabCategory.Indeterminate)),
                                        onAlphaBetaInput: _cache[41] || (_cache[41] = (newValue) => _ctx.onClass2AlphaBetaInput(newValue, _ctx.sabCategory.Indeterminate)),
                                        invalidAntibodies: _ctx.editState.invalidAntibodies.class2_indeterminate_allele_group,
                                        invalidAlleleSpecifics: _ctx.editState.invalidAntibodies.class2_indeterminate_allele_specific,
                                        invalidAlphaBetas: _ctx.editState.invalidAntibodies.class2_indeterminate_alpha_beta,
                                        disabled: !_ctx.canSave
                                      }, null, 8, ["name", "modelValue", "placeholder", "alleleSpecific", "alphaBeta", "invalidAntibodies", "invalidAlleleSpecifics", "invalidAlphaBetas", "disabled"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.showSabClass2 && _ctx.showClass2PossibleAlleleSpecific)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                      _createVNode(_component_hla_input, {
                                        inputId: "antibodies-tests-antibodies_class2_possible_allele_specific",
                                        name: _ctx.$t('possible_allele_specific_field'),
                                        modelValue: _ctx.editState.antibodies.class2_possible_allele_specific,
                                        "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.editState.antibodies.class2_possible_allele_specific) = $event)),
                                        inputClass: "hla-input hla-possible-allele-specific",
                                        calculated: true,
                                        calculatedText: _ctx.$t('legacy_data_indicator'),
                                        calculatedHoverText: _ctx.$t('legacy_data_explanation'),
                                        disabled: !_ctx.canSave,
                                        onInput: _cache[43] || (_cache[43] = ($event: any) => (_ctx.onSabAntibodiesInput(_ctx.sabClass.Class2, _ctx.sabCategory.PossibleAlleleSpecific))),
                                        invalidAntibodies: _ctx.editState.invalidAntibodies.class2_possible_allele_specific
                                      }, null, 8, ["name", "modelValue", "calculatedText", "calculatedHoverText", "disabled", "invalidAntibodies"])
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ], 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "disabled", "save-button-text"]))
}