import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card tip-card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "media align-items-center" }
const _hoisted_4 = { class: "media-left" }
const _hoisted_5 = { class: "media-body flex-row align-items-center" }
const _hoisted_6 = { class: "tip-report-name" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "form-group col-sm-12 col-md-6 col-lg-4 col-xl-3" }
const _hoisted_10 = { class: "form-group col-sm-12 col-md-6 col-lg-4 col-xl-3" }
const _hoisted_11 = { class: "form-group col-sm-12 col-md-6 col-lg-4 col-xl-3" }
const _hoisted_12 = { class: "form-group col-sm-12 col-md-6 col-lg-4 col-xl-3" }
const _hoisted_13 = { class: "form-group col-md-8 col-lg-4 col-xl-3 col-xl-3" }
const _hoisted_14 = { class: "form-group col-md-8 col-lg-4 col-xl-3 col-xl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    subSectionId: "recipient-data-audit-report",
    lookupsToLoad: _ctx.lookupsToLoad
  }, _createSlots({ _: 2 }, [
    (_ctx.editState)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'file-chart-line'],
                      "fixed-width": ""
                    })
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t(_ctx.report)), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_date_input, {
                        inputId: "recipientDataAuditReport-from_date",
                        name: _ctx.$t('from_date'),
                        modelValue: _ctx.editState.fromDate,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.fromDate) = $event)),
                        rules: "required"
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_date_input, {
                        inputId: "recipientDataAuditReport-to_date",
                        name: _ctx.$t('to_date'),
                        modelValue: _ctx.editState.toDate,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.toDate) = $event)),
                        rules: "required"
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_select_input, {
                        selectId: "recipientDataAuditReport-blood_type",
                        name: _ctx.$t('blood_type'),
                        modelValue: _ctx.editState.bloodType,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.bloodType) = $event)),
                        options: _ctx.bloodTypeLookup,
                        placeholder: _ctx.editState.bloodType.length == 0 ? _ctx.$t('all') : '',
                        multiple: true
                      }, null, 8, ["name", "modelValue", "options", "placeholder"])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_select_input, {
                        selectId: "recipientDataAuditReport-change_type",
                        name: _ctx.$t('change_type'),
                        modelValue: _ctx.editState.changeType,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.changeType) = $event)),
                        options: _ctx.changeTypesLookup,
                        placeholder: _ctx.editState.changeType.length == 0 ? _ctx.$t('all') : '',
                        multiple: true
                      }, null, 8, ["name", "modelValue", "options", "placeholder"])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_select_input, {
                        selectId: "recipientDataAuditReport-hospital",
                        name: _ctx.$t('hospital'),
                        modelValue: _ctx.editState.hospital,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.hospital) = $event)),
                        options: _ctx.filteredHospitalsByUser,
                        placeholder: _ctx.editState.hospital.length == 0 ? _ctx.$t('all') : '',
                        multiple: true,
                        "max-tags": 8
                      }, null, 8, ["name", "modelValue", "options", "placeholder"])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_select_input, {
                        selectId: "recipientDataAuditReport-organ_code",
                        name: _ctx.$t('organ_code'),
                        modelValue: _ctx.editState.organType,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.organType) = $event)),
                        options: _ctx.filterOrgansByUser,
                        placeholder: _ctx.editState.organType.length == 0 ? _ctx.$t('all') : '',
                        multiple: true
                      }, null, 8, ["name", "modelValue", "options", "placeholder"])
                    ])
                  ]),
                  _createVNode(_component_save_toolbar, {
                    class: "form-inline col-sm-12 action-row",
                    buttonClass: "btn btn-success",
                    ref: "generateReport",
                    savingText: _ctx.$t('saving_text'),
                    successText: _ctx.$t('success_text'),
                    defaultErrorText: _ctx.$t('default_error_text'),
                    onSave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.generateReport()))
                  }, {
                    label: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['far', 'file-chart-line'],
                        class: "mr-2"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t(`generate_${_ctx.report}`)), 1)
                    ]),
                    _: 1
                  }, 8, ["savingText", "successText", "defaultErrorText"])
                ])
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["lookupsToLoad"]))
}