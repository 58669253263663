import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-sm-12"
}
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "content-wrap" }
const _hoisted_4 = { class: "container-fluid" }
const _hoisted_5 = { class: "nav-wrapper" }
const _hoisted_6 = { class: "page-content" }
const _hoisted_7 = { class: "form-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page_top = _resolveComponent("page-top")!
  const _component_potential_duplicate_modal = _resolveComponent("potential-duplicate-modal")!
  const _component_side_nav_living_donor = _resolveComponent("side-nav-living-donor")!
  const _component_demographics = _resolveComponent("demographics")!
  const _component_contact_information = _resolveComponent("contact-information")!
  const _component_donation_information = _resolveComponent("donation-information")!
  const _component_general_clinical_information = _resolveComponent("general-clinical-information")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dispatchEventsComplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("loading")), 1)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: { name: 'list-living-donors' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("living_donors")), 1)
                ]),
                _: 1
              }),
              _createTextVNode(" / " + _toDisplayString(_ctx.$t("new")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_potential_duplicate_modal, {
            onPerformSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.performSave(true))),
            ref: "potentialDuplicateModal"
          }, null, 512),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_side_nav_living_donor, { newLivingDonor: true }),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_validation_observer, {
                    ref: "validations",
                    autocomplete: "off",
                    tag: "form"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_demographics, {
                        ref: "demographics",
                        newLivingDonor: true,
                        canSave: _ctx.isGroupWriteable('living_donor_personal')
                      }, null, 8, ["canSave"]),
                      _createVNode(_component_contact_information, {
                        ref: "contactInfo",
                        newLivingDonor: true,
                        canSave: _ctx.isGroupWriteable('living_donor_personal')
                      }, null, 8, ["canSave"]),
                      _createVNode(_component_donation_information, {
                        ref: "donationInformation",
                        newLivingDonor: true,
                        canSave: _ctx.isGroupWriteable('living_donor_personal')
                      }, null, 8, ["canSave"]),
                      _createVNode(_component_general_clinical_information, {
                        ref: "generalClinical",
                        newLivingDonor: true,
                        canSave: _ctx.isGroupWriteable('living_donor_personal')
                      }, null, 8, ["canSave"]),
                      _createElementVNode("div", _hoisted_7, [
                        (_ctx.canSaveLivingDonor)
                          ? (_openBlock(), _createBlock(_component_save_toolbar, {
                              key: 0,
                              ref: "saveLivingDonor",
                              label: _ctx.$t('save_living_donor'),
                              onSave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.performSave(false)))
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }, 512)
                ])
              ])
            ])
          ])
        ], 64))
  ]))
}