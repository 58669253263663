import { defineStore } from 'pinia';
import { CurrentPageState } from '@/stores/currentPage/types';
import { UIConfiguration } from '@/UIModels/configuration';
import { UIRecipient } from '@/UIModels/recipient';
import { UIJourney } from '@/UIModels/journey';

const state = (): CurrentPageState => ({
  configuration: null,
  currentRecipient: null,
  currentJourney: null
});

export const useCurrentPageStore = defineStore('currentPage', {
  state,
  actions: {
    setConfiguration(uiConfiguration: UIConfiguration|null): Promise<void> {
      return new Promise<void> ((resolve, _reject) => {
        this.configuration = uiConfiguration;
        resolve();
      });
    },
    setCurrentRecipient(uiRecipient: UIRecipient|null): Promise<void> {
      return new Promise<void> ((resolve, _reject) => {
        this.currentRecipient = uiRecipient;
        resolve();
      });
    },
    setCurrentJourney(uiJourney: UIJourney|null): Promise<void> {
      return new Promise<void> ((resolve, _reject) => {
        this.currentJourney = uiJourney;
        resolve();
      });
    },
  },
});
