export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize panel"])},
        "recent_living_donor_registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Living Donor Registrations"])},
        "no_living_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Living Donors"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse Panel"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Options"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Style"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this panel"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "recent_recipient_registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Recipient Registrations"])},
        "no_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Recipients"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient ID"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Date"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Recipient Page"])}
      },
      "fr": {
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le panneau"])},
        "recent_living_donor_registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscriptions des nouveaux donneurs vivants"])},
        "no_living_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun donneur viviant"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau rabattu"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options du panneau"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style du panneau"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher ce panneau"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "recent_recipient_registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscriptions récentes des patients"])},
        "no_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anucun patient"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Patient"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'inscription"])},
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page des patients"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun"])}
      }
    }
  })
}
