import Vue from "vue";
import { MutationTree } from "vuex";
import { PageState } from "@/store/currentPage/types";

export const mutations: MutationTree<PageState> = {
  set(state, { pageKey, componentKey, value }) {
    if (componentKey) {
      state.currentPage[pageKey][componentKey] = value;
    } else {
      state.currentPage[pageKey] = value;
    }
  },
};
