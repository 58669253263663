export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "exceptional_distribution_disposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution Disposition"])},
        "expired_post_release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired due to Post-Release"])},
        "toggle_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle selection of"])},
        "acceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance"])},
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Patient Page"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
        "exd_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ExD Form"])},
        "generating_exd_forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating ExD Forms"])},
        "exd_forms_generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ExD Forms generated"])},
        "problem_generating_exd_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem generating ExD Forms"])},
        "download_all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download all selected ExD Forms"])},
        "confirm_recipient_consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Patient Consent"])},
        "save_exd_forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save ExD Forms"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
        "organs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organs"])},
        "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
        "physician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physician"])},
        "date_time_acceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/Time Acceptance"])},
        "acceptance1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance"])},
        "acceptance_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance Reason"])},
        "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient"])},
        "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation Provided"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "no_permission_to_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to view this information"])},
        "no_exceptional_distribution_acceptances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no Exceptional Distribution Acceptances to Confirm"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes (eOffer)"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
      },
      "fr": {
        "exceptional_distribution_disposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposition de distribution exceptionnelle"])},
        "expired_post_release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiré en raison de la post-libération"])},
        "toggle_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la sélection de"])},
        "acceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptation"])},
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page du patient"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
        "exd_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire ExD"])},
        "generating_exd_forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération de formulaires ExD"])},
        "exd_forms_generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaires ExD générés"])},
        "problem_generating_exd_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a eu un problème lors de la génération des formulaires ExD"])},
        "download_all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger tous les formulaires ExD sélectionnés"])},
        "confirm_recipient_consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le consentement du patient"])},
        "save_exd_forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les formulaires ExD"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
        "organs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organes"])},
        "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme"])},
        "physician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecin"])},
        "date_time_acceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/heure d'acceptation"])},
        "acceptance1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptation"])},
        "acceptance_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison de l'acceptation"])},
        "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient"])},
        "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation fournie"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "no_permission_to_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas la permission de voir cette information"])},
        "no_exceptional_distribution_acceptances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas d'acceptation de distribution exceptionnelle à confirmer."])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui (eOffert)"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])}
      },
      "en_tgln": {
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Recipient Page"])},
        "confirm_recipient_consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Recipient Consent"])},
        "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])}
      },
      "fr_tgln": {
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page du patient"])},
        "confirm_recipient_consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le consentement du patient"])},
        "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient"])}
      }
    }
  })
}
