import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "subsection-action-row d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "button-bar"),
    (_ctx.createButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCreateButtonClick && _ctx.handleCreateButtonClick(...args))),
          type: "button",
          class: "btn btn-primary btn-sm sub-section-create-button ml-auto"
        }, _toDisplayString(_ctx.createText || _ctx.$t('create_row')), 1))
      : _createCommentVNode("", true)
  ]))
}