<template>
  <span v-if="isVerbose">
    <template v-if="result === '+'">
      {{ $t('positive_vxm_result') }}
    </template>
    <template v-else-if="result === '-'">
      {{ $t('negative_vxm_result') }}
    </template>
    <template v-else-if="result === '?'">
      {{ $t('indeterminate_vxm_result') }}
    </template>
    <template v-else-if="result === 'U'">
      {{ $t('untested_vxm_result') }}
    </template>
    <span v-else>{{ result }}</span>
  </span>
  <span v-else>
    <template v-if="result === '+'">
      <font-awesome-icon
        :icon="['far', 'plus-circle']"
        :title="$t('positive')"
        fixed-width class="text-red"
      />
      <span class="sr-only">{{ result }}</span>
    </template>
    <template v-else-if="result === '-'">
      <font-awesome-icon
        :icon="['far', 'minus-circle']"
        :title="$t('negative')"
        fixed-width class="text-green"
      />
      <span class="sr-only">{{ result }}</span>
    </template>
    <span v-else>{{ result }}</span>
  </span>
</template>

<script lang="ts">
import { i18nMessages } from "@/i18n";
import { VXM_VERBOSE_DISPLAY } from "@/store/administration/types";
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({
  ...i18nMessages([
    require('@/components/hla/_locales/HlaVirtualCrossmatchResult.json'),
  ]),
})
export default class HlaVirtualCrossmatchResult extends Vue {
  @Prop({ default: null }) result!: string|null;

  // Use VXM display features specified by 2022-016 Change Request?
  get isVerbose(): boolean {
    return VXM_VERBOSE_DISPLAY || false;
  }
}
</script>
