export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "transplant_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplantation Hospital ID"])},
        "dialysis_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialysis Hospital ID"])},
        "dialysis_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialysis Provider(s)"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])}
      },
      "fr": {
        "transplant_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplantation Hospital ID"])},
        "dialysis_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialysis Hospital ID"])},
        "dialysis_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialysis Provider(s)"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])}
      }
    }
  })
}
