import Vue from 'vue';
import { MutationTree } from 'vuex';
import { ObjectId } from '@/store/types';
import { LivingAllocationState, LivingAllocations, LivingAllocation, LivingExclusionRules, 
  LivingAllocationChecklistResponse, LivingAllocationChecklistForm, LivingAllocationChecklist, LivingRecipientDetails, LivingDonorDetails } from '@/store/livingAllocations/types';

export const mutations: MutationTree<LivingAllocationState> = {
  setExclusionRules(state: LivingAllocationState, exclusionRules: LivingExclusionRules[]) {
    state.exclusionRules = exclusionRules;
  },
  setAllocation(state: LivingAllocationState, allocation: LivingAllocation) {
    state.selected = allocation;
  },
  /**
   * Mutation to clear out a selected allocation so that the we can reset it.
   * @param state
   */
  clearAllocation(state: LivingAllocationState) {
    [
      'selected',
      'exclusionRules',
      'checklist',
      'lastLoadedAllocationDonorId'
    ].forEach((key: string) => (state as any)[key] = undefined);
  },
  setAllocations(state: LivingAllocationState, allocations: LivingAllocations[]) {
    state.allAllocations = allocations;
  },
  setActiveAllocations(state: LivingAllocationState, allocations: LivingAllocations[]) {
    state.activeAllocations = allocations;
  },
  setInactiveAllocations(state: LivingAllocationState, allocations: LivingAllocations[]) {
    state.inactiveAllocations = allocations;
  },
  startCreatingAllocation(state: LivingAllocationState) {
    state.isCreatingAllocation = true;
  },
  stopCreatingAllocation(state: LivingAllocationState) {
    state.isCreatingAllocation = false;
  },
  setLastLoadedAllocationDonorId(state: LivingAllocationState, livingDonorId: ObjectId) {
    state.lastLoadedAllocationDonorId = livingDonorId;
  },
  startLoading(state: LivingAllocationState) {
    state.isLoadingAllocation = true;
  },
  startLoadingAllocations(state: LivingAllocationState) {
    state.isLoadingAllocations = true;
  },
  stopLoading(state: LivingAllocationState) {
    state.isLoadingAllocation = false;
  },
  stopLoadingAllocations(state: LivingAllocationState) {
    state.isLoadingAllocations = false;
  },
  startGeneratingReport(state: LivingAllocationState) {
    state.isGeneratingAllocationReport = true;
  },
  stopGeneratingReport(state: LivingAllocationState) {
    state.isGeneratingAllocationReport = false;
  },
  startAddingRecipient(state: LivingAllocationState) {
    state.isAddingRecipient = true;
  },
  stopAddingRecipient(state: LivingAllocationState) {
    state.isAddingRecipient = false;
  },
  startDiscontinuingOneAllocation(state: LivingAllocationState) {
    state.isDiscontinuingOneAllocation = true;
  },
  stopDiscontinuingOneAllocation(state: LivingAllocationState) {
    state.isDiscontinuingOneAllocation = false;
  },
  startDiscontinuingAllAllocations(state: LivingAllocationState) {
    state.isDiscontinuingAllAllocations = true;
  },
  stopDiscontinuingAllAllocations(state: LivingAllocationState) {
    state.isDiscontinuingAllAllocations = false;
  },
  startDiscontinuingOneOffer(state: LivingAllocationState) {
    state.isDiscontinuingOneOffer = true;
  },
  stopDiscontinuingOneOffer(state: LivingAllocationState) {
    state.isDiscontinuingOneOffer = false;
  },
  startMakingOffer(state: LivingAllocationState) {
    state.isMakingOffer = true;
  },
  stopMakingOffer(state: LivingAllocationState) {
    state.isMakingOffer = false;
  },
  startRespondingOffer(state: LivingAllocationState) {
    state.isRespondingOffer = true;
  },
  stopRespondingOffer(state: LivingAllocationState) {
    state.isRespondingOffer = false;
  },
  startDecliningMultiple(state: LivingAllocationState) {
    state.isDecliningMultiple = true;
  },
  stopDecliningMultiple(state: LivingAllocationState) {
    state.isDecliningMultiple = false;
  },
  setDonorDetails(state: LivingAllocationState, allocation: LivingDonorDetails) {
    state.donorDetails = allocation;
  },
  clearDonorDetails(state: LivingAllocationState) {
    state.donorDetails = undefined;
  },
  setRecipientDetails(state: LivingAllocationState, recipientDetails: LivingRecipientDetails) {
    state.recipientDetails = recipientDetails;
  },
  clearRecipientDetails(state: LivingAllocationState) {
    state.recipientDetails = undefined;
  },
  setChecklist(state: LivingAllocationState, checklist: LivingAllocationChecklistForm) {
    state.checklist = checklist;
  },
  setChecklistDetails(state: LivingAllocationState, checklist: LivingAllocationChecklistResponse) {
    state.checklistDetails = checklist;
  },
  setChecklistHistory(state: LivingAllocationState, checklist: LivingAllocationChecklistResponse[]) {
    state.checklistHistory = checklist;
  }
};
