import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_3 = {
  key: 1,
  class: "legend-title"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "standard-form-group-6column-xlarge-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_boolean_radio_input = _resolveComponent("boolean-radio-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    "sub-section-id": "policy-final-decisions",
    tabbableValue: _ctx.$t('final_decision'),
    "total-records": _ctx.getFinalDecisions.length,
    "table-config": _ctx.finalDecisionTableConfig,
    onTableRowClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectFinalDecision($event))),
    onTableCreateRow: _cache[6] || (_cache[6] = ($event: any) => (_ctx.createFinalDecision())),
    saveButton: _ctx.canSave,
    disabled: !_ctx.selectedDecisionId && (_ctx.canSave || _ctx.newJourney),
    "save-button-text": _ctx.$t('save_final_decision'),
    ref: "saveFinalDecisionsSection",
    onSave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.saveFinalDecision())),
    title: _ctx.$t('final_decisions')
  }, {
    contents: _withCtx(() => [
      (_ctx.editState)
        ? (_openBlock(), _createElementBlock("fieldset", {
            key: 0,
            disabled: !_ctx.canSave
          }, [
            _createElementVNode("legend", null, [
              (!_ctx.editState._id)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('new_final_decision')), 1))
                : (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.$t('selected_final_decision')), 1))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_select_input, {
                  selectId: "final_decision_type",
                  ruleKey: "decision_type",
                  name: _ctx.$t('final_decision'),
                  options: _ctx.decisionFinalDecisionTypes,
                  modelValue: _ctx.editState.decision_type,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.decision_type) = $event))
                }, null, 8, ["name", "options", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_date_input, {
                  "input-id": "final_decision_date",
                  ruleKey: "decision_datetime",
                  name: _ctx.$t('date_of_final_decision'),
                  modelValue: _ctx.editState.decision_date,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.decision_date) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_time_input, {
                  "input-id": "final_decision_time",
                  name: _ctx.$t('time_of_final_decision'),
                  rules: "required",
                  modelValue: _ctx.editState.decision_time,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.decision_time) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_boolean_radio_input, {
                  "input-id": "final_medical_director_consulted",
                  ruleKey: "medical_director_consulted",
                  labelName: _ctx.$t('medical_director_consulted'),
                  acceptId: true,
                  declineId: false,
                  acceptLabel: _ctx.$t('yes'),
                  declineLabel: _ctx.$t('no'),
                  modelValue: _ctx.editState.medical_director_consulted,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.medical_director_consulted) = $event))
                }, null, 8, ["labelName", "acceptLabel", "declineLabel", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_text_area_input, {
                  inputId: "final_comments",
                  name: _ctx.$t('comments'),
                  ruleKey: "comments",
                  modelValue: _ctx.editState.comments,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.comments) = $event))
                }, null, 8, ["name", "modelValue"])
              ])
            ])
          ], 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tabbableValue", "total-records", "table-config", "saveButton", "disabled", "save-button-text", "title"]))
}