import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu-header-nav",
  "aria-label": "Toolbar"
}
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "nav-item" }
const _hoisted_4 = ["title", "aria-label"]
const _hoisted_5 = { class: "sr-only" }
const _hoisted_6 = { class: "nav-item toggle-link" }
const _hoisted_7 = ["title", "aria-label"]
const _hoisted_8 = { class: "far fa-w fa-bars" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", _hoisted_2, [
      _renderSlot(_ctx.$slots, "return-link")
    ]),
    _createElementVNode("li", _hoisted_3, [
      _createElementVNode("a", {
        class: "nav-link",
        href: "#top",
        title: _ctx.$t('return_to_top'),
        "aria-label": _ctx.$t('control_returns_to_top')
      }, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'arrow-up'],
          "fixed-width": ""
        }),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('return_to_top')), 1)
      ], 8, _hoisted_4)
    ]),
    _createElementVNode("li", _hoisted_6, [
      _createElementVNode("a", {
        class: "nav-link",
        href: "#",
        title: _ctx.$t('toggle_menu_visibility'),
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)), ["prevent"])),
        "aria-label": _ctx.$t('control_toggles_visibility')
      }, [
        _createElementVNode("span", _hoisted_8, [
          _createVNode(_component_font_awesome_icon, { icon: ['far', 'bars'] })
        ])
      ], 8, _hoisted_7)
    ])
  ]))
}