<template>
  <base-widget :title="$t('notifications')" :preferences="preferences.notifications" v-if="editState">
    <template v-slot:icon>
      <div class="circle dashboard-icon">
        <font-awesome-icon :icon="['fas', 'rocket']" />
      </div>
    </template>
    <template v-slot:linkID>
      <a href="#idNotifications" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'cog']" data-toggle="tooltip" :title="$t('customize_panel')" />
      </a>
      <a href="#bodyNotifications" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'caret-down']" data-toggle="tooltip" :title="$t('collapse_panel')" />
      </a>
    </template>
    <template v-slot:options>
      <div class="card-body card-options p-0 collapse" id="idNotifications">
        <div class="customize-panel">
          <h4>{{$t('panel_options')}}</h4>
          <!-- Widget options-->
          <slot name="options">
            <form>
              <div class="form-row mb-3">
                <div class="col-sm-6">
                  <select-input
                    name="panel-style"
                    select-id='dashNotificationStyle'
                    v-model="editState.style"
                    :label="$t('panel_style')"
                    :options="getPanelStyles"
                    />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="form-row mb-3">
                <div class="col-sm-12 col-lg-8">
                  <checkbox-input
                    input-id='dashNotificationVisible'
                    v-model="editState.visible"
                    :label="$t('show_this_panel')"
                    :disabled="requiredWidget"
                  />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="form-row mb-3">
                <div class="col-sm-12 col-lg-8">
                  <checkbox-input
                    input-id='dashNotificationunread'
                    v-model="editState.unread"
                    :label="$t('show_only_unread_notifications')"
                  />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="col-sm-12" v-if="errorMessage">
                <p>{{errorMessage}}</p>
              </div>
              <button
                type="button"
                class="btn btn-sm btn-wide btn-success"
                data-target="#idNotifications"
                @click="saveSettings()"
              >
                {{$t('save')}}
              </button>
            </form>
          </slot>
        </div>
      </div>
    </template>
    <template v-slot:widget-contents>
      <div class="card-body collapse show" id="bodyNotifications">
        <!-- TODO: Vue3_Upgrade: tables -->
      </div>
    </template>
    <template v-slot:widget-modal-link>
      <a href="#" data-toggle="modal" data-target="#notificationHistoryModal">{{$t('view_notifications_history')}}</a>
    </template>
    <!-- Temp generic history modal -->
    <template v-slot:widget-modal-body>
      <div class="modal fade" id="notificationHistoryModal"  tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{$t('notification_history')}}</h5>
              <button type="button" class="close" data-dismiss="modal" :aria-label="$t('close')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- TODO: Vue3_Upgrade: tables -->
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('close')}}</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-widget>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="@/components/dashboard/widgets/_locales/WidgetNotifications.json"></i18n>

<script lang="ts">
import store from '@/store';
import { Getter, State } from 'vuex-facing-decorator';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardState, DashboardSettingState, NotificationsWidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Format } from '@/store/utilities/types';

@Component({
  components: {
    BaseWidget,
    TextInput,
    CheckboxInput,
    SelectInput
  }
})
export default class WidgetNotifications extends Vue {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashNotifications) private editState!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getNotifications', { namespace: 'dashboard' }) getNotifications!: any[];
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];
  @Getter('getDateFormat', { namespace: 'configuration' }) private getDateFormat!: string;

  public errorMessage = null;

  get getFilteredNotifications(): any {
    const unread = this.getPreferences ? this.getPreferences.unread : true;
    const notifications = this.getNotifications || [];
    return notifications.filter((item: any) => {
      return unread ? item.unread == true : item;
    });
  }

  rowStyleForReadUnread(row: any): any {
    return row.unread ? 'tr-link row-unread' : 'tr-link';
  }

  mounted(): void {
    Promise.all([
      this.$store.dispatch('dashboard/loadNotifications')
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  // Setup temporary edit state for unsaved widget preferences form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;
    this.$store.commit('pageState/set', {
      pageKey: 'dashNotifications',
      value: this.buildNotificationsEditState(preferences),
    });
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#idNotifications").collapse('toggle');
  }

  // Build edit state based on overall dashboard preferences
  buildNotificationsEditState(preferences: DashboardSettingState): NotificationsWidgetSettings {
    return Object.assign({}, preferences.notifications);
  }

  // Build new overall dashboard preferences with new preferences for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: NotificationsWidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ notifications: {} }, currentSettings);
    newSettings.notifications = {
      style: editState.style,
      visible: editState.visible,
      unread: editState.unread
    };
    return newSettings;
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.getFilteredNotifications,
      columns: [
        { label: this.$t('date_time_of_event_trigger'), field: 'date_time', sortable: true },
        { label: this.$t('description_of_notification'), field: 'description', sortable: false },
      ],
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'date_time', type: 'desc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: true,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfigHistory(): TableConfig {
    return {
      data: this.getNotifications,
      columns: [
        { label: this.$t('date_time_of_event_trigger'), field: 'date_time', sortable: true, type: 'date', dateInputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT, dateOutputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT },
        { label: this.$t('description_of_notification'), field: 'description', sortable: false },
      ],
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'date_time', type: 'desc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }
}
</script>
