<template v-if="isDonorAcceptabilityEnabled && !newJourney">
  <card-section
    section-id="donor-acceptability-criteria"
    :lookupsToLoad="lookupsToLoad"
    @loaded="loaded()"
    >
    <template v-slot:header>
      {{ $t('donor_acceptability_criteria') }}
    </template>
    <template v-slot:body>
      <sub-section
        :title="$t('donor_acceptability_criteria')"
        sub-section-id="sub_donor_acceptability_criteria"
      >
        <template v-slot:contents>
          <!-- contents -->
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Component, Vue, Prop } from "vue-facing-decorator";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { i18nMessages } from "@/i18n";

@Component({
  components: {
    CardSection,
    SubSection,
  },
  ...i18nMessages([
    require('./_locales/common.json'),
    require('./_locales/DonorAcceptabilityDetails.json'),
  ]),
  emits: [
    'loaded',
  ],
})
export default class DonorAcceptabilityDetails extends Vue {
  // Getters
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('isDonorAcceptabilityEnabled', { namespace: 'configuration' }) private isDonorAcceptabilityEnabled!: boolean;

  // Properties
  @Prop({ default: false }) newJourney!: boolean;

  private isLoading = true;

  private lookupsToLoad = [];

  public get canSave(): boolean {
    return this.isGroupWriteable('journey_medical');
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens TransplantDetails#loaded
   * @emits loaded
   */
   public loaded(): void {
    this.$emit('loaded', 'donor_acceptability_criteria');
  }
}
</script>
