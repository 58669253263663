import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { ConfigurationState, FeaturesConfig, ObjectWithLocation } from '@/store/configuration/types';

/**
 * Check what value an arbitrarily nested feature object has for the specified property
 *
 * @param {FeaturesConfig} featuresConfig features config state to check
 * @param {string} featurePath dot-separated path to feature object
 * @param {string} propertyKey property key, e.g. "enabled"
 * @returns value defined for the property in the feature object
 */
function getFeatureProperty(featuresConfig: FeaturesConfig, featurePath: string, propertyKey: string) {
  if (!featuresConfig) return null;

  const keys = featurePath.split('.');
  if (keys.length === 0) return false;

  let featureConfig: any = featuresConfig;
  keys.forEach((key: string) => {
    if (featureConfig == null) return;

    const keyTyped = key as keyof typeof featureConfig;
    const value = featureConfig[keyTyped];
    featureConfig = value;
  });
  if (!featureConfig || !featureConfig[propertyKey]) return null;

  return featureConfig[propertyKey];
}

export const getters: GetterTree<ConfigurationState, RootState> = {
  /**
   * Checks for bad configuration, if incorrectly setup return false
   * 
   * @returns boolean
   */
  sanityCheck(state: ConfigurationState): boolean {
    if (!state.configuration || !state.configuration.app) return false;

    let result = true;
    // check configuration, if fail, set return to false
    if (!state.configuration.app.localization.date_format) result = false;
    if (!state.configuration.app.base_class) result = false;
    if (!state.configuration.app.time_zone) result = false;
    if (!state.configuration.app.localization.default_locale) result = false;
    return result;
  },

  /**
   * check if the configuration has been loaded
   *
   * @returns {boolean} true only if configurations module state has non-null 'configuration' information
   */
  isConfigurationLoaded(state: ConfigurationState): boolean {
    return !!state.configuration;
  },

  /**
   * returns true if IPOS Heart enabled
   *
   * @returns {boolean}
   */
  ctrIposHeart(state: ConfigurationState): boolean {
    // TODO: Configuration
    return true;
  },

  /**
   * returns true if user select list enabled
   *
   * @returns {boolean}
   */
  showUserSelect(state: ConfigurationState): boolean {
    if (!state.configuration || !state.configuration.app) return false;
    return !!state.configuration.app.enable_user_select;
  },

  /**
   * returns true if oneid enabled
   *
   * @returns {boolean}
   */
  oneid(state: ConfigurationState): boolean {
    // TODO: Configuration
    return false;
  },

  /**
   * returns system timezone
   *
   * @returns {string|undefined}
   */
  systemTimeZone(state: ConfigurationState): string|undefined {
    if (!state.configuration || !state.configuration.app) return undefined;
    return state.configuration.app.time_zone;
  },

  /**
   * returns true if i18n enabled
   *
   * @returns {boolean}
   */
  i18nEnabled(state: ConfigurationState): boolean {
    if (!state.configuration || !state.configuration.app) return false;
    return !!state.configuration.app.localization.enable_i18n;
  },

  /**
   * returns default locale
   *
   * @returns {string|undefined}
   */
  getDefaultLocale(state: ConfigurationState): string|undefined {
    if (!state.configuration || !state.configuration.app) return undefined;
    return state.configuration.app.localization.default_locale;
  },

  /**
   * returns available locales
   *
   * @returns {string[]}
   */
  getAvailableLocales(state: ConfigurationState): string[] {
    if (!state.configuration || !state.configuration.app) return [];
    if (!state.configuration || !state.configuration.app.localization.enable_i18n) return [state.configuration.app.localization.default_locale];
    return state.configuration.app.localization.enabled_locales;
  },

  /**
   * returns date format
   *
   * @returns {string|undefined}
   */
  getDateFormat(state: ConfigurationState): string|undefined {
    if (!state.configuration || !state.configuration.app) return undefined;
    return state.configuration.app.localization.date_format;
  },

  /**
   * returns help url
   *
   * @returns {string|undefined}
   */
  getHelpUrl(state: ConfigurationState): string|undefined {
    if (!state.configuration || !state.configuration.app) return undefined;
    return state.configuration.app.help_url;
  },

  /**
   * returns application name prefix
   *
   * @returns {string|undefined}
   */
  applicationNamePrefix(state: ConfigurationState): string|undefined {
    if (!state.configuration || !state.configuration.app) return undefined;
    return state.configuration.app.application_name_prefix;
  },

  /**
   * returns true if view_allocation_audit_messages enabled
   *
   * @returns {boolean}
   */
  viewAllocationAuditMessages(state: ConfigurationState): boolean {
    // TODO: Configuration
    return true;
  },

  /**
   * returns array of regions
   *
   * @returns {string[]}
   */
  getRegions(state: ConfigurationState): string[] {
    if (!state.configuration || !state.configuration.app) return [];
    return state.configuration.app.localization.regions;
  },

  /**
   * returns logo as base64 string
   *
   * @returns {string|null}
   */
  getLogo(state: ConfigurationState): string|null {
    if (!state.configuration || !state.configuration.app) return null;
    return state.configuration.app.logo;
  },

  /**
   * returns css base class
   *
   * @returns {string|undefined}
   */
  getAppBaseClass(state: ConfigurationState): string|undefined {
    if (!state.configuration || !state.configuration.app) return undefined;
    return state.configuration.app.base_class;
  },

  /**
   * returns true if fusion auth bypassed
   *
   * @returns {boolean}
   */
  bypassFusionAuth(state: ConfigurationState,): boolean {
    if (!state.configuration || !state.configuration.app) return false;
    return state.configuration.app.bypass_fusionauth;
  },

  /**
   * returns login provider name
   *
   * @returns {string|null}
   */
  getLoginProviderName(state: ConfigurationState): string|null {
    if (!state.configuration || !state.configuration.app) return null;
    return state.configuration.app.login_provider;
  },

  /**
   * Is the configuration feature turned on?
   * NOTE: returns 'enabled' flag from nested feature config based on dot-separated path string
   *
   * @returns {boolean}
   */
  featureEnabled(state: ConfigurationState) {
    return (feature: string): boolean => {
      if (!state.configuration || !state.configuration.features) return false;
  
      const result = getFeatureProperty(state.configuration.features, feature, 'enabled') || false;
      return result;
    };
  },

  /**
  * Does the configuration feature have extended details turned on?
  * NOTE: returns 'extended_details' flag from nested feature config based on dot-separated path string
  *
  * @returns {string}
  * @returns {boolean}
  */
  extendedDetails(state: ConfigurationState) {
    return (feature: string): boolean => {
      if (!state.configuration || !state.configuration.features) return false;

      return getFeatureProperty(state.configuration.features, feature, 'extended_details');
    };
  },

  /**
   * Check if Recipient Clinical Transactions (a.k.a. Call Date/Time) card sections are enabled
   *
   * @returns {boolean} true only if Recipient Clinical Transactions (a.k.a. Call Date/Time) card sections are enabled
   */
  isClinicalTransactionEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient.clinical_transactions');
  },

   /**
   * Check if Recipient Documents card sections are enabled
   *
   * @returns {boolean} true only if Recipient Documents card sections are enabled
   */
   isRecipientDocumentEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient.recipient_documents');
  },

  /**
   * Check if Urgent Recipient field is enabled
   *
   * @returns {boolean} true only if Urgent Recipient field is enabled
   */
  isUrgentRecipientEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient.demographics.registration_info.urgent_recipient');
  },

  /**
   * Check if Recipient Clinical Transactions (a.k.a. Call Date/Time) card sections are enabled
   *
   * @returns {boolean} true only if Recipient Clinical Transactions (a.k.a. Call Date/Time) card sections are enabled
   */
  isPersonalInfoEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient.demographics.personal_info');
  },

  /**
   * Check if Recipient Financial Assistance (a.k.a. TPER) sub-section is enabled
   *
   * @returns {boolean} true only if Recipient Financial Assistance (a.k.a. TPER) sub-section is enabled
   */
  isFinancialAssistanceEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient.demographics.financial_assistance');
  },

  /**
   * Check if Recipient Contact Info card section is enabled
   *
   * @returns {boolean} true only if Recipient Contact Info card section is enabled
   */
  isContactInfoEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient.contact_info');
  },

  /**
   * Check if Patient Address list sub-section is enabled
   *
   * @returns {boolean} true only if Patient Address list sub-section is enabled
   */
  isPatientAddressEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient.contact_info.patient_addresses');
  },

  /**
   * Check if Patient Contact Details list sub-section is enabled
   *
   * @returns {boolean} true only if Patient Contact Details list sub-section is enabled
   */
  isPatientContactDetailsEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient.contact_info.patient_contact_details');
  },

  /**
   * Check if Recipient Create Includes Journey functionality is enabled
   *
   * @returns {boolean} true only if Recipient Create Includes Journey functionality is enabled
   */
  isRecipientCreateIncludeJourneyEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient.create_includes_journey');
  },

  /**
   * Check if Recipient Previous Out-of-Province Transplants sub-section is enabled
   *
   * @returns {boolean} true only if Recipient Previous Out-of-Province Transplants sub-section is enabled
   */
  isOopJourneysEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient.oop_journeys');
  },

  /**
   * Check if Urgent Listing field is enabled
   *
   * @returns {boolean} true only if Urgent Listing field is enabled
   */
  isUrgentListingEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.journey_overview.journey_details.urgent_listing');
  },

  /**
   * Check if Referred With field is enabled
   *
   * @returns {boolean} true only if Referred With field is enabled
   */
  isReferredWithEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.journey_overview.journey_details.referred_with');
  },

  /**
   * Check if Recipient Journey Workflow Step field is enabled
   *
   * @returns {boolean} true only if Recipient Journey Workflow Step field is enabled
   */
  isWorkflowStepEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.journey_overview.phase_status.workflow_step');
  },

  /**
   * Check if Referral Details section is enabled
   *
   * @returns {boolean} true only if Referral Details section is enabled
   */
  isReferralDetailsEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.referral_details');
  },

  /**
   * Check if Donor Acceptability Criteria section is enabled
   *
   * @returns {boolean} true only if Donor Acceptability Criteria section is enabled
   */
  isDonorAcceptabilityEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.donor_acceptability');
  },

  /**
   * Check if Recipient Journey Consultation Decision card section is enabled
   *
   * @returns {boolean} true only if Recipient Journey Consultation Decision card section is enabled
   */
  isConsultationSectionEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.consultation_section');
  },

  /**
   * Check if Recipient Journey Medical Assessment Decision card section is enabled
   *
   * @returns {boolean} true only if Recipient Journey Medical Assessment Decision card section is enabled
   */
  isMedicalAssessmentSectionEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.medical_assessment_section');
  },

  /**
   * Check if Waitlist Active Transfer sub-section is enabled
   *
   * @returns {boolean} true only if Waitlist Active Transfer sub-section is enabled
   */
  isWaitlistActiveTransferEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.manage_waitlist.transfer_active');
  },

  /**
   * Check if Post-Transplant Follow-Up Transfer sub-section is enabled
   *
   * @returns {boolean} true only if Post-Transplant Follow-Up Transfer sub-section is enabled
   */
  isPostTransplantTransferEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.post_transplant_follow_up.transfer_post_transplant');
  },

  /**
   * Check if Liver PELD results sub-section is enabled
   *
   * @returns {boolean} true only if Liver PELD results sub-section is enabled
   */
  isLiverPeldEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.liver_specific_details.lab_liver_peld');
  },

  /**
   * Check if Liver HCC Criteria determination sub-section is enabled
   *
   * @returns {boolean} true only if Liver HCC Criteria determination sub-section is enabled
   */
  isLiverHccCriteriaEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.liver_specific_details.hcc_criteria');
  },

  /**
   * Check if HCC lab results data sub-section is enabled
   *
   * @returns {boolean} true only if HCC lab results data sub-section is enabled
   */
  isLiverHccDataEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.liver_specific_details.hcc_data');
  },

  /**
   * Check if Liver Bridging Therapy sub-section is enabled
   *
   * @returns {boolean} true only if Liver Bridging Therapy sub-section is enabled
   */
  isLiverBridgingTherapyEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.liver_specific_details.ald_program_participation');
  },

  /**
   * Check if Liver Downstaging Therapy sub-section is enabled
   *
   * @returns {boolean} true only if Liver Downstaging Therapy sub-section is enabled
   */
  isLiverDownstagingTherapyEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.liver_specific_details.bridging_therapy');
  },

  /**
   * Check if ALD Program Participation sub-section is enabled
   *
   * @returns {boolean} true only if ALD Program Participation sub-section is enabled
   */
  isLiverAldProgramParticipationEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.liver_specific_details.downstaging_therapy');
  },

  /**
   * Check if Liver Exception Diseases sub-section is enabled
   *
   * @returns {boolean} true only if Liver Exception Diseases sub-section is enabled
   */
  isLiverExceptionDiseases(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.liver_specific_details.exception_diseases');
  },

  /**
   * Check if Heart Measurements sub-section is enabled
   *
   * @returns {boolean} true only if Heart Measurements sub-section is enabled
   */
  isHeartMeasurementsEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.heart_specific_details.measurements_heart');
  },

  /**
   * Check if Ventricular Assist Device sub-section sub-section is enabled
   *
   * @returns {boolean} true only if Ventricular Assist Device sub-section sub-section is enabled
   */
  isHeartVadProcedureEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.heart_specific_details.procedure_vad');
  },

  /**
   * Check if Heart Mechanical Circulatory Support sub-section is enabled
   *
   * @returns {boolean} true only if Heart Mechanical Circulatory Support sub-section is enabled
   */
  isHeartMechanicalCirculatorySupportEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.heart_specific_details.mechanical_circulatory_support');
  },

  /**
   * Check if Heart Hemodynamic sub-section is enabled
   *
   * @returns {boolean} true only if Heart Hemodynamic sub-section is enabled
   */
  isHeartHemodynamicEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.heart_specific_details.measurements_heart_hemodynamic');
  },

  /**
   * Check if Heart Thoracic Measurements sub-section is enabled
   *
   * @returns {boolean} true only if Heart Thoracic Measurements sub-section is enabled
   */
  isHeartThoracicMeasurementsEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.heart_specific_details.thoracic_measurements');
  },

  /**
   * Check if Kidney Specific Details card section has 'extended details'
   *
   * @returns {boolean} true only if Kidney Specific Details card section has extended details
   */
  isKidneySpecificDetailsExtendedDetails(state: ConfigurationState, getters) {
    return getters.extendedDetails('recipient_journey.kidney_specific_details');
  },

  /**
   * Check if Kidney Dialysis Procedures sub-section has 'extended details'
   *
   * @returns {boolean} true only if Kidney Dialysis Procedures sub-section has extended details
   */
  isKidneyDialysisExtendedDetails(state: ConfigurationState, getters) {
    return getters.extendedDetails('recipient_journey.kidney_specific_details.dialysis_events');
  },

  /**
   * Check if Kidney Override Paediatric Age Determination sub-section is enabled
   *
   * @returns {boolean} true only if Kidney Override Paediatric Age Determination sub-section is enabled
   */
  isKidneyPaediatricAgeEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.kidney_specific_details.paediatric_age_override');
  },

  /**
   * Check if Lung Thoracic Measurements sub-section is enabled
   *
   * @returns {boolean} true only if Lung Thoracic Measurements sub-section is enabled
   */
  isLungThoracicMeasurementsEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.lung_specific_details.thoracic_measurements');
  },

  /**
   * Check if Recipient Journey Decisions (a.k.a. Policy Exemptions) page is enabled
   *
   * @returns {boolean} true only if Recipient Journey Decisions (a.k.a. Policy Exemptions) page is enabled
   */
  isPolicyExemptionsEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.lung_specific_details.thoracic_measurements');
  },

  /**
   * Check if Clinical Notes section is enabled
   *
   * @returns {boolean} true only if Clinical Notes section is enabled
   */
  isClinicalNotesEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.clinical_notes');
  },

  /**
   * Check if Checklists section is enabled
   *
   * @returns {boolean} true only if Checklists section is enabled
   */
  isChecklistsEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.checklists');
  },

  /**
   * Check if Transplant Details section is enabled
   *
   * @returns {boolean} true only if Transplant Details section is enabled
   */
  isTransplantDetailsEnabled(state: ConfigurationState, getters) {
    return getters.featureEnabled('recipient_journey.transplant');
  },

  /**
   * returns true if region includes province code
   *
   * @returns {boolean}
   */
  regionIncludesProvinceCode(state: ConfigurationState, getters) {
    return (object: ObjectWithLocation): boolean => {
      const regions = getters.getRegions;
      const province_code = object.location?.province_code || null;
      return province_code ? regions.includes(province_code) : false;
    };
  },
};
