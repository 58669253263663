<template>
  <div>
    <page-top>
      {{$t('donors')}}
    </page-top>
    <template v-if="!donors">
      <div class="content-wrap">
        <div class="container-fluid">
          <loading-list-view />
        </div>
      </div>
    </template>
    <full-page-list-layout v-else>
      <template v-slot:content>
        <nav class="nav list-patients action-row">
          <column-config
            columnKey="donorListColumns"
            :optionDefaults="getColumnDefaults"
            config-id="donor-list-columns"
            :options="donorColumns"
            v-model="columnsFilter.selectedColumns"
          />
        </nav>
        <template>
          <p>{{ $t("system_returned")}} <b>{{donors.count}}</b> {{$t('donors')}}</p>
          <div class="highlight-scroll" v-if="isOverflowed">
            {{ $t('scroll_right_to_see_more') }}
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="nav-caret" fixed-width />
          </div>
          <sub-section
            sub-section-id="list-donors"
            title=""
            mode="remote"
            :tabbableValue="$t('deceased_donor_id')"
            :total-records="donors.count"
            :lookupsToLoad="lookupsToLoad"
            :isParentLoading="isLoading"
            :table-config="donorTableConfig()"
            @on-page-change="updatePagination($event)"
            @on-per-page-change="updatePagination($event)"
            @on-column-filter="filterList($event)"
            @on-sort-change="filterList($event)"
            @table-row-click="selectDonor($event)">
          </sub-section>
        </template>
      </template>
    </full-page-list-layout>
  </div>
</template>

<i18n src="../../_locales/deceasedDonors/deceasedDonorsListTableColumns.json"></i18n>
<i18n src="../_locales/common.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { State, Getter} from 'vuex-facing-decorator';
import { TableConfig } from '@/types';
import PageTop from '@/components/shared/PageTop.vue';
import ColumnConfig from '@/components/shared/ColumnConfig.vue';
import { DeceasedDonorOrgan, ListDonor, StateDonorList } from '@/store/deceasedDonors/types';
import { Component, Vue } from 'vue-facing-decorator';
import SubSection from '@/components/shared/SubSection.vue';
import { Sex, CauseOfDeathDonor, RhIndicator, BloodType, OrganCodeValue, Organ } from "@/store/lookups/types";
import { urlParams, titleCase, isMasked, uniqueElements } from '@/utils';
import LoadingListView from '@/components/shared/LoadingListView.vue';
import { Format } from '@/store/utilities/types';
import FullPageListLayout from "@/components/layouts/FullPageListLayout.vue";
import { GenericCodeValue } from "@/store/types";

interface ColumnsFilterState {
  selectedColumns: string[];
}


// interface for mapped table rows shown in UI
interface DeceasedDonorActiveListingRow {
  deceased_donor_id: string;
  client_id: string;
  hospital_name: string;
  national_id: string;
  first_name: string;
  last_name: string;
  age: string;
  sex: string;
  cause_of_death: string;
  donor_type: string;
  referral_type: string;
  organ_code: string;
  ecd: string;
  referral_date: string;
  blood_type: string;
  rh: string;
  mrn: string;
  weight: string;
  height: string;
}

@Component({
  components: {
    PageTop,
    SubSection,
    ColumnConfig,
    LoadingListView,
    FullPageListLayout
  }
})

export default class ListDeceasedDonorsActive extends mixins(DateUtilsMixin) {
  @State(state => state.deceasedDonors.donorsList) donors!: StateDonorList;
  @State(state => state.pageState.currentPage.columnsFilter) columnsFilter!: ColumnsFilterState;
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.lookups.blood_type) bloodTypeLookup!: BloodType[];
  @State(state => state.lookups.rh_indicator) private rhIndicatorLookup!: RhIndicator[];

  // getters
  @Getter('sexOptions', { namespace: 'lookups' }) sexOptions!: Sex[];
  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string|undefined;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('getColumnPreferences', { namespace: 'users'}) getColumnPreferences!: (columnKey: string, optionDefaults: string[]) => string[];
  @Getter('causeOfDeathDonor', { namespace: 'lookups' }) causeOfDeathDonor!: CauseOfDeathDonor[];
  @Getter('organOptions', { namespace: 'lookups' }) organOptions!: (type?: string) => { code: number; value: string }[];

  get canCreate(): boolean {
    return this.checkAllowed('/donors', 'POST');
  }

  public currentPage = 1;
  public perPage = 25;
  public isOverflowed = false;
  public isLoading = false;

  private lookupsToLoad = [
    'cause_of_death_donor',
    'blood_type',
    'rh_indicator',
    'sex'
  ];

  public checkwidth() {
    if (window.innerWidth < 1500) {
      this.isOverflowed = true;
    } else {
       this.isOverflowed = false;
    }
  }

  // Vue lifecycle hooks
  public mounted(): void {
    this.$store.commit('setPageTitle', `Donors`);
    this.loadData();
    this.initializePageState();
    this.checkwidth();
  }

  private initializePageState(): void {
    // Initialize filter form state
    this.$store.commit('pageState/set', {
      pageKey: 'columnsFilter',
      value: this.buildColumnsFilter(),
    });
  }

  get getColumnDefaults(): string[] {
    return [
      'deceased_donor_id',
      'client_id',
      'hospital_name',
      'national_id',
      'first_name',
      'last_name',
      'age',
      'sex',
      'cause_of_death',
      'donor_type',
      'organ_code',
      'ecd',
      'referral_type',
      'referral_date'
    ];
  }

  private buildColumnsFilter(): ColumnsFilterState {
    // get selected columns from user preferences or defaults
    const columns = this.getColumnPreferences('donorListColumns', this.getColumnDefaults);

    return {
      selectedColumns: columns,
    };
  }

  public loadData(search='', sort='') {
    this.isLoading = true;
    const search_params = [search, sort].filter((p) => { return p && p.length >= 0; });

    this.$store.dispatch(
      'deceasedDonors/getListActive', {
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`
      }
    ).then(() => {
      setTimeout(() => {
        this.isLoading = false;
      }, 600);
    });
  }

  get sexOptionsDropdownValues(): GenericCodeValue[] {
    return this.sexOptions.map((option: Sex): GenericCodeValue => {
      return {
        value: option.value,
        code: option.code
      };
    });
  }

  get bloodTypeOptionsDropdownValues(): any[] {
    if(!this.bloodTypeLookup) {
      return [];
    }

    return this.bloodTypeLookup.filter((item: any) => !item.disabled).map((option: any) => {
      return {
        value: option.value,
        code: option.code
      };
    });
  }

  get rhIndicatorOptionsDropdownValues(): any[] {
    if(!this.rhIndicatorLookup) {
      return [];
    }

    return this.rhIndicatorLookup.filter((item: any) => !item.disabled).map((option: any) => {
      return {
        value: option.value,
        code: option.code
      };
    });
  }

  get donorTypeOptions(): any[] {
    return [
      {
        value: 'NDD',
        code: 'NDD'
      },
      {
        value: 'DCD',
        code: 'DCD'
      }
    ];
  }

  get ecdOptions(): any[] {
    return [
      {
        value: 'Y',
        code: 'true'
      },
      {
        value: 'N',
        code: 'false'
      }
    ];
  }

  get referralTypeOptions(): any[] {
    return [
      {
        value: this.$t('out_of_province'),
        code: 'out_of_province'
      },
      {
        value: this.$t('tissue_donor'),
        code: 'tissue_donor'
      },
      {
        value: this.$t('organ_donor'),
        code: 'organ_donor'
      },
      {
        value: this.$t('unknown_donor'),
        code: 'unknown_donor'
      }
    ];
  }

  // Build options list for organs filter
  get organFilterOptions(): { code: number; value: string }[] {
    return this.organOptions('single');
  }

  /**
   * gets the cause of death dropdown options
   *
   */
  get causeOfDeathDonorOptions() {
    if (!this.causeOfDeathDonor) return [];

    return this.causeOfDeathDonor.filter((item: any) => !item.disabled ).map((cause) =>{
      return {
        value: cause.value,
        code: cause.value
      };
    });
  }

  /**
   * Get string array of Donor's Referral Type values
   *
   * Note: returns one masked item if user doesn't have access to these fields
   *
   * @param donor the donor's entry from an index response
   * @returns {string[]} array of strings with applicable types
   */
  private referralType(donor: ListDonor): string[] {
    const referral_type = [];

    if(donor.out_of_province && !isMasked(donor.out_of_province)) {
      referral_type.push('Out of Province');
    }
    if(donor.tissue_donor && !isMasked(donor.tissue_donor)){
      referral_type.push('Tissue Donor');
    }
    if(donor.organ_donor && !isMasked(donor.organ_donor)){
      referral_type.push('Organ Donor');
    }
    if(donor.unknown_donor_type && !isMasked(donor.unknown_donor_type)){
      referral_type.push('Unknown Donor');
    }

    // show masked value if masked
    if (referral_type.length === 0 && isMasked(donor.organ_donor) ) return [`${donor.organ_donor}`];

    return referral_type;
  }

  get filteredColumns(): any[] {
    if (!this.columnsFilter || !this.columnsFilter.selectedColumns) {
      return this.donorColumns;
    }
    const selectedFields = this.columnsFilter.selectedColumns;
    const selectedColumns = this.donorColumns.filter((column: any) => {
      return selectedFields.includes(column.field);
    });
    return selectedColumns;
  }

  /**
   * Get string representation of a Donor Type: NDD or DCD
   *
   * Note: returns unparsed input if it is masked instead of boolean
   *
   * @param neurologicalDeath boolean flag from donor's donor_type
   * @returns {string} string description of donor type
   */
  private donorTypeFromNeurologicalDeath(neurologicalDeath: boolean|string|null): string {
    if (neurologicalDeath === null) return '-';
    if (isMasked(neurologicalDeath)) return `${neurologicalDeath}`;

    return !!neurologicalDeath ? 'NDD' : 'DCD';
  }

  /**
   * Get list of organ names based on a donor's consented organ data
   *
   * Note: returns an array with masked item at least one organ consent is masked
   *
   * @param n numeric field
   * @returns {string} rounded number as a string
   */
  private organConsentsDisplay(organ_consents: DeceasedDonorOrgan[]): string[] {
    if (!organ_consents) return ['-'];

    // Only show organs with consented: true
    const organsConsented = organ_consents.filter((organ: DeceasedDonorOrgan) => organ.consented);

    // Map organ codes to organ names
    const result = organsConsented.map((organ: DeceasedDonorOrgan) => {
      if (isMasked(organ.organ_code)) return `${organ.organ_code}`;

      const name: string|undefined = this.organName(organ.organ_code) || undefined;
      return name ? this.$t(name) : 'Unknown';
    });

    return uniqueElements(result);
  }

  /**
   * Parse numeric field to exactly two decimal places
   *
   * Note: returns unparsed input if it is masked instead of number
   *
   * @param n numeric field
   * @returns {string} rounded number as a string
   */
  private parseMaskedNumber(n: string|number): string {
    if (isMasked(n)) return `${n}`;

    const sanitized = Number(n);
    if (isNaN(sanitized)) return '-';

    return sanitized.toFixed(2);
  }

  /**
   * Handle ecd_donor flag, should be visible for all organ organs
   *
   * @param donor ListDonor donor to check ECD for
   * @returns {string} display value for ECD column
   */
  private ecdDisplay(donor: ListDonor): string {
    const ecd_boolean = donor.ecd_donor;
    if (isMasked(ecd_boolean)) return `${ecd_boolean}`;

    if (donor.ecd_donor == null) return '-';

    const result = donor.ecd_donor ? 'Y' : 'N';
    return result;
  }

  /**
   * Row data to show in active donor listing table
   *
   * @returns {DeceasedDonorActiveListingRow[]} row data
   */
  get donorsForTable(): DeceasedDonorActiveListingRow[] {
    if (!this.donors || this.isLoading) return [];
    
    const donors: ListDonor[] = this.donors.entries || [];
    const result = donors.map((donor: ListDonor): DeceasedDonorActiveListingRow => {
      const referralType = this.referralType(donor);
      const organConsentedNames: string[] = this.organConsentsDisplay(donor.organ_consents || []);
      const row: DeceasedDonorActiveListingRow = {
        deceased_donor_id:      donor.deceased_donor_id != null ? donor.deceased_donor_id.toString() : '-',
        client_id:              donor.client_id != null ? donor.client_id.toString() : '-',
        hospital_name:          donor.hospital_name || '-',
        national_id:            donor.national_id || '-',
        first_name:             donor.first_name || '-',
        last_name:              donor.last_name || '-',
        age:                    donor.age != null ? donor.age.toString() : '-',
        sex:                    donor.sex != null ? donor.sex : '-',
        cause_of_death:         donor.cause_of_death != null ? donor.cause_of_death : '-',
        donor_type:             this.donorTypeFromNeurologicalDeath(donor.donor_type),
        referral_type:          referralType.join('<br /> '),
        organ_code:             organConsentedNames.join('<br /> '),
        ecd:                    this.ecdDisplay(donor),
        referral_date:          donor.referral_date || '-',
        blood_type:             donor.blood_type || '-',
        rh:                     donor.rh || '-',
        mrn:                    donor.mrn || '-',
        weight:                 this.parseMaskedNumber(donor.weight),
        height:                 this.parseMaskedNumber(donor.height),
      };
      return row;
    });
    return result;
  }

  get donorColumns(): any[] {
    return [
      {
        label: `${this.$t('deceased_donor_id')}`,
        field: 'deceased_donor_id',
        sortable: true,
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'text'
        }
      },
      {
        label: `${this.$t('system_id')}`,
        field: 'client_id',
        sortable: true,
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'text'
        }
      },
      {
        label: `${this.$t('hospital_name')}`,
        field: 'hospital_name',
        sortable: false,
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'text'
        }
      },
      {
        label: `${this.$t('national_id')}`,
        field: 'national_id',
        sortable: true,
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'text'
        }
      },
      {
        label: `${this.$t('first_name')}`,
        field: 'first_name',
        sortable: false,
      },
      {
        label: `${this.$t('last_name')}`,
        field: 'last_name',
        sortable: false,
      },
      {
        label: `${this.$t('age')}`,
        field: 'age',
        sortable: true,
        thClass: 'vgt-small-column',
        tdClass: 'vgt-small-column',
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'text',
          placeholder: 'All',
        }
      },
      {
        label: `${this.$t('sex')}`,
        field: 'sex',
        width: "130px",
        sortable: true,
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'dropdown',
          placeholder: 'All',
          filterDropdownItems: this.sexOptionsDropdownValues
        }
      },
      {
        label: `${this.$t('cause_of_death')}`,
        field: 'cause_of_death',
        sortable: false,
      },
      {
         label: `${this.$t('donor_type')}`,
        field: 'donor_type',
        sortable: true,
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'dropdown',
          placeholder: 'All',
          filterDropdownItems: this.donorTypeOptions
        }
      },
      {
        label: `${this.$t('referral_type')}`,
        field: 'referral_type',
        html: true,
        sortable: false,
      },
      {
        label: `${this.$t('organ_consents_display')}`,
        field: 'organ_code',
        html: true,
        sortable: false,
        width: '170px',
        filterOptions: {
          enabled: true,
          type: 'dropdown',
          custom: true,
          placeholder: 'All',
          filterDropdownItems: this.organFilterOptions
        }
      },
      {
        label: `${this.$t('ecd')}`,
        field: 'ecd',
        width: "80px",
        sortable: false,
      },
      {
        label: `${this.$t('referral_date')}`,
        field: 'referral_date',
        type: 'text',
        sortable: true,
        width: '130px',
        thClass: 'vgt-left-align',
        tdClass: 'vgt-left-align',
        formatFn: this.formatDateFromDateTime,
        // FIXME - re-enable once referral date searching approved TPGLI-6628/TPGLI-6629
        // dateInputFormat: Format.DISPLAY_DATE, 
        // dateOutputFormat: Format.DISPLAY_DATE,
        // filterOptions: this.buildDateFilter()
      },
      {
        label: `${this.$t('blood_type')}`,
        field: 'blood_type',
        width: "120px",
        sortable: false,
      },
      {
        label: `${this.$t('rh')}`,
        field: 'rh',
        width: "50px",
        sortable: false,
      },
      {
        label: `${this.$t('mrn')}`,
        field: 'mrn',
        width: "50px",
        sortable: false,
      },
      {
        label: `${this.$t('height')}`,
        field: 'height',
        width: "70px",
        sortable: false,
      },
      {
        label: `${this.$t('weight')}`,
        field: 'weight',
        width: "70px",
        sortable: false,
      },
    ];
  }

  private buildDateFilter(): any {
    // Generic string filter
    const formatDate = this.formatDateFromDateTime;
    return {
      enabled: true,
      placeholder: ' ',
      // should return true if data matches the filterString, otherwise false
      filterFn: function(data?: string|null, filterString?: string|null): boolean {
        if (data == null) {
          return false;
        }
        if (filterString == null) {
          return true;
        }
        const parsedDate = formatDate(data);

        // Parse numeric client_id as if it were a string
        const filterRegexp = new RegExp(filterString);
        const result = filterRegexp.test(parsedDate);
        return result;
      },
    };
  }
  /**
   * Parse combined date/time field to be shown in table
   *
   * @param value the datetime property
   * @returns {string} string representation of date
   */
  formatDateFromDateTime(value: string|null): string {
    if (!value) return '-';
    if (isMasked(value)) return `${value}`;

    return this.parseDisplayDateUiFromDateTime(value) || '-';
  }

  private donorTableConfig(): TableConfig {
    return {
      data: this.donorsForTable,
      columns: this.filteredColumns,
      empty: 'Cannot find any Donors that match the selected filters',
      createButton: false,
      pagination: true,
      sortOptions: {
        enabled: true
      },
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        setCurrentPage: this.currentPage,
        mode: 'pages',
        perPageDropdown: [10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: 'Results per page',
        position: 'bottom'
      }
    };
  }

  public selectDonor(event: any) {
    if (event.row.client_id !== undefined) {
      const clientId: string = event.row.client_id.toString();
      this.$router.push({
        name: 'edit-deceased-donor',
        params: {
          id: clientId,
        }
      });
    } else {
      console.warn('Missing donor clientId');
      this.$router.push({name: 'list-donors'});
    }
  }

  public updatePagination(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;

    this.loadData(search_params, sort_params);
  }

  /**
   * Sets the search terms and sorting options
   *
  */
  public filterList(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.loadData(search_params, sort_params);
  }
}
</script>
