import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!
  const _component_recipients_search_results = _resolveComponent("recipients-search-results")!
  const _component_deceased_donors_search_results = _resolveComponent("deceased-donors-search-results")!
  const _component_warning_banner = _resolveComponent("warning-banner")!
  const _component_full_page_list_layout = _resolveComponent("full-page-list-layout")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_page_top, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('search')), 1)
      ]),
      _: 1
    }),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
        ], 64))
      : (_openBlock(), _createBlock(_component_full_page_list_layout, { key: 1 }, {
          content: _withCtx(() => [
            (_ctx.searchedTerm)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("p", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('you_searched_for')) + " ", 1),
                    _createElementVNode("strong", null, _toDisplayString(_ctx.searchedTerm), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('in')) + " " + _toDisplayString(_ctx.$t(_ctx.searchedType)) + ". ", 1)
                  ]),
                  (_ctx.searchedType === 'recipients')
                    ? (_openBlock(), _createBlock(_component_recipients_search_results, {
                        key: _ctx.searchedTerm,
                        "searched-term": _ctx.searchedTerm
                      }, null, 8, ["searched-term"]))
                    : _createCommentVNode("", true),
                  (_ctx.searchedType === 'donors')
                    ? (_openBlock(), _createBlock(_component_deceased_donors_search_results, {
                        key: _ctx.searchedTerm,
                        "searched-term": _ctx.searchedTerm
                      }, null, 8, ["searched-term"]))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createBlock(_component_warning_banner, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('enter_entity_id')), 1)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }))
  ]))
}