import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "fa-stack summary-stack"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.compareModalIcon || _ctx.summaryBarIcon)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, {
          icon: _ctx.organIcon,
          class: _normalizeClass(_ctx.organIconClass),
          title: _ctx.title || _ctx.organName,
          "fixed-width": ""
        }, null, 8, ["icon", "class", "title"])
      ]))
    : (_openBlock(), _createElementBlock("span", _hoisted_2, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['fa', 'circle'],
          class: "fa-stack-2x fa-w-16"
        }),
        _createVNode(_component_font_awesome_icon, {
          icon: _ctx.organIcon,
          class: _normalizeClass(_ctx.organIconClass),
          title: _ctx.title || _ctx.organName,
          "fixed-width": ""
        }, null, 8, ["icon", "class", "title"])
      ]))
}