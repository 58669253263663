export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "date_of_admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Admission to Hospital"])},
        "time_of_admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of Admission to Hospital (ET)"])},
        "organ_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Source"])},
        "specific_organ_transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Organ Being Transplanted"])},
        "donor_identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODO Donor Identifier"])},
        "donor_odo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor ODO"])},
        "donor_cross_clamp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Cross-Clamp Date"])},
        "donor_cross_clamp_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Cross-Clamp Time (ET)"])},
        "cold_preservation_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold Preservation Start Date"])},
        "cold_preservation_start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold Preservation Start Time (ET)"])},
        "cold_preservation_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold Preservation End Date"])},
        "cold_preservation_end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold Preservation End Time (ET)"])},
        "cold_ischemia_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold Ischemia Time (CIT)"])},
        "perfusion_device_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfusion Device Status"])},
        "perfusion_device_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfusion Device Used"])},
        "reperfusion_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reperfusion Date"])},
        "reperfusion_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reperfusion Time (ET)"])},
        "transplant_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant/OR Start Date"])},
        "transplant_start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant/OR Start Time (ET)"])},
        "transplant_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant/OR End Date"])},
        "transplant_end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant/OR End Time (ET)"])},
        "anastomosis_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anastomosis Date"])},
        "anastomosis_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anastomosis Time (ET)"])},
        "organ_transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Transplanted?"])},
        "not_transplanted_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Transplanted Reason"])},
        "surgical_complications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surgical Complications"])},
        "clavien_dindo_classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clavien-Dindo Classification"])},
        "intra_operative_death": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-Operative Death"])},
        "discharge_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Hospital Discharge"])},
        "provider_of_followup_care": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider of Follow-Up Care"])},
        "save_transplant_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Transplant Details"])},
        "cancel_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are cancelling this update. Any unsaved changes will be cleared. Do you wish to proceed?"])},
        "transplant_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Details"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "minutes_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
        "cold_ischemia_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The time elapsed (in minutes) between the start and end of cold preservation for the specified organ(s)."])}
      },
      "fr": {
        "date_of_admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Admission to Hospital"])},
        "time_of_admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of Admission to Hospital (ET)"])},
        "organ_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Source"])},
        "specific_organ_transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Organ Being Transplanted"])},
        "donor_identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODO Donor Identifier"])},
        "donor_odo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor ODO"])},
        "donor_cross_clamp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Cross-Clamp Date"])},
        "donor_cross_clamp_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Cross-Clamp Time (ET)"])},
        "cold_preservation_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold Preservation Start Date"])},
        "cold_preservation_start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold Preservation Start Time (ET)"])},
        "cold_preservation_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold Preservation End Date"])},
        "cold_preservation_end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold Preservation End Time (ET)"])},
        "cold_ischemia_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold Ischemia Time (CIT)"])},
        "perfusion_device_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfusion Device Status"])},
        "perfusion_device_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfusion Device Used"])},
        "reperfusion_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reperfusion Date"])},
        "reperfusion_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reperfusion Time (ET)"])},
        "transplant_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant/OR Start Date"])},
        "transplant_start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant/OR Start Time (ET)"])},
        "transplant_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant/OR End Date"])},
        "transplant_end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant/OR End Time (ET)"])},
        "anastomosis_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anastomosis Date"])},
        "anastomosis_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anastomosis Time (ET)"])},
        "organ_transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Transplanted?"])},
        "not_transplanted_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Transplanted Reason"])},
        "surgical_complications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surgical Complications"])},
        "clavien_dindo_classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clavien-Dindo Classification"])},
        "intra_operative_death": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-Operative Death"])},
        "discharge_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Hospital Discharge"])},
        "provider_of_followup_care": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider of Follow-Up Care"])},
        "save_transplant_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Transplant Details"])},
        "cancel_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are cancelling this update. Any unsaved changes will be cleared. Do you wish to proceed?"])},
        "transplant_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la transplantation"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "minutes_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
        "cold_ischemia_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The time elapsed (in minutes) between the start and end of cold preservation for the specified organ(s)."])}
      },
      "en_tgln": {
        "recipient_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Status"])}
      },
      "fr_tgln": {
        "recipient_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut du patient"])}
      }
    }
  })
}
