export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "core_ab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Core Ab"])},
        "recipient_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Information"])},
        "recipient_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Details"])},
        "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare"])},
        "hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA"])},
        "recipient_hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient HLA"])},
        "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
        "allocation_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Step"])},
        "manually_added_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually added recipient"])},
        "listed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listed for"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient ID"])},
        "recipient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Name"])},
        "national_recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National Recipient ID"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "medical_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Status"])},
        "secondary_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Status"])},
        "wait_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait Time (Days)"])},
        "list_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Date"])},
        "primary_diagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Diagnosis"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
        "general_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Information"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
        "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
        "bmi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMI"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type (ABO)"])},
        "accept_a2_or_a2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept A2 or A2B"])},
        "donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Type"])},
        "mrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRN"])},
        "total_lung_capacity_actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Lung Capacity (TLC) - Actual"])},
        "total_lung_capacity_predicted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Lung Capacity (TLC) - Predicted"])},
        "virology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virology"])},
        "cmv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMV"])},
        "ebv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBV"])},
        "hbs_ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBs Ag"])},
        "hcv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HCV"])},
        "patient_hla_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient HLA Comments"])},
        "donor_acceptance_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Acceptance Comments"])},
        "referred_organ_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referred Organ(s) Section:"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
        "registration_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Type"])},
        "registration_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Status"])},
        "pra_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRA Section:"])},
        "cumulative_cpra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumulative cPRA:"])},
        "pra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRA"])},
        "current_pra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current PRA"])},
        "months_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Months Old"])},
        "peak_pra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peak PRA"])},
        "class_i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class I"])},
        "class_ii": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class II"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "vxm_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VXM Section:"])},
        "vxm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VXM"])},
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
        "cumulative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumulative"])},
        "class_status": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("class")), " ", _interpolate(_named("status"))])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no VXM data."])},
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])},
        "unacceptable_dsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unacceptable DSA"])},
        "unacceptable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unacceptable"])},
        "indeterminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indeterminate"])},
        "indeterminate_dsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indeterminate DSA"])},
        "possible_allele_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possible Allele Specific"])},
        "untested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untested"])},
        "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
        "current_serum_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Serum Date"])},
        "total_serum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total # Serum"])},
        "donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor"])},
        "demographics_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demographics Section:"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
        "cause_of_death": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause of Death"])},
        "stroke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stroke"])},
        "abo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABO"])},
        "accept_abo_incompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept ABO incompatible?"])},
        "ndd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NDD"])},
        "dcd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DCD"])},
        "ecd_consented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECD consented?"])},
        "ecd_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECD Donor"])},
        "exceptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution"])},
        "exceptional_distribution_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution reason"])},
        "exceptional_distribution_other_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution other reason"])},
        "exceptional_distribution_travel_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution travel reason"])},
        "exceptional_distribution_transmission_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution increased risk for transmission of Hep B/Hep C/HIV other reason"])},
        "minimum_acceptable_donor_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum acceptable donor weight"])},
        "maximum_acceptable_donor_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum acceptable donor weight"])},
        "previous_transplants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Transplants"])},
        "recipient_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient comments"])},
        "virology_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virology Results"])},
        "serology_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serology Results"])},
        "nat_hiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAT HIV"])},
        "nat_hcv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAT HCV"])},
        "nat_hbv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAT HBV"])},
        "hep_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hep B"])},
        "surface_ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface Ag"])},
        "surface_ab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface Ab"])},
        "hep_surface": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hep")), " ", _interpolate(_named("surface"))])},
        "hiv_i_ii": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIV I/II"])},
        "htlv1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTLV1"])},
        "htlv2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTLV2"])},
        "syphilis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T.P.Syphilis"])},
        "toxoplasma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toxoplasma"])},
        "wnv_pcr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WNV PCR"])},
        "class_i_typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class I Typing"])},
        "class_i_antibodies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class I Antibodies"])},
        "class_ii_typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class II Typing"])},
        "class_ii_antibodies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class II Antibodies"])},
        "molecular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molecular"])},
        "serologic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serologic"])},
        "most_likely_allele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Likely Allele"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
      },
      "fr": {
        "core_ab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noyau Ab"])},
        "recipient_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations du patient"])},
        "recipient_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du patient"])},
        "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer"])},
        "hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA"])},
        "recipient_hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA du patient"])},
        "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient"])},
        "allocation_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape d'allocation"])},
        "manually_added_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire ajouté manuellement"])},
        "listed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listé pour"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Patient"])},
        "recipient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du patient"])},
        "national_recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No National du patient"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "medical_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut médical"])},
        "secondary_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut secondaire"])},
        "wait_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente (jours)"])},
        "list_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la liste"])},
        "primary_diagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnostic primaire"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
        "general_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge"])},
        "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])},
        "bmi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMC"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe sanguin (ABO)"])},
        "accept_a2_or_a2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter A2 ou A2B"])},
        "donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de donneur"])},
        "mrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRN"])},
        "total_lung_capacity_actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacité Pulmonaire Totale (TLC) - Réelle"])},
        "total_lung_capacity_predicted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacité Pulmonaire Totale (TLC) - Prévu"])},
        "virology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virologie"])},
        "cmv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMV"])},
        "ebv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBV"])},
        "hbs_ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBs Ag"])},
        "hcv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HCV"])},
        "patient_hla_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires sur le HLA du patient"])},
        "donor_acceptance_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires sur l'acceptation du donaneur"])},
        "referred_organ_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section d'organe(s) référencé(s) :"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe"])},
        "registration_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'enregistrement"])},
        "registration_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut d'enregistrement"])},
        "pra_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section PRA :"])},
        "cumulative_cpra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPRA cumulé :"])},
        "pra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRA"])},
        "current_pra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRA actuel"])},
        "months_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois d'ancienneté"])},
        "peak_pra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRA de pointe"])},
        "class_i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe I"])},
        "class_ii": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe II"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
        "vxm_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section VXM :"])},
        "vxm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VXM"])},
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actuel"])},
        "cumulative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cumulatif"])},
        "class_status": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("class")), " ", _interpolate(_named("status"))])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de données VXM."])},
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Négatif"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positif"])},
        "unacceptable_dsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DSA inacceptable"])},
        "unacceptable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inacceptable"])},
        "indeterminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indéterminé"])},
        "indeterminate_dsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DSA indéterminé"])},
        "possible_allele_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allèle spécifique possible"])},
        "untested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non testé"])},
        "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
        "current_serum_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date actuelle du sérum"])},
        "total_serum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de sérums"])},
        "donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneur"])},
        "demographics_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section sur les données démographiques :"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
        "cause_of_death": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause du décès"])},
        "stroke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accident vasculaire cérébral"])},
        "abo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABO"])},
        "accept_abo_incompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter l'incompatibilité ABO ?"])},
        "ndd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NDD"])},
        "dcd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DCD"])},
        "ecd_consented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECD a consenti?"])},
        "ecd_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneur ECD"])},
        "exceptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Exceptionnelle"])},
        "exceptional_distribution_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Exceptionnelle raison"])},
        "exceptional_distribution_other_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Exceptionnelle autre raison"])},
        "exceptional_distribution_travel_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Exceptionnelle raison du voyage"])},
        "exceptional_distribution_transmission_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Exceptionnelle risque accru de transmission de l'hépatite B/Hépatite C/VIH autre raison"])},
        "minimum_acceptable_donor_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids minimal acceptable du donneur"])},
        "maximum_acceptable_donor_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids maximal acceptable du donneur"])},
        "previous_transplants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplantations précédentes"])},
        "recipient_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commantaires su patient"])},
        "virology_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la virologie"])},
        "serology_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la serologie"])},
        "nat_hiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAT HIV"])},
        "nat_hcv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAT HCV"])},
        "nat_hbv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAT HBV"])},
        "hep_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hep B"])},
        "surface_ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["surface Ag"])},
        "surface_ab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["surface Ab"])},
        "hep_surface": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hep")), " ", _interpolate(_named("surface"))])},
        "hiv_i_ii": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIV I/II"])},
        "htlv1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTLV1"])},
        "htlv2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTLV2"])},
        "syphilis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T.P.Syphilis"])},
        "toxoplasma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toxoplasma"])},
        "wnv_pcr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WNV PCR"])},
        "class_i_typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typage de classe I"])},
        "class_i_antibodies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anticorps de classe I"])},
        "class_ii_typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typage de classe II"])},
        "class_ii_antibodies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anticorps de classe II"])},
        "molecular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moleculaire"])},
        "serologic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sérologique"])},
        "most_likely_allele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allèle le plus probable"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
      }
    }
  })
}
