import { createI18n, I18nOptions } from 'vue-i18n';
import customValidationMessages from '@/views/_locales/errorMessages.json';
import customValidationValues from '@/views/_locales/errorValues.json';
import common from '@/components/_locales/common.json';
import deepmerge from 'deepmerge';

// combine default & custom messages
const messagesEN = {
  code: 'en',
  messages: { 
    ...customValidationMessages.en
  }
};

const messagesFR = {
  code: 'fr',
  messages: { 
    ...customValidationMessages.fr
  }
};

const messagesTglnEN = {
  code: 'en_tgln',
  messages: {
    ...customValidationMessages.en_tgln
  }
};

const messagesTglnFR = {
  code: 'en_tgln',
  messages: {
    ...customValidationMessages.fr_tgln
  }
};

// Define common system-wide messages available to be used in all components, views, etc.
const globalMessages = {
  en: {
    ...common.en,
    ...customValidationValues.en,
    // ...extraI18n_en,
    validation: messagesEN
  },
  fr: {
    ...common.fr,
    ...customValidationValues.en,
    // ...extraI18n_fr,
    validation: messagesFR
  },
  en_tgln: {
    ...common.en,
    ...customValidationValues.en,
    validation: messagesTglnEN
  },
  fr_tgln: {
    ...common.en,
    ...customValidationValues.en,
    validation: messagesTglnFR
  }
} as Record<string, any>;

// load in extra i18n content if available
const extraI18n_en = process.env.VUE_APP_EXTRA_I18N ? JSON.parse(process.env.VUE_APP_EXTRA_I18N).en : null;
const extraI18n_fr = process.env.VUE_APP_EXTRA_I18N ? JSON.parse(process.env.VUE_APP_EXTRA_I18N).fr : null;
const extraMessages = {
  en: {
    ...extraI18n_en,
  },
  fr: {
    ...extraI18n_fr,
  },
};

// Export i18n instance as 'i18nPlugin' to be registered with the bue application in main.ts
export const i18nPlugin = createI18n({
  // setup default locales that are pre-loaded
  locale: 'en',
  fallbackLocale: {
    'en_tgln': ['en'],
    'fr_tgln': ['fr'],
    'en_tq':   ['en'],
    'fr_tq':   ['fr'],    
    'fr':      ['en'],
    'default': ['en'],
  },
  // setup with validation messages
  messages: globalMessages as I18nOptions['messages'],
  silentTranslationWarn: false // show 'no translation' warning messages
});

// Combine any number of 'local' i18n messages for a component with the 'global' messages defined above
function mergeWithGlobalMessages(localMessages:  Record<string, any>[]): Record<string, any> {
  return deepmerge.all([
    globalMessages,
    ...localMessages,
    extraMessages,
  ]);
}

// Setup structure to define local consolidated i18n messages for a component (e.g. in @Component decorator)
export function i18nMessages(localMessages:  Record<string, any>[]): Record<string, any> {
  return { i18n: { messages: mergeWithGlobalMessages(localMessages) } };
}

// Export 'i18n.global' as default instead of i18n itself to simplify usage of translation functions outside vue components
export default i18nPlugin.global;
