export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page:"])},
        "return_to_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to previous page"])},
        "wait_time_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 days"]), _normalize(["1 day"]), _normalize([_interpolate(_named("n")), " days"])])},
        "confirm_partial_cluster_transplant_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This journey is part of a cluster.\n\nSaving transplant details for this journey alone will place all clustered journeys on Partial Cluster Transplant Hold until their respective transplant details are entered or the cluster is broken.\n\nClick OK to continue or Cancel to return to the transplant details area."])}
      },
      "fr": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours…"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page:"])},
        "return_to_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à la page précédente"])},
        "wait_time_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 jours"]), _normalize(["1 jour"]), _normalize([_interpolate(_named("n")), " jours"])])},
        "confirm_partial_cluster_transplant_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce trajet fait partie d'un regroupement.\n\nSi vous enregistrez les détails de la greffe pour ce seul trajet, tous les trajets du cluster seront mis en attente de greffe partielle de cluster jusqu'à ce que les détails de leur greffe respective soient saisis ou que le cluster soit rompu.\n\nCliquez sur OK pour continuer ou sur Annuler pour revenir à la zone des détails de la transplantation."])}
      }
    }
  })
}
