<template>
  <footer id="footer" class="content-wrap py-3 mt-2 border-top">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-2">
          <p class="d-block mb-3">
            &copy; {{currentYear}}
          </p>
        </div>
        <div class="col-sm-1 text-center">      
        </div>
        <div class="col-sm-9">
          <span class="footer-brand float-right">
            {{ $t('system') }}
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { i18nMessages } from '@/i18n';
import { Component, Vue } from 'vue-facing-decorator';

@Component({
  ...i18nMessages([
    require('./_locales/common.json'),
  ]),
})
export default class SiteFooter extends Vue {
  get currentYear() {
    return new Date().getFullYear();
  }
}
</script>
