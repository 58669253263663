<template>
  <card-section
    section-id="hla-vxm"
    :lookups-to-load="lookupsToLoad"
    @loaded="loaded()"
  >
    <template v-slot:header>
      {{$t('virtual_crossmatch')}}
    </template>
    <template v-slot:body>
      <!-- Perform Specified Virtual Crossmatch -->
      <hla-perform-specified-virtual-crossmatch
        :recipient-id="recipientId"
      />
      <!-- View Virtual Crossmatch History -->
      <hla-view-virtual-crossmatch-history />
    </template>
  </card-section>
</template>

<script lang="ts">
import { Getter }  from 'vuex-facing-decorator';
import { Component, Vue } from 'vue-facing-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import HlaViewVirtualCrossmatchHistory from '@/components/hla/HlaViewVirtualCrossmatchHistory.vue';
import HlaPerformSpecifiedVirtualCrossmatch from '@/components/hla/HlaPerformSpecifiedVirtualCrossmatch.vue';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    CardSection,
    HlaViewVirtualCrossmatchHistory,
    HlaPerformSpecifiedVirtualCrossmatch,
  },
  ...i18nMessages([
    require('@/components/hla/_locales/_HlaVirtualCrossmatch.json'),
  ]),
})
export default class HlaVirtualCrossmatch extends Vue {
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;

  // List lookup data to be loaded by the card section component
  private lookupsToLoad = [
    'hla_testing_method',
  ];

  // Load recipient VXM history
  private loaded(): void {
    this.$emit('loaded', 'hlaVirtualCrossmatch');
    this.$store.dispatch('history/loadRecipientVxm', this.recipientId);
  }
}
</script>
