import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group-large" }
const _hoisted_3 = {
  key: 0,
  class: "standard-form-group-large"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: _ctx.$t('donation_destination_heading'),
    subSectionId: "donation-destination",
    ref: "saveDonationDestination",
    saveButtonText: _ctx.$t('donation_destination_save_button'),
    saveButton: _ctx.showSaveButton,
    onSave: _ctx.savePatch,
    disabled: _ctx.isDisabled || !_ctx.showSaveButton
  }, _createSlots({ _: 2 }, [
    (_ctx.editState)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_select_input, {
                  selectId: "donation-destination-donate-to",
                  name: _ctx.$t('donation_destination_donate_to_field'),
                  modelValue: _ctx.editState.donateTo,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.donateTo) = $event)),
                  options: _ctx.donateToOptions,
                  onChange: _ctx.onDonateToChanged
                }, null, 8, ["name", "modelValue", "options", "onChange"])
              ]),
              (_ctx.showDestinationSpecification)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_select_input, {
                      selectId: "donation-destination-destination-specification",
                      name: _ctx.$t(_ctx.destinationSpecificationNameKey),
                      modelValue: _ctx.editState.destinationSpecification,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.destinationSpecification) = $event)),
                      options: _ctx.destinationSpecificationOptions,
                      rules: "required"
                    }, null, 8, ["name", "modelValue", "options"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["title", "saveButtonText", "saveButton", "onSave", "disabled"]))
}