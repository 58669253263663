export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "checklist_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist History"])},
        "checklist_history_title_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist history for Allocation"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "pre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-Allocation"])},
        "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-Allocation"])},
        "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Allocation"])},
        "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
        "not confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Confirmed"])}
      },
      "fr": {
        "checklist_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist History"])},
        "checklist_history_title_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist history for Allocation"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
        "pre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-Allocation"])},
        "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-Allocation"])},
        "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Allocation"])},
        "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
        "not confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Confirmed"])}
      }
    }
  })
}
