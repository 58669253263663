import { Component, Vue } from 'vue-facing-decorator';

@Component
export class UrlUtilsMixin extends Vue {

  getUrlQueryValue(q: string): string|null {
    let term: string|null = null;
    if(this.$route.query[q]) {
      term = Array.isArray(this.$route.query[q]) ? (this.$route.query[q] || [null])[0] : this.$route.query[q] as string;
    }

    return term;
  }

  /**
   * set access_type (either fusionauth or oneid)
   * used to determine what the next stage of authentication will be
   *
   * @param type string
   * @param replace parameters to replace in path
   * @returns {string}
   */
  prepareRequest(type: string) {
    localStorage.removeItem('access_type');
    localStorage.setItem('access_type', type);
  }
}
