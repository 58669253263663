<template>
  <fieldset v-if="showToolbar || loading">
    <nav class="nav action-row" v-if="loading">
      <span class="skeleton-box skeleton-button-left mb-0" />
    </nav>
    <nav class="nav action-row" v-else>
      <button
        type="button"
        v-if="canArchive"
        class="btn btn-wide btn-warning"
        @click.prevent="openArchive"
        :disabled="!canArchive"
      >
        {{ $t('action.archive') }}
        <span class="pl-2" v-if="isArchiving">
          <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" />
        </span>
      </button>
      
      <button
        type="button"
        v-if="canRestore"
        class="btn btn-wide btn-primary"
        @click.prevent="openRestore"
        :disabled="!canRestore"
      >
        {{ $t('action.restore') }}
        <span class="pl-2" v-if="isRestoring">
          <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" />
        </span>
      </button>
    </nav>
    <hr />

    <validation-observer ref="actionValidation" autocomplete="off" tag="form" v-slot="{ handleSubmit }">
      <modal-section
        modalId="actionModal" 
        ref="actionModal" 
        class="modal-action-modal"
        :centered="true" 
        :closeButton="false" 
        size="md" 
        :wide="false">
        <template v-slot:title>
          {{ getLocaleText('title') }}
        </template>

        <template v-slot:body>
          <fieldset>
            <small>{{ getGuidingText() }}</small>
            <div class="mt-2" v-if="rationaleRequired">
              <hr/>
              <div class="col-xs-12">
                <text-input
                  rules="required"
                  input-id="archive-rationale"
                  required="true"
                  :name="getLocaleText('rationale')"
                  v-model="rationale"
                />
              </div>
            </div>
          </fieldset>
        </template>

        <template v-slot:footer>
          <div class="modal-footer-body">
            <button
              class="btn btn-success"
              @click.prevent="rationaleRequired ? handleSubmit(performAction): performAction()">
              {{ $t('action.confirm') }}
            </button>
            <button 
              type="button" 
              data-dismiss="modal" 
              @click="cancel"
              class="btn btn-secondary">
              {{ $t('action.cancel') }}
            </button>
          </div>
        </template>
      </modal-section>
    </validation-observer>

  </fieldset>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextInput from '@/components/shared/TextInput.vue';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    ModalSection,
    TextInput
  },
  ...i18nMessages([
    require('@/components/shared/_locales/ActionToolbar.json'),
  ]),
})
export default class ActionToolbar extends Vue {
  @Prop({ default: () => { return []; } }) permittedActions!: string[];
  // Is an action operation currently in progress?
  @Prop({ default: false }) isArchiving!: boolean;
  @Prop({ default: false }) isRestoring!: boolean;
  @Prop({ default: null }) archiveGuidingText!: string;
  @Prop({ default: null }) restoreGuidingText!: string;
  @Prop({ default: false }) rationaleRequired!: boolean;
  @Prop({ default: false }) loading!: true;

  private rationale = null
  private action: string|null = null

  get showToolbar(): boolean {
    return this.canArchive || this.canRestore;
  }

  // Does the current user have permission to archive?
  get canArchive(): boolean {
    return this.permittedActions.includes('archive');
  }

  // Does the current user have permission to restore?
  get canRestore(): boolean {
    return this.permittedActions.includes('restore');
  }

  getLocaleText(key: string): string {
    if (!key || !this.action) return '';

    return this.$t(`${this.action}.${key}`).toString();
  }

  getGuidingText(): string {
    const archiveGuidingText = this.archiveGuidingText ? this.archiveGuidingText : this.$t('archive.guiding_text').toString();
    const restoreGuidingText = this.restoreGuidingText ? this.restoreGuidingText : this.$t('restore.guiding_text').toString();
    return this.action == 'archive' ? archiveGuidingText : restoreGuidingText;
  }  

  // Toggle a modal based on a ref
  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    targetModal.toggleModal();
  }

  private openArchive(): void {
    this.resetToolbar();
    this.action = 'archive';
    this.toggleModal('actionModal');
  }

  private openRestore(): void {
    this.resetToolbar();
    this.action = 'restore';
    this.toggleModal('actionModal');
  }
  private performAction(): void {
    this.$emit(this.action as string, this.rationale);
    this.toggleModal('actionModal');
  }

  public resetToolbar(): void {
    this.action = null; // clear action
    this.rationale = null; // clear rationale

    // clear form validation
    const validations = this.$refs.actionValidation as any;
    if (validations) { validations.resetForm(); }
  }

  private cancel(): void {
    this.toggleModal('actionModal'); // close modal
  }

}
</script>
