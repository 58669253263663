import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-footer-body" }
const _hoisted_2 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_openBlock(), _createBlock(_component_modal_section, {
    modalId: "ctr-log-modal",
    ref: "ctrLogModal",
    class: "modal-sticky-header",
    centered: true
  }, _createSlots({
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('ctr_integration')), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", _hoisted_2, _toDisplayString(_ctx.$t('cancel')), 1)
      ])
    ]),
    _: 2
  }, [
    (this.editState && this.editState.logs)
      ? {
          name: "body",
          fn: _withCtx(() => [
            _createVNode(_component_sub_section, {
              "sub-section-id": "ctr-integration-section",
              tabbableValue: _ctx.$t('date'),
              title: _ctx.$t('integration_sync_events'),
              "table-config": _ctx.ctrLogsTableConfig
            }, null, 8, ["tabbableValue", "title", "table-config"])
          ]),
          key: "0"
        }
      : {
          name: "body",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
          ]),
          key: "1"
        }
  ]), 1536))
}