import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = {
  for: "header-search",
  class: "sr-only"
}
const _hoisted_3 = {
  key: 0,
  class: "input-group"
}
const _hoisted_4 = { class: "input-group-prepend" }
const _hoisted_5 = {
  type: "button",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false",
  class: "btn btn-outline dropdown-toggle dropdown-toggle-split shadow-none"
}
const _hoisted_6 = { class: "sr-only" }
const _hoisted_7 = {
  class: "dropdown-menu dropdown-menu-right",
  "x-placement": "bottom-end"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = { class: "input-group-append" }
const _hoisted_11 = {
  type: "submit",
  class: "btn btn-outline-primary shadow-none"
}
const _hoisted_12 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("form", {
    class: "form-inline form-search",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.performSearch()), ["stop","prevent"])),
    method: "get"
  }, [
    _createElementVNode("input", {
      type: "hidden",
      name: "type",
      value: _ctx.selectedSearchOption
    }, null, 8, _hoisted_1),
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('search_recipient_and_records')) + "}", 1),
    (_ctx.canSearchAnything)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.availableSearchCategories[_ctx.selectedSearchOption]) + " ", 1),
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'caret-down'] }),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('toggle_search_options_dropdown')), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.availableSearchCategories), (k) => {
                return (_openBlock(), _createElementBlock("a", {
                  key: k,
                  href: "#",
                  onClick: _withModifiers(($event: any) => (_ctx.selectedSearchOption = k), ["prevent"]),
                  class: "dropdown-item"
                }, _toDisplayString(_ctx.availableSearchCategories[k]), 9, _hoisted_8))
              }), 128))
            ])
          ]),
          _withDirectives(_createElementVNode("input", {
            id: "header-search",
            name: "term",
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
            placeholder: _ctx.$t('search'),
            class: "form-control border-0"
          }, null, 8, _hoisted_9), [
            [_vModelText, _ctx.searchTerm]
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("button", _hoisted_11, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'search'] }),
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('search')), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 32))
}