export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "active_backup_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Backup Offers"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
        "active_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Donors"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize panel"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse panel"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Options"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Style"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this panel"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor ID"])},
        "missing_deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing donor Afflo ID"])},
        "donor_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Hospital"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
        "abo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABO"])},
        "consented_organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consented Organ"])},
        "referral_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Date"])},
        "no_active_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Active Donors"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
        "link_to_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Donor Page"])},
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Recipient Page"])},
        "no_active_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Active Backup Offers"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation ID"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient ID"])},
        "offer_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Time"])},
        "offer_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Response"])}
      },
      "fr": {
        "active_backup_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres subsituts actifs"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe"])},
        "active_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneurs actifs"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le panneau"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau rabattu"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher ce panneau"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Donneur"])},
        "missing_deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No donneur manquant"])},
        "donor_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôpital du donneur"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
        "abo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABO"])},
        "consented_organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe consenti"])},
        "referral_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de référence"])},
        "no_active_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun donneur actif"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "link_to_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page des donneurs"])},
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page des patients"])},
        "no_active_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun offre subsitut actif"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Attribution"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Patient"])},
        "offer_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'heure  de l'offre"])},
        "offer_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse de l'offre"])}
      },
      "en_tgln": {
        "missing_deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing donor TGLN ID"])}
      },
      "en_tq": {
        "missing_deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing donor TQ Number"])}
      }
    }
  })
}
