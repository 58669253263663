import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card tip-card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "media align-items-center" }
const _hoisted_4 = { class: "media-left" }
const _hoisted_5 = { class: "media-body flex-row align-items-center" }
const _hoisted_6 = { class: "tip-report-name" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "form-group col-sm-12 col-md-6 col-lg-4 col-xl-3" }
const _hoisted_10 = { class: "form-group col-sm-12 col-md-6 col-lg-4 col-xl-3" }
const _hoisted_11 = { class: "form-group col-sm-12 col-md-6 col-lg-4 col-xl-3" }
const _hoisted_12 = { class: "form-group col-sm-12 col-md-6 col-lg-4 col-xl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, { subSectionId: "change-status-report" }, _createSlots({ _: 2 }, [
    (_ctx.editState)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'file-chart-line'],
                      "fixed-width": ""
                    })
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('change_status_report')), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_validation_observer, {
                  id: "change-status-report-filters",
                  ref: "validations",
                  autocomplete: "off",
                  tag: "form"
                }, {
                  default: _withCtx(({ handleSubmit }) => [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_date_input, {
                            inputId: "change-status-report-start-date",
                            name: _ctx.$t('start_date'),
                            modelValue: _ctx.editState.startDate,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.startDate) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_date_input, {
                            inputId: "change-status-report-end-date",
                            name: _ctx.$t('end_date'),
                            modelValue: _ctx.editState.endDate,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.endDate) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_select_input, {
                            selectId: "changeStatusReport-hospital",
                            name: _ctx.$t('hospital'),
                            modelValue: _ctx.editState.hospital,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.hospital) = $event)),
                            options: _ctx.filteredHospitalsByUser,
                            multiple: true,
                            placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.hospital)
                          }, null, 8, ["name", "modelValue", "options", "placeholder"])
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_select_input, {
                            selectId: "changeStatusReport-organ_type",
                            name: "Organ Type",
                            modelValue: _ctx.editState.organCode,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.organCode) = $event)),
                            options: _ctx.organOptions,
                            multiple: true,
                            placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.organCode)
                          }, null, 8, ["modelValue", "options", "placeholder"])
                        ])
                      ]),
                      _createVNode(_component_save_toolbar, {
                        class: "form-inline col-sm-12 action-row",
                        buttonClass: "btn btn-success",
                        ref: "generateReport",
                        savingText: _ctx.$t('saving_text'),
                        successText: _ctx.$t('success_text'),
                        defaultErrorText: _ctx.$t('default_error_text'),
                        onSave: ($event: any) => (handleSubmit(_ctx.generateReport))
                      }, {
                        label: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['far', 'file-chart-line'],
                            class: "mr-2"
                          }),
                          _createTextVNode(" Generate Change of Status Report ")
                        ]),
                        _: 2
                      }, 1032, ["savingText", "successText", "defaultErrorText", "onSave"])
                    ])
                  ]),
                  _: 1
                }, 512)
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}