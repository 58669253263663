export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "clinical_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinical Information"])},
        "exd-distribution-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution"])},
        "exd_reason-codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution Reason(s)"])},
        "exd-add-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Exceptional Distribution Reason"])},
        "exd-reason-details-other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution Other Reason"])},
        "exd-reason-details-travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution Travel Reason"])},
        "exd-reason-details-transmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increased Risk of Transmission"])},
        "clinical_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinical Data"])},
        "save_clinical_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Clinical Data"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
        "rh_factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RH Factor"])},
        "blood_type_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type Verified"])},
        "save_exceptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Exceptional Distribution"])},
        "save_measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Measurement"])},
        "measurement_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement Date"])},
        "measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurements"])},
        "new_measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Measurement"])},
        "selected_measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Measurement"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metric"])},
        "warning_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning: Normal range for weight is 1 to 225 kg"])},
        "warning_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning: Normal range for Height is 30 to 215 cm"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imperial"])},
        "weight_kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight (kg)"])},
        "height_cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height (cm)"])},
        "use_form_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use the form below to add a new Measurement"])},
        "create_measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Measurement"])}
      },
      "fr": {
        "clinical_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations cliniques généraux"])},
        "exd-distribution-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Exceptionnelle"])},
        "exd_reason-codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison(s) de Distribution Exceptionnelle"])},
        "exd-add-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une raison de distribution exceptionnelle"])},
        "exd-reason-details-other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Exceptionnelle Autre Raison"])},
        "exd-reason-details-travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison de Voyage de Distribution Exceptionnelle"])},
        "exd-reason-details-transmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risque Accru de Transmission"])},
        "clinical_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données cliniques"])},
        "save_clinical_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les données cliniques "])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe sanguin "])},
        "rh_factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe  RH"])},
        "blood_type_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe sanguin vérifié"])},
        "save_exceptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder la distribution exceptionelle"])},
        "save_measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les mensurations"])},
        "measurement_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de mensuration"])},
        "measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensurations"])},
        "new_measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveaux mensuration"])},
        "selected_measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuration sélectionné"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métrique"])},
        "warning_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement : Le poids normal se situe entre 1 et 225 kg"])},
        "warning_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement : La taille normale se situe entre 30 et 215 cm"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impérial"])},
        "weight_kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids (kg)"])},
        "height_cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille (cm)"])},
        "use_form_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisez le formulaire ci-dessous pour ajouter une nouveau mensuration"])},
        "create_measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mensuration"])}
      }
    }
  })
}
