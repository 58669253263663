<template>
  <div class="nav-block-wrap">
    <ul class="nav flex-column nav-block"
        :class="{ active: isActive }">
      <li class="nav-item">
        <span class="nav-link" >
          <router-link class="no-scroll" style="color: #2e2e2e !important" :to="organRoute">
            <font-awesome-icon class="nav-caret" :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" fixed-width @click.prevent="toggleNav" />
            {{ $t(`${organ}`) }}
          </router-link>
        </span>
        <template v-if="show">
          <ul class="nav flex-column">
            <li class="nav-item" v-if="!isTransplantCoordinator" @click="toggleSideNav">
              <a class="nav-link" :href="getUrl('donor-checklist')">{{ $t(`checklists`) }}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav">
              <a class="nav-link text-flat" :href="getUrl(isTransplantCoordinator ? 'allocation-offer-responses' : 'allocation-recommendation-listing')">{{ $t(`allocation_eoffer`) }}</a>
            </li>
          </ul>
        </template>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { i18nMessages } from '@/i18n';

@Component({
  ...i18nMessages([
    require('../../_locales/Organs.json'),
    require('../_locales/commonPatientShared.json'),
  ])
})
export default class SelectedDeceasedDonorOrganLinks extends Vue {
  @Getter('clientId', { namespace: 'deceasedDonors' }) clientId!: string|undefined;
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;

  @Prop() organId!: string;
  @Prop() organ!: string;
  @Prop() organCode!: string;
  @Prop() organRoute!: any;

  show = false;

  public toggleNav() {
    this.show = !this.show;
  }

  public mounted() {
    this.show = this.isActive;
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange() {
    this.show = this.isActive;
  }

  get isActive(): boolean {
    return this.organCode == this.$route.params.organ_code
      && this.organRoute.params.option == this.$route.params.option;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) {
      this.$emit("toggle");
    }
  }


  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.isActive) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'deceased-donor-organ', params: { organ_code: this.organCode }, hash: hash });
      return location.href;
    }
  }
}
</script>
