export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "discontinue_offer_to_selected_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue Offer(s) to Selected Recipients"])},
        "select_discontinue_details_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select discontinue details for recipient(s)"])},
        "response_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response Type"])},
        "reason_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason Category"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
        "discontinue_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue Offer(s)"])},
        "re_allocated_to_non_intended_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Allocated to Non-Intended Recipient"])},
        "offerdwl.error.statereasonmismatch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR does not accept this reason as valid for ", _interpolate(_named("action_type")), ". Please select another reason"])},
        "offerdwl.error.offerhistoryupdate.statemissingreasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR does not accept this reason as valid for ", _interpolate(_named("action_type")), ". Please select another reason"])},
        "action_types": {
          "W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["withdrawal"])},
          "C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancellation"])}
        },
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
      },
      "fr": {
        "discontinue_offer_to_selected_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompre les offres pour les receveurs sélectionnés"])},
        "select_discontinue_details_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner les détails de l’interruption pour les receveurs"])},
        "response_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de réponse"])},
        "reason_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de motif"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif"])},
        "discontinue_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompre les offres"])},
        "re_allocated_to_non_intended_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ré-attribué à un destinataire non prévu"])},
        "offerdwl.error.statereasonmismatch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de synchroniser le retrait de l'offre au CTR pour ", _interpolate(_named("action_type")), ". Il y a une discordance entre l'état de l'offre et la raison."])},
        "offerdwl.error.offerhistoryupdate.statemissingreasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de synchroniser le retrait de l'offre au CTR pour ", _interpolate(_named("action_type")), ". État de l'offre Retraité doit avoir des motifs de l'état de l'offre"])},
        "action_types": {
          "W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["retrait"])},
          "C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annulation"])}
        },
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])}
      }
    }
  })
}
