import axios from 'axios';
import { ActionTree } from 'vuex';
import { SaveResult } from '@/types';
import { prefixErrors } from '@/utils';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import {
  OrganDetails,
  LiverMeldLab,
  LiverPeldLab,
  LiverHccLab,
  LiverDiseaseLab,
  VadProcedure,
  HistoryLiverSmc,
} from '@/store/organSpecificDetails/types';
import { buildErrorResult } from '@/utils';

export const actions: ActionTree<OrganDetails, RootState> = {
  saveOrganDetail({ commit, getters }, { recipientId, journeyId, organDetail }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      if (organDetail) {
        const url: string = APIRoute(EP.recipients.organ_specific_details.update, [[':recipientId', recipientId], [':id', journeyId]]);
        axios.patch(url, organDetail).then((response: any) => {
          const isSuccessful = response.data && !response.data.errors;
          if (isSuccessful) {
            // Handle successful response
            resolve({ success: true, responseData: response.data });
          } else if (response.data && response.data.errors) {
            // Handle server-side validation errors
            const validationErrors = prefixErrors(response.data.errors, 'organ_specific_details');
            reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: validationErrors });
          }
        }).catch((error: any) => {
          // Handle generic errors
          reject({ success: false, errorMessages: [error.message] });
        });
      } else {
        reject();
      }
    });
  },
  saveLiverSmcResult({ commit }, { recipientId, journeyId, payload }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const url: string = APIRoute(EP.recipients.journeys.organ_specific_details.liver_smc_result.update, [[':recipient_id', recipientId], [':journey_id', journeyId]]);
      axios.patch(url, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const validationErrors = prefixErrors(response.data.errors, 'liver_smc_result');
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: validationErrors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveOverridePaediatricAge({ commit }, { recipientId, journeyId, payload }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const url: string = APIRoute(EP.recipients.journeys.organ_specific_details.overridePaediatricAge.update, [[':recipient_id', recipientId], [':journey_id', journeyId]]);
      axios.patch(url, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const validationErrors = prefixErrors(response.data.errors, 'override_paediatric_age');
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: validationErrors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveAlcoholicLiverDiseaseProgram({ commit }, { recipientId, journeyId, aldProgram }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Ensure we have something to send
      if (aldProgram) {
        // Prepare information to send
        let method: any;
        let ep: string;
        // Payload to send
        const payload = { alcoholic_liver_disease_program: aldProgram };
        // If we have an id we're updating
        if (aldProgram._id) {
          method = axios.patch;
          ep = APIRoute(EP.recipients.journeys.organ_specific_details.alcoholic_liver_disease_programs.update, [[':recipientId', recipientId], [':journeyId', journeyId], [':id', aldProgram._id.$oid]]);
        } else {
          method = axios.post;
          ep = APIRoute(EP.recipients.journeys.organ_specific_details.alcoholic_liver_disease_programs.create, [[':recipientId', recipientId], [':journeyId', journeyId]]);
        }
        // Send asynchronously
        method(ep, payload).then((response: any) => {
          const isSuccessful = response.data && !response.data.errors;
          // Check if the update was successful
          if (isSuccessful) {
            // Handle successful response
            resolve({ success: true, responseData: response.data });
          } else if (response.data && response.data.errors) {
            // If we get a single error back
            if (typeof response.data.errors === 'string') {
              reject({ success: false , errorMessages: [response.data.errors], validationErrors: response.data.errors });
            }
            // Handle server-side validation errors
            reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
          }
        }).catch((error: any) => {
          // Handle generic errors
          reject({ success: false, errorMessages: [error.message] });
        });
      } else {
        reject();
      }
    });
  },
  loadAlcoholicLiverDiseaseProgram({ commit }, { recipientId, journeyId }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.organ_specific_details.alcoholic_liver_disease_programs.index, [[':recipientId', recipientId], [':journeyId', journeyId]]);
      axios.get(url).then((response: any) => {
        commit('setAlcoholicLiverDiseasePrograms', response.data.alcoholic_liver_disease_programs);
        resolve(response);
      }).catch((error) => {
        console.warn(error);
        reject();
      });
    });
  },
   loadPancreasInfusions({ commit }, { recipientId, journeyId }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.procedure_pancreas_islets_infusions.index, [[':recipientId', recipientId], [':journeyId', journeyId]]);
      axios.get(url).then((response: any) => {
        commit('setPancreasInfusions', response.data.procedure_pancreas_islets_infusions);
        resolve(response);
      }).catch((error) => {
        console.warn(error);
        reject();
      });
    });
  },
  savePancreasInfusion({ commit }, { id, recipientId, infusion }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { procedure_pancreas_islets_infusion: infusion } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.procedure_pancreas_islets_infusions.update, [[':recipientId', recipientId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.procedure_pancreas_islets_infusions.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          const result = buildErrorResult(response.data.errors, 'pancreas_islets_infusions');
          reject(result);
        }
      }).catch((error: any) => {
        // Handle generic errors
        const result = buildErrorResult(error, 'pancreas_islets_infusions');
        reject(result);
      });
    });
  },
  loadHeartMeasurements({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.heart_measurements.index, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        commit('setHeartMeasurements', response.data.heart_measurements);
        resolve(response);
      }).catch((error) => {
        console.warn(error);
        reject();
      });
    });
  },
  saveHeartMeasurement({ commit }, { id, recipientId, heartMasurement }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { heart_measurements: heartMasurement } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.heart_measurements.update, [[':recipientId', recipientId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.heart_measurements.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          const validationErrors = prefixErrors(response.data.errors, 'heart_measurement');
          // Handle server-side validation errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: validationErrors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadThoracicMeasurements({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.thoracic_measurements.index, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        commit('setThoracicMeasurements', response.data.thoracic_measurements);
        resolve(response);
      }).catch((error) => {
        console.warn(error);
        reject();
      });
    });
  },
  saveThoracicMeasurement({ commit }, { id, recipientId, thoracicMeasurement }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { thoracic_measurements: thoracicMeasurement } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.thoracic_measurements.update, [[':recipientId', recipientId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.thoracic_measurements.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          const validationErrors = prefixErrors(response.data.errors, 'thoracic_measurement');
          // Handle server-side validation errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: validationErrors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadHemodynamicInfo({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.heart_hemodynamic.index, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        // Note: we must preserve the API-side sorting, so here we should save the array 'as is'
        commit('setHemodynamicInfo', response.data.heart_hemodynamics);
        resolve(response);
      }).catch((error) => {
        console.warn(error);
        reject();
      });
    });
  },
  saveHemodynamicInfo({ commit }, { id, recipientId, hemodynamic }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { heart_hemodynamic: hemodynamic } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.heart_hemodynamic.update, [[':recipientId', recipientId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.heart_hemodynamic.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          const validationErrors = prefixErrors(response.data.errors, 'heart_hemodynamic');
          // Handle server-side validation errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: validationErrors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadHistoryLiverSmc({ commit }, { recipientId, journeyId }): Promise<void> {
    return new Promise<void>((resolve) => {
      const url = APIRoute(EP.recipients.journeys.historyLiverSmcs.index, [[':recipientId', recipientId], [':journeyId', journeyId]]);
      axios.get(url).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        const liverSmc = response.data.history_liver_smcs || [];
        commit('setHistoryLiverSmc', liverSmc);
        // Resolve promise successfully
        resolve();
      });
    });
  },
  loadLiverCriteriaDecisions({ commit }, { recipientId, journeyId }): Promise<void> {
    return new Promise<void>((resolve) => {
      const url = APIRoute(EP.recipients.journeys.organ_specific_details.liver_criteria_decisions.index, [[':recipient_id', recipientId], [':journey_id', journeyId]]);
      axios.get(url).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        const liverCriteriaDecisions = response.data.significant_event_decision || [];
        commit('setHistoryCriteriaDecisions', liverCriteriaDecisions);
        // Resolve promise successfully
        resolve();
      });
    });
  },
  loadLiverMelds({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.liverMeldLabs.index, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        const rawLiverMeldLabs: any[] = response.data.liver_meld_labs;
        const sanitizedLiverMeldLabs = rawLiverMeldLabs.map((rawLiverMeldLab: LiverMeldLab) => {
          return {
            _id: rawLiverMeldLab._id,
            _type: rawLiverMeldLab._type,
            test_date: rawLiverMeldLab.test_date,
            test_code: rawLiverMeldLab.test_code,
            comments: rawLiverMeldLab.comments,
            created_at: rawLiverMeldLab.created_at,
            expiry_date: rawLiverMeldLab.expiry_date,
            inr: rawLiverMeldLab.inr,
            na_meld: rawLiverMeldLab.na_meld,
            on_dialysis: rawLiverMeldLab.on_dialysis,
            serum_bilirubin: rawLiverMeldLab.serum_bilirubin,
            serum_creatinine: rawLiverMeldLab.serum_creatinine,
            serum_sodium: rawLiverMeldLab.serum_sodium,
          };
        });
        commit('setLiverMelds', sanitizedLiverMeldLabs);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  saveSodiumMeld({ getters }, { id, recipientId, sodiumMeld }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { liver_meld_lab: sodiumMeld } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.liverMeldLabs.update, [[':recipientId', recipientId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.liverMeldLabs.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const validationErrors = prefixErrors(response.data.errors, 'liver_meld_lab');
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadLiverPelds({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.liverPeldLabs.index, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        const rawLiverPeldLabs: any[] = response.data.liver_peld_labs;
        const sanitizedLiverPeldLabs = rawLiverPeldLabs.map((rawLiverPeldLab: LiverPeldLab) => {
          return {
            _id: rawLiverPeldLab._id,
            _type: rawLiverPeldLab._type,
            test_code: rawLiverPeldLab.test_code,
            comments: rawLiverPeldLab.comments,
            recipient_id: rawLiverPeldLab._id,
            test_date: rawLiverPeldLab.test_date,
            serum_bilirubin: rawLiverPeldLab.serum_bilirubin,
            inr: rawLiverPeldLab.inr,
            albumin: rawLiverPeldLab.albumin,
            growth_failure: rawLiverPeldLab.growth_failure,
            peld_score: rawLiverPeldLab.peld_score,
            expiry_date: rawLiverPeldLab.expiry_date
          };
        });
        commit('setLiverPelds', sanitizedLiverPeldLabs);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  saveSodiumPeld({ getters }, { id, recipientId, sodiumPeld, liverScoreJourneyId }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      // NOTE: Liver Score Journey ID is needed to handle edge cases where recipient has multiple liver journeys (see APS-198)
      const payload = {
        liver_peld_lab: sodiumPeld,
        liver_score_journey_id: liverScoreJourneyId,
      } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.liverPeldLabs.update, [[':recipientId', recipientId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.liverPeldLabs.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const result = buildErrorResult(response.data.errors, 'liver_peld_lab');
          reject(result);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadLiverHccs({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.liverHccLabs.index, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        const rawLiverHccLabs: any[] = response.data.liver_hcc_labs;
        const sanitizedLiverHccLabs = rawLiverHccLabs.map((rawLiverHccLab: LiverHccLab) => {
          return {
            _id: rawLiverHccLab._id,
            _type: rawLiverHccLab._type,
            test_date: rawLiverHccLab.test_date,
            expiry_date: rawLiverHccLab.expiry_date,
            created_at: rawLiverHccLab.created_at,
            afp: rawLiverHccLab.afp,
            ttv: rawLiverHccLab.ttv,
            number_of_nodules: rawLiverHccLab.number_of_nodules,
            largest_nodule_size: rawLiverHccLab.largest_nodule_size,
            total_nodules_size: rawLiverHccLab.total_nodules_size,
            criteria_status: rawLiverHccLab.criteria_status,
          };
        });
        commit('setLiverHccs', sanitizedLiverHccLabs);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  saveHcc({ getters }, { id, recipientId, hcc, liverScoreJourneyId }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      // NOTE: Liver Score Journey ID is needed to handle edge cases where recipient has multiple liver journeys (see APS-198)
      const payload = {
        liver_hcc_lab: hcc,
        liver_score_journey_id: liverScoreJourneyId,
      } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.liverHccLabs.update, [[':recipientId', recipientId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.liverHccLabs.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const result = buildErrorResult(response.data.errors, 'liver_hcc_lab');
          reject(result);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadLiverBridgings({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.liver_bridging_procedures.index, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        commit('setLiverBridgings', response.data.liver_bridging_procedures);
        resolve(response);
      }).catch((error) => {
        reject();
      });
    });
  },
  saveLiverBridging({ commit, getters }, { id, recipientId, liver_bridging }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { procedure_liver_bridging: liver_bridging } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.liver_bridging_procedures.update, [[':recipientId', recipientId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.liver_bridging_procedures.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const validationErrors = prefixErrors(response.data.errors, 'procedure_liver_bridging');
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadLiverDownstagings({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.liver_downstaging_procedures.index, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        commit('setLiverDownstagings', response.data.liver_downstaging_procedures);
        resolve(response);
      }).catch((error) => {
        reject();
      });
    });
  },
  saveLiverDownstaging({ commit, getters }, { id, recipientId, liver_downstaging }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { procedure_liver_downstaging: liver_downstaging } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.liver_downstaging_procedures.update, [[':recipientId', recipientId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.liver_downstaging_procedures.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const validationErrors = prefixErrors(response.data.errors, 'procedure_liver_downstaging');
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveVadInfo({ commit }, { id, recipientId, vadInfo }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { procedure_vad: vadInfo } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.procedure_vad.update, [[':recipientId', recipientId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.procedure_vad.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          const validationErrors = response.data.errors;
          // Handle server-side validation errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: validationErrors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadVadInfo({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.procedure_vad.index, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        const vadInfo: VadProcedure[] = response.data.vad_procedures;
        commit('setVadProcedures', vadInfo);
        resolve(response);
      }).catch((error) => {
        console.warn(error);
        reject();
      });
    });
  },
};
