export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "clinical_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinical Information"])},
        "save_clinical_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Clinical Information"])},
        "warning_negative_tlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculated TLC is negative. Please confirm Age and Height values."])},
        "not_enough_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Enough Information"])},
        "cannot_calculate_tlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot calculate TLC: "])},
        "sex_unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Sex' is 'Unknown'"])},
        "height_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Height' is missing"])},
        "age_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Age' is missing"])}
      },
      "fr": {
        "clinical_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations Cliniques"])},
        "save_clinical_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les Informations Cliniques"])},
        "warning_negative_tlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculated TLC is negative. Please confirm Age and Height values."])},
        "not_enough_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Enough Information"])},
        "cannot_calculate_tlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot calculate TLC: "])},
        "sex_unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Sex' is 'Unknown'"])},
        "height_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Height' is missing"])},
        "age_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Age' is missing"])}
      }
    }
  })
}
