export interface UtilitiesState {
}

export interface TagObject {
  text: string;
  code?: number|string;
  expired_date?: string;
  disabled?: boolean;
  tiClasses?: string[];
  hint?: string;
}

export interface FormatDefinition {
  DISPLAY_DATE: string;
  DATE_TIME_FORMAT: string;
  FORMATTED_DATE: string;
  TIME_MASK: string;
  TELEPHONE_MASK: string;
  TIME_DISPLAY: string;
  TIME_ARIA_DISPLAY: string;
}

export const Format = (dateFormat: string|null): FormatDefinition => {
  const isoFormat = {
    TIME_MASK: '##:##',
    TIME_DISPLAY: '-- : --',
    TIME_ARIA_DISPLAY: 'Entry pattern is twenty-four hour time with two digits for the hour and two digits for the minutes',
    TELEPHONE_MASK: '(XXX) XXX-XXXX',
    DATE_TIME_FORMAT: 'yyyy-MM-dd HH:mm',
    FORMATTED_DATE: 'yyyy, MMM, d',
    DISPLAY_DATE: 'yyyy-MM-dd',
  };

  const enFormat = {
    TIME_MASK: '##:##',
    TIME_DISPLAY: '-- : --',
    TIME_ARIA_DISPLAY: 'Entry pattern is twenty-four hour time with two digits for the hour and two digits for the minutes',
    TELEPHONE_MASK: '(XXX) XXX-XXXX',
    DATE_TIME_FORMAT: 'dd-MM-yyyy HH:mm',
    FORMATTED_DATE: 'd MMM, yyyy',
    DISPLAY_DATE: 'dd-MM-yyyy',
  };

  switch (dateFormat) {
    case 'iso':
      return isoFormat;
    case 'en':
      return enFormat;
    default:
      return isoFormat; // return iso Format as default
  }
};
