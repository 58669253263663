export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "eoffer_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eOffer Responses"])},
        "loading_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["... loading allocation ..."])},
        "allocation_e_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation / eOffer"])},
        "table": {
          "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
          "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
          "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
          "select_all_matching_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all matching items"])}
        },
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading..."])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
        "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle selection of this row"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select&nbsp;All"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "hsp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSP"])},
        "Hepatitis B Core Antibody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HepBcAb"])},
        "Hepatitis B Surface Antigen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HepBsAg"])},
        "Hepatitis C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HCV"])},
        "ctr_unavailable_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canadian Transplant Registry connection failed"])},
        "special_consideration": {
          "allocation_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("allocationType")), " Allocation"])},
          "double_kidney": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Double Kidney ", _interpolate(_named("allocationType"))])},
          "ecd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECD"])},
          "manually_added": {
            "recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recipient <strong>", _interpolate(_named("recipientId")), "</strong> manually added"])},
            "out_of_province_program": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["OOP <strong>", _interpolate(_named("hospitalName")), "</strong> manually added"])}
          },
          "expedited_allocation": {
            "missing_hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expedited Allocation: Missing donor HLA Test Results"])},
            "missing_virology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expedited Allocation: Missing or undetermined donor Virology Test Results"])},
            "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expedited Allocation"])}
          },
          "ctr": {
            "ipos": {
              "hsp": {
                "runHSPMatchOnDonor": {
                  "data": {
                    "error": {
                      "invalidNationalID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])},
                      "patientnotexist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])},
                      "StringFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])}
                    }
                  },
                  "error": {
                    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Unavailable: Generic Error"])}
                  },
                  "hsp": {
                    "missingTest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: Missing Donor Serology (", _interpolate(_named("parsedValue")), ")"])},
                    "invalidSerologyResult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: Invalid Donor Serology (", _interpolate(_named("parsedValue")), ")"])},
                    "pendingFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: Pending Donor Serology (", _interpolate(_named("parsedValue")), ")"])}
                  },
                  "vxm": {
                    "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Unavailable: HLA results are incomplete"])},
                    "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Unavailable: HLA results are not confirmed"])}
                  }
                },
                "getHSPMatchforDonor": {
                  "data": {
                    "error": {
                      "invalidNationalID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])},
                      "patientnotexist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])},
                      "StringFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])}
                    }
                  },
                  "patient": {
                    "error": {
                      "patientType": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])}
                    }
                  }
                },
                "getOfferDonorInfo": {
                  "data": {
                    "error": {
                      "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Unavailable: No offers from donor"])},
                      "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Unavailable: National Donor ID missing"])}
                    }
                  }
                },
                "recipients_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Allocation is run without <strong>HSP</strong> Recipients from CTR"])},
                "connectivity_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure to Download CTD Number. This allocation is run without HSP recipients from CTR"])},
                "unknown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: ", _interpolate(_named("errorMessage"))])},
                "unavailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: ", _interpolate(_named("ctrUnavailableMessage"))])}
              },
              "hsh": {
                "runHsHeartMatch": {
                  "data": {
                    "error": {
                      "invalidNationalID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])},
                      "patientnotexist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])},
                      "StringFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])}
                    }
                  },
                  "error": {
                    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Unavailable: Generic Error"])}
                  },
                  "vxm": {
                    "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Unavailable: HLA results are incomplete"])},
                    "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Unavailable: HLA results are not confirmed"])}
                  }
                },
                "getHsHeartMatchResults": {
                  "data": {
                    "error": {
                      "invalidNationalID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])},
                      "patientnotexist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])},
                      "StringFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])}
                    }
                  },
                  "patient": {
                    "error": {
                      "patientType": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: National Donor ID invalid (", _interpolate(_named("parsedValue")), ")"])}
                    }
                  }
                },
                "getOfferDonorInfo": {
                  "data": {
                    "error": {
                      "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Unavailable: No offers from donor"])},
                      "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Unavailable: National Donor ID missing"])}
                    }
                  }
                },
                "recipients_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Allocation is run without <strong>HSH</strong> Recipients from CTR"])},
                "connectivity_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure to Download CTD Number. This allocation is run without HSH recipients from CTR"])},
                "unknown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: ", _interpolate(_named("errorMessage"))])},
                "unavailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR Unavailable: ", _interpolate(_named("ctrUnavailableMessage"))])}
              }
            }
          }
        },
        "validation": {
          "messages": {
            "no_ctr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expedited Allocation: National Donor ID missing"])}
          }
        },
        "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
        "provincial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincial"])},
        "LOCAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
        "PROVINCIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincial"])},
        "LIVING_ANONYMOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donor"])},
        "Recipient's current offer cannot be accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User conflict error; unable to save changes"])},
        "Recipient's current offer cannot be declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User conflict error; unable to save changes"])},
        "Recipient's current offer cannot be extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User conflict error; unable to save changes"])},
        "An internal server error occurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User conflict error; unable to save changes"])}
      },
      "fr": {
        "eoffer_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses à eOffer"])},
        "loading_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["… chargement de l’attribution en cours…"])},
        "allocation_e_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribution/eOffer"])},
        "table": {
          "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
          "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
          "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])},
          "select_all_matching_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner tous les éléments correspondants"])}
        },
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chargement en cours…"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
        "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la sélection de cette ligne"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner tout"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
        "hsp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSP"])},
        "Hepatitis B Core Antibody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HepBcAb"])},
        "Hepatitis B Surface Antigen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HepBsAg"])},
        "Hepatitis C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HCV"])},
        "ctr_unavailable_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la connexion au Registre canadien des transplantations"])},
        "special_consideration": {
          "allocation_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'attribution  ", _interpolate(_named("allocationType"))])},
          "double_kidney": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Double Kidney ", _interpolate(_named("allocationType"))])},
          "ecd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECD"])},
          "manually_added": {
            "recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patient <strong>", _interpolate(_named("recipientId")), "</strong> a été ajouté manuellement"])},
            "out_of_province_program": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["OOP <strong>", _interpolate(_named("hospitalName")), "</strong> a été ajouté manuellement"])}
          },
          "expedited_allocation": {
            "missing_hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribution accélérée: résultats du test HLA du donneur manquants"])},
            "missing_virology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribution accélérée: résultats des tests de virologie des donneurs manquants ou indéterminés"])},
            "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribution accélérée"])}
          },
          "ctr": {
            "ipos": {
              "hsp": {
                "runHSPMatchOnDonor": {
                  "data": {
                    "error": {
                      "invalidNationalID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])},
                      "patientnotexist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])},
                      "StringFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])}
                    }
                  },
                  "error": {
                    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Unavailable: Generic Error"])}
                  },
                  "hsp": {
                    "missingTest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Sérologie du donneur manquant (", _interpolate(_named("parsedValue")), ")"])},
                    "invalidSerologyResult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Sérologie du donneur non-valide (", _interpolate(_named("parsedValue")), ")"])},
                    "pendingFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : En attente de la sérologie du donneur (", _interpolate(_named("parsedValue")), ")"])}
                  },
                  "vxm": {
                    "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR indisponible : Les résultats HLA sont incomplets"])},
                    "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR indisponible : Les résultats HLA ne sont pas confirmés"])}
                  }
                },
                "getHSPMatchforDonor": {
                  "data": {
                    "error": {
                      "invalidNationalID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])},
                      "patientnotexist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])},
                      "StringFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])}
                    }
                  },
                  "patient": {
                    "error": {
                      "patientType": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])}
                    }
                  }
                },
                "getOfferDonorInfo": {
                  "data": {
                    "error": {
                      "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR indisponible : Aucune offre de ce donneur"])},
                      "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR indisponible : Numéro d'identification national du donateur manquant"])}
                    }
                  }
                },
                "recipients_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette attribution se fait sans <strong>HSP</strong> patients de CTR"])},
                "connectivity_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec du téléchargement du numéro CTD. Cette allocation est exécutée sans HSP patients de CTR"])},
                "unknown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : ", _interpolate(_named("errorMessage"))])},
                "unavailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible: ", _interpolate(_named("ctrUnavailableMessage"))])}
              },
              "hsh": {
                "runHsHeartMatch": {
                  "data": {
                    "error": {
                      "invalidNationalID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])},
                      "patientnotexist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])},
                      "StringFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])}
                    }
                  },
                  "error": {
                    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Unavailable: Generic Error"])}
                  },
                  "vxm": {
                    "hlaNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR indisponible : Les résultats HLA sont incomplets"])},
                    "hlaNotConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR indisponible : Les résultats HLA ne sont pas confirmés"])}
                  }
                },
                "getHsHeartMatchResults": {
                  "data": {
                    "error": {
                      "invalidNationalID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])},
                      "patientnotexist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])},
                      "StringFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])}
                    }
                  },
                  "patient": {
                    "error": {
                      "patientType": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : Le numéro national du donneur n'est pas valide (", _interpolate(_named("parsedValue")), ")"])}
                    }
                  }
                },
                "getOfferDonorInfo": {
                  "data": {
                    "error": {
                      "noProvincialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR indisponible : Aucune offre de ce donneur"])},
                      "missingNationalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR indisponible : Numéro d'identification national du donateur manquant"])}
                    }
                  }
                },
                "recipients_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette attribution se fait sans <strong>HSH</strong> patients de CTR"])},
                "connectivity_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec du téléchargement du numéro CTD. Cette allocation est exécutée sans HSH patients de CTR"])},
                "unknown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible : ", _interpolate(_named("errorMessage"))])},
                "unavailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CTR indisponible: ", _interpolate(_named("ctrUnavailableMessage"))])}
              }
            }
          }
        },
        "validation": {
          "messages": {
            "no_ctr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribution accélérée: Numéro d'identification national du donateur manquant"])}
          }
        },
        "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["local"])},
        "provincial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["provincial"])},
        "LOCAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
        "PROVINCIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincial"])},
        "LIVING_ANONYMOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneur vivant"])},
        "Recipient's current offer cannot be accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de conflit d'utilisateur ; impossible de sauvegarder les changements"])},
        "Recipient's current offer cannot be declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de conflit d'utilisateur ; impossible de sauvegarder les changements"])},
        "Recipient's current offer cannot be extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de conflit d'utilisateur ; impossible de sauvegarder les changements"])},
        "An internal server error occurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de conflit d'utilisateur ; impossible de sauvegarder les changements"])}
      }
    }
  })
}
