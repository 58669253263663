import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { ObjectId } from '@/store/types';

export interface LabsState {
  virologies: LabVirology[];
  selectedVirology?: LabVirology;
  hlaAntibodies: LabHLAAntibody[];
  selectedHlaAntibodies?: LabHLAAntibody;
  selectedHlaSerumSummaryReview?: LabHLAAntibody;
  hlaTyping?: LabHLATypingRecipient|LabHlaTypingDonor;
  crossmatchLabs?: LabCrossmatch[];
  selectedCrossmatch?: LabCrossmatch;
  selectedCrossmatchSample?: CrossmatchRecipientSample;
}

export interface Lab {
  _id?: ObjectId;
  _type?: string;
  test_date?: string|null;
  test_code?: string;
  comments?: string;
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
}

export interface LabVirology {
  _id?: { $oid?: string };
  donor_id?: number;
  recipient_id?: number;
  _type?: string;
  lab_id?: ObjectId|null;
  lab_other_code?: string|null;
  lab_code?: string|null;
  lab_other?: string|null;
  test_date?: string|null;
  test_code?: string;
  sample_date?: string|null;
  sample_number?: string;
  sample_type?: string|null;
  from_mother?: boolean;
  comments?: string;
  results?: LabVirologyResult[];
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
  virology_comments?: string;
}

export interface LabHLATypingRecipient {
  _id?: ObjectId;
  recipient_id?: ObjectId;
  lab_code?: string|null;
  lab_id?: { $oid?: string }|null;
  updated_by_lab_id?: ObjectId;
  updated_by_user_id?: string;
  updated_at?: string;
  updated_by?: string;
  comments?: string;
  antigens?: LabHlaTypingAntigen[];
  epitopes?: LabHlaTypingEpitope[];
  typing_incomplete?: boolean;
  test_date?: string|null;
  initial_lab_code?: string;
  initial_lab_id?: ObjectId;
  initial_laboratory?: InitialLaboratory;
}

export interface InitialLaboratory {
  lab_code: string;
  lab_type: string;
  name: string;
}

export interface LabOther {
  recipient_id: number;
  result_value: string | null;
  tbd: string;
}

// Schema for HLA Antibody Lab Result source API document
export interface LabHLAAntibody {
  _id?: ObjectId;
  recipient_id?: number;
  lab_code?: string|null;
  sample_date?: string|null;
  antibodies_confirmed?: boolean;
  effective_date?: string;
  expired_date?: string;
  test_date?: string|null;
  comments?: string;
  test_code?: string;
  antibodies?: HlaAntibodyData;
  cpra?: number|null;
  cpra_class1?: number|null;
  cpra_class2?: number|null;
  hla_class1_testing_method_code?: number|null;
  hla_class2_testing_method_code?: number|null;
  hla_class1_test_kit_code?: string|null;
  hla_class2_test_kit_code?: string|null;
}

export interface HlaAntibodyData {
  class1: HlaAntibodyClass;
  class2: HlaAntibodyClass;
}

export interface HlaAntibodyClass {
  antibodies: HlaAntibodyArrays;
  epitopes?: HlaEpitopeArrays;
}

export interface HlaAntibodyArrays {
  acceptable?: string[];
  unacceptable_allele_group?: string[];
  unacceptable_allele_specific?: string[];
  unacceptable_alpha_beta?: string[];
  indeterminate_allele_group?: string[];
  indeterminate_allele_specific?: string[];
  indeterminate_alpha_beta?: string[];
  possible_allele_specific?: string[];
  untested?: string[];
}

export interface HlaEpitopeArrays {
  acceptable?: string[];
  unacceptable?: string[];
  indeterminate?: string[];
  untested?: string[];
}

// Schema for HLA Antibody Test form edit state in UI
export interface HLAAntibodiesForm {
  sample_code?: string;
  sample_draw_date?: string|null;
  testing_method_default?: number|null;
  testing_method_class1?: number|null;
  testing_method_class2?: number|null;
  testing_kit_class1?: string|null;
  testing_kit_class2?: string|null;
  hla_lab?: string|null;
  hla_lab_name?: string;
  lab_code?: string|null;
  sample_tested_date?: string|null;
  comments?: string;
  cpra_class1?: number|null;
  cpra_class2?: number|null;
  combined_cpra?: number|null;
  antibodies?: HlaAntibodyDataForm;
  invalidAntibodies: HlaAntibodyDataForm;
}

export interface HlaAntibodyDataForm {
  class1_acceptable?: string[];
  class1_unacceptable?: string[];
  class1_unacceptable_allele_group?: string[];
  class1_unacceptable_allele_specific?: string[];
  class1_unacceptable_alpha_beta?: string[];
  class1_indeterminate?: string[];
  class1_indeterminate_allele_group?: string[];
  class1_indeterminate_allele_specific?: string[];
  class1_indeterminate_alpha_beta?: string[];
  class1_allele_specific?: string[];
  class1_alpha_beta_specific?: string[];
  class1_possible_allele_specific?: string[];
  class2_acceptable?: string[];
  class2_unacceptable?: string[];
  class2_unacceptable_allele_group?: string[];
  class2_unacceptable_allele_specific?: string[];
  class2_unacceptable_alpha_beta?: string[];
  class2_indeterminate?: string[];
  class2_indeterminate_allele_group?: string[];
  class2_indeterminate_allele_specific?: string[];
  class2_indeterminate_alpha_beta?: string[];
  class2_allele_specific?: string[];
  class2_alpha_beta_specific?: string[];
  class2_possible_allele_specific?: string[];
  epitopes_acceptable?: string[];
  epitopes_unacceptable?: string[];
  epitopes_indeterminate?: string[];
  epitopes_untested?: string[];
}

export interface LabVirologyResult {
  code: string;
  positive?: boolean|null;
  initial_result?: string;
  comments: string|null;
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
}

export interface HlaSerologicalValue {
  value: string;
  tbc?: boolean;
}

export interface LabHlaTypingAntigen {
  _id?: ObjectId;
  sequence_number?: number;
  molecular_locus?: string;
  molecular_value?: string|null;
  serological_locus?: string;
  typing_technique_code?: number|null;
  most_likely_allele?: boolean;
  comments?: string|null;
  serological_values?: HlaSerologicalValue[]|null;
  override_mapping?: boolean;
}

export interface HlaAntibodyTag {
  locus?: string;
  gene?: string;
  allele?: string;
  standardText?: string;
  standardTextWithoutLocus?: string;
  standardTextWithoutAllele?: string;
  rawInput?: string;
  alleleGroup?: string;
  alleleProtein?: string;
  proteinSpecific?: string[];
  alphaBetaSpecific?: string[];
  epitopes?: string[];
  betaLocus?: string;
  mostRecentlyParsedLocus?: string;
}

export interface HlaTypingTag {
  locus?: string;
  allele?: string;
  protein?: string;
  suffix?: string;
  tbc?: boolean;
  standardText: string;
  standardAlleleGroupOnly?: string;
  standardAlleleSpecific?: string;
  standardAlleleText?: string;
  standardWithoutTbc?: string;
  standardMostLikelyAllele?: string|null;
}

// Enumerations

export enum HlaAntibodyTestingMethod {
  Storage = 1,
  Idpra = 2,
  Sab = 3
}

export enum HlaSabClass {
  Class1,
  Class2
}

export enum HlaSabCategory {
  Acceptable,
  Unacceptable,
  Indeterminate,
  AlleleSpecific,
  PossibleAlleleSpecific,
  Untested
}

export enum HlaTestingKit {
  Default = 'default'
}

export interface DonorVirologyResult {
  code?: string;
  test_type?: string;
  initial_result?: string;
  repeat_result?: string;
  confirmatory_comparative_result?: string;
  confirmatory_comparative_test_kit?: string;
  comments?: string;
}

export interface LabHlaTypingDonor {
  _id?: ObjectId;
  donor_id?: ObjectId;
  initial_lab_id?: ObjectId;
  initial_lab_code?: string;
  lab_id?: ObjectId;
  updated_by_lab_id?: ObjectId;
  updated_by_user_id?: string;
  lab_code?: string|null;
  comments?: string;
  antigens?: LabHlaTypingAntigen[];
  epitopes?: LabHlaTypingEpitope[];
  typing_incomplete?: boolean;
  test_date?: string|null;
  updated_at?: string;
  updated_by?: string;
  initial_laboratory?: InitialLaboratory;
}

export interface LabHlaTypingEpitope {
  epitope_value?: string;
  present_dictionary?: boolean|null;
  present?: boolean|null;
  override_date?: string|null;
  override_comment?: string;
  class_code?: number;
}

export interface LabCrossmatch extends Lab {
  crossmatch_date?: string|null;
  lab_code?: string|null;
  sample_type?: string;
  method_technique?: number;
  recipient_samples?: CrossmatchRecipientSample[];
}

export interface CrossmatchRecipientSample {
  _id?: ObjectId;
  // DIAG: client_id doesn't exist on the model but we use this field
  // to allow the user to send a client_id when creating/updating
  client_id?: string|null;
  recipient_id?: ObjectId;
  autologous_allogeneic?: string|null;
  test_date?: string|null;
  t_cell_response_indicator?: string;
  b_cell_response_indicator?: string;
  treatment?: string|null;
  pronase?: boolean;
  dsa?: string|null;
}

export enum VirologyType {
  Recipient,
  DeceasedDonor,
  LivingDonor,
}
