<template>
  <button @click.prevent
    :class="{ 'tooltip-form-spacer': isFormELement}"
    class="tooltip-toggle"
    v-tooltip="toolTipText" 
    v-tooltip.focus="toolTipText">
      <font-awesome-icon class="text-grey" :icon="['far', 'exclamation-circle']" fixed-width />
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class ToolTipControl extends Vue {
  @Prop({default: null}) toolTipText!: string|null;
  @Prop({default: null}) labelText!: string|null;
  @Prop({default: true}) isFormELement!: string|null;
  
  get getText(): string {
    return this.toolTipText || '';
  }
}
</script>

<style>
.tooltip-toggle {
  border: 0;
  padding: 0;
  background-color: transparent;
  vertical-align: middle;
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1rem !important; 
}

.tooltip-form-spacer {
  right: 1rem;
}

.tooltip-toggle svg {
  height: 15px;
}

.tooltip-toggle:focus-visible {
  outline: none;
}

.tooltip-toggle:focus-visible svg {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border-radius: 50%;
}

.p-tooltip { 
  opacity:0;
  transition:visibility 0.3s linear, opacity 0.3s linear;
  line-height: normal;
  text-decoration: none;
  /* position: absolute; */
  /* right: -1em; */
  color: #ffffff;
  padding: 0 !important;
      top: 1px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;

  /* top: 1.5rem; */
  outline: none;
  z-index: -9;
}
.p-tooltip-text {
  background: rgb(76, 80, 87) !important;
  border-radius: 6px !important;
  padding: 10px !important;
}

.p-tooltip-right .p-tooltip-arrow {
  left: -6px!important;
  scale: 3 !important;
  border-right-color: rgb(76, 80, 87) !important;
}

.p-tooltip-left .p-tooltip-arrow {
  right: -6px!important;
  scale: 3 !important;
  border-left-color: rgb(76, 80, 87) !important;
}


</style>
