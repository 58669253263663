export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "allocation_recommendation_listing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Recommendation Listing"])},
        "primary_offer_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Offer History"])},
        "backup_offer_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup Offer History"])},
        "no_offer_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Offer History"])},
        "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
        "organ_spec_offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Spec. Offered"])},
        "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response"])},
        "effective_rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])},
        "ctr_rank_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking in CTR"])},
        "effective_rank_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking in Afflo"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "medical_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Med. Status"])},
        "sec_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sec. Status"])},
        "hsp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSP"])},
        "allocation_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Points"])},
        "mpe_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sodium MELD"])},
        "province_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "abo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABO"])},
        "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
        "hospital_abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
        "listed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listed For"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
        "cpra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cPRA"])},
        "secondary_medical_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Medical Status"])},
        "recipientStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rec. Status"])},
        "rec_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rec. Status"])},
        "transplant_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIP"])},
        "with_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With selected"])},
        "make_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Offer(s)"])},
        "discontinue_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue Offer"])},
        "discontinue_selected_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue selected offer(s)"])},
        "link_recipient_responses_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Recipient in Offer Responses table"])},
        "cannot_mix_hsp_with_non_hsp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSP and Non-HSP offers must be done separately."])},
        "registration_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Type"])},
        "offer_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Responses"])},
        "opo_transplant_details_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Details"])},
        "opo-button-enabled-explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture Transplant Details for Open Offer to OPO"])},
        "opo-button-disabled-explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Post-Allocation checklist to enable entry of Transplant Details"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "allocation_recipients": {
          "no_recipients_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Recipients found"])}
        },
        "excluded_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluded Recipients"])},
        "loading_excluded_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading excluded recipients"])},
        "show_excluded_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show excluded recipients"])},
        "excluded_recipients_modal": {
          "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to load recipients"])},
          "table_columns": {
            "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])},
            "ctr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR ID"])},
            "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
            "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
            "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
            "excluded_reason_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluded Reason Code"])},
            "excluded_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluded Reason"])}
          }
        }
      },
      "fr": {
        "allocation_recommendation_listing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de prescription d’attribution"])},
        "primary_offer_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique de l’offre primaire"])},
        "backup_offer_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique de l’offre de réserve"])},
        "no_offer_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique de l’absence d’offre"])},
        "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre"])},
        "organ_spec_offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe spéc. offert"])},
        "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
        "effective_rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo"])},
        "ctr_rank_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking in CTR"])},
        "effective_rank_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking in Afflo"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
        "medical_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut méd."])},
        "sec_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut Sec."])},
        "hsp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient hyperimmunisé"])},
        "allocation_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points d'attribution"])},
        "mpe_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sodium MELD"])},
        "province_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "abo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système ABO"])},
        "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme"])},
        "hospital_abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe"])},
        "listed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrit sur la liste pour"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])},
        "cpra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cPRA (immunisation anti HLA)"])},
        "secondary_medical_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut médical secondaire"])},
        "recipientStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status de pat."])},
        "rec_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de Pat."])},
        "transplant_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP"])},
        "with_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec sélectionné"])},
        "make_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire une ou plusieurs offres"])},
        "discontinue_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinuer l'offre"])},
        "discontinue_selected_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompre l'offre/les offres sélectionnées"])},
        "link_recipient_responses_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers le receveur dans le tableau des réponses aux offres"])},
        "cannot_mix_hsp_with_non_hsp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les offres HSP et non HSP doivent être faites séparément."])},
        "registration_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'enregistrement"])},
        "offer_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses aux offres"])},
        "opo_transplant_details_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la transplantation"])},
        "opo-button-enabled-explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir les détails de la transplantation pour l'offre ouverte à l'OPO"])},
        "opo-button-disabled-explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez la liste de contrôle post-attribution pour permettre la saisie des détails de la transplantation."])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
        "allocation_recipients": {
          "no_recipients_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun patient trouvé"])}
        },
        "excluded_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients exclus"])},
        "loading_excluded_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des patients exclus en cours"])},
        "show_excluded_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les patients exclus"])},
        "excluded_recipients_modal": {
          "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de charger les patients"])},
          "table_columns": {
            "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo"])},
            "ctr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No CTR"])},
            "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
            "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
            "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe sanguin"])},
            "excluded_reason_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de raison d'exclusion"])},
            "excluded_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison de exclusion"])}
          }
        }
      },
      "en_tgln": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID"])},
        "effective_rank_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking in OATS"])},
        "excluded_recipients_modal": {
          "table_columns": {
            "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID"])}
          }
        }
      },
      "fr_tgln": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])},
        "effective_rank_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking in OATS"])},
        "excluded_recipients_modal": {
          "table_columns": {
            "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])}
          }
        }
      },
      "en_tq": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])},
        "excluded_recipients_modal": {
          "table_columns": {
            "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])}
          }
        }
      },
      "fr_tq": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])},
        "excluded_recipients_modal": {
          "table_columns": {
            "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])}
          }
        }
      }
    }
  })
}
