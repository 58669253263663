export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "transplant_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant in Progress"])},
        "no_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Transplant In Progress"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor ID"])},
        "donor_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Location"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient ID"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "organ_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Status"])},
        "organ_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
        "recipient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Name"])},
        "offer_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Date"])},
        "link_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Donor Page"])},
        "link_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Recipient Page"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIP"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse Panel"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize Panel"])}
      },
      "fr": {
        "transplant_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplantation en cours"])},
        "no_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun transplantation en cours"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Donneur"])},
        "donor_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu du donneur"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Patient"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "organ_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de l'organe"])},
        "organ_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe"])},
        "recipient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du patient"])},
        "offer_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de  l'offre"])},
        "link_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page des donneurs"])},
        "link_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page des patients"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEC"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau rabattu"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le panneau"])}
      }
    }
  })
}
