<template>
  <div class="card summary-card shadow-none skeleton-padding">
    <div class="card-header">
      <div class="row">
        <div class="col-sm-4 col-lg-3 col-xl-3">
          <span class="skeleton-box w-100" />
        </div>
        <div class="col-sm-4 col-lg-3 col-xl-3" />
        <div class="col-sm-4 col-lg-3 col-xl-2">
          <span class="skeleton-box w-100" />
        </div>          
        <div class="col-sm-4 col-lg-3 col-xl-2">
          <span class="skeleton-box w-100" />
        </div>  
        <div class="col-sm-4 col-lg-3 col-xl-2">
          <span class="skeleton-box w-100" />
        </div>  
      </div>
    </div>    
    <div class="row skeleton-padding-text">
      <div class="column col-sm-2 col-lg-1 col-xl-1">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" />
      </div>
      <div class="column col-sm-2 col-lg-1 col-xl-1">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" />                
      </div>
      <div class="column col-sm-2 col-lg-1 col-xl-1">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" />
      </div>
      <div class="column col-sm-2 col-lg-1 col-xl-1">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" />                
      </div>
      <div class="column col-sm-2 col-lg-1 col-xl-1">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" />
      </div>
      <div class="column col-sm-2 col-lg-1 col-xl-1">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" />
      </div>
        <div class="column col-sm-2 col-lg-1 col-xl-1">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" />
      </div>
      <div class="column col-sm-2 col-lg-1 col-xl-1">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" />                
      </div>     
      <div class="column col-sm-2 col-lg-1 col-xl-1">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" />
      </div>
      <div class="column col-sm-2 col-lg-1 col-xl-1">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" />                
      </div>
      <div class="column col-sm-2 col-lg-1 col-xl-1">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" />
      </div>
      <div class="column col-sm-2 col-lg-1 col-xl-1">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" />
      </div>  
    </div>
    <hr />  
    <div class="row skeleton-padding">
      <div class="summary-journey-item">
        <span class="skeleton-box w-100" style="height:200px;" />
      </div>
      <div class="summary-journey-item">
        <span class="skeleton-box w-100" style="height:200px;" />
      </div>       
      <div class="summary-journey-item">
        <span class="skeleton-box w-100" style="height:200px;" />
      </div>   
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class LoadingSummaryCard extends Vue {}
</script>
