import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = {
  key: 0,
  class: "standard-full-width-group"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = {
  key: 0,
  class: "standard-form-group-6column-xlarge-only"
}
const _hoisted_10 = {
  key: 1,
  class: "standard-form-group-6column-xlarge-only"
}
const _hoisted_11 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_15 = {
  key: 0,
  class: "row"
}
const _hoisted_16 = { class: "standard-form-group" }
const _hoisted_17 = { class: "standard-form-group" }
const _hoisted_18 = { class: "standard-form-group" }
const _hoisted_19 = {
  key: 1,
  class: "row"
}
const _hoisted_20 = { class: "standard-form-group" }
const _hoisted_21 = { class: "standard-form-group" }
const _hoisted_22 = { class: "standard-form-group" }
const _hoisted_23 = { class: "standard-form-group" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "standard-form-group" }
const _hoisted_26 = { class: "standard-form-group" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "form-group col-md-12 col-lg-12 col-xl-12" }
const _hoisted_29 = { class: "p-label" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "form-inline" }
const _hoisted_32 = { class: "row" }
const _hoisted_33 = { class: "form-group col-md-4 col-lg-4 col-xl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_boolean_radio_input = _resolveComponent("boolean-radio-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_other_input = _resolveComponent("select-other-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "donation-information",
    saveButtonText: `${_ctx.$t('save_donor_information')}`,
    ref: "saveDonationInformation",
    "lookups-to-load": _ctx.lookupsToLoad,
    saveButton: !_ctx.newDonor,
    disabled: _ctx.isSaveDisabled,
    onSave: _cache[21] || (_cache[21] = ($event: any) => (_ctx.savePatch())),
    onLoaded: _cache[22] || (_cache[22] = ($event: any) => (_ctx.loaded()))
  }, _createSlots({
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('donor_information')), 1)
    ]),
    _: 2
  }, [
    (_ctx.editState)
      ? {
          name: "body",
          fn: _withCtx(() => [
            _createElementVNode("fieldset", null, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_boolean_radio_input, {
                    "input-id": "donor-type",
                    ruleKey: "death.neurological_death",
                    labelName: _ctx.$t("donor_type"),
                    acceptId: true,
                    declineId: false,
                    acceptLabel: "NDD",
                    declineLabel: "DCD",
                    modelValue: _ctx.editState.donorType,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.donorType) = $event)),
                    disabled: !_ctx.hasWriteAccessGeneral
                  }, null, 8, ["labelName", "modelValue", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_select_input, {
                    "select-id": "donationInformation-ventilator_status",
                    ruleKey: "indicators.ventilator_status",
                    name: _ctx.$t("donationInformation-ventilator_status"),
                    options: _ctx.ventilatorStatusLookup,
                    modelValue: _ctx.editState.ventilatorStatus,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.ventilatorStatus) = $event)),
                    disabled: !_ctx.hasWriteAccessGeneral
                  }, null, 8, ["name", "options", "modelValue", "disabled"])
                ]),
                _createVNode(_component_select_other_input, {
                  "select-id": "donationInformation-ventilator_type",
                  ruleKey: "indicators.ventilator_type",
                  name: _ctx.$t("donationInformation-ventilator_type"),
                  validationId: "ventilator_type",
                  modelValue: _ctx.editState.ventilatorType,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.ventilatorType) = $event)),
                  options: _ctx.ventilatorTypeLookup,
                  onChange: _ctx.clearVentTypeOther,
                  disabled: !_ctx.hasWriteAccessGeneral,
                  otherTitle: _ctx.$t("donationInformation-ventilator_type_other"),
                  colStyling: "standard-form-group-with-other"
                }, {
                  other: _withCtx(() => [
                    _createVNode(_component_text_input, {
                      "input-id": "donationInformation-ventilator_type_other",
                      name: _ctx.$t("donationInformation-ventilator_type_other"),
                      ruleKey: "indicators.ventilator_type_other",
                      modelValue: _ctx.editState.ventilatorTypeOther,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.ventilatorTypeOther) = $event)),
                      disabled: !_ctx.hasWriteAccessGeneral
                    }, null, 8, ["name", "modelValue", "disabled"])
                  ]),
                  _: 1
                }, 8, ["name", "modelValue", "options", "onChange", "disabled", "otherTitle"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_checkbox_input, {
                    "input-id": "donationInformation-exceptional_distribution",
                    ruleKey: "indicators.exceptional_distribution",
                    modelValue: _ctx.editState.exceptional_distribution,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.exceptional_distribution) = $event)),
                    labelName: _ctx.$t("donationInformation-exceptional_distribution.title"),
                    label: _ctx.$t("affimative"),
                    validationId: "exceptional_distribution",
                    onChange: _ctx.changeEXDStatus,
                    disabled: !_ctx.hasWriteAccessGeneral
                  }, null, 8, ["modelValue", "labelName", "label", "onChange", "disabled"])
                ]),
                (_ctx.editState.exceptional_distribution)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_select_input, {
                        selectId: "donationInformation-exd_reason_codes",
                        rules: "required",
                        multiple: true,
                        name: _ctx.$t("donationInformation-exceptional_distribution.donationInformation-exd_reason_codes"),
                        label: _ctx.$t("donationInformation-exceptional_distribution.donationInformation-exd_reason_codes"),
                        disabled: !_ctx.hasWriteAccessGeneral,
                        placeholder: _ctx.$t('donationInformation-exceptional_distribution.donationInformation-add-reason'),
                        options: _ctx.exceptionalDistributionOptionsForDonor,
                        modelValue: _ctx.editState.exd_reason_codes,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.exd_reason_codes) = $event))
                      }, null, 8, ["name", "label", "disabled", "placeholder", "options", "modelValue"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.editState.exd_reason_codes!.includes(300))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_text_area_input, {
                        "input-id": "donationInformation-exd_reason_details_other",
                        ruleKey: "indicators.exd_reason_details_other",
                        name: _ctx.$t("donationInformation-exceptional_distribution.donationInformation-exd_reason_details_other"),
                        modelValue: _ctx.editState.exd_reason_details_other,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.exd_reason_details_other) = $event)),
                        disabled: !_ctx.hasWriteAccessGeneral
                      }, null, 8, ["name", "modelValue", "disabled"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.editState.exd_reason_codes!.includes(200))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_text_area_input, {
                        "input-id": "donationInformation-exd_reason_details_travel",
                        ruleKey: "indicators.exd_reason_details_travel",
                        name: _ctx.$t("donationInformation-exceptional_distribution.donationInformation-exd_reason_details_travel"),
                        modelValue: _ctx.editState.exd_reason_details_travel,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.exd_reason_details_travel) = $event)),
                        disabled: !_ctx.hasWriteAccessGeneral
                      }, null, 8, ["name", "modelValue", "disabled"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.editState.exd_reason_codes!.includes(406))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(_component_text_area_input, {
                        "input-id": "donationInformation-exd_reason_details_transmission",
                        ruleKey: "indicators.exd_reason_details_transmission",
                        name: _ctx.$t("donationInformation-exceptional_distribution.donationInformation-exd_reason_details_transmission"),
                        modelValue: _ctx.editState.exd_reason_details_transmission,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.exd_reason_details_transmission) = $event)),
                        disabled: !_ctx.hasWriteAccessGeneral
                      }, null, 8, ["name", "modelValue", "disabled"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_checkbox_input, {
                    "input-id": "donationInformation-post_release",
                    ruleKey: "indicators.post_release",
                    modelValue: _ctx.editState.postRelease,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.postRelease) = $event)),
                    labelName: _ctx.$t("donationInformation-post_release.title"),
                    label: _ctx.$t("affimative"),
                    disabled: !_ctx.hasWriteAccessAllocations || _ctx.editState.postReleaseReason
                  }, null, 8, ["modelValue", "labelName", "label", "disabled"])
                ]),
                (_ctx.postReleaseRequired)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createVNode(_component_text_input, {
                        "input-id": "donationInformation-post_release_reason",
                        ruleKey: "indicators.post_release_reason",
                        name: _ctx.$t("donationInformation-post_release.donationInformation-post_release_reason"),
                        modelValue: _ctx.editState.postReleaseReason,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.postReleaseReason) = $event)),
                        rules: { required: _ctx.editState.postRelease },
                        disabled: !_ctx.hasWriteAccessAllocations
                      }, null, 8, ["name", "modelValue", "rules", "disabled"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.postReleaseRequired)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_select_input, {
                        selectId: "donationInformation-results_received",
                        name: _ctx.$t("donationInformation-post_release.donationInformation-results_received"),
                        disabled: !_ctx.hasWriteAccessAllocations,
                        options: _ctx.yesNoOptions,
                        onChange: _ctx.clearResultsReceivedDate,
                        modelValue: _ctx.editState.resultsReceived,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.resultsReceived) = $event))
                      }, null, 8, ["name", "disabled", "options", "onChange", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_date_input, {
                        "input-id": "donationInformation-results_received_date",
                        name: _ctx.$t("donationInformation-post_release.donationInformation-results_received_date"),
                        modelValue: _ctx.editState.resultsReceivedDate,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.resultsReceivedDate) = $event)),
                        disabled: _ctx.editState.resultsReceived == null,
                        rules: { required: _ctx.editState.resultsReceived != null }
                      }, null, 8, ["name", "modelValue", "disabled", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_time_input, {
                        "input-id": "donationInformation-results_received_time",
                        name: _ctx.$t("donationInformation-post_release.donationInformation-results_received_time"),
                        modelValue: _ctx.editState.resultsReceivedTime,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.resultsReceivedTime) = $event)),
                        disabled: _ctx.editState.resultsReceived == null,
                        rules: { required: _ctx.editState.resultsReceived != null }
                      }, null, 8, ["name", "modelValue", "disabled", "rules"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.postReleaseRequired)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createVNode(_component_select_input, {
                        selectId: "donationInformation-results_reported",
                        name: _ctx.$t("donationInformation-post_release.donationInformation-results_reported"),
                        options: _ctx.yesNoOptions,
                        onChange: _ctx.clearResultsReportedInfo,
                        modelValue: _ctx.editState.resultsReported,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.resultsReported) = $event))
                      }, null, 8, ["name", "options", "onChange", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _createVNode(_component_text_input, {
                        "input-id": "donationInformation-results_reason",
                        ruleKey: "indicators.results_reason",
                        name: _ctx.$t("donationInformation-post_release.donationInformation-results_reason"),
                        modelValue: _ctx.editState.resultsReason,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.resultsReason) = $event)),
                        disabled: _ctx.editState.resultsReported == null,
                        rules: { required: _ctx.editState.resultsReported != null }
                      }, null, 8, ["name", "modelValue", "disabled", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_date_input, {
                        "input-id": "donationInformation-results_reported_date",
                        name: _ctx.$t("donationInformation-post_release.donationInformation-results_reported_date"),
                        modelValue: _ctx.editState.resultsReportedDate,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.resultsReportedDate) = $event)),
                        disabled: _ctx.editState.resultsReported == null,
                        rules: { required: _ctx.editState.resultsReported != null }
                      }, null, 8, ["name", "modelValue", "disabled", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(_component_time_input, {
                        "input-id": "donationInformation-results_reported_time",
                        name: _ctx.$t("donationInformation-post_release.donationInformation-results_reported_time"),
                        modelValue: _ctx.editState.resultsReportedTime,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState.resultsReportedTime) = $event)),
                        disabled: _ctx.editState.resultsReported == null,
                        rules: { required: _ctx.editState.resultsReported != null }
                      }, null, 8, ["name", "modelValue", "disabled", "rules"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(_component_checkbox_input, {
                    "input-id": "donationInformation-ecd",
                    ruleKey: "indicators.ecd",
                    labelName: _ctx.$t("donationInformation-ecd"),
                    modelValue: _ctx.editState.ecd,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editState.ecd) = $event)),
                    label: _ctx.$t("affimative"),
                    disabled: !_ctx.hasWriteAccessGeneral
                  }, null, 8, ["labelName", "modelValue", "label", "disabled"])
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_checkbox_input, {
                    "input-id": "donationInformation-double-kidney",
                    ruleKey: "indicators.double_kidney",
                    labelName: _ctx.$t("donationInformation-double-kidney"),
                    modelValue: _ctx.editState.doubleKidney,
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editState.doubleKidney) = $event)),
                    label: _ctx.$t("affimative"),
                    disabled: !_ctx.hasWriteAccessGeneral
                  }, null, 8, ["labelName", "modelValue", "label", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.$t("consented-organs.title")), 1),
                  _createElementVNode("div", _hoisted_30, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buildSingleTypeOrgans, (organ, idx) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: organ.code,
                        class: "standard-form-group"
                      }, [
                        _createElementVNode("div", _hoisted_31, [
                          _createVNode(_component_checkbox_input, {
                            "input-id": `donationInformation-organ-${0+idx}-checkbox`,
                            modelValue: (_ctx.editState.consentedOrgans as any)[organ.code],
                            "onUpdate:modelValue": ($event: any) => (((_ctx.editState.consentedOrgans as any)[organ.code]) = $event),
                            label: _ctx.$t(`${organ.value}`),
                            validationId: `organ-${0+idx}-checkbox`,
                            disabled: !_ctx.hasWriteAccessGeneral
                          }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label", "validationId", "disabled"])
                        ]),
                        _createElementVNode("div", null, [
                          _createVNode(_component_date_input, {
                            rules: "required_if_filled:@code",
                            "input-id": `donationInformation-${organ.code}-consented_date`,
                            name: _ctx.$t("consented-organs.consented_date"),
                            crossValues: { code: (_ctx.editState.consentedOrgans as any)[organ.code] },
                            modelValue: (_ctx.editState.consentedDates as any)[organ.code],
                            "onUpdate:modelValue": ($event: any) => (((_ctx.editState.consentedDates as any)[organ.code]) = $event),
                            validationId: `donationInformation-${organ.code}-consented_date`,
                            disabled: !_ctx.hasWriteAccessGeneral
                          }, null, 8, ["input-id", "name", "crossValues", "modelValue", "onUpdate:modelValue", "validationId", "disabled"])
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _createVNode(_component_boolean_radio_input, {
                    "input-id": "donor-consented-for-research",
                    labelName: _ctx.$t("donor-consented-for-research"),
                    acceptId: true,
                    declineId: false,
                    acceptLabel: _ctx.$t("affimative"),
                    declineLabel: _ctx.$t("negative"),
                    modelValue: _ctx.editState.consentedForResearch,
                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editState.consentedForResearch) = $event)),
                    disabled: !_ctx.hasWriteAccessAllocations
                  }, null, 8, ["labelName", "acceptLabel", "declineLabel", "modelValue", "disabled"])
                ])
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["saveButtonText", "lookups-to-load", "saveButton", "disabled"]))
}