import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "nav-block-wrap" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = {
  key: 1,
  href: "#",
  class: "nav-link no-scroll open",
  to: { name: 'list-living-donors', hash: '#summary'}
}
const _hoisted_4 = {
  key: 0,
  class: "nav flex-column"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", {
      class: _normalizeClass(["nav nav-block flex-column", { active: _ctx.active }])
    }, [
      _createElementVNode("li", _hoisted_2, [
        (_ctx.clientId)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              class: "nav-link no-scroll open",
              to: { name: 'edit-living-donor', params: { id: _ctx.clientId }, hash: '#summary' }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['fas', (_ctx.show ? 'chevron-down' : 'chevron-right')],
                  class: "nav-caret",
                  "fixed-width": "",
                  onClick: _withModifiers(_ctx.toggleNav, ["prevent"])
                }, null, 8, ["icon", "onClick"]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('profile')), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createElementBlock("a", _hoisted_3, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', (_ctx.show ? 'chevron-down' : 'chevron-right')],
                class: "nav-caret",
                "fixed-width": "",
                onClick: _withModifiers(_ctx.toggleNav, ["prevent"])
              }, null, 8, ["icon", "onClick"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('profile')), 1)
            ]))
      ]),
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
            }, [
              _createElementVNode("a", {
                class: "nav-link",
                href: _ctx.getUrl('demographics')
              }, _toDisplayString(_ctx.$t('demographics')), 9, _hoisted_5)
            ]),
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
            }, [
              _createElementVNode("a", {
                class: "nav-link",
                href: _ctx.getUrl('contactinfo')
              }, _toDisplayString(_ctx.$t('contact_information')), 9, _hoisted_6)
            ]),
            (!_ctx.newDonor)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: "nav-item",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('link-to-recipient')
                  }, _toDisplayString(_ctx.$t('link_to_recipient')), 9, _hoisted_7)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
            }, [
              _createElementVNode("a", {
                class: "nav-link",
                href: _ctx.getUrl('donation-information')
              }, _toDisplayString(_ctx.$t('donation_information')), 9, _hoisted_8)
            ]),
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
            }, [
              _createElementVNode("a", {
                class: "nav-link",
                href: _ctx.getUrl('gci')
              }, _toDisplayString(_ctx.$t('clinical_information')), 9, _hoisted_9)
            ]),
            (!_ctx.newDonor)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 1,
                  class: "nav-item",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('virology')
                  }, _toDisplayString(_ctx.$t('virology_results')), 9, _hoisted_10)
                ]))
              : _createCommentVNode("", true),
            (_ctx.showRecoveryInformation)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 2,
                  class: "nav-item",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('recovery-information')
                  }, _toDisplayString(_ctx.$t('recovery_details')), 9, _hoisted_11)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}