<template>
  <div>
    <loading-summary-card />

    <span class="skeleton-box skeleton-title w-100" />  
    <loading-section/>

    <span class="skeleton-box skeleton-title w-100" />  
    <loading-section/>

    <span class="skeleton-box skeleton-title w-100" />  
    <loading-section/>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import LoadingSummaryCard from '@/components/shared/LoadingSummaryCard.vue';
import LoadingSection from '@/components/shared/LoadingSection.vue';

@Component({
  components: {
    LoadingSummaryCard,
    LoadingSection
  }
})
export default class LoadingRecipientsPage extends Vue {}
</script>
