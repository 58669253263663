export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "contact_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
        "save_contact_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Contact Information"])},
        "permanent_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanent Address"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
        "other_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Country"])},
        "street_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street Address"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "living_donor_contact_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donor Contact Details"])},
        "mobile_phone_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone Number"])},
        "home_phone_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Phone Number"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
        "zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
        "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])}
      },
      "fr": {
        "contact_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordondées"])},
        "save_contact_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les coordondées"])},
        "permanent_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse permanente"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
        "other_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre pays"])},
        "street_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
        "living_donor_contact_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées du donneur vivant"])},
        "mobile_phone_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone portable"])},
        "home_phone_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone résidentiel"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse électronique"])},
        "zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code zip"])},
        "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Postale"])}
      }
    }
  })
}
