export interface ReportState {
  currentReport?: string;
  TransplantInProgressReportState?: TransplantInProgressReportState;
  WaitingListReportState?: WaitingListReportState;
  ChangeStatusReportState?: ChangeStatusReportState;
  CrossmatchReportState?: CrossmatchReportState;
  DialysisReportState?: DialysisReportState;
  AllocationReportState?: AllocationReportState;
}

export interface TransplantInProgressReportState {
  sort: string;
}

export interface TransplantActivityReportState {
  organ?: string[];
  hospital?: string[];
  startDate? :string;
  endDate?: string;
}

export interface AllocationReportState {
  allocationClientId?: string;
  hospital?: string;
}

export interface AllocationReportQuery {
  allocation_id?: string;
  hospital?: string;
  organ_code?: number;
}

export enum allocationReports {
  AllocationHeart          = 'allocation_report_heart',
  AllocationLiver          = 'allocation_report_liver',
  AllocationKidneyPancreas = 'allocation_report_kidney_pancreas',
  AllocationLung           = 'allocation_report_lung',
  AllocationPancreasIslets = 'allocation_report_pancreas_islet',
  AllocationPancreasWhole = 'allocation_report_pancreas_whole',
  AllocationSmallBowel     = 'allocation_report_small_bowel'
}

export interface TransplantActivityReportQuery {
  organ?: string[] | null;
  hospital?: string[];
  start_date? :string;
  end_date?: string;
}

export interface CrossmatchReportState {
  region?: string[];
  hospital?: string[];
  bloodType?: string[];
  trayNumber?: string;
}

export interface ChangeStatusReportState {
  organCode?: string[];
  hospital?: string[];
  startDate?: string;
  endDate?: string;
}

export interface WaitingListReportState {
  hospital?: string[];
  clustered?: boolean;
  clusteredList?: string;
  includeNonClustered?: boolean;
  organCode?: number;
  lowerAge?: number;
  upperAge?: number;
  bloodType?: string[];
  extendedCriteriaDonor?: string[];
  dacAcceptanceHCVAB?: string[];
  dacAcceptanceHCNAT?: string[];
  dacAcceptanceHepABCore?: string[];
  suspendedPatients?: string[];
  gender?: string[];
  acceptLivingDonor?: string[],
  acceptDeceasedDonor?: string[],
  sortOrder?: string;
}

export interface WaitingListReportQuery {
  hospital?: string[],
  organ_type?: number,
  lower_age?: number,
  upper_age?: number,
  blood_type?: string[],
  extended_criteria_donor?: string[] | null,
  dac_acceptance_hcvab?: string[] | null,
  dac_acceptance_hcnat?: string[] | null,
  dac_acceptance_hepabcore?: string[] | null,
  accept_living_donor?: string[] | null,
  accept_deceased_donor?: string[] | null,
}

export enum waitingListReports {
  WaitingListReport         = 'waiting_list_report',
  WaitingListKidneyPancreas = 'waiting_list_kidney_pancreas_report',
  WaitingListLungHeart      = 'waiting_list_lung_heart_report',
  WaitingListPancreasIslets = 'waiting_list_pancreas_islets_report',
  WaitingListHeartClusters  = 'waiting_list_heart_clusters_report',
  WaitingListLiverClusters  = 'waiting_list_liver_clusters_report',
  WaitingListPancreasWhole  = 'waiting_list_pancreas_whole_report'
}

export enum recipientDataAuditReports {
  Index = 'recipient_data_audit_index',
  Detail = 'recipient_data_audit_detail'
}

export interface OrganYieldUtilizationReportState {
  fromDate1?: string;
  toDate1?: string;
  hospital?: string[];
}

export interface OrganYieldUtilizationReportQuery {
  from_date_1?: string|null;
  to_date_1?: string|null;
  hospital?: string[];
}

export interface RecipientDataAuditReportState {
  fromDate?: string;
  toDate?: string;
  bloodType?: string[];
  changeType?: string[];
  organType?: string[];
  hospital?: string[];
}

export interface RecipientDataAuditReportQuery {
  from_date?: string|null;
  to_date?: string|null;
  blood_type?: string[];
  change_type?: string[];
  organ_type?: string[];
  hospital?: string[];
}

export interface DialysisReportState {
  transplantHospital?: string[];
  dialysisProvider?: string[];
  bloodType?: string[];
}

export interface DialysisReportQuery {
  transplant_hospital?: string[];
  dialysis_hospital?: string[];
  blood_type?: string[];
}
