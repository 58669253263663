import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", { id: _ctx.formId }, [
    _renderSlot(_ctx.$slots, "title"),
    _renderSlot(_ctx.$slots, "action"),
    _createElementVNode("fieldset", { disabled: _ctx.disabled }, [
      _renderSlot(_ctx.$slots, "contents"),
      _renderSlot(_ctx.$slots, "save")
    ], 8, _hoisted_2)
  ], 8, _hoisted_1))
}