export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "add_recipient_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Recipient to Allocation Recommendation"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading..."])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient ID"])},
        "oop_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out-Of-Province Program"])},
        "add_this_recipient_to_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to add this Recipient to the Allocation Recommendation?"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "ohip_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohip Number"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "add_this_program_to_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to add this Program to the Allocation Recommendation?"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "recipient_id_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient ID Invalid"])}
      },
      "fr": {
        "add_recipient_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un patient à l'attribution"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chargement en cours…"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Patient"])},
        "oop_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme hors province"])},
        "add_this_recipient_to_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous ajouter ce patient à la recommandation d'allocation ?"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "ohip_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'assurance-santé de l'Ontario"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "add_this_program_to_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous ajouter ce programme à la recommandation de l'allocation ?"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "recipient_id_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No patient non valide"])}
      }
    }
  })
}
