import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-footer-body" }
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_openBlock(), _createBlock(_component_modal_section, {
    modalId: "national-organ-waitlist-modal",
    ref: "nationalOrganWaitlistModal",
    class: "modal-sticky-header"
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('national_organ_waitlist')), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = _withModifiers((event) => event.preventDefault(), ["prevent"]))
      }, [
        _createVNode(_component_sub_section, {
          "sub-section-id": "now-list-section",
          title: _ctx.$t('national_organ_waitlist_records'),
          "table-config": _ctx.nowListTableConfig,
          onOnColumnFilter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterList($event))),
          onOnSortChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterList($event)))
        }, null, 8, ["title", "table-config"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": _ctx.$t('close'),
          class: "btn btn-secondary",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeModal()))
        }, _toDisplayString(_ctx.$t('close')), 9, _hoisted_2)
      ])
    ]),
    _: 1
  }, 512))
}