export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse Panel"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize Panel"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Options"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Style"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this panel"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "link_to_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Donor Page"])},
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Recipient Page"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
        "xm_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XM Info"])},
        "test_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Date"])},
        "save_crossmatch_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Crossmatch Info"])},
        "new_crossmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Crossmatch"])},
        "selected_crossmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Crossmatch"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor ID"])},
        "testing_lab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testing Lab"])},
        "test_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Time (EST)"])},
        "donor_sample_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Sample Code"])},
        "donor_sample_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Sample Date"])},
        "sample_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Type"])},
        "method_technique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method/Technique"])},
        "use_form_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use the form below to add a new Crossmatch"])},
        "create_crossmatch_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Crossmatch Info"])}
      },
      "fr": {
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau rabattu"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le panneau"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options du panneau"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style du panneau"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher ce panneau"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])},
        "link_to_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page des donneurs"])},
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page des patients"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])},
        "xm_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info XM"])},
        "test_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du test"])},
        "save_crossmatch_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les informations de compatilbilité croisée"])},
        "new_crossmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau compatilbilité croisée"])},
        "selected_crossmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compatilbilité croisée sélectionné"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Doneur"])},
        "testing_lab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labo de test"])},
        "test_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure du test (EST)"])},
        "donor_sample_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'échantillon du donneur"])},
        "donor_sample_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de l'échantillon du donneur"])},
        "sample_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'échantillon"])},
        "method_technique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode/Technique"])},
        "use_form_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisez le formulaire ci-dessous pour ajouter un nouveau compatilbilité croisée"])},
        "create_crossmatch_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des informations sur le recoupement"])}
      }
    }
  })
}
