import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card tip-card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "media align-items-center" }
const _hoisted_4 = { class: "media-left" }
const _hoisted_5 = { class: "media-body flex-row align-items-center" }
const _hoisted_6 = { class: "tip-report-name" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "content-wrap" }
const _hoisted_9 = { class: "container-fluid" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_12 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_13 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_14 = {
  key: 3,
  class: "standard-form-group"
}
const _hoisted_15 = {
  key: 4,
  class: "standard-form-group"
}
const _hoisted_16 = { class: "row" }
const _hoisted_17 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_18 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_19 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_20 = {
  key: 3,
  class: "standard-form-group"
}
const _hoisted_21 = {
  key: 4,
  class: "standard-form-group"
}
const _hoisted_22 = { class: "row" }
const _hoisted_23 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_24 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_25 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_26 = {
  key: 3,
  class: "standard-form-group"
}
const _hoisted_27 = {
  key: 4,
  class: "standard-form-group"
}
const _hoisted_28 = {
  key: 5,
  class: "standard-form-group"
}
const _hoisted_29 = { class: "form-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, { subSectionId: "transplant-in-progress-report" }, _createSlots({ _: 2 }, [
    (_ctx.editState)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'file-chart-line'],
                      "fixed-width": ""
                    })
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('waiting_list_report')), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      (_ctx.showHospitalandOrgan)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createVNode(_component_select_input, {
                              selectId: "waitingListReport-organ_type",
                              name: _ctx.$t('organ_type'),
                              modelValue: _ctx.editState.organCode,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.organCode) = $event)),
                              options: _ctx.filteredOrganLookup,
                              rules: "required"
                            }, null, 8, ["name", "modelValue", "options"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showHospitalandOrgan)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode(_component_checkbox_input, {
                              "input-id": "waitingListReport-clustered",
                              labelName: _ctx.$t('clustered_with'),
                              label: "Yes",
                              modelValue: _ctx.editState.clustered,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.clustered) = $event))
                            }, null, 8, ["labelName", "modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showHospitalandOrgan)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createVNode(_component_select_input, {
                              selectId: "waitingListReport-clustered_list",
                              name: _ctx.$t('clustered_list'),
                              modelValue: _ctx.editState.clusteredList,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.clusteredList) = $event)),
                              options: _ctx.clusteredOrganLookup(_ctx.editState.organCode as number),
                              multiple: true,
                              disabled: _ctx.editState.organCode && _ctx.editState.clustered ? false : true,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.clusteredList, _ctx.editState.organCode && _ctx.editState.clustered ? false : true),
                              "max-tags": 3
                            }, null, 8, ["name", "modelValue", "options", "disabled", "placeholder"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showHospitalandOrgan)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createVNode(_component_checkbox_input, {
                              "input-id": "waitingListReport-include_non_clustered",
                              labelName: _ctx.$t('include_non_clustered'),
                              label: "Yes",
                              modelValue: _ctx.editState.includeNonClustered,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.includeNonClustered) = $event))
                            }, null, 8, ["labelName", "modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showSuspendedPatients)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createVNode(_component_select_input, {
                              selectId: "waitingListReport-suspended_patients",
                              name: _ctx.$t('suspended_patients'),
                              modelValue: _ctx.editState.suspendedPatients,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.suspendedPatients) = $event)),
                              options: _ctx.yesNoOptions,
                              multiple: true,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.suspendedPatients)
                            }, null, 8, ["name", "modelValue", "options", "placeholder"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      (_ctx.showHospitalandOrgan)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createVNode(_component_select_input, {
                              selectId: "waitingListReport-hospital",
                              name: _ctx.$t('hospital'),
                              modelValue: _ctx.editState.hospital,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.hospital) = $event)),
                              options: _ctx.filteredHospitalsByUser,
                              multiple: true,
                              undefinedText: _ctx.hospitalUndefinedText,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.hospital, _ctx.editState.organCode ? false : true),
                              disabled: _ctx.editState.organCode ? false : true
                            }, null, 8, ["name", "modelValue", "options", "undefinedText", "placeholder", "disabled"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showAgeBloodTypeDonorTypeECD)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                            _createVNode(_component_select_input, {
                              selectId: "waitingListReport-blood_type",
                              name: _ctx.$t('blood_type'),
                              modelValue: _ctx.editState.bloodType,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.bloodType) = $event)),
                              options: _ctx.bloodTypeOptions,
                              multiple: true,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.bloodType)
                            }, null, 8, ["name", "modelValue", "options", "placeholder"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showAgeBloodTypeDonorTypeECD)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createVNode(_component_select_input, {
                              selectId: "waitingListReport-accept_living_donor",
                              name: _ctx.$t('accept_living_donor'),
                              modelValue: _ctx.editState.acceptLivingDonor,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.acceptLivingDonor) = $event)),
                              options: _ctx.yesNoOptions,
                              multiple: true,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.acceptLivingDonor)
                            }, null, 8, ["name", "modelValue", "options", "placeholder"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showAgeBloodTypeDonorTypeECD)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                            _createVNode(_component_select_input, {
                              selectId: "waitingListReport-accept_deceased_donor",
                              name: _ctx.$t('accept_deceased_donor'),
                              modelValue: _ctx.editState.acceptDeceasedDonor,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.acceptDeceasedDonor) = $event)),
                              options: _ctx.yesNoOptions,
                              multiple: true,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.acceptDeceasedDonor)
                            }, null, 8, ["name", "modelValue", "options", "placeholder"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showECD)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                            _createVNode(_component_select_input, {
                              selectId: "waitingListReport-ecd",
                              name: _ctx.$t('ecd'),
                              modelValue: _ctx.editState.extendedCriteriaDonor,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.extendedCriteriaDonor) = $event)),
                              options: _ctx.yesNoOptions,
                              multiple: true,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.extendedCriteriaDonor)
                            }, null, 8, ["name", "modelValue", "options", "placeholder"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      (_ctx.showDACAcceptance)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            _createVNode(_component_select_input, {
                              selectId: "waitingListReport-dac_acceptance_hcvab",
                              name: _ctx.$t('hcv_ab'),
                              modelValue: _ctx.editState.dacAcceptanceHCVAB,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.dacAcceptanceHCVAB) = $event)),
                              options: _ctx.yesNoOptions,
                              multiple: true,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.dacAcceptanceHCVAB)
                            }, null, 8, ["name", "modelValue", "options", "placeholder"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showDACAcceptance)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                            _createVNode(_component_select_input, {
                              selectId: "waitingListReport-dac_acceptance_hcnat",
                              name: _ctx.$t('hcv_nat'),
                              modelValue: _ctx.editState.dacAcceptanceHCNAT,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.dacAcceptanceHCNAT) = $event)),
                              options: _ctx.yesNoOptions,
                              multiple: true,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.dacAcceptanceHCNAT)
                            }, null, 8, ["name", "modelValue", "options", "placeholder"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showDACAcceptance)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                            _createVNode(_component_select_input, {
                              selectId: "waitingListReport-posituve_acceptance_hepabcore",
                              name: _ctx.$t('hep_ab_core'),
                              modelValue: _ctx.editState.dacAcceptanceHepABCore,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.dacAcceptanceHepABCore) = $event)),
                              options: _ctx.yesNoOptions,
                              multiple: true,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.dacAcceptanceHepABCore)
                            }, null, 8, ["name", "modelValue", "options", "placeholder"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showAgeBloodTypeDonorTypeECD)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                            _createVNode(_component_number_input, {
                              inputId: "waitingListReport-lower_age",
                              name: _ctx.$t('lower_age'),
                              modelValue: _ctx.editState.lowerAge,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.lowerAge) = $event)),
                              rules: "required"
                            }, null, 8, ["name", "modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showAgeBloodTypeDonorTypeECD)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            _createVNode(_component_number_input, {
                              inputId: "waitingListReport-upper_age",
                              name: _ctx.$t('upper_age'),
                              modelValue: _ctx.editState.upperAge,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.upperAge) = $event)),
                              rules: "required"
                            }, null, 8, ["name", "modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showSort)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                            _createVNode(_component_select_input, {
                              selectId: "waitingListReport-sorting",
                              name: _ctx.$t('sort'),
                              modelValue: _ctx.editState.sortOrder,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.sortOrder) = $event)),
                              options: _ctx.sortOptions,
                              placeholder: "Sort By"
                            }, null, 8, ["name", "modelValue", "options"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_component_save_toolbar, {
                        class: "form-inline action-row",
                        buttonClass: "btn btn-success",
                        ref: "generateReport",
                        savingText: _ctx.$t('saving_text'),
                        successText: _ctx.$t('success_text'),
                        defaultErrorText: _ctx.$t('default_error_text'),
                        onSave: _cache[16] || (_cache[16] = ($event: any) => (_ctx.generateReport()))
                      }, {
                        label: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['far', 'file-chart-line'],
                            class: "mr-2"
                          }),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('generate_report')), 1)
                        ]),
                        _: 1
                      }, 8, ["savingText", "successText", "defaultErrorText"])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}