import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-wrap page-top" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "page-top-right" }
const _hoisted_4 = { class: "system-title d-inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.uiEnvVersion), 1)
      ])
    ])
  ]))
}