import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "donor-summary" }
const _hoisted_2 = { class: "list-inline-item" }
const _hoisted_3 = { class: "patient-name" }
const _hoisted_4 = { class: "list-inline-item" }
const _hoisted_5 = { class: "list-inline-item" }
const _hoisted_6 = { class: "list-inline-item" }
const _hoisted_7 = { class: "list-inline-item" }
const _hoisted_8 = { class: "list-inline-item" }
const _hoisted_9 = { class: "list-inline-item" }
const _hoisted_10 = { class: "list-inline-item" }
const _hoisted_11 = { class: "list-inline-item" }
const _hoisted_12 = { class: "list-inline-item" }
const _hoisted_13 = { class: "list-inline-item" }
const _hoisted_14 = { class: "list-inline-item" }
const _hoisted_15 = { class: "list-inline-item" }
const _hoisted_16 = { class: "list-inline-item" }
const _hoisted_17 = { class: "dropdown" }
const _hoisted_18 = {
  key: 0,
  class: "list-inline-item"
}
const _hoisted_19 = { class: "dropdown" }
const _hoisted_20 = { class: "organ-summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_organ_icon = _resolveComponent("organ-icon")!
  const _component_sticky_summary = _resolveComponent("sticky-summary")!

  return (_openBlock(), _createBlock(_component_sticky_summary, null, _createSlots({ _: 2 }, [
    (_ctx.editState)
      ? {
          name: "summaryContent",
          fn: _withCtx(() => [
            _createElementVNode("ul", _hoisted_1, [
              _createElementVNode("li", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, [
                  _createTextVNode(_toDisplayString(_ctx.$t('name')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.deceasedDonorDisplayName), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_4, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('deceased_donor_id')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.donor.deceased_donor_id || '-'), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_5, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('hospital')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.referral_hospital), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_6, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('nat_short_ID')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.national_donor_id ? _ctx.editState.national_donor_id : '-'), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_7, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('abo')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.abo), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_8, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('sex')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.sex), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_9, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('age')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.age), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_10, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('height')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.height), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_11, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('weight')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.weight), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_12, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('exd')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.exceptional_distribution), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_13, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('donor_type')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.editState.donor_type), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_14, [
                _createVNode(_component_router_link, {
                  to: { name: 'edit-deceased-donor', params: { id: _ctx.donor.client_id }},
                  class: "nav-link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('donor_information')), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _createElementVNode("li", _hoisted_15, [
                _createVNode(_component_router_link, {
                  to: { name: 'edit-deceased-donor', params: { id: _ctx.donor.client_id }, hash: '#hla-typing' },
                  class: "nav-link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('hla_information')), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _createElementVNode("li", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_router_link, {
                    to: {},
                    class: "nav-link dropdown-toggle",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('allocation_reports')), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              (_ctx.canAccessNowList)
                ? (_openBlock(), _createElementBlock("li", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("a", {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openNowModal())),
                        class: "nav-link dropdown-toggle",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      }, _toDisplayString(_ctx.$t('now_list')), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("ul", _hoisted_20, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedDonorConsentedOrganList, (consentedOrgan, idx) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: idx,
                  class: _normalizeClass(_ctx.listItemClass(consentedOrgan))
                }, [
                  _createVNode(_component_router_link, {
                    to: consentedOrgan.route,
                    class: "nav-link"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_organ_icon, {
                        "organ-code": consentedOrgan.organ_code,
                        "summary-bar-icon": true
                      }, null, 8, ["organ-code"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t(`${consentedOrgan.display_name}`)), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ], 2))
              }), 128))
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}