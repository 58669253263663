import { createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading || !_ctx.recipients)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(" Loading... ")
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("p", null, [
            _createTextVNode("Your search returned "),
            _createElementVNode("strong", null, _toDisplayString(_ctx.recipients.count), 1),
            _createTextVNode(" results")
          ]),
          _createVNode(_component_sub_section, {
            "sub-section-id": "list-recipient",
            lookupsToLoad: [],
            title: "",
            mode: "remote",
            tabbableValue: _ctx.$t('client_id'),
            "total-records": _ctx.recipients.count,
            "table-config": _ctx.recipientTableConfig,
            onOnPageChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatePagination($event))),
            onOnPerPageChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updatePagination($event))),
            onTableRowClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectRecipient($event)))
          }, null, 8, ["tabbableValue", "total-records", "table-config"])
        ], 64))
  ]))
}