export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "allocation_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Details"])},
        "allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation ID"])},
        "allocation_stage_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Stage"])},
        "allocation_run_datetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Run Date and Time (EST)"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor ID"])},
        "national_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National ID"])},
        "donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Type"])},
        "disabled_program_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled Program Rules"])},
        "special_considerations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Considerations"])},
        "special_considerations_detail_hover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View more details about these special considerations"])},
        "ontario_hsp_kidneys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontario HSP Kidneys at Export Threshold?"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "default_export_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A - No HSP Patients Identified"])},
        "default_out_of_province_export_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A - Out of Province Donor"])}
      },
      "fr": {
        "allocation_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l’attribution"])},
        "allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l’attribution"])},
        "allocation_stage_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape de l’attribution"])},
        "allocation_run_datetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure de l’exécution de l’attribution"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du donneur"])},
        "national_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité nationale"])},
        "donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de donneur"])},
        "disabled_program_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles du programme désactivées"])},
        "special_considerations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observations spéciales"])},
        "special_considerations_detail_hover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus de détails sur ces observations spéciales"])},
        "ontario_hsp_kidneys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reins du HSP de l'Ontario au seuil d'exportation?"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "default_export_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A - No HSP Patients Identified"])},
        "default_out_of_province_export_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A - Out of Province Donor"])}
      }
    }
  })
}
