import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { ConfigurationState } from '@/store/configuration/types';

export const actions: ActionTree<ConfigurationState, RootState> = {
  load({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('clearConfiguration');
      // Prepare request
      const url = APIRoute(EP.configuration.show);
      // Dispatch request
      axios.get(url).then((response: any) => {
        // Process configuration settings
        const configuration = response.data.configuration;
        commit('setConfiguration', configuration);
        resolve();
      }).catch((error: any) => {
        // Process error
        console.warn(error);
        const configuration = { app: null, features: null };
        commit('setConfiguration', configuration);
        reject();
      });
    });
  }
};
