<template>
  <div class="content-wrap">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Vue, Component} from "vue-facing-decorator";

@Component
export default class FullPageListLayout extends Vue {

}
</script>
