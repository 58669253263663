export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title_widget_lab_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Lab Results"])},
        "empty_widget_lab_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Lab Results"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor ID"])},
        "result_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Lab Result"])},
        "date_time_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Time Received"])},
        "hla_typing_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA Typing"])},
        "virology_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virology"])},
        "crossmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serum Crossmatch"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize panel"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse panel"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Options"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Style"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this panel"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "no_new_lab_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No new lab results"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
        "link_to_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Donor Page"])}
      },
      "fr": {
        "title_widget_lab_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveaux résultats de labo"])},
        "empty_widget_lab_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat de labo"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Donneur"])},
        "result_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de résultats de labo"])},
        "date_time_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure de réception"])},
        "hla_typing_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typage HLA"])},
        "virology_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serologie"])},
        "crossmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test croisé du sérum"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le panneau"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire le panneau"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options du panneau"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style du panneau"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer ce panneau"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "no_new_lab_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nouveau résultat de labo"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "link_to_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page des donneurs"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])}
      }
    }
  })
}
