import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, toHandlers as _toHandlers, mergeProps as _mergeProps, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id", "value"]
const _hoisted_2 = ["id", "aria-labelledby", "disabled"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_asterisk = _resolveComponent("validation-asterisk")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!

  return (_openBlock(), _createBlock(_component_validation_provider, {
    ref: "provider",
    rules: _ctx.formRules,
    name: _ctx.inputId,
    label: _ctx.labelName,
    vid: _ctx.validationId ? _ctx.validationId : _ctx.inputId,
    modelValue: _ctx.modelValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue) = $event))
  }, {
    default: _withCtx(({ errors }) => [
      (_ctx.labelName)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(["p-label", { 'sr-only': _ctx.hideLabel }])
          }, [
            _createTextVNode(_toDisplayString(_ctx.labelName) + " ", 1),
            (!_ctx.disabled)
              ? (_openBlock(), _createBlock(_component_validation_asterisk, {
                  key: 0,
                  rules: _ctx.formRules,
                  crossValues: _ctx.crossValues,
                  ruleKey: _ctx.ruleKey
                }, null, 8, ["rules", "crossValues", "ruleKey"]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["form-check form-check-inline", { 'is-invalid': errors[0] }])
      }, [
        (_ctx.isMasked)
          ? (_openBlock(), _createElementBlock("input", {
              key: 0,
              type: "checkbox",
              class: "form-check-input",
              id: _ctx.inputId,
              disabled: true,
              readonly: true,
              value: _ctx.modelValue
            }, null, 8, _hoisted_1))
          : _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
              key: 1,
              id: _ctx.inputId,
              "aria-labelledby": _ctx.inputId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isChecked) = $event)),
              disabled: _ctx.isReadOnly(_ctx.disabled),
              type: "checkbox",
              class: "form-check-input"
            }, _toHandlers(_ctx.inputEvents(), true)), null, 16, _hoisted_2)), [
              [_vModelCheckbox, _ctx.isChecked]
            ]),
        _createElementVNode("label", {
          class: "form-check-label",
          for: _ctx.inputId
        }, [
          _createElementVNode("span", {
            innerHTML: _ctx.label || _ctx.labelName
          }, null, 8, _hoisted_4)
        ], 8, _hoisted_3)
      ], 2),
      (errors[0] && !_ctx.disabled)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "invalid-feedback",
            id: `${_ctx.inputId}-error`
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['far', 'exclamation-circle'],
              "fixed-width": ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.labelName)), 1)
          ], 8, _hoisted_5))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["rules", "name", "label", "vid", "modelValue"]))
}