import { UIRecipientConfig } from '@/UIModels/configuration/features/recipientConfig';
import { FeaturesConfig } from '@/store/configuration/types';

export class UIFeatureConfig {
  public recipientConfig: UIRecipientConfig;

  public constructor(apiFeatureConfig: FeaturesConfig) {
    this.recipientConfig = new UIRecipientConfig(apiFeatureConfig.recipient);
  }
}
