import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createBlock as _createBlock, renderList as _renderList, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_7 = { class: "row" }
const _hoisted_8 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_9 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_10 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_11 = {
  key: 3,
  class: "standard-form-group"
}
const _hoisted_12 = {
  key: 4,
  class: "standard-form-group"
}
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "standard-form-group" }
const _hoisted_16 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_17 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_18 = {
  key: 4,
  class: "standard-form-group"
}
const _hoisted_19 = { class: "row" }
const _hoisted_20 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_21 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_22 = {
  key: 2,
  class: "standard-form-group-large"
}
const _hoisted_23 = {
  key: 0,
  class: "row"
}
const _hoisted_24 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_25 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_26 = ["disabled"]
const _hoisted_27 = { class: "row" }
const _hoisted_28 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_29 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "standard-form-group" }
const _hoisted_32 = { class: "standard-form-group" }
const _hoisted_33 = { class: "standard-form-group-large" }
const _hoisted_34 = { class: "standard-form-group-large" }
const _hoisted_35 = { for: "demographics-fa-tper-attachment" }
const _hoisted_36 = { class: "list-inline" }
const _hoisted_37 = ["onClick", "title"]
const _hoisted_38 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_select_other_input = _resolveComponent("select-other-input")!
  const _component_boolean_radio_input = _resolveComponent("boolean-radio-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "demographics",
    "lookups-to-load": _ctx.lookupsToLoad,
    disabled: !_ctx.canSave,
    onLoaded: _cache[34] || (_cache[34] = ($event: any) => (_ctx.loaded()))
  }, _createSlots({
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('demographics')), 1)
    ]),
    _: 2
  }, [
    (_ctx.editState)
      ? {
          name: "body",
          fn: _withCtx(() => [
            _createVNode(_component_validation_observer, { ref: "validations" }, {
              default: _withCtx(() => [
                _createVNode(_component_form_layout, { "form-id": "demographics-form" }, {
                  contents: _withCtx(() => [
                    _createVNode(_component_sub_section, {
                      title: _ctx.$t('registration_information'),
                      "sub-section-id": "demographics-internal",
                      disabled: !_ctx.canSave,
                      hideDivider: true
                    }, {
                      contents: _withCtx(() => [
                        (_ctx.editState.internal)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                              _createElementVNode("div", _hoisted_2, [
                                _createVNode(_component_text_input, {
                                  inputId: "demographics-personal-client_id",
                                  name: _ctx.$t('client_id'),
                                  disabled: true,
                                  modelValue: _ctx.editState.internal.client_id,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.internal.client_id) = $event))
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              (_ctx.checkPropExists('patient_profile.ctr.national_recipient_id'))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                    _createVNode(_component_text_input, {
                                      inputId: "demographics-personal-ctr_id",
                                      name: _ctx.$t('CTR_Number'),
                                      disabled: true,
                                      modelValue: _ctx.editState.internal.ctr_id,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.internal.ctr_id) = $event))
                                    }, null, 8, ["name", "modelValue"])
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_date_input, {
                                  inputId: "demographics-personal-registration_date",
                                  name: _ctx.$t('registration_date'),
                                  disabled: true,
                                  modelValue: _ctx.editState.internal.registration_date,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.internal.registration_date) = $event))
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_5, [
                                _createVNode(_component_text_input, {
                                  inputId: "demographics-personal-registration_time",
                                  name: _ctx.$t('registration_time'),
                                  disabled: true,
                                  modelValue: _ctx.editState.internal.registration_time,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.internal.registration_time) = $event))
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              (_ctx.isUrgentRecipientEnabled)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    _createVNode(_component_checkbox_input, {
                                      ruleKey: "patient_profile.urgent",
                                      validationId: "urgent",
                                      "input-id": "demographics-internal-urgent",
                                      labelName: _ctx.$t('urgent_recipient_label'),
                                      disabled: !_ctx.newRecipient,
                                      title: _ctx.isUrgent ? _ctx.$t('urgent_recipient_hover_explanation') : '',
                                      modelValue: _ctx.editState.internal.urgent,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.internal.urgent) = $event)),
                                      label: "Yes"
                                    }, null, 8, ["labelName", "disabled", "title", "modelValue"])
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["title", "disabled"]),
                    (_ctx.isPersonalInfoEnabled)
                      ? (_openBlock(), _createBlock(_component_sub_section, {
                          key: 0,
                          title: _ctx.$t('personal_information'),
                          "sub-section-id": "demographics-personal",
                          disabled: !_ctx.canSave,
                          hideDivider: true
                        }, {
                          contents: _withCtx(() => [
                            (_ctx.editState.personal)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createElementVNode("div", _hoisted_7, [
                                    (_ctx.checkPropExists('patient_profile.first_name'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                          _createVNode(_component_text_input, {
                                            ruleKey: "patient_profile.first_name",
                                            inputId: "demographics-personal-firstname",
                                            name: _ctx.$t('first_name'),
                                            modelValue: _ctx.editState.personal.first_name,
                                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.personal.first_name) = $event))
                                          }, null, 8, ["name", "modelValue"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.middle_name'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                          _createVNode(_component_text_input, {
                                            ruleKey: "patient_profile.middle_name",
                                            inputId: "demographics-personal-middlename",
                                            name: _ctx.$t('middle_name'),
                                            modelValue: _ctx.editState.personal.middle_name,
                                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.personal.middle_name) = $event))
                                          }, null, 8, ["name", "modelValue"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.last_name'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                          _createVNode(_component_text_input, {
                                            ruleKey: "patient_profile.last_name",
                                            inputId: "demographics-personal-lastname",
                                            name: _ctx.$t('last_name'),
                                            modelValue: _ctx.editState.personal.last_name,
                                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.personal.last_name) = $event))
                                          }, null, 8, ["name", "modelValue"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.preferred_name'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                          _createVNode(_component_text_input, {
                                            ruleKey: "patient_profile.preferred_name",
                                            inputId: "demographics-personal-preferred_name",
                                            name: _ctx.$t('preferred_name'),
                                            modelValue: _ctx.editState.personal.preferredName,
                                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.personal.preferredName) = $event))
                                          }, null, 8, ["name", "modelValue"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.preferred_pronouns'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                          _createVNode(_component_text_input, {
                                            ruleKey: "patient_profile.preferred_pronouns",
                                            inputId: "demographics-personal-preferred_pronouns",
                                            name: _ctx.$t('preferred_pronouns'),
                                            modelValue: _ctx.editState.personal.preferredPronouns,
                                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.personal.preferredPronouns) = $event))
                                          }, null, 8, ["name", "modelValue"])
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _createElementVNode("div", _hoisted_13, [
                                    (_ctx.checkPropExists('patient_profile.birth.date'))
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                          _createElementVNode("div", _hoisted_14, [
                                            _createVNode(_component_date_input, {
                                              ruleKey: "patient_profile.birth.date",
                                              inputId: "demographics-personal-dob",
                                              name: _ctx.$t('dob'),
                                              modelValue: _ctx.editState.personal.date_of_birth,
                                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.personal.date_of_birth) = $event)),
                                              minDateLimit: _ctx.getDobMinDateLimit,
                                              maxDateLimit: _ctx.getDobMaxDateLimit
                                            }, null, 8, ["name", "modelValue", "minDateLimit", "maxDateLimit"])
                                          ]),
                                          _createElementVNode("div", _hoisted_15, [
                                            _createVNode(_component_text_input, {
                                              inputId: "demographics-personal-age",
                                              name: _ctx.$t('age'),
                                              disabled: true,
                                              calculated: true,
                                              modelValue: _ctx.calculatedAge,
                                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.calculatedAge) = $event))
                                            }, null, 8, ["name", "modelValue"])
                                          ])
                                        ], 64))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.government_identification.number'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                          _createVNode(_component_text_input, {
                                            ruleKey: "patient_profile.government_identification.number",
                                            inputId: "demographics-personal-government_identification_number",
                                            name: _ctx.$t('government_identification_number'),
                                            modelValue: _ctx.editState.personal.governmentIdentificationNumber,
                                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.personal.governmentIdentificationNumber) = $event)),
                                            mask: _ctx.getMaskFromRules('patient_profile.government_identification.number')
                                          }, null, 8, ["name", "modelValue", "mask"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.race_codes'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                          _createVNode(_component_select_input, {
                                            ruleKey: "patient_profile.race_codes",
                                            selectId: "demographics-personal-race",
                                            multiple: true,
                                            disabled: !_ctx.canSave,
                                            name: _ctx.$t('race'),
                                            modelValue: _ctx.editState.personal.race,
                                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.personal.race) = $event)),
                                            options: _ctx.raceOptions
                                          }, null, 8, ["disabled", "name", "modelValue", "options"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.ethnicity_code'))
                                      ? (_openBlock(), _createBlock(_component_select_other_input, {
                                          key: 3,
                                          ruleKey: "patient_profile.ethnicity_code",
                                          "select-id": "demographics-personal-ethnicity",
                                          name: _ctx.$t('ethnicity'),
                                          validationId: "ethnicity_code",
                                          modelValue: _ctx.editState.personal.ethnicity,
                                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.personal.ethnicity) = $event)),
                                          options: _ctx.ethnicityLookup,
                                          onChange: _ctx.clearEthnicityOther,
                                          otherTitle: _ctx.$t('ethnicity_other'),
                                          enableOther: _ctx.showEthnicityOther,
                                          colStyling: "standard-form-group-with-other",
                                          reduceColumnWidth: "standard-form-group"
                                        }, {
                                          other: _withCtx(() => [
                                            _createVNode(_component_text_input, {
                                              ruleKey: "patient_profile.ethnicity_other",
                                              "input-id": "demographics-personal-ethnicityother",
                                              name: _ctx.$t('ethnicity_other'),
                                              modelValue: _ctx.editState.personal.ethnicity_other,
                                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.personal.ethnicity_other) = $event))
                                            }, null, 8, ["name", "modelValue"])
                                          ]),
                                          _: 1
                                        }, 8, ["name", "modelValue", "options", "onChange", "otherTitle", "enableOther"]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.indigenous_identity_codes'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                          _createVNode(_component_select_input, {
                                            ruleKey: "patient_profile.indigenous_identity_codes",
                                            selectId: "demographics-personal-indigenous_identity_codes",
                                            multiple: true,
                                            disabled: !_ctx.canSave,
                                            name: _ctx.$t('indigenous_identity_codes'),
                                            modelValue: _ctx.editState.personal.indigenousIdentityCodes,
                                            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.personal.indigenousIdentityCodes) = $event)),
                                            options: _ctx.indigenousIdentityOptions
                                          }, null, 8, ["disabled", "name", "modelValue", "options"])
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _createElementVNode("div", _hoisted_19, [
                                    (_ctx.checkPropExists('patient_profile.sex'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                          _createVNode(_component_select_input, {
                                            ruleKey: "patient_profile.sex",
                                            selectId: "demographics-internal-sex",
                                            name: _ctx.$t('sex'),
                                            modelValue: _ctx.editState.personal.sex,
                                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState.personal.sex) = $event)),
                                            options: _ctx.sexOptions
                                          }, null, 8, ["name", "modelValue", "options"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.displayInUtero)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                          _createVNode(_component_boolean_radio_input, {
                                            ruleKey: "patient_profile.in_utero",
                                            "input-id": "demographics-personal-in-utero",
                                            labelName: _ctx.$t('in_utero'),
                                            acceptId: true,
                                            declineId: false,
                                            acceptLabel: _ctx.$t('yes'),
                                            declineLabel: _ctx.$t('no'),
                                            modelValue: _ctx.editState.personal.in_utero,
                                            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editState.personal.in_utero) = $event))
                                          }, null, 8, ["labelName", "acceptLabel", "declineLabel", "modelValue"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.gender_sex_different'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                          _createVNode(_component_checkbox_input, {
                                            ruleKey: "patient_profile.gender_sex_different",
                                            validationId: "gender_sex_different",
                                            "input-id": "demographics-personal-gender-different",
                                            labelName: _ctx.$t('gender_is_diff'),
                                            modelValue: _ctx.editState.personal.gender_sex_different,
                                            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editState.personal.gender_sex_different) = $event)),
                                            label: _ctx.$t('yes')
                                          }, null, 8, ["labelName", "modelValue", "label"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.editState.personal.gender_sex_different && _ctx.checkPropExists('patient_profile.gender'))
                                      ? (_openBlock(), _createBlock(_component_select_other_input, {
                                          key: 3,
                                          ruleKey: "patient_profile.gender",
                                          "select-id": "demographics-personal-gender",
                                          name: _ctx.$t('gender'),
                                          validationId: "gender",
                                          modelValue: _ctx.editState.personal.gender,
                                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.editState.personal.gender) = $event)),
                                          options: _ctx.genderLookup,
                                          onChange: _ctx.clearGenderOther,
                                          otherTitle: _ctx.$t('gender_other'),
                                          colStyling: "standard-form-group-with-other",
                                          reduceColumnWidth: "standard-form-group",
                                          enableOther: _ctx.checkPropExists('patient_profile.gender_other')
                                        }, {
                                          other: _withCtx(() => [
                                            _createVNode(_component_text_input, {
                                              ruleKey: "patient_profile.gender_other",
                                              "input-id": "demographics-personal-other-gender",
                                              name: _ctx.$t('gender_other'),
                                              modelValue: _ctx.editState.personal.gender_other,
                                              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editState.personal.gender_other) = $event))
                                            }, null, 8, ["name", "modelValue"])
                                          ]),
                                          _: 1
                                        }, 8, ["name", "modelValue", "options", "onChange", "otherTitle", "enableOther"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  (_ctx.checkAtLeastOnePropExists(['patient_profile.education.highest_education_level', 'patient_profile.education.academic_grade_level']))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                        (_ctx.checkPropExists('patient_profile.education.highest_education_level'))
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                              _createVNode(_component_select_input, {
                                                ruleKey: "patient_profile.education.highest_education_level",
                                                selectId: "demographics-personal-highest_education_level",
                                                name: _ctx.$t('highest_education_level'),
                                                modelValue: _ctx.editState.personal.highestEducationLevel,
                                                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.editState.personal.highestEducationLevel) = $event)),
                                                options: _ctx.highestEducationLevelOptions
                                              }, null, 8, ["name", "modelValue", "options"])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (_ctx.checkPropExists('patient_profile.education.academic_grade_level'))
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                              _createVNode(_component_select_input, {
                                                ruleKey: "patient_profile.education.academic_grade_level",
                                                selectId: "demographics-personal-academic_grade_level",
                                                name: _ctx.$t('academic_grade_level'),
                                                modelValue: _ctx.editState.personal.academicGradeLevel,
                                                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.editState.personal.academicGradeLevel) = $event)),
                                                options: _ctx.academicGradeLevelOptions
                                              }, null, 8, ["name", "modelValue", "options"])
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    : _createCommentVNode("", true),
                                  (_ctx.checkPropExists('patient_profile.insurance'))
                                    ? (_openBlock(), _createElementBlock("fieldset", {
                                        key: 1,
                                        disabled: !_ctx.isGroupWriteable('recipient_insurance')
                                      }, [
                                        _createElementVNode("div", _hoisted_27, [
                                          _createVNode(_component_select_other_input, {
                                            ruleKey: "patient_profile.insurance.plan_code",
                                            selectId: "demographics-personal-insurancetype",
                                            name: _ctx.$t('insurance_type'),
                                            modelValue: _ctx.editState.personal.insurance_type,
                                            "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.editState.personal.insurance_type) = $event)),
                                            options: _ctx.insurancePlanCodeLookup,
                                            onChange: _ctx.clearInsurance,
                                            validationId: "plan_code",
                                            reduceColumnWidth: "standard-form-group",
                                            colStyling: "standard-form-group-with-other"
                                          }, {
                                            other: _withCtx(() => [
                                              _createVNode(_component_text_input, {
                                                ruleKey: "patient_profile.insurance.other_plan",
                                                "input-id": "demographics-personal-insuranceother",
                                                name: _ctx.$t('other_insurance_detail'),
                                                modelValue: _ctx.editState.personal.insurance_other,
                                                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.editState.personal.insurance_other) = $event))
                                              }, null, 8, ["name", "modelValue"])
                                            ]),
                                            _: 1
                                          }, 8, ["name", "modelValue", "options", "onChange"]),
                                          (_ctx.showOtherProvinceInsurance)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                                _createVNode(_component_select_input, {
                                                  ruleKey: "patient_profile.insurance.other_province_plan",
                                                  "select-id": "demographics-personal-provinceother",
                                                  name: _ctx.$t('other_province_insurance'),
                                                  modelValue: _ctx.editState.personal.insurance_province,
                                                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.editState.personal.insurance_province) = $event)),
                                                  options: _ctx.otherProvinceDropdown
                                                }, null, 8, ["name", "modelValue", "options"])
                                              ]))
                                            : _createCommentVNode("", true),
                                          (_ctx.showNumber)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                                _createVNode(_component_text_input, {
                                                  ruleKey: "patient_profile.insurance.number",
                                                  inputId: "demographics-personal-insurancenumber",
                                                  name: _ctx.$t('insurance_number'),
                                                  modelValue: _ctx.editState.personal.insurance_number,
                                                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.editState.personal.insurance_number) = $event)),
                                                  mask: _ctx.getMaskFormatType(_ctx.editState.personal.insurance_type)
                                                }, null, 8, ["name", "modelValue", "mask"])
                                              ]))
                                            : _createCommentVNode("", true)
                                        ])
                                      ], 8, _hoisted_26))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["title", "disabled"]))
                      : _createCommentVNode("", true),
                    _withDirectives(_createVNode(_component_sub_section, {
                      title: _ctx.$t('financial_assistance'),
                      "sub-section-id": "demographics-fa",
                      disabled: !_ctx.isGroupWriteable('recipient_financial'),
                      hideDivider: true
                    }, {
                      contents: _withCtx(() => [
                        (_ctx.editState.financial_assistance)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createElementVNode("div", _hoisted_30, [
                                _createElementVNode("div", _hoisted_31, [
                                  _createVNode(_component_select_input, {
                                    selectId: "demographics-fa-tper_status",
                                    name: _ctx.$t('tper_status'),
                                    modelValue: _ctx.editState.financial_assistance.status,
                                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.editState.financial_assistance.status) = $event)),
                                    options: _ctx.tperStatusLookup,
                                    onChange: _ctx.clearTPERDate
                                  }, null, 8, ["name", "modelValue", "options", "onChange"])
                                ]),
                                _createElementVNode("div", _hoisted_32, [
                                  _createVNode(_component_date_input, {
                                    ruleKey: "patient_profile.financial_assistance.date",
                                    inputId: "demographics-fa-tper_date",
                                    name: _ctx.$t('tper_status_date'),
                                    modelValue: _ctx.editState.financial_assistance.tper_date,
                                    "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.editState.financial_assistance.tper_date) = $event)),
                                    rules: { required: _ctx.isTperStatusDateRequired }
                                  }, null, 8, ["name", "modelValue", "rules"])
                                ]),
                                _createElementVNode("div", _hoisted_33, [
                                  _createVNode(_component_text_area_input, {
                                    inputId: "demographics-fa-tper-comment",
                                    name: _ctx.$t('tper_comment'),
                                    modelValue: _ctx.editState.financial_assistance.tper_comment,
                                    "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.editState.financial_assistance.tper_comment) = $event))
                                  }, null, 8, ["name", "modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_34, [
                                  _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t('tper_attachments')), 1),
                                  _createElementVNode("input", {
                                    "input-id": "demographics-fa-tper-attachment",
                                    ref: "fileUploader",
                                    type: "file",
                                    class: "form-control",
                                    onChange: _cache[31] || (_cache[31] = ($event: any) => (_ctx.onTperAttachmentsChanged($event)))
                                  }, null, 544)
                                ])
                              ]),
                              (_ctx.financialAttachments)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('uploaded_documents')), 1),
                                    _createElementVNode("ul", _hoisted_36, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.financialAttachments, (attachement) => {
                                        return (_openBlock(), _createElementBlock("li", {
                                          key: attachement.id,
                                          class: "list-inline-item"
                                        }, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: ['fas', 'circle'],
                                            "fixed-width": "",
                                            size: "sm",
                                            class: "text-grey mr-2"
                                          }),
                                          _createElementVNode("a", {
                                            href: "#",
                                            onClick: _withModifiers(($event: any) => (_ctx.generateDownloadLink(attachement.id)), ["prevent","stop"]),
                                            title: _ctx.$t('download_this_document')
                                          }, [
                                            _createVNode(_component_font_awesome_icon, {
                                              class: "text-grey mr-2",
                                              icon: ['far', 'download'],
                                              size: "sm",
                                              "aria-hidden": "true",
                                              "fixed-width": ""
                                            }),
                                            _createTextVNode(" " + _toDisplayString(attachement.name), 1)
                                          ], 8, _hoisted_37),
                                          (_ctx.isGroupWriteable('recipient_financial'))
                                            ? (_openBlock(), _createElementBlock("a", {
                                                key: 0,
                                                href: "#",
                                                title: "Delete this document",
                                                onClick: _withModifiers(($event: any) => (_ctx.onDeleteFile(attachement.id, attachement.name)), ["prevent"])
                                              }, [
                                                _createVNode(_component_font_awesome_icon, {
                                                  class: "text-grey mr-2",
                                                  icon: ['fa', 'trash'],
                                                  size: "sm",
                                                  "aria-hidden": "true",
                                                  "fixed-width": "",
                                                  style: {"color":"#8b0000"}
                                                })
                                              ], 8, _hoisted_38))
                                            : _createCommentVNode("", true)
                                        ]))
                                      }), 128))
                                    ])
                                  ], 64))
                                : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["title", "disabled"]), [
                      [_vShow, _ctx.isFinancialAssistanceEnabled && _ctx.isFinancialAssistanceNeeded()]
                    ])
                  ]),
                  save: _withCtx(() => [
                    (_ctx.showSaveToolbar)
                      ? (_openBlock(), _createBlock(_component_save_toolbar, {
                          key: 0,
                          ref: "saveDemographics",
                          label: _ctx.newRecipient ? _ctx.$t('save_recipient') : _ctx.$t('save_demographics'),
                          onSave: _cache[32] || (_cache[32] = ($event: any) => (_ctx.savePatch(false))),
                          cancelButton: true,
                          onCancel: _cache[33] || (_cache[33] = ($event: any) => (_ctx.cancelPatch()))
                        }, null, 8, ["label"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 512)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["lookups-to-load", "disabled"]))
}