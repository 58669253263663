export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "recipient_data_audit_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Data Audit Detail Report"])},
        "recipient_data_audit_index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Data Audit Index Report"])},
        "generate_recipient_data_audit_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Data Audit Detail Report"])},
        "generate_recipient_data_audit_index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Data Audit Index Report"])},
        "from_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Start Date"])},
        "to_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit End Date"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
        "change_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Type"])},
        "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital Name"])},
        "organ_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Code"])}
      },
      "fr": {
        "recipient_data_audit_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Data Audit Detail Report"])},
        "recipient_data_audit_index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Data Audit Index Report"])},
        "generate_recipient_data_audit_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Data Audit Detail Report"])},
        "generate_recipient_data_audit_index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Data Audit Index Report"])},
        "from_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Start Date"])},
        "to_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit End Date"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
        "change_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Type"])},
        "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital Name"])},
        "organ_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Code"])}
      }
    }
  })
}
