import { resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_select_input, _mergeProps({
        modelValue: _ctx.selectLocalValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectLocalValue) = $event)),
        name: _ctx.name,
        "select-id": _ctx.selectId,
        "validation-id": _ctx.validationId,
        options: _ctx.options,
        rules: _ctx.rules,
        label: _ctx.label,
        disabled: _ctx.disabled,
        "null-text": _ctx.nullText,
        "text-key": _ctx.textKey,
        "value-key": _ctx.valueKey,
        "undefined-text": _ctx.undefinedText,
        readonly: _ctx.readonly,
        "hide-label": _ctx.hideLabel,
        ruleKey: _ctx.ruleKey,
        crossValues: _ctx.crossValues,
        numeric: _ctx.numeric
      }, _toHandlers(_ctx.selectEvents()), { filterByLookupRule: _ctx.filterByLookupRule }), null, 16, ["modelValue", "name", "select-id", "validation-id", "options", "rules", "label", "disabled", "null-text", "text-key", "value-key", "undefined-text", "readonly", "hide-label", "ruleKey", "crossValues", "numeric", "filterByLookupRule"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.showProvince)
        ? _renderSlot(_ctx.$slots, "province", { key: 0 })
        : (_ctx.showState)
          ? _renderSlot(_ctx.$slots, "state", { key: 1 })
          : (_ctx.showOther)
            ? _renderSlot(_ctx.$slots, "other", { key: 2 })
            : _createCommentVNode("", true)
    ])
  ]))
}