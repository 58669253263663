import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_3 = {
  key: 1,
  class: "legend-title"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = {
  key: 0,
  class: "row"
}
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_boolean_radio_input = _resolveComponent("boolean-radio-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: _ctx.$t('crossmatch_detail'),
    "sub-section-id": "crossmatch-sample",
    "table-config": _ctx.crossmatchSampleTableConfig,
    "save-button": !!_ctx.selectedCrossmatch,
    disabled: !_ctx.canSave,
    "save-button-text": _ctx.$t('save_crossmatch_detail'),
    onSave: _cache[10] || (_cache[10] = ($event: any) => (_ctx.savePatch())),
    ref: "saveCrossmatchSample",
    onTableRowClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.selectCrossmatchSample($event))),
    onTableCreateRow: _cache[12] || (_cache[12] = ($event: any) => (_ctx.createCrossmatchSample()))
  }, {
    contents: _withCtx(() => [
      (!_ctx.editState || !_ctx.editState.crossmatchSample)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
          ], 64))
        : (_openBlock(), _createElementBlock("fieldset", {
            key: 1,
            disabled: !_ctx.selectedCrossmatch
          }, [
            _createElementVNode("legend", null, [
              (!_ctx.selectedCrossmatchSample)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('new_crossmatch_detail')), 1))
                : (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.$t('selected_crossmatch_detail')), 1))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_date_input, {
                  "input-id": "crossmatch-sample-serum_date",
                  rules: "required",
                  name: _ctx.$t('serum_date'),
                  modelValue: _ctx.editState.crossmatchSample.serumDate,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.crossmatchSample.serumDate) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_text_input, {
                  "input-id": "crossmatch-sample-recipient_id",
                  rules: "required",
                  cssClasses: { 'is-valid': !!_ctx.recipient },
                  onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getRecipientInfo($event))),
                  name: _ctx.$t('recipient_id'),
                  modelValue: _ctx.editState.crossmatchSample.recipientClientId,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.crossmatchSample.recipientClientId) = $event))
                }, null, 8, ["cssClasses", "name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_boolean_radio_input, {
                  "input-id": "crossmatch-sample-type",
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearAlloValues($event))),
                  rules: "required",
                  labelName: _ctx.$t('type'),
                  acceptId: "allo",
                  declineId: "auto",
                  acceptLabel: _ctx.$t('allo'),
                  declineLabel: _ctx.$t('auto'),
                  modelValue: _ctx.editState.crossmatchSample.type,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.crossmatchSample.type) = $event))
                }, null, 8, ["labelName", "acceptLabel", "declineLabel", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_select_input, {
                  "select-id": "crossmatch-sample-t_cell",
                  name: _ctx.$t('t_cell_result'),
                  modelValue: _ctx.editState.crossmatchSample.tCellResult,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.crossmatchSample.tCellResult) = $event)),
                  options: _ctx.cellResultLookup
                }, null, 8, ["name", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_select_input, {
                  "select-id": "crossmatch-sample-b_cell",
                  name: _ctx.$t('b_cell_result'),
                  modelValue: _ctx.editState.crossmatchSample.bCellResult,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.crossmatchSample.bCellResult) = $event)),
                  options: _ctx.cellResultLookup
                }, null, 8, ["name", "modelValue", "options"])
              ])
            ]),
            (_ctx.isAlloSample)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_select_input, {
                      "select-id": "crossmatch-sample-treatment",
                      name: _ctx.$t('treatment'),
                      modelValue: _ctx.editState.crossmatchSample.treatment,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.crossmatchSample.treatment) = $event)),
                      options: _ctx.treatmentLookup
                    }, null, 8, ["name", "modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_boolean_radio_input, {
                      "input-id": "crossmatch-sample-pronase",
                      labelName: _ctx.$t('pronase'),
                      acceptId: true,
                      declineId: false,
                      acceptLabel: _ctx.$t('yes'),
                      declineLabel: _ctx.$t('no'),
                      modelValue: _ctx.editState.crossmatchSample.pronase,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.crossmatchSample.pronase) = $event))
                    }, null, 8, ["labelName", "acceptLabel", "declineLabel", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_select_input, {
                      "select-id": "crossmatch-sample-dsa",
                      name: _ctx.$t('dsa'),
                      modelValue: _ctx.editState.crossmatchSample.dsa,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.crossmatchSample.dsa) = $event)),
                      options: _ctx.dsaLookup
                    }, null, 8, ["name", "modelValue", "options"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 8, _hoisted_1))
    ]),
    _: 1
  }, 8, ["title", "table-config", "save-button", "disabled", "save-button-text"]))
}