export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "urgent_listings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgent Listings"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customizer le panneau"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse Panel"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Options"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Style"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this panel"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "link_to_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Donor Page"])},
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Recipient Page"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
        "no_urgent_listings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Urgent Listings"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient ID"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "referral_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Date"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ(s)"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])}
      },
      "fr": {
        "urgent_listings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscriptions urgentes"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le panneau"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau rabattu"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options du panneau"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style du panneau"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher ce panneau"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])},
        "link_to_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page des donneurs"])},
        "link_to_recipient_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page des patients"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])},
        "no_urgent_listings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune inscription urgente"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Patient"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "referral_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de référence"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe(s)"])}
      }
    }
  })
}
