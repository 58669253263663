<template>
  <sub-section sub-section-id="summary">
    <template v-slot:contents v-if="editState">
      <div class="card summary-card">
        <div class="card-header">
          <div class="media align-items-center">
            <div class="media-left">
              <font-awesome-icon :icon="['far', 'id-card']" fixed-width />
            </div>
            <div class="media-body flex-row align-items-center">
              <h3 class="summary-name">{{ livingDonorDisplayName }}</h3>
              <ul class="nav card-nav">
                <li class="nav-item">
                  <router-link :to="{ name: 'edit-living-donor', params: { id: livingDonor.client_id } }" class="nav-link">{{$t('living_donor_information')}}</router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'edit-living-donor', params: { id: livingDonor.client_id }, hash: '#hla-typing' }" class="nav-link">{{ $t('hla_information') }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row summary-list">
            <dl>
              <dt>{{ $t('date_of_birth') }}</dt>
              <dd>{{ editState.date_of_birth }} </dd>
            </dl>
            <dl>
              <dt>{{ $t('living_donor_id') }}</dt>
              <dd>{{ editState.living_donor_id }} </dd>
            </dl>
            <dl>
              <dt>{{ $t('sex') }}</dt>
              <dd>{{ editState.sex }} </dd>
            </dl>
            <dl>
              <dt>{{ $t('age') }}</dt>
              <dd>{{ editState.age }} </dd>
            </dl>
            <dl>
              <dt>{{ $t('height') }}</dt>
              <dd>{{ editState.height }} </dd>
            </dl>
            <dl>
              <dt>{{ $t('weight') }}</dt>
              <dd>{{ editState.weight }} </dd>
            </dl>
            <dl>
              <dt>{{ $t('insurance') }} #</dt>
              <dd>{{ editState.insurance_number }} </dd>
            </dl>
            <dl>
              <dt>{{ $t('virology_results') }}</dt>
                <router-link :to="{ name: 'edit-living-donor', params: { id: editState.client_id }, hash: '#virology' }">[{{$t('view')}}]</router-link>
            </dl>
            <dl class="shortitem">
              <dt>{{ $t('abo') }}</dt>
              <dd>{{editState.abo_sub_type || editState.abo}}</dd>
            </dl>
            <dl v-if="editState.transplant_program">
              <dt>{{ $t('referral_hospital') }}</dt>
              <dd>{{ editState.transplant_program }} </dd>
            </dl>
          </div>
          <hr/>
          <div class="summary-items">
              <div class="row">
                <div class="summary-journey-item summary-item" v-for="(consentedOrgan, idx) in selectedLivingDonorConsentedOrganList" :key="idx" >
                  <div :class="listItemClass(consentedOrgan)">
                    <router-link :to="consentedOrgan.route" class="organ-row">
                        <organ-icon :organ-code="consentedOrgan.organ_code" />
                      <div class="summary-organ">
                        {{$t(`${consentedOrgan.display_name}`)}}
                      </div>
                    </router-link>
                    <div class="organ-summary-wrap">
                      <template v-if="isLoadingAllocations">
                        <span class="skeleton-box w-50" />
                        <span class="skeleton-box w-100" />
                      </template>
                      <template v-else>
                        <ul class="organ-summary-list" v-if="consentedOrgan.allocationId" :title="$t('allocation_id')">
                          <li>
                            {{ $t('allocation_id') }} {{ consentedOrgan.allocationId }}
                          </li>
                        </ul>
                        <ul class="organ-summary-list" v-if="consentedOrgan.allocationId" :title="$t('allocation_run_datetime')">
                          <li>
                            <font-awesome-icon :icon="['far', 'clock']" fixed-width />
                            {{ formatDateTime(consentedOrgan.allocationRunDateTime) }}
                          </li>
                        </ul>
                        <ul class="organ-summary-list" v-if="consentedOrgan.allocationId" :title="$t('allocation_stage_field')">
                          <li>
                            {{ $t(consentedOrgan.allocationStage) }}
                          </li>
                        </ul>
                        <ul class="organ-summary-list" :title="$t('consented_date_label')">
                          <li>
                            <font-awesome-icon :icon="['far', 'clock']" fixed-width />
                            {{ formatDate(consentedOrgan.consented_date) }}
                          </li>
                        </ul>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </template>
  </sub-section>
</template>

<i18n src="@/views/_locales/common.json"></i18n>
<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/DonorSummary.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/commonPatientShared.json"></i18n>
<i18n src="@/components/allocations/_locales/_AllocationDetails.json"></i18n>
<i18n src="@/components/livingDonors/_locales/DonationInformation.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import { titleCase, mergeClasses, isMasked } from '@/utils';
import SubSection from '@/components/shared/SubSection.vue';
import { LivingDonor, LivingDonorOrgan, LivingDonorAllocationSummary, LivingDonorJourney } from '@/store/livingDonors/types';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { Organ, AllDispositionStatus } from '@/store/lookups/types';
import DiscontinueOrgansModal from '@/components/allocations/_DiscontinueOrgansModal.vue';
import { GenericCodeValue } from '@/store/types';
import { Hospital } from '@/store/hospitals/types';
import { Allocations } from '@/store/allocations/types';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import { ClassObject } from '@/types';
import { EP } from '@/api-endpoints';

interface LivingDonorSummaryForm {
  date_of_birth?: string|null|undefined;
  living_donor_id?: number;
  system_id?: number;
  donor_name?: string;
  sex?: string;
  age?: string|number;
  abo?: string|null;
  abo_sub_type?: string|null;
  donor_type?: string;
  height?: number;
  weight?: number;
  insurance_number?: string;
  region?: string;
  serology?: string;
  transplant_program?: string|null;
}

@Component({
   components: {
    SubSection,
    OrganIcon,
    DiscontinueOrgansModal
   },
})
export default class LivingDonorSummary extends mixins(DateUtilsMixin) {
  @State(state => state.pageState.currentPage.livingDonorSummary) editState!: LivingDonorSummaryForm;
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.livingAllocations.isLoadingAllocation) isLoadingAllocation!: boolean;
  @State(state => state.livingAllocations.isLoadingAllocations) isLoadingAllocations!: boolean;
  @State(state => state.livingAllocations.isDiscontinuingAllAllocations) isDiscontinuingAllAllocations!: boolean;

  //Getters
  @Getter('livingDonorId', { namespace: 'livingDonors' }) private livingDonorId!: string;
  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string;
  @Getter('livingDonorDisplayName', { namespace: 'livingDonors' } ) private livingDonorDisplayName!: string;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('getOntarioHospitalById', { namespace: 'hospitals' }) private getOntarioHospitalById!: (hospitalId?: string|null) => Hospital|null;
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('selectedLivingDonorConsentedOrganList', { namespace: 'livingDonors'}) private selectedLivingDonorConsentedOrganList!: LivingDonorAllocationSummary[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('getDonorAge', { namespace: 'livingDonors' }) getDonorAge!: number|undefined;
  @Getter('selectedLivingDonorJourney', { namespace: 'livingDonors' }) selectedLivingDonorJourney!: LivingDonorJourney|null;

  @Prop({ default: '' }) inputClass!: string; // Class string for list

  public lookupsToLoad = [];
  private isFinishedLoadingEvents = false;

  /**
   * Get hash for donation information section if allowed to see the section
   *
   * @returns {string} return hash to section, otherwise empty
   */
  get allowJumpToDonationInformation(): string {
    const apiAllowed = this.checkAllowed(EP.living_donors.show, "GET");
    return apiAllowed ? "#donation-information" : "";
  }

  /**
   * Get a boolean represenation of if we have any active allocations
   *
   * @returns {boolean} true if there are active allocations
   */
  get showDiscontinue(): boolean {
    // TODO: replace this - check API for active allocations (not implemented yet)
    const apiAllowed = this.checkAllowed(EP.living_donors.allocations.offers.discontinue_offers, "POST");
    return this.selectedLivingDonorConsentedOrganList.length > 0 && apiAllowed;
  }

   //Getter - Selected organ shown as active
  get listItemClass(){
    return (consentedOrgan: any): ClassObject => {
      const option = this.$route.params.option;
      const organCode = this.$route.params.organ_code;
      const consentedOrganRoute = consentedOrgan.route.params.option;
      const consentedOrganCode = consentedOrgan.organ_code;
      let isActive : any;

      if(consentedOrgan.route.params.option)
      {
        isActive = consentedOrgan.display_name.toLowerCase().includes(option) && organCode == consentedOrganCode.toString();
      } else {
        isActive = organCode == consentedOrganCode.toString();
      }
      //Define dynamic class based on the selected organ
      const conditionalClass = {
        'active' : isActive
      };
      return conditionalClass;

    };
  }

  /**
   * Initializes the Journey Status form state after recipient events, lookups, and all subsections have finished loading/mounting
   *
   * Emits a loaded event to notify its page layout that loading of this card section has completed
   *
   * @emits loaded
   */
  private checkIfLoadingComplete(): void {
    if (this.isFinishedLoadingEvents) {
      this.$store.dispatch('hospitals/load').then(() => {
        this.initializeForm();
      });
    }
  }

  @Watch('livingDonor', { immediate: true })
  private onLivingDonorChanged(): void {
    this.initializeForm();
  }

  public loaded(): void {
    this.$emit('loaded', 'livingDonorSummary');
  }

  // Private methods
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'livingDonorSummary',
      value: this.extractDonorSummaryForm(this.livingDonor)
    });
  }

  // Format date-only field for display
  public formatDate(dateValue: string): string|undefined {
    if (!isNaN(Date.parse(dateValue))) {
      return this.parseDisplayDateUi(dateValue);
    } else {
      return dateValue;
    }
  }

  // Format combined date/time for display
  public formatDateTime(dateTimeValue: string): string|undefined {
    if (!isNaN(Date.parse(dateTimeValue))) {
      return this.parseFormattedDateTimeUi(dateTimeValue);
    } else {
      return dateTimeValue;
    }
  }

  public toggleClass(organ_code: string){
    let organCode = this.$route.params.organ_code;
    return organ_code == organCode? true : false;
  }

  public extractDonorSummaryForm(donor?: LivingDonor): LivingDonorSummaryForm {
    // Return empty object if there is no data document
    if (!donor) {
      return {};
    }
    // Sanitize nested objects, so their parameters can easily be read as 'undefined' instead of raising type errors
    const patientProfile = donor.patient_profile || {};
    const birth = donor.patient_profile?.birth || {};
    const referral = donor.referral || {};
    const blood = donor.blood || {};
    const donorMeasurements = donor.measurements || [];
    const insurance = donor.patient_profile?.insurance;

    const addresses = patientProfile.addresses || {};
    // Most recent measurement will be first in the array
    const measurement = donorMeasurements.length > 0 ? donorMeasurements[0] : {};

    const hospital = this.selectedLivingDonorJourney?.transplant_program ? this.getOntarioHospitalById(this.selectedLivingDonorJourney?.transplant_program?.transplant_hospital_id?.$oid) : undefined;
    const hospitalName = hospital?.hospital_name_info.abbreviation;

    const birthDate = !isMasked(birth.date) ? this.parseDisplayDateUi(birth.date) : birth.date;

    // Return parameters extracted from data document based on structure of form state interface
    return {
      date_of_birth: birthDate,
      living_donor_id: donor.living_donor_id,
      system_id: donor.client_id,
      donor_name: patientProfile.first_name && patientProfile.last_name,
      sex: patientProfile.sex || undefined,
      age: isMasked(birth.date) ? '*******' : this.getDonorAge,
      abo: blood.type,
      abo_sub_type: blood.sub_type,
      height: measurement.height,
      weight: measurement.weight,
      insurance_number: insurance?.number || undefined,
      transplant_program: hospitalName || undefined
    };
  }
}

</script>
