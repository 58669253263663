import { format, addSeconds, differenceInCalendarDays } from 'date-fns';
import { Getter } from "vuex-facing-decorator";
import { Component, Vue } from 'vue-facing-decorator';
import { parseDateUi, parseDisplayDateUi, parseDisplayDateUiFromDateTime, sanitizeDateApi } from '@/utilities/date-utils';
import { UI_MODEL_DATE, UI_MODEL_TIME, API_SAVE_VALID_DATETIME } from '@/utilities/date-utils';

@Component
export class DateUtilsMixin extends Vue {
  @Getter('getDateFormat', { namespace: 'configuration' }) getDateFormat!: string;
  @Getter('systemTimeZone', { namespace: 'configuration' }) systemTimeZone!: string;

  @Getter('parseTimeUi', { namespace: 'utilities' }) parseTimeUi!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('parseFormattedDateUi', { namespace: 'utilities' }) parseFormattedDateUi!: (datetime?: string) => string|undefined;
  @Getter('parseDateTimeUi', { namespace: 'utilities' }) parseDateTimeUi!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('parseFormattedDateTimeUi', { namespace: 'utilities' }) parseFormattedDateTimeUi!: (datetime?: string) => string|undefined;
  @Getter('correctTimeOffset', { namespace: 'utilities' }) correctTimeOffset!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('sanitizeDateTimeApi', { namespace: 'utilities' }) sanitizeDateTimeApi!: (date?: string|null, time?: string|null) => string|null;
  @Getter('parseDisplayDateTimeUi', { namespace: 'utilities' }) parseDisplayDateTimeUi!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('parseDateUiFromDateTime', { namespace: 'utilities' }) parseDateUiFromDateTime!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('parseTimeUiFromDateTime', { namespace: 'utilities' }) parseTimeUiFromDateTime!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('sanitizeDateWithRequiredTimeApi', { namespace: 'utilities' }) sanitizeDateWithRequiredTimeApi!: (date: string, time?: string) => string|undefined;
  @Getter('sanitizeOptionalDateWithTimeApi', { namespace: 'utilities' }) sanitizeOptionalDateWithTimeApi!: (date?: string, time?: string) => string|undefined;
  @Getter('parseFormattedDateTimeUiFromDateTime', { namespace: 'utilities' }) parseFormattedDateTimeUiFromDateTime!: (datetime?: string) => string|undefined;
  @Getter('parseDisplayDateTimeUiFromDateTime', { namespace: 'utilities' }) parseDisplayDateTimeUiFromDateTime!: (datetime?: string|undefined|null) => string|undefined;

  /**
   * Calculates the days from a starting date and an integer containing seconds. This function uses
   * the date-fns library, first to 'addSeconds' to the specified 'startDate' to get an ending date
   * and then second to count the 'differenceInCalendarDays' between those two dates.
   *
   * This function also adds one to the result, as per Journey State counting rules (see AP-750).
   *
   * NOTE: the same number of seconds can give different results depending on when in the day we
   * begin counting. That is why this function requires not just the seconds but also a start date.
   *
   * @param startDate date/time when to start counting days
   * @param durationSeconds number value of seconds to count
   * @returns {number} number representing days
   */
  public formatSecondsToDays(startDate: string|null, durationSeconds: number|null): number {
    if (!startDate || durationSeconds == null) return 0;

    // use date-fns addSeconds method to determine starting and ending dates
    const startDateObject = new Date(startDate);
    const endDateObject = addSeconds(new Date(startDate), durationSeconds);

    // return the day portion
    return differenceInCalendarDays(endDateObject, startDateObject) + 1;
  }

  /**
   * Generate current date-only value for UI build form
   *
   * @returns {string} formatted current date 
   */
  public currentDateUi(): string {
    const currentDateObject = new Date();
    return format(currentDateObject, UI_MODEL_DATE);
  }

  /**
   * Generate current time-only value for UI build form
   *
   * @returns {string} formatted current time
   */
  public currentTimeUi(): string {
    const currentDateObject = new Date();
    return format(currentDateObject, UI_MODEL_TIME);
  }

  /**
   * Generate current date/time value for API extract patch
   *
   * @returns {string} ISO formatted current date/time
   */
  public currentDateTimeApi(): string {
    const currentDateObject = new Date();
    return format(currentDateObject, API_SAVE_VALID_DATETIME);
  }

  // Expose methods from utils
  public parseDateUi(apiDate?: string|null): string|undefined { return parseDateUi(apiDate); }
  public parseDisplayDateUi(apiDate?: string|null): string|undefined { return parseDisplayDateUi(apiDate); }
  public parseDisplayDateUiFromDateTime(apiDateTime?: string|null): string|undefined { return parseDisplayDateUiFromDateTime(apiDateTime); }
  public sanitizeDateApi(uiDate?: string|null): string|null { return sanitizeDateApi(uiDate); }
}
