<template>
  <div>
    <template v-if="!dispatchEventsComplete">
      <div class="col-sm-12">
        <div class="col-sm-12">
          {{ $t("loading") }}
        </div>
      </div>
    </template>
    <template v-else>
      <page-top>
        <router-link :to="{ name: 'list-living-donors' }">
          {{ $t("living_donors")}}
        </router-link>
        / {{ livingDonorDisplayName }}
      </page-top>
      <potential-duplicate-modal
        ref="potentialDuplicateModal"
        @savePatch="performSave(true)"
        @saveDemographics="handleContinueDemographics"
        @saveContactInfo="handleContinueContactInfo"
        @saveGeneralClinicalInformation="handleContinueGeneralClinicalInformation"
      />
      <living-donor-sticky-summary/>
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
           <side-nav-living-donor />
            <div class="page-content">
              <validation-observer ref="validations" autocomplete="off" tag="form">
                <!-- Profile Card Sections -->
                <living-donor-summary />
                <demographics
                  ref="demographics"
                  @loaded="(ref: string) => loaded(ref)"
                  @save="(sectionSaved: string) => handleSectionSave(sectionSaved)"
                  @handleErrors="(errors: SaveResult[]|SaveResult) => handleErrors(errors)"
                  :canSave="isGroupWriteable('living_donor_personal')"
                />
                <contact-information
                  ref="contactInfo"
                  @loaded="(ref: string) => loaded(ref)"
                  @save="(sectionSaved: string) => handleSectionSave(sectionSaved)"
                  @handleErrors="(errors: SaveResult[]|SaveResult) => handleErrors(errors)"
                  :canSave="isGroupWriteable('living_donor_personal')"
                />
                <link-to-recipient
                  ref="linkToRecipient"
                  @loaded="(ref: string) => loaded(ref)"
                  @save="(sectionSaved: string) => handleSectionSave(sectionSaved)"
                  @handleErrors="(errors: SaveResult[]|SaveResult) => handleErrors(errors)"
                  :canSave="isGroupWriteable('living_donor_personal') && !isLockRecoveryAndDonorInformation"
                  @reload="reload"
                />
                <donation-information
                  ref="donationInformation"
                  @loaded="(ref: string) => loaded(ref)"
                  @save="(sectionSaved: string) => handleSectionSave(sectionSaved)"
                  @handleErrors="(errors: SaveResult[]|SaveResult) => handleErrors(errors)"
                  :canSave="isGroupWriteable('living_donor_personal') && !isLockRecoveryAndDonorInformation"
                  @clear="resetValidationErrors"
                  @reload="reload"
                />
                <general-clinical-information
                  ref="generalClinical"
                  @loaded="(ref: string) => loaded(ref)"
                  @save="(sectionSaved: string) => handleSectionSave(sectionSaved)"
                  @handleErrors="(errors: SaveResult[]|SaveResult) => handleErrors(errors)"
                  @clear="resetValidationErrors"
                  :canSave="isGroupWriteable('living_donor_personal')"
                />
                <virology-results
                  ref="virology"
                  :newRecord="false"
                  :virologyType="virologyType"
                  @loaded="(ref: string) => loaded(ref)"
                  @handleErrors="(errors: SaveResult[]|SaveResult) => handleErrors(errors)"
                  @clear="resetValidationErrors"
                  :canSave="isGroupWriteable('living_donor_personal')"
                />
                <recovery-information
                  ref="recoveryInformation"
                  :newRecord="false"
                  @loaded="(ref: string) => loaded(ref)"
                  @handleErrors="(errors: SaveResult[]|SaveResult) => handleErrors(errors)"
                  @clear="resetValidationErrors"
                  :canSave="isGroupWriteable('living_donor_recovery_details')"
                  v-if="showRecoveryInformation"
                  @reload="reload"
                />

                <!-- HLA Information Card Sections -->
                <hla-typing
                  ref="hlaTypingLivingDonor"
                  :enableDonor="true"
                  :livingDonor="true"
                  @loaded="(ref: string) => loaded(ref)"
                  @save="(sectionSaved: string) => handleSectionSave(sectionSaved)"
                  @handleErrors="(errors: SaveResult[]|SaveResult) => handleErrors(errors)"
                  :canSave="checkAllowed('/living_donors/:living_donor_id/hla_typing', 'POST')"
                />

                <div class="form-btns">
                  <save-toolbar
                    ref="saveLivingDonor"
                    :label="$t('save_living_donor')"
                    @save="performSave()"
                    v-if="canSaveLivingDonor"
                  />
                </div>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Getter, State } from "vuex-facing-decorator";
import HlaTyping from '@/components/hla/HlaTyping.vue';
import { Component } from 'vue-facing-decorator';
import { mixins } from "vue-facing-decorator";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { VirologyType } from '@/store/labs/types';
import { SaveProvider, SaveResult } from '@/types';
import { LivingDonor } from '@/store/livingDonors/types';
import PageTop from '@/components/shared/PageTop.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import Demographics from '@/components/livingDonors/Demographics.vue';
import VirologyResults from '@/components/virology/VirologyResults.vue';
import LinkToRecipient from '@/components/livingDonors/LinkToRecipient.vue';
import ContactInformation from '@/components/livingDonors/ContactInformation.vue';
import LivingDonorSummary from '@/components/livingDonors/LivingDonorSummary.vue';
import DonationInformation from '@/components/livingDonors/DonationInformation.vue';
import SideNavLivingDonor from '@/components/livingDonors/side-nav/SideNavLivingDonor.vue';
import LivingDonorStickySummary from '@/components/livingDonors/LivingDonorStickySummary.vue';
import GeneralClinicalInformation from '@/components/livingDonors/GeneralClinicalInformation.vue';
import RecoveryInformation from '@/components/livingDonors/RecoveryInformation.vue';
import PotentialDuplicateModal from "@/components/livingDonors/PotentialDuplicateModal.vue";
import { EP } from '@/api-endpoints';
import { ACTIVE_REGION_TRANSPLANT_PROGRAM } from '@/store/hospitals/types';
import { i18nMessages } from "@/i18n";

@Component({
  components: {
    PageTop,
    HlaTyping,
    CardSection,
    SaveToolbar,
    Demographics,
    VirologyResults,
    LinkToRecipient,
    ContactInformation,
    SideNavLivingDonor,
    LivingDonorSummary,
    DonationInformation,
    LivingDonorStickySummary,
    GeneralClinicalInformation,
    RecoveryInformation,
    PotentialDuplicateModal
  },
  ...i18nMessages([
    require('../_locales/common.json'),
    require('../_locales/listLivingDonors.json'),
  ]),
})
export default class EditLivingDonor extends mixins(ValidationUtilsMixin) implements SaveProvider{
  // State
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

  // Getters
  @Getter('livingDonorDisplayName', { namespace: 'livingDonors' } ) private livingDonorDisplayName!: string;
  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('hasIntendedRecipients', { namespace: 'livingDonors' }) private hasIntendedRecipients!: boolean;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('isLockRecoveryAndDonorInformation', { namespace: 'livingDonors' }) private isLockRecoveryAndDonorInformation!: boolean;
  @Getter('showRecoveryInformation', { namespace: 'livingDonors' }) private showRecoveryInformation!: boolean;

  private dispatchEventsComplete = false;
  private sectionsLoaded = new Set();
  private allSectionsLoaded = false;

  get canSaveLivingDonor(): boolean {
    return this.checkAllowed(EP.living_donors.create, 'POST');
  }

  /**
   * Returns enum value VirologyType.LivingDonor
   *
   * @returns {number} enum value for Living Donor
   */
  get virologyType(): number {
    return VirologyType.LivingDonor;
  }

  // Vue lifecycle hooks
  public mounted(): void {
    Promise.all([
      this.$store.commit('setPageTitle', `LivingDonors / ${this.livingDonorDisplayName}`),
      this.$store.dispatch('hospitals/load', ACTIVE_REGION_TRANSPLANT_PROGRAM),
      this.$store.dispatch('livingAllocations/getAllAllocations', { clientId: this.clientId, clearSelectedAllocation: true }),
      this.$store.dispatch('validations/loadEdit', { view: 'living_donors', action: 'edit', clientId: this.clientId }),
    ]).finally(() => {
      this.dispatchEventsComplete = true;
    });
  }

  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Create a set of all the sections to load filtering out sections we don't care about
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations|saveLivingDonor|potentialDuplicateModal/)));
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }

  public handleContinueDemographics(): void {
    (this.$refs.demographics as Demographics).savePatch(true);
  }
  public handleContinueContactInfo(): void {
    (this.$refs.contactInfo as ContactInformation).savePatch(true);
  }
  public handleContinueGeneralClinicalInformation(): void {
    (this.$refs.generalClinical as GeneralClinicalInformation).savePatch(true);
  }
  
  public handleWarnings(errors: SaveResult, target: string): void {
    (this.$refs.potentialDuplicateModal as PotentialDuplicateModal).checkingDuplicateWarning(errors, target);
  }

  // Handle saving triggered by local save button
  public performSave(potential_duplicate_profile_confirmed?: boolean): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveLivingDonor as SaveToolbar;
    // Show appropriate notification
    saveToolbar.startSaving();
    // Ref for each component required in the patch
    const demographics = this.$refs.demographics as Demographics;
    const contactInfo = this.$refs.contactInfo as ContactInformation;
    const donationInformation = this.$refs.donationInformation as DonationInformation;
    const generalClinical = this.$refs.generalClinical as GeneralClinicalInformation;
    const recoveryInformation = this.$refs.recoveryInformation as RecoveryInformation;
    const virology = this.$refs.virology as VirologyResults;

    // Extract demographics and general clinical
    let livingDonorPatch: LivingDonor = {
      ...donationInformation.extractPatch(),
      ...generalClinical.extractPatch(),
      ...donationInformation.extractPatch(),
      ...recoveryInformation.extractPatch(),
      ...demographics.extractPatch(potential_duplicate_profile_confirmed)
    };

    // recovery information may or may not be there
    if (recoveryInformation) {
      livingDonorPatch = {...livingDonorPatch, ...recoveryInformation.extractPatch()};
    }

    // Extract contact info and merge with patient profile
    livingDonorPatch.patient_profile = {
      ...livingDonorPatch.patient_profile,
      ...contactInfo.extractPatch().patient_profile,
    };
    // Extract and add virology if exists
    if (!virology.isEmpty) {
      const virologyPatch = virology.extractPatch();
      // Separately extract the virology comments
      if (virologyPatch.virology_comments && livingDonorPatch) {
        Object.assign(livingDonorPatch.living_donor_info || {}, {
          virology_comments: virologyPatch.virology_comments
        });
        // Then delete the key so we don't include them in the virology patch
        delete virologyPatch.virology_comments;
      }
      livingDonorPatch.living_donor_info?.virology_comments;
      livingDonorPatch.virology_labs = [virologyPatch];
    }

    // Extract and add measurement if exists
    if (!generalClinical.isEmpty) {
      livingDonorPatch.measurements = [generalClinical.extractMeasurementPatch()];
    }

    // Clear any save toolbar messages
    demographics.resetSaveToolbar();
    contactInfo.resetSaveToolbar();
    generalClinical.resetSaveToolbar();

    if (recoveryInformation) {
      recoveryInformation.resetSaveToolbar();
    }

    // Clear previous errors
    (this.$refs.validations as any).setErrors({});

    // Attempt to save the livingDonor
    this.$store.dispatch('livingDonors/saveLivingDonorsPatch', livingDonorPatch).then((success: SaveResult) => {
      virology.loadVirologyLabs();
      this.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      this.registerSaveResult(error);
    });
  }

  // Handle result of save
  public registerSaveResult(result: SaveResult): void {
    /**
     * If successful, update the root record(s). Depending on what the page is intended to save, this could be one
     * record (e.g. EditLivingDonor updates the currently selected livingDonor), zero records, or multiple records.
     */
    if (result.success) {
      this.$store.commit('livingDonors/set', result.responseData.living_donor);
    } else {
      // Handle errors
      this.handleErrors(result, true);
    }
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveLivingDonor as SaveToolbar;
    // Show appropriate saving notification
    saveToolbar.stopSaving(result);
  }

  // Handle save events generated by descendent components
  private handleSectionSave(sectionSaved: string): void {
    (this.$refs.validations as any).setErrors({});
    this.resetSaveToolbar();
  }

  private resetValidationErrors(): void {
    (this.$refs.validations as any).setErrors({});
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult, mainSave?: boolean): void {
    mainSave = mainSave ? true : false;

    // Check validation keys for card sections that are always displayed
    const idLookup: IdLookup = {
      ...(this.$refs.demographics as Demographics).idLookup,
      ...(this.$refs.contactInfo as ContactInformation).idLookup,
      ...(this.$refs.donationInformation as DonationInformation).idLookup(),
      ...(this.$refs.generalClinical as GeneralClinicalInformation).idLookup,
      ...(this.$refs.recoveryInformation as RecoveryInformation).idLookup,
      ...(this.$refs.virology as VirologyResults).idLookup(),
      ...(this.$refs.linkToRecipient as LinkToRecipient).idLookup(),
      ...(this.$refs.hlaTypingLivingDonor as HlaTyping).idLookup(),
    };

    // Check validation keys for card sections that may or may not be shown
    const recoveryInformation = this.$refs.recoveryInformation as RecoveryInformation;
    if (recoveryInformation) {
      Object.assign(idLookup, {
        ...recoveryInformation.idLookup,
      });
    }

    // Standardize errors to an array of SaveResult objects to faciliate duplicate check
    errors = Array.isArray(errors) ? errors : [errors];

    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);

    if(mainSave) {
      errors.forEach((err: SaveResult) => {
        (this.$refs.potentialDuplicateModal as PotentialDuplicateModal).checkingDuplicateWarning(err, 'savePatch');
      });
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveLivingDonor as SaveToolbar;
    if (saveToolbar) saveToolbar.reset();
  }

  // Reload living donor after successful subsection save
  private reload(): void {
    this.$store.dispatch('livingDonors/get', this.clientId).then(() => {
      this.reinitialize();
    });
  }

  // Re-initialize card sections that rely on Living Donor document
  public reinitialize(): void {
    const donationInformation = this.$refs.donationInformation as DonationInformation;
    if (donationInformation) donationInformation.reinitialize();
    const recoveryInformation = this.$refs.recoveryInformation as DonationInformation;
    if (recoveryInformation) recoveryInformation.reinitialize();
  }
}
</script>
