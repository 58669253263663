<template>
  <div>
    <template v-if="!recipient">
      <page-top>
        <span class="skeleton-box" style="width: 200px" />
      </page-top>     
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <loading-side-nav />
            <div class="page-content">
              <loading-recipients-page />
            </div>
          </div>
        </div>
      </div>
    </template>
    <router-view v-else />
  </div>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Recipient } from '@/store/recipients/types';
import { Component, Vue } from 'vue-facing-decorator';
import PageTop from '@/components/shared/PageTop.vue';
import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
import LoadingRecipientsPage from '@/components/shared/LoadingRecipientsPage.vue';


@Component({
  components: {
    PageTop,
    LoadingSideNav, 
    LoadingRecipientsPage
  },
})
export default class BaseRecipient extends Vue {
  @Getter('show', { namespace: 'recipients' }) private recipient!: Recipient;
}
</script>
