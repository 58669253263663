export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo"])},
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo"])},
        "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
        "tgln_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo Logo"])},
        "afflo_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo Logo"])},
        "select_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select User"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
        "login_ehealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with OneID"])},
        "login_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with Office365"])},
        "login_fusionauth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with FusionAuth"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading..."])}
      },
      "fr": {
        "sys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo"])},
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo"])},
        "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter"])},
        "tgln_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo Afflo"])},
        "afflo_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo Afflo"])},
        "select_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un utilisateur"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
        "login_ehealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec OneID"])},
        "login_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with Office365"])},
        "login_fusionauth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with FusionAuth"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading..."])}
      },
      "en_tgln": {
        "sys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OATS"])},
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Allocation and Transplant System"])},
        "tgln_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OATS Logo"])},
        "afflo_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OATS Logo"])}
      },
      "fr_tgln": {
        "sys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OATS"])},
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système d'attribution et de transplantation d'organes"])},
        "tgln_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo OATS"])},
        "afflo_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo OATS"])}
      }
    }
  })
}
