<template>
  <div class="subsection-action-row d-flex">
    <!-- button bar -->
    <slot name="button-bar"/>
    <!-- create button -->
    <button
      v-if="createButton"
      @click="handleCreateButtonClick" 
      type="button"
      class="btn btn-primary btn-sm sub-section-create-button ml-auto">
      {{ createText || $t('create_row') }}
    </button>
  </div>
</template>

<script lang="ts">
import { i18nMessages } from '@/i18n';
import { Component, Vue, Prop } from 'vue-facing-decorator';

export interface Options {
  [key: string]: string | undefined;
}

@Component({
  ...i18nMessages([
    require('@/components/shared/_locales/TableToolbar.json'),
  ]),
  emits: [
    'table-create-row',
  ],
})
export default class TableToolbar extends Vue {
  @Prop({ default: false }) createButton!: boolean;
  @Prop({ default: false }) createText!: string|null;

  public handleCreateButtonClick(event: any) {
    this.$emit('table-create-row');
  }
}
</script>
