export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "donor_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Documents"])},
        "save_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Description"])},
        "save_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Document"])},
        "clinical_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinical Attachment"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])},
        "ctr_file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR File Name"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "remove_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Document"])},
        "uploaded_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Documents"])},
        "upload_to_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload to CTR"])},
        "upload_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Document(s) to CTR?"])},
        "upload_documents_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Document(s) to CTR? Any existing files will be overwritten in CTR."])},
        "delete_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Document"])},
        "delete_documents_confirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete Document from Afflo? Are you sure you want to delete '", _interpolate(_named("fileName")), "' from Afflo?"])},
        "delete_documents_confirmation_ctr": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete Document from Afflo and CTR? Are you sure you want to delete '", _interpolate(_named("fileName")), "' from Afflo and CTR?"])},
        "download_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Document(s) from CTR"])},
        "download_documents_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Document(s) from CTR? Any existing files will be overridden."])},
        "attachment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment Date"])},
        "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Type"])},
        "uploadedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded By"])},
        "ctr_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Status"])},
        "not_present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Present"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "download_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download from CTR in progress..."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted in CTR"])},
        "downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloaded from CTR"])},
        "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded to CTR"])},
        "attach_new_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach New Document"])},
        "download_this_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download this document"])},
        "edit_this_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit this document"])},
        "remove_this_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove this document"])},
        "use_form_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the form below to upload a new file"])},
        "error_generating_download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error generating download link"])},
        "upload_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload to CTR in progress..."])},
        "unable_to_upload_to_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to upload to CTR"])},
        "unable_to_download_from_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to download from CTR"])}
      },
      "fr": {
        "donor_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents des donneurs"])},
        "save_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder la description"])},
        "save_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder le document"])},
        "clinical_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachement clinique"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
        "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du fichier"])},
        "ctr_file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du fichier CTR"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "remove_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le document"])},
        "uploaded_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents transférés"])},
        "upload_to_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer vers CTR"])},
        "upload_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer le(s) document(s) vers CTR ?"])},
        "upload_documents_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer le(s) document(s) vers CTR ? Tous les fichiers existants seront écrasés dans CTR."])},
        "delete_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le document"])},
        "delete_documents_confirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer un document d'Afflo ? Êtes-vous sûr de vouloir supprimer '", _interpolate(_named("fileName")), "' de l'Afflo?"])},
        "delete_documents_confirmation_ctr": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer un document d'Afflo et de CTR ? Êtes-vous sûr de vouloir supprimer '", _interpolate(_named("fileName")), "' de l'Afflo et du CTR ?"])},
        "download_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le(s) document(s) du CTR"])},
        "download_documents_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le(s) document(s) du CTR ? Tous les fichiers existants seront remplacés."])},
        "attachment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de l'attachement"])},
        "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de fichier"])},
        "uploadedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transféré par"])},
        "ctr_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut CTR"])},
        "not_present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non présent"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
        "download_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement en cours..."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé dans CTR"])},
        "downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargé de CTR"])},
        "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transféré to CTR"])},
        "attach_new_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre un nouveau document"])},
        "download_this_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger ce document"])},
        "edit_this_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier ce document"])},
        "remove_this_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer ce document"])},
        "use_form_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez le formulaire ci-dessous pour télécharger un nouveau fichier"])},
        "error_generating_download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de génération du lien de téléchargement"])},
        "upload_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours..."])},
        "unable_to_upload_to_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de télécharger vers CTR"])},
        "unable_to_download_from_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de télécharger à partir de CTR"])}
      },
      "en_tgln": {
        "delete_documents_confirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete Document from OATS? Are you sure you want to delete '", _interpolate(_named("fileName")), "' from OATS?"])},
        "delete_documents_confirmation_ctr": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete Document from OATS and CTR? Are you sure you want to delete '", _interpolate(_named("fileName")), "' from OATS and CTR?"])}
      },
      "fr_tgln": {
        "delete_documents_confirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer un document d'OATS ? Êtes-vous sûr de vouloir supprimer '", _interpolate(_named("fileName")), "' de l'OATS?"])},
        "delete_documents_confirmation_ctr": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer un document d'OATS et de CTR ? Êtes-vous sûr de vouloir supprimer '", _interpolate(_named("fileName")), "' de l'OATS et du CTR ?"])}
      }
    }
  })
}
