export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "confirm_recipient_consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Recipient Consent"])},
        "exceptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution"])},
        "reasons_exceptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons for Exceptional Distribution"])},
        "confirmation_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation Details"])},
        "physician_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physician Name"])},
        "recipient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Name"])},
        "exd_organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXD Organ"])},
        "acceptance_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance Reason"])},
        "other_acceptance_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Acceptance Reason"])},
        "confirmation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation Text"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I (or my authorized designate) have had a conversation with the recipient and/or next of kin/substitute decision maker in which I explained the reason(s) for Exceptional Distribution as defined above, and the risks associated with the reason(s). I have obtained informed consent from the recipient and/or next of kin/substitute decision maker and I authorize the acceptance of the organ(s) described above for Transplant."])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "confirmation_organ_acceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation of Organ Acceptance"])},
        "exceptional_distribution_travel_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution Travel Reason:"])},
        "exceptional_distribution_other_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution Other Reason:"])},
        "increased_risk_transmission_reason:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increased Risk of Transmission Reason:"])}
      },
      "fr": {
        "confirm_recipient_consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le consentement du patient"])},
        "exceptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution exceptionnelle"])},
        "reasons_exceptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raisons pour la distribution exceptionnelle"])},
        "confirmation_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la confirmation"])},
        "physician_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du médecin"])},
        "recipient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du patient"])},
        "exd_organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe EXD"])},
        "acceptance_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison de l'acceptation"])},
        "other_acceptance_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre raison de l'acceptation"])},
        "confirmation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte de confirmation"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai (ou mon représentant autorisé) eu une conversation avec le patient et/ou le plus proche parent/décideur substitut au cours de laquelle j'ai expliqué la ou les raisons de la distribution exceptionnelle telle que définie ci-dessus, et les risques associés à la ou aux raisons. J'ai obtenu le consentement éclairé du patient et/ou du plus proche parent/décideur substitut et j'autorise l'acceptation de l'organe ou des organes décrits ci-dessus en vue d'une transplantation."])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "confirmation_organ_acceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de l'acceptation de l'organe"])},
        "exceptional_distribution_travel_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution exceptionnelle Raison du voyage :"])},
        "exceptional_distribution_other_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution exceptionnelle Autre raison :"])},
        "increased_risk_transmission_reason:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison risque élevé de transmission :"])}
      }
    }
  })
}
