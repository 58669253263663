<template>
  <modal-section
    modalId="recipientInformation-modal"
    ref="recipientInformationModal"
    class="modal-sticky-tabs"
    @hide="clearRecipientDetailsState()">

    <template v-slot:title>
      {{$t('recipient_information')}}
    </template>

    <template v-slot:body v-if="recipientDetails && donorDetails">
      <form>
        <ul class="nav nav-tabs border-bottom">
          <li class="nav-item">
            <a
              id="RecDetailsTab"
              class="nav-link active"
              href="#recDetails"
              ref="recipientDetailsTab"
              data-toggle="tab"
              aria-controls="Recipient Details"
            >{{$t('recipient_details')}}</a>
          </li>
          <li class="nav-item">
            <a
              id="RecCompareTab"
              class="nav-link"
              href="#DemoGraph"
              data-toggle="tab"
              aria-controls="Demographics"
            >{{$t('compare')}}</a>
          </li>
          <li class="nav-item">
            <a
              id="RecHLATab"
              class="nav-link"
              href="#RecHLA"
              data-toggle="tab"
              aria-controls="Compare"
            >{{$t('hla')}}</a>
          </li>
          <!-- Only show the Recipient profile and HLA links if we have a client_id available to use for the links -->
          <template v-if="showRecipientLinks">
            <li class="ml-auto">
              {{ recipientDetails.out_of_province }}
              <recipient-link :client_id="recipientDetails.recipient.client_id" />
            </li>
            <li class="ml-3 mr-3" v-if="!recipientDetails.recipient.out_of_province">
              <router-link
                :to="{ name: 'recipient-hla', params: { id: recipientDetails.recipient.client_id }}"
                target="_blank">
                <i aria-hidden="true" class="far fa-fw fa-file-export"></i> {{$t('recipient_hla')}}
              </router-link>
            </li>
          </template>
        </ul>

        <div id="recipientModalTabContent" class="tab-content">
          <div id="recDetails" class="tab-pane fade show active p-3" role="tabpanel" aria-labelledby="recDetailsTab">
            <div class="row">
              <dl class="col-md-6">
                <div class="modal-body-title-sm">
                  {{$t('recipient')}}
                </div>
              </dl>
            </div>
            <div class="row dl-display">
              <dl class="col-md-12">
                <dt>{{$t('allocation_step')}}</dt>
                <dd>
                  <template v-if="recipientDetails.recipient.added_manually">
                    {{$t('manually_added_recipient')}}
                    <br/>
                    {{recipientDetails.recipient.added_manually_reason}}
                  </template>
                  <template v-else>
                    {{recipientDetails.recipient.ranking_category || '-'}}
                  </template>
                </dd>
              </dl>
            </div>
            <div class="row dl-display">
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('listed_for')}}</dt>
                <dd>
                  <organ-icon :organ-code="allocation.organ_code" :compare-modal-icon="true" />
                  <span class="ml-1" v-html="describeOrganListedFor"></span>
                </dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('recipient_id')}}</dt>
                <dd>{{recipientDetails.recipient.client_id || '-'}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('recipient_name')}}</dt>
                <!-- Show the Last name and First name separated by a comma, in that order, if both are available -->
                <dd
                  v-if="recipientDetails.recipient.last_name && recipientDetails.recipient.first_name"
                >
                  {{ recipientDetails.recipient.last_name }}, {{ recipientDetails.recipient.first_name }}
                </dd>
                <dd v-else>
                  {{ recipientDetails.recipient.last_name || '-' }}
                </dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('national_recipient_id')}}</dt>
                <dd>{{recipientDetails.recipient.national_recipient_id || '-'}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('transplant_program')}}</dt>
                <dd>{{getRecipientTransplantProgram || '-'}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('medical_status')}}</dt>
                <dd>{{parseMedicalStatus(recipientDetails.recipient.medical_status) }}</dd>
              </dl>
              <template v-if="!showIposForAllocation">
                <dl class="col-md-6 col-lg-4 col-xl-3" v-if="checkIfOrganSpecificAllocation('heart')">
                  <dt>Secondary Status</dt>
                  <dd>{{parseSecondaryMedicalStatus(recipientDetails.recipient.secondary_medical_status) || '-' }}</dd>
                </dl>
              </template>
              <dl class="col-md-6 col-lg-4 col-xl-3" v-if="!showIposForAllocation">
                <dt>{{$t('wait_time_days')}}</dt>
                <dd>{{parsePossibleZeroValue(recipientDetails.recipient.wait_days)}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3" v-if="!showIposForAllocation">
                <dt>{{$t('list_date')}}</dt>
                <dd>{{parseDate(recipientDetails.recipient.listing_date) || '-'}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('primary_diagnosis')}}</dt>
                <dd><strong>{{recipientDetails.recipient.primary_diagnosis || '-'}}</strong></dd>
              </dl>
              <dl class="col-md-12">
                <dt>{{$t('comments')}}</dt>
                <dd>
                  {{recipientDetails.recipient.comments || '-'}}
                </dd>
              </dl>
            </div>
            <template v-if="showIposForAllocation">
              <div class="row">
                <dl class="col-md-6">
                  <div class="modal-body-title-sm">
                    {{$t('wait_time')}}
                  </div>
                </dl>
              </div>
              <div class="row dl-display">
                <dl class="col-md-6 col-lg-4 col-xl-3">
                  <dt :title="$t('on_wait_time_hover_text')">{{$t('on_wait_time')}}</dt>
                  <dd>{{getIposDetails.on_wait_time}}</dd>
                </dl>
                <dl class="col-md-6 col-lg-4 col-xl-3">
                  <dt>{{$t('on_wait_time_date')}}</dt>
                  <dd>{{getIposDetails.on_wait_time_date}}</dd>
                </dl>
                <dl class="col-md-6 col-lg-4 col-xl-3">
                  <dt>{{$t('hsh')}}</dt>
                  <dd>{{getIposDetails.hsh}}</dd>
                </dl>
              </div>
              <div class="row dl-display">
                <dl class="col-md-6 col-lg-4 col-xl-3">
                  <dt :title="$t('ctr_wait_time_hover_text')">{{$t('ctr_wait_time')}}</dt>
                  <dd>{{getIposDetails.ctr_wait_time}}</dd>
                </dl>
                <dl class="col-md-6 col-lg-4 col-xl-3">
                  <dt>{{$t('ctr_wait_time_date')}}</dt>
                  <dd>{{getIposDetails.ctr_wait_time_date}}</dd>
                </dl>
                <dl class="col-md-6 col-lg-4 col-xl-3">
                  <dt>{{$t('initial_list_date')}}</dt>
                  <dd>{{getIposDetails.initial_list_date}}</dd>
                </dl>
              </div>
            </template>
            <div class="row">
              <dl class="col-md-6">
                <div class="modal-body-title-sm">
                  {{$t('general_info')}}
                </div>
              </dl>
            </div>
            <div class="row dl-display">
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('sex')}}</dt>
                <dd>{{sexValue(recipientDetails.recipient.gender)}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('age')}}</dt>
                <dd>{{recipientDetails.recipient.age}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('date_of_birth')}}</dt>
                <dd>{{parseDate(recipientDetails.recipient.dob)}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('height')}}</dt>
                <dd>{{ recipientDetails.recipient.out_of_province ? '-' : parseHeight(recipientDetails.recipient.height)}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('weight')}}</dt>
                <dd>{{ recipientDetails.recipient.out_of_province ? '-' : parseWeight(recipientDetails.recipient.weight)}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('bmi')}}</dt>
                <dd>{{parseBmi(recipientDetails.recipient.bmi)}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('blood_type')}}</dt>
                <dd>{{recipientDetails.recipient.blood_type || '-'}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3" v-if="checkIfOrganSpecificAllocation('kidney')">
                <dt>{{$t('accept_a2_a2b')}}</dt>
                <dd>{{parseNullValue(recipientDetails.recipient.donor_acceptability.a2_or_a2b_donor)}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('donor_type')}}</dt>
                <dd> {{recipientDonorType}} </dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('mrn')}}</dt>
                <dd>{{recipientDetails.recipient.mrn || '-'}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3" v-if="isLungAllocation">
                <dt>{{$t('total_lung_capacity_actual')}} <span class="text-red" :title="$t('only_lung')"><u>(*)</u></span></dt>
                <dd>{{recipientDetails.recipient.actual_total_lung_capacity ? recipientDetails.recipient.actual_total_lung_capacity + 'L' : '-'}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3" v-if="isLungAllocation">
                <dt>{{$t('total_lung_capacity_predicted')}} <span class="text-red" :title="$t('only_lung')"><u>(*)</u></span></dt>
                <dd>{{recipientDetails.recipient.predicted_total_lung_capacity ? recipientDetails.recipient.predicted_total_lung_capacity + 'L' : '-'}}</dd>
              </dl>
            </div>
            <div class="row">
              <dl class="col-md-6">
                <div class="modal-body-title-sm">
                  {{$t('virology')}}
                </div>
              </dl>
            </div>
            <div class="row">
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('cmv')}}</dt>
                <dd>{{parseVirology(recipientDetails.recipient.virology.cmv)}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('ebv')}}</dt>
                <dd>{{parseVirology(recipientDetails.recipient.virology.ebv)}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('hb_ag')}}</dt>
                <dd>{{parseVirology(recipientDetails.recipient.virology.hbv_surface_ag)}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('hcv')}}</dt>
                <dd>{{parseVirology(recipientDetails.recipient.virology.hcv_ab)}}</dd>
              </dl>
            </div>
            <div class="row">
              <dl class="col-md-12">
                <div class="modal-body-title-sm">
                  {{$t('patienbt_hla_comments')}}
                </div>
              </dl>
            </div>
            <div class="row dl-display">
              <dl class="col-md-12">
                <dd>{{recipientDetails.recipient.hla_comments || '-'}}</dd>
              </dl>
            </div>
            <div class="row">
              <dl class="col-md-12">
                <div class="modal-body-title-sm">
                  {{$t('donor_acceptance_comments')}}
                </div>
              </dl>
            </div>
            <div class="row dl-display">
              <dl class="col-md-12">
                <dd>{{recipientDetails.recipient.donor_acceptability.comments || '-'}}</dd>
              </dl>
            </div>
            <div class="table-responsive-md">
              <table class="table table-bordered">
                <caption><strong>{{$t('referred_organ_section')}}</strong></caption>
                <thead>
                  <tr>
                    <th scope="col">
                      {{$t('organ')}}
                    </th>
                    <th scope="col">
                      {{$t('registration_type')}}
                    </th>
                    <th scope="col">
                      {{$t('registration_status')}}
                    </th>
                    <th scope="col">
                      {{$t('medical_status')}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(organ, index) in recipientDetails.recipient.associated_journeys" :key="`recipient_associated_journey-${index}`">
                    <td><i class="fas fa-fw fa-heart"></i> <strong>{{organ.organ}}</strong></td>
                    <td>{{organ.registration_type}}</td>
                    <td><strong>{{organ.registration_status}}</strong></td>
                    <td>{{`${organ.medical_status_code} - ${organ.medical_status_desc}`}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive-md">
              <table>
                <caption><strong>{{$t('pra_section')}}</strong></caption>
                <div class="row dl-display">
                  <dl class="col-md-12 col-lg-12 col-xl-12">
                    <strong>{{$t('cumulative_cpra')}} </strong> {{parsePossibleZeroValue(recipientDetails.recipient.cpra)}}
                  </dl>
                </div>
              </table>
            </div>
            <div class="table-responsive-md">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">
                      {{$t('pra')}}
                    </th>
                    <th scope="col">
                      {{$t('current_pra')}}
                    </th>
                    <th scope="col">
                      {{$t('months_old')}}
                    </th>
                    <th scope="col">
                      {{$t('peak_pra')}}
                    </th>
                    <th scope="col">
                      {{$t('months_old')}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      {{$t('class_i')}}
                    </th>
                    <td>{{recipientDetails.recipient.pra.class1_current_value}}</td>
                    <td>{{recipientDetails.recipient.pra.class1_current_months}}</td>
                    <td>{{recipientDetails.recipient.pra.class1_peak_value}}</td>
                    <td>{{recipientDetails.recipient.pra.class1_peak_months}}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {{$t('class_ii')}}
                    </th>
                    <td>{{recipientDetails.recipient.pra.class2_current_value}}</td>
                    <td>{{recipientDetails.recipient.pra.class2_current_months}}</td>
                    <td>{{recipientDetails.recipient.pra.class2_peak_value}}</td>
                    <td>{{recipientDetails.recipient.pra.class2_peak_months}}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {{$t('total')}}
                    </th>
                    <td>{{recipientDetails.recipient.pra.total_current_value}}</td>
                    <td>{{recipientDetails.recipient.pra.total_current_months}}</td>
                    <td>{{recipientDetails.recipient.pra.total_peak_value}}</td>
                    <td>{{recipientDetails.recipient.pra.total_peak_months}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive-md">
              <table class="table table-bordered">
                <caption><strong>{{$t('vxm_section')}}</strong></caption>
                <thead>
                  <tr>
                    <th scope="col" width="10%">
                      {{$t('vxm')}}
                    </th>
                    <th scope="col" width="22.5%">
                      {{$t('class_i')}} {{$t('current')}}
                    </th>
                    <th scope="col" width="22.5%">
                      {{$t('class_i')}} {{$t('cumulative')}}
                    </th>
                    <th scope="col" width="22.5%">
                      {{$t('class_ii')}} {{$t('current')}}
                    </th>
                    <th scope="col" width="22.5%">
                      {{$t('class_ii')}} {{$t('cumulative')}}
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <template v-if="!recipientDetails.recipient.vxm.current">
                    <td colspan="100%" class="text-center">
                      <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                      {{$t('no_vxm_data')}}
                    </td>
                  </template>
                  <template v-else>
                    <tr>
                      <th>{{$t('result')}}</th>
                      <td>
                        <hla-virtual-crossmatch-result :result="recipientDetails.recipient.vxm.current.class1.result" />
                      </td>
                      <td>
                        <hla-virtual-crossmatch-result :result="recipientDetails.recipient.vxm.cumulative.class1.result" />
                      </td>
                      <td>
                        <hla-virtual-crossmatch-result :result="recipientDetails.recipient.vxm.current.class2.result" />
                      </td>
                      <td>
                        <hla-virtual-crossmatch-result :result="recipientDetails.recipient.vxm.cumulative.class2.result" />
                      </td>
                    </tr>
                    <tr>
                      <th>{{$t('unacceptable_dsa')}}</th>
                      <td>
                        <hla-input-group
                          inputId="vxm-summary-table-unacceptable_class1_current"
                          v-model="recipientDetails.recipient.vxm.current.class1.unacceptable_allele_group"
                          :enableEpitopes="true"
                          :alleleSpecific="recipientDetails.recipient.vxm.current.class1.unacceptable_allele_specific"
                          :epitopes="recipientDetails.recipient.vxm.current.class1.unacceptable_epitopes"
                          :readonly="true"
                          inputClass="hla-input hla-unacceptable border-0"
                          :name="$t('unacceptable')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.current.class1.result"
                        />
                      </td>
                      <td>
                        <hla-input-group
                          inputId="vxm-summary-table-unacceptable_class1_cumulative"
                          v-model="recipientDetails.recipient.vxm.cumulative.class1.unacceptable_allele_group"
                          :enableEpitopes="true"
                          :alleleSpecific="recipientDetails.recipient.vxm.cumulative.class1.unacceptable_allele_specific"
                          :epitopes="recipientDetails.recipient.vxm.cumulative.class1.unacceptable_epitopes"
                          :readonly="true"
                          inputClass="hla-input hla-unacceptable border-0"
                          :name="$t('unacceptable')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.cumulative.class1.result"
                        />
                      </td>
                      <td>
                        <hla-input-group
                          inputId="vxm-summary-table-unacceptable_class2_current"
                          v-model="recipientDetails.recipient.vxm.current.class2.unacceptable_allele_group"
                          :alleleSpecific="recipientDetails.recipient.vxm.current.class2.unacceptable_allele_specific"
                          :alphaBeta="recipientDetails.recipient.vxm.current.class2.unacceptable_alpha_beta"
                          :readonly="true"
                          inputClass="hla-input hla-unacceptable border-0"
                          :name="$t('unacceptable')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.current.class2.result"
                        />
                      </td>
                      <td>
                        <hla-input-group
                          inputId="vxm-summary-table-unacceptable_class2_cumulative"
                          v-model="recipientDetails.recipient.vxm.cumulative.class2.unacceptable_allele_group"
                          :alleleSpecific="recipientDetails.recipient.vxm.cumulative.class2.unacceptable_allele_specific"
                          :alphaBeta="recipientDetails.recipient.vxm.cumulative.class2.unacceptable_alpha_beta"
                          :readonly="true"
                          inputClass="hla-input hla-unacceptable border-0"
                          :name="$t('unacceptable')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.cumulative.class2.result"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{{$t('indeterminate_dsa')}}</th>
                      <td>
                        <hla-input-group
                          inputId="vxm-summary-table-indeterminate_class1_current"
                          v-model="recipientDetails.recipient.vxm.current.class1.indeterminate_allele_group"
                          :enableEpitopes="true"
                          :alleleSpecific="recipientDetails.recipient.vxm.current.class1.indeterminate_allele_specific"
                          :epitopes="recipientDetails.recipient.vxm.current.class1.indeterminate_epitopes"
                          :readonly="true"
                          inputClass="hla-input hla-indeterminate border-0"
                          :name="$t('indeterminate')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.current.class1.result"
                        />
                      </td>
                      <td>
                        <hla-input-group
                          inputId="vxm-summary-table-indeterminate_class1_cumulative"
                          v-model="recipientDetails.recipient.vxm.cumulative.class1.indeterminate_allele_group"
                          :enableEpitopes="true"
                          :alleleSpecific="recipientDetails.recipient.vxm.cumulative.class1.indeterminate_allele_specific"
                          :epitopes="recipientDetails.recipient.vxm.cumulative.class1.indeterminate_epitopes"
                          :readonly="true"
                          inputClass="hla-input hla-indeterminate border-0"
                          :name="$t('indeterminate')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.cumulative.class1.result"
                        />
                      </td>
                      <td>
                        <hla-input-group
                          inputId="vxm-summary-table-indeterminate_class2_current"
                          v-model="recipientDetails.recipient.vxm.current.class2.indeterminate_allele_group"
                          :alleleSpecific="recipientDetails.recipient.vxm.current.class2.indeterminate_allele_specific"
                          :alphaBeta="recipientDetails.recipient.vxm.current.class2.indeterminate_alpha_beta"
                          :readonly="true"
                          inputClass="hla-input hla-indeterminate border-0"
                          :name="$t('indeterminate')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.current.class2.result"
                        />
                      </td>
                      <td>
                        <hla-input-group
                          inputId="vxm-summary-table-indeterminate_class2_cumulative"
                          v-model="recipientDetails.recipient.vxm.cumulative.class2.indeterminate_allele_group"
                          :alleleSpecific="recipientDetails.recipient.vxm.cumulative.class2.indeterminate_allele_specific"
                          :alphaBeta="recipientDetails.recipient.vxm.cumulative.class2.indeterminate_alpha_beta"
                          :readonly="true"
                          inputClass="hla-input hla-indeterminate  border-0"
                          :name="$t('indeterminate')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.cumulative.class2.result"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{{$t('possible_allele_specific')}}</th>
                      <td>
                        <hla-input
                          inputId="vxm-summary-table-possible-allele-specific-class1-current"
                          v-model="recipientDetails.recipient.vxm.current.class1.possible_allele_specific"
                          :readonly="true"
                          inputClass="hla-input hla-possible-allele-specific border-0"
                          :name="$t('possible_allele_specific')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.current.class1.result"
                        />
                      </td>
                      <td>
                        <hla-input
                          inputId="vxm-summary-table-possible-allele-specific-class1-cumulative"
                          v-model="recipientDetails.recipient.vxm.cumulative.class1.possible_allele_specific"
                          :readonly="true"
                          inputClass="hla-input hla-possible-allele-specific border-0"
                          :name="$t('possible_allele_specific')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.cumulative.class1.result"
                        />
                      </td>
                      <td>
                        <hla-input
                          inputId="vxm-summary-table-possible-allele-specific-class2-current"
                          v-model="recipientDetails.recipient.vxm.current.class2.possible_allele_specific"
                          :readonly="true"
                          inputClass="hla-input hla-possible-allele-specific border-0"
                          :name="$t('possible_allele_specific')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.current.class2.result"
                        />
                      </td>
                      <td>
                        <hla-input
                          inputId="vxm-summary-table-possible-allele-specific-class2-cumulative"
                          v-model="recipientDetails.recipient.vxm.cumulative.class2.possible_allele_specific"
                          :readonly="true"
                          inputClass="hla-input hla-possible-allele-specific border-0"
                          :name="$t('possible_allele_specific')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.cumulative.class2.result"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{{$t('untested')}}</th>
                      <td>
                        <hla-input
                          inputId="vxm-summary-table-untested-class1-current"
                          v-model="recipientDetails.recipient.vxm.current.class1.untested"
                          :readonly="true"
                          inputClass="hla-input hla-untested border-0"
                          :name="$t('untested')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.current.class1.result"
                        />
                      </td>
                      <td>
                        <hla-input
                          inputId="vxm-summary-table-untested-class1-cumulative"
                          v-model="recipientDetails.recipient.vxm.cumulative.class1.untested"
                          :readonly="true"
                          inputClass="hla-input hla-untested border-0"
                          :name="$t('untested')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.cumulative.class1.result"
                        />
                      </td>
                      <td>
                        <hla-input
                          inputId="vxm-summary-table-untested-class2-current"
                          v-model="recipientDetails.recipient.vxm.current.class2.untested"
                          :readonly="true"
                          inputClass="hla-input hla-untested border-0"
                          :name="$t('untested')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.current.class2.result"
                        />
                      </td>
                      <td>
                        <hla-input
                          inputId="vxm-summary-table-untested-class2-cumulative"
                          v-model="recipientDetails.recipient.vxm.cumulative.class2.untested"
                          :readonly="true"
                          inputClass="hla-input hla-untested border-0"
                          :name="$t('untested')"
                          :hide-label="true"
                          :vxm="recipientDetails.recipient.vxm.cumulative.class2.result"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{{$t('messages')}}</th>
                      <td>
                        <span
                          v-for="(message, index) in recipientDetails.recipient.vxm.current.class1.messages"
                          class="hla-vxm-message"
                          :key="`vxm-summary-table-messages-class1-current-${index}`"
                        >
                          {{message}}
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(message, index) in recipientDetails.recipient.vxm.cumulative.class1.messages"
                          class="hla-vxm-message"
                          :key="`vxm-summary-table-messages-class1-cummulative-${index}`"
                        >
                          {{message}}
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(message, index) in recipientDetails.recipient.vxm.current.class2.messages"
                          class="hla-vxm-message"
                          :key="`vxm-summary-table-messages-class2-current-${index}`"
                        >
                          {{message}}
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(message, index) in recipientDetails.recipient.vxm.cumulative.class2.messages"
                          class="hla-vxm-message"
                          :key="`vxm-summary-table-messages-class2-cumulative-${index}`"
                        >
                          {{message}}
                        </span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="row">
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <dt>{{$t('current_serum_date')}}</dt>
                <dd>{{parseDate(recipientDetails.recipient.vxm.current_serum_date || '') || '-'}}</dd>
              </dl>
              <dl class="col-md-6 col-lg-4 col-xl-3">
                <!-- How many HLA Antibody Tests the recipient had when the allocation was run, minus any before the HSCT Date -->
                <dt>{{$t('total_serum')}}</dt>
                <dd>{{recipientDetails.recipient.total_valid_hla_antibody_labs != null ? recipientDetails.recipient.total_valid_hla_antibody_labs: '-'}}</dd>
              </dl>
            </div>
          </div>

          <div id="DemoGraph" class="tab-pane fade p-3" role="tabpanel" aria-labelledby="RecCompareTab">
            <div class="row">
              <div class="col-12">
                <h3 class="card-title sr-only">
                  {{$t('compare')}}
                </h3>
                <div>
                  <div class="row no-gutters sticky-headings">
                    <div class="col-6">
                      <h4 class="col-title">
                        {{$t('recipient')}}
                      </h4>
                    </div>
                    <div class="col-6">
                      <h4 class="col-title">
                        {{$t('donor')}}
                      </h4>
                    </div>
                    <div class="col-12">
                      <hr />
                    </div>
                  </div>
                  <div class="row no-gutters">
                    <div class="col-md-6">
                      <h5 class="hla-block-title">
                        {{$t('demographics_section')}}
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="hla-block-title">
                        {{$t('demographics_section')}}
                      </h5>
                    </div>
                    <div class="col-md-12">
                      <table class="table table-hover table-sm mt-3">
                        <tbody>
                          <tr>
                            <th scope="row" width="20%">
                              {{$t('age')}}
                            </th>
                            <td width="30%">
                              {{recipientDetails.recipient.age}}
                            </td>
                            <th scope="row" width="20%">
                              {{$t('age')}}
                            </th>
                            <td width="30%">
                              {{donorDetails.donor.age}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('gender')}}
                            </th>
                            <td>
                              {{sexValue(recipientDetails.recipient.gender)}}
                            </td>
                            <th scope="row">
                              {{$t('gender')}}
                            </th>
                            <td>
                              {{sexValue(donorDetails.donor.gender)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('primary_diagnosis')}}
                            </th>
                            <td>
                              {{ recipientDetails.recipient.primary_diagnosis || '-'}}
                            </td>
                            <th scope="row">
                              {{$t('cause_of_death')}}
                            </th>
                            <td>
                              {{parseCauseOfDeath(donorDetails.donor.cause_of_death_code)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('abo')}}
                            </th>
                            <td>
                              {{recipientDetails.recipient.blood_type}}
                            </td>
                            <th scope="row">
                              {{$t('abo')}}
                            </th>
                            <td>
                              {{donorDetails.donor.blood_type}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('accept_abo_incompatible')}}
                            </th>
                            <td>
                              {{parseNullValue(recipientDetails.recipient.donor_acceptability.abo_incompatible)}}
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                          </tr>
                          <!-- Shows for kidney only -->
                          <tr>
                            <th scope="row">
                              {{$t('accept_a2_a2b')}}
                            </th>
                            <td>
                              {{parseNullValue(recipientDetails.recipient.donor_acceptability.a2_or_a2b_donor)}}
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                            </th>
                            <td>
                            </td>
                            <th>
                              {{$t('donor_type')}}
                            </th>
                            <td>
                              {{donorDetails.donor.neurological_death ? 'NDD' : 'DCD'}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('ecd_consented')}}
                            </th>
                            <td>
                              {{parseNullValue(recipientDetails.recipient.donor_acceptability.ecd_donor)}}
                            </td>
                            <th>
                              {{$t('ecd_donor')}}
                            </th>
                            <td>
                              {{donorDetails.donor.ecd ? $t('yes'): $t('no')}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                            </th>
                            <td>
                            </td>
                            <th>
                              {{$t('exeptional_distribution')}}
                            </th>
                            <td>
                              {{donorDetails.donor.exceptional_distribution ? $t('yes'): $t('no')}}
                            </td>
                          </tr>
                          <tr>
                            <td>
                            </td>
                            <td>
                            </td>
                            <th scope="row">
                              {{$t('exeptional_distribution_reason')}}
                            </th>
                            <td>
                              <ul class="simple-list" v-if="exdReasonValues">
                                <li v-for="(reason, index) in exdReasonValues" :key="`exd-${index}`">{{reason}}</li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td>
                            </td>
                            <td>
                            </td>
                            <th scope="row">
                              {{$t('exeptional_distribution_other_reason')}}
                            </th>
                            <td>
                              {{donorDetails.donor.exd_reason_details_other || '-'}}
                            </td>
                          </tr>
                          <tr>
                            <td>
                            </td>
                            <td>
                            </td>
                            <th scope="row">
                              {{$t('exeptional_distribution_travel_reason')}}
                            </th>
                            <td>
                              {{donorDetails.donor.exd_reason_details_travel || '-'}}
                            </td>
                          </tr>
                          <tr>
                            <td>
                            </td>
                            <td>
                            </td>
                            <th scope="row">
                              {{$t('exeptional_distribution_increased_risk')}}
                            </th>
                            <td>
                              {{donorDetails.donor.exd_reason_details_transmission || '-'}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('height')}}
                            </th>
                            <td>
                              {{parseHeight(recipientDetails.recipient.height)}}
                            </td>
                            <th>
                              {{$t('height')}}
                            </th>
                            <td>
                              {{parseHeight(donorDetails.donor.height)}}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              {{$t('weight')}}
                            </th>
                            <td>
                              {{parseWeight(recipientDetails.recipient.weight)}}
                            </td>
                            <th scope="row">
                              {{$t('weight')}}
                            </th>
                            <td>
                              {{parseWeight(donorDetails.donor.weight)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('minimum_donor_weight')}}
                            </th>
                            <td>
                              {{parseWeight(recipientDetails.recipient.donor_acceptability.min_weight)}}
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('maximum_donor_weight')}}
                            </th>
                            <td>
                              {{parseWeight(recipientDetails.recipient.donor_acceptability.max_weight)}}
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('comments')}}
                            </th>
                            <td>
                              {{recipientDetails.recipient.donor_acceptability.comments || '-'}}
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              {{$t('bmi')}}
                            </th>
                            <td>
                              {{parseBmi(recipientDetails.recipient.bmi)}}
                            </td>
                            <th scope="row">
                              {{$t('bmi')}}
                            </th>
                            <td>
                              {{parseBmi(donorDetails.donor.bmi)}}
                            </td>
                          </tr>
                          <tr v-if="allocationOrganName=='Lung'">
                            <th scope="row">
                              {{$t('total_lung_capacity_actual')}} <span class="text-red" :title="$t('only_lung')"><u>(*)</u></span>
                            </th>
                            <td>
                              {{recipientDetails.recipient.actual_total_lung_capacity ? recipientDetails.recipient.actual_total_lung_capacity + 'L' : '-'}}
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                          </tr>
                          <tr v-if="allocationOrganName=='Lung'">
                            <th scope="row">
                              {{$t('total_lung_capacity_predicted')}} <span class="text-red" :title="$t('only_lung')"><u>(*)</u></span>
                            </th>
                            <td>
                              {{recipientDetails.recipient.predicted_total_lung_capacity ? recipientDetails.recipient.predicted_total_lung_capacity + 'L' : '-'}}
                            </td>
                            <th scope="row">
                              {{$t('total_lung_capacity_actual')}} <span class="text-red" :title="$t('only_lung')"><u>(*)</u></span>
                            </th>
                            <td>
                              {{donorDetails.donor.predicted_total_lung_capacity ? donorDetails.donor.predicted_total_lung_capacity + 'L' : '-'}}
                            </td>
                            <td>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('prev_transplants')}}
                            </th>
                            <td>
                              {{recipientDetails.recipient.previous_transplants}}
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('recipient_comments')}}
                            </th>
                            <td>
                              {{recipientDetails.recipient.comments}}
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12">
                      <hr />
                    </div>
                  </div>
                  <div class="row no-gutters">
                    <div class="col-md-6">
                      <h5 class="hla-block-title">
                        {{$t('virology_results')}}
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="hla-block-title">
                        {{$t('serology_results')}}
                      </h5>
                    </div>
                    <div class="col-md-12">
                      <table class="table table-hover table-sm mt-3">
                        <tbody>
                          <tr>
                            <th scope="row" width="20%">
                            </th>
                            <td width="30%">
                            </td>
                            <th scope="row" width="20%">
                              {{$t('nat_hiv')}}
                            </th>
                            <td width="30%">
                              {{parseVirology(donorDetails.donor.virology.hiv_nat)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                            </th>
                            <td>
                            </td>
                            <th scope="row">
                              {{$t('nat_hcv')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.hcv_nat)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                            </th>
                            <td>
                            </td>
                            <th scope="row">
                              {{$t('nat_hbv')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.hbv_nat)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('cmv')}}
                            </th>
                            <td>
                              {{parseVirology(recipientDetails.recipient.virology.cmv)}}
                            </td>
                            <th scope="row">
                              {{$t('cmv')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.cmv)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('hep_b_surface_ag')}}
                            </th>
                            <td>
                              {{parseVirology(recipientDetails.recipient.virology.hbv_surface_ag)}}
                            </td>
                            <th scope="row">
                              {{$t('hep_b_surface_ag')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.hbv_surface_ag)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('hep_b_surface_ab')}}
                            </th>
                            <td>
                              {{parseVirology(recipientDetails.recipient.virology.hbv_surface_ab)}}
                            </td>
                            <th scope="row">
                              {{$t('hep_b_surface_ab')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.hbv_surface_ab)}}
                            </td>
                          </tr>
                           <tr>
                            <th scope="row">
                              {{$t('hep_b_core_ab')}}
                            </th>
                            <td>
                              {{parseVirology(recipientDetails.recipient.virology.hep_ab_core)}}
                            </td>
                            <th scope="row">
                              {{$t('hep_b_core_ab')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.hep_ab_core)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('hcv')}}
                            </th>
                            <td>
                              {{parseVirology(recipientDetails.recipient.virology.hcv_ab)}}
                            </td>
                            <th scope="row">
                              {{$t('hcv')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.hcv_ab)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                            </th>
                            <td>
                            </td>
                            <th scope="row">
                              {{$t('hiv_i_ii')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.hiv_1_2)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                            </th>
                            <td>
                            </td>
                            <th scope="row">
                              {{$t('htlv1')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.htlv1)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                            </th>
                            <td>
                            </td>
                            <th scope="row">
                              {{$t('htlv2')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.htlv2)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                            </th>
                            <td>
                            </td>
                            <th scope="row">
                              {{$t('tp_syphilis')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.syphilis)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                            </th>
                            <td>
                            </td>
                            <th scope="row">
                              {{$t('toxoplasma')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.toxoplasma)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {{$t('ebv')}}
                            </th>
                            <td>
                              {{parseVirology(recipientDetails.recipient.virology.ebv)}}
                            </td>
                            <th scope="row">
                              {{$t('ebv')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.ebv)}}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                            </th>
                            <td>
                            </td>
                            <th scope="row">
                              {{$t('wnv_pcr')}}
                            </th>
                            <td>
                              {{parseVirology(donorDetails.donor.virology.wnv_pcr)}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="RecHLA" class="tab-pane fade p-3" role="tabpanel" aria-labelledby="RecHLATab">
            <div class="row">
              <div class="col-12">
                <h3 class="card-title sr-only">
                  {{$t('hla')}}
                </h3>
                <div>
                  <div class="row sticky-headings">
                    <div class="col-6">
                      <h4 class="col-title">
                        {{$t('recipient')}}
                      </h4>
                    </div>
                    <div class="col-6">
                      <h4 class="col-title">
                        {{$t('donor')}}
                      </h4>
                    </div>
                    <div class="col-12">
                      <hr />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="hla-block-title">
                        {{$t('class_i')}} {{$t('typing')}}
                      </h5>

                      <div class="table-responsive">
                        <!-- TODO: Vue3_Upgrade: tables -->
                      </div>

                    </div>
                    <div class="col-md-6">
                      <h5 class="hla-block-title">
                        {{$t('class_i')}} {{$t('typing')}}
                      </h5>
                      <div class="table-responsive">
                        <!-- TODO: Vue3_Upgrade: tables -->
                      </div>
                    </div>
                  </div>

                  <!-- Show recipient's Cumulative Class I antibodies if they are available -->
                  <div
                    class="col-md-6"
                    v-if="recipientDetails.recipient.antibodies && recipientDetails.recipient.antibodies.cumulative.class1 && recipientDetails.recipient.antibodies.cumulative.class1.antibodies"
                  >
                    <div class="hr-break" />
                    <h5 class="hla-block-title">
                      {{$t('class_i')}} {{$t('antibodies')}}
                    </h5>
                    <div class="form-group">
                      <hla-input-group
                        inputId="serumsummary-modal-antibodies_class1_unacceptable"
                        :name="$t('unacceptable')"
                        v-model="recipientDetails.recipient.antibodies.cumulative.class1.antibodies.unacceptable_allele_group"
                        :enableEpitopes="true"
                        :alleleSpecific="recipientDetails.recipient.antibodies.cumulative.class1.antibodies.unacceptable_allele_specific"
                        :alphaBeta="recipientDetails.recipient.antibodies.cumulative.class1.antibodies.unacceptable_alpha_beta"
                        :epitopes="recipientDetails.recipient.antibodies.cumulative.class1.epitopes.unacceptable"
                        :readonly="true"
                        inputClass="hla-input hla-unacceptable border-0" />
                    </div>
                    <div class="form-group">
                      <hla-input-group
                        inputId="serumsummary-modal-antibodies_class1_indeterminate"
                        :name="$t('indeterminate')"
                        v-model="recipientDetails.recipient.antibodies.cumulative.class1.antibodies.indeterminate_allele_group"
                        :enableEpitopes="true"
                        :alleleSpecific="recipientDetails.recipient.antibodies.cumulative.class1.antibodies.indeterminate_allele_specific"
                        :alphaBeta="recipientDetails.recipient.antibodies.cumulative.class1.antibodies.indeterminate_alpha_beta"
                        :epitopes="recipientDetails.recipient.antibodies.cumulative.class1.epitopes.indeterminate"
                        :readonly="true"
                        inputClass="hla-input hla-indeterminate border-0" />
                    </div>
                  </div>

                  <hr>

                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="hla-block-title">
                        {{$t('class_ii')}} {{$t('typing')}}
                      </h5>
                      <div class="table-responsive">
                        <!-- TODO: Vue3_Upgrade: tables -->
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h5 class="hla-block-title">
                        {{$t('class_ii')}} {{$t('typing')}}
                      </h5>

                      <div class="table-responsive">
                        <!-- TODO: Vue3_Upgrade: tables -->
                      </div>
                    </div>

                    <!-- Show recipient's Cumulative Class II antibodies if they are available -->
                    <div
                      class="col-md-6"
                      v-if="recipientDetails.recipient.antibodies && recipientDetails.recipient.antibodies.cumulative.class2 && recipientDetails.recipient.antibodies.cumulative.class2.antibodies"
                    >
                      <div class="hr-break" />
                      <h5 class="hla-block-title">
                        {{$t('class_ii')}} {{$t('antibodies')}}
                      </h5>
                      <div class="form-group">
                        <hla-input-group
                          inputId="serumsummary-modal-antibodies_class2_unacceptable"
                          :name="$t('unacceptable')"
                          v-model="recipientDetails.recipient.antibodies.cumulative.class2.antibodies.unacceptable_allele_group"
                          :alleleSpecific="recipientDetails.recipient.antibodies.cumulative.class2.antibodies.unacceptable_allele_specific"
                          :alphaBeta="recipientDetails.recipient.antibodies.cumulative.class2.antibodies.unacceptable_alpha_beta"
                          :readonly="true"
                          inputClass="hla-input hla-unacceptable border-0" />
                      </div>
                      <div class="form-group">
                        <hla-input-group
                          inputId="serumsummary-modal-antibodies_class2_indeterminate"
                          :name="$t('indeterminate')"
                          v-model="recipientDetails.recipient.antibodies.cumulative.class2.antibodies.indeterminate_allele_group"
                          :alleleSpecific="recipientDetails.recipient.antibodies.cumulative.class2.antibodies.indeterminate_allele_specific"
                          :alphaBeta="recipientDetails.recipient.antibodies.cumulative.class2.antibodies.indeterminate_alpha_beta"
                          :readonly="true"
                          inputClass="hla-input hla-indeterminate border-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:body v-else>
      <template v-if="!!recipientDetailsErrorMessage && !loading">
        <div class="alert alert-danger">
          {{recipientDetailsErrorMessage}}
        </div>
      </template>
      <template v-else>
        {{$t('loading')}}
      </template>
    </template>
  </modal-section>
</template>

<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/common.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/commonPatientShared.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/CompareModal.json"></i18n>
<i18n src="@/components/_locales/iposFields.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-facing-decorator';
import { NumericCodeValue } from '@/store/types';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { Recipient, RecipientOopTransplant } from '@/store/recipients/types';
import SubSection from '@/components/shared/SubSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import HlaInput from '@/components/shared/HlaInput.vue';
import HlaInputGroup from '@/components/shared/HlaInputGroup.vue';
import { DonorDetails, RecipientDetails } from '@/store/allocations/types';
import { Organ, OrganWaitlistMedicalStatus, OrganDiseaseCode, OrganCodeValue, SYSTEM_ONLY_EXCEPTIONAL_DISTRIBUTION_REASONS } from '@/store/lookups/types';
import { HlaTypingTag, HlaSerologicalValue, LabHlaTypingEpitope } from '@/store/labs/types';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import { isMasked } from '@/utils';
import RecipientLink from '@/components/shared/RecipientLink.vue';
import {AllocationUtilsMixin} from "@/mixins/allocation-utils-mixin";
import HlaVirtualCrossmatchResult from '@/components/hla/HlaVirtualCrossmatchResult.vue';

interface HlaTypingAntigenRow {
  locus?: string,
  molecular?: string[],
  most_likely_allele?: string[],
  serologic?: string[]
}

interface HlaTypingLoci {
  locus?: string,
  sequence?: number
}

interface IposObject {
  hsh: boolean,
  on_wait_time: string;
  on_wait_time_date: string;
  initial_list_date: string;
  ctr_wait_time: string;
  ctr_wait_time_date: string;
}

@Component({
  components: {
    SubSection,
    ModalSection,
    HlaInput,
    HlaInputGroup,
    OrganIcon,
    RecipientLink,
    HlaVirtualCrossmatchResult,
  }
})
export default class CompareModal extends mixins(DateUtilsMixin, AllocationUtilsMixin) {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;

  // TODO: TECH_DEBT: make nessary corrections to DonorDetails and RecipientDetails and use those instead of 'any' types
  @State(state => state.allocations.donorDetails) private donorDetails!: any;
  @State(state => state.allocations.recipientDetails) private recipientDetails!: any;

  @State(state => state.lookups.organ) organLookup!: Organ[];

  @Getter('selectedAllocation', { namespace: 'allocations' }) private allocation!: any;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('parseHlaTypingTag', { namespace: 'labs' }) parseHlaTypingTag!: (tagText: string) => HlaTypingTag|undefined;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('allDonorTypes', { namespace: 'lookups' }) donorTypes!: any;
  @Getter('lookupValueNumeric', { namespace: 'lookups' }) lookupValueNumeric!: (code: number, lookupId: string) => string|null;
  @Getter('medicalStatusLookup', { namespace: 'lookups' }) medicalStatusLookup!: (excludeHold: boolean, organLookup?: Organ[], organCode?: number) => any;
  @Getter('secondaryMedicalStatusLookup', { namespace: 'lookups' }) secondaryMedicalStatusLookup!: (organLookup?: Organ[], organCode?: number) => any;
  @Getter('clusterOrganCodeDisplayValue', { namespace: 'utilities' }) private clusterOrganCodeDisplayValue!: (organCode: number|null, clusterOrganCode?: string|null) => string;
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;
  @Getter('showIposForAllocation', { namespace: 'allocations' }) private showIposForAllocation!: boolean;

  // Order of molecular locus values shown in HLA Typing Details Table
  private CLASS_1_TYPING_TABLE: HlaTypingLoci[] = [
    { locus: 'A', sequence: 1 },
    { locus: 'A', sequence: 2 },
    { locus: 'B', sequence: 1 },
    { locus: 'B', sequence: 2 },
    { locus: this.epitopesLabel },
    { locus: 'C', sequence: 1 },
    { locus: 'C', sequence: 2 },
  ];

  // Order of molecular locus values shown in HLA Typing Details Table
  private CLASS_2_TYPING_TABLE: HlaTypingLoci[] = [
    { locus: 'DRB1', sequence: 1 },
    { locus: 'DRB1', sequence: 2 },
    { locus: 'DRB3', sequence: 1 },
    { locus: 'DRB3', sequence: 2 },
    { locus: 'DRB4', sequence: 1 },
    { locus: 'DRB4', sequence: 2 },
    { locus: 'DRB5', sequence: 1 },
    { locus: 'DRB5', sequence: 2 },
    { locus: 'DQB1', sequence: 1 },
    { locus: 'DQB1', sequence: 2 },
    { locus: 'DQA1', sequence: 1 },
    { locus: 'DQA1', sequence: 2 },
    { locus: 'DPB1', sequence: 1 },
    { locus: 'DPB1', sequence: 2 },
    { locus: 'DPA1', sequence: 1 },
    { locus: 'DPA1', sequence: 2 }
  ];

  private loading = true;
  private recipientDetailsErrorMessage = '';

  // load donor details on mount
  public mounted() {
    this.$store.dispatch('allocations/getDonorDetails', { donorId: this.donor.client_id, organCode: this.allocation.organ_code, allocationId: this.allocation._id });
  }

  /**
   * Return ipos heart data
   *
   * @param recipientId string representation of recipient ObjectID
   * @param journeyOrganCode numeric organ code associated with the recipient journey entry in allocation list
   */
  get getIposDetails() {
    const record = this.recipientDetails.recipient;
    return {
      // IPOS Heart Data
      hsh: record.hsh ? this.$t('yes').toString() : this.$t('no').toString(),
      on_wait_time: record.wait_days === 0 ? 0 : (record.wait_days || '-'),
      on_wait_time_date: this.parseDisplayDateUiFromDateTime(record.wait_days_date) || '-',
      initial_list_date: this.parseDisplayDateUiFromDateTime(record.listing_date) || '-',
      ctr_wait_time: record.ctr_wait_days === 0 ? 0 : (record.ctr_wait_days || '-'),
      ctr_wait_time_date: this.parseDisplayDateUiFromDateTime(record.ctr_wait_days_date) || '-',
    };
  }

  /**
   * Load up the recipient details and catch any errors
   *
   * @returns {iposObject} object representation of ipos data
   */
  public initializeAllocationCompare(recipientId: string, journeyOrganCode?: number): void {
    this.loading = true;
    // clear error message
    this.recipientDetailsErrorMessage = "";
    this.toggleModal();
    this.$store.dispatch('allocations/getRecipientDetails', { donorId: this.donor.client_id, allocationOrganCode: this.allocation.organ_code, allocationId: this.allocation._id, recipientId: recipientId, journeyOrganCode }).then(() => {
      if(!this.recipientDetails.recipient.donor_acceptability) {
        // Force set a blank donor_acceptability for OOP manually added recipient
        this.recipientDetails.recipient.donor_acceptability = {};
      }
      this.loading = false;
    }).catch((error: any) => {
      this.recipientDetailsErrorMessage = error.errorMessages && error.errorMessages.length > 0 ? error.errorMessages[0] : error;
      this.loading = false;
    });
  }

  // Clear recipient details state and reset the tabs when the modal is closed
  private clearRecipientDetailsState() {
    this.$store.commit('allocations/clearRecipientDetails');
    // reset tabs
    if (this.recipientDetails) {
      const recipientDetailsTab = this.$refs.recipientDetailsTab as HTMLElement;
      recipientDetailsTab.click();
    }
  }

  private checkIfOrganSpecificAllocation(organ: any) {
    if (this.recipientDetails.organ == organ) {
        return true;
    }
  }

  // Toggle modal
  private toggleModal(): void {
    const targetModal = this.$refs.recipientInformationModal as ModalSection;
    targetModal.toggleModal();
  }

  public parseDate(datetime: string) {
    return !isMasked(datetime) ? this.parseFormattedDateUi(datetime) : datetime;
  }

  // if value is null then return '-' otherwise return value
  public parseNullValue(value: any) {
    return value === null ? '-' : value ? this.$t('yes').toString() : this.$t('no').toString();
  }

  // if value is 0 then returns 0, if value is null the returns '-', otherwise returns value
  public parsePossibleZeroValue(value: number) {
    return value === 0 ? 0 : (value || '-');
  }

  /**
   * @param value cause of death code for donor
   * @returns {string} string representation of cause of death for donor
   */
  public parseCauseOfDeath(value: number) {
    return this.lookupValue(value?.toString(), 'cause_of_death_donor');
  }

  /**
   * @param bmi number value of bmi
   * @returns {number} bmi to 1 decimal place
   */
  public parseBmi(bmi: number): number|string {
    return bmi ? parseFloat(bmi.toFixed(1)) : '-';
  }

  /**
   * @param height number value of height
   * @returns {string} height with cm unit measurement
   */
  public parseHeight(height: number): string {
    return height ? height + this.$t('cm').toString() : '-';
  }

  /**
   * @param weight number value of bmi
   * @returns {string} weight to 1 decimal place with kg unit measurement
   */
  public parseWeight(weight: number): string {
    // Handle missing information: this is true only for [null, undefined]; false for 0
    return weight ? parseFloat(weight.toFixed(1)) + this.$t('kg').toString() : '-';
  }

  /**
   * List of Exceptional Distribution reason text values based on exd_reason_codes
   * in donor details response from allocation service.
   *
   * Note: does not include 'other travel', 'other transmission', or 'other other',
   * since those are disabled as separate rows in the compare modal
   *
   * @returns {string[]} array of exd reasons
   */
  get exdReasonValues(): string[] {
    if (!this.donorDetails || !this.donorDetails.donor) return [];

    // get list of exd reason codes
    const codes = this.donorDetails?.donor?.exd_reason_codes || [];

    // filter out system-only reasons (see B#15171)
    const filtered = codes.filter((code: number) => {
      return !SYSTEM_ONLY_EXCEPTIONAL_DISTRIBUTION_REASONS.includes(code);
    });

    // Sort codes by number and then map to display text
    const values = filtered.sort((x: any, y: any) => x > y ? 1 : -1).map((code: number): string => {
      return this.lookupValueNumeric(code, 'donor_exceptional_distribution') || this.$t('unknown').toString();
    });
    return values;
  }

  // Whether or not the selected organ allocation is for lung
  get isLungAllocation() {
    const organCode = this.allocation.organ_code;
    return organCode == OrganCodeValue.Lung;
  }

  /**
   * Returns name of selected organ, based on allocation organ code
   *
   * @returns {string} organ name
   */
  get allocationOrganName() {
    const organCode = this.allocation.organ_code;
    const allocationOrganName: string|undefined = this.organNameLookup(organCode) || undefined;
    return allocationOrganName ? this.$t(allocationOrganName).toString() : this.$t('unknown').toString();
  }

  get classOneTypingTable() {
    return this.CLASS_1_TYPING_TABLE;
  }

  get classTwoTypingTable() {
    return this.CLASS_2_TYPING_TABLE;
  }

  get epitopesLabel() {
    return this.$t('epitopes').toString();
  }

  // Whether or not we have enough information to navigate to Recipient profile and Recipient HLA pages
  get showRecipientLinks(): boolean {
    return !!this.recipientDetails && !!this.recipientDetails?.recipient?.client_id && !isMasked(this.recipientDetails?.recipient?.client_id);
  }

  // Get the recipient's transplant program
  get getRecipientTransplantProgram(): string|null {
    // if no hospital id, fallback to show program code
    if (!this.recipientDetails?.recipient?.hospital_id) return this.recipientDetails?.recipient?.program;
    const transplantId = this.recipientDetails?.recipient?.hospital_id ? this.recipientDetails.recipient.hospital_id as unknown as string : null;
    const transplantProgram = transplantId ? this.getHospitalAbbreviation(transplantId) : '--';
    return transplantProgram;
  }

  /**
   * Provide a string representation of the recipient's required organ
   *
   * Since the recipient can be listed for combinations of organs, we might be showing a list of organ names.
   * Ideally we show the 'waitlisted_for_organs' string array derived by the Allocation Service. If this is
   * not available (e.g. it appears to be empty for CTR Recipients) we can instead use the 'organ' property
   * that relates to the organ the allocation was originally run for
   * 
   * As per CR-2023-019, new string array derived from Allocation Service 'listed_for' is used to show sorted organs.
   *
   * This requires a lot of manual manipulation to match what the report shows. The report registration_type for listed for
   * is different than the allocation registration type.
   * See Sean Hundt's comment on https://shore.tpondemand.com/entity/11981-tgln_uat-sp-3042-cyc1-uat2-recipient
   *
   * @returns {string} text description of the recipient's Waitlisted Organ Journeys
   */
  get describeOrganListedFor(): string {
    if (!this.recipientDetails || !this.recipientDetails.recipient) {
      return '-';
    }

    const waitlistedOrgans = this.recipientDetails.recipient?.listed_for || [this.recipientDetails.organ];
    return this.parseListedFor(this.recipientDetails.recipient.registration_type, waitlistedOrgans);
  }

  /**
   * Get a string representation of the recipient's donor type
   *
   * @returns {string} string representation of the recipient's preferred donor type
   */
  get recipientDonorType(): string {
    const donorAcceptability = this.recipientDetails.recipient.donor_acceptability;
    if(!donorAcceptability) return 'N/A';
    const donorType = this.donorTypes.find((e: any) => {
      return e.deceased_donor == donorAcceptability.deceased_donor && e.living_donor == donorAcceptability.living_donor;
    });
    return donorType ? donorType.value : 'N/A';
  }

  public sexValue(code: string):string {
    return code ? this.lookupValue(code, 'gender') : '-';
  }

  // String representation of the Medical Status using the Medical Status Lookup
  private parseMedicalStatus(medicalStatusCode: any): string {
    const medStatusLookup = this.medicalStatusLookup(true, this.organLookup, this.allocation.organ_code);
    const medicalStatus = medStatusLookup.find((medStatus: OrganWaitlistMedicalStatus) => medStatus.code == medicalStatusCode);
    return medicalStatus?.value;
  }

  // String representation of the Secondary Medical Status using the Secondary Medical Status Lookup
  private parseSecondaryMedicalStatus(secondaryMedicalStatusCode: any): string|undefined {
    if (secondaryMedicalStatusCode) {
      const secondaryMedStatusLookup = this.secondaryMedicalStatusLookup(this.organLookup, this.allocation.organ_code);
      const secondaryMedicalStatus = secondaryMedStatusLookup.find((secondaryMedStatus: OrganWaitlistMedicalStatus) => secondaryMedStatus.code == secondaryMedicalStatusCode);
      return secondaryMedicalStatus!.value;
    } else {
      return undefined;
    }
  }

  public parseVirology(value: boolean) {
    if (value == null) {
      return this.$t('not_done').toString();
    } else if (!value) {
      return this.$t('negative').toString();
    } else {
      return this.$t('positive').toString();
    }
  }

  /**
   * Gets table row data representing the HLA Typing for the selected recipient.
   *
   * @param formState current edit state for the HLA Typing form
   * @returns {HlaTypingAntigenRow[]} HLA Typing rows
   */
  public hlaTypingDetailRows(CLASS_TYPING: any, patient: any): HlaTypingAntigenRow[] {
    const result: HlaTypingAntigenRow[] = [];

    // if typing isn't available then return empty array
    if(!patient.hla_typing.typing_available) {
      return result;
    }

    const antigens = patient.hla_typing.antigens;
    let prevLocus = "";

    // Iterate through all possible loci
    antigens.map((item: any) => {
      item.sequence = prevLocus === item.molecular_locus ? 2 : 1;
      prevLocus = item.molecular_locus;
    });

    CLASS_TYPING.forEach((loci: HlaTypingLoci, index: number) => {
      const antigen = antigens.find((item: any) => item.molecular_locus === loci.locus && item.sequence === loci.sequence);

      // Epitopes
      if (loci.locus == this.epitopesLabel) {
        let epitopes: string[] = [];

        // loop through epitopes and push the values into the array
        patient.hla_typing.epitopes.forEach((epitope: LabHlaTypingEpitope) => {
          if (epitope.epitope_value){
            epitopes.push(epitope.epitope_value);
          }
        });

        let sequence1: HlaTypingAntigenRow = {
          locus: loci.locus,
          molecular: epitopes
        };
        // push the epitopes into the HLA Typing Array
        result.push(sequence1);
      }
      if (antigen) {
        let molecular = this.parseMolecularValue(antigen.molecular_value, antigen.most_likely_allele);
        let most_likely_allele = this.parseMostLikelyAllele(antigen.molecular_value, antigen.most_likely_allele);
        let serologic = antigen.serological_values;

        let sequence1: HlaTypingAntigenRow = {
          locus: loci.locus,
          molecular: molecular ? [molecular] : [],
          most_likely_allele: most_likely_allele ? [most_likely_allele] : [],
          serologic: serologic ? serologic : [],
        };
        // Store the entries for this locus
        result.push(sequence1);
      }
    });
    return result;
  }

  /**
   * Gets configuration for the Details Table
   *
   * @returns {TableConfig} Details Table configuration
   */
  public hlaTypingDetailsTableConfig(isRecipient: boolean, class_typing: any): TableConfig {
    return {
      data: isRecipient ? this.hlaTypingDetailRows(class_typing, this.recipientDetails.recipient) : this.hlaTypingDetailRows(class_typing, this.donorDetails.donor),
      columns: [
        { label: '', field: 'locus', width: '5%' },
        { label: this.$t('molecular'), field: 'molecular', width: '27.5%' },
        { label: this.$t('most_likely_allele'), field: 'most_likely_allele', width: '40%' },
        { label: this.$t('serologic'), field: 'serologic', width: '27.5%' },
      ],
      // Disable unused sorting feature, because Vue Good Table has sorting enabled by default
      sortOptions: {
        enabled: false,
      },
    };
  }

  /**
   * Extract the gene from an antibody string that may have allele-specific information.
   *
   * E.g. returns "01" if the input is "01:02"
   *
   * @param rawMolecular string representation of an antibody that may or may not have allele-specific information
   */
  private parseMolecularValue(rawMolecular?: string|null, isMostLikelyAllele?: boolean): string|undefined {
    if (!rawMolecular) {
      return undefined;
    }
    // If we cannot parse, then we check the boolean most_likely_allele value. If false, we return raw input
    const antigen = this.parseHlaTypingTag(rawMolecular);
    if (!antigen) {
      return !isMostLikelyAllele ? rawMolecular : undefined;
    }
    // Return standard Molecular representation, which is the allele group only e.g. 01
    return antigen.standardAlleleGroupOnly;
  }

  /**
   * Extract just the allele from an antibody string that may have allele-specific information.
   *
   * E.g. returns "01:02" if the input is "01:02", but undefined for "01" on its own
   *
   * @param rawMolecular string representation of an antibody that may or may not have allele-specific information
   */
  private parseMostLikelyAllele(rawMolecular?: string|null, isMostLikelyAllele?: boolean): string|undefined {
    // If there is no data or the most_likely_allele boolean is false, then most likely allele is blank
    if (!rawMolecular || !isMostLikelyAllele) {
      return undefined;
    }
    // If we cannot parse, then return raw input
    const antigen = this.parseHlaTypingTag(rawMolecular);
    if (!antigen) {
      return rawMolecular;
    }
    // Return standard Most Likely Allele representation or raw input if unexpected standardization error
    return antigen.standardMostLikelyAllele || rawMolecular;
  }

}
</script>
