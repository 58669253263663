<template>
  <card-section
    section-id="monitor-recovery-outcomes"
    :lookups-to-load="lookupsToLoad"
    @loaded="loaded()"
  >
    <template v-slot:header>
      {{$t('monitor_recovery_outcomes')}}
    </template>
    <template v-slot:body>
      <sub-section
      sub-section-id="recovery-outcomes"
      ref="recoveryOutcomes"
      :table-config="recoveryOutcomesTableConfig"
      >
      <template v-slot:table-cell="props">
        <!-- Link table cells -->
        <template v-if="props.column.field == 'recipientId'">
          <router-link
            v-if="props.row.recipientId"
            class="table-link"
            :to="{
              name: props.row.isOutOfProvince ? 'edit-recipient-oop' : 'edit-recipient',
              params: { id: props.row.recipientId }
            }"
            :title="$t('link_to_recipient_page')"
            :alt="props.row.recipientId"
          >
            {{props.formattedRow[props.column.field] }}
          </router-link>
          <span v-else>-</span>
        </template>
        <template v-else-if="props.column.field == 'recipientName'">
          <router-link
            v-if="props.row.recipientName"
            class="table-link"
            :to="{
              name: props.row.isOutOfProvince ? 'edit-recipient-oop' : 'edit-recipient',
              params: { id: props.row.recipientId }
            }"
            :title="props.row.recipientName"
            :alt="props.row.recipientName"
          >
            {{props.row.recipientNameShort }}
          </router-link>
          <span v-else>-</span>
        </template>
        <!-- Date/Time cell -->
        <template v-else-if="props.column.field == 'lastUpdate'">
          <span :title="props.row.lastUpdateTime">
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
        <!-- Other table cells -->
        <span v-else>
          {{props.formattedRow[props.column.field] != null ? props.formattedRow[props.column.field] : '-'}}
        </span>
      </template>
    </sub-section>
    </template>
  </card-section>
</template>

<i18n src="./_locales/MonitorRecoveryOutcomes.json"></i18n>
<i18n src="./_locales/commonPatientShared.json"></i18n>
<i18n src="../_locales/Organs.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/common.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { State, Getter } from 'vuex-facing-decorator';
import { Hospital } from '@/store/hospitals/types';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { DeceasedDonorOrganDonations, DeceasedDonorAllocationSummary } from '@/store/deceasedDonors/types';

interface RecoveryOutcomeRow {
  organ: string;
  outcome: string;
  organization: string;
  recipientId?: string|null;
  recipientName?: string;
  recipientNameShort: string;
  recipientAge: string;
  lastUpdate: string;
  lastUpdateTime?: string;
  isOutOfProvince: boolean;
}

@Component({
  components: {
    SubSection,
    CardSection,
  }
})
export default class showMonitorRecoveryOutcomes extends mixins(DateUtilsMixin) {
  @State(state => state.deceasedDonors.organDonations) private organDonations!: DeceasedDonorOrganDonations[]|null;

  @Getter('clientId', { namespace: 'deceasedDonors' }) private donorId!: number;
  @Getter('getHospitalById', { namespace: 'hospitals' }) getHospitalById!: (hospitalCode?: string|null) => Hospital|null;
  @Getter('lookupValueNumeric', { namespace: 'lookups' }) lookupValueNumeric!: (code: number, lookupId: string) => string|null;
  @Getter('getOrganSpecificationName', { namespace: 'lookups' }) getOrganSpecificationName!: (organCode?: number|null, organSpecificationCode?: number|null) => string;

  private lookupsToLoad = [
    'donor_organ_donation_outcomes',
  ];

  //Table configuration
  get recoveryOutcomesTableConfig(): TableConfig {
    return {
      data: this.recoveryOutcomeRows,
      columns: [
        { label: this.$t('organs').toString(), field: 'organ' },
        // TP B#15335 TGLN_UAT: SP 4073 - Donor Summary - "Outcome" column should be hidden
        // { label: this.$t('outcome').toString(), field: 'outcome' },
        { label: this.$t('receiving_organization').toString(), field: 'organization' },
        { label: this.$t('recipientID').toString(), field: 'recipientId' },
        { label: this.$t('recipientName').toString(), field: 'recipientName' },
        { label: this.$t('recipientAge').toString(), field: 'recipientAge' },
        { label: this.$t('lastUpdate').toString(), field: 'lastUpdate' },
      ],
      empty: this.emptyMessage,
    };
  }

  get emptyMessage(): string {
    if (!this.organDonations) {
      return this.$t('loading').toString();
    } else {
      return this.$t('no_recovery_outcomes').toString();
    }
  }

  // Map data loaded from API to array of UI rows
  get recoveryOutcomeRows(): RecoveryOutcomeRow[] {
    const collection: DeceasedDonorOrganDonations[] = this.organDonations || [];
    const rows = collection.map((item: DeceasedDonorOrganDonations): RecoveryOutcomeRow => {
      const organ = this.getOrganSpecificationName(item.organ_code, item.organ_specification_code);
      const firstName = item?.organ_offer?.recipient_first_name;
      const lastName = item?.organ_offer?.recipient_last_name;
      const recipientName = !!firstName || !!lastName ? (`${firstName} ${lastName}`).trim() : undefined;
      const recipientNameShort = !!lastName ? (lastName.length > 3 ? lastName.substring(0, 3) : lastName) : '-';
      const recipientAgeOffered = item?.organ_offer?.recipient_age;
      const recipientAge = recipientAgeOffered != null ? recipientAgeOffered : item?.transplanted_opo_recipient?.age;
      const outcome = item.outcome_code ? this.lookupValueNumeric(item.outcome_code, 'donor_organ_donation_outcomes') : undefined;
      const organRecovery = item?.organ_recovery && item?.organ_recovery.length > 0 ? item?.organ_recovery[0] : {};
      const destinationIdentifier = organRecovery?.destination_program_identifier;
      const destinationProgramId = organRecovery?.destination_program?.$oid;
      const hospital = this.getHospitalById(destinationProgramId);
      return {
        organ: this.$t(organ).toString(),
        outcome: outcome ? outcome : '-',
        organization: destinationIdentifier || hospital?.hospital_name_info?.abbreviation || '-',
        recipientId: item?.organ_offer?.recipient_client_id ? item?.organ_offer?.recipient_client_id.toString() : null,
        recipientName,
        recipientNameShort,
        recipientAge: recipientAge != null ? recipientAge.toString() : '-',
        lastUpdate: this.parseDisplayDateUiFromDateTime(item.updated_at) || '-',
        lastUpdateTime: this.parseTimeUiFromDateTime(item.updated_at),
        isOutOfProvince: !!item?.organ_offer?.oop_recipient,
      };
    });
    return rows;
  }

  // Load donor's array of Organ Donations
  private loaded(): void {
    this.$emit('loaded', 'monitorRecoveryOutcomes');
    this.$store.dispatch('deceasedDonors/loadOrganDonations', this.donorId);
  }

  // Reload when confirmations have been updated
  private reload(): void {
    this.loaded();
  }
}
</script>
