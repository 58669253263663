export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize panel"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse panel"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Options"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Style"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this Panel"])},
        "show_only_unread_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show  Only Unread Notifications"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "no_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Notifications"])},
        "date_time_of_event_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Time of Event Trigger"])},
        "description_of_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of Notification"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
        "view_notifications_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Notifications History"])},
        "notification_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification History"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unread"])},
        "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])}
      },
      "fr": {
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le panneau"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire le panneau"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style du panneau"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher ce panneau"])},
        "show_only_unread_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher uniquement  les notifications non-lues"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "no_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune notification"])},
        "date_time_of_event_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure du déclencheur"])},
        "description_of_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de notification "])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultats par page"])},
        "view_notifications_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir l'historique des notifications"])},
        "notification_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des notifications"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
        "unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-lu"])},
        "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lu"])}
      }
    }
  })
}
