export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer History"])},
        "title_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offer history for Allocation"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique de l’offre"])},
        "title_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["historique de l’offre pour l’attribution"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo"])}
      },
      "en_tgln": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID"])}
      },
      "fr_tgln": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])}
      },
      "en_tq": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])}
      },
      "fr_tq": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])}
      }
    }
  })
}
