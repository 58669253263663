export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unknown"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize Panel"])},
        "allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation ID"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Options"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Style"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this panel"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "next_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "prev_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "rows_per_page_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
        "allocation_widget_state": {
          "recommending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Running"])},
          "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Run"])},
          "offering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offering"])},
          "offer-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Accepted"])},
          "offer-confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Confirmed"])},
          "discontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinued"])},
          "superceded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superceded"])},
          "concluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluded"])},
          "transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplanted"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
          "primary-offer-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Offer Pending"])},
          "primary-offer-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Offer Sent"])}
        }
      },
      "fr": {
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unknown"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize Panel"])},
        "allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l’attribution"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options du panneau"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style du panneau"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher ce panneau"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "next_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "prev_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "rows_per_page_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])},
        "allocation_widget_state": {
          "recommending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation en cours"])},
          "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycle d'allocation"])},
          "offering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre"])},
          "offer-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre acceptée"])},
          "offer-confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre confirmé"])},
          "discontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandonné"])},
          "superceded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacé"])},
          "concluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclu"])},
          "transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplanté"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
          "primary-offer-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre primaire en attente"])},
          "primary-offer-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre primaire envoyée"])}
        }
      }
    }
  })
}
