import Vue from 'vue';
import deepmerge from 'deepmerge';
import { MutationTree } from 'vuex';
import { Rules } from '@/store/validations/types';
import { ValidationsState } from '@/store/validations/types';

export const mutations: MutationTree<ValidationsState> = {
  set(state, rules) {
    // Clone validation data and sanitize the clone
    const sanitizedRules: Rules = Object.assign({}, rules.rules || {});
    // Deepmerge incoming rules into existing state.ruleSet 
    // Note: this prevents replacing existing rules entirely with incoming rules which might be limited in scope
    const mergedRules = deepmerge.all([sanitizedRules, (state.ruleSet || {})]);
    state.ruleSet = mergedRules;
  },
  setAppendRecoveryInfoRules(state, rules) {
    // Deepmerge incoming groups into existing state.ruleSet.groups
    const currentRules = state.ruleSet || {};
    const recoveryInfo = rules.recovery_info || {};
    currentRules.groups = deepmerge.all([currentRules.groups || {}, (recoveryInfo.groups || {})]);
    state.ruleSet = currentRules;
  },
  reset(state) {
    state.ruleSet = undefined;
  },

  resetPrefix(state, prefix) {
    if (!state.ruleSet) return;

    state.ruleSet[prefix] =  {};
  }
};
