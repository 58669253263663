import Vue from 'vue';
import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { LaboratoriesState, Laboratory } from '@/store/laboratories/types';

export const actions: ActionTree<LaboratoriesState, RootState> = {
  load({ state, rootGetters }, labType): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Begin fetching index of laboratories with parameters limiting the response to the specified lab type
      const sanitizedLabType = labType.toUpperCase();
      const url = APIRoute(EP.laboratories.index, [[':lab_type', sanitizedLabType]]);
      axios.get(url).then((response: any) => {
        // Set the specified laboratories state to the list of laboratories in the response data
        const laboratories: Laboratory[] = response.data.laboratories || [];
        const filtered = laboratories.filter((item: Laboratory) => {
          // NOTE: this may be invalid using a getter inside an action
          const valid = rootGetters['configuration/regionIncludesProvinceCode'](item);
          if (valid) return item;
        });
        state[labType] = filtered;
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
};
