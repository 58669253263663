<template>
  <div v-if="donorCanAccessCtrLog">
    <template>
      <page-top>
        <router-link :to="{ name: 'administration' }">
          {{ $t('administration') }}
        </router-link>
        /
        {{ $t('ctr_integration') }}
      </page-top>
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper" id="administration-area">
            <side-nav-ctr />
            <div class="page-content">
              <form autocomplete="off">
                <ctr-event-log />
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import PageTop from '@/components/shared/PageTop.vue';
import { Component, Vue } from 'vue-facing-decorator';
import SideNavCtr from '@/components/administration/side-nav/SideNavCtr.vue';
import CtrEventLog from '@/components/administration/CtrEventLog.vue';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    PageTop,
    CtrEventLog,
    SideNavCtr
  },
  ...i18nMessages([
    require('@/components/_locales/ManageAccount.json'),
  ]),
})
export default class CtrIntegration extends Vue {
  // Getters
  @Getter('donorCanAccessCtrLog', { namespace: 'users' }) private donorCanAccessCtrLog!: boolean;
}
</script>
