<template>
  <modal-section
    modalId="duplicate-living-donor-modal"
    ref="duplicateLivingDonorModal"
    class="modal-sticky-header"
    :centered="true">
    <template v-slot:title>
      {{$t('potential_duplicate')}}
    </template>
    <template v-slot:body>
      {{modalDuplicateWarningMessage}}
    </template>
    <template v-slot:footer>
      <div class="modal-footer-body">
        <button type="button" data-dismiss="modal" class="btn btn-secondary">
          {{$t('cancel')}}
        </button>
        <a class="btn btn-success" @click="saveDuplicatePatch()">
          {{$t('continue')}}
        </a>
      </div>
    </template>
  </modal-section>
</template>

<i18n src="@/components/livingDonors/_locales/PotentialDuplicateModal.json"></i18n>

<script lang="ts">
import { Getter, State } from "vuex-facing-decorator";
import {Component, Vue} from "vue-facing-decorator";
import ModalSection from '@/components/shared/ModalSection.vue';
import {SaveResult} from "@/types";

@Component({
  components: {
    ModalSection,
  }
})
export default class PotentialDuplicateModal extends Vue {
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  public modalDuplicateWarningMessage = '';
  public backEmitAction = '';

  public saveDuplicatePatch(): void {
    const targetModal = this.$refs.duplicateLivingDonorModal as ModalSection;
    targetModal.toggleModal();
    this.$emit(this.backEmitAction);
  }

  public checkingDuplicateWarning(error: SaveResult, backEmitAction: any): void {
    this.backEmitAction = backEmitAction;
    if(error.warning === true) {
      const targetModal = this.$refs.duplicateLivingDonorModal as ModalSection;
      this.modalDuplicateWarningMessage = this.translateError(error.warningMessage, null);
      targetModal.toggleModal();
    }
  }
}

</script>

