<template>
  <!-- Sub-section container -->
    <sub-section
      sub-section-id="patient-address"
      :title="$t('patient_address_title')"
      :isParentLoading="isLoading"
    >
    <template v-slot:contents>
      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="showCreateButton"
        :createText="$t('address_create_button')"
        @table-create-row="handleTableCreateRow"
      >
        <template v-slot:button-bar>
          <div class="filter-section-action-row">
            <div class="filter-section-wrapper">
              <filter-component
                fieldID="addressFilter"
                :showFilter="true"
                :showArchived="true"
                @setFilters="handleSetFilters"
              />
            </div>
          </div>
        </template>
      </table-toolbar>

      <!-- List of Items, or History List -->
      <table-list
        ref="patientAddressTable"
        table-id="patient-address-table"
        :table-config="patientAddressTableConfig"
        @table-row-click="handleTableRowClick"
        :highlightSelection="true"
        tabbableColumn="lastUpdatedDisplay"
        :rowStyleClass="rowStyleClass"
      />

      <!-- Form layout -->
      <address-form
        form-id="patient-address-form"
        ref="patientAddressForm"
        :selection="selection"
        :newRecipient="newRecipient"
        :canSave="canSave"
        @success="handleSuccess"
      />
    </template>
  </sub-section>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { TableConfig } from '@/types';
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Prop } from 'vue-facing-decorator';
import { UIAddress } from "@/UIModels/address";
import { AddressCategoryLookup, Country } from '@/store/lookups/types';
import { SaveResult } from '@/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from '@/components/shared/filter/FilterComponent.vue';
import TableList from '@/components/shared/TableList.vue';
import AddressForm from '@/components/recipients/contactInformation/patientAddress/AddressForm.vue';
import { UIError } from '@/UIModels/error';
import { UIFilterAssignments } from '@/store/recipientJourney/types';
import { i18nMessages } from '@/i18n';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { sortByDate } from '@/utils';

interface PatientAddressComponentRow {
  id: string|null;
  lastUpdatedDateObject: Date|null;
  lastUpdatedDisplay: string;
  categoryType: string;
  description: string;
  status: string;
  isArchived: boolean;
}

// When an interaction with the table list requires scrolling to the form, where should it go to?
const SCROLL_TO_ID = "patient-address-form";

// What are all the possible per-page sizes allowed in the table list pagination bar?
const PAGE_SIZES = [5, 10, 25];

@Component({
  components: {
    SubSection,
    TableToolbar,
    FilterComponent,
    TableList,
    AddressForm,
  },
  ...i18nMessages([
    require('@/components/recipients/contactInformation/_locales/PatientAddress.json'),
  ]),
})
export default class PatientAddress extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.address_categories) addressCategories!: AddressCategoryLookup[];

  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('country', { namespace: 'lookups' }) private countryOptions!: Country[];

  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Selection instance to provide to child form component
  private selection = new UIListFormSelection();

  // Local component state related to table filters
  private filterAssignments: UIFilterAssignments = {
    include_archived: false,
  }

  private isLoading = true;

  // Reference to Patient Address table list
  get patientAddressTable(): TableList {
    return this.$refs.patientAddressTable as TableList;
  }

  // Do we need to parse any Address view model content for display in the table?
  get patientAddressRows(): PatientAddressComponentRow[] {
    return this.patientAddressListItems.map((uiAddress: UIAddress): PatientAddressComponentRow => { return this.buildRow(uiAddress); });
  }

  // Handle frontend filtering based on current "filterAssignments" values
  get filteredRows(): PatientAddressComponentRow[] {
    return this.patientAddressRows.filter((addressRow: PatientAddressComponentRow) => {
      return this.filterAssignments.include_archived || !addressRow.isArchived;
    });
  }

  get showCreateButton(): boolean {
    return this.canSave && !this.newRecipient;
  }

  // Configure the list table
  get patientAddressTableConfig(): TableConfig {
    return {
      data: sortByDate(this.filteredRows, { fieldName: 'lastUpdatedDateObject' }),
      columns: [
        { label: this.$t('address_last_updated').toString(), field: 'lastUpdatedDisplay', width: '125px'},
        { label: this.$t('address_category_name').toString(), field: 'categoryType',  width: '185px' },
        { label: this.$t('address_description').toString(), field: 'description' },
        { label: this.$t('address_status').toString(), field: 'status', width: '105px'},
      ],
      empty: this.$t('address_table_empty').toString(),
      sortOptions: {
        enabled: false,
        initialSortBy: [{ field: 'lastUpdatedDateObject', type: 'desc' }]
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  // How many address items does this Patient have based on the current filter assignments?
  // NOTE: make sure to do filtering before counting records
  get totalRecords(): number {
    if (!this.filteredRows) return 0;

    return this.filteredRows.length;
  }

  // Figure out if we need any extra style class based on the row data
  private rowStyleClass(row: PatientAddressComponentRow): string|null {
    if (!row) return null;
    if (row.isArchived) return 'highlight-archived';
    return null;
  }

  // Map from UI Address view model to row interface for this component's table
  private buildRow(uiAddress: UIAddress): PatientAddressComponentRow {
    return {
      id: uiAddress.id,
      lastUpdatedDateObject: uiAddress.lastUpdatedDateObject,
      lastUpdatedDisplay: this.parseDisplayDateUiFromDateTime(uiAddress.lastUpdated) || '-',
      categoryType: uiAddress.categoryType ? this.lookupValue(uiAddress.categoryType, 'address_categories') : '-',
      description: uiAddress.description(this.countryOptions) || '-',
      status: uiAddress.status ? this.$t(uiAddress.status).toString() : '-',
      isArchived: uiAddress.status === 'status.archived',
    };
  }

  scrollToMethod() {
    if (SCROLL_TO_ID) {
      // perform scrollTo
      const location = document.getElementById(SCROLL_TO_ID) || null;
      if (location) {
        const elementPosition = location.getBoundingClientRect().top;
        const offsetPosition = elementPosition - 105;
        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }
  }

  // Process an update to one of the inputs in the filter component
  // NOTE: here we copy values to ensure table rows only change on "Filter" or "Clear" button clicks
  private handleSetFilters(filterAssignments: UIFilterAssignments): void {
    Object.assign(this.filterAssignments, { ...filterAssignments });
  }

  // Clear selected address based on a create button click event
  private handleTableCreateRow(): void {
    this.patientAddressTable.resetSelection();
    this.selection = new UIListFormSelection();
    this.scrollToMethod();
  }

  // Select an address UI Model List item from the list based on a row click event
  private handleTableRowClick(event: { row: PatientAddressComponentRow }): void {
    const listItem = this.patientAddressListItems.find((listItem: UIAddress) => { return listItem.id == event.row.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem.id);
    this.scrollToMethod();
  }

  // Clear selection
  private handleSuccess(_success: SaveResult): void {
    this.patientAddressTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  // Get UI Address view models from current page pinia store UI Recipient view model
  get patientAddressListItems(): UIAddress[] {
    const currentPageStore = useCurrentPageStore();
    const uiRecipient = currentPageStore.currentRecipient;
    if (!uiRecipient) return [];

    return uiRecipient.addresses as UIAddress[];
  }

  mounted(): void {
    const currentPageStore = useCurrentPageStore();
    const uiRecipient = currentPageStore.currentRecipient;
    if (!uiRecipient) return;

    uiRecipient.load().then(() => {
      this.isLoading = false;
    }).catch((uiError: UIError) => {
      this.isLoading = false;
      console.warn('Something unexpected happen when attempting to get address information', uiError);
    });
  }
}
</script>
