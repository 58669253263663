export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "decline_multiple_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline multiple offers."])},
        "decline_reasons_to_send_multiple_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give the decline reasons you wish to send for multiple offers."])},
        "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response"])},
        "reason_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason Category"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
        "confirm_and_save_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm and Save Responses"])}
      },
      "fr": {
        "decline_multiple_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser les offres multiples"])},
        "decline_reasons_to_send_multiple_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer les raisons du refus que vous souhaitez envoyer pour les offres multiples."])},
        "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
        "reason_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de motif"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif"])},
        "confirm_and_save_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer et enregistrer les réponses"])}
      }
    }
  })
}
