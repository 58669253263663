export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "bookmarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmarks"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse Panel"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Options"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Style"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize Panel"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this panel"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "new_bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Bookmark"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "save_bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Bookmark"])},
        "create_bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Bookmark"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "missing_bookmark_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing Bookmark Name"])},
        "saving_bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving Bookmark"])},
        "bookmark_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmark saved"])},
        "problem_saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem saving the bookmark"])},
        "delete_bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Bookmark"])},
        "selected_bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Bookmark"])},
        "use_form_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the form below to add a new Bookmark"])}
      },
      "fr": {
        "bookmarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signets"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau rabattu"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options du panneau"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style du panneau"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le panneau"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher ce panneau"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "new_bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau signetk"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
        "save_bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder le signet"])},
        "create_bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un signet"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
        "missing_bookmark_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du signet manquant"])},
        "saving_bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegardant le signet"])},
        "bookmark_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signet sauvegardé"])},
        "problem_saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a eu un problème lors de la sauvegarde du signet"])},
        "delete_bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le signet"])},
        "selected_bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signet sélectionné"])},
        "use_form_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez le formulaire ci-dessous pour ajouter un nouveau signet"])}
      }
    }
  })
}
