<template>
  <div
    :id="modalId"
    :aria-labelledby="labelId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div 
      :class="modalStyle" 
      role="document"
    >
      <div
        :class="modalClass"
      >
        <div class="modal-header">
          <h5 :id="labelId" class="modal-title">
            <slot name="title" />
          </h5>
          <button
            tabindex="-1"
            type="button"
            class="close"
            aria-label="Close"
            @click="hideModal()"
            v-if="closeButton"
          >
            <span class="sr-only">
              {{$t('close_modal')}}
            </span>
            <span aria-hidden="true">
              <font-awesome-icon :icon="['far', 'times-circle']" class="text-red" fixed-width />
            </span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { i18nMessages } from '@/i18n';
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({
  ...i18nMessages([
    require('@/components/shared/_locales/common.json'),
  ]),
  emits: [
    'hide',
    'switch',
  ],
})
export default class ModalSection extends Vue {
  @Prop({ required: true }) modalId!: string;
  @Prop({ default: false }) centered!: boolean;
  @Prop({ default: true }) wide!: boolean; 
  @Prop({ default: 'lg' }) size!: string; // Size of the modal (sm, md, lg)
  @Prop({ default: true }) closeButton!: boolean; // Do you need a close button
  @Prop({ default: 'modal-content' }) modalClass!: string;

  get labelId(): string {
    return `${this.modalId}-label`;
  }

  get modalElement(): JQuery<HTMLElement> {
    return $(`#${this.modalId}`);
  }

  /**
   * Return the class element on the modal
   *
   * @returns {string} css styles
   */
  get modalStyle(): string {
    // default style
    const style = ['modal-dialog'];
    // Add centred
    if (this.centered) { style.push('modal-dialog-centered'); }
    // Add wide
    if (this.wide) { style.push('modal-wide'); }
    // Add size (default is lg)
    style.push(`modal-${this.size}`);
    return style.join(' ');
  }

  public showModal(): void {
    this.modalElement.modal('show');
  }

  public hideModal(): void {
    this.modalElement.modal('hide');
  }
  
  // Open a static modal which will only close with cancel or the close (x) button
  public toggleStaticModal(): void {
    this.modalElement.modal({ backdrop: 'static', keyboard: false });
  }

  public toggleModal(): void {
    this.modalElement.modal('toggle');
  }

  public switchModal(options?: any): void {
    // Remove general 'hide' event handler, so that event is not emitted in the middle of the switching action
    this.modalElement.off('hidden.bs.modal');
    // Register 'switch' event handler, so parent can change form state before showing the modal again
    this.modalElement.on('hidden.bs.modal', this.emitSwitchEvent.bind(event, options));
    // Begin hiding modal
    this.modalElement.modal('hide');
  }

  /**
   * Register general 'hide' event handler. When the user switches the model, this handler is replaced with one that
   * emits a 'switch' event instead. 
   */
  public mounted(): void {
    this.modalElement.on('hidden.bs.modal', this.emitHideEvent.bind(event));
  }

  /**
   * Report to parent that the modal has finished hiding. The parent component can then clear form states, etc.
   * This event is sent both when the modal is forced to hide (our 'close' button) and in general (escape button)
   */
  private emitHideEvent(options: any): void {
    this.$emit('hide', options);
  }

  /**
   * Report to parent that the hiding portion of the switch action has completed. The parent component can then
   * change slot contents before showing the modal again, so that the modal only changes when hidden. This provides
   * behaviour similar to a carousel.
   */
  private emitSwitchEvent(options: any): void {
    this.$emit('switch', options);
    // Remove 'switch' event handler
    this.modalElement.off('hidden.bs.modal');
    // Register general 'hide' event handler
    this.modalElement.on('hidden.bs.modal', this.emitHideEvent.bind(event, options));
  }
}
</script>
