export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "allocation_controls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Controls"])},
        "starting_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting allocation 0%"])},
        "run_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run Allocation Recommendation"])},
        "re_run_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-run Allocation Recommendation"])},
        "allocation_report_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating Allocation Report"])},
        "allocation_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Report"])},
        "override_exclusion_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Override Exclusion Filters"])},
        "discontinue_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue Allocation"])},
        "discontinue_all_offers_in_this_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue all offers in this allocation"])},
        "no_exclusion_rules_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no exclusion rules available."])},
        "confirm_expedited_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Expedited Allocation Recommendation"])},
        "run_expedited_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run Expedited Allocation Recommendation"])},
        "allocation_service_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot run allocation service"])},
        "allocation_report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error generating the Allocation report"])},
        "discontinue_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to discontinue this allocation. All offers will also be discontinued.  Are you sure?"])},
        "exclusion_rules_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you confirm that Exclusion Filters will be deactivated for this Allocation Recommendation?"])},
        "no_exclusion_rules_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No exclusion filter was deactivated. Are you sure you want to proceed?"])},
        "hpv_surface_ag_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation will not return recipients because of a positive Hepatitis B Surface Antigen."])},
        "hla_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning: HLA Results are missing or incomplete. Do you want to run an Expedited Allocation Recommendation?"])},
        "vca_hla_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA Results are missing or incomplete.  This allocation is not allowed."])},
        "vca_hla_error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
        "rerun_allocation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning. A new Allocation Recommendation will replace a previous Recommendation and re-start the eOffer process for this organ. Do you wish to continue?"])},
        "confirm_ctr_connection_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure to Download CTD Number"])},
        "donor_serology_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Warning: Missing or undetermined Virology marker results for ", _interpolate(_named("markers")), ". Do you want to run an Expedited Allocation Recommendation?"])},
        "ctr_connection_error": {
          "national_donor_id_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The National Donor ID is not present on the donor profile and will be run without HSP or HSH recipients from CTR.  Do you wish to proceed?"])}
        },
        "run_expedited_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to run an Expedited Allocation Recommendation?"])},
        "add_recipient_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Recipient to Allocation"])}
      },
      "fr": {
        "allocation_controls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôles d’attribution"])},
        "starting_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commençant l’attribution 0%"])},
        "run_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exécuter la prescription d’attribution"])},
        "re_run_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exécuter à nouveau les prescriptions d’attribution"])},
        "allocation_report_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération du rapport d'attribution"])},
        "allocation_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport d'attribution"])},
        "override_exclusion_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer les filtres d’exclusion"])},
        "discontinue_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêter l'attribution"])},
        "discontinue_all_offers_in_this_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêter tous les offres en cet attribution"])},
        "no_exclusion_rules_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune règle d’exclusion disponible"])},
        "confirm_expedited_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la prescription d’attribution accélérée"])},
        "run_expedited_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exécuter la prescription d’attribution accélérée"])},
        "allocation_service_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de lancer le service d’attribution"])},
        "allocation_report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la génération du rapport d'attribution"])},
        "discontinue_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point d'Arrêter cette attribution. Toutes les offres seront également supprimées. Vous êtes sûr ?"])},
        "exclusion_rules_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez-vous la désactivation des filtres d’exclusion pour cette prescription d’attribution ?"])},
        "no_exclusion_rules_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun filtre d'exclusion n'a été désactivé. Êtes-vous sûr de vouloir continuer ?"])},
        "hpv_surface_ag_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'allocation ne renverra pas les destinataires en raison d'un Hepatitis B Surface Antigen."])},
        "hla_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement : Les résultats HLA sont manquants ou incomplets Voulez-vous lancer une prescription d’attribution accélérée"])},
        "vca_hla_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les résultats HLA sont manquants ou incomplets. Cette attribution n'est pas autorisée."])},
        "vca_hla_error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement"])},
        "rerun_allocation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention. Une nouvelle recommandation d'attribution remplacera une recommandation précédente et relancera le processus d'eOffer pour cet organe. Souhaitez-vous continuer?"])},
        "confirm_ctr_connection_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec du téléchargement du numéro CTD"])},
        "donor_serology_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Attention : Les résultats du marqueur virologique pour ", _interpolate(_named("markers")), " sont manquants ou indéterminés. Voulez-vous lancer une prescription d’attribution accélérée."])},
        "ctr_connection_error": {
          "national_donor_id_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'identifiant national du donneur n'est pas présent sur le profil du donneur et sera exécuté sans les destinataires HSP ou HSH du CTR. Voulez-vous continuer?"])}
        },
        "run_expedited_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous exécuter une prescription d’attribution accélérée?"])},
        "add_recipient_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un patient à l'attribution"])}
      }
    }
  })
}
