<template>
  <sub-section subSectionId="change-status-report">
    <template v-slot:contents v-if="editState">
      <div class="card tip-card">
        <div class="card-header">
          <div class="media align-items-center">
            <div class="media-left">
              <font-awesome-icon :icon="['far', 'file-chart-line']" fixed-width />
            </div>
            <div class="media-body flex-row align-items-center">
              <h3 class="tip-report-name">
                {{$t('change_status_report')}}
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <validation-observer id="change-status-report-filters" ref="validations" autocomplete="off" tag="form" v-slot="{ handleSubmit }">
            <div>
              <div class="row">
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-3">
                  <date-input
                    inputId="change-status-report-start-date"
                    :name="$t('start_date')"
                    v-model="editState.startDate"
                  />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-3">
                  <date-input
                    inputId="change-status-report-end-date"
                    :name="$t('end_date')"
                    v-model="editState.endDate"
                  />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-3">
                  <select-input
                    selectId="changeStatusReport-hospital"
                    :name="$t('hospital')"
                    v-model="editState.hospital"
                    :options="filteredHospitalsByUser"
                    :multiple="true"
                    :placeholder="allOptionsPlaceholderValue(editState.hospital)"
                  />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-3">
                  <select-input
                    selectId="changeStatusReport-organ_type"
                    name="Organ Type"
                    v-model="editState.organCode"
                    :options="organOptions"
                    :multiple="true"
                    :placeholder="allOptionsPlaceholderValue(editState.organCode)"
                  />
                </div>
              </div>
              <save-toolbar
                  class="form-inline col-sm-12 action-row"
                  buttonClass="btn btn-success"
                  ref="generateReport"
                  :savingText="$t('saving_text')"
                  :successText="$t('success_text')"
                  :defaultErrorText="$t('default_error_text')"
                  @save="handleSubmit(generateReport)"
                >
                <template v-slot:label>
                  <font-awesome-icon :icon="['far', 'file-chart-line']" class="mr-2" /> Generate Change of Status Report
                </template>
              </save-toolbar>
            </div>
          </validation-observer>
        </div>
      </div>
    </template>
  </sub-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/ChangeStatusReport.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import { Component, Vue } from 'vue-facing-decorator';
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { GenericCodeValue } from '@/store/types';
import { SaveResult } from '@/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import DateInput from "@/components/shared/DateInput.vue";
import SubSection from '@/components/shared/SubSection.vue';
import { ChangeStatusReportState } from '@/store/reports/types';
import { Organ, OrganCodeValue } from '@/store/lookups/types';
import { Hospital } from '@/store/hospitals/types';
import { User } from '@/store/users/types';

@Component({
  components: {
    SaveToolbar,
    SelectInput,
    DateInput,
    SubSection
  }
})

export default class ChangeStatusReport extends mixins(DateUtilsMixin, ValidationUtilsMixin) {
  // State
  @State(state => state.reports.ChangeStatusReportState) editState!: ChangeStatusReportState;
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.hospitals.all) hospitals!: Hospital[];
  @State(state => state.users.user) user!: User;

  @Getter('activeOntarioTransplantOopHospitalOptions', { namespace: 'hospitals' }) hospitalOptions!: GenericCodeValue[];
  @Getter('allOptionsPlaceholderValue', { namespace: 'reports' }) allOptionsPlaceholderValue!: (state: any) => string;

  get defaultDate(): string|undefined|null {
    return this.currentDateUi();
  }

  public mounted(): void {
    this.$store.dispatch('hospitals/load').then(() => {
      this.$store.commit('reports/setChangeStatusReport', {
        organCode: null,
        hospital: null,
        startDate: null,
        endDate: this.defaultDate
      });
    });
  }

  public allOptionsPlaceholderText(state: any) {
    return this.allOptionsPlaceholderValue(state);
  }

  // pass lookup options to get all options code
  public defaultAllOptions(options: any) {
    return options.map((option: any) => {
      return option.code;
    });
  }

  public buildReportQuery(): ChangeStatusReportState {
    if (!this.editState) {
      return {};
    }
    const organ_codes = (this.editState.organCode || []).length === 0 ? this.defaultAllOptions(this.organOptions) : this.editState.organCode;
    const organ_options = (organ_codes || []).map((organ_code: number) => organ_code.toString());

    const result = {
      organ_type: organ_options,
      hospital: (this.editState.hospital || []).length === 0 ? this.defaultAllOptions(this.filteredHospitalsByUser) : this.editState.hospital,
      start_date: this.editState.startDate,
      end_date: this.editState.endDate
    };
    return result;
  }

   /**
   * Returns an array of options for organs
   *
   * @returns {Organ[]} organ options
   */
  get organOptions(): { code: number; value: string }[] {
    const filterBy: { [key: string]: any } = this.user.hospital_organ_codes || {};
    const userOrganCodes = Object.keys(filterBy).map((hospital: string) => filterBy[hospital]).flat();
    const nonClusteredOrgans = this.organLookup.filter((organ: Organ) => {
      return organ.type != 'cluster' && organ.code != OrganCodeValue.VesselsForBanking;
    });

    if(userOrganCodes.length === 0) {
      return nonClusteredOrgans;
    }

    return nonClusteredOrgans.filter((organ) => userOrganCodes.includes(organ.code));
  }

  /**
   * If user.hospital_organ_codes is empty then returns all hospitals from filteredHospitals()
   * otherwise filters the hospitals based on the hospital_id keys in user.hospital_organ_codes
   *
   * @returns {Organ[]} filtered list of ontario transplant programs and oop transplant programs by user
   */
  get filteredHospitalsByUser(): GenericCodeValue[]|undefined {
    const hospitals = this.hospitalOptions || [];
    const filterBy = this.user.hospital_organ_codes || {};
    
    if (Object.keys(filterBy).length === 0 || this.user.all_hospitals) {
      // if there are no user hospital organ codes then return all hospitals
      return hospitals;
    } else {
      // get all hospital keys from user.hospital_organ_codes and filter hospitals by the hospital_id key
      return hospitals.filter((hospital: any) => {
        return Object.keys(filterBy).includes(hospital.code);
      });
    }
  }

  public generateReport(): void {
    this.clear();
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    saveToolbar.startSaving();

    // Submit query
    this.$store.dispatch('reports/printChangeStatusReport', this.buildReportQuery()).then((result: SaveResult) => {
      // Get the filename
      const fileName = result.responseData.report;

      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
      }
      saveToolbar.stopSaving(result);

    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.handleErrors(error);
      saveToolbar.stopSaving(error);
    });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'start_date': 'change-status-report-start-date',
    'end_date': 'change-status-report-end-date',
    'organ_type': 'changeStatusReport-organ_type',
    'hospital': 'changeStatusReport-hospital'
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult): void {
    const idLookup = this.idLookup;

    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);
  }

  // Emit event to parent so it can clear validations
  private clear() {
    this.$emit('clear');
  }
}
</script>
