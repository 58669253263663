import { SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APISaveJourneyResponse } from '@/types';
import { UIRecipient } from '@/UIModels/recipient';
import { ObjectId } from '@/store/types';
import { UIJourney } from '../journey';
import { useCurrentPageStore } from '@/stores/currentPage';
import { APIPancreasWholeSpecificDetails } from '@/APIModels/journey/types';

export class UIPancreasWholeSpecificDetails {
  private loaded: boolean;

  public apiSource: APIPancreasWholeSpecificDetails|null = null;

  public comments: string|null = null;
  public vesselsRequired: boolean|null;

  // Group Class constructer
  public constructor() {
    this.loaded = false;
    this.vesselsRequired = false;
  }

  // Define empty/default/new UI model structure
  public static buildNew() {
    const uiModel = new UIPancreasWholeSpecificDetails();

    return uiModel;
  }

  // Class constructor using api group as base
  public static buildFromAPIPancreasWholeSpecificDetails(apiPancreasWholeSpecificDetails: APIPancreasWholeSpecificDetails|null) {
    if (!apiPancreasWholeSpecificDetails) return UIPancreasWholeSpecificDetails.buildNew();

    const uiModel = new UIPancreasWholeSpecificDetails;

    uiModel.apiSource = apiPancreasWholeSpecificDetails;
    uiModel.comments = apiPancreasWholeSpecificDetails?.organ_specific_details?.general_comments || null;
    uiModel.vesselsRequired = apiPancreasWholeSpecificDetails?.organ_specific_details?.vessels_required || null;

    return uiModel;
  }

  // Build a copy of specific details for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModel() {
    const apiSource = this.apiSource as APIPancreasWholeSpecificDetails;
    if (!apiSource) return UIPancreasWholeSpecificDetails.buildNew();

    return UIPancreasWholeSpecificDetails.buildFromAPIPancreasWholeSpecificDetails(apiSource);
  }

  // Save edit state to the backend
  public save(opts: { recipient: string|null, journey: string|null }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient;
      const journeyId = opts.journey;
      if (!recipientId) reject((new UIError('organ_specific_details')));

      const ep = APIRoute(EP.recipients.journeys.update, [[':recipientId', recipientId as string], [':journeyId', journeyId as string]]);
      const payload = {
        journey: this.extractPatch()
      };
      axios.patch(ep, payload).then((response: APISaveJourneyResponse) => {
        if (response.data.errors) {
          reject((new UIError('organ_specific_details', response)).errorResult);
        } else {
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('organ_specific_details', errorResponse)).errorResult);
      });
    });
  }

  // Generate request payload parameters to provide to API as part of Create or Update activity
  private extractPatch(): APIPancreasWholeSpecificDetails {
    return {
      organ_specific_details: {
        vessels_required: this.vesselsRequired,
        general_comments: this.comments
      }  
    };
  }
}
