<template>
  <nav id="side-nav" :class="{ active: isVisible, footerIsVisible: footerIsVisible }" class="page-nav">
    <slot name="side-nav-content" />
  </nav>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component
export default class BaseSideNav extends Vue {
    @Prop({ default: 380 }) scrollThresholdMobile!: number;
    @Prop({ default: 150 }) scrollThresholdDesktop!: number;

    private isVisible = false; 
    private footerIsVisible = false;
    
    private mounted(): void {
    window.addEventListener('scroll', this.handleScroll);
  }

  // Event listeners
  private handleScroll(): void {
    const element = document.querySelector('#footer');
	  if (element !== null) {
      const position = element.getBoundingClientRect();
      if(position.top < window.innerHeight && position.bottom >= 0) {
        this.footerIsVisible = true;
      }
      else {
        this.footerIsVisible = false;
      }
    }

    const lastPosition = window.pageYOffset;
    if ((window.innerWidth >= 992) && (lastPosition > this.scrollThresholdDesktop)) {
      this.isVisible = true;
    } else if ((window.innerWidth <= 992) && (lastPosition > this.scrollThresholdMobile)) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  }
}
</script>
