export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "wait_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait Time"])},
        "hsh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSH"])},
        "hsh_yesno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSH: Y/N"])},
        "yes_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y/N"])},
        "on_wait_time_no_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ON Wait Time"])},
        "on_wait_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ON Wait Time (Days)"])},
        "on_wait_time_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ON Wait Time Date"])},
        "on_wait_time_hover_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the Ontario Days at Current Medical Status calculation, the recipient is eligible to use the days earned at an equal or higher medical status to calculate days accrued for allocation"])},
        "initial_list_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial List Date"])},
        "ctr_wait_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Wait Time (Days)"])},
        "ctr_wait_time_hover_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the CTR Days at Current Medical Status calculation, if a patient advances to a higher medical status (i.e. from status 2 to 3), the wait time for the higher medical status will start at zero. If a patient has accumulated wait time at a lower status and their medical status is downgraded (i.e. from status 3 to 2), the wait time from the higher status is added to the lower medical status"])},
        "ctr_wait_time_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Wait Time Date"])},
        "ctr_wait_time_date_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Wait Time Date Updated"])},
        "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])}
      },
      "fr": {
        "wait_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait Time"])},
        "hsh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSH"])},
        "yes_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y/N"])},
        "on_wait_time_no_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ON Wait Time"])},
        "on_wait_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ON Wait Time (Days)"])},
        "on_wait_time_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ON Wait Time Date"])},
        "on_wait_time_hover_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the Ontario Days at Current Medical Status calculation, the recipient is eligible to use the days earned at an equal or higher medical status to calculate days accrued for allocation"])},
        "initial_list_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial List Date"])},
        "ctr_wait_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Wait Time (Days)"])},
        "ctr_wait_time_hover_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the CTR Days at Current Medical Status calculation, if a patient advances to a higher medical status (i.e. from status 2 to 3), the wait time for the higher medical status will start at zero. If a patient has accumulated wait time at a lower status and their medical status is downgraded (i.e. from status 3 to 2), the wait time from the higher status is added to the lower medical status"])},
        "ctr_wait_time_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Wait Time Date"])},
        "ctr_wait_time_date_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Wait Time Date Updated"])},
        "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours"])}
      }
    }
  })
}
