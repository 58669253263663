import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "nav-block-wrap" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "nav-link" }
const _hoisted_4 = {
  key: 0,
  class: "nav flex-column"
}
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", {
      class: _normalizeClass(["nav nav-block flex-column", { active: _ctx.active }])
    }, [
      _createElementVNode("li", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', (_ctx.show ? 'chevron-down' : 'chevron-right')],
            class: "nav-caret",
            "fixed-width": "",
            onClick: _withModifiers(_ctx.toggleNav, ["prevent"])
          }, null, 8, ["icon", "onClick"]),
          _createVNode(_component_router_link, {
            class: "no-scroll open",
            style: {"color":"#2e2e2e !important"},
            to: { name: 'recipient-hla', params: { id: _ctx.recipient.client_id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('hla_information')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ]),
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
            }, [
              _createElementVNode("a", {
                class: "nav-link",
                href: _ctx.getUrl('hla-antibodies')
              }, _toDisplayString(_ctx.$t('antibodies')), 9, _hoisted_5)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}