import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "form-group col-sm-12" }
const _hoisted_3 = { class: "p-label" }
const _hoisted_4 = {
  key: 0,
  class: "simple-list"
}
const _hoisted_5 = {
  key: 0,
  disabled: true
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "form-group col-md-4 col-lg-4 col-xl-2" }
const _hoisted_8 = { class: "form-group col-md-4 col-lg-4 col-xl-2" }
const _hoisted_9 = { class: "form-group col-md-4 col-lg-4 col-xl-2" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = {
  key: 0,
  class: "modal-footer-body"
}
const _hoisted_13 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_other_input = _resolveComponent("select-other-input")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, {
    ref: "confirmRecipientConsentValidations",
    autocomplete: "off",
    tag: "form"
  }, {
    default: _withCtx(({ handleSubmit }) => [
      _createVNode(_component_modal_section, {
        modalId: "confirm-recipient-consent",
        ref: "confirmRecipientConsent",
        class: "modal-sticky-header",
        centered: true,
        size: "xl"
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('confirm_recipient_consent')), 1)
        ]),
        body: _withCtx(() => [
          (!_ctx.editState)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_sub_section, {
                  title: _ctx.$t("exceptional_distribution"),
                  "sub-section-id": "confirm-recipient-consent-exceptional-distribution"
                }, {
                  contents: _withCtx(() => [
                    _createElementVNode("fieldset", null, [
                      _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('reasons_exceptional_distribution')), 1),
                          (_ctx.getExdReasonList)
                            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getExdReasonList, (reason, index) => {
                                  return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(reason), 1))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_sub_section, {
                  title: _ctx.$t('confirmation_details'),
                  "sub-section-id": "confirm-recipient-consent-confirmation-details",
                  ref: "confirmRecipientConsentConfirmationDetails"
                }, {
                  contents: _withCtx(() => [
                    (_ctx.editState)
                      ? (_openBlock(), _createElementBlock("fieldset", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                              _createVNode(_component_text_input, {
                                name: _ctx.$t('physician_name'),
                                "input-id": "confirm-recipient-consent-physician-name",
                                modelValue: _ctx.editState.detailRow.physicianName,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.detailRow.physicianName) = $event)),
                                readonly: true
                              }, null, 8, ["name", "modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_text_input, {
                                name: _ctx.$t('recipient_name'),
                                "input-id": "confirm-recipient-consent-recipient-name",
                                modelValue: _ctx.editState.detailRow.recipientName,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.detailRow.recipientName) = $event)),
                                readonly: true
                              }, null, 8, ["name", "modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_text_input, {
                                name: _ctx.$t('exd_organ'),
                                "input-id": "confirm-recipient-consent-exd-organ",
                                modelValue: _ctx.editState.detailRow.organ,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.detailRow.organ) = $event)),
                                readonly: true
                              }, null, 8, ["name", "modelValue"])
                            ]),
                            _createVNode(_component_select_other_input, {
                              name: _ctx.$t('acceptance_reason'),
                              "select-id": "confirm-recipient-consent-justification-reason",
                              modelValue: _ctx.editState.detailEdit.justificationReason,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.detailEdit.justificationReason) = $event)),
                              options: _ctx.exdAcceptanceReasons,
                              numeric: true,
                              otherTitle: _ctx.$t('other_acceptance_reason'),
                              colStyling: "form-group selectWithOther col-md-8 col-lg-8 col-xl-6"
                            }, {
                              other: _withCtx(() => [
                                _createVNode(_component_text_input, {
                                  name: _ctx.$t('other_acceptance_reason'),
                                  "input-id": "confirm-recipient-consent-other-justification-reason",
                                  modelValue: _ctx.editState.detailEdit.otherJustificationReason,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.detailEdit.otherJustificationReason) = $event))
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              _: 1
                            }, 8, ["name", "modelValue", "options", "otherTitle"])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_sub_section, {
                  title: _ctx.$t('confirmation_text'),
                  "sub-section-id": "confirm-recipient-consent-confirmation-text"
                }, {
                  contents: _withCtx(() => [
                    _createElementVNode("fieldset", null, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('text')), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["title"]),
                (_ctx.editState.serverErrors && _ctx.editState.serverErrors.length > 0)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.editState.serverErrors, (serverError, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `server-error-${index}`,
                        class: "alert alert-danger"
                      }, _toDisplayString(serverError), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ], 64))
        ]),
        footer: _withCtx(() => [
          (_ctx.editState)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("button", _hoisted_13, _toDisplayString(_ctx.$t('cancel')), 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-success",
                  onClick: ($event: any) => (handleSubmit(_ctx.confirmAcceptance))
                }, _toDisplayString(_ctx.$t('confirmation_organ_acceptance')), 9, _hoisted_14)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1536)
    ]),
    _: 1
  }, 512))
}