<template>
  <div :class="getFormControlStyling">
    <div class="row">

      <div :class="getInputStyling">
        <select-input
          v-model="selectLocalValue"
          :name="name"
          :select-id="selectId"
          :validation-id="validationId"
          :options="options"
          :label="label"
          :null-text="nullText"
          :text-key="textKey"
          :value-key="valueKey"
          :undefined-text="undefinedText"
          :disabled="disabled"
          :readonly="readonly"
          :hide-label="hideLabel"
          :rules="rules"
          :ruleKey="ruleKey"
          :crossValues="crossValues"
          :numeric="numeric"
          v-on="selectEvents()"
        />
      </div>
      <template v-if="enableOther">
        <div v-if="!showOther && otherTitle.length > 0" class="col-6">
          <text-input
            inputId="id"
            :label="otherTitle"
            :name="otherTitle"
            value=""
            disabled="true"
            required="true"
            />
        </div>
        <div v-else-if="showOther" :class="stackInputs ? 'col-12' : 'col-6'">
          <slot name="other" />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import TextInput from '@/components/shared/TextInput.vue';

@Component({
  components: {
    SelectInput,
    TextInput
  },
  emits: [
    'change',
    'update:modelValue',
  ],
})
export default class SelectOtherInput extends Vue {
  // NOTES:
  // The show other input needs to be displayed on the screen but be disabled, 
  // however due to validation errors appearing we need to show a fake text-input
  // instead as this will prevent the error from being displayed to the user.
  // There are certain locations where the showOther fake text-input should not show, 
  // e.g insurance details so the otherTitle is used as a variable to control this.

  // V-model
  @Prop() modelValue!: string|string[];

  // Standard properties
  @Prop({ required: true }) selectId!: string; // Set the ID
  @Prop({ required: true }) name!: string; // Set the label and name property
  @Prop({ required: true }) options!: any[]; // Enumerable data for building the options

  // Optional properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Optional label value
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: 'Not Applicable' }) nullText!: string; // Label used for null value
  @Prop({ default: 'value' }) textKey!: string; // Key for text displayed as option label
  @Prop({ default: 'code' }) valueKey!: string; // Key for code value associated with option
  @Prop({ default: 'Select...' }) undefinedText!: string; // Label used for unselected/undefined state
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers
  @Prop({ default: true }) enableOther!: boolean; // Show other input, by default true

  // rules
  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: '' }) ruleKey!: string;
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix
  @Prop({ default: false }) numeric!: boolean; // If true handle option codes internally as strings, but emit numbers

  // styling
  @Prop({ default: false}) stackInputs!: boolean // puts the inputs on 2 lines when its in a table 
  @Prop({ default: '' }) colStyling!: string; // styling to past the width of the component if the showOther input is displayed
  @Prop({ default: '' }) reduceColumnWidth!: string; // styling to past the width if the showOther input is hidden
  @Prop({ default: '' }) otherTitle!: string; // class to use when passing the title of the 'show Other' label, 
                                              // also will control if the fake text-input should show or not


  // single-select local storage
  selectLocalValue: any = this.defaultValue;

  // return default value
  get defaultValue(): any {
    return '';
  }

  /**
   * Applying internal column width of component
   */
  get getInputStyling(): string {
    // if other field not enabled
    if (!this.enableOther) return 'col-12';

    // if input is stacked
    if (this.stackInputs) return 'col-12';

    // if input is showother input and displays all the time or if the fake text-input will display
    if (this.otherTitle.length > 0) return 'col-6';

    // if the fake-input should not show full width
    if (this.otherTitle.length == 0 && !this.showOther) return 'col-12';

    // default
    return 'col-6';
  }

  /**
   * Applying form-control parent column width of component
   */
  get getFormControlStyling(): string {
    // if other field not enabled
    if (!this.enableOther) return this.reduceColumnWidth;

    // Apply styling that will allow the fake text-input to display
    if (this.otherTitle.length > 0 && !this.showOther) return this.colStyling;

    // styling when the show other is hidden
    if (this.otherTitle.length == 0 && !this.showOther ) return this.reduceColumnWidth;

    // default
    return this.colStyling;
  }

  /**
   * Gets the full option document corresponding to the selected code value
   */
  get selectedOption(): any|undefined {
    if (!this.options)  {
      return undefined;
    }
    return this.options.find((option: any) => {
      return option.code == this.modelValue;
    });
  }

  /**
   * Gets a boolean representation of whether or not to show the Other input slot
   * 
   * @returns {boolean} true if the Other inout slot should be shown, false otherwise
   */
  get showOther(): boolean {
    if (!this.selectedOption) return false;
    return this.selectedOption.other_selected || false;
  }

  // Sanitize value to string for comparison in select options
  private sanitizeSingleValue(value: string[]|string): string {
    if (!value) return '';

    const firstValue = Array.isArray(value) ? value[0] : value;
    return firstValue?.toString() || '';
  }

  public mounted(): void {
    // catch zero value, interprerted as null
    if (this.sanitizeSingleValue(this.modelValue) === '0') {
      this.selectLocalValue = 0;
    } else {
      this.selectLocalValue = this.modelValue && this.getLength(this.modelValue) > 0 ? this.modelValue : this.defaultValue;
    }
  }

  public selectEvents(): any {
    const _vm = this as SelectOtherInput;
    return Object.assign({},
      // parent listeners
      this.$attrs.listeners,
      {
        // custom listeners
        change(value: any) {
          // Emit updated value for v-model
          const selectLocalValue = value != null ? value : null;
          const newValue = selectLocalValue == null || selectLocalValue === '' ? null : (_vm.numeric ? Number(selectLocalValue) : selectLocalValue);
          _vm.$emit('update:modelValue', newValue);
          _vm.$emit('change', newValue);
        }
      }
    );
  }

  private getLength(value: any): any {
    return this.modelValue.toString().length;
  }

  @Watch('modelValue')
  public onValueChange() {
    this.selectLocalValue = (this.modelValue && this.getLength(this.modelValue) > 0) || this.sanitizeSingleValue(this.modelValue) === '0' ? this.modelValue : this.defaultValue;
  }
}
</script>
