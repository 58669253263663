export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "recent_living_donor_registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Living Donor Registrations"])},
        "no_living_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Living Donors"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse Panel"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Options"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Style"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this panel"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor ID"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Date"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize Panel"])},
        "link_to_living_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Living Donor Page"])}
      },
      "fr": {
        "recent_living_donor_registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscriptions des nouveaux donneurs vivants"])},
        "no_living_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun donneur viviant"])},
        "collapse_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau rabattu"])},
        "panel_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options du panneau"])},
        "panel_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style du panneau"])},
        "show_this_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher ce panneau"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Donneur"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'inscription"])},
        "customize_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalizer le panneau"])},
        "link_to_living_donor_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la page des donneurs vivants"])}
      }
    }
  })
}
