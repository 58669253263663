<template>
  <sub-section subSectionId="allocation-report">
    <template v-slot:contents v-if="editState">
      <div class="card tip-card">
        <div class="card-header">
          <div class="media align-items-center">
            <div class="media-left">
              <font-awesome-icon :icon="['far', 'file-chart-line']" fixed-width />
            </div>
            <div class="media-body flex-row align-items-center">
              <h3 class="tip-report-name">
                Allocation Report
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <validation-observer id="allocation-report-filters" ref="validations" autocomplete="off" tag="form" v-slot="{ handleSubmit }">
            <div>
              <div class="standard-form-group sorting-column">
                <text-input
                  input-id="allocationReport-allocation-client-id"
                  name="Allocation ID"
                  v-model="editState.allocationClientId"
                  rules="required"
                />
              </div>
              <div class="standard-form-group sorting-column">
                <select-input
                  selectId="allocationReport-hospital"
                  :name="$t('hospital')"
                  :rules="!authorizedForAllHospitals ? 'required' : ''"
                  v-model="editState.hospital"
                  :options="filteredHospitalsByUser"
                  :undefinedText="defaultHospitalPlaceholder"
                />
              </div>
              <save-toolbar
                class="form-inline col-sm-12 action-row"
                buttonClass="btn btn-success"
                ref="generateReport"
                savingText="Generating"
                successText="Report generated"
                defaultErrorText="There was a problem generating the report"
                @save="handleSubmit(generateReport)"
              >
                <template v-slot:label>
                  <font-awesome-icon :icon="['far', 'file-chart-line']" class="mr-2" /> Generate Allocation Report
                </template>
              </save-toolbar>
            </div>
          </validation-observer>
        </div>
      </div>
    </template>
  </sub-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/AllocationReport.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { mixins } from "vue-facing-decorator";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import TextInput from '@/components/shared/TextInput.vue';
import { SaveResult } from '@/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { User } from '@/store/users/types';
import { AllocationReportState, AllocationReportQuery } from '@/store/reports/types';
import { allocationReports } from '@/store/reports/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { Hospital, HospitalOption, ACTIVE_REGION_TRANSPLANT_PROGRAM, HospitalOrgansTransplanted } from '@/store/hospitals/types';

@Component({
  components: {
    SaveToolbar,
    SelectInput,
    TextInput,
    SubSection
  }
})

export default class AllocationReport extends mixins(ValidationUtilsMixin) {
  // props
  @Prop({ default: null }) report!: string; // report code

  // State
  @State(state => state.reports.AllocationReportState) editState!: AllocationReportState;

  @State(state => state.hospitals.all) hospitals!: Hospital[];
  @State(state => state.users.user) user!: User;

  // Getters
  @Getter('hospitalOptionsOntarioTransplant', { namespace: 'hospitals' }) hospitalOptionsOntarioTransplant!: HospitalOption[];
  @Getter('allOptionsPlaceholderValue', { namespace: 'reports' }) allOptionsPlaceholderValue!: (state: any, disabled?: boolean) => string;
  @Getter('filterHospitalOptionsByOrganCodes', { namespace: 'hospitals' }) filterHospitalOptionsByOrganCodes!: (options: HospitalOption[], organCode: string) => HospitalOption[];


  // Is user authorized to access data for all transplant program hospitals?
  get authorizedForAllHospitals(): boolean {
    return this.user?.all_hospitals || false;
  }

  /**
   * returns default placeholder for hospitals
   *
   * @returns {string} placeholder for hospitals select
   */
  get defaultHospitalPlaceholder(): string {
    // By default we show a generic 'Select a Hospital' (e.g. for transplant program user)
    let result = this.$t('hospital_placeholder').toString();

    // If is has flag indicating authorization for all hospitals, we show 'All' instead
    if (this.authorizedForAllHospitals) {
      result = this.$t('all').toString();
    }
    return result;
  }

  // Which Ontario Hospitals handle Transplants for the Organ associated with the Allocation Report?
  get hospitalsFilterByReportOrgan(): HospitalOption[] {
    const hospitals = this.hospitalOptionsOntarioTransplant || [];

    // Filter hospitals options based on this report's organ code
    // NOTE: organ codes in hospitals collection are strings
    const reportOrganCode: string = this.organCode.toString();
    const filteredByOrgan = this.filterHospitalOptionsByOrganCodes(hospitals, reportOrganCode);
    return filteredByOrgan;
  }

  /**
   * If user.hospital_organ_codes is empty then returns all hospitals from filteredHospitals()
   * otherwise filters the hospitals based on the hospital_id keys in user.hospital_organ_codes
   *
   * @returns {Organ[]} filtered list of ontario transplant programs by user
   */
  get filteredHospitalsByUser(): HospitalOption[]|undefined {
    const hospitals = this.hospitalsFilterByReportOrgan || [];

    const filterBy = this.user.hospital_organ_codes || {};

    if (this.user.all_hospitals) {
      // if the user has access to all hospitals return them.
      return hospitals;
    } else {
      // get all hospital keys from user.hospital_organ_codes and filter hospitals by the hospital_id key
      return hospitals.filter((hospital: any) => {
        const result = Object.keys(filterBy).includes(hospital.code);
        return result;
      });
    }
  }

  get organCode(): number {
    const { report }: any = this.$props;

    const allocations: { [key: string]: any } = [
      { code: allocationReports.AllocationHeart, organ_code: OrganCodeValue.Heart }, // Bug 11677
      { code: allocationReports.AllocationKidneyPancreas, organ_code: OrganCodeValue.Kidney }, // Bug 11677
      { code: allocationReports.AllocationLiver, organ_code: OrganCodeValue.Liver }, // Bug 11677
      { code: allocationReports.AllocationLung, organ_code: OrganCodeValue.Lung }, // Bug 11677
      { code: allocationReports.AllocationPancreasIslets, organ_code: OrganCodeValue.PancreasIslets }, // Bug 11677
      { code: allocationReports.AllocationPancreasWhole, organ_code: OrganCodeValue.PancreasWhole },
      { code: allocationReports.AllocationSmallBowel, organ_code: OrganCodeValue.SmallBowel } // Bug 11677
    ];

    return allocations.find((reports: { code: string, organ_code: number }) => reports.code === report)?.organ_code;
  }

  // Load the Ontario hospitals with Transplant Programs
  public mounted(): void {
    this.$store.dispatch('hospitals/load', ACTIVE_REGION_TRANSPLANT_PROGRAM).then(() => {
      this.$store.commit('reports/setAllocationReportState', this.buildAllocationReportState());
    });
  }

  public buildAllocationReportState(): AllocationReportState {
    const result: AllocationReportState = {
      allocationClientId: ''
    };
    return result;
  }

  // pass lookup options to get all options code
  public defaultAllOptions(options: any) {
    return options.map((option: any) => {
      return option.code;
    });
  }

  public buildAllocationReportQuery(): AllocationReportQuery {
    if (!this.editState) {
      return {};
    }

    // We send only the values that are visible using the field parameter show getters
    // If we don't show the field then send undefined
    // If the field is visible but no value is selected then send all possible values otherwise send the values in v-model editState
    const result = {
      allocation_client_id: (this.editState.allocationClientId || '').trim(),
      hospital: this.editState.hospital || '',
      organ_code: this.organCode
    };
    return result;
  }

  public generateReport(): void {
    this.clear();
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    saveToolbar.startSaving();
    const allocationReportQuery = this.buildAllocationReportQuery();
    // Submit query
    this.$store.dispatch('reports/printAllocationReport', allocationReportQuery).then((result: SaveResult) => {
      // Get the filename
      const fileName = result.responseData.report;

      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
      }
      saveToolbar.stopSaving(result);

    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.handleErrors(error);
      saveToolbar.stopSaving(error);
    });
  }

    // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult): void {
    const idLookup = this.idLookup;
    
    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);
  }


  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'allocation' : 'allocationReport-allocation-client-id',
    'hospital' : 'allocationReport-hospital'
  }

  // Emit event to parent so it can clear validations
  private clear() {
    this.$emit('clear');
  }
}
</script>
