import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { class: "time-label" }
const _hoisted_4 = { class: "allocation-countdown" }
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = { class: "time-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_count_down_clock = _resolveComponent("count-down-clock")!

  return (_ctx.editState)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("template", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.primary, (row, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `${row._id}-${idx}`
            }, [
              _createElementVNode("div", null, [
                _createElementVNode("div", {
                  class: "clock-display primary-offer-time ml-1",
                  "aria-label": _ctx.$t('primary_offer_time_aria')
                }, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('primary_offer_time')), 1),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.getDateTimeOffered(row)), 1)
                ], 8, _hoisted_2),
                _createElementVNode("div", {
                  class: "clock-display ml-1",
                  "aria-label": _ctx.$t('primary_offer_time_aria')
                }, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('offer_expires_in')), 1),
                  _createVNode(_component_count_down_clock, {
                    id: 'allocation-countdown',
                    start: _ctx.getTimerAttributes(row).start,
                    duration: _ctx.getTimerAttributes(row).duration
                  }, null, 8, ["start", "duration"])
                ], 8, _hoisted_5)
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}