<template>
  <validation-provider 
    ref="provider"
    :name="inputId"
    v-slot="{ errors }">
    <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
      <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
      {{ translateError(errors) }}
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { Getter } from 'vuex-facing-decorator';

// Control to show error message from api without being attached to a UI input

@Component({
  components: {
  },
})
export default class ErrorMessage extends Vue {
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
}
</script>
