import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "row fieldset" }
const _hoisted_3 = { class: "standard-form-group-large" }
const _hoisted_4 = { class: "legend-title mb-3" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "standard-form-group-large" }
const _hoisted_7 = { class: "legend-title mb-3" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "legend-title" }
const _hoisted_10 = { class: "nav action-row cumulative-sera" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "row mb-3" }
const _hoisted_15 = { class: "col-12" }
const _hoisted_16 = { class: "modal-footer-body" }
const _hoisted_17 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_hla_input_group = _resolveComponent("hla-input-group")!
  const _component_hla_input = _resolveComponent("hla-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_hla_antibody_filter_table = _resolveComponent("hla-antibody-filter-table")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "hla-serum",
    "lookups-to-load": _ctx.lookupsToLoad,
    onLoaded: _ctx.loaded,
    "show-actions": true
  }, _createSlots({
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('serum_summary')) + " & " + _toDisplayString(_ctx.$t('review')), 1)
    ]),
    actions: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-sm btn-default",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openExportDataModal && _ctx.openExportDataModal(...args))),
        title: _ctx.$t('serum_summary_export_data_hover')
      }, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['far', 'table'],
          class: "mr-2"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('serum_summary_export_data_button')), 1)
      ], 8, _hoisted_1)
    ]),
    _: 2
  }, [
    (_ctx.editState)
      ? {
          name: "body",
          fn: _withCtx(() => [
            _createVNode(_component_validation_observer, null, {
              default: _withCtx(() => [
                _createVNode(_component_sub_section, {
                  title: _ctx.$t('cumulative_serum_results'),
                  "sub-section-id": "serumsummary-cumulative",
                  class: "cumulative-sera"
                }, {
                  contents: _withCtx(() => [
                    _createElementVNode("fieldset", null, [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("h6", _hoisted_4, _toDisplayString(_ctx.$t('class_1')), 1),
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_hla_input_group, {
                              inputId: "serumsummary-cumulative-class1-unacceptable",
                              name: _ctx.$t('unacceptable'),
                              modelValue: _ctx.editState.cumulativeAntibodies!.class1_unacceptable,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.cumulativeAntibodies!.class1_unacceptable) = $event)),
                              inputClass: "hla-unacceptable",
                              onSelected: _cache[2] || (_cache[2] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                              disabled: true,
                              highlightSelection: true,
                              selectedAntibodies: _ctx.editState.selectedAntibodies,
                              alleleSpecific: _ctx.editState.cumulativeAntibodies!.class1_unacceptable_allele_specific,
                              alphaBeta: _ctx.editState.cumulativeAntibodies!.class1_unacceptable_alpha_beta,
                              "enable-epitopes": true,
                              epitopes: _ctx.editState.cumulativeAntibodies!.epitopes_unacceptable
                            }, null, 8, ["name", "modelValue", "selectedAntibodies", "alleleSpecific", "alphaBeta", "epitopes"]),
                            _createVNode(_component_hla_input_group, {
                              inputId: "serumsummary-cumulative-class1-indeterminate",
                              name: _ctx.$t('indeterminate'),
                              modelValue: _ctx.editState.cumulativeAntibodies!.class1_indeterminate,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.cumulativeAntibodies!.class1_indeterminate) = $event)),
                              inputClass: "hla-indeterminate",
                              onSelected: _cache[4] || (_cache[4] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                              disabled: true,
                              highlightSelection: true,
                              selectedAntibodies: _ctx.editState.selectedAntibodies,
                              alleleSpecific: _ctx.editState.cumulativeAntibodies!.class1_indeterminate_allele_specific,
                              alphaBeta: _ctx.editState.cumulativeAntibodies!.class1_indeterminate_alpha_beta,
                              "enable-epitopes": true,
                              epitopes: _ctx.editState.cumulativeAntibodies!.epitopes_indeterminate
                            }, null, 8, ["name", "modelValue", "selectedAntibodies", "alleleSpecific", "alphaBeta", "epitopes"]),
                            (_ctx.showClass1PossibleAlleleSpecific)
                              ? (_openBlock(), _createBlock(_component_hla_input, {
                                  key: 0,
                                  inputId: "serumsummary-cumulative-class1-possible-allele-specific",
                                  name: _ctx.$t('possible_allele_specific_field'),
                                  modelValue: _ctx.editState.cumulativeAntibodies!.class1_possible_allele_specific,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.cumulativeAntibodies!.class1_possible_allele_specific) = $event)),
                                  inputClass: "hla-possible-allele-specific",
                                  onSelected: _cache[6] || (_cache[6] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  disabled: true,
                                  highlightSelection: true,
                                  selectedAntibodies: _ctx.editState.selectedAntibodies,
                                  calculated: true,
                                  calculatedText: _ctx.$t('legacy_data_indicator'),
                                  calculatedHoverText: _ctx.$t('legacy_data_explanation')
                                }, null, 8, ["name", "modelValue", "selectedAntibodies", "calculatedText", "calculatedHoverText"]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("h6", _hoisted_7, _toDisplayString(_ctx.$t('class_2')), 1),
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_hla_input_group, {
                              inputId: "serumsummary-cumulative-class2-unacceptable",
                              name: _ctx.$t('unacceptable'),
                              modelValue: _ctx.editState.cumulativeAntibodies!.class2_unacceptable,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.cumulativeAntibodies!.class2_unacceptable) = $event)),
                              inputClass: "hla-unacceptable",
                              onSelected: _cache[8] || (_cache[8] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                              disabled: true,
                              highlightSelection: true,
                              selectedAntibodies: _ctx.editState.selectedAntibodies,
                              alleleSpecific: _ctx.editState.cumulativeAntibodies!.class2_unacceptable_allele_specific,
                              alphaBeta: _ctx.editState.cumulativeAntibodies!.class2_unacceptable_alpha_beta
                            }, null, 8, ["name", "modelValue", "selectedAntibodies", "alleleSpecific", "alphaBeta"]),
                            _createVNode(_component_hla_input_group, {
                              inputId: "serumsummary-cumulative-class2-indeterminate",
                              name: _ctx.$t('indeterminate'),
                              modelValue: _ctx.editState.cumulativeAntibodies!.class2_indeterminate,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.cumulativeAntibodies!.class2_indeterminate) = $event)),
                              inputClass: "hla-indeterminate",
                              onSelected: _cache[10] || (_cache[10] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                              disabled: true,
                              highlightSelection: true,
                              selectedAntibodies: _ctx.editState.selectedAntibodies,
                              alleleSpecific: _ctx.editState.cumulativeAntibodies!.class2_indeterminate_allele_specific,
                              alphaBeta: _ctx.editState.cumulativeAntibodies!.class2_indeterminate_alpha_beta
                            }, null, 8, ["name", "modelValue", "selectedAntibodies", "alleleSpecific", "alphaBeta"]),
                            (_ctx.showClass2PossibleAlleleSpecific)
                              ? (_openBlock(), _createBlock(_component_hla_input, {
                                  key: 0,
                                  inputId: "serumsummary-cumulative-class2-possible-allele-specific",
                                  name: _ctx.$t('possible_allele_specific_field'),
                                  modelValue: _ctx.editState.cumulativeAntibodies!.class2_possible_allele_specific,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.cumulativeAntibodies!.class2_possible_allele_specific) = $event)),
                                  inputClass: "hla-possible-allele-specific",
                                  onSelected: _cache[12] || (_cache[12] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  disabled: true,
                                  highlightSelection: true,
                                  selectedAntibodies: _ctx.editState.selectedAntibodies,
                                  calculated: true,
                                  calculatedText: _ctx.$t('legacy_data_indicator'),
                                  calculatedHoverText: _ctx.$t('legacy_data_explanation')
                                }, null, 8, ["name", "modelValue", "selectedAntibodies", "calculatedText", "calculatedHoverText"]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_sub_section, {
                  title: _ctx.$t('entries'),
                  "sub-section-id": "serumsummary-entries"
                }, {
                  contents: _withCtx(() => [
                    _createElementVNode("fieldset", null, [
                      _createElementVNode("h5", _hoisted_9, _toDisplayString(_ctx.$t('selected_antibodies')), 1),
                      _createElementVNode("nav", _hoisted_10, [
                        _createElementVNode("button", {
                          type: "button",
                          class: _normalizeClass(["btn btn-light btn-sm mt-2 mr-2", { disabled: _ctx.zeroAntibodiesSelected }]),
                          onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (_ctx.removeAllSelectedAntibodies()), ["prevent"])),
                          disabled: _ctx.zeroAntibodiesSelected
                        }, _toDisplayString(_ctx.$t('clear_filters')), 11, _hoisted_11),
                        (!_ctx.zeroAntibodiesSelected)
                          ? (_openBlock(), _createBlock(_component_hla_input, {
                              key: 0,
                              modelValue: _ctx.editState.selectedAntibodies,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.selectedAntibodies) = $event)),
                              inputId: "serumsummary-entries-selectedantibodies",
                              inputClass: "hla-cumulative",
                              name: _ctx.$t('selected_antibodies'),
                              onSelected: _cache[15] || (_cache[15] = (selectedAntibody) => _ctx.removeSelectedAntibody(selectedAntibody)),
                              hideLabel: true,
                              disabled: true
                            }, null, 8, ["modelValue", "name"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_select_input, {
                            selectId: "serumsummary-entries-testingmethod",
                            name: _ctx.$t('testing_method'),
                            modelValue: _ctx.editState.testingMethod,
                            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.testingMethod) = $event)),
                            options: _ctx.hlaTestingMethodLookup,
                            "undefined-text": _ctx.$t('all')
                          }, null, 8, ["name", "modelValue", "options", "undefined-text"])
                        ])
                      ]),
                      (_ctx.editState)
                        ? (_openBlock(), _createBlock(_component_hla_antibody_filter_table, {
                            key: 0,
                            id: "serumsummary-entries-table",
                            "table-config": _ctx.hlaSerumSummaryReviewTableConfig,
                            "testing-method": _ctx.editState.testingMethod,
                            "selected-antibodies": _ctx.editState.selectedAntibodies
                          }, null, 8, ["table-config", "testing-method", "selected-antibodies"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_modal_section, {
                  "modal-id": "hla-serum-summary-export-data-popup",
                  ref: "exportDataModel",
                  class: "modal-sticky-header",
                  centered: true
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('serum_summary_export_data_heading')), 1)
                  ]),
                  body: _withCtx(() => [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('serum_summary_export_data_explanation')), 1)
                    ]),
                    _createVNode(_component_sub_section, {
                      "sub-section-id": "hla-serum-summary-export-data-current-table",
                      title: _ctx.$t('serum_summary_export_data_current_heading'),
                      "table-config": _ctx.exportDataCurrentTableConfig,
                      "row-style-class": "no-hover no-pointer",
                      collapsible: true,
                      "default-collapsed": true
                    }, null, 8, ["title", "table-config"]),
                    _createVNode(_component_sub_section, {
                      "sub-section-id": "hla-serum-summary-export-data-cumulative-table",
                      title: _ctx.$t('serum_summary_export_data_cumulative_heading'),
                      "table-config": _ctx.exportDataCumulativeTableConfig,
                      "row-style-class": "no-hover no-pointer",
                      collapsible: true
                    }, null, 8, ["title", "table-config"])
                  ]),
                  footer: _withCtx(() => [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("button", _hoisted_17, _toDisplayString(_ctx.$t('cancel')), 1)
                    ])
                  ]),
                  _: 1
                }, 512)
              ]),
              _: 1
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["lookups-to-load", "onLoaded"]))
}