export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "create_forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Forms"])},
        "organ_packaging_forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Packaging Forms"])},
        "organ_packaging_organ_consents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Consents"])},
        "organ_packaging_ODSIF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODSIF"])},
        "organ_packaging_cooler_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooler Sheet"])},
        "organ_packaging_interior_organ_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interior Organ Labels"])},
        "organ_packaging_exterior_organ_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exterior Organ Labels"])},
        "organ_packaging_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "organ_packaging_intended_research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intended for Research"])},
        "organ_packaging_allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation ID"])},
        "organ_packaging_transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "organ_packaging_recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient ID"])},
        "available_forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Forms"])},
        "toggle_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle selection of this row"])}
      },
      "fr": {
        "create_forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des formulaires"])},
        "organ_packaging_forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaires d'emballage des organes"])},
        "organ_packaging_organ_consents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentements pour les organes"])},
        "organ_packaging_ODSIF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODSIF"])},
        "organ_packaging_cooler_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feuille de refroidissement"])},
        "organ_packaging_interior_organ_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes d'organes intérieurs"])},
        "organ_packaging_exterior_organ_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes d'organes extérieurs"])},
        "organ_packaging_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
        "organ_packaging_intended_research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destiné à la recherche"])},
        "organ_packaging_allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Allocation"])},
        "organ_packaging_transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])},
        "organ_packaging_recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Patient"])},
        "available_forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaires disponibles"])},
        "toggle_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la sélection de cette ligne"])}
      }
    }
  })
}
