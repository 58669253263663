import { RecipientConfig, FeatureConfig, GeneralClinicalConfig } from '@/store/configuration/types';

export class UIRecipientDeathConfig {
  public enabled: boolean;

  public constructor(apiDeathConfig: FeatureConfig) {
    this.enabled = apiDeathConfig.enabled;
  }
}

export class UIRecipientGeneralClinicalConfig {
  public enabled: boolean;
  public blood_type: FeatureConfig;
  public measurements: FeatureConfig;

  public constructor(apiGeneralClinical: GeneralClinicalConfig) {
    this.enabled = apiGeneralClinical.enabled;
    this.blood_type = apiGeneralClinical.blood_type;
    this.measurements = apiGeneralClinical.measurements;
  }
}

export class UIRecipientConfig {
  public recipientDeath: UIRecipientDeathConfig;
  public recipientGeneralClinical: UIRecipientGeneralClinicalConfig;
  public recipientSerology: UIRecipientSerologyConfig;

  public constructor(apiRecipientConfig: RecipientConfig) {
    this.recipientDeath = new UIRecipientDeathConfig(apiRecipientConfig.death);
    this.recipientGeneralClinical = new UIRecipientGeneralClinicalConfig(apiRecipientConfig.general_clinical);
    this.recipientSerology = new UIRecipientSerologyConfig(apiRecipientConfig.virology);
  }
}

export class UIRecipientSerologyConfig {
  public enabled: boolean;

  public constructor(apiSerologyConfig: FeatureConfig) {
    this.enabled = !!apiSerologyConfig?.enabled;
  }
}
