import Vue from 'vue';
import { ObjectId } from '@/store/types';
import Vuex, { StoreOptions } from 'vuex';

export interface LivingAllocationState {
  selected?: LivingAllocation;
  allAllocations?: LivingAllocations[];
  activeAllocations?: LivingAllocations[];
  inactiveAllocations?: LivingAllocations[];
  exclusionRules?: LivingExclusionRules[];
  donorDetails?: LivingDonorDetails;
  recipientDetails?: LivingRecipientDetails;
  checklist: LivingAllocationChecklistForm|undefined;
  checklistDetails: LivingAllocationChecklistResponse|undefined;
  checklistHistory: LivingAllocationChecklistResponse[]|undefined;
  // loading state flags used across components
  lastLoadedAllocationDonorId?: ObjectId;
  isCreatingAllocation: boolean;
  isLoadingAllocation: boolean;
  isLoadingAllocations: boolean;
  isGeneratingAllocationReport: boolean;
  isAddingRecipient: boolean
  isDiscontinuingOneAllocation: boolean;
  isDiscontinuingAllAllocations: boolean;
  isMakingOffer: boolean;
  isDiscontinuingOneOffer: boolean;
  isRespondingOffer: boolean;
  isDecliningMultiple: boolean;
}

export interface LivingAllocationChecklistForm {
  _id?: { $oid: string };
  checklistType: string;
  checklistUser: string;
  allocationId?: string;
  allocationRunDate?: string;
  livingDonorId?: number;
  consentedOrgan?: string;
  donorFirstName?: string;
  donorLastName?: string;
}

export interface LivingAllocationChecklistData {
  confirmed: boolean;
  details: LivingAllocationChecklistDetails;
}

export interface LivingAllocationChecklistResponse {
  pre: LivingAllocationChecklistData;
  post: LivingAllocationChecklistData;
}

export interface LivingAllocations {
  organ_code: number;
  allocations: LivingAllocation[];
}

export interface LivingAllocation {
  _id: string;
  client_id: string;
  state: string;
  created_at: string;
  allocation_type: string|null;
  expedited: boolean|null;
  expedited_reasons: {
    donor_hla_typing_missing: boolean;
    donor_virology_missing: boolean;
  };
  rerunnable: boolean;
  organ_code: number;
  organ: string;
  disabled_program_rules: string[];
  donor: LivingAllocationDonor;
  recipients: LivingAllocationRecipient[];
  checklists_status: LivingAllocationChecklist;
  supercedes_id: string|null;
  superceded_by_id?: string|null;
  start_date: string;
  messages: {
    warnings: string[];
    errors: string[];
  };
  concluded_reason_code: number;
  concluded_reason: string;
}

export interface LivingAllocationChecklistDetails {
  csc1_confirmed: boolean;
  csc1_confirmed_date: string;
  csc1_confirmed_comments: string;
  csc1_confirmed_userid?: string;
  csc2_confirmed: boolean;
  csc2_confirmed_date: string;
  csc2_confirmed_comments: string;
  csc2_confirmed_userid?: string;
}
export interface LivingAllocationChecklist {
  [key: string]: boolean;
  post_confirmed: boolean;
  post_csc1_confirmed: boolean;
  post_csc2_confirmed: boolean;
  pre_confirmed: boolean;
  pre_csc1_confirmed: boolean;
  pre_csc2_confirmed: boolean;
}

export interface LivingAllocationDonor {
  _id: ObjectId;
  client_id: number;
  first_name: string;
  last_name: string;
  blood_type: string;
  blood_sub_type: string;
  dob: string;
  donor_category: string;
  donor_at_threshold: boolean;
  age: number;
  sex: string;
  neurological_death: boolean;
  ecd: boolean;
  exceptional_distribution: boolean;
  hospital_id: string;
  hospital_code: number;
  province_code: string;
  region_code: string;
}

export interface LivingAllocationRecipient {
  _id: string;
  client_id: number;
  rank: number;
  effective_rank: number;
  first_name: string;
  last_name: string;
  organ_code: number;
  cluster_organ_code?: string;
  cluster_organ_codes: number[],
  dob: string;
  hospital_abbreviation?: string;
  age: number;
  sex: string;
  medical_status: string;
  secondary_medical_status: string;
  blood_type: string;
  height: number;
  weight: number;
  cpra: number;
  hsp: string;
  allocation_points: number|null;
  mpe_score: number|null;
  status: string;
  hospital_id: string,
  hospital_code: number;
  program: string;
  province_code: string;
  country_code: string;
  offer?: LivingAllocationOffer;
  waitlisted_for_organ_codes: string[];
  waitlisted_for_organs: string[];
  cluster_organ: string|null;
  other_offers_pending: boolean;
  other_offers_accepted: boolean;
  out_of_province: boolean;
  added_manually: boolean;
  re_offer_scenario: boolean;
  transplant_in_progress: LivingAllocationRecipientTransplantInProgress;
  has_2nd_ranking_entry?: boolean;
  is_2nd_ranking_entry?: boolean;
  entry_offer_actions_enabled?: boolean;
  ranking_category?: string|null;
  registration_type?: string|null;
  ctr_match_id?: string|null;
  listed_for: string[];
}

export interface LivingAllocationRecipientTransplantInProgress {
  donor_client_id: number;
  deceased_donor_id: number;
}

export interface LivingAllocationOffer {
  offer_type_code: string|null;
  provisional: boolean;
  datetime_offered: string|null;
  datetime_effective: string|null;
  datetime_expires: string|null;
  offered_by: string;
  organ_specification_code: number|null;
  no_offer_reason_category: string;
  no_offer_reason_code: string;
  carried_over_from_rerun: boolean;
  comments: string;
  response_code: string|null;
  response_date: string;
  response_by: string;
  responsible_physician_id: string;
  response_reason_category_code: number|null;
  response_reason_code: number|null;
  responses: any;
}

export interface LivingExclusionRules {
  id: string;
  description: string;
  active: boolean;
  organ_code: number[];
  effective_date: string;
  expired_date: string;
}

export interface LivingAllocationOfferAction {
  recipients: {
    id: string;
    offer_organ_code: number|undefined;
  }[];
  type: string;
  reason_code?: number|string;
  reason_category?: number|string;
  responsible_physician_id?: string;
  organ_specification_code?: number;
  comments?: string;
  out_of_sequence_offer_reason_code?: number|null;
  out_of_sequence_offer_reason_other?: string|null;
}

export interface LivingAllocationResponseAction {
  recipient_id?: string;
  type?: string;
  reason_code?: number|null;
  reason_category?: number|null;
  offer_organ_code?: number;
}

export enum LivingAllocationOfferTypeValues {
  Primary = 'P',
  Backup = 'B',
  NoOffer = 'N',
  ConvertToAcceptedPrimary = 'C',
}

export enum LivingAllocationOfferResponseCodeValues {
  Accept = 'A',
  AcceptWithCondition = 'AC',
  Decline = 'D',
  RequestExtension = 'E',
  TimeOut = 'T',
  Withdraw = 'W',
  Cancel = 'C'
}

// NOTE: the 'Request Extension' response is still considered an open / proposed offer (see B#15470)
export const RESPONSE_CODES_CONSIDERED_TO_BE_OPEN: (string|null)[] = [
  LivingAllocationOfferResponseCodeValues.RequestExtension.toString(),
  null,
];

export interface LivingDonorDetails {
  _id: ObjectId;
  client_id: number;
  state: string;
  expedited: boolean;
  allocation_type: string;
  organ_code: number;
  organ: string;
  disabled_program_rules: string[];
  created_at: string;
  donor: LivingDonorDetailsProfile;
}

export interface LivingDonorDetailsProfile {
  _id: ObjectId;
  client_id: number;
  first_name: string;
  last_name: string;
  blood_type: string;
  blood_sub_type: string;
  blood_rh: string;
  dob: string;
  age: number;
  gender: string;
  neurological_death: boolean;
  ecd: boolean;
  exceptional_distribution: boolean;
  exd_reason_codes?: number[]|null;
  exd_reason_details_other?: string|null;
  exd_reason_details_travel?: string|null;
  exd_reason_details_transmission?: string|null;
  height: number;
  weight: number;
  bmi: number;
  hospital_id: ObjectId;
  hospital_code: number;
  region_code: string;
  ndd: boolean;
  virology: LivingDonorDetailsVirology;
  hla_typing: LivingDonorDetailsHlaTyping[];
}

export interface LivingDonorDetailsVirology {
  cmv: boolean;
  ebv: boolean;
  hbv_surface_ag: boolean;
  hbv_surgace_ab: boolean;
  hcv: boolean;
  hiv_nat: boolean;
  hcv_nat: boolean;
  hbv_nat: boolean;
  hiv_1_2: boolean;
  htlv1: boolean;
  htlv2: boolean;
  syphilis: boolean;
  toxoplasma: boolean;
  wnv_pcr: boolean;
}

export interface LivingDonorDetailsHlaTyping {
  typing_available: boolean;
  antigens: LivingDonorDetailsHlaAntigens[];
  epitopes: string[];
}

export interface LivingDonorDetailsHlaAntigens {
  locus: string;
  molecular: string;
  most_likely_allele: boolean;
  serologic: string[];
}


export interface LivingRecipientDetails {
  _id: ObjectId;
  client_id: number;
  state: string;
  expedited: boolean;
  allocation_type: string;
  organ_code: number;
  organ: string;
  disabled_program_rules: string[];
  created_at: string;
  recipient: LivingRecipientDetailsProfile;
}

export interface AssociatedJourney {
  organ: string;
  registration_type: string;
  registration_status: string;
  medical_status_code: string;
  medical_status_desc: string;
  secondary_medical_status_code: string;
  secondary_medical_status_desc: string;
}

export interface LivingRecipientDetailsProfile {
  _id: ObjectId;
  client_id: number;
  national_recipient_id: string;
  mrn: string;
  rank: number;
  first_name: string;
  last_name: string;
  dob: string;
  age: number;
  gender: string;
  medical_status: string;
  secondary_medical_status: string;
  blood_type: string;
  height: number;
  weight: number;
  weight_min: number;
  weight_max: number;
  bmi: number;
  cpra: string;
  hsp: string;
  status: string;
  hospital_id: ObjectId;
  hospital_code: number;
  program: string;
  province_code: string;
  country_code: string;
  wait_days: number;
  listing_date: string;
  primary_diagnosis: string;
  secondary_diagnosis: string;
  registration_type: string;
  ranking_category: string;
  registration_status: string;
  waitlisted_for_organs: string[];
  comments: string;
  donor_acceptability: LivingRecipientDetailsDonorAcceptability;
  total_lung_capacity: number;
  virology: LivingRecipientDetailsVirology;
  pra: LivingRecipientDetailsPra;
  vxm: LivingRecipientDetailsVxm;
  hla_typing: LivingRecipientDetailsHlaTyping;
  antibodies: LivingRecipientDetailsHlaAntibodies;
  out_of_province?: boolean;
  associated_journeys: AssociatedJourney[];
  listed_for: string[];
}

export interface LivingRecipientDetailsDonorAcceptability {
  abo_incompatible: boolean;
  a2_or_a2b: boolean;
  ecd: boolean;
  min_weight: number;
  max_weight: number;
  comments: string;
  deceased_donor: boolean;
  living_donor: boolean;
}

export interface LivingRecipientDetailsVirology {
  cmv: boolean;
  ebv: boolean;
  hbv_surface_ag: boolean;
  hcv: boolean;
}

export interface LivingRecipientDetailsPra {
  class1_current_value: number;
  class1_current_months: number;
  class1_peak_value: number;
  class1_peak_months: number;
  class2_current_value: number;
  class2_current_months: number;
  class2_peak_value: number;
  class2_peak_months: number;
  total_current_value: number;
  total_current_months: number;
  total_peak_value: number;
  total_peak_months: number;
}

export interface LivingRecipientDetailsVxm {
  result: string;
  current: LivingRecipientDetailsVxmCurrentCumulative;
  cumulative: LivingRecipientDetailsVxmCurrentCumulative;
  current_serum_date: string;
}

export interface LivingRecipientDetailsVxmCurrentCumulative {
  result: string|null;
  class1?: LivingRecipientDetailsVxmClass;
  class2?: LivingRecipientDetailsVxmClass;
}
export interface LivingRecipientDetailsVxmClass {
  result: string|null;
  messages: string[];
  unacceptable_allele_group: string[];
  unacceptable_allele_specific: string[];
  unacceptable_epitopes: string[];
  indeterminate_allele_group: string[];
  indeterminate_allele_specific: string[];
  indeterminate_epitopes: string[];
  possible_allele_specific: string[];
  untested: string[];
  untested_epitopes: string[];
}

export interface LivingRecipientDetailsHlaTyping {
  antigens: LivingRecipientDetailsHlaAntigens[];
  epitopes: string[];
}

export interface LivingRecipientDetailsHlaAntigens {
  locus: string;
  molecular: string;
  most_likely_allele: boolean;
  serologic: string[];
}

export interface LivingRecipientDetailsHlaAntibodies {
  cumulative: LivingRecipientDetailsCumulativeCurrent;
  current: LivingRecipientDetailsCumulativeCurrent;
  current_antibodies_confirmed: boolean;
  test_date: string;
}

export interface LivingRecipientDetailsCumulativeCurrent {
  class1: LivingRecipientDetailsHlaClass;
  class2: LivingRecipientDetailsHlaClass;
  cpra: number;
  cpra_class1: number;
  cpra_class2: number;
}

export interface LivingRecipientDetailsHlaClass {
  antibodies: LivingRecipientDetailsAntibodies;
  epitopes: LivingRecipientDetailsEpitopes;
}

export interface LivingRecipientDetailsAntibodies {
  acceptable: string[];
  unacceptable_allele_group: string[];
  unacceptable_allele_specific: string[];
  unacceptable_alpha_beta: string[];
  indeterminate_allele_group: string[];
  indeterminate_allele_specific: string[];
  indeterminate_alpha_beta: string[];
  possible_allele_specific: string[];
  untested: string[];
}

export interface LivingRecipientDetailsEpitopes {
  acceptable: string[];
  unacceptable: string[];
  indeterminate: string[];
  untested: string[];
}

export interface LivingAllocationResponse {
  _id?: string;
  selected?: boolean,
  offerType?: string;
  organ_spec_offered?: string|null;
  rank?: number;
  effective_rank?: number;
  client_id?: number;
  lastName?: string;
  program?: string;
  hospital_abbreviation?: string;
  hospitalId?: string;
  organ?: string;
  offerOrganCode?: number;
  offerDateTime?: string;
  offeredBy?: string;
  hsp?: string;
  responseCode?: string;
  responseCategoryCode?: number|null;
  responseReasonCode?: number|null;
  responseDateTime?: string;
  responsiblePhysician?: string;
  responseBy?: string;
  recipientStatus?: string;
  outOfProvince?: boolean;
  medical_status?: string;
  secondary_medical_status?: string;
  allocation_points: number|null;
  mpe_score: number|null;
  abo?: string;
  sex?: string;
  age?: number;
  height?: number;
  weight?: number;
  cpra?: number;
  disableCompareModal?: boolean;
}

export interface LivingChecklistUsers {
  [key: string]: LivingChecklistFields;
  csc1: LivingChecklistFields;
  csc2: LivingChecklistFields;
}

export interface LivingChecklistFields {
  [key: string]: boolean;
  donor_id: boolean;
  blood: boolean;
  donor_type: boolean;
  region: boolean;
  offering_plan: boolean;
  offering_program: boolean;
  offering_recipient: boolean;
  allocation_date: boolean;
  no_new_high_status_check: boolean;
  tips_confirmed: boolean;
  ontario_hsp_kidney: boolean;
  special_considerations: boolean;
}

export interface LivingAllocationOfferSnapshot {
  donor_client_id?: string;
  deceased_donor_id?: string;
  client_id?: string;
  organ_code?: string;
  allocation_type?: string;
  state?: string;
  offered_recipients?: LivingAllocationOfferedRecipient[];
}

export interface LivingAllocationOfferedRecipient {
  client_id?: string;
  datetime_offered?: string;
  datetime_expires?: string;
  offer_response_code?: string;
  program_identifier?: string;
  offer_type_code?: string;
}

export interface LivingAllocationOfferRecipient {
  id: string;
  effective_rank: number;
  offer_organ_code: number|undefined;
  hsp: string|null;
  cluster_organ_code: string|undefined;
  cluster_organ_codes?: number[],
  re_offer_scenario: boolean;
  offer_type_code: string|null,
  response_code: string|null,
  offered_to_2nd_category?: boolean;
}

export const RUN_ALLOCATION = 'run_allocation';
export const RERUN_ALLOCATION = 'rerun_allocation';
export const RUN_EXPEDITED_ALLOCATION = 'run_expedited_allocation';
export const RERUN_EXPEDITED_ALLOCATION = 'rerun_expedited_allocation';

export const RANKING_CATEGORY_HSP = 'HSP Patient Identified by CTR';

export enum LivingAllocationStateValues {
  Offering = 'offering',
  OfferConfirmed = 'offer-confirmed',
  Concluded = 'concluded',
  Recommended = 'recommended',
}

export enum LivingAllocationConcludedReasonCodes {
  OrganNotUsed = 1,
  OrganForResearch = 2,
  OrganTransplanted = 3,
  MultipleDifferentOrganDispositions = 4,
  AllocationPastCutoffPeriod = 5,
  Other = 99,
}

export const DISCONTINUED = 'discontinued';
export const SUPERCEDED = 'superceded';

export const SYSTEM_ONLY_ALLOCATION_STATES = [
  DISCONTINUED,
  SUPERCEDED,
  'error',
];

export enum RegistrationType {
  Cluster = "CLUSTER"
}
