import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 4 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = { class: "sr-only" }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.isVerbose)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        (_ctx.result === '+')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('positive_vxm_result')), 1)
            ], 64))
          : (_ctx.result === '-')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('negative_vxm_result')), 1)
              ], 64))
            : (_ctx.result === '?')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('indeterminate_vxm_result')), 1)
                ], 64))
              : (_ctx.result === 'U')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('untested_vxm_result')), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.result), 1))
      ]))
    : (_openBlock(), _createElementBlock("span", _hoisted_3, [
        (_ctx.result === '+')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['far', 'plus-circle'],
                title: _ctx.$t('positive'),
                "fixed-width": "",
                class: "text-red"
              }, null, 8, ["title"]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.result), 1)
            ], 64))
          : (_ctx.result === '-')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['far', 'minus-circle'],
                  title: _ctx.$t('negative'),
                  "fixed-width": "",
                  class: "text-green"
                }, null, 8, ["title"]),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.result), 1)
              ], 64))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.result), 1))
      ]))
}