import Vue from 'vue';
import { MutationTree } from 'vuex';
import { ConfigurationState } from '@/store/configuration/types';

export const mutations: MutationTree<ConfigurationState> = {
  clearConfiguration(state: ConfigurationState) {
    state.configuration = null;
  },
  setConfiguration(state: ConfigurationState, configuration) {
    state.configuration = configuration;
  },
};
