import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "standard-comment-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "pancreas-whole-specific-details",
    key: _ctx.journeyId
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('pancreas_specific_details')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        "sub-section-id": "psd-general",
        title: _ctx.$t('pancreas_info'),
        disabled: !_ctx.canSave || _ctx.newJourney,
        ref: "pancreasWholeSpecificDetails"
      }, {
        contents: _withCtx(() => [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
              ], 64))
            : (_openBlock(), _createBlock(_component_form_layout, {
                key: 1,
                disabled: !_ctx.enableForm,
                "form-id": "pancreas-whole-specific-details-form"
              }, _createSlots({
                contents: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_checkbox_input, {
                        "input-id": "vessels-required",
                        ruleKey: "organ_specific_details.vessels_required",
                        labelName: _ctx.$t('vessels_required'),
                        label: _ctx.$t('yes'),
                        modelValue: _ctx.editState.vesselsRequired,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.vesselsRequired) = $event))
                      }, null, 8, ["labelName", "label", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_text_area_input, {
                        inputId: "general-comments",
                        ruleKey: "organ_specific_details.general_comments",
                        name: _ctx.$t('general_comments'),
                        modelValue: _ctx.editState.comments,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.comments) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ])
                  ])
                ]),
                _: 2
              }, [
                (!_ctx.newJourney && _ctx.enableSaveToolbar)
                  ? {
                      name: "save",
                      fn: _withCtx(() => [
                        (_ctx.showSaveToolbar)
                          ? (_openBlock(), _createBlock(_component_save_toolbar, {
                              key: 0,
                              ref: "pancreasSpecificSaveToolbar",
                              class: "card-footer action-row temp-saving row",
                              label: _ctx.$t('save_pancreas_specific_details'),
                              cancelButton: true,
                              onSave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSave())),
                              onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleCancel()))
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["disabled"]))
        ]),
        _: 1
      }, 8, ["title", "disabled"])
    ]),
    _: 1
  }))
}