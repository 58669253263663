<template>
  <div class="nav-block-wrap">
    <ul class="nav nav-block flex-column" :class="{ active: active }">
      <li class="nav-item" >
        <router-link class="nav-link no-scroll open" :to="{ name: 'edit-living-donor', params: { id: clientId }, hash: '#summary' }" v-if="clientId">
          <font-awesome-icon :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width @click.prevent="toggleNav" />
          {{ $t('profile') }}
        </router-link>
        <a href="#" class="nav-link no-scroll open" :to="{ name: 'list-living-donors', hash: '#summary'}" v-else>
          <font-awesome-icon :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width @click.prevent="toggleNav" />
          {{ $t('profile') }}
        </a>
      </li>
      <ul class="nav flex-column" v-if="show">
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" :href="getUrl('demographics')">
            {{ $t('demographics') }}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" :href="getUrl('contactinfo')">
            {{ $t('contact_information') }}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="!newDonor">
          <a class="nav-link" :href="getUrl('link-to-recipient')">
            {{ $t('link_to_recipient') }}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" :href="getUrl('donation-information')">
            {{ $t('donation_information') }}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" :href="getUrl('gci')">
            {{ $t('clinical_information') }}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav"  v-if="!newDonor">
          <a class="nav-link" :href="getUrl('virology')">
            {{ $t('virology_results') }}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="showRecoveryInformation">
          <a class="nav-link" :href="getUrl('recovery-information')">
            {{ $t('recovery_details') }}
          </a>
        </li>
      </ul>
    </ul>
  </div>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { i18nMessages } from '@/i18n';

@Component({
  ...i18nMessages([
    require('../_locales/common.json'),
    require('@/components/livingDonors/_locales/SideNav.json'),
  ]),
})
export default class SelectedLivingDonorProfileLinks extends Vue {

  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string;
  @Getter('showRecoveryInformation', { namespace: 'livingDonors' }) private showRecoveryInformation!: boolean;

  @Prop({ default: false }) active!: boolean;
  @Prop({default: false }) newDonor!: boolean;

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) {
       this.$emit("toggle");
    }
  }

  /**
   * Create a return to profile link
   *
   * @returns {string} client_id as a string
   */
  get profileReturnLink(): string {
    return this.clientId === '' ? `/livingDonors` : `/livingDonors/${this.clientId}`;
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new living donors we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-living-donor', params: { id: this.clientId }, hash: hash });
      return location.href;
    }
  }
}
</script>
