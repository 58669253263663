import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "legend-title" }
const _hoisted_2 = {
  key: 0,
  class: "btn-group float-right",
  role: "group",
  "aria-label": "sub section button group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toggle_button = _resolveComponent("toggle-button")!

  return (_openBlock(), _createElementBlock("h4", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass({ 'pt-3': _ctx.collapsible, 'd-inline-block': _ctx.collapsible })
    }, _toDisplayString(_ctx.heading), 3),
    (_ctx.collapsible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_toggle_button, {
            value: _ctx.value,
            onChange: _ctx.changed,
            toggleLabel: _ctx.toggleLabel,
            untoggleLabel: _ctx.untoggleLabel
          }, null, 8, ["value", "onChange", "toggleLabel", "untoggleLabel"])
        ]))
      : _createCommentVNode("", true)
  ]))
}