import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "content-wrap" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = {
  class: "nav-wrapper",
  id: "administration-area"
}
const _hoisted_5 = { class: "page-content" }
const _hoisted_6 = { autocomplete: "off" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page_top = _resolveComponent("page-top")!
  const _component_side_nav_ctr = _resolveComponent("side-nav-ctr")!
  const _component_ctr_event_log = _resolveComponent("ctr-event-log")!

  return (_ctx.donorCanAccessCtrLog)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("template", null, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: { name: 'administration' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('administration')), 1)
                ]),
                _: 1
              }),
              _createTextVNode(" / " + _toDisplayString(_ctx.$t('ctr_integration')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_side_nav_ctr),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("form", _hoisted_6, [
                    _createVNode(_component_ctr_event_log)
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}