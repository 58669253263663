<template>
  <card-section
    section-id="demographics"
    :lookups-to-load="lookupsToLoad"
    :disabled="!canSave"
    @loaded="loaded()"
  >
    <template v-slot:header>
      {{$t('demographics')}}
    </template>
    <template v-slot:body v-if="editState">
      <validation-observer ref="validations">
      <form-layout form-id="demographics-form">
        <template v-slot:contents>
          <sub-section 
            :title="$t('registration_information')" 
            sub-section-id="demographics-internal" 
            :disabled="!canSave"
            :hideDivider="true"
            >
            <template v-slot:contents>
              <template v-if="editState.internal">
                <div class="row">
                  <div class="standard-form-group">
                    <text-input
                      inputId="demographics-personal-client_id"
                      :name="$t('client_id')"
                      :disabled="true"
                      v-model="editState.internal.client_id"
                    />
                  </div>
                  <div class="standard-form-group" v-if="checkPropExists('patient_profile.ctr.national_recipient_id')">
                    <text-input
                      inputId="demographics-personal-ctr_id"
                      :name="$t('CTR_Number')"
                      :disabled="true"
                      v-model="editState.internal.ctr_id"
                    />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      inputId="demographics-personal-registration_date"
                      :name="$t('registration_date')"
                      :disabled="true"
                      v-model="editState.internal.registration_date"
                    />
                  </div>
                  <div class="standard-form-group">
                    <text-input
                      inputId="demographics-personal-registration_time"
                      :name="$t('registration_time')"
                      :disabled="true"
                      v-model="editState.internal.registration_time"
                    />
                  </div>
                  <div v-if="isUrgentRecipientEnabled" class="standard-form-group">
                    <checkbox-input
                      ruleKey="patient_profile.urgent"
                      validationId="urgent"
                      input-id="demographics-internal-urgent"
                      :labelName="$t('urgent_recipient_label')"
                      :disabled="!newRecipient"
                      :title="isUrgent ? $t('urgent_recipient_hover_explanation') : ''"
                      v-model="editState.internal.urgent"
                      label="Yes"
                    />
                  </div>
                </div>
              </template>
            </template>
          </sub-section>
          <sub-section
            :title="$t('personal_information')"
            sub-section-id="demographics-personal"
            :disabled="!canSave"
            v-if="isPersonalInfoEnabled"
            :hideDivider="true"
          >
            <template v-slot:contents>
              <template v-if="editState.personal">
                <div class="row">
                  <div class="standard-form-group" v-if="checkPropExists('patient_profile.first_name')">
                    <text-input
                      ruleKey="patient_profile.first_name"
                      inputId="demographics-personal-firstname"
                      :name="$t('first_name')"
                      v-model="editState.personal.first_name"
                    />
                  </div>
                  <div class="standard-form-group" v-if="checkPropExists('patient_profile.middle_name')">
                    <text-input
                      ruleKey="patient_profile.middle_name"
                      inputId="demographics-personal-middlename"
                      :name="$t('middle_name')"
                      v-model="editState.personal.middle_name"
                    />
                  </div>
                  <div class="standard-form-group" v-if="checkPropExists('patient_profile.last_name')">
                    <text-input
                      ruleKey="patient_profile.last_name"
                      inputId="demographics-personal-lastname"
                      :name="$t('last_name')"
                      v-model="editState.personal.last_name"
                    />
                  </div>
                  <div class="standard-form-group" v-if="checkPropExists('patient_profile.preferred_name')">
                    <text-input
                      ruleKey="patient_profile.preferred_name"
                      inputId="demographics-personal-preferred_name"
                      :name="$t('preferred_name')"
                      v-model="editState.personal.preferredName"
                    />
                  </div>
                  <div class="standard-form-group" v-if="checkPropExists('patient_profile.preferred_pronouns')">
                    <text-input
                      ruleKey="patient_profile.preferred_pronouns"
                      inputId="demographics-personal-preferred_pronouns"
                      :name="$t('preferred_pronouns')"
                      v-model="editState.personal.preferredPronouns"
                    />
                  </div>
                </div>
                <div class="row">
                  <template  v-if="checkPropExists('patient_profile.birth.date')">
                    <div class="standard-form-group">
                      <date-input
                        ruleKey="patient_profile.birth.date"
                        inputId="demographics-personal-dob"
                        :name="$t('dob')"
                        v-model="editState.personal.date_of_birth"
                        :minDateLimit="getDobMinDateLimit"
                        :maxDateLimit="getDobMaxDateLimit"
                      />
                    </div>
                    <div class="standard-form-group">
                      <text-input
                        inputId="demographics-personal-age"
                        :name="$t('age')"
                        :disabled="true"
                        :calculated="true"
                        v-model="calculatedAge"
                      />
                    </div>
                  </template>
                  <div class="standard-form-group" v-if="checkPropExists('patient_profile.government_identification.number')">
                    <text-input
                      ruleKey="patient_profile.government_identification.number"
                      inputId="demographics-personal-government_identification_number"
                      :name="$t('government_identification_number')"
                      v-model="editState.personal.governmentIdentificationNumber"
                      :mask="getMaskFromRules('patient_profile.government_identification.number')"
                    />
                  </div>
                  <div class="standard-form-group" v-if="checkPropExists('patient_profile.race_codes')">
                    <select-input
                      ruleKey="patient_profile.race_codes"
                      selectId="demographics-personal-race"
                      :multiple="true"
                      :disabled="!canSave"
                      :name="$t('race')"
                      v-model="editState.personal.race"
                      :options="raceOptions"
                    />
                  </div>
                  <select-other-input
                    v-if="checkPropExists('patient_profile.ethnicity_code')"
                    ruleKey="patient_profile.ethnicity_code"
                    select-id="demographics-personal-ethnicity"
                    :name="$t('ethnicity')"
                    validationId="ethnicity_code"
                    v-model="editState.personal.ethnicity"
                    :options="ethnicityLookup"
                    @change="clearEthnicityOther"
                    :otherTitle="$t('ethnicity_other')"
                    :enableOther="showEthnicityOther"
                    colStyling="standard-form-group-with-other"
                    reduceColumnWidth="standard-form-group"
                  >
                    <template v-slot:other>
                      <text-input
                        ruleKey="patient_profile.ethnicity_other"
                        input-id="demographics-personal-ethnicityother"
                        :name="$t('ethnicity_other')"
                        v-model="editState.personal.ethnicity_other"
                      />
                    </template>
                  </select-other-input>
                  <div class="standard-form-group" v-if="checkPropExists('patient_profile.indigenous_identity_codes')">
                    <select-input
                      ruleKey="patient_profile.indigenous_identity_codes"
                      selectId="demographics-personal-indigenous_identity_codes"
                      :multiple="true"
                      :disabled="!canSave"
                      :name="$t('indigenous_identity_codes')"
                      v-model="editState.personal.indigenousIdentityCodes"
                      :options="indigenousIdentityOptions"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="standard-form-group" v-if="checkPropExists('patient_profile.sex')">
                    <select-input
                      ruleKey="patient_profile.sex"
                      selectId="demographics-internal-sex"
                      :name="$t('sex')"
                      v-model="editState.personal.sex"
                      :options="sexOptions"
                    />
                  </div>
                  <div class="standard-form-group" v-if="displayInUtero">
                    <boolean-radio-input
                      ruleKey="patient_profile.in_utero"
                      input-id='demographics-personal-in-utero'
                      :labelName="$t('in_utero')"
                      :acceptId="true"
                      :declineId="false"
                      :acceptLabel="$t('yes')"
                      :declineLabel="$t('no')"
                      v-model="editState.personal.in_utero"
                    />
                  </div>
                  <div class="standard-form-group-large" v-if="checkPropExists('patient_profile.gender_sex_different')">
                    <checkbox-input
                      ruleKey="patient_profile.gender_sex_different"
                      validationId="gender_sex_different"
                      input-id="demographics-personal-gender-different"
                      :labelName="$t('gender_is_diff')"
                      v-model="editState.personal.gender_sex_different"
                      :label="$t('yes')"
                    />
                  </div>
                  <select-other-input
                    ruleKey="patient_profile.gender"
                    select-id="demographics-personal-gender"
                    :name="$t('gender')"
                    validationId="gender"
                    v-if="editState.personal.gender_sex_different && checkPropExists('patient_profile.gender')"
                    v-model="editState.personal.gender"
                    :options="genderLookup"
                    @change="clearGenderOther"
                    :otherTitle="$t('gender_other')"
                    colStyling="standard-form-group-with-other"
                    reduceColumnWidth="standard-form-group"
                    :enableOther="checkPropExists('patient_profile.gender_other')"
                  >
                    <template v-slot:other>
                      <text-input
                        ruleKey="patient_profile.gender_other"
                        input-id="demographics-personal-other-gender"
                        :name="$t('gender_other')"
                        v-model="editState.personal.gender_other"
                      />
                    </template>
                  </select-other-input>
                </div>
                <div class="row" v-if="checkAtLeastOnePropExists(['patient_profile.education.highest_education_level', 'patient_profile.education.academic_grade_level'])">
                  <div class="standard-form-group" v-if="checkPropExists('patient_profile.education.highest_education_level')">
                    <select-input
                      ruleKey="patient_profile.education.highest_education_level"
                      selectId="demographics-personal-highest_education_level"
                      :name="$t('highest_education_level')"
                      v-model="editState.personal.highestEducationLevel"
                      :options="highestEducationLevelOptions"
                    />
                  </div>
                  <div class="standard-form-group" v-if="checkPropExists('patient_profile.education.academic_grade_level')">
                    <select-input
                      ruleKey="patient_profile.education.academic_grade_level"
                      selectId="demographics-personal-academic_grade_level"
                      :name="$t('academic_grade_level')"
                      v-model="editState.personal.academicGradeLevel"
                      :options="academicGradeLevelOptions"
                    />
                  </div>
                </div>
                <fieldset
                  v-if="checkPropExists('patient_profile.insurance')"
                  :disabled="!isGroupWriteable('recipient_insurance')">
                  <div class="row">
                    <select-other-input
                      ruleKey="patient_profile.insurance.plan_code"
                      selectId="demographics-personal-insurancetype"
                      :name="$t('insurance_type')"
                      v-model="editState.personal.insurance_type"
                      :options="insurancePlanCodeLookup"
                      @change="clearInsurance"
                      validationId="plan_code"
                      reduceColumnWidth="standard-form-group"
                      colStyling="standard-form-group-with-other"
                    >
                      <!-- TODO: refactor select other component  https://vuejs.org/v2/guide/components-slots.html#Slot-Content -->
                      <template v-slot:other>
                        <text-input
                          ruleKey="patient_profile.insurance.other_plan"
                          input-id="demographics-personal-insuranceother"
                          :name="$t('other_insurance_detail')"
                          v-model="editState.personal.insurance_other"
                        />
                      </template>
                    </select-other-input>
                    <div class="standard-form-group"  v-if="showOtherProvinceInsurance">
                      <select-input
                        ruleKey="patient_profile.insurance.other_province_plan"
                        select-id="demographics-personal-provinceother"
                        :name="$t('other_province_insurance')"
                        v-model="editState.personal.insurance_province"
                        :options="otherProvinceDropdown"
                      />
                    </div>
                    <div class="standard-form-group" v-if="showNumber">
                      <!-- not for other canada or none -->
                      <text-input
                        ruleKey="patient_profile.insurance.number"
                        inputId="demographics-personal-insurancenumber"
                        :name="$t('insurance_number')"
                        v-model="editState.personal.insurance_number"
                        :mask="getMaskFormatType(editState.personal.insurance_type)"
                      />
                    </div>
                  </div>
                </fieldset>
              </template>
            </template>
          </sub-section>

          <!-- To show this section the patient must have: Heart, Lung or Small Bowel added as required organ and eligible for the waitlist -->
          <sub-section
            :title="$t('financial_assistance')"
            sub-section-id="demographics-fa"
            v-show="isFinancialAssistanceEnabled && isFinancialAssistanceNeeded()"
            :disabled="!isGroupWriteable('recipient_financial')"
            :hideDivider="true"
          >
            <template v-slot:contents>
              <template v-if="editState.financial_assistance">
                <div class="row">
                  <div class="standard-form-group">
                    <select-input
                      selectId="demographics-fa-tper_status"
                      :name="$t('tper_status')"
                      v-model="editState.financial_assistance.status"
                      :options="tperStatusLookup"
                      @change="clearTPERDate"
                    />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      ruleKey="patient_profile.financial_assistance.date"
                      inputId="demographics-fa-tper_date"
                      :name="$t('tper_status_date')"
                      v-model="editState.financial_assistance.tper_date"
                      :rules="{ required: isTperStatusDateRequired }"
                    />
                  </div>
                  <div class="standard-form-group-large">
                    <text-area-input
                      inputId="demographics-fa-tper-comment"
                      :name="$t('tper_comment')"
                      v-model="editState.financial_assistance.tper_comment"
                    />
                  </div>
                  <div class="standard-form-group-large">
                    <label for="demographics-fa-tper-attachment"
                      >{{$t('tper_attachments')}}</label>
                    <input
                      input-id="demographics-fa-tper-attachment"
                      ref="fileUploader"
                      type="file"
                      class="form-control"
                      @change="onTperAttachmentsChanged($event)"
                    />
                  </div>
                </div>
                <template v-if="financialAttachments">
                  <h3>
                    {{$t('uploaded_documents')}}
                  </h3>
                  <ul class="list-inline">
                    <li
                      v-for="(attachement) in financialAttachments"
                      :key="attachement.id"
                      class="list-inline-item"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'circle']"
                        fixed-width
                        size="sm"
                        class="text-grey mr-2"
                      />
                      <a href="#" @click.prevent.stop="generateDownloadLink(attachement.id)" :title="$t('download_this_document')">
                        <font-awesome-icon class='text-grey mr-2' :icon="['far', 'download']" size='sm' aria-hidden='true' fixed-width />
                            {{ attachement.name }}
                      </a>
                      <a href="#" title='Delete this document' @click.prevent="onDeleteFile(attachement.id, attachement.name)" v-if="isGroupWriteable('recipient_financial')">
                        <font-awesome-icon class='text-grey mr-2' :icon="['fa', 'trash']" size='sm' aria-hidden='true' fixed-width style="color:#8b0000"/>
                      </a>
                    </li>
                  </ul>
                </template>
              </template>
            </template>
          </sub-section>
        </template>

        <template v-slot:save>
          <save-toolbar
            v-if="showSaveToolbar"
            ref="saveDemographics"
            :label="newRecipient ? $t('save_recipient') : $t('save_demographics')"
            @save="savePatch(false)"
            :cancelButton="true"
            @cancel="cancelPatch()"
          />
        </template>
      </form-layout>
      </validation-observer>
    </template>
  </card-section>
</template>

<script lang="ts">
import { Getter, State } from "vuex-facing-decorator";
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import DateInput from "@/components/shared/DateInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import SubSection from "@/components/shared/SubSection.vue";
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from "@/components/shared/SaveToolbar.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import CheckboxInput from "@/components/shared/CheckboxInput.vue";
import { SaveableSection, SaveResult } from "@/types";
import { Component, Prop, Watch } from "vue-facing-decorator";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import { calculateAge } from "@/utils";
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { Recipient, RecipientFinancialAssistance, RecipientAttachment, RecipientProfileDetails, RecipientValidations } from "@/store/recipients/types";
import { Ethnicity, Sex, Gender, InsurancePlanCode, InsuranceType, OrganCodeValue, Province, TperStatusCode } from "@/store/lookups/types";
import { RecipientJourney } from "@/store/recipientJourney/types";
import { GenericCodeValue } from "@/store/types";
import FormLayout from '@/components/shared/FormLayout.vue';
import { isMasked } from '@/utils';
import { i18nMessages } from "@/i18n";
import { parseFormErrors } from '@/utils';
import { useCurrentPageStore } from "@/stores/currentPage";

interface DemographicsForm {
  internal: {
    client_id?: number;
    ctr_id?: number;
    registration_date?: string;
    registration_time?: string;
    recipient_type?: string;
    urgent?: boolean;
  };
  personal: {
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    preferredName?: string;
    preferredPronouns?: string;
    date_of_birth?: string;
    governmentIdentificationNumber?: string;
    sex?: string;
    gender?: string | null;
    gender_sex_different?: boolean;
    gender_other?: string | null;
    race?: string[];
    indigenousIdentityCodes?: string[];
    ethnicity?: string | null;
    ethnicity_other?: string;
    highestEducationLevel?: string|null;
    academicGradeLevel?: string|null;
    insurance_type?: number;
    insurance_other?: number;
    insurance_number?: string;
    insurance_province?: string;
    in_utero?: boolean|null;
  };
  financial_assistance: {
    status?: string;
    tper_date?: string;
    tper_comment?: string;
    tper_attachments?: FileList;
    tper_applicable?: boolean;
    uploadedFiles?: RecipientAttachment[];
  };
  // TODO: TECH_DEBT: remove top-level "editState.fileName" setting in favour of handling entirely in financial_assistance
  fileName?: string;
}

interface ReferralDetailsForm {
  organCode: string|null;
  mrn: string|undefined;
  urgent: boolean;
  transplant: {
    transplantProgram?: string;
    recipientCoordinator?: string;
  };
  referralPackage: {
    referredWith: number|null,
  };
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    FormLayout,
    SaveToolbar,
    SelectInput,
    NumberInput,
    CheckboxInput,
    SelectOtherInput,
    TextAreaInput,
    BooleanRadioInput
  },
  ...i18nMessages([
    require('./../_locales/common.json'),
    require('./_locales/common.json'),
    require('@/components/recipients/_locales/Demographics.json'),
    require('@/views/_locales/common.json'),
  ]),
  emits: [
    'loaded',
  ],
})
export default class Demographics extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.lookups.province) provinceLookup!: Province[];
  @State(state => state.lookups.ethnicity) ethnicityLookup!: Ethnicity[];
  @State(state => state.pageState.currentPage.demographics) editState!: DemographicsForm;
  @State(state => state.pageState.currentPage.organDetails) organDetailsState!: ReferralDetailsForm;
  @State(state => state.lookups.insurance_plan_codes) insurancePlanCodeLookup!: InsurancePlanCode[];
  @State(state => state.recipients.selectedRecipient.validations) validations!: RecipientValidations;
  @State(state => state.lookups.gender) genderLookup!: Gender[];
  @State(state=> state.lookups.tper_status) tperStatusLookup!: TperStatusCode[];
  @State(state => state.lookups.race) raceOptions!: GenericCodeValue[];
  @State(state => state.lookups.indigenous_identity) indigenousIdentityOptions!: GenericCodeValue[];
  @State(state => state.lookups.highest_education_level) highestEducationLevelOptions!: GenericCodeValue[];
  @State(state => state.lookups.academic_grade_level) academicGradeLevelOptions!: GenericCodeValue[];

  // Getters
  @Getter('isUrgent', { namespace: 'recipients' }) public isUrgent!: boolean;
  @Getter('getDobMinDateLimit', { namespace: 'lookups' }) private getDobMinDateLimit!: string;
  @Getter('getDobMaxDateLimit', { namespace: 'lookups' }) private getDobMaxDateLimit!: string;
  @Getter("show", { namespace: "recipients" }) private recipient!: Recipient;
  @Getter("insurance", { namespace: "lookups" }) private insuranceLookup: any;
  @Getter("sexOptions", { namespace: "lookups" }) sexOptions!: Sex[];
  @Getter("financialAttachments", { namespace: "recipients" }) private financialAttachments!: { name?: string, id?: string }[] | undefined;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('isUrgentRecipientEnabled', { namespace: 'configuration' }) private isUrgentRecipientEnabled!: boolean
  @Getter('isFinancialAssistanceEnabled', { namespace: 'configuration' }) private isFinancialAssistanceEnabled!: boolean
  @Getter('isPersonalInfoEnabled', { namespace: 'configuration' }) private isPersonalInfoEnabled!: boolean
  @Getter('mustBeBlank', { namespace: "validations" }) private mustBeBlank!: (ruleKey: string) => boolean;
  @Getter('defaultLookup', { namespace: 'lookups' }) defaultLookup!: (lookupId: string) => any;
  @Getter('checkPropExists', { namespace: 'validations' }) checkPropExists!: (ruleKey: string) => boolean;
  @Getter('getMaskFormatType', { namespace: 'lookups' }) getMaskFormatType!: (code?: number|null) => string;
  @Getter('checkAtLeastOnePropExists', { namespace: 'validations' }) checkAtLeastOnePropExists!: (ruleKeys: string[]) => boolean;
  @Getter('getMaskFromRules', { namespace: 'validations' }) private getMaskFromRules!: (ruleKey: string) => string|null;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  showErrors(errors: any) {
    console.log(errors);
  }  

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [
    "ethnicity",
    "sex",
    "gender",
    "insurance_plan_codes",
    "country",
    "tper_status",
    "race",
    "indigenous_identity",
    "highest_education_level",
    "academic_grade_level",
  ];

  get showEthnicityOther(): boolean {
    return this.checkPropExists('patient_profile.ethnicity_other');
  }

  get showSaveToolbar(): boolean {
    if (!this.canSave) return false;
    return (this.isUrgentRecipientEnabled || this.isPersonalInfoEnabled || this.isFinancialAssistanceEnabled);
  }

  //Show financial assisstence if Heart, Lungs or Small Bowel
  public ORGAN_CODES_TO_INCLUDE =
    [
      OrganCodeValue.Lung,
      OrganCodeValue.Heart,
      OrganCodeValue.SmallBowel
    ];

  get isTperStatusDateRequired(): boolean {
    if (!this.editState || !this.editState.financial_assistance.status) {
      return false;
    }
    return true;
  }

   /**
   * Returns if the in_utero field must be displayed
   *
   * @returns {boolean} true if the in utero field is displayed
   */
  get displayInUtero(): boolean {
    const propertyExists = this.checkPropExists('patient_profile.in_utero');
    const journeys = this.recipient?.journeys || [];
    const selectedOrganCode = this.organDetailsState ? this.organDetailsState.organCode : null;
    if (journeys) {
      const organs = journeys.map((journey: RecipientJourney) => {
        return journey.organ_code;
      });
     
      return propertyExists && (organs.includes(OrganCodeValue.Heart) || selectedOrganCode === OrganCodeValue.Heart.toString());
    }
    return false;
  }

  /**
   * Gets a boolean value representing if insurance type other
   *
   * @returns {boolean} true if insurance type other is selected, false otherwise
   */
  get insuranceTypeIsOther(): boolean {
    if (!this.editState || !this.editState.personal.insurance_type) {
      return false;
    }
    return (
      this.editState.personal.insurance_type == InsuranceType.Other
    );
  }

  get showOtherProvinceInsurance(): boolean {
    if (!this.editState.personal) {
      return false;
    }
    return (
      this.editState.personal.insurance_type == InsuranceType.OtherProvince
    );
  }

  /**
   * Get a number for the age of the Recipient
   *
   * Calculates the age of the Recipient using the value of Date of Birth and Date of Death (if exists)
   *
   * @returns {number|null} Recipient's age or null
   */

  get calculatedAge() {
    const recipientDoB = this.editState.personal.date_of_birth || null;
    const recipientDoD =
      this.recipient.patient_profile && this.recipient.death
        ? this.recipient.death.death_date
        : null;
    // I have a birth and death date
    if (recipientDoB && recipientDoD) {
      return calculateAge(recipientDoB, this.parseDateUi(recipientDoD));
    }
    // I have a birth date only
    if (recipientDoB && !recipientDoD) {
      return calculateAge(recipientDoB);
    }
    return null;
  }

  // Do we need to show the Insurance Number field?
  get showNumber(): boolean {
    if (!this.editState || !this.editState.personal) {
      return false;
    }

    if (this.editState.personal.insurance_number) return true;

    return !this.mustBeBlank('patient_profile.insurance.number');
  }

  // returns province dropdown without ontario for the 'other provinces' dropdown
  get otherProvinceDropdown(): Province[] {
    if (!this.provinceLookup) {
      return [];
    }
    const ontarioIndex = this.provinceLookup.findIndex( element => element.code === "ON");
    const provinceWithoutOntario = this.provinceLookup.filter((element, index) => {
        return index === ontarioIndex ? false : element;
      }
    );
    return provinceWithoutOntario;
  }

  // Initialize the form
  @Watch('organDetailsState.organCode')
  public initializeForm(): void {
    this.$store.commit("pageState/set", {
      pageKey: "demographics",
      value: this.buildDemographicsForm(this.recipient)
    });
  }

  // Clear ethnicity other when ethnicity changes
  public clearEthnicityOther() {
    this.editState.personal.ethnicity_other = undefined;
  }

  // Clear tper date when tper status changes
  public clearTPERDate() {
    this.editState.financial_assistance.tper_date = undefined;
  }

  // Clear gender other when gender changes
  public clearGenderOther() {
    this.editState.personal.gender_other = undefined;
  }

  // Clear insurance other when insurance changes
  public clearInsurance() {
    this.editState.personal.insurance_number = undefined;
    this.editState.personal.insurance_province = undefined;
    this.editState.personal.insurance_other = undefined;
  }

  // Triggered when all the lookups have been loaded
  // NOTE: this ensure we initialize the form AFTER lookups are loaded by the sub-section
  public loaded(): void {
    this.initializeForm();
    this.$emit('loaded', 'demographics');
  }

  // Translate from relevant parts of the recipient data structure to the form layout
  public buildDemographicsForm(recipient: Recipient): DemographicsForm {
    // Initialize empty form state with default values
    const result: DemographicsForm = {
      internal: {},
      personal: {
        insurance_type: this.defaultLookup('insurance_plan_codes'),
      },
      financial_assistance: {},
    };
    // Copy values from the recipient patient profile into the form state
    const recipientProfile = recipient.patient_profile || {};
    const genderSexDifferent = recipientProfile.gender_sex_different ? true : false;
    const insurancePlanCode = recipient.patient_profile?.insurance?.plan_code;
    Object.assign(result, {
      internal: {
        client_id: recipient.client_id,
        ctr_id: recipient.patient_profile?.ctr?.national_recipient_id,
        registration_date: this.parseDateUiFromDateTime(recipient.patient_profile?.registration_date),
        registration_time: this.parseTimeUiFromDateTime(recipient.patient_profile?.registration_date),
        recipient_type: undefined,
        urgent: recipient.urgent
      },
      financial_assistance: {
        status: recipient.financial_assistance?.status,
        tper_date: this.parseDateUi(recipient.financial_assistance?.date),
        tper_comment: recipient.financial_assistance?.comments,
        tper_applicable: this.isFinancialAssistanceNeeded(),
        uploadedFiles: recipient.attachments
      }
    });
    if (this.isPersonalInfoEnabled) {
      Object.assign(result, {
        personal: {
          first_name: recipient.patient_profile?.first_name,
          middle_name: recipient.patient_profile?.middle_name,
          last_name: recipient.patient_profile?.last_name,
          preferredName: recipient.patient_profile?.preferred_name,
          preferredPronouns: recipient.patient_profile?.preferred_pronouns,
          date_of_birth: this.parseDateUi(recipient.patient_profile?.birth?.date),
          governmentIdentificationNumber: recipient.patient_profile?.government_identification?.number,
          sex: recipient.patient_profile?.sex || undefined,
          gender: genderSexDifferent ? recipientProfile.gender : null,
          gender_sex_different: recipient.patient_profile?.gender_sex_different,
          gender_other: recipient.patient_profile?.gender_other,
          race: recipient.patient_profile?.race_codes,
          ethnicity: recipient.patient_profile?.ethnicity_code || null,
          ethnicity_other: recipient.patient_profile?.ethnicity_other || undefined,
          indigenousIdentityCodes: recipient.patient_profile?.indigenous_identity_codes,
          // FIXME being masked out when education bundle enabled
          highestEducationLevel: isMasked(recipient.patient_profile?.education?.highest_education_level) ? null : recipient.patient_profile?.education?.highest_education_level,
          // FIXME being masked out when education bundle enabled
          academicGradeLevel: isMasked(recipient.patient_profile?.education?.academic_grade_level) ? null : recipient.patient_profile?.education?.academic_grade_level,
          insurance_type: insurancePlanCode != null ? insurancePlanCode : result.personal.insurance_type,
          insurance_province: recipient.patient_profile?.insurance?.other_province_plan || undefined,
          insurance_other: recipient.patient_profile?.insurance?.other_plan || undefined,
          insurance_number: recipient.patient_profile?.insurance?.number || undefined,
          in_utero: recipient.patient_profile?.in_utero || false
        },
      });
    }

    return result;
  }

  // Translate from the form layout to relevant parts of the recipient data structure
  public extractPatch(potential_duplicate_profile_confirmed?: boolean): Recipient {
    const updatedProfile: RecipientProfileDetails = {};
    if (this.isPersonalInfoEnabled) {
      Object.assign(updatedProfile, {
        first_name: this.editState.personal.first_name,
        middle_name: this.editState.personal.middle_name,
        last_name: this.editState.personal.last_name,
        preferred_name: this.editState.personal.preferredName,
        preferred_pronouns: this.editState.personal.preferredPronouns,
        in_utero: this.editState.personal.in_utero === undefined ? false : this.editState.personal.in_utero,
        birth: {
          date: this.sanitizeDateApi(this.editState.personal.date_of_birth)
        },
        government_identification: {
          number: this.editState.personal.governmentIdentificationNumber,
        },
        sex: this.editState.personal.sex || null,
        gender_sex_different: this.editState.personal.gender_sex_different ? true : false,
        gender: (this.editState.personal.gender_sex_different && this.editState.personal.gender) || null,
        gender_other: (this.editState.personal.gender_sex_different && this.editState.personal.gender_other) || null,
        race_codes: this.editState.personal.race,
        ethnicity_code: this.editState.personal.ethnicity || null,
        ethnicity_other: this.editState.personal.ethnicity_other || null,
        indigenous_identity_codes: this.editState.personal.indigenousIdentityCodes,
        education: {
          highest_education_level: this.editState.personal.highestEducationLevel || null,
          academic_grade_level: this.editState.personal.academicGradeLevel || null,
        },
        insurance: {
          plan_code: this.editState.personal.insurance_type,
          number: this.editState.personal.insurance_number || null,
          other_plan: this.editState.personal.insurance_other || null,
          other_province_plan: this.editState.personal.insurance_province || null
        }
      });
    }

    const payload: any = {
      _id: this.recipient._id,
      urgent: this.editState.internal.urgent ? true : false,
      patient_profile: updatedProfile,
      potential_duplicate_profile_confirmed: potential_duplicate_profile_confirmed || false
    };

    if (this.isFinancialAssistanceEnabled) {
      const financial_assistance: RecipientFinancialAssistance = {
        status: this.editState.financial_assistance.status,
        date: this.sanitizeDateApi(this.editState.financial_assistance.tper_date),
        comments: this.editState.financial_assistance.tper_comment,
        tper_applicable: this.editState.financial_assistance.tper_applicable
      };
      payload.financial_assistance = financial_assistance;
    }

    return payload;
  }

  public isFinancialAssistanceNeeded(): boolean {
    const journeys = this.recipient.journeys;
    if (journeys) {
      const organs = journeys.map((journey: RecipientJourney) => {
        return journey.organ_code;
      });
      const assisstanceNeeded = this.ORGAN_CODES_TO_INCLUDE.some(item =>
        organs.includes(item)
      );
      return assisstanceNeeded;
    }
    return false;
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handle the areas present on this form component
    this.saveToolbar.reset();
  }

  get saveToolbar(): SaveToolbar {
    return this.$refs.saveDemographics as unknown as SaveToolbar;
  }

  public cancelPatch(): void {
    this.initializeForm();
  }

  // Handle saving triggered by local save button
  public savePatch(potential_duplicate_profile_confirmed?: boolean): void {
    // Refer to the save toolbar that handles this form area
    this.saveToolbar.startSaving();
    // Generate payload based on current edit state
    const recipientPatch = this.extractPatch(potential_duplicate_profile_confirmed);
    // Dispatch save action and register the response
    if (this.newRecipient) {
      this.createRecipient(recipientPatch);
    } else {
      this.updateRecipient(recipientPatch);
    }
  }

  private async createRecipient(recipientPatch: Recipient): Promise<void> {
    // Attempt to create the recipient
    this.$store.dispatch('recipients/saveRecipient', {
       recipientId: null,
       recipient: recipientPatch,
    }).then((success: SaveResult) => {
      // Get the Client ID assigned to the new recipient
      const clientId = success.responseData.recipient.client_id;
      // Navigate to recipient edit
      this.$router.push({ name: 'edit-recipient', params: { id: clientId } });
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.handleErrors(error);
      this.saveToolbar.stopSaving(error);
    });
  }

  private async updateRecipient(recipientPatch: Recipient): Promise<void> {
    // TODO: TECH_DEBT remove recipient actions in favour of UI view models and pinia client state
    this.$store.dispatch("recipients/saveRecipientPatch", recipientPatch).then((success: SaveResult) => {
      // If successful, update the current recipient and show success notification
      this.saveToolbar.stopSaving(success);
      this.initializeForm();
    })
    .catch((error: SaveResult) => {
      // Emit event to handle errors
      this.handleErrors(error);
      this.saveToolbar.stopSaving(error);
    });
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup);

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);
  }

  public generateDownloadLink(id?: string): void {
    const payload = {
      recipientId: this.recipient.client_id,
      id: id,
    };

    this.$store.dispatch('recipients/downloadFile', payload).then((result: any) => {
      const link = document.createElement('a');
      link.href = result;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    }).catch((error: any) => {
        console.warn(this.$t('error_generating').toString(), error);
    });
  }

   /**
     * Handle deleting a file after the user accepts a warning dialog.
     */
    private onDeleteFile(id?: string, name?:string): void {
      const confirmed = confirm(this.$t('are_you_sure').toString() + "[" + name + "] ?");
      if (!confirmed) {
        // Cancel save if not confirmed
        return;
      }
      this.$store.dispatch('recipients/deleteFile', {
        id: id,
        recipientId: this.recipient.client_id,
      });
    }

  /**
   * Updates form state when Clinical Attachments files are uploaded
   *
   * @listens tper_attachments#changed
   */
  private onTperAttachmentsChanged(event: any) {
    if (!!this.editState && !!event.target) {
      {
        this.editState.financial_assistance.tper_attachments = event.target.files;

        // TODO: TECH_DEBT: remove top-level "editState.fileName" setting in favour of handling entirely in financial_assistance
        if (this.editState.financial_assistance.tper_attachments && this.editState.financial_assistance.tper_attachments[0]) {
          this.editState.fileName = this.editState.financial_assistance.tper_attachments[0].name;
        } else {
          this.editState.fileName = "";
        }
      }
    }
  }

  // API response keys on the left, id for our UI on the right
  public get idLookup(): {[key: string]: string} {
    const result = {
      "patient_profile.first_name"                        : "demographics-personal-firstname",
      "patient_profile.middle_name"                       : "demographics-personal-middlename",
      "patient_profile.last_name"                         : "demographics-personal-lastname",
      "patient_profile.preferred_name"                    : "demographics-personal-preferred_name",
      "patient_profile.preferred_pronouns"                : "demographics-personal-preferred_pronouns",
      "patient_profile.government_identification.number"  : "demographics-personal-government_identification_number",
      "patient_profile.insurance.number"                  : "demographics-personal-insurancenumber",
      "patient_profile.sex"                               : "demographics-internal-sex",
      "patient_profile.gender_sex_different"              : "demographics-personal-gender-different",
      "patient_profile.race_codes"                        : "demographics-personal-race",
      "patient_profile.ethnicity_code"                    : "ethnicity_code",
      "patient_profile.ethnicity_other"                   : "demographics-personal-ethnicityother",
      "patient_profile.indigenous_identity_codes"         : "demographics-personal-indigenous_identity_codes",
      "patient_profile.gender"                            : "demographics-personal-gender",
      "patient_profile.gender_other"                      : "demographics-personal-other-gender",
      "patient_profile.insurance.plan_code"               : "plan_code",
      "patient_profile.birth.date"                        : "demographics-personal-dob",
      "patient_profile.education.highest_education_level" : "demographics-personal-highest_education_level",
      "patient_profile.education.academic_grade_level"    : "demographics-personal-academic_grade_level",
      "patient_profile.insurance.other_plan"              : "demographics-personal-insuranceother",
      "patient_profile.insurance.other_province_plan"     : "demographics-personal-provinceother",
      "patient_profile.in_utero"                          : "demographics-personal-in-utero",
      "financial_assistance.date"                         : "demographics-fa-tper_date",
      "financial_assistance.status"                       : "demographics-fa-tper_status",
      "financial_assistance.comments"                     : "demographics-fa-tper_comment",
      

       file: "demographics-fa-tper-attachment"
    };
    return result;
  }
}
</script>
