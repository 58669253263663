import { APIContactDetailInterface } from '@/store/recipients/types';
import { APIPermittedActions, SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APISaveContactDetailResponse } from '@/types';
import { UIRecipient } from '@/UIModels/recipient';
import { sanitizePhoneNumber } from '@/utils';

export enum CONTACT_DETAIL_FORMAT {
  PatientPhone = 'phone_number',
  PatientEmail = 'email'
}

export enum CONTACT_DETAIL_OKAY_TO {
  Call = 'call',
  Voicemail = 'voicemail',
  Text = 'text',
}

export class UIContactDetail {
  public apiSource: APIContactDetailInterface|null = null;
  public id: string|null = null;

  public categoryType: string|null = null;
  public formatCode: string|null = null;

  public phoneNumber: string|null = null;
  public email: string|null = null;
  public preferred = false;
  public okayTo: string[] = [];
  public comments: string|null = null;

  public lastUpdated: string|null = null;
  public lastUpdatedDateObject: Date|null = null;
  public archivedAt: string|null = null;

  public permittedActions: APIPermittedActions[] = [];

  // Define new UI view model structure
  public constructor(apiContactDetail: APIContactDetailInterface|null = null) {
    if (apiContactDetail) this.updateFromAPIResponse(apiContactDetail);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiContactDetail: APIContactDetailInterface): void {
    this.apiSource = apiContactDetail;
    this.id = apiContactDetail._id?.$oid || null;

    this.categoryType = apiContactDetail.type || null;
    this.formatCode = apiContactDetail.format_code || null;

    this.lastUpdated = apiContactDetail.updated_at || null;
    this.lastUpdatedDateObject = apiContactDetail.updated_at ? new Date(apiContactDetail.updated_at) : null;
    this.archivedAt = apiContactDetail.archived_at || null;

    switch(this.formatCode) {
      case CONTACT_DETAIL_FORMAT.PatientPhone:
        this.phoneNumber = apiContactDetail.phone_number || null;
        this.preferred = this.isAPIContactDetailPreferred(apiContactDetail);
        this.okayTo = apiContactDetail.ok_to || [];
        this.comments = apiContactDetail.comments || null;
        break;
      case CONTACT_DETAIL_FORMAT.PatientEmail:
        this.email = apiContactDetail.email || null;
        this.preferred = this.isAPIContactDetailPreferred(apiContactDetail);
        this.comments = apiContactDetail.comments || null;
        break;
    }
  }

  /**
   * Build boolean preferred for UI view model
   * @param preferredRank numeric preferred rank
   * @returns {boolean} true if rank 1, false otherwise
   */
  public isAPIContactDetailPreferred(apiContactDetail: APIContactDetailInterface): boolean {
    if (!apiContactDetail) return false;

    return apiContactDetail.preferred === 1;
  }

  /**
   * Extract numeric preferred for API request payload
   * @param preferredChecked boolean preferred
   * @returns {number} 1 if preferred is checked, 2 otherwise
   */
  public preferredAsRank(preferredChecked: boolean): number {
    return preferredChecked ? 1 : 2;
  }

  /**
   * Format phone number for display purposes
   * @param apiPhoneNumber phone number string from API
   * @returns {number} phone number in UI display format
   */
  public parseDisplayPhoneNumber(apiPhoneNumber: string|null): string|null {
    if (!apiPhoneNumber) return null;

    const parts: string[]|null = apiPhoneNumber.match(/\+1(\d\d\d)(\d\d\d)(\d\d\d\d)/);
    if (!parts) return apiPhoneNumber;

    return `+1 (${parts[1]}) ${parts[2]}-${parts[3]}`;
  }

  // Build a copy of the view model (e.g. for editState, v-model, etc.)
  public copyViewModel() {
    return new UIContactDetail(this.apiSource);
  }

  // Derive 'Details' for table based on format
  get details(): string|null {
    switch(this.formatCode) {
      case CONTACT_DETAIL_FORMAT.PatientPhone:
        return this.parseDisplayPhoneNumber(this.phoneNumber);
      case CONTACT_DETAIL_FORMAT.PatientEmail:
        return this.email;
      default:
        return null;
    }
  }

  /**
   * Derive 'Call' for table Yes/No indicator
   *
   * @returns {boolean|true} explicit true/false for phone, null otherwise
   */
  get okayToCall(): boolean|null {
    const permitted: string[] = this.okayTo || [];

    switch(this.formatCode) {
      case CONTACT_DETAIL_FORMAT.PatientPhone:
        return permitted.includes(CONTACT_DETAIL_OKAY_TO.Call);
      default:
        return null;
    }
  }

  /**
   * Derive 'Voicemail' for table Yes/No indicator
   *
   * @returns {boolean|true} explicit true/false for phone, null otherwise
   */
  get okayToVoicemail(): boolean|null {
    const permitted: string[] = this.okayTo || [];

    switch(this.formatCode) {
      case CONTACT_DETAIL_FORMAT.PatientPhone:
        return permitted.includes(CONTACT_DETAIL_OKAY_TO.Voicemail);
      default:
        return null;
    }
  }

  /**
   * Derive 'Text' for table Yes/No indicator
   *
   * @returns {boolean|true} explicit true/false for phone, null otherwise
   */
  get okayToText(): boolean|null {
    const permitted: string[] = this.okayTo || [];

    switch(this.formatCode) {
      case CONTACT_DETAIL_FORMAT.PatientPhone:
        return permitted.includes(CONTACT_DETAIL_OKAY_TO.Text);
      default:
        return null;
    }
  }

  // Derive 'Status' indicator based on whether or not the record is archived
  get status(): string {
    return this.archivedAt ? 'status.archived' : 'status.active';
  }

  // Is this view model Archived?
  get isArchived(): boolean {
    return !!this.archivedAt;
  }

  // Is this an unsaved New Contact Detail?
  get isNew(): boolean {
    return !this.id;
  }

  // Load permitted actions (update, archive, restore)
  // NOTE: this checks permitted_actions in 'show' instead of 'edit' to handle archived scenario
  public async loadPermittedActions(recipientId: string): Promise<void> {
    if (!this.id) return;

    const ep = APIRoute(EP.recipients.patient_profile.contactDetails.show, [[':recipient_id', recipientId], [':id', this.id as string]]);
    axios.get(ep).then((response: any) => {
      this.permittedActions = response?.data?.permitted_actions || [];
    }).catch((error: unknown) => {
      this.permittedActions = [];
      console.warn('Unable to load permitted actions', error);
    });
  }

  // Save UI Contact Detail edit state to the backend
  public save(opts: { id: string|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      if (!recipientId) reject((new UIError('patient_contact_detail')));

      let method: any;
      let ep: string;
      if (opts.id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.patient_profile.contactDetails.update, [[':recipient_id', recipientId as string], [':id', opts.id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.patient_profile.contactDetails.create, [[':recipient_id', recipientId as string]]);
      }
      const payload = {
        contact_detail: this.extractPatch()
      };
      method(ep, payload).then((response: APISaveContactDetailResponse) => {
        if (response.data.errors) {
          reject((new UIError('patient_contact_detail', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          opts.recipient.load({ reload: true }).then(() => {
            resolve((new UISuccess(response)).getSaveResult());
          });
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('patient_contact_detail', errorResponse)).errorResult);
      });
    });
  }

  // Generate request payload parameters to provide to API as part of Create or Update activity
  private extractPatch(): APIContactDetailInterface {
    const result = {
      type: this.categoryType,
    };

    switch (this.formatCode) {
      case CONTACT_DETAIL_FORMAT.PatientPhone:
        Object.assign(result, {
          phone_number: sanitizePhoneNumber(this.phoneNumber) || null,
          email: null,
          preferred: this.preferredAsRank(this.preferred),
          ok_to: this.okayTo || [],
          comments: this.comments || null,
        });
        break;
      case CONTACT_DETAIL_FORMAT.PatientEmail:
        Object.assign(result, {
          phone_number: null,
          email: this.email || null,
          preferred: this.preferredAsRank(this.preferred),
          ok_to: [],
          comments: this.comments || null,
        });
        break;
    }

    return result;
  }

  // Process archive activity
  public archive(opts: { id: string|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      const selectedId = opts.id as string;
      if (!recipientId || !selectedId) reject((new UIError('patient_contact_detail')));

      const ep = APIRoute(EP.recipients.patient_profile.contactDetails.archive, [[':recipient_id', recipientId as string], [':id', selectedId]]);
      axios.put(ep, {}).then((response: APISaveContactDetailResponse) => {
        if (response.data.errors) {
          reject((new UIError('patient_contact_detail', response)).errorResult);
        } else {
          opts.recipient.loadContactDetails();
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('patient_contact_detail', errorResponse)).errorResult);
      });
    });
  }

  // Process restore activity
  public restore(opts: { id: string|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      const selectedId = opts.id as string;
      if (!recipientId || !selectedId) reject((new UIError('patient_contact_detail')));

      const ep = APIRoute(EP.recipients.patient_profile.contactDetails.restore, [[':recipient_id', recipientId as string], [':id', selectedId]]);
      axios.put(ep, {}).then((response: APISaveContactDetailResponse) => {
        if (response.data.errors) {
          reject((new UIError('patient_contact_detail', response)).errorResult);
        } else {
          opts.recipient.loadContactDetails();
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('patient_contact_detail', errorResponse)).errorResult);
      });
    });
  }
}
