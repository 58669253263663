<template>
  <card-section
    section-id="donor-allocation-eoffer"
    :lookupsToLoad="lookupsToLoad"
  >
    <template v-slot:header>
      <span class='text-flat'> {{ $t('eoffer_responses') }} </span>
    </template>
    <template v-slot:body>
      <template v-if="isLoadingAllocation">
        <div class="loading-data w-100" style="padding: 2rem 2rem; text-align: center; opacity: 50%;">
          <font-awesome-icon class="fa-5x fa-spin" :icon="['far', 'spinner-third']" />
        </div>
      </template>
      <template v-if="!isLoadingAllocations && allocation && !isLoadingAllocation && !isInitializing">     
        <sub-section
          v-if="allowedAllocationReport"
          :title="$t('allocation_controls')"
          sub-section-id="allocation-controls"
          :save-button="false"
        >
          <template v-slot:contents v-if="editState">
            <fieldset :disabled="isLoadingAllocation">
              <nav class="nav action-row mt-0">
                <div class="dropdown">
                  <button
                    type="button"
                    data-toggle="dropdown"
                    :disabled="isLoadingAllocation || isCreatingAllocation || isGeneratingAllocationReport"

                    class="btn btn-wide btn-lg btn-primary btn-sm mt-2 mr-2 dropdown-toggle"
                    v-if="allowedAllocationReport"
                  >
                    {{ allocationReportButtonText }}
                    <span class="pl-2" v-if="isGeneratingAllocationReport">
                      <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" />
                    </span>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item"
                      v-for="(row, idx) in allowedAllocationReportHospitals"
                      :key="idx"
                      @click="downloadAllocationReport(row.code)"
                    >
                      {{row.value}}
                    </a>
                  </div>
                </div>
              </nav>
            </fieldset>
          </template>
        </sub-section>
        <template v-if="showAllocationComponents">
          <allocation-details ref="allocationDetails"/>
          <donor-checklist
            v-if="canReadChecklists"
            :canSave="false"
          />
          <allocation-offer-response 
            ref="allocationOffers" 
            v-if="allocation"
            :combined="true"
            />
        </template>
      </template>
    </template>
  </card-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/_AllocationControls.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Watch, Prop } from 'vue-facing-decorator';
import { Allocation, Allocations, AllocationRecipient } from '@/store/allocations/types';
import AllocationOfferResponse from '@/components/allocations/_AllocationOfferResponse.vue';
import SubSection from '@/components/shared/SubSection.vue';
import AllocationControls, { AllocationControlState } from '@/components/allocations/_AllocationControls.vue';
import { EP } from '@/api-endpoints';
import DonorChecklist from '@/components/allocations/_DonorChecklist.vue';
import AllocationDetails from '@/components/allocations/_AllocationDetails.vue';
import { HospitalOption, ACTIVE_REGION_TRANSPLANT_PROGRAM } from '@/store/hospitals/types';
import { User } from '@/store/users/types';

@Component({
  components: {
    CardSection,
    SubSection,
    AllocationControls,
    AllocationOfferResponse,
    DonorChecklist,
    AllocationDetails
  }
})
export default class CoordinatorOffers extends Vue {
  @State(state => state.pageState.currentPage.allocations) editState!: AllocationControlState;
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @State(state => state.allocations.selected) private allocation!: Allocation|undefined;
  @State(state => state.allocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.allocations.isLoadingAllocations) private isLoadingAllocations!: boolean;
  @State(state => state.allocations.isGeneratingAllocationReport) private isGeneratingAllocationReport!: boolean;
  @State(state => state.allocations.isCreatingAllocation) private isCreatingAllocation!: boolean;
  @State(state => state.allocations.allAllocations) private allAllocations!: any;
  @State(state => state.users.user) user!: User;

  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string|undefined;
  @Getter('activeAllocations', { namespace: 'allocations' }) private activeAllocations!: Allocations[];
  @Getter('findAllocations', { namespace: 'allocations' }) private findAllocations!: (activeAllocations: Allocations[], organCode: string, option?: string, doubleKidney?: boolean) => any;
  @Getter('findAllocationForPage', { namespace: 'allocations' }) private findAllocationForPage!: (activeAllocations: Allocations[], organCode: string, option?: string, doubleKidney?: boolean) => any;
  @Getter('allPrimaryBackupOffers', { namespace: 'allocations' }) private allPrimaryBackupOffers!: AllocationRecipient[];
  @Getter('getUserHospitalIds', { namespace: 'users' }) private getUserHospitalIds!: string[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('isSurgicalRecoveryCoordinator', { namespace: 'users' }) private isSurgicalRecoveryCoordinator!: boolean;
  @Getter('isQA', { namespace: 'users' }) private isQA!: boolean;
  @Getter('hospitalOptionsOntarioAll', { namespace: 'hospitals' }) hospitalOptionsOntarioAll!: HospitalOption[];

  @Prop({ default: false }) canSave!: boolean;

  public lookupsToLoad = ['cause_of_death_donor', 'offer_types', 'offer_responses', 'gender', 'attachment_category'];

  public isInitializing = false;

  /**
   * determines whether to show allocation components (details, checklists, offer response table)
   *
   * @returns {boolean} true/false
   */
  get showAllocationComponents(): boolean {
    return this.isSurgicalRecoveryCoordinator || this.isQA;
  }

  /**
   * checks for read permission to checklists
   * Note: hla_tech are not allowed to see the checklists section, only view the list of recommendations
   *
   * @returns {boolean} if is allowed to see checklists
   */
  get canReadChecklists(): boolean {
    return this.checkAllowed(EP.deceasedDonors.allocations.checklist.show, "GET");
  }

  /**
   * Return true if we the user can see the Allocation Report
   *
   * @returns {boolean} true if the user has access
   */
  get allowedAllocationReport(): boolean | undefined {
    return this.allocation && this.checkAllowed("/reports/allocation_report", "POST");
  }

  /**
   * Call the API to generate a download link and forcibly click it
   * for the user.  This link needs to generate every time because
   * the url expires quickly.
   */
  public downloadAllocationReport(hospital_selected: string|undefined): void {
    if (this.allocation) {
      const payload = {
        hospital: hospital_selected,
        allocation_client_id: this.allocation.client_id,
        organ_code: this.allocation.organ_code,
      };
      // Clear the reportLink url
      this.editState.reportLink = '';
      // Attempt to generate an Allocation Report and download it
      this.$store.dispatch('allocations/generateAllocationReport', { payload }).then((url: any) => {
        // Update the report url
        this.editState.reportLink = url;
        const previousLink = document.getElementById('download-allocation-report');
        // Remove the previous link
        if (previousLink) previousLink.remove();
        // Create and add link to the body
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.setAttribute('target', '_blank');
        downloadLink.setAttribute('id', 'download-allocation-report');
        document.body.appendChild(downloadLink);
        // Click to download in a new tab
        downloadLink.click();
      }).catch((error: any) => {
        console.warn('ERROR: unable to download allocation report', error);
        alert(this.$t('allocation_report_error').toString());
      });
    }
  }

  /**
   * Return text for the allocation report button
   *
   * @returns {string} text to display for the button
   */
  get allocationReportButtonText(): string {
    return this.isGeneratingAllocationReport ? this.$t('allocation_report_create').toString() : this.$t('allocation_report').toString();
  }

  /**
   * Return the organ code
   *
   * Get the organ_code param from the url.
   *
   * @returns {string} organ code
   */
  get organCode(): string {
    return this.$route.params.organ_code.toString() || '';
  }

  // Get the organ allocation option param from the url
  get organAllocationOption(): string {
    return this.$route.params.option as string;
  }

  // Initialize page on mount
  public mounted(): void {
    this.$store.dispatch('hospitals/load', ACTIVE_REGION_TRANSPLANT_PROGRAM).then(() => {
      this.initializeAllocation();
    });
  }

  get allowedAllocationReportHospitals(): HospitalOption[]|undefined {
    const hospitals = this.hospitalOptionsOntarioAll || [];
    let allowedHospitals = this.user.all_hospitals ? [{ code: '', value: 'All'}] : [];
    const filterBy = this.user.hospital_organ_codes || {};

    hospitals.forEach((hospital: any) => {
      if(Object.keys(filterBy).includes(hospital.code)){
        allowedHospitals.push(hospital);
      }
    });

    return allowedHospitals;
  }

  // Build empty allocation pageState when the organ_code param changes
  public buildAllocationPageState() {
    // Fetch our donor record to make sure we have the most up to date information
    this.$store.dispatch('deceasedDonors/get', this.clientId);
    // Commit our inital pageState
    this.$store.commit('pageState/set', {
      pageKey: 'allocations',
      value: {
        allocationErrorMessage: '',
        reportLink: '',
      }
    });
    this.$store.dispatch('utilities/scrollBehavior');
  }

  /**
   * Vue component hook, watches for when data changes and runs a function.
   *
   * Listen for changes to the organ_code param.  When it changes clear the allocation.
   *
   * @listens $route.params.organ_code
   */
  @Watch('$route.params.organ_code')
  @Watch('isLoadingAllocations')
  // TODO: TECH_DEBT: remove watch isLoadingAllocations
  // TODO: tp-14489 for now we have added a watch on isLoadingAllocations, because once allocations are loaded component doesn't know about it.
  public initializeAllocation() {
    if(this.isInitializing) return;
    if(this.isLoadingAllocations) {
      return;
    }
    this.isInitializing = true;
    this.getAllocation();
  }

  // Clear allocation if organ allocation 'option' changes
  @Watch('$route.params.option')
  onAllocationRegionChange() {
    if(!this.$route.hash){
     this.initializeAllocation();
    }
  }

  // PRIVATE

  // Get all active Allocations
  private getActiveAllocations() {
    this.$store.commit('allocations/startLoading');
    this.$store.dispatch('allocations/getAllocations', { clientId: this.clientId, state: 'active' }).then((response) => {
      // if there is an organ_code get the allocation
      if (this.organCode !== '') {
        this.getAllocation();
      }
    }).catch(() => {
      this.$store.commit('allocations/stopLoading');
    });
    this.buildAllocationPageState();
  }

  // Get active Allocation based on organ_code
  private getAllocation() {
    this.$store.commit('allocations/clearAllocation');
    // TODO: TECH_DEBT: Currently donor details is called everytime a checklist is called, so instead of that we need to get 
    // TODO: rh_indicator,height,weight from allocation service, so that we can avoid calling donordetails
    // TODO: Remove clearDonorDetails once we have above fields from allocation service
    this.$store.commit('allocations/clearDonorDetails');
    const allocations = this.findAllocations(this.allAllocations, this.organCode, this.organAllocationOption, this.donor.indicators?.double_kidney);
    const allocationForPage = this.findAllocationForPage(allocations, this.organCode, this.organAllocationOption, this.donor.indicators?.double_kidney);
    if (allocationForPage) {
      const allocationPageId = allocationForPage._id;
      this.$store.dispatch('allocations/getAllocation', { clientId: this.clientId, organCode: this.organCode, allocationId: allocationPageId }).then(() => {
        this.isInitializing = false;
        this.buildAllocationPageState();
      });
    } else {
      this.isInitializing = false;
    }
  }
}
</script>
