import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toHandlers as _toHandlers, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "mobile-spacing-wrapper" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id", "title", "readonly"]
const _hoisted_5 = {
  key: 0,
  value: ""
}
const _hoisted_6 = ["value", "disabled"]
const _hoisted_7 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_asterisk = _resolveComponent("validation-asterisk")!
  const _component_vue_tags_input = _resolveComponent("vue-tags-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!

  return (_openBlock(), _createBlock(_component_validation_provider, {
    ref: "provider",
    rules: _ctx.formRules,
    name: _ctx.selectId,
    label: _ctx.name,
    vid: _ctx.validationId ? _ctx.validationId : _ctx.selectId,
    modelValue: _ctx.validatedValue,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.validatedValue) = $event))
  }, {
    default: _withCtx(({ field, errors }) => [
      _createElementVNode("label", {
        for: _ctx.selectId,
        class: _normalizeClass({ 'sr-only': _ctx.hideLabel })
      }, [
        _createTextVNode(_toDisplayString(_ctx.label || _ctx.name) + " ", 1),
        (!_ctx.disabled)
          ? (_openBlock(), _createBlock(_component_validation_asterisk, {
              key: 0,
              rules: _ctx.formRules,
              crossValues: _ctx.crossValues,
              ruleKey: _ctx.ruleKey
            }, null, 8, ["rules", "crossValues", "ruleKey"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "link")
      ], 10, _hoisted_1),
      _createElementVNode("span", _hoisted_2, [
        (_ctx.isMasked)
          ? (_openBlock(), _createElementBlock("input", _mergeProps({
              key: 0,
              type: "text",
              class: _ctx.getClassObject(errors),
              id: _ctx.selectId,
              tabindex: -1
            }, field), null, 16, _hoisted_3))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (!_ctx.multiple)
                ? (_openBlock(), _createElementBlock("select", _mergeProps({
                    key: 0,
                    class: _ctx.getClassObject(errors),
                    id: _ctx.selectId,
                    title: _ctx.selectTitleValue
                  }, field, {
                    readonly: _ctx.isReadOnly(_ctx.readonly || _ctx.disabled)
                  }, _toHandlers(_ctx.selectEvents(), true)), [
                    (!_ctx.disabled && _ctx.nullable)
                      ? (_openBlock(), _createElementBlock("option", _hoisted_5, _toDisplayString(_ctx.undefinedText), 1))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectFilteredOptions, (o, idx) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: `${_ctx.selectId}-${o[_ctx.valueKey]}-${idx}`,
                        value: o[_ctx.valueKey],
                        disabled: _ctx.isDisabledOption(o)
                      }, _toDisplayString(o[_ctx.textKey]), 9, _hoisted_6))
                    }), 128))
                  ], 16, _hoisted_4))
                : (_openBlock(), _createBlock(_component_vue_tags_input, {
                    key: 1,
                    id: _ctx.selectId,
                    placeholder: _ctx.placeholder || (_ctx.undefinedPlaceholder ? _ctx.undefinedText : null),
                    "add-on-key": [13, ' '],
                    "allow-edit-tags": false,
                    "autocomplete-min-length": 0,
                    "add-only-from-autocomplete": true,
                    separators: [' '],
                    modelValue: _ctx.tag,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tag) = $event)),
                    validation: _ctx.getTagValidationRule(),
                    "autocomplete-items": _ctx.getAutocompleteOptions,
                    onTagsChanged: _cache[1] || (_cache[1] = (updatedTagObjects) => _ctx.updateVModel(updatedTagObjects)),
                    onBeforeAddingTag: _cache[2] || (_cache[2] = (event) => _ctx.beforeAddingTag(event)),
                    disabled: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled),
                    tags: _ctx.tags,
                    class: _normalizeClass(_ctx.getClassObject(errors)),
                    tagClass: "invalid-tag",
                    "max-tags": _ctx.maxTags,
                    onBlur: _ctx.handleBlur
                  }, null, 8, ["id", "placeholder", "modelValue", "validation", "autocomplete-items", "disabled", "tags", "class", "max-tags", "onBlur"]))
            ], 64)),
        (_ctx.isLoadingSelectionValues)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 2,
              style: {"float":"right","top":"-26px","position":"relative","right":"24px","margin-bottom":"-26px"},
              icon: ['far', 'spinner-third'],
              "fixed-width": "",
              spin: "",
              "aria-hidden": "true"
            }))
          : _createCommentVNode("", true),
        (errors[0] && !_ctx.disabled)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: "invalid-feedback",
              id: `${_ctx.selectId}-error`
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['far', 'exclamation-circle'],
                "fixed-width": ""
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
            ], 8, _hoisted_7))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["rules", "name", "label", "vid", "modelValue"]))
}