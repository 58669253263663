export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "change_status_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change of Status Report"])},
        "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
        "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital Name"])},
        "organ_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Code"])}
      },
      "fr": {
        "change_status_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change of Status Report"])},
        "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
        "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital Name"])},
        "organ_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Code"])}
      }
    }
  })
}
