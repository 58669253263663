import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_list = _resolveComponent("user-list")!
  const _component_login_container = _resolveComponent("login-container")!

  return (_openBlock(), _createBlock(_component_login_container, null, {
    content: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
          ], 64))
        : (_openBlock(), _createBlock(_component_user_list, {
            key: 1,
            fusionAuth: true,
            userToken: _ctx.userToken
          }, null, 8, ["userToken"]))
    ]),
    _: 1
  }))
}