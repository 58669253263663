<template>
  <div>
    <span class="col-md-2">
      <label :for="inputId">
        {{ label || name }}
      </label>
    </span>
    <div 
      :id="inputId"
      :name="label"
      class="col-12 large-checklist-confirmation-display"
      >{{ modelValue }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({
  components: {
  }
})
export default class ChecklistTextArea extends Vue {
  // V-model
  @Prop() modelValue!: string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) name!: string; // Field name, also used as the label

  // Optional properties
  @Prop({ default: null }) label!: string; // Alternate Label property
}
</script>
