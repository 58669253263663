import { APIAddressInterface } from '@/store/recipients/types';
import { CountryValue, Country, Province } from '@/store/lookups/types';
import { APIPermittedActions, SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APISaveAddressResponse } from '@/types';
import { UIRecipient } from '@/UIModels/recipient';

export class UIAddress {
  public apiSource: APIAddressInterface|null = null;
  public id: string|null = null;

  public categoryType: string|null = null;
  public countryCode: string|null = null;
  public provinceCode: string|null = null;
  public stateCode: string|null = null;
  public countryOther: string|null = null;
  public streetAddress: string|null = null;
  public city: string|null = null;
  public postalCode: string|null = null;
  public zipCode: string|null = null;
  public urbanization: string|null = null;

  public lastUpdated: string|null = null;
  public lastUpdatedDateObject: Date|null = null;
  public archivedAt: string|null = null;

  public permittedActions: APIPermittedActions[] = [];

  // Define new UI view model structure
  public constructor(apiAddress: APIAddressInterface|null = null) {
    if (apiAddress) this.updateFromAPIResponse(apiAddress);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiAddress: APIAddressInterface) {
    this.apiSource = apiAddress;
    this.id = apiAddress._id?.$oid || null;

    this.categoryType = apiAddress.type || null;
    this.countryCode = apiAddress.country_code || null;

    this.streetAddress = apiAddress.street || null;
    this.city = apiAddress.city || null;
    this.lastUpdated = apiAddress.updated_at || null;
    this.lastUpdatedDateObject = apiAddress.updated_at ? new Date(apiAddress.updated_at) : null;
    this.archivedAt = apiAddress.archived_at || null;

    switch(this.countryCode) {
      case CountryValue.Canada:
        this.provinceCode = apiAddress.province_code || null;
        this.postalCode = apiAddress.postal_code || null;
        break;
      case CountryValue.USA:
        this.stateCode = apiAddress.province_code || null;
        this.zipCode = apiAddress.postal_code || null;
        this.urbanization = apiAddress.urbanization || null;
        break;
      case CountryValue.Other:
        this.countryOther = apiAddress.country_other || null;
        break;
    }
  }

  // Build a copy of the address for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModel() {
    return new UIAddress(this.apiSource);
  }

  // Derive concatenated 'Address' description by combining multiple field values
  public description(countryLookup: Country[]): string {
    if (!countryLookup) return '-';

    const descriptionParts = [
      this.streetAddress,
      this.urbanization,
      this.city,
    ];

    switch (this.countryCode) {
      // NOTE: if 'Other' country is selected, show the free-form string value instead of country code
      case CountryValue.Other:
        descriptionParts.push(this.countryOther);
        break;
      default:
        const countryLookupEntry = countryLookup.find((country: Country) => { return country.code === this.countryCode; });
        if (countryLookupEntry) {
          const provinceLookup = countryLookupEntry.sub_tables.province || [];
          const provinceLookupEntry = provinceLookup.find((province: Province) => { return province.code === (this.provinceCode || this.stateCode); });
          if (provinceLookupEntry) descriptionParts.push(provinceLookupEntry.value);
          if (countryLookupEntry) descriptionParts.push(countryLookupEntry.value);
        }
        break;
    }

    if (this.postalCode || this.zipCode) descriptionParts.push(this.postalCode || this.zipCode);
  
    if (descriptionParts.length === 0) return '-';
  
    return descriptionParts.filter((item?: string|null) => { return item != null; }).join(', ');
  }

  // Derive 'Status' indicator based on whether or not the record is archived
  get status(): string {
    return this.archivedAt ? 'status.archived' : 'status.active';
  }

  // Postal Code is only for Canada
  get showPostalCode(): boolean {
    return this.countryCode === CountryValue.Canada;
  }

  // ZIP Code is only for USA
  get showZipCode(): boolean {
    return this.countryCode === CountryValue.USA;
  }

  // Urbanization is only for Puerto Rico
  get showUrbanization(): boolean {
    return this.stateCode === 'PR';
  }

  // Is this address Archived?
  get isArchived(): boolean {
    return !!this.archivedAt;
  }

  // Is this an unsaved New Address?
  get isNew(): boolean {
    return !this.id;
  }

  // Load permitted actions (update, archive, restore)
  // NOTE: this checks permitted_actions in 'show' instead of 'edit' to handle archived scenario
  public async loadPermittedActions(recipientId: string): Promise<void> {
    if (!this.id) return;

    const ep = APIRoute(EP.recipients.patient_profile.addresses.show, [[':recipient_id', recipientId], [':id', this.id as string]]);
    axios.get(ep).then((response: any) => {
      this.permittedActions = response?.data?.permitted_actions || [];
    }).catch((error: unknown) => {
      this.permittedActions = [];
      console.warn('Unable to load permitted actions', error);
    });
  }

  // Save UI Address edit state to the backend
  public save(opts: { id: string|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      if (!recipientId) reject((new UIError('patient_address')));

      let method: any;
      let ep: string;
      if (opts.id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.patient_profile.addresses.update, [[':recipient_id', recipientId as string], [':id', opts.id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.patient_profile.addresses.create, [[':recipient_id', recipientId as string]]);
      }
      const payload = {
        address: this.extractPatch()
      };
      method(ep, payload).then((response: APISaveAddressResponse) => {
        if (response.data.errors) {
          reject((new UIError('patient_address', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          opts.recipient.load({ reload: true }).then(() => {
            resolve((new UISuccess(response)).getSaveResult());
          });
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('patient_address', errorResponse)).errorResult);
      });
    });
  }

  // Generate request payload parameters to provide to API as part of Create or Update activity
  private extractPatch(): APIAddressInterface {
    const result = {
      type: this.categoryType,
      country_code: this.countryCode || null,
      country_other: this.countryOther || null,
      street: this.streetAddress || null,
      city: this.city || null,
    };

    switch (this.countryCode) {
      case CountryValue.Canada:
        Object.assign(result, {
          country_other: null,
          province_code: this.provinceCode || null,
          urbanization: null,
          postal_code: this.postalCode || null,
        });
        break;
      case CountryValue.USA:
        Object.assign(result, {
          country_other: null,
          province_code: this.stateCode || null,
          urbanization: this.showUrbanization ? this.urbanization || null : null,
          postal_code: this.zipCode || null,
        });
        break;
      case CountryValue.Other:
        Object.assign(result, {
          country_other: this.countryOther || null,
          province_code: null,
          urbanization: null,
          postal_code: null,
        });
        break;
    }

    return result;
  }

  // Process archive activity
  public archive(opts: { selected: UIAddress|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      const selectedId = opts.selected?.id as string;
      if (!recipientId || !selectedId) reject((new UIError('patient_address')));

      const ep = APIRoute(EP.recipients.patient_profile.addresses.archive, [[':recipient_id', recipientId as string], [':id', selectedId]]);
      axios.put(ep, {}).then((response: APISaveAddressResponse) => {
        if (response.data.errors) {
          reject((new UIError('patient_address', response)).errorResult);
        } else {
          opts.recipient.loadAddresses();
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('patient_address', errorResponse)).errorResult);
      });
    });
  }

  // Process restore activity
  public restore(opts: { selected: UIAddress|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      const selectedId = opts.selected?.id as string;
      if (!recipientId || !selectedId) reject((new UIError('patient_address')));

      const ep = APIRoute(EP.recipients.patient_profile.addresses.restore, [[':recipient_id', recipientId as string], [':id', selectedId]]);
      axios.put(ep, {}).then((response: APISaveAddressResponse) => {
        if (response.data.errors) {
          reject((new UIError('patient_address', response)).errorResult);
        } else {
          opts.recipient.loadAddresses();
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('patient_address', errorResponse)).errorResult);
      });
    });
  }
}

export enum AddressFormat {
  USAOther = "usa_other",
  NorthAmerica = "north_america",
  CanadaOther = "canada_other",
  CanadaOnly = "canada_only",
  USAOnly = "usa_only",
  USAStatesOther = "usa_states_other",
}
