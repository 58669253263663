import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
    class: _normalizeClass([{ 'tooltip-form-spacer': _ctx.isFormELement}, "tooltip-toggle"])
  }, [
    _createVNode(_component_font_awesome_icon, {
      class: "text-grey",
      icon: ['far', 'exclamation-circle'],
      "fixed-width": ""
    })
  ], 2)), [
    [_directive_tooltip, _ctx.toolTipText],
    [
      _directive_tooltip,
      _ctx.toolTipText,
      void 0,
      { focus: true }
    ]
  ])
}