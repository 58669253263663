<template>
  <div class="content-wrap">
    <div class="container-fluid">
      <form>
        <div class="row">
          <div class="col-sm-3 mb-1">
            <span class="skeleton-box w-50" />
            <span class="skeleton-box w-100" />
            <span class="skeleton-box w-75" />
          </div>
        </div>
        <div class="hr-break" />
        <div class="page-content p-0">
          <div class="form-row">
            <div class="standard-form-group">
            <span class="skeleton-box w-50" />
              <span class="skeleton-box w-100" />
            </div>
            <div class="standard-form-group">
            <span class="skeleton-box w-50" />
              <span class="skeleton-box w-100" />
            </div>
            <div class="standard-form-group">
            <span class="skeleton-box w-50" />
              <span class="skeleton-box w-100" />
            </div>
            <div class="standard-form-group">
            <span class="skeleton-box w-50" />
              <span class="skeleton-box w-100" />
            </div>
          </div>
        </div>
        <div class="form-row skeleton-padding-text">
          <div class="column col-sm-2 col-lg-1 col-xl-1">
            <span class="skeleton-box w-50" />
            <span class="skeleton-box w-100" />
          </div>
          <div class="column col-sm-2 col-lg-1 col-xl-1">
            <span class="skeleton-box w-50" />
            <span class="skeleton-box w-100" />
          </div>
          <div class="column col-sm-2 col-lg-1 col-xl-1">
            <span class="skeleton-box w-50" />
            <span class="skeleton-box w-100" />
          </div>
          <div class="column col-sm-2 col-lg-1 col-xl-1">
            <span class="skeleton-box w-50" />
            <span class="skeleton-box w-100" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-12">
            <nav class="action-row mb-3">
              <span class="skeleton-box skeleton-button-left" />
            </nav>
          </div>
        </div>
        <hr />
        <div class="card summary-card shadow-none skeleton-padding">
          <div class="row">
            <div class="col-sm-6">
              <span class="skeleton-box w-100" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-inline action-row mb-3">
              <span class="skeleton-box skeleton-button-left" /><span class="ml-3 skeleton-box skeleton-button-left" />
            </div>
          </div>
        </div>
      </form>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <div class="action-row mb-0">
          <span class="skeleton-box skeleton-button-left" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-for="index in 8" :key="index" class="loading_row_container">
          <div class="loading_row"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class LoadingReportView extends Vue {}
</script>
