import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_3 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_6 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_7 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_8 = {
  key: 3,
  class: "standard-form-group"
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = {
  key: 0,
  class: "standard-form-group-large"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_fields = _resolveComponent("loading-fields")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_boolean_radio_input = _resolveComponent("boolean-radio-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "recipient-death",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[9] || (_cache[9] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('title')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        title: _ctx.$t('record_recipient_death'),
        "sub-section-id": "record-recipient-death"
      }, {
        contents: _withCtx(() => [
          (_ctx.isLoading || !_ctx.editState)
            ? (_openBlock(), _createBlock(_component_loading_fields, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_validation_observer, { ref: "validations" }, {
            default: _withCtx(() => [
              _createVNode(_component_form_layout, {
                "form-id": "recipientDeath-form",
                disabled: _ctx.newRecipient || !_ctx.canSave
              }, {
                contents: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    (_ctx.checkPropExists('recipient_death.death_date'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createVNode(_component_date_input, {
                            ruleKey: "recipient_death.death_date",
                            inputId: "recipientDeath-death_date",
                            name: _ctx.$t('death_date'),
                            modelValue: _ctx.editState.deathDate,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.deathDate) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.checkPropExists('recipient_death.estimated_death_date'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(_component_checkbox_input, {
                            ruleKey: "recipient_death.estimated_death_date",
                            "input-id": "recipientDeath-estimated",
                            labelName: _ctx.$t('estimated'),
                            label: _ctx.$t('yes'),
                            modelValue: _ctx.editState.estimated,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.estimated) = $event))
                          }, null, 8, ["labelName", "label", "modelValue"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.checkPropExists('recipient_death.cause_category_code'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_select_input, {
                            ruleKey: "recipient_death.cause_category_code",
                            selectId: "recipientDeath-death_category",
                            name: _ctx.$t('death_category'),
                            modelValue: _ctx.editState.causeOfDeathCategory,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.causeOfDeathCategory) = $event)),
                            options: _ctx.causeOfDeathCategoryLookup,
                            onChange: _ctx.clearCauseOfDeathType
                          }, null, 8, ["name", "modelValue", "options", "onChange"])
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.showCauseOfDeathTypeOther && _ctx.checkPropExists('recipient_death.cause_type_code'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_select_input, {
                            ruleKey: "recipient_death.cause_type_code",
                            disabled: _ctx.causeOfDeathTypeDisabled || _ctx.causeOfDeathTypeLookup.length === 0,
                            "select-id": "recipientDeath-death_type",
                            name: _ctx.$t('death_type'),
                            modelValue: _ctx.editState.causeOfDeathType,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.causeOfDeathType) = $event)),
                            options: _ctx.causeOfDeathTypeLookup
                          }, null, 8, ["disabled", "name", "modelValue", "options"])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showCauseOfDeathTypeOther && _ctx.checkPropExists('recipient_death.cause_type_other'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_text_input, {
                            ruleKey: "recipient_death.cause_type_other",
                            "input-id": "recipientDeath-death_type_other",
                            name: _ctx.$t('death_type_other'),
                            modelValue: _ctx.editState.causeOfDeathTypeOther,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.causeOfDeathTypeOther) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.checkPropExists('recipient_death.related_to_waittime'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_boolean_radio_input, {
                            ruleKey: "recipient_death.related_to_waittime",
                            "input-id": "recipientDeath-related_to_waittime",
                            labelName: _ctx.$t('related_to_waittime'),
                            acceptId: true,
                            declineId: false,
                            acceptLabel: _ctx.$t('yes'),
                            declineLabel: _ctx.$t('no'),
                            modelValue: _ctx.editState.relatedToWaitTime,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.relatedToWaitTime) = $event))
                          }, null, 8, ["labelName", "acceptLabel", "declineLabel", "modelValue"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    (_ctx.checkPropExists('recipient_death.comments'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_text_area_input, {
                            ruleKey: "recipient_death.comments",
                            inputId: "recipientDeath-comments",
                            name: _ctx.$t('comments'),
                            modelValue: _ctx.editState.comment,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.comment) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                save: _withCtx(() => [
                  (!_ctx.newRecipient && _ctx.canSave)
                    ? (_openBlock(), _createBlock(_component_save_toolbar, {
                        key: 0,
                        disabled: !_ctx.canSave,
                        ref: "saveRecipientDeath",
                        label: _ctx.$t('save_button'),
                        onSave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.savePatch())),
                        cancelButton: true,
                        onCancel: _cache[8] || (_cache[8] = ($event: any) => (_ctx.cancelPatch()))
                      }, null, 8, ["disabled", "label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}