import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { uniqueElements } from '@/utils';
import { HlaAntibodyTestKit } from '@/store/lookups/types';
import { LabsState, LabVirology, LabHLAAntibody, LabHLATypingRecipient, LabHlaTypingDonor, HlaTypingTag, HlaAntibodyTag, HlaAntibodyTestingMethod, LabHlaTypingEpitope, HlaAntibodyClass, HLAAntibodiesForm, HlaAntibodyData, HlaAntibodyDataForm, VirologyType } from '@/store/labs/types';
import { HlaEpitope } from '../lookups/types';
import { Laboratory } from '../laboratories/types';
import { parseDateUi } from '@/utilities/date-utils';

export const getters: GetterTree<LabsState, RootState> = {
  hlaTyping(state, getters): LabHLATypingRecipient|LabHlaTypingDonor|null {
    return state.hlaTyping || null;
  },
  virologies(state) {
    return state.virologies;
  },
  selectedVirology(state) {
    return state.selectedVirology;
  },
  newVirologyResult() {
    return (type: number): LabVirology => {
      const result = { _type: '', results: [] };
      switch(type) {
        case VirologyType.DeceasedDonor:
          Object.assign(result, { _type: 'virology_donor'});
          break;
        case VirologyType.LivingDonor:
          Object.assign(result, { _type: 'virology_living_donor'});
          break;
        default:
          Object.assign(result, { _type: 'virology_recipient'});
          break;
      }
      return result;
    };
  },
  /**
   * Get 'current' HLA antibody lab result, based on 'hlaAntibodies' in state loaded for recipient
   * 
   * @returns {LabHLAAntibody|null} latest HLA antibody lab result if there is one, null otherwise 
   */
  currentHlaAntibodyTest(state, getters, rootState, rootGetters): LabHLAAntibody|null {
    if (!state.hlaAntibodies || state.hlaAntibodies.length === 0) return null;

    // Note: API has already sorted this information, so all we need to do is get first element
    const current = (state.hlaAntibodies || [null])[0];
    return current;
  },
  newHlaAntibodies(state, getters, rootState, rootGetters){
    return(userLab: string): LabHLAAntibody => {
    const hla_lab = userLab;
    const result= {
      sample_code: undefined,
      sample_draw_date: undefined,
      hla_lab: hla_lab,
      lab_code: hla_lab,
      sample_tested_date:  undefined,
      comments: undefined,
      testing_method_default: HlaAntibodyTestingMethod.Sab,
      hla_class1_testing_method_code: HlaAntibodyTestingMethod.Sab,
      hla_class2_testing_method_code: HlaAntibodyTestingMethod.Sab,
      testing_kit_class1: undefined,
      testing_kit_class2: undefined,
      antibodies: undefined,
      invalidAntibodies: getters.buildNewAntibodiesForm,
      cpra_class1: undefined,
      cpra_class2: undefined,
      combined_cpra: undefined
    };
    return result;
    };
  },
  parseHlaTypingTag() {
    return (tagText: string): HlaTypingTag|undefined => {
      // Standardize text input
      const standardizedText = tagText.toUpperCase().replace(/W/g, 'w');
      // Match against regular expressions
      const ANTIGEN_PATTERN = /^(?:((?:[ABC]w?)|(?:D[PQR](?:[AB]\d?)?))\*?)?(?:(?:(?:(?:(\d{1,3})(?:\:?XX)?)|(?:(\d{2,3})(?:\:?(\d{2})))|(?:(\d{2}):(\d{3})))([A-Z])?(?:-(TBC))?)|(PRESENT|\*\*|--))$/;
      const antigenMatch = standardizedText.match(ANTIGEN_PATTERN);
      if (!antigenMatch) {
        // Invalid format
        return undefined;
      }
      // Identify regex capture groups
      const GENE_LOCUS = 1;
      const ALLELE_WHOLE = 2;
      const ALLELE_SPECIFIC = 3;
      const PROTEIN = 4;
      const ALLELE_LONG = 5;
      const PROTEIN_LONG = 6;
      const SUFFIX = 7;
      const TBC = 8;
      const OTHER = 9;
      // Extract meaningful information from capture groups
      const locus = antigenMatch[GENE_LOCUS];
      const rawAllele = antigenMatch[ALLELE_WHOLE] || antigenMatch[ALLELE_SPECIFIC] || antigenMatch[ALLELE_LONG];
      const protein = antigenMatch[PROTEIN] || antigenMatch[PROTEIN_LONG];
      const suffix = antigenMatch[SUFFIX];
      const rawTbc = antigenMatch[TBC];
      const other = antigenMatch[OTHER];
      // Standardize and parse
      const tbcBoolean = rawTbc != undefined && rawTbc.length > 0;
      const tbcText = tbcBoolean ? '-tbc' : undefined;
      // Sanitize serologic antigen for the only valid serologic letter suffix: "N" e.g. "1N"
      const suffixForAlleleGroup = suffix && suffix === 'N' ? suffix : '';
      // Sanitize most likely allele for valid molecular letter suffixes: "N", "P", and "G" 
      const suffixForAlleleSpecific = suffix && ['N', 'P', 'G'].includes(suffix) ? suffix : '';
      const molecularAllele = rawAllele && rawAllele.length === 1 ? `0${rawAllele}` : rawAllele;
      const serologicAllele = rawAllele && rawAllele.length === 2 && rawAllele[0] === '0' ? rawAllele[1] : rawAllele;
      // Generate standard string representations of the antigen and return the results
      const standardText = other == undefined ? `${locus || ''}${protein ? `${molecularAllele}${protein}` : serologicAllele}${suffix || ''}${tbcText || ''}` : `${other}${tbcText || ''}`;
      const standardAlleleGroupOnly = other == undefined ? `${molecularAllele}${suffixForAlleleGroup || ''}${tbcText  || ''}${other || ''}` : `${other}${tbcText || ''}`;
      const standardAlleleSpecific = other == undefined ? `${molecularAllele}:${protein || 'XX'}${suffix || ''}${tbcText || ''}${other || ''}` : `${other}${tbcText || ''}`;
      const isAlleleSpecific = protein && protein.length > 0 || false;
      // Format antigen allele as proper molecular (most likely allele) or serologic format, including letter suffixes
      const alleleFull = isAlleleSpecific ? `${molecularAllele}${protein}${suffixForAlleleSpecific}` : `${serologicAllele}${suffixForAlleleGroup}`;
      const standardWithoutTbc = other || alleleFull;
      const standardMostLikelyAllele = isAlleleSpecific ? standardAlleleSpecific : null;
      const result = {
        locus,
        allele: molecularAllele,
        protein,
        suffix,
        tbc: tbcBoolean,
        standardText,
        standardAlleleGroupOnly,
        standardAlleleSpecific,
        standardWithoutTbc,
        serologicAllele,
        standardMostLikelyAllele,
      };
      return result;
    };
  },
  /**
   * Extract the first quartile from an HLA Typing antigen string that has second quartile information.
   * E.g. returns "01" if the input is "01:02"
   * 
   * Note: returns low-res molecular if most likely allele is not specified
   *
   * @param mostLikelyAlleles string array of antigen strings with first and second quartiles
   * @returns {string[]} string array containing only the first quartiles
   */
  parseLowResValues(state, getters, rootState, rootGetters) {
    return (moleculars: string[], mostLikelyAlleles: string[]): string[] => {
      if (!mostLikelyAlleles || mostLikelyAlleles.length === 0) return moleculars;

      const result: string[] = [];
      mostLikelyAlleles.forEach((mostLikelyAllele: string) => {
        const antigen = getters.parseHlaTypingTag(mostLikelyAllele);
        if (antigen && antigen.standardAlleleGroupOnly) {
          result.push(antigen.standardAlleleGroupOnly);
        }
      });
      return result;
    };
  },
  parseHlaAntibodyEpitope(state, getters, rootState, rootGetters) {
    return (inputAntibody: string): string|null => {
      const possibleEpitopes: HlaEpitope[] = rootGetters['lookups/possibleEpitopes'] || [];
      const matchingEpitope: HlaEpitope|undefined = possibleEpitopes.find((epitopeLookupItem: HlaEpitope) => {
        const isDirectMatch = epitopeLookupItem.code === inputAntibody;
        const isAliasMatch = (epitopeLookupItem.ui_aliases || []).includes(inputAntibody);
        return isDirectMatch || isAliasMatch;
      });
      const result = matchingEpitope ? matchingEpitope.value || inputAntibody : null;
      return result;
    };
  },
  parseHlaMolecularLocus(state, getters, rootState, rootGetters) {
    return (rawLocus: string): string => {
      const locus = rawLocus.replace(/\*/, '');
      const locusLookup = rootGetters['lookups/hlaSerologicLociToMolecularMap'];
      const molecularLocus = locusLookup[locus];
      const result = molecularLocus || locus;
      return result;
    };
  },
  parseHlaSerologicLocus(state, getters, rootState, rootGetters) {
    return (rawLocus: string): string => {
      const locus = rawLocus.replace(/\*/, '');
      const locusLookup = rootGetters['lookups/hlaMolecularLociToSerologicMap'];
      const serologicLocus = locusLookup[locus];
      const result = serologicLocus || locus;
      return result;
    };
  },
  parseHlaMolecularAlleleGroup() {
    return (rawAlleleGroup: string): string => {
      return rawAlleleGroup && rawAlleleGroup.length === 1 ? `0${rawAlleleGroup}` : rawAlleleGroup;
    };
  },
  parseHlaSerologicAlleleGroup() {
    return (rawAlleleGroup: string): string => {
      return rawAlleleGroup && rawAlleleGroup.length === 2 && rawAlleleGroup[0] === '0' ? rawAlleleGroup[1] : rawAlleleGroup;
    };
  },
  parseHlaAntibodyTag(state, getters, rootState, rootGetters) {
    return (rawInput: string): HlaAntibodyTag => {
      // Setup default result
      const result: HlaAntibodyTag = { rawInput };
      // Standardize text input
      const standardInput = rawInput.toUpperCase().replace(/W/g, 'w');
      /**
       * Define a regular expression to match valid text formats. This pattern also extracts meaningful components of the
       * antibody (locus, gene, and allele) using capturing groups. The text must begin with an antibody locus, which is
       * one or more letters, optionally followed by an asterisk. The remainder of the text is covered by three cases:
       *
       * 1) Common: usually only a gene is entered, and it may be one or two digits. E.g. A01, A2, B13, B*27, CW*03
       *
       * 2) Specific: for allele-specific antibodies, both a gene and an allele are entered. The gene must be exactly two
       * digits, and the allele may be two or three digits. The gene and allele may optionally be separated by a colon.
       * E.g. A0104, B*1302, B39:05, CW*04:02 (Note that unlike in the Common case, single digit genes are not permitted)
       *
       * 3) DR103: the only allele-specific antibody that can be entered with a one-digit gene. E.g. DR103, DR*1:03
       */
      const antibodyPattern = '(?:([ABCD][PQR]?[AB]?w?(?:\\d?\\*)?))?:?(?:(?:(\\d{1,3})(\\d{2})?)|(?:(\\d{2,3}):(\\d{2,3}|XX)))';
      const tagPattern = `^${antibodyPattern}(?:[\\-/\\\\]${antibodyPattern})?,?$`;
      const tagRegex = new RegExp(tagPattern);
      enum RegexGroup {
        Locus = 1,
        AlleleGroup = 2,
        AlleleProtein = 3,
        AlleleGroupSeparated = 4,
        AlleleProteinSeparated = 5,
        BetaLocus = 6,
        BetaAlleleGroup = 7,
        BetaAlleleProtein = 8,
        BetaAlleleGroupSeparated = 9,
        BetaAlleleProteinSeparated = 10,
      }
      /**
       * Perform the regular expression matching operation for the input text using the regular expression defined above.
       * This will store the meaningful components of the antibody (locus, gene, and allele) in different capturing
       * groups depending on the case (Common, Specific, or DR103), or return undefined if the format is invalid.
       */
      const regexResult = standardInput.match(tagRegex);
      if (!regexResult) {
        return result;
      }
      /**
       * Identify the array indicies of the capturing groups that the meaningful components of the antibody (locus, gene,
       * and allele) may have been stored in. Each antibody component may be found in one of multiple capturing groups,
       * depending on the case (common, specific, or DR103). E.g. The gene digits will be at index 10 in the DR103 case,
       * index 6 in the Specific case, or index 4 in the Common case.
       */
      // Extract the meaningful components of the antibody from the input text
      const rawLocus = regexResult[RegexGroup.Locus] || '';
      const rawAlleleGroup = regexResult[RegexGroup.AlleleGroup] || regexResult[RegexGroup.AlleleGroupSeparated] || '';
      const rawAlleleProtein = regexResult[RegexGroup.AlleleProtein] || regexResult[RegexGroup.AlleleProteinSeparated] || '';
      const rawBetaLocus = regexResult[RegexGroup.BetaLocus] || '';
      const rawBetaAlleleGroup = regexResult[RegexGroup.BetaAlleleGroup] || regexResult[RegexGroup.BetaAlleleGroupSeparated] || '';
      const rawBetaAlleleProtein = regexResult[RegexGroup.BetaAlleleProtein] || regexResult[RegexGroup.BetaAlleleProteinSeparated] || '';
      // Standardize locus
      const locus = getters.parseHlaSerologicLocus(rawLocus);
      const molecularLocus = getters.parseHlaMolecularLocus(rawLocus);
      const betaLocus = getters.parseHlaSerologicLocus(rawBetaLocus);
      const betaMolecularLocus = getters.parseHlaMolecularLocus(rawBetaLocus);
      // Standardize the gene value to include a leading zero if needed
      const alleleGroup = getters.parseHlaSerologicAlleleGroup(rawAlleleGroup);
      const molecularAlleleGroup = getters.parseHlaMolecularAlleleGroup(rawAlleleGroup);
      const alleleProtein = rawAlleleProtein.replace(/:X/g, '');
      const betaAlleleGroup = getters.parseHlaSerologicAlleleGroup(rawBetaAlleleGroup);
      const betaMolecularAlleleGroup = getters.parseHlaMolecularAlleleGroup(rawBetaAlleleGroup);
      const betaAlleleProtein = rawBetaAlleleProtein.replace(/:X/g, '');
      // Standardize each part of the antibody - there may be one (called alpha here) or two (alpha-beta)
      let alphaGroup = `${locus}${alleleGroup}`;
      const alphaGroupMolecular = `${molecularLocus}*${molecularAlleleGroup}`;
      let alphaAlleleSpecific = alleleProtein.length > 0;
      let alphaFull = alphaAlleleSpecific ? `${molecularLocus}*${molecularAlleleGroup}:${alleleProtein}` : alphaGroup;
      const alphaAllele = alphaAlleleSpecific ? `${molecularAlleleGroup}:${alleleProtein}` : `${molecularAlleleGroup}:XX`;
      const alphaMolecular = alphaAlleleSpecific ? `${molecularLocus}*${molecularAlleleGroup}:${alleleProtein}` : `${molecularLocus}*${molecularAlleleGroup}:XX`;
      const betaGroup = `${betaLocus}${betaAlleleGroup}`;
      const betaGroupMolecular = `${betaMolecularLocus}*${betaMolecularAlleleGroup}`;
      const betaAlleleSpecific = betaAlleleProtein.length > 0;
      const betaFull = betaAlleleSpecific ? `${betaMolecularLocus}*${betaMolecularAlleleGroup}:${betaAlleleProtein}` : betaGroupMolecular;
      const betaMolecular = betaAlleleSpecific ? `${betaMolecularLocus}*${betaMolecularAlleleGroup}:${betaAlleleProtein}` : `${betaMolecularLocus}*${betaMolecularAlleleGroup}:XX`;
      // If alpha-beta then alpha portion is necessarily molecular as well
      if (betaAlleleGroup.length > 0 && alleleProtein.length === 0) {
        alphaFull = alphaGroupMolecular;
      }
      // Override standardized formats if special cases are applicable
      const specialCases = rootGetters['lookups/hlaAlleleGroupOverrides'] || [];
      const alphaSpecialCase = specialCases.find((specialCase: { standard: string; override: string }) => {
        return specialCase.standard == alphaFull;
      });
      if (alphaSpecialCase) {
        alphaGroup = alphaSpecialCase.override;
        alphaAlleleSpecific = false;
        alphaFull = alphaSpecialCase.override;
      }
      // Identify epitopes that match either standardized part
      const epitopes: string[] = [];
      const alphaEpitope = getters.parseHlaAntibodyEpitope(alphaFull);
      const betaEpitope = getters.parseHlaAntibodyEpitope(betaFull);
      if (alphaEpitope) {
        epitopes.push(alphaEpitope);
      }
      if (betaEpitope) {
        epitopes.push(betaEpitope);
      }
      const standardText = betaAlleleGroup.length > 0 ? `${alphaFull}-${betaFull}` : alphaFull;
      const isAlphaBeta = betaGroup.length > 0;
      const alphaBetaSpecific = isAlphaBeta ? [alphaMolecular, betaMolecular] : [];
      const proteinSpecific: string[] = (alphaAlleleSpecific && !isAlphaBeta) ? [alphaMolecular] : [];
      const mostRecentlyParsedLocus =  betaMolecularLocus || molecularLocus || locus || null;
      Object.assign(result, {
        locus: molecularLocus || locus || null,
        betaLocus: betaMolecularLocus || null,
        mostRecentlyParsedLocus,
        standardText,
        proteinSpecific,
        alphaBetaSpecific,
        epitopes,
        allele: alphaAllele,
      });
      return result;
    };
  },
  standardizeAntibody(state, getters) {
    return (antibody: string): string => {
      const parsed = getters.parseHlaAntibodyTag(antibody);
      return parsed !== undefined ? parsed.standardText || antibody : antibody;
    };
  },
  standardizeAntibodies(state, getters) {
    return (antibodies?: string[]): string[] => {
      if (antibodies === undefined || antibodies.length === 0) {
        return [];
      }
      const result = antibodies.map((antibody: string) => {
        return getters.standardizeAntibody(antibody);
      });
      return result;
    };
  },
  impliedTestingMethodDefault() {
    /**
     * The 'default' Testing Method is a virtual field for the HLA Antibodies form that basically allows the user to
     * set Testing Method Class I and Testing Method Class II at the same time. Furthermore, if a form's testing
     * methods are both set to 'Storage' and/or blank then the class-specific areas of the form are hidden. In this
     * situation, the 'default' Testing Method is the only Testing Method dropdown active for the user.
     */
    return (hlaAntibodiesTest: LabHLAAntibody): number|null => {
      const method1 = hlaAntibodiesTest.hla_class1_testing_method_code || null;
      const method2 = hlaAntibodiesTest.hla_class2_testing_method_code || null;
      // If methods are the same value, then default is clearly that same value
      let result: number|null = null;
      if (method1 === method2) {
        result = method1;
      } else {
        // If methods differ, then infer default based on a logical order of precedence: SAB > ID/PRA > Storage 
        if (method1 === HlaAntibodyTestingMethod.Sab || method2 === HlaAntibodyTestingMethod.Sab) {
          result = HlaAntibodyTestingMethod.Sab;
        } else if (method1 === HlaAntibodyTestingMethod.Idpra || method2 === HlaAntibodyTestingMethod.Idpra) {
          result = HlaAntibodyTestingMethod.Idpra;
        } else {
          result = HlaAntibodyTestingMethod.Storage;
        }
      }
      return result;
    };
  },
  // Filter for molecular allele-specific antibodies
  extractAlleleSpecific(state, getters) {
    return (antibodies: string[]): string[] => {
      // Return loci and allele groups, without allele proteins
      let specific: string[] = [];
      antibodies.forEach((antibody: string) => {
        const parsedAntibody = getters.parseHlaAntibodyTag(antibody);
        const proteinGroups = parsedAntibody && parsedAntibody.proteinSpecific && parsedAntibody.proteinSpecific.length > 0 ? parsedAntibody.proteinSpecific : [];
        if (proteinGroups.length > 0) specific = specific.concat(proteinGroups);
      });
      // Remove duplicates
      return uniqueElements(specific);
    };
  },
  // Filter for molecular alpha-beta antibodies
  extractAlphaBetaSpecific(state, getters) {
    return (antibodies: string[]): string[] => {
      let specific: string[] = [];
      antibodies.forEach((antibody: string) => {
        const parsedAntibody = getters.parseHlaAntibodyTag(antibody);
        const alphaBetaGroups = parsedAntibody && parsedAntibody.alphaBetaSpecific && parsedAntibody.alphaBetaSpecific.length > 0 ? parsedAntibody.alphaBetaSpecific : [];
        if (alphaBetaGroups.length > 0) specific = specific.concat(alphaBetaGroups);
      });
      // Remove duplicates
      return uniqueElements(specific);
    };
  },
  // Build list of tested serologic antibodies associated with allele-specific molecular antibodies input
  buildAlleleSpecific(state, getters, rootState, rootGetters) {
    return (testedAntibodies: string[], inputAntibodies: string[]): string[] => {
      const alleleSpecificMolecular = getters.extractAlleleSpecific(inputAntibodies);
      const alleleSpecificSerologic = rootGetters['lookups/findAllSerologicalByAntibodiesArray'](alleleSpecificMolecular);
      // Filter out untested and input antibodies
      const alleleSpecific = alleleSpecificSerologic.filter((antibody: string) => {
        return testedAntibodies.includes(antibody) && !inputAntibodies.includes(antibody);
      });
      return alleleSpecific;
    };
  },
  // Build list of tested serologic antibodies associated with alpha-beta molecular antibodies input
  // E.g. if "DQA1*01-DQB1*02" is input, we highlight both DQA1 and DQ2 allele groups in the Acceptable field
  buildAlphaBetaSpecific(state, getters, rootState, rootGetters) {
    return (testedAntibodies: string[], inputAntibodies: string[]): string[] => {
      const alphaBetaMolecular = getters.extractAlphaBetaSpecific(inputAntibodies);
      const alphaBetaSerologic = rootGetters['lookups/findAllSerologicalByAntibodiesArray'](alphaBetaMolecular);
      // Filter out untested and input antibodies
      const alphaBeta = alphaBetaSerologic.filter((antibody: string) => {
        return testedAntibodies.includes(antibody) && !inputAntibodies.includes(antibody);
      });
      return alphaBeta;
    };
  },
  // Build list of tested serologic antibodies not associated with any input antibodies
  buildAcceptable(state, getters, rootState, rootGetters) {
    return (testedAntibodies: string[], inputAntibodies: string[], alleleSpecific: string[], alphaBetaSpecific: string[], possibleAlleleSpecific: string[]): string[] => {
      // Filter out input as well as allele-specific and alpha-beta specific antibodies
      const inputAndSpecifics = inputAntibodies.concat(alleleSpecific).concat(alphaBetaSpecific).concat(possibleAlleleSpecific);
      const acceptable = testedAntibodies.filter((testedAntibody: string) => {
        return !inputAndSpecifics.includes(testedAntibody);
      });
      return acceptable;
    };
  },
  // Build empty HLA SAB antibody form
  buildNewAntibodiesForm(): HlaAntibodyDataForm {
    // Only the fields that can receive value-specific validation errors
    return {
      class1_unacceptable_allele_group: [],
      class1_unacceptable_allele_specific: [],
      class1_unacceptable_alpha_beta: [],
      class1_indeterminate_allele_group: [],
      class1_indeterminate_allele_specific: [],
      class1_indeterminate_alpha_beta: [],
      class2_unacceptable_allele_group: [],
      class2_unacceptable_allele_specific: [],
      class2_unacceptable_alpha_beta: [],
      class2_indeterminate_allele_group: [],
      class2_indeterminate_allele_specific: [],
      class2_indeterminate_alpha_beta: [],
      epitopes_unacceptable: [],
      epitopes_indeterminate: [],
    };
  },
  // Build HLA SAB antibody form edit state based on source API data
  buildAntibodiesForm(state, getters) {
    return (antibodies?: HlaAntibodyData, testedClass1?: any, testedClass2?: any): HlaAntibodyDataForm => {
      const result: HlaAntibodyDataForm = {};
      if (antibodies) {
        // Load input antibodies directly
        const class1: HlaAntibodyClass = antibodies.class1 || { antibodies: {}, epitopes: {} };
        const class2: HlaAntibodyClass = antibodies.class2 || { antibodies: {}, epitopes: {} };
        // Fetch all groups of Unacceptable and Indeterminate fields
        const class1_unacceptable = class1.antibodies.unacceptable_allele_group || [];
        const class1_unacceptable_allele_specific = class1.antibodies.unacceptable_allele_specific || [];
        const class1_unacceptable_alpha_beta = class1.antibodies.unacceptable_alpha_beta || [];
        const class1_indeterminate = class1.antibodies.indeterminate_allele_group || [];
        const class1_indeterminate_allele_specific = class1.antibodies.indeterminate_allele_specific || [];
        const class1_indeterminate_alpha_beta = class1.antibodies.indeterminate_alpha_beta || [];
        const class1_possible_allele_specific = class1.antibodies.possible_allele_specific || [];
        const class2_unacceptable = class2.antibodies.unacceptable_allele_group || [];
        const class2_unacceptable_allele_specific = class2.antibodies.unacceptable_allele_specific || [];
        const class2_unacceptable_alpha_beta = class2.antibodies.unacceptable_alpha_beta || [];
        const class2_indeterminate = class2.antibodies.indeterminate_allele_group || [];
        const class2_indeterminate_allele_specific = class2.antibodies.indeterminate_allele_specific || [];
        const class2_indeterminate_alpha_beta = class2.antibodies.indeterminate_alpha_beta || [];
        const class2_possible_allele_specific = class2.antibodies.possible_allele_specific || [];
        // Fetch Class I epitope fields
        const epitopes = class1.epitopes || {};
        const epitopes_unacceptable = epitopes.unacceptable || [];
        const epitopes_indeterminate = epitopes.indeterminate || [];

        // Users input which antibodies are Unacceptable or Indeterminate
        const class1_unacceptable_inputs = class1_unacceptable.concat(class1_unacceptable_allele_specific).concat(class1_unacceptable_alpha_beta).concat(epitopes_unacceptable);
        const class1_indeterminate_inputs = class1_indeterminate.concat(class1_indeterminate_allele_specific).concat(class1_indeterminate_alpha_beta).concat(epitopes_indeterminate);
        const class1_inputAntibodies = class1_unacceptable_inputs.concat(class1_indeterminate_inputs);

        // Fetch list of antibodies tested based on Laboratory, Sample Method, and Testing Kit
        const class1_tested = getters.standardizeAntibodies(testedClass1);

        // Build Acceptable list, and lists of antibodies to highlight specific to Allele-specific and Alpha-beta 
        const class1_allele_specific = getters.buildAlleleSpecific(class1_tested, class1_inputAntibodies);
        const class1_alpha_beta_specific = getters.buildAlphaBetaSpecific(class1_tested, class1_inputAntibodies);
        const class1_acceptable = getters.buildAcceptable(class1_tested, class1_inputAntibodies, class1_allele_specific, class1_alpha_beta_specific, class1_possible_allele_specific);

        // Users input which antibodies are Unacceptable or Indeterminate
        const class2_unacceptable_inputs = class2_unacceptable.concat(class2_unacceptable_allele_specific).concat(class2_unacceptable_alpha_beta);
        const class2_indeterminate_inputs = class2_indeterminate.concat(class2_indeterminate_allele_specific).concat(class2_indeterminate_alpha_beta);
        const class2_inputAntibodies = class2_unacceptable_inputs.concat(class2_indeterminate_inputs);

        // Fetch list of antibodies tested based on Laboratory, Sample Method, and Testing Kit
        const class2_tested = getters.standardizeAntibodies(testedClass2);

        // Build Acceptable list, and lists of antibodies to highlight specific to Allele-specific and Alpha-beta 
        const class2_allele_specific = getters.buildAlleleSpecific(class2_tested, class2_inputAntibodies);
        const class2_alpha_beta_specific = getters.buildAlphaBetaSpecific(class2_tested, class2_inputAntibodies);
        const class2_acceptable = getters.buildAcceptable(class2_tested, class2_inputAntibodies, class2_allele_specific, class2_alpha_beta_specific, class2_possible_allele_specific);

        Object.assign(result, {
          class1_acceptable,
          class1_allele_specific,
          class1_alpha_beta_specific,
          class1_unacceptable,
          class1_unacceptable_allele_specific,
          class1_unacceptable_alpha_beta,
          class1_indeterminate,
          class1_indeterminate_allele_specific,
          class1_indeterminate_alpha_beta,
          class1_possible_allele_specific,
          class2_acceptable,
          class2_allele_specific,
          class2_alpha_beta_specific,
          class2_unacceptable,
          class2_unacceptable_allele_specific,
          class2_unacceptable_alpha_beta,
          class2_indeterminate,
          class2_indeterminate_allele_specific,
          class2_indeterminate_alpha_beta,
          class2_possible_allele_specific,
          epitopes_unacceptable,
          epitopes_indeterminate,
        });
      }
      return result;
    };
  },
  // Get Class I tested antibodies, whether the testing kit is specified or if the lab's defaults should be used
  buildTestedClass1(state, getters, rootState, rootGetters) {
    return (labCode: string, testingKitCode: string): string[] => {
      // Check default prefill based on selected laboratory
      const laboratory = rootGetters['laboratories/hlaLaboratoryByLabCode'](labCode);
      const defaultTested = rootGetters['laboratories/defaultHlaAcceptableClass1'](laboratory);
      // Check prefill for selected test kit
      const kits = rootState.lookups?.laboratory_hla_antibody_test_kits || [];
      const selectedKit = kits.find((kit: HlaAntibodyTestKit) => {
        return kit.code == testingKitCode;
      });
      const selectedPrefill = selectedKit?.prefill_antibodies || [];
      return selectedKit ? selectedPrefill : defaultTested;
    };
  },
  // Get Class II tested antibodies, whether the testing kit is specified or if the lab's defaults should be used
  buildTestedClass2(state, getters, rootState, rootGetters) {
    return (labCode: string, testingKitCode: string): string[] => {
      // Check default prefill based on selected laboratory
      const laboratory = rootGetters['laboratories/hlaLaboratoryByLabCode'](labCode);
      const defaultTested = rootGetters['laboratories/defaultHlaAcceptableClass2'](laboratory);
      // Check prefill for selected test kit
      const kits = rootState.lookups?.laboratory_hla_antibody_test_kits || [];
      const selectedKit = kits.find((kit: HlaAntibodyTestKit) => {
        return kit.code == testingKitCode;
      });
      const selectedPrefill = selectedKit?.prefill_antibodies || [];
      return selectedKit ? selectedPrefill : defaultTested;
    };
  },
  /**
   * Build an HLA Antibodies Test form edit state based on source HLA Antibodies Lab result provided by the API.
   * 
   * @returns {HLAAntibodiesForm} HLA Antibodies Test form data
   */
  buildHLAAntibodiesForm(state, getters, rootState, rootGetters) {
    return(hlaAntibodiesData: LabHLAAntibody): HLAAntibodiesForm => {
      // Check Testing Methods
      const testing_method_class1 = hlaAntibodiesData.hla_class1_testing_method_code;
      const testing_method_class2 = hlaAntibodiesData.hla_class2_testing_method_code;
      // Check SAB Test Kits
      const testing_kit_class1 = hlaAntibodiesData.hla_class1_test_kit_code;
      const testing_kit_class2 = hlaAntibodiesData.hla_class2_test_kit_code;
      // Deduce default testing method based on class-specific testing methods
      const testing_method_default = getters.impliedTestingMethodDefault(hlaAntibodiesData);

      // Identify which antibodies were tested
      const hla_lab = hlaAntibodiesData.lab_code;
      const hla_laboratory: Laboratory =  hla_lab ? rootGetters['laboratories/hlaLaboratoryByLabCode'](hla_lab) : undefined;

      const testedClass1 = getters.buildTestedClass1(hla_lab, testing_kit_class1);
      const testedClass2 = getters.buildTestedClass2(hla_lab, testing_kit_class2);
      // Build form edit state
      const result: HLAAntibodiesForm = {
        sample_code: hlaAntibodiesData.test_code,
        sample_draw_date: parseDateUi(hlaAntibodiesData.sample_date),
        hla_lab,
        // Fallback for handling lab results with a lab_code but no laboratory from HLA integration (ATQ-713)
        hla_lab_name: hla_laboratory?.name || hla_lab || undefined,
        sample_tested_date: parseDateUi(hlaAntibodiesData.test_date || undefined),
        comments: hlaAntibodiesData.comments,
        testing_method_default,
        testing_method_class1,
        testing_method_class2,
        testing_kit_class1,
        testing_kit_class2,
        antibodies: getters.buildAntibodiesForm(hlaAntibodiesData.antibodies, testedClass1, testedClass2),
        invalidAntibodies: getters.buildNewAntibodiesForm,
        cpra_class1: hlaAntibodiesData.cpra_class1,
        cpra_class2: hlaAntibodiesData.cpra_class2,
        combined_cpra: hlaAntibodiesData.cpra
      };
      return result;
    };
  },
  isSodiumAdult(state, getters, rootState, rootGetters): boolean {
    const recipientAge = rootGetters['recipients/recipientAge'];
    // Sodium MELD for adults (recipients who are 12 years of age or older)
    return recipientAge >= 12 ? true : false;
  },
  showMeld(state, getters): boolean {
    return getters.isSodiumAdult;
  },
  showExceptionDisease(state, getters, rootState, rootGetters): boolean {
    const recipientAge = rootGetters['recipients/recipientAge'];
    return recipientAge >= 18 ? true : false;
  },
  numLiverPelds(state, getters, rootState, rootGetters): number {
    return rootGetters['organSpecificDetails/numLiverPelds'];
  },
  showPeld(state, getters): boolean {
    if (!getters.isSodiumAdult) {
      return true;
    } else if (getters.isSodiumAdult && getters.numLiverPelds > 0) {
      return true;
    } else {
      return false;
    }
  }
};
