export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "transplant_activity_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Activity Report"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
        "generate_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Transplant Activity Report"])}
      },
      "fr": {
        "transplant_activity_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Activity Report"])},
        "organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
        "generate_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Transplant Activity Report"])}
      }
    }
  })
}
