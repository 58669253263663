import { MutationTree } from 'vuex';
import { LookupsState } from '@/store/lookups/types';
import Vue from 'vue';

export const mutations: MutationTree<LookupsState> = {
  /**
   * Mutation to set lookup state
   *
   * @param state lookup state
   * @param opts options object
   *    .id = string
   *    .value = string
   */
   set(state, opts) {
    state[opts.id] = opts.value;
  },
  /**
   * Mutation to set subtables
   *
   * @param state lookup state
   * @param opts options object
   *    .id = string
   *    .value = string
   */
   setSubTable(state, opts) {
    state[opts.id] = opts.value;
  },
  /**
   * Mutation to replace lookup attribute in state
   * 
   * @param state lookup state
   * @param opts options object
   *    .state = status, string
   *    .i = dispatch array index position, integer (NOTE: can be zero)
   */
  setDispatch(state, opts) {
    if (opts.i != null) {
      const table = state.dispatchTable;
      table[opts.i].state = opts.state;
      state.dispatchTable = table;
    }
  },
};
