import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-footer-body" }
const _hoisted_2 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_openBlock(), _createBlock(_component_modal_section, {
    modalId: "duplicate-living-donor-modal",
    ref: "duplicateLivingDonorModal",
    class: "modal-sticky-header",
    centered: true
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('potential_duplicate')), 1)
    ]),
    body: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.modalDuplicateWarningMessage), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", _hoisted_2, _toDisplayString(_ctx.$t('cancel')), 1),
        _createElementVNode("a", {
          class: "btn btn-success",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveDuplicatePatch()))
        }, _toDisplayString(_ctx.$t('continue')), 1)
      ])
    ]),
    _: 1
  }, 512))
}