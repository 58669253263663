<template>
  <login-container>
    <template v-slot:content>
      <div class="form-group">
        <template v-if="showUserSelect">
          <template v-if="bypassFusionAuth">
            <user-list :fusionAuth="!bypassFusionAuth"/>
          </template>
          <template v-else>
            <form :action="fusionAuthURL" v-on:submit="(event) => prepareRequest('fusionauth')" ref="fusionRequest" method="post" class="oneIdRequest">
              <input type="submit" :value="$t('login_fusionauth')" class="btn btn-info login float-left"/>
            </form>
          </template>
        </template>
        <login-one-id v-if="oneid"/>
      </div>
    </template>
  </login-container>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Component, mixins } from 'vue-facing-decorator';
import { APINakedRoute, EP } from '@/api-endpoints';
import LoginContainer from '@/components/shared/LoginContainer.vue';
import UserList from '@/components/login/UserList.vue';
import LoginOneId from '@/components/shared/LoginOneId.vue';
import { UrlUtilsMixin } from "@/mixins/url-utils-mixin";
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    LoginContainer,
    UserList,
    LoginOneId
  },
  ...i18nMessages([
    require('@/components/login/_locales/common.json'),
  ]),
})
export default class Login extends mixins(UrlUtilsMixin) {
  @Getter('showUserSelect', { namespace: 'configuration' }) private showUserSelect!: boolean;
  @Getter('bypassFusionAuth', { namespace: 'configuration' }) private bypassFusionAuth!: boolean;
  @Getter('oneid', { namespace: 'configuration' }) private oneid!: boolean;

  private mounted(): void {
    this.$store.commit('setPageTitle', 'Log in');
    localStorage.removeItem('user_token');
  }

  /**
   * get fusionauth url
   *
   * @returns string
   */
  get fusionAuthURL(): string { return APINakedRoute(EP.fusionAuth.request); }
}
</script>
