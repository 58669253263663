import Vue from 'vue';
import { MutationTree } from 'vuex';
import { idComparator } from '@/utils';
import { DeceasedDonorsState, DonorSignificantEvent } from '@/store/deceasedDonors/types';

export const mutations: MutationTree<DeceasedDonorsState> = {
  set(state, deceasedDonor) {
    state.selected = deceasedDonor;
  },
  clearDeceasedDonor(state) {
    state.selected = undefined;
  },
  setList(state, deceasedDonors) {
    state.donorsList = deceasedDonors;
  },
  setMeasurements(state, measurements) {
    state.selected!.measurements = measurements;
  },
  setDonorAttchments(state, attachments) {
    state.attachments = attachments;
  },
  clearExdConfirmations(state) {
    state.exdConfirmations = null;
  },
  setExdConfirmations(state, exdConfirmations) {
    state.exdConfirmations = exdConfirmations;
  },
  clearOrganDonations(state) {
    state.organDonations = null;
  },
  setOrganDonations(state, organDonations) {
    state.organDonations = organDonations;
  },
  clearSignificantEvents(state: DeceasedDonorsState) {
    state.significantEvents = null;
  },
  setSignificantEvents(state: DeceasedDonorsState, significantEvents: DonorSignificantEvent[]) {
    state.significantEvents = significantEvents;
  },
  setCtrLogs(state, ctrLogs) {
    state.ctrLogs = ctrLogs;
  },
  clearCtrLogs(state) {
    state.ctrLogs = [];
  },
  setNowLists(state, nowLists) {
    state.nowLists = nowLists;
  },
  clearNowLists(state) {
    state.nowLists = [];
  },
};
