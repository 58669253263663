import { APIFieldLevelValidationErrorData, APISaveTransplantResponse, APITransplantActivityData, SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { UIRecipient } from '@/UIModels/recipient';
import { UIJourney } from '@/UIModels/journey';
import { APITransplantDetailsInterface } from '@/APIModels/journey/types';
import { parseDateUi, parseDateUiFromDateTime, parseTimeUiFromDateTime, sanitizeDateApi, sanitizeDateTimeApi } from '@/utilities/date-utils';

export class UITransplantDetails {
  public apiSource: APITransplantDetailsInterface|null = null;

  public admission_date: string|null = null;
  public admission_time: string|null = null;

  public organ_source: string|null = null;
  public specific_organ_transplanted: string|null = null;
  public odo_donor_id: string|null = null;
  public odo: string|null = null;

  public donor_cross_clamp_date: string|null = null;
  public donor_cross_clamp_time: string|null = null;

  public cold_preservation_start_date: string|null = null;
  public cold_preservation_start_time: string|null = null;
  public cold_preservation_end_date: string|null = null;
  public cold_preservation_end_time: string|null = null;

  public cold_ischemia_minutes: number|null = null;

  public perfusion_device_status: string|null = null;
  public perfusion_device_used: string|null = null;

  public transplant_start_date: string|null = null;
  public transplant_start_time: string|null = null;
  public transplant_end_date: string|null = null;
  public transplant_end_time: string|null = null;

  public reperfusion_date: string|null = null;
  public reperfusion_time: string|null = null;

  public anastomosis_date: string|null = null;
  public anastomosis_time: string|null = null;

  public transplant_complete: string|null = null;
  public not_transplanted_reason: string|null = null;
  public surgical_complications: string|null = null;
  public clavien_dindo_classification: string|null = null;
  public intra_operative_death: string|null = null;

  public discharge_date: string|null = null;

  public follow_up_provider: string|null = null;

  public permitted_actions: string[] = [];

  // Define new UI view model structure
  public constructor(apiTransplantDetails: APITransplantDetailsInterface|null = null) {
    if (apiTransplantDetails) this.updateFromAPIResponse(apiTransplantDetails);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiTransplantDetails: APITransplantDetailsInterface) {
    this.apiSource = apiTransplantDetails;

    this.admission_date = parseDateUiFromDateTime(apiTransplantDetails.admission_datetime) || null;
    this.admission_time = parseTimeUiFromDateTime(apiTransplantDetails.admission_datetime) || null;

    this.organ_source = apiTransplantDetails.organ_source || null;
    this.specific_organ_transplanted = apiTransplantDetails.specific_organ_transplanted || null;
    this.odo_donor_id = apiTransplantDetails.odo_donor_id || null;
    this.odo = apiTransplantDetails.odo || null;

    this.donor_cross_clamp_date = parseDateUiFromDateTime(apiTransplantDetails.donor_cross_clamp_datetime) || null;
    this.donor_cross_clamp_time = parseTimeUiFromDateTime(apiTransplantDetails.donor_cross_clamp_datetime) || null;

    this.cold_preservation_start_date = parseDateUiFromDateTime(apiTransplantDetails.cold_preservation_start_datetime) || null;
    this.cold_preservation_start_time = parseTimeUiFromDateTime(apiTransplantDetails.cold_preservation_start_datetime) || null;

    this.cold_preservation_end_date = parseDateUiFromDateTime(apiTransplantDetails.cold_preservation_end_datetime) || null;
    this.cold_preservation_end_time = parseTimeUiFromDateTime(apiTransplantDetails.cold_preservation_end_datetime) || null;

    this.cold_ischemia_minutes = apiTransplantDetails.cold_ischemia_seconds != null ? Math.floor(apiTransplantDetails.cold_ischemia_seconds / 60) : null;
    this.perfusion_device_status = apiTransplantDetails.perfusion_device_status || null;
    this.perfusion_device_used = apiTransplantDetails.perfusion_device_used || null;

    this.transplant_start_date = parseDateUiFromDateTime(apiTransplantDetails.transplant_start_datetime) || null;
    this.transplant_start_time = parseTimeUiFromDateTime(apiTransplantDetails.transplant_start_datetime) || null;

    this.transplant_end_date = parseDateUiFromDateTime(apiTransplantDetails.transplant_end_datetime) || null;
    this.transplant_end_time = parseTimeUiFromDateTime(apiTransplantDetails.transplant_end_datetime) || null;

    this.reperfusion_date = parseDateUiFromDateTime(apiTransplantDetails.reperfusion_datetime) || null;
    this.reperfusion_time = parseTimeUiFromDateTime(apiTransplantDetails.reperfusion_datetime) || null;

    this.anastomosis_date = parseDateUiFromDateTime(apiTransplantDetails.anastomosis_datetime) || null;
    this.anastomosis_time = parseTimeUiFromDateTime(apiTransplantDetails.anastomosis_datetime) || null;

    this.transplant_complete = this.convertFromBoolean(apiTransplantDetails.transplant_complete);
    this.not_transplanted_reason = apiTransplantDetails.not_transplanted_reason || null;
    this.surgical_complications = this.convertFromBoolean(apiTransplantDetails.surgical_complications);
    this.clavien_dindo_classification = apiTransplantDetails.clavien_dindo_classification || null;
    this.intra_operative_death = this.convertFromBoolean(apiTransplantDetails.intra_operative_death);

    this.discharge_date = parseDateUi(apiTransplantDetails.discharge_date) || null;

    this.follow_up_provider = apiTransplantDetails.follow_up_provider || null;
  }

  // Convert boolean for string for ui
  private convertFromBoolean(value: boolean|null|undefined): string|null {
    if (value === null || value === undefined) return null;
    return value === true ? 'yes' : 'no';
  }

  // Convert string to boolean for api
  private convertToBoolean(value: string|null): boolean|null {
    if (value === null || value === undefined) return null;
    return value === 'yes' ? true : false;
  }


  // Build a copy of specific details for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModel() {
    const apiSource = this.apiSource as APITransplantDetailsInterface;
    if (!apiSource) return new UITransplantDetails();

    return new UITransplantDetails(apiSource);
  }

  // Save edit state to the backend
  public save(opts: { recipient: UIRecipient, journey: UIJourney }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      const journeyId = opts.journey.journeyId;
      if (!recipientId) reject((new UIError('transplant_details')));
      if (!journeyId) reject((new UIError('transplant_details')));

      const ep = APIRoute(EP.recipients.journeys.transplant.update, [[':recipientId', recipientId as string], [':journeyId', journeyId as string]]);
      const payload = {
        transplant_attributes: this.extractPatch()
      };
      axios.patch(ep, payload).then((response: APISaveTransplantResponse) => {
        if ((response.data as APIFieldLevelValidationErrorData).errors) {
          reject((new UIError('transplant_details', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          this.updateFromAPIResponse((response.data as APITransplantActivityData).transplant_attributes);
          /// NOTE: here we simply call journey-level reload to handle everything
          opts.journey.load({ reload: true }).then(() => {
            resolve((new UISuccess(response)).getSaveResult());
          });
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('transplant_details', errorResponse)).errorResult);
      });
    });
  }

  // Generate request payload parameters to provide to API as part of Create or Update activity
  private extractPatch(): APITransplantDetailsInterface {
    const patch: APITransplantDetailsInterface = {
      admission_datetime: sanitizeDateTimeApi(this.admission_date, this.admission_time),
      organ_source: this.organ_source,
      specific_organ_transplanted: this.specific_organ_transplanted,
      odo_donor_id: this.odo_donor_id,
      odo: this.odo,
      donor_cross_clamp_datetime: sanitizeDateTimeApi(this.donor_cross_clamp_date, this.donor_cross_clamp_time),
      cold_preservation_start_datetime: sanitizeDateTimeApi(this.cold_preservation_start_date, this.cold_preservation_start_time),
      cold_preservation_end_datetime: sanitizeDateTimeApi(this.cold_preservation_end_date, this.cold_preservation_end_time),
      perfusion_device_status: this.perfusion_device_status,
      perfusion_device_used: this.perfusion_device_used,
      transplant_start_datetime: sanitizeDateTimeApi(this.transplant_start_date, this.transplant_start_time),
      transplant_end_datetime: sanitizeDateTimeApi(this.transplant_end_date, this.transplant_end_time),
      reperfusion_datetime: sanitizeDateTimeApi(this.reperfusion_date, this.reperfusion_time),
      anastomosis_datetime: sanitizeDateTimeApi(this.anastomosis_date, this.anastomosis_time),
      transplant_complete: this.convertToBoolean(this.transplant_complete),
      not_transplanted_reason: this.not_transplanted_reason,
      surgical_complications: this.convertToBoolean(this.surgical_complications),
      clavien_dindo_classification: this.clavien_dindo_classification,
      intra_operative_death: this.convertToBoolean(this.intra_operative_death),
      discharge_date: sanitizeDateApi(this.discharge_date),
      follow_up_provider: this.follow_up_provider,
    };

    return patch;
  }

  public setPermittedActions(permitted_actions: string[]) {
    this.permitted_actions = permitted_actions;
  }
}
