export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "referral_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Information"])},
        "save_referral_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Referral Information"])},
        "referral_details_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Details Form"])},
        "out_of_province_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Province Donation"])},
        "referral_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Date"])},
        "referral_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Time"])},
        "referral_type": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Type"])},
          "referral-type-out-of-province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out-of-Province"])},
          "referral-type-tissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tissue"])},
          "referral-type-organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
          "referral-type-unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
        }
      },
      "fr": {
        "referral_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de Référence"])},
        "save_referral_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les Informations de Référence"])},
        "referral_details_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire de détails de référence"])},
        "out_of_province_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don à l’extérieur de la province"])},
        "referral_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de référence"])},
        "referral_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure de référence"])},
        "referral_type": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Type"])},
          "referral-type-out-of-province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out-of-Province"])},
          "referral-type-tissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tissue"])},
          "referral-type-organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ"])},
          "referral-type-unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
        }
      }
    }
  })
}
