import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: _ctx.$t('blood_type'),
    "sub-section-id": "gci_blood_type",
    ref: "blood_type"
  }, {
    contents: _withCtx(() => [
      _createVNode(_component_validation_observer, { ref: "validations" }, {
        default: _withCtx(() => [
          _createVNode(_component_form_layout, {
            disabled: !_ctx.canSave,
            "form-id": "blood_type_form"
          }, {
            contents: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_select_input, {
                    ruleKey: "diagnostics.blood.type",
                    selectId: "gci-blood.type",
                    name: _ctx.$t('blood_type'),
                    modelValue: _ctx.editState.type,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.type) = $event)),
                    options: _ctx.bloodTypeLookup
                  }, null, 8, ["name", "modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_select_input, {
                    ruleKey: "diagnostics.blood.rh_indicator",
                    selectId: "gci-blood.rh",
                    name: _ctx.$t('rh_factor'),
                    modelValue: _ctx.editState.rh_indicator,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.rh_indicator) = $event)),
                    options: _ctx.rhIndicatorLookup
                  }, null, 8, ["name", "modelValue", "options"])
                ])
              ])
            ]),
            save: _withCtx(() => [
              (_ctx.canSave)
                ? (_openBlock(), _createBlock(_component_save_toolbar, {
                    key: 0,
                    ref: "saveBloodType",
                    class: "card-footer action-row temp-saving row",
                    label: _ctx.$t('save_blood_type'),
                    cancelButton: true,
                    onSave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSave())),
                    onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleCancel()))
                  }, null, 8, ["label"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["title"]))
}