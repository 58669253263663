import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hla_antibodies_comments = _resolveComponent("hla-antibodies-comments")!
  const _component_hla_antibodies_test = _resolveComponent("hla-antibodies-test")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "hla-antibodies",
    "lookups-to-load": _ctx.lookupsToLoad,
    "laboratories-to-load": _ctx.laboratoriesToLoad,
    saveButton: false,
    ref: "HlaAntibodies",
    saveButtonText: "Save HLA Antibodies Test",
    onLoaded: _ctx.loaded
  }, {
    header: _withCtx(() => [
      _createTextVNode(" Antibodies ")
    ]),
    body: _withCtx(() => [
      _createVNode(_component_hla_antibodies_comments, {
        ref: "hlaComments",
        onHandleErrors: _ctx.handleErrors,
        onClear: _ctx.clear,
        onLoaded: _cache[0] || (_cache[0] = (sectionName) => _ctx.loadedSubsection(sectionName)),
        canSave: _ctx.checkAllowed('/recipients/:recipient_id/diagnostics_hla', 'PATCH')
      }, null, 8, ["onHandleErrors", "onClear", "canSave"]),
      _createVNode(_component_hla_antibodies_test, {
        ref: "hlaAntibodiesTest",
        onSaved: _ctx.saved,
        onHandleErrors: _ctx.handleErrors,
        onClear: _ctx.clear,
        onLoaded: _cache[1] || (_cache[1] = (sectionName) => _ctx.loadedSubsection(sectionName)),
        canSave: _ctx.checkAllowed('/recipients/:recipient_id/hla_antibodies', 'POST')
      }, null, 8, ["onSaved", "onHandleErrors", "onClear", "canSave"])
    ]),
    _: 1
  }, 8, ["lookups-to-load", "laboratories-to-load", "onLoaded"]))
}