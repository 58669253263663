import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "standard-form-group" }
const _hoisted_16 = { class: "standard-form-group" }
const _hoisted_17 = { class: "standard-form-group" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "standard-form-group" }
const _hoisted_22 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "standard-form-group" }
const _hoisted_25 = { class: "standard-form-group" }
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "standard-form-group" }
const _hoisted_28 = { class: "standard-form-group" }
const _hoisted_29 = { class: "standard-form-group" }
const _hoisted_30 = { class: "standard-form-group" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "standard-form-group" }
const _hoisted_33 = { class: "standard-form-group" }
const _hoisted_34 = { class: "row" }
const _hoisted_35 = { class: "standard-form-group" }
const _hoisted_36 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_37 = { class: "row" }
const _hoisted_38 = { class: "standard-form-group" }
const _hoisted_39 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_40 = { class: "row" }
const _hoisted_41 = { class: "standard-form-group" }
const _hoisted_42 = { class: "standard-form-group" }
const _hoisted_43 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_fields = _resolveComponent("loading-fields")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_SelectInput = _resolveComponent("SelectInput")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "transplant-details",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[32] || (_cache[32] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('transplant_details')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        title: _ctx.$t('transplant_details'),
        "sub-section-id": "sub-transplant-details"
      }, {
        contents: _withCtx(() => [
          (_ctx.isLoading || !_ctx.editState)
            ? (_openBlock(), _createBlock(_component_loading_fields, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_validation_observer, { ref: "validations" }, {
            default: _withCtx(() => [
              _createVNode(_component_form_layout, {
                disabled: !_ctx.enableForm,
                "form-id": "blood_type_form"
              }, {
                contents: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_date_input, {
                        ruleKey: "transplant_details.admission_datetime",
                        inputId: "admission_date",
                        name: _ctx.$t('date_of_admission'),
                        modelValue: _ctx.editState.admission_date,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.admission_date) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_time_input, {
                        ruleKey: "transplant_details.admission_datetime",
                        inputId: "admission_time",
                        name: _ctx.$t('time_of_admission'),
                        modelValue: _ctx.editState.admission_time,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.admission_time) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_SelectInput, {
                        ruleKey: "transplant_details.organ_source",
                        selectId: "organ_source",
                        name: _ctx.$t('organ_source'),
                        modelValue: _ctx.editState.organ_source,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.organ_source) = $event)),
                        options: _ctx.organ_source
                      }, null, 8, ["name", "modelValue", "options"])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_SelectInput, {
                        ruleKey: "transplant_details.specific_organ_transplanted",
                        selectId: "specific_organ_transplanted",
                        name: _ctx.$t('specific_organ_transplanted'),
                        modelValue: _ctx.editState.specific_organ_transplanted,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.specific_organ_transplanted) = $event)),
                        options: _ctx.getSpecificOrganLookup
                      }, null, 8, ["name", "modelValue", "options"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_text_input, {
                        ruleKey: "transplant_details.odo_donor_id",
                        inputId: "donor_identifier",
                        name: _ctx.$t('donor_identifier'),
                        modelValue: _ctx.editState.odo_donor_id,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.odo_donor_id) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_SelectInput, {
                        ruleKey: "transplant_details.organ_donation_organization",
                        selectId: "organ_donation_organization",
                        name: _ctx.$t('donor_odo'),
                        modelValue: _ctx.editState.odo,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.odo) = $event)),
                        options: _ctx.organ_donation_organization
                      }, null, 8, ["name", "modelValue", "options"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_date_input, {
                        ruleKey: "transplant_details.donor_cross_clamp_datetime",
                        inputId: "donor_cross_clamp_date",
                        name: _ctx.$t('donor_cross_clamp_date'),
                        modelValue: _ctx.editState.donor_cross_clamp_date,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.donor_cross_clamp_date) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_time_input, {
                        ruleKey: "transplant_details.donor_cross_clamp_datetime",
                        inputId: "donor_cross_clamp_time",
                        name: _ctx.$t('donor_cross_clamp_time'),
                        modelValue: _ctx.editState.donor_cross_clamp_time,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.donor_cross_clamp_time) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_date_input, {
                        ruleKey: "transplant_details.cold_preservation_start_datetime",
                        inputId: "cold_preservation_start_date",
                        name: _ctx.$t('cold_preservation_start_date'),
                        modelValue: _ctx.editState.cold_preservation_start_date,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.cold_preservation_start_date) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_time_input, {
                        ruleKey: "transplant_details.cold_preservation_start_datetime",
                        inputId: "cold_preservation_start_time",
                        name: _ctx.$t('cold_preservation_start_time'),
                        modelValue: _ctx.editState.cold_preservation_start_time,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.cold_preservation_start_time) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_date_input, {
                        ruleKey: "transplant_details.cold_preservation_end_datetime",
                        inputId: "cold_preservation_end_date",
                        name: _ctx.$t('cold_preservation_end_date'),
                        modelValue: _ctx.editState.cold_preservation_end_date,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.cold_preservation_end_date) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_time_input, {
                        ruleKey: "transplant_details.cold_preservation_end_datetime",
                        inputId: "cold_preservation_end_time",
                        name: _ctx.$t('cold_preservation_end_time'),
                        modelValue: _ctx.editState.cold_preservation_end_time,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.cold_preservation_end_time) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_text_input, {
                        inputId: "cold_isch_time",
                        name: _ctx.$t('cold_ischemia_time'),
                        disabled: true,
                        append: true,
                        "append-text": _ctx.$t('minutes_unit'),
                        guidingText: true,
                        showToolTip: true,
                        toolTipText: _ctx.$t('cold_ischemia_time_tooltip'),
                        modelValue: _ctx.editState.cold_ischemia_minutes,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.cold_ischemia_minutes) = $event))
                      }, null, 8, ["name", "append-text", "toolTipText", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createVNode(_component_SelectInput, {
                        ruleKey: "transplant_details.perfusion_device_status",
                        selectId: "perfusion_device_status",
                        name: _ctx.$t('perfusion_device_status'),
                        modelValue: _ctx.editState.perfusion_device_status,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.perfusion_device_status) = $event)),
                        options: _ctx.perfusion_device_status,
                        onChange: _ctx.onChangePerfusionDeviceStatus()
                      }, null, 8, ["name", "modelValue", "options", "onChange"])
                    ]),
                    (_ctx.editState.perfusion_device_status === 'yes')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                          _createVNode(_component_SelectInput, {
                            ruleKey: "transplant_details.perfusion_device_used",
                            selectId: "perfusion_device_used",
                            name: _ctx.$t('perfusion_device_used'),
                            modelValue: _ctx.editState.perfusion_device_used,
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.perfusion_device_used) = $event)),
                            options: _ctx.perfusion_device
                          }, null, 8, ["name", "modelValue", "options"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createVNode(_component_date_input, {
                        ruleKey: "transplant_details.reperfusion_datetime",
                        inputId: "reperfusion_date",
                        name: _ctx.$t('reperfusion_date'),
                        modelValue: _ctx.editState.reperfusion_date,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.reperfusion_date) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createVNode(_component_time_input, {
                        ruleKey: "transplant_details.reperfusion_datetime",
                        inputId: "reperfusion_time",
                        name: _ctx.$t('reperfusion_time'),
                        modelValue: _ctx.editState.reperfusion_time,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.reperfusion_time) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _createVNode(_component_date_input, {
                        ruleKey: "transplant_details.transplant_start_datetime",
                        inputId: "transplant_start_date",
                        name: _ctx.$t('transplant_start_date'),
                        modelValue: _ctx.editState.transplant_start_date,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState.transplant_start_date) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_time_input, {
                        ruleKey: "transplant_details.transplant_start_datetime",
                        inputId: "transplant_start_time",
                        name: _ctx.$t('transplant_start_time'),
                        modelValue: _ctx.editState.transplant_start_time,
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editState.transplant_start_time) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_component_date_input, {
                        ruleKey: "transplant_details.transplant_end_datetime",
                        inputId: "transplant_end_date",
                        name: _ctx.$t('transplant_end_date'),
                        modelValue: _ctx.editState.transplant_end_date,
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editState.transplant_end_date) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _createVNode(_component_time_input, {
                        ruleKey: "transplant_details.transplant_end_datetime",
                        inputId: "transplant_end_time",
                        name: _ctx.$t('transplant_end_time'),
                        modelValue: _ctx.editState.transplant_end_time,
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editState.transplant_end_time) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _createVNode(_component_date_input, {
                        ruleKey: "transplant_details.anastomosis_datetime",
                        inputId: "anastomosis_date",
                        name: _ctx.$t('anastomosis_date'),
                        modelValue: _ctx.editState.anastomosis_date,
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.editState.anastomosis_date) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _createVNode(_component_time_input, {
                        ruleKey: "transplant_details.atastomosis_datetime",
                        inputId: "atastomosis_time",
                        name: _ctx.$t('anastomosis_time'),
                        modelValue: _ctx.editState.anastomosis_time,
                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.editState.anastomosis_time) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("div", _hoisted_35, [
                      _createVNode(_component_SelectInput, {
                        ruleKey: "transplant_details.transplant_complete",
                        selectId: "transplant_complete",
                        name: _ctx.$t('organ_transplanted'),
                        modelValue: _ctx.editState.transplant_complete,
                        "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.editState.transplant_complete) = $event)),
                        options: _ctx.getYesNoLookup,
                        onChange: _ctx.onChangeTransplantComplete()
                      }, null, 8, ["name", "modelValue", "options", "onChange"])
                    ]),
                    (_ctx.editState.transplant_complete === 'no')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                          _createVNode(_component_SelectInput, {
                            ruleKey: "transplant_details.not_transplanted_reason",
                            selectId: "not_transplanted_reason",
                            name: _ctx.$t('not_transplanted_reason'),
                            modelValue: _ctx.editState.not_transplanted_reason,
                            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.editState.not_transplanted_reason) = $event)),
                            options: _ctx.not_transplanted_reason
                          }, null, 8, ["name", "modelValue", "options"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("div", _hoisted_38, [
                      _createVNode(_component_SelectInput, {
                        ruleKey: "transplant_details.surgical_complications",
                        selectId: "surgical_complications",
                        name: _ctx.$t('surgical_complications'),
                        modelValue: _ctx.editState.surgical_complications,
                        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.editState.surgical_complications) = $event)),
                        options: _ctx.getYesNoLookup,
                        onChange: _ctx.onChangeSurgicalComplications()
                      }, null, 8, ["name", "modelValue", "options", "onChange"])
                    ]),
                    (_ctx.editState.surgical_complications === 'yes')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                          _createVNode(_component_SelectInput, {
                            ruleKey: "transplant_details.clavien_dindo_classification",
                            selectId: "clavien_dindo_classification",
                            name: _ctx.$t('clavien_dindo_classification'),
                            modelValue: _ctx.editState.clavien_dindo_classification,
                            "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.editState.clavien_dindo_classification) = $event)),
                            options: _ctx.clavien_dindo_classification
                          }, null, 8, ["name", "modelValue", "options"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("div", _hoisted_41, [
                      _createVNode(_component_SelectInput, {
                        ruleKey: "transplant_details.intra_operative_death",
                        selectId: "intra_operative_death",
                        name: _ctx.$t('intra_operative_death'),
                        modelValue: _ctx.editState.intra_operative_death,
                        "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.editState.intra_operative_death) = $event)),
                        options: _ctx.getYesNoLookup
                      }, null, 8, ["name", "modelValue", "options"])
                    ]),
                    _createElementVNode("div", _hoisted_42, [
                      _createVNode(_component_date_input, {
                        ruleKey: "transplant_details.discharge_date",
                        inputId: "discharge_date",
                        name: _ctx.$t('discharge_date'),
                        modelValue: _ctx.editState.discharge_date,
                        "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.editState.discharge_date) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_43, [
                      _createVNode(_component_SelectInput, {
                        ruleKey: "transplant_details.follow_up_provider",
                        selectId: "follow_up_provider",
                        name: _ctx.$t('provider_of_followup_care'),
                        modelValue: _ctx.editState.follow_up_provider,
                        "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.editState.follow_up_provider) = $event)),
                        options: _ctx.follow_up_provider
                      }, null, 8, ["name", "modelValue", "options"])
                    ])
                  ])
                ]),
                save: _withCtx(() => [
                  (_ctx.showSaveToolbar)
                    ? (_openBlock(), _createBlock(_component_save_toolbar, {
                        key: 0,
                        ref: "saveTransplantDetails",
                        class: "card-footer action-row temp-saving row",
                        label: _ctx.$t('save_transplant_details'),
                        cancelButton: true,
                        onSave: _cache[30] || (_cache[30] = ($event: any) => (_ctx.handleSave())),
                        onCancel: _cache[31] || (_cache[31] = ($event: any) => (_ctx.handleCancel()))
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}