export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "table": {
          "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
          "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
          "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])}
        },
        "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading..."])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "fr": {
        "table": {
          "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
          "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
          "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])}
        },
        "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chargement en cours…"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
      },
      "en_tgln": {
        "2": {
          "OFR_370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Higher ranked recipient has not been offered or has pending offer"])},
          "OFR_380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSH Recipient already accepted a primary offer in another allocation"])}
        },
        "3": {
          "OFR_370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Higher ranked recipient has not been offered or has pending offer"])},
          "OFR_380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSP Recipient already accepted a primary offer in another allocation"])}
        }
      },
      "fr_tgln": {
        "2": {
          "OFR_370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Higher ranked recipient has not been offered or has pending offer"])},
          "OFR_380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSH Recipient already accepted a primary offer in another allocation"])}
        },
        "3": {
          "OFR_370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Higher ranked recipient has not been offered or has pending offer"])},
          "OFR_380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSP Recipient already accepted a primary offer in another allocation"])}
        }
      }
    }
  })
}
