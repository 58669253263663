import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = {
  key: 0,
  class: "standard-full-width-group"
}
const _hoisted_9 = {
  key: 0,
  class: "row"
}
const _hoisted_10 = {
  key: 0,
  class: "standard-form-group-6column-xlarge-only"
}
const _hoisted_11 = {
  key: 1,
  class: "standard-form-group-6column-xlarge-only"
}
const _hoisted_12 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_13 = ["disabled"]
const _hoisted_14 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_15 = {
  key: 1,
  class: "legend-title"
}
const _hoisted_16 = { class: "row d-flex" }
const _hoisted_17 = { class: "measurements-form-group" }
const _hoisted_18 = { class: "measurements-form-group" }
const _hoisted_19 = { class: "row d-flex" }
const _hoisted_20 = { class: "measurements-form-group measureUnits" }
const _hoisted_21 = { class: "inner" }
const _hoisted_22 = { class: "form-group" }
const _hoisted_23 = { class: "form-group" }
const _hoisted_24 = { class: "measurements-form-group measureUnits last" }
const _hoisted_25 = { class: "inner" }
const _hoisted_26 = { class: "form-group" }
const _hoisted_27 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_hidden_input = _resolveComponent("hidden-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "gci",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[24] || (_cache[24] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("clinical_information")), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        title: _ctx.$t('clinical_data'),
        "sub-section-id": "gci-clinical",
        saveButtonText: _ctx.$t('save_clinical_data'),
        ref: "saveGeneralClinical",
        saveButton: !_ctx.newLivingDonor && _ctx.canSave,
        disabled: !_ctx.canSave,
        onSave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.savePatch(false)))
      }, {
        contents: _withCtx(() => [
          (_ctx.editState)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_select_input, {
                    ruleKey: "diagnostics.blood.type",
                    selectId: "ld-clinical-blood-type",
                    name: _ctx.$t('blood_type'),
                    modelValue: _ctx.editState.bloodType,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.bloodType) = $event)),
                    options: _ctx.bloodTypesForLivingDonor
                  }, null, 8, ["name", "modelValue", "options"])
                ]),
                (_ctx.showAboSubType)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_select_input, {
                        ruleKey: "diagnostics.blood.sub_type",
                        selectId: "ld-clinical-abo_sub_type",
                        name: _ctx.$t("abo_sub_type"),
                        modelValue: _ctx.editState.aboSubType,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.aboSubType) = $event)),
                        options: _ctx.bloodSubTypeOptions
                      }, null, 8, ["name", "modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_select_input, {
                    ruleKey: "diagnostics.blood.rh_indicator",
                    selectId: "ld-clinical-rh",
                    name: _ctx.$t('rh_factor'),
                    modelValue: _ctx.editState.rhIndicator,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.rhIndicator) = $event)),
                    options: _ctx.rhIndicatorsForLivingDonor
                  }, null, 8, ["name", "modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_checkbox_input, {
                    ruleKey: "diagnostics.blood.type_verified",
                    "input-id": "ld-clinical-btv",
                    labelName: _ctx.$t('blood_type_verified'),
                    modelValue: _ctx.editState.bloodTypeVerified,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.bloodTypeVerified) = $event)),
                    label: _ctx.$t('yes'),
                    disabled: _ctx.isBloodTypeVerifiedDisabled()
                  }, null, 8, ["labelName", "modelValue", "label", "disabled"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "saveButtonText", "saveButton", "disabled"]),
      _createVNode(_component_sub_section, {
        title: _ctx.$t("exceptional_distribution"),
        "sub-section-id": "gci-exceptional-distribution",
        saveButtonText: _ctx.$t('save_exceptional_distribution'),
        ref: "saveExD",
        saveButton: !_ctx.newLivingDonor && _ctx.canSave,
        disabled: !_ctx.canSave,
        onSave: _cache[10] || (_cache[10] = ($event: any) => (_ctx.saveExDPatch()))
      }, {
        contents: _withCtx(() => [
          (_ctx.editState)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_checkbox_input, {
                      "input-id": "exceptional_distribution",
                      modelValue: _ctx.editState.exceptionalDistribution,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.exceptionalDistribution) = $event)),
                      labelName: _ctx.$t("exd-distribution-title"),
                      label: _ctx.$t("yes"),
                      validationId: "exceptional_distribution",
                      onChange: _ctx.changeEXDStatus
                    }, null, 8, ["modelValue", "labelName", "label", "onChange"])
                  ]),
                  (_ctx.editState.exceptionalDistribution)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_select_input, {
                          selectId: "exd_reason_codes",
                          rules: "required",
                          multiple: true,
                          disabled: !_ctx.canSave,
                          name: _ctx.$t("exd_reason-codes"),
                          label: _ctx.$t("exd_reason-codes"),
                          placeholder: _ctx.$t('exd-add-reason'),
                          options: _ctx.exceptionalDistributionOptionsForLivingDonor,
                          modelValue: _ctx.editState.exdReasonCodes,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.exdReasonCodes) = $event))
                        }, null, 8, ["disabled", "name", "label", "placeholder", "options", "modelValue"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.editState.exceptionalDistribution)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (_ctx.editState.exdReasonCodes.includes(300))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createVNode(_component_text_input, {
                              "input-id": "exd_reason_details_other",
                              ruleKey: "indicators.exd_reason_details_other",
                              name: _ctx.$t("exd-reason-details-other"),
                              modelValue: _ctx.editState.exdReasonDetailsOther,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.exdReasonDetailsOther) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.editState.exdReasonCodes.includes(200))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createVNode(_component_text_input, {
                              "input-id": "exd_reason_details_travel",
                              ruleKey: "indicators.exd_reason_details_travel",
                              name: _ctx.$t("exd-reason-details-travel"),
                              modelValue: _ctx.editState.exdReasonDetailsTravel,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.exdReasonDetailsTravel) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.editState.exdReasonCodes.includes(406))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode(_component_text_input, {
                              "input-id": "exd_reason_details_transmission",
                              ruleKey: "indicators.exd_reason_details_transmission",
                              name: _ctx.$t("exd-reason-details-transmission"),
                              modelValue: _ctx.editState.exdReasonDetailsTransmission,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.exdReasonDetailsTransmission) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "saveButtonText", "saveButton", "disabled"]),
      _createVNode(_component_sub_section, {
        title: _ctx.$t("measurements"),
        "sub-section-id": "gci-measurements",
        ref: "saveMeasurement",
        saveButtonText: _ctx.$t('save_measurement'),
        tabbableValue: _ctx.$t('measurement_date'),
        saveButton: !_ctx.newLivingDonor && _ctx.canSaveMeasurement && _ctx.isNewOrMostRecentEntry,
        "table-config": _ctx.measurementsTableConfig(),
        onTableRowClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.selectMeasurement($event))),
        onTableCreateRow: _cache[22] || (_cache[22] = ($event: any) => (_ctx.createMeasurement())),
        onSave: _cache[23] || (_cache[23] = ($event: any) => (_ctx.saveMeasurement()))
      }, {
        contents: _withCtx(() => [
          (_ctx.editState && _ctx.editState.measurement)
            ? (_openBlock(), _createElementBlock("fieldset", {
                key: 0,
                disabled: !_ctx.canSaveMeasurement || !_ctx.isNewOrMostRecentEntry
              }, [
                _createElementVNode("legend", null, [
                  (!_ctx.editState.measurement._id)
                    ? (_openBlock(), _createElementBlock("h5", _hoisted_14, _toDisplayString(_ctx.$t('new_measurement')), 1))
                    : (_openBlock(), _createElementBlock("h5", _hoisted_15, _toDisplayString(_ctx.$t('selected_measurement')), 1))
                ]),
                _createVNode(_component_hidden_input, {
                  inputId: "destination_allocation",
                  validationId: "destination_allocation",
                  value: _ctx.hasSelectedLivingDonorConsentedOrgans
                }, null, 8, ["value"]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_date_input, {
                      validationId: "date",
                      ruleKey: "measurements.date",
                      inputId: "gci-measurements-date",
                      name: _ctx.$t('measurement_date'),
                      modelValue: _ctx.editState.measurement.date,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.measurement.date) = $event))
                    }, null, 8, ["name", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_text_input, {
                      inputId: "gci-measurements-bmi",
                      name: _ctx.$t('bmi'),
                      modelValue: _ctx.editState.measurement.bmi,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.measurement.bmi) = $event)),
                      calculated: true,
                      disabled: true
                    }, null, 8, ["name", "modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('metric')), 1),
                        _createVNode(_component_number_input, {
                          validationId: "weight",
                          ruleKey: "measurements.weight",
                          inputId: "gci-measurements-weight_kg",
                          name: _ctx.$t('weight'),
                          append: true,
                          appendText: "kg",
                          step: "0.1",
                          calculated: true,
                          calculatedText: "",
                          onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.calculateMeasurement())),
                          warningParams: { min: 1, max: 225, message: _ctx.$t('warning_weight')},
                          modelValue: _ctx.editState.measurement.weight,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.measurement.weight) = $event))
                        }, null, 8, ["name", "warningParams", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _createVNode(_component_number_input, {
                          validationId: "height",
                          ruleKey: "measurements.height",
                          inputId: "gci-measurements-height_cm",
                          name: _ctx.$t('height'),
                          append: true,
                          appendText: "cm",
                          step: "0.1",
                          calculated: true,
                          calculatedText: "",
                          onChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.calculateMeasurement())),
                          warningParams: { min: 30, max: 215, message: _ctx.$t('warning_height')},
                          modelValue: _ctx.editState.measurement.height,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.measurement.height) = $event))
                        }, null, 8, ["name", "warningParams", "modelValue"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('imperial')), 1),
                      _createElementVNode("div", _hoisted_26, [
                        _createVNode(_component_number_input, {
                          inputId: "gci-measurements-weight_lbs",
                          name: _ctx.$t('weight'),
                          append: true,
                          appendText: "lbs",
                          step: "0.1",
                          calculated: true,
                          calculatedText: "",
                          onChange: _cache[17] || (_cache[17] = ($event: any) => (_ctx.calculateMeasurement('lbs'))),
                          modelValue: _ctx.editState.measurement.weight_lbs,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editState.measurement.weight_lbs) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        _createVNode(_component_number_input, {
                          inputId: "gci-measurements-height_in",
                          name: _ctx.$t('height'),
                          append: true,
                          appendText: "in",
                          step: "0.1",
                          calculated: true,
                          calculatedText: "",
                          onChange: _cache[19] || (_cache[19] = ($event: any) => (_ctx.calculateMeasurement('in'))),
                          modelValue: _ctx.editState.measurement.height_in,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editState.measurement.height_in) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ])
                    ])
                  ])
                ])
              ], 8, _hoisted_13))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "saveButtonText", "tabbableValue", "saveButton", "table-config"])
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}