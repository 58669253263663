<template>
  <validation-observer ref="confirmRecipientConsentValidations" autocomplete="off" tag="form" v-slot="{ handleSubmit }">
    <modal-section
      modalId="confirm-recipient-consent"
      ref="confirmRecipientConsent"
      class="modal-sticky-header"
      :centered="true"
      size="xl"
    >
      <template v-slot:title>
        {{$t('confirm_recipient_consent')}}
      </template>
      <template v-slot:body>
        <template v-if="!editState">
          {{$t('loading')}}
        </template>
        <template v-else>
          <!-- Exceptional Distribution -->
          <sub-section
            :title='$t("exceptional_distribution")'
            sub-section-id="confirm-recipient-consent-exceptional-distribution"
          >
            <template v-slot:contents>
              <fieldset>
                <div class="row">
                  <div class="form-group col-sm-12">
                    <p class="p-label">{{$t('reasons_exceptional_distribution')}}</p>
                    <ul class="simple-list" v-if="getExdReasonList">
                      <li v-for="(reason, index) in getExdReasonList" v-bind:key="index">{{reason}}</li>
                    </ul>
                  </div>
                </div>
              </fieldset>
            </template>
          </sub-section>
          <!-- Confirmation details -->
          <sub-section
            :title="$t('confirmation_details')"
            sub-section-id="confirm-recipient-consent-confirmation-details"
            ref="confirmRecipientConsentConfirmationDetails"
          >
            <template v-slot:contents>
              <fieldset v-if="editState" :disabled="true">
                <div class="row">
                  <div class="form-group col-md-4 col-lg-4 col-xl-2">
                    <text-input
                      :name="$t('physician_name')"
                      input-id="confirm-recipient-consent-physician-name"
                      v-model="editState.detailRow.physicianName"
                      :readonly="true"
                    />
                  </div>
                  <div class="form-group col-md-4 col-lg-4 col-xl-2">
                    <text-input
                      :name="$t('recipient_name')"
                      input-id="confirm-recipient-consent-recipient-name"
                      v-model="editState.detailRow.recipientName"
                      :readonly="true"
                    />
                  </div>
                  <div class="form-group col-md-4 col-lg-4 col-xl-2">
                    <text-input
                      :name="$t('exd_organ')"
                      input-id="confirm-recipient-consent-exd-organ"
                      v-model="editState.detailRow.organ"
                      :readonly="true"
                    />
                  </div>
                  <select-other-input
                    :name="$t('acceptance_reason')"
                    select-id="confirm-recipient-consent-justification-reason"
                    v-model="editState.detailEdit.justificationReason"
                    :options="exdAcceptanceReasons"
                    :numeric="true"
                    :otherTitle="$t('other_acceptance_reason')"
                    colStyling="form-group selectWithOther col-md-8 col-lg-8 col-xl-6"
                  >
                    <template v-slot:other>
                      <text-input
                        :name="$t('other_acceptance_reason')"
                        input-id="confirm-recipient-consent-other-justification-reason"
                        v-model="editState.detailEdit.otherJustificationReason"
                      />
                    </template>
                  </select-other-input>
                </div>
              </fieldset>
            </template>
          </sub-section>
          <!-- Confirmation text -->
          <sub-section
            :title="$t('confirmation_text')"
            sub-section-id="confirm-recipient-consent-confirmation-text"
          >
            <template v-slot:contents>
              <fieldset>
                <div class="row">
                  <div class="col-12">
                    {{$t('text')}}
                  </div>
                </div>
              </fieldset>
            </template>
          </sub-section>
          <!-- Error messages -->
          <template v-if="editState.serverErrors && editState.serverErrors.length > 0">
            <div v-for="(serverError, index) in editState.serverErrors" :key="`server-error-${index}`" class="alert alert-danger">
              {{serverError}}
            </div>
          </template>
        </template>
      </template>
      <template v-slot:footer>
        <div class="modal-footer-body" v-if="editState">
          <button type="button" data-dismiss="modal" class="btn btn-secondary">
            {{$t('cancel')}}
          </button>
          <button
            type=button
            class="btn btn-success"
            @click="handleSubmit(confirmAcceptance)"
          >
            {{$t('confirmation_organ_acceptance')}}
          </button>
        </div>
      </template>
    </modal-section>
  </validation-observer>
</template>

<i18n src="@/components/deceasedDonors/_locales/common.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/ConfirmRecipientConsentModal.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/common.json"></i18n>

<script lang="ts">
import { SaveResult } from '@/types';
import { Getter, State } from 'vuex-facing-decorator';
import { Component, Vue } from 'vue-facing-decorator';
import { mixins } from "vue-facing-decorator";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { GenericCodeValue, NumericCodeValue } from '@/store/types';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import TableCheckboxInput from '@/components/shared/TableCheckboxInput.vue';
import { SYSTEM_ONLY_EXCEPTIONAL_DISTRIBUTION_REASONS } from '@/store/lookups/types';
import { DeceasedDonor, OrganDonationExceptionalDistributionConfirmation } from '@/store/deceasedDonors/types';

interface ConfirmRecipientConsentState {
  selectedId: string;
  detailRow: ConfirmationDetailRow; // acceptance information from e-Offer
  detailEdit: ConfirmationDetailEdit; // object to handle v-model changes
  serverErrors: string[];
}

interface ConfirmationDetailRow {
  id: string;
  physicianName: string;
  recipientName: string;
  organ: string;
  justificationReason: number|null;
  otherJustificationReason: string;
}

interface ConfirmationDetailEdit {
  justificationReason: number|null;
  otherJustificationReason: string;
}

@Component({
  components: {
    TextInput,
    SubSection,
    ModalSection,
    CheckboxInput,
    SelectOtherInput,
    TableCheckboxInput,
  }
})
export default class ConfirmRecipientConsentModal extends mixins(ValidationUtilsMixin) {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @State(state => state.deceasedDonors.exdConfirmations) private exdConfirmations!: any;
  @State(state => state.pageState.currentPage.confirmRecipientConsent) editState!: ConfirmRecipientConsentState;
  @State(state => state.lookups.exceptional_distribution_acceptance_reasons) private exdAcceptanceReasons!: NumericCodeValue[];
  @Getter('getOrganSpecificationName', { namespace: 'lookups' }) getOrganSpecificationName!: (organCode?: number|null, organSpecificationCode?: number|null) => string;

  @Getter('clientId', { namespace: 'deceasedDonors' }) private donorId!: number;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('getUserName', { namespace: 'users' }) private getUserName!: string;
  @Getter('lookupValueNumeric', { namespace: 'lookups' }) lookupValueNumeric!: (code: number, lookupId: string) => string|null;

  // Clear state and open modal
  public initialize(selectedId: string) {
    (this.$refs.confirmRecipientConsentValidations as any).setErrors({});
    this.initializePageState(selectedId);
    this.toggleModal();
  }

  // Open a static modal
  private toggleModal(): void {
    const targetModal = this.$refs.confirmRecipientConsent as ModalSection;
    targetModal.toggleStaticModal();
  }

  // Build empty pageState
  private initializePageState(selectedId: string): void {
    this.$store.commit('pageState/set', {
      pageKey: 'confirmRecipientConsent',
      value: this.buildConfirmRecipientConsentState(selectedId)
    });
  }

  // Map to UI form interface
  private buildConfirmRecipientConsentState(selectedId: string): ConfirmRecipientConsentState {
    const collection = this.exdConfirmations || [];
    const exdConfirmation = collection.find((item: any) => {
      return selectedId === item?._id.$oid;
    });
    const justificationReason = exdConfirmation?.organ_offer?.exceptional_distribution?.accepted_reason_code;
    const otherJustificationReason = exdConfirmation?.organ_offer?.exceptional_distribution?.accepted_reason_other;
    const physicianName = this.getUserName;
    const recipientName = (exdConfirmation?.organ_offer?.recipient_first_name || exdConfirmation?.organ_offer?.recipient_first_name) ? (`${exdConfirmation?.organ_offer?.recipient_first_name} ${exdConfirmation?.organ_offer?.recipient_last_name}`).trim() : '-';
    const organName = this.getOrganSpecificationName(exdConfirmation.organ_code, exdConfirmation.organ_specification_code);
    const detailRow: ConfirmationDetailRow = {
      id: exdConfirmation?._id?.$oid,
      physicianName,
      recipientName,
      organ: this.$t(organName).toString(),
      justificationReason,
      otherJustificationReason,
    };
    const detailEdit: ConfirmationDetailEdit = {
      justificationReason: detailRow.justificationReason,
      otherJustificationReason: detailRow.otherJustificationReason,
    };
    return {
      selectedId,
      detailRow,
      detailEdit,
      serverErrors: [],
    };
  }

  /**
   * Builds a list of EXD reasons based on exd_reason_codes
   *
   * @returns {string[]} array of exd reasons
   */
  get getExdReasonList(): string[] {
    // get list of exd reason codes
    const exd_reason_codes = this.donor?.indicators?.exd_reason_codes || [];

    // filter out system-only reasons (see B#15171)
    const filtered = exd_reason_codes.filter((code: number) => {
      return !SYSTEM_ONLY_EXCEPTIONAL_DISTRIBUTION_REASONS.includes(code);
    });

    // Sort codes by number and then map to display text
    const list = filtered.sort((x: any, y: any) => x > y ? 1 : -1).map((code: number): string => {
      return this.lookupValueNumeric(code, 'donor_exceptional_distribution') || this.$t('unknown').toString();
    });

    // add other exd reasons to list
    if (this.donor?.indicators?.exd_reason_details_travel) { list.push(`${this.$t('exceptional_distribution_travel_reason')} ${this.donor?.indicators?.exd_reason_details_travel}`); }
    if (this.donor?.indicators?.exd_reason_details_other) { list.push(`${this.$t('exceptional_distribution_other_reason')} ${this.donor?.indicators?.exd_reason_details_other}`); }
    if (this.donor?.indicators?.exd_reason_details_transmission) { list.push(`${this.$t('increased_risk_transmission_reason')} ${this.donor?.indicators?.exd_reason_details_transmission}`); }

    return list;
  }

  // Payload for updating organ donation ExD donation consent
  private extractExceptionalDistributionConfirmationPatch(): OrganDonationExceptionalDistributionConfirmation {
    if (!this.editState) return {};

    const form = this.editState.detailEdit || {};
    return {
      confirmed_reason_code: form.justificationReason,
      confirmed_reason_other: form.otherJustificationReason,
    };
  }

  // Save the new ExD donation acceptance confirmation i.e. physician indicates that recipient consents
  private confirmAcceptance(): void {
    if (!this.editState) return;

    // Setup save request
    const donorId = this.donorId;
    const organDonationId = this.editState.selectedId;
    const payload = {
      confirmation: this.extractExceptionalDistributionConfirmationPatch(),
    };
    // Clear error notification
    this.editState.serverErrors = [];
    this.$store.dispatch('deceasedDonors/saveExdConfirmations', { donorId, organDonationId, payload }).then((success: any) => {
      // If successful, dismiss the modal and reload the ExD dispositions
      (this.$refs.confirmRecipientConsent as ModalSection).hideModal();
      this.$emit('reload');
    }).catch((error: any) => {
      this.handleErrors(error);
    });
  }

  // Parse and highlight errors from api response
  private handleErrors(error: SaveResult): void {
    // Show error notification
    this.editState.serverErrors = error.errorMessages ? error.errorMessages : [];

    // Clear previous errors
    (this.$refs.confirmRecipientConsentValidations as any).setErrors({});

    // Gather validation IDs used in forms on this page
    const idLookup = this.idLookup();
    
    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(error, idLookup);

    // Set all validation errors using the validation observer wrapping entire form
    (this.$refs.confirmRecipientConsentValidations as any).setErrors(formErrors);
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    return {
      'confirmed_reason_code'  : 'confirm-recipient-consent-justification-reason',
      'confirmed_reason_other' : 'confirm-recipient-consent-other-justification-reason',
    };
  }
}
</script>
