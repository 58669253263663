<template>
  <div class="alert alert-danger">
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component({})
export default class WarningBanner extends Vue {}
</script>
