export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "confirm_that_you_want": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm that you want to save these responses."])},
        "donor_exceptional_distribution_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This donor has <strong>Exceptional Distribution</strong>. Please confirm below that you have understood and wish to continue."])},
        "accept_exceptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Exceptional Distribution"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "confirm_and_save_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm and Save Responses"])}
      },
      "fr": {
        "confirm_that_you_want": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez que vous souhaitez enregistrer ces réponses."])},
        "donor_exceptional_distribution_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce donneur a une <strong>répartition exceptionnelle</strong>. Veuillez confirmer ci-dessous que vous avez compris et que vous souhaitez continuer."])},
        "accept_exceptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter la répartition exceptionnelle"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "confirm_and_save_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer et enregistrer les réponses"])}
      }
    }
  })
}
