import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "nav-block-wrap" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "nav-link" }
const _hoisted_4 = {
  key: 0,
  class: "nav flex-column"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", {
      class: _normalizeClass(["nav nav-block flex-column", { active: _ctx.active }])
    }, [
      _createElementVNode("li", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', (_ctx.show ? 'chevron-down' : 'chevron-right')],
            class: "nav-caret",
            "fixed-width": "",
            onClick: _withModifiers(_ctx.toggleNav, ["prevent"])
          }, null, 8, ["icon", "onClick"]),
          _renderSlot(_ctx.$slots, "profile-return-link")
        ])
      ]),
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_ctx.newRecipient && _ctx.isRecipientCreateIncludeJourneyEnabled)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: "nav-item",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('organ-details-section')
                  }, _toDisplayString(_ctx.$t('journey_details')), 9, _hoisted_5)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
            }, [
              _createElementVNode("a", {
                class: "nav-link",
                href: _ctx.getUrl('demographics')
              }, _toDisplayString(_ctx.$t('demographics')), 9, _hoisted_6)
            ]),
            (!_ctx.newRecipient && _ctx.isContactInfoEnabled)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 1,
                  class: "nav-item",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('contactinfo')
                  }, _toDisplayString(_ctx.$t('contact_information')), 9, _hoisted_7)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.newRecipient && _ctx.isRecipientDocumentEnabled)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 2,
                  class: "nav-item",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('recipient-documents')
                  }, _toDisplayString(_ctx.$t('recipient_documents')), 9, _hoisted_8)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.newRecipient && _ctx.isRecipientGeneralClinicalEnabled)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 3,
                  class: "nav-item",
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('gci')
                  }, _toDisplayString(_ctx.$t('general_clinical_information')), 9, _hoisted_9)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.newRecipient && _ctx.isRecipientSerologyEnabled)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 4,
                  class: "nav-item",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('recipient-serology')
                  }, _toDisplayString(_ctx.$t('recipient_serology')), 9, _hoisted_10)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.newRecipient && _ctx.isRecipientDeathEnabled)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 5,
                  class: "nav-item",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('recipient-death')
                  }, _toDisplayString(_ctx.$t('recipient_death')), 9, _hoisted_11)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}