import Vue from 'vue';
import { MutationTree } from 'vuex';
import { ObjectId } from '@/store/types';
import { errorType, TaskState, TaskType } from '@/store/tasks/types';

export const mutations: MutationTree<TaskState> = {
  setCurrentTask(state: TaskState, taskState: TaskType) {
    state.currentTask = taskState;
  },
  setPercentage(state: TaskState, percentage: number) {
    state.percentage = percentage;
  },
  setStatus(state: TaskState, status: string|undefined) {
    state.status = status;
  },
  resetCurrentTask(state: TaskState, taskState: TaskType) {
    state.currentTask = undefined;
  },
  setErrorState(state: TaskState, error: errorType) {
    state.errorState = error;
  },
  resetTask(state: TaskState) {
    [
      'currentTask',
      'percentage',
      'status',
      'errorState'
    ].forEach((key: string) => (state as any)[key] = undefined);
  }
};
