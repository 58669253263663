import { SaveResult } from '@/types';

export class UISuccess {
  private successResult: SaveResult;

  // Create a new UISuccess instance based on successful response returned by API
  public constructor(apiSuccess: any) {
    this.successResult = {
      success: true,
      responseData: apiSuccess.data,
    };
  }

  // Get the save result
  public getSaveResult(): SaveResult {
    return this.successResult;
  }
}
