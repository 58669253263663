<template>
  <card-section
    section-id="administration-ctr"
    @loaded="loaded()"
  >
    <template v-slot:header>
      CTR Event Log
    </template>
    <template v-slot:body>
      <sub-section
        mode="remote"
        :title="$t('ctr_sync_events')"
        sub-section-id="administration-ctr-events"
        tabbableValue="Date"
        :total-records="getCtrCount"
        :table-config="ctrIntegrationLogTableConfig()"
        @on-page-change="updatePagination($event)"
        @on-per-page-change="updatePagination($event)"
      >
        <template v-slot:table-cell="props">
          <!-- Link table cells -->
          <template v-if="props.column.field == 'entityId'">
            <router-link
              v-if="props.row.link"
              class="table-link"
              :to="{ name: props.row.link, params: { id: props.row.entityId } }"
              :title="`Link to ${props.row.type} Page`"
            >
              {{props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              {{props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- Other table cells -->
          <span v-else>
            {{props.formattedRow[props.column.field] }}
          </span>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-facing-decorator';
import { Component } from 'vue-facing-decorator';
import { CtrLogItem } from '@/store/administration/types';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { i18nMessages } from "@/i18n";

interface CtrLogRow {
  datetime: string; // used for sorting
  date: string;
  time: string;
  type: string;
  entityId: string;
  status: string;
  item: CtrLogItem;
  link: string|null;
}

@Component({
  components: {
    SubSection,
    CardSection,
    ModalSection,
  },
  ...i18nMessages([
    require('../_locales/common.json'),
    require('@/components/administration/_locales/CtrEventLog.json'),
  ]),
})
export default class CtrEventLog extends mixins(DateUtilsMixin) {
  @State(state => state.administration.ctrIntegrationLog) ctrIntegrationLog!: CtrLogItem[];

  @Getter('getCtrCount', { namespace: 'administration' }) private getCtrCount!: number;
  @Getter('getCtrLog', { namespace: 'administration' }) private getCtrLog!: CtrLogItem[];

  private isCardSectionLoaded = false;
  private isCtrLogLoaded = false;
  private logEntryMessage: any = null;

  private currentPage = 1;
  private perPage = 25;

  private loaded(): void {
    // Card section has finished loading lookups
    this.isCardSectionLoaded = true;
    this.checkIfLoadingComplete();
  }

  private mounted(): void {
    this.loadData();
  }

  private loadData() {
    this.$store.dispatch('administration/loadCtrIntegrationLog', {
      pageNumber: this.currentPage,
    }).then(() => {
      // Store has finished loading CTR integration log
      this.isCtrLogLoaded = true;
      this.checkIfLoadingComplete();
    }).catch(() => {
      // Could not load
    });
  }

  private updatePagination(event: any) {
    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;
    this.loadData();
  }

  private checkIfLoadingComplete(): void {
    // Check if both the card section lookups and the form-specific data have finished loading
    const isLoaded = this.isCardSectionLoaded && this.isCtrLogLoaded;
    if (isLoaded) {
      // Notify parent component that loading has completed
      this.$emit('loaded', 'ctrIntegrationLog');
    }
  }

  private describeCtrLogItemType(item: CtrLogItem): string|null {
    const recordTypes: string[] = [];
    if (item.recipient_id) {
      recordTypes.push('Recipient');
    }
    if (item.donor_id) {
      recordTypes.push('Donor');
    }
    return recordTypes.length > 0 ? recordTypes.join(' ') : null;
  }

  private routeNameBasedOnCtrLogItem(item: CtrLogItem): string|null {
    if (item.recipient_id) {
      return 'edit-recipient';
    } else if (item.donor_id) {
      return 'edit-deceased-donor';
    } else {
      return null;
    }
  }

  private buildTableRow(item: CtrLogItem): CtrLogRow {
    const datetime = item.date || item.updated_at || item.created_at;
    const status = item.status || '-';
    return {
      datetime,
      date: this.parseDisplayDateUiFromDateTime(datetime) || '-',
      time: this.parseTimeUiFromDateTime(datetime) || '-',
      type: this.describeCtrLogItemType(item) || '-',
      entityId: item.entity_id == null ? '-' : `${item.entity_id}`,
      status: item.status || '-',
      item,
      link : this.routeNameBasedOnCtrLogItem(item),
    };
  }

  get ctrIntegrationLogTableRows(): any[] {
    // Transform log items fetched from API into table rows formatted for the UI
    const logItems = this.getCtrLog;

    // const logItems: CtrLogItem[] = this.ctrIntegrationLog || [];
    const logRows = logItems.map((item: CtrLogItem): CtrLogRow => {
      return this.buildTableRow(item);
    });
    // Sort the UI rows
    const sorted = logRows.sort((a: CtrLogRow, b: CtrLogRow): number => {
      const aDate = a.datetime ? new Date(a.datetime) : new Date();
      const bDate = b.datetime ? new Date(b.datetime) : new Date();
      return bDate.getTime() - aDate.getTime();
    });
    return sorted;
  }

  private ctrIntegrationLogTableConfig(): TableConfig {
    return {
      data: this.ctrIntegrationLogTableRows,
      columns: [
        { label: 'Date', field: 'date', width: '10%' },
        { label: 'Time (EST)', field: 'time', width: '10%' },
        { label: 'Sync Type', field: 'type', width: '10%' },
        { label: this.$t('entity_id').toString(), field: 'entityId', width: '10%' },
        { label: 'Status', field: 'status' },
      ],
      empty: 'No Integration Sync Events found in the CTR Log',
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 25,
        mode: 'records',
        perPageDropdown: [25],
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: 'Results per page',
      },
    };
  }
}
</script>
