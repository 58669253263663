<template>
  <!-- journey state -->
  <sub-section
    v-if="editState"
    sub-section-id="journey-state"
    :title="$t('journey_state')"
  >
    <template v-slot:guiding_text>
      {{ $t('journey_state_guiding_text') }}
    </template>
    <template v-slot:contents>

      <table-toolbar
        :createButton="canSave && !newJourney"
        :createText="$t('create_new_entry')"
        @table-create-row="prepareNewEntry"
      />

      <table-list
        table-id="journey-table-list"
        ref="journeyTableList"
        tabbableColumn="effective_datetime_display"
        :table-config="journeyStateTableConfig"
        @table-row-click="handleTableRowClick"
        :highlightSelection="true"
        @onPageNumber="onPageNumber($event); loadData()"
        @onPageSize="onPageSize($event); loadData()"
        mode="remote"
        :rowStyleClass="rowStyleClass"
        :isLoading="isLoading"
        :totalRecords="totalRecords"
      />
      
      <!-- Form layout -->
      <validation-observer ref="validations">
        <form-layout
          :disabled="!canSave || newJourney || isDisabled"
          form-id="journey-sub-section-form"
        >
          <template v-slot:title>
            <!-- Mode indicator / subsection form title -->
            <legend>
              <h5 class="legend-title">
                {{ isEditing ? $t('selected_entry') : $t('new_entry') }}
              </h5>
            </legend>
            <template v-if="newJourney || !isDisabled">
              <small>{{ isEditing ? $t('selected_guiding_text') : $t('new_guiding_text') }}</small>
            </template>
          </template>

          <template v-slot:contents>
            <div class="row">
              <div class="standard-form-group">
                <select-input
                  selectId="journey-state-phase"
                  ruleKey="journeyState.phase"
                  :name="$t('phase')"
                  v-model="editState.phase"
                  :options="getPhaseOptions"
                  @change="resetJourneyStatus"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  selectId="journey-state-status"
                  ruleKey="journeyState.status"
                  :name="$t('status')"
                  v-model="editState.status"
                  :options="getStatusOptions"
                  :disabled="getStatusOptions.length === 0"
                  @change="resetJourneyReason"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  selectId="journey-state-reason"
                  ruleKey="journeyState.reason"
                  :name="$t('reason')"
                  v-model="editState.reason"
                  :options="getReasonOptions"
                  :disabled="!editState.status || getReasonOptions.length === 0"
                />
              </div>

              <div class="standard-form-group" v-if="isWorkflowStepEnabled">
                <select-input
                  selectId="journey-state-workflow-step"
                  :name="$t('workflow_step')"
                  v-model="editState.step"
                  :options="workflowStepLookup"
                />
              </div>

            </div>
            <div class="row">
              <div class="standard-form-group">
                <date-input
                  inputId="journey-state-effective-date"
                  :name="$t('effective_date')"
                  ruleKey="journeyState.effective_datetime"
                  v-model="editState.effectiveDate"
                />
              </div>
              <div class="standard-form-group">
                <time-input
                  inputId="journey-state-effective-time"
                  :name="$t('effective_time')"
                  ruleKey="journeyState.effective_datetime"
                  v-model="editState.effectiveTime" />
              </div>
            </div>
            <div class="row">
              <div class="standard-form-group-large">
                <text-area-input
                  input-id="journey-state-comment"
                  :name="$t('comment')"
                  ruleKey="journeyState.comment"
                  v-model="editState.comment"
                />
              </div>
            </div>
          </template>

          <template v-slot:save>
            <save-toolbar
              v-if="canSave && !newJourney && !isDisabled"
              ref="journeySaveToolbar"
              :label="$t('save_journey_state')"
              :cancelButton="true"
              @save="savePatch()"
              @cancel="cancelPatch()"
            />
          </template>
        </form-layout>
      </validation-observer>
    </template>
  </sub-section>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Component, Prop } from 'vue-facing-decorator';
import { GenericCodeValue } from '@/store/types';
import { TableConfig, SaveResult } from '@/types';
import { Recipient } from '@/store/recipients/types';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import TextInput from "@/components/shared/TextInput.vue";
import { RecipientJourney, CurrentStateHistory, APIStateHistoryResponse, UICurrentStateHistory } from '@/store/recipientJourney/types';
import TimeInput from '@/components/shared/TimeInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { mixins } from "vue-facing-decorator";
import { parseFormErrors, sortOptionsByValue } from '@/utils';
import { EP } from '@/api-endpoints';
import { APIPermittedActions } from '@/types';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import TableList from '@/components/shared/TableList.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import { i18nMessages } from '@/i18n';
import { IdLookup } from '@/store/validations/types';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { RemotePaginationMixin } from '@/mixins/remote-pagination-mixin';

interface JourneyStateForm {
  id?: string|null;
  phase: string|null;
  status: string|null;
  reason: string|null;
  effectiveDate: string|null|undefined;
  effectiveTime: string|null|undefined;
  comment: string|null;
  step: string|null;
}

interface PatchObject {
  phase?: string|null;
  status?: string|null;
  reason?: string|null;
  effective_datetime?: string|null;
  comment?: string|null;
  step?: string|null;
}

const SCROLL_TO_ID = "journey-sub-section-form";
const PAGE_SIZES = [10, 25, 50];
const DEFAULT_PAGE_SIZE = PAGE_SIZES[0]; // 10

@Component({
  components: {
    DateInput,
    SubSection,
    SaveToolbar,
    CardSection,
    SelectInput,
    CheckboxInput,
    TextInput,
    TimeInput,
    TextAreaInput,
    TableToolbar,
    TableList,
    FormLayout
  },
  ...i18nMessages([
    require('@/components/organs/shared/_locales/OrganDetailsSection.json'),
  ]),
})
export default class JourneyStateSection extends mixins(DateUtilsMixin, RemotePaginationMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.lookups.recipient_journey_phase_status) recipientJourneyPhaseStatus!: any[];
  @State(state => state.lookups.recipient_journey_workflow_step) workflowStepLookup!: GenericCodeValue[];

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('currentStateHistory', { namespace: 'journeyState' }) currentStateHistory!: CurrentStateHistory[];
  @Getter('defaultLookup', { namespace: 'lookups' }) defaultLookup!: (lookupId: string) => any;
  @Getter('isWorkflowStepEnabled', { namespace: 'configuration' }) private isWorkflowStepEnabled!: boolean;
  @Getter('mayUpdate', { namespace: 'validations' }) private mayUpdate!: (prefix: string|null) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private isLoading = true;
  private isEditing = false;
  private isDisabled = false;

  private stateHistoryIndex: CurrentStateHistory[] = [];
  private totalRecords = 0;

  private editState: JourneyStateForm = {
    phase: null,
    status: null,
    reason: null,
    effectiveDate: null,
    effectiveTime: null,
    comment: null,
    step: null
  };

  // Selection instance
  private selection = new UIListFormSelection();

  /**
   * Return true if organ details section section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
   get canEdit(): boolean{
    if (this.newJourney) {
      return true;
    }
    if (this.journey && this.journey.completed) {
      return false;
    }
    return true;
  }

  mounted() {
    this.perPage = DEFAULT_PAGE_SIZE;
    this.queryNewRules();
    this.loadData();
    this.initializeForm();
  }

  private queryNewRules(): void {
    if (this.newJourney) return;

    this.$store.dispatch('validations/loadValidations', {
      route: EP.recipients.journeys.state_history.new, 
      payload: [[':recipient_id', this.recipientId], [':journey_id', this.journeyId]],
      prefix: 'journeyState'
    });
  }

  public loadData(search='', sort='') {
    if (this.newJourney) {
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    this.$store.dispatch(
      'journeyState/loadStateHistoryIndex', {
      recipientId: this.recipientId, 
      journeyId: this.journeyId,
      params: {
        page_number: this.pageNumber,
        page_size: this.perPage
      }
    }).then((success: SaveResult) => {
      this.stateHistoryIndex = success.responseData.records;
      this.totalRecords = success.responseData.count;
      this.isLoading = false;
    });
  }

  // Resets Form Errors
  public resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  /**
   * Populates the Referral Details form state with data from the selected journey
   */
   public initializeForm(): void {
    this.resetFormErrors();
    this.editState = this.buildJourneyStateForm();
    this.selection = new UIListFormSelection();
  }

  /**
   * Generates Journey Details / Journey State forms based on the selected/new journey
   *
   * @returns {JourneyStateForm} Referral Details form state
  */
  private buildJourneyStateForm(): JourneyStateForm {
    const defaultPhaseOption = this.getDefaultPhaseOption();
    const defaultPhase = defaultPhaseOption ? this.defaultLookup("recipient_journey_phase_status") : null;
    const defaultStatus = defaultPhaseOption ? defaultPhaseOption.default_status : null;

    // if new journey
    if (!this.journey) {
      this.isDisabled = true;
      const result = {
        phase: defaultPhase,
        status: defaultStatus,
        reason: null,
        effectiveDate: null,
        effectiveTime: null,
        comment: null,
        step: null,
      };

      if (this.isWorkflowStepEnabled) {
        Object.assign(result, {
          step: this.defaultLookup('recipient_journey_workflow_step'),
        });
      }

      return result;
    }

    // otherwise blank form
    const result = {
      phase: null,
      status: null,
      reason: null,
      effectiveDate: null,
      effectiveTime: null,
      comment: null,
      step: null
    };

    if (this.isWorkflowStepEnabled) {
      Object.assign(result, {
        step: null,
      });
    }

    this.isEditing = false;

    return result;
  }

  scrollToMethod() {
    if (SCROLL_TO_ID) {
      // perform scrollTo
      const location = document.getElementById(SCROLL_TO_ID) || null;
      if (location) {
        const elementPosition = location.getBoundingClientRect().top;
        const offsetPosition = elementPosition - 105;
        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth"
        }); 
      }
    }
  }

  prepareNewEntry(scroll = true): void {
    this.resetFormErrors();
    (this.$refs.journeyTableList as TableList).resetSelection();
    this.selection = new UIListFormSelection();

    const form = {
      phase: null,
      status: null,
      reason: null,
      effectiveDate: null,
      effectiveTime: null,
      comment: null,
      step: null,
    };

    if (this.isWorkflowStepEnabled) {
      Object.assign(form, {
        step: null,
      });
    }

    this.editState = form;

    this.isEditing = false;
    this.isDisabled = false;    

    // Clear any errors
    if (scroll) { this.scrollToMethod(); }
    this.resetSaveToolbar();
  }

  handleTableRowClick(event: { row: UICurrentStateHistory }): void {
    const listItem = this.buildRows.find((listItem: UICurrentStateHistory) => { return listItem.id == event.row.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem.id);
    this.prepareEditEntry(this.selection);
  }

  prepareEditEntry(selection: UIListFormSelection, scroll = true) {
    const row = this.buildRows.find((listItem: UICurrentStateHistory) => { return listItem.id == selection.id; });
    if (!row) return;

    this.resetFormErrors();
    let form: JourneyStateForm;

    // if first entry, switch to edit
    if (row.editable) {
      this.$store.dispatch(
        'journeyState/loadStateHistory', {
        recipientId: this.recipientId, 
        journeyId: this.journeyId,
        id: row.id,
      }).then(({ success, responseData }) => {

        this.$store.dispatch('validations/loadValidations', {
          route: EP.recipients.journeys.state_history.edit, 
          payload: [[':recipient_id', this.recipientId], [':journey_id', this.journeyId], [':id', row.id]],
          prefix: 'journeyState'
        }).then(() => {
          // NOTE: show response includes 'state_history' record as well as a list of 'permitted_actions' (AP-800)
          const response: APIStateHistoryResponse = responseData;
          const record = response.state_history;
          const permittedActions = response.permitted_actions;

          this.isEditing = true;
          
          // use current_state to generate form values
          form = {
            id: record._id.$oid || null,
            phase: record.phase || null,
            status: record.status || null,
            reason: record.reason || null,
            effectiveDate: this.parseDateUiFromDateTime(record.effective_datetime || null),
            effectiveTime: this.parseTimeUiFromDateTime(record.effective_datetime || null),
            comment: record.comment || null,
            step: record.step || null
          };

          if (this.isWorkflowStepEnabled) {
            Object.assign(form, {
              step: record.step || null,
            });
          }

          this.editState = form;

          // NOTE: permitted actions list has 'update' only if user has write permissions (AP-800)
          this.isDisabled = !permittedActions.includes(APIPermittedActions.Update);
        });
      });
    } else {
      // display old entry
      form = {
        phase: row.phase || null,
        status: row.status || null,
        reason: row.reason || null,
        effectiveDate: this.parseDateUiFromDateTime(row.effective_datetime || null),
        effectiveTime: this.parseTimeUiFromDateTime(row.effective_datetime || null),
        comment: row.comment || null,
        step: row.step || null
      };

      if (this.isWorkflowStepEnabled) {
        Object.assign(form, {
          step: row.step || null,
        });
      }

      this.editState = form;
      this.isDisabled = true;
    }

    this.isEditing = true;
    if (scroll) { this.scrollToMethod(); }
    this.resetSaveToolbar();
  }

  get getColumns() {
    return [
      {
        label: this.$t('comment'),
        field: "comment",
        sortable: false,
        hidden: true,
      },
      {
        label: this.$t('workflow_step'),
        field: "step",
        sortable: false,
        hidden: true,
      },
      {
        label: this.$t('effective_date_time'),
        field: "effective_datetime",
        sortable: false,
        hidden: true,
        width: '125px'
      },
      {
        label: this.$t('effective_date_time'),
        field: "effective_datetime_display",
        sortable: false,
        width: '120px'
      },
      { 
        label: this.$t('phase'),
        field: "phase_display",
        sortable: false,
        width: '120px'
      },
      { 
        label: this.$t('status'),
        field: "status_display",
        sortable: false,
        width: '120px'
      },
      { 
        label: this.$t('reason'),
        field: "reason_display",
        sortable: false,
        width: '120px'

      },
      {
        label: this.$t('workflow_step').toString(),
        field: "step_display",
        sortable: false,
        hidden: !this.isWorkflowStepEnabled,
        width: '185px'
      },
      { 
        label: this.$t('entered_by'),
        field: "updated_by",
        sortable: false,
        width: '135px'
      },
      {
        label: this.$t('number_of_days_in_this_step'),
        field: "duration_of_state_seconds",
        sortable: false,
         width: '120px'
      },
    ];
  }

  get getPhaseOptions(): any {
    return this.buildPhaseOptions();
  }

  get getStatusOptions(): any {
    return this.buildStatusOptions(this.editState.phase);
  }

  get getReasonOptions(): any {
    return this.buildReasonOptions(this.editState.phase, this.editState.status);
  }

  get buildRows(): UICurrentStateHistory[] {
    const phaseOptions = this.recipientJourneyPhaseStatus || [];

    const records = this.stateHistoryIndex.map((record: CurrentStateHistory) => {

      let phaseValue = null;
      let statusValue = null;
      let reasonValue = null;

      // get phase
      if (record.phase) {
        const phase = phaseOptions.find((item: any) => { return item.code === record.phase; });
        if (phase) phaseValue = phase.value;
        // get status
        if (phase && record.status) {
          const status = phase.sub_tables.statuses.find((item: any) => { return item.code === record.status; });
          if (status) statusValue = status.value;
          // get reason
          if (status && record.reason) {
            const reason = status.sub_tables.reasons.find((item: any) => { return item.code === record.reason; });
            if (reason) reasonValue = reason.value;
          }
        }
      }

      const number_of_days_in_this_step = this.formatSecondsToDays(record.effective_datetime, record.duration_of_state_seconds);

      const result = {
        effective_datetime: record.effective_datetime || null,
        effective_datetime_display: record.effective_datetime ? this.parseFormattedDateTimeUi(record.effective_datetime) : null,
        phase: record.phase,
        phase_display: phaseValue ? phaseValue : '--',
        status: record.status,
        status_display: statusValue ? statusValue : '--',
        reason: record.reason,
        reason_display: reasonValue ? reasonValue : '--',
        step: record.step,
        updated_by: record.updated_by,
        duration_of_state_seconds: number_of_days_in_this_step,
        id: record._id.$oid,
        comment: record.comment || null,
        editable: record.permitted_actions.includes('update') || false,
      };

      if (this.isWorkflowStepEnabled) {
        let stepValue = null;
        if (record.step) {
          const step = this.workflowStepLookup.find((item: any) => { return item.code === record.step; });
          if (step) stepValue = step.value;
        }
        Object.assign(result, {
          step: record.step,
          step_display: stepValue ? stepValue : '--',
        });
      }

      return result;
    });
    return records;
  }

  get journeyStateTableConfig(): TableConfig {
    return {
      data: this.buildRows,
      columns: this.getColumns,
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        position: 'bottom'
      }
    };
  }

  rowStyleClass(row: UICurrentStateHistory) {
    // if row editable, change color
    return row.editable ? 'tr-highlight-green' : null;
  }

  buildPhaseOptions() {
    const possibleOptions = this.recipientJourneyPhaseStatus || [];
    return sortOptionsByValue(possibleOptions);
  }

  buildStatusOptions(phase: string|null) {
    if (!phase) return [];
    const statusOptions = this.recipientJourneyPhaseStatus.find((item: GenericCodeValue) => { return item.code === phase; });
    const possibleOptions = statusOptions && statusOptions.sub_tables ? statusOptions.sub_tables.statuses : [];
    return sortOptionsByValue(possibleOptions);
  }

  buildReasonOptions(phase: string|null, status: string|null) {
    if (!phase) return [];
    if (!status) return [];
    const statusOptions = this.recipientJourneyPhaseStatus.find((item: GenericCodeValue) => { return item.code === phase; });
    const reasonOptions = statusOptions.sub_tables.statuses.find((item: GenericCodeValue) => { return item.code === status; });
    return reasonOptions && reasonOptions.sub_tables && reasonOptions.sub_tables.reasons ? sortOptionsByValue(reasonOptions.sub_tables.reasons) : [];
  }

  resetJourneyStatus() {
    this.editState.status = null;
    this.editState.reason = null;
  }

  resetJourneyReason() {
    this.editState.reason = null;
  }

  getDefaultPhaseOption() {
    const phaseOptions = this.recipientJourneyPhaseStatus || [];
    const defaultOption = phaseOptions.find((item: GenericCodeValue) => { return item.default === true; });
    return defaultOption ? defaultOption : null;
  }

  // Patch request payload for 'Journey State' sub-section
  public extractPatch(): PatchObject {
    const result = {
      phase: this.editState.phase,
      status: this.editState.status,
      reason: this.editState.reason,
      effective_datetime: this.sanitizeDateTimeApi(this.editState.effectiveDate, this.editState.effectiveTime),
      comment: this.editState.comment
    };

    if (this.isWorkflowStepEnabled) {
      Object.assign(result, {
        step: this.editState.step,
      });
    }

    return result;
  }

  /**
   * Saves the form edit state.
   * 
   * Prepares a payload for transplant details, recipient coordinator and urgent.
   * Dispatches a save action, and registers the save result.
  */
  public savePatch(): void {
    // Refer to the save toolbar
    const saveToolbar = this.$refs.journeySaveToolbar as SaveToolbar;
    if (saveToolbar) saveToolbar.startSaving();
    // Generate payload from the current editState
    const payload = {
      state_history: this.extractPatch()
    };

    // Attempt to save
    this.$store.dispatch('journeyState/saveStateHistory', { 
      payload: payload,
      recipientId: this.recipientId, 
      journeyId: this.journeyId,       
      id: this.editState.id || null
    }).then((success: SaveResult) => {
      // Reload index
      this.loadData();
      (this.$refs.journeyTableList as TableList).resetSelection();
      // Re-initialize form
      this.initializeForm();
      // Register save result
      saveToolbar.stopSaving(success);
    }).catch((error: SaveResult) => {
      this.handleErrors(error);
      saveToolbar.stopSaving(error);
    });
  }

  public cancelPatch(): void {
    // Re-initialize form
    if (this.selection && this.selection.id) {
      this.prepareEditEntry(this.selection, false);
    } else {
      this.prepareNewEntry(false);
    }
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);
  }

  /**
   * Resets the save toolbar
   */
   public resetSaveToolbar(): void {
    // Refer to the save toolbar that handle the areas present on this form component
    const saveToolbar = this.$refs.journeySaveToolbar as SaveToolbar;
    // Reset the save toolbar
    if (saveToolbar) saveToolbar.reset();
  }

// API response keys on the left, id for our UI on the right
public idLookup(): IdLookup {
  return {
    "phase"              : "journey-state-phase",
    "status"             : "journey-state-status",
    "reason"             : "journey-state-reason",
    "effective_datetime" : ["journey-state-effective-date", "journey-state-effective-time"],
    "comment"            : "journey-state-comment",
  };
}
}
</script>
