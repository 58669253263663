<template>
  <div :id="formId">
    <!-- Mode indicator / subsection form title -->
    <slot name="title" />

    <!-- Action Toolbar -->
    <slot name="action" />
      
    <fieldset :disabled="disabled">

      <!-- Data Entry Form (Add, View, and Edit modes) -->
      <slot name="contents" />

      <!-- Save Toolbar -->
      <slot name="save" />
    </fieldset>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component
export default class FormLayout extends Vue {
  @Prop({ required: true }) formId!: string;
  @Prop({ default: false }) disabled!: boolean;
}
</script>
