import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  class: "legend-title",
  id: "hla-selected-vxm"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { for: "vxm-summary-selected-result" }
const _hoisted_10 = {
  class: "form-text text-muted",
  title: "Calculated"
}
const _hoisted_11 = {
  id: "vxm-summary-selected-result",
  class: "form-control",
  readonly: "readonly"
}
const _hoisted_12 = { class: "table-responsive-md" }
const _hoisted_13 = { class: "table table-bordered vgt-table" }
const _hoisted_14 = {
  scope: "col",
  width: "10%"
}
const _hoisted_15 = {
  scope: "col",
  width: "22.5%"
}
const _hoisted_16 = {
  scope: "col",
  width: "22.5%"
}
const _hoisted_17 = {
  scope: "col",
  width: "22.5%"
}
const _hoisted_18 = {
  scope: "col",
  width: "22.5%"
}
const _hoisted_19 = {
  key: 0,
  colspan: "100%"
}
const _hoisted_20 = ["title"]
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "legend-title" }
const _hoisted_24 = { class: "nav action-row cumulative-sera" }
const _hoisted_25 = ["disabled"]
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_hla_virtual_crossmatch_result = _resolveComponent("hla-virtual-crossmatch-result")!
  const _component_hla_input_group = _resolveComponent("hla-input-group")!
  const _component_hla_input = _resolveComponent("hla-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_hla_antibody_filter_table = _resolveComponent("hla-antibody-filter-table")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, null, {
    default: _withCtx(() => [
      _createVNode(_component_sub_section, {
        title: _ctx.$t('view_crossmatch_history'),
        "sub-section-id": "hla-vxm-history",
        "table-config": _ctx.vxmHistoryTableConfig,
        onTableRowClick: _ctx.selectVxmHistoryRow,
        class: "view-virtual-crossmatch"
      }, {
        contents: _withCtx(() => [
          (!_ctx.editState || !_ctx.editState)
            ? (_openBlock(), _createElementBlock("fieldset", _hoisted_1, _toDisplayString(_ctx.$t('loading')), 1))
            : (_openBlock(), _createElementBlock("fieldset", _hoisted_2, [
                _createElementVNode("legend", null, [
                  _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.$t('selected_vxm')), 1)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_text_input, {
                      "input-id": "vxm-summary-selected-date",
                      name: _ctx.$t('date'),
                      modelValue: _ctx.editState.history.date,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.history.date) = $event)),
                      disabled: true,
                      title: _ctx.editState.history.time
                    }, null, 8, ["name", "modelValue", "title"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_text_input, {
                      "input-id": "vxm-summary-selected-recipient-id",
                      name: _ctx.$t('recipient_id'),
                      modelValue: _ctx.editState.history.recipientId,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.history.recipientId) = $event)),
                      disabled: true
                    }, null, 8, ["name", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_text_input, {
                      "input-id": "vxm-summary-selected-donor-id",
                      name: _ctx.$t('donor_id'),
                      modelValue: _ctx.editState.history.donorId,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.history.donorId) = $event)),
                      disabled: true
                    }, null, 8, ["name", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('result')), 1),
                    _createElementVNode("small", _hoisted_10, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "text-grey",
                        icon: ['far', 'exclamation-circle'],
                        "fixed-width": ""
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('calculated')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_hla_virtual_crossmatch_result, {
                        result: _ctx.editState.history.result
                      }, null, 8, ["result"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("table", _hoisted_13, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.$t('vxm')), 1),
                        _createElementVNode("th", _hoisted_15, _toDisplayString(_ctx.$t('class_stat', {class: _ctx.$t('class_i'), stat: _ctx.$t('current')})), 1),
                        _createElementVNode("th", _hoisted_16, _toDisplayString(_ctx.$t('class_stat', {class: _ctx.$t('class_i'), stat: _ctx.$t('cumulative')})), 1),
                        _createElementVNode("th", _hoisted_17, _toDisplayString(_ctx.$t('class_stat', {class: _ctx.$t('class_ii'), stat: _ctx.$t('current')})), 1),
                        _createElementVNode("th", _hoisted_18, _toDisplayString(_ctx.$t('class_stat', {class: _ctx.$t('class_ii'), stat: _ctx.$t('cumulative')})), 1)
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      (!_ctx.editState.history || !_ctx.editState.history.vxm)
                        ? (_openBlock(), _createElementBlock("td", _hoisted_19, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: ['far', 'exclamation-circle'],
                              "fixed-width": ""
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('select_row')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", null, _toDisplayString(_ctx.$t('result')), 1),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_virtual_crossmatch_result, {
                                  result: _ctx.editState.history.vxm.currentClass1Result
                                }, null, 8, ["result"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_virtual_crossmatch_result, {
                                  result: _ctx.editState.history.vxm.cumulativeClass1Result
                                }, null, 8, ["result"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_virtual_crossmatch_result, {
                                  result: _ctx.editState.history.vxm.currentClass2Result
                                }, null, 8, ["result"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_virtual_crossmatch_result, {
                                  result: _ctx.editState.history.vxm.cumulativeClass2Result
                                }, null, 8, ["result"])
                              ])
                            ]),
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", null, _toDisplayString(_ctx.$t('unacceptable_dsa')), 1),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input_group, {
                                  inputId: "vxm-summary-table-unacceptable_class1_current",
                                  modelValue: _ctx.editState.history.vxm.currentClass1UnacceptableAlleleGroup,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.history.vxm.currentClass1UnacceptableAlleleGroup) = $event)),
                                  enableEpitopes: true,
                                  alleleSpecific: _ctx.editState.history.vxm.currentClass1UnacceptableAlleleSpecific,
                                  epitopes: _ctx.editState.history.vxm.currentClass1UnacceptableEpitopes,
                                  disabled: true,
                                  inputClass: "hla-input hla-unacceptable border-0",
                                  name: _ctx.$t('unacceptable'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[4] || (_cache[4] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.currentClass1Result
                                }, null, 8, ["modelValue", "alleleSpecific", "epitopes", "name", "selectedAntibodies", "vxm"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input_group, {
                                  inputId: "vxm-summary-table-unacceptable_class1_cumulative",
                                  modelValue: _ctx.editState.history.vxm.cumulativeClass1UnacceptableAlleleGroup,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.history.vxm.cumulativeClass1UnacceptableAlleleGroup) = $event)),
                                  enableEpitopes: true,
                                  alleleSpecific: _ctx.editState.history.vxm.cumulativeClass1UnacceptableAlleleSpecific,
                                  epitopes: _ctx.editState.history.vxm.cumulativeClass1UnacceptableEpitopes,
                                  disabled: true,
                                  inputClass: "hla-input hla-unacceptable border-0",
                                  name: _ctx.$t('unacceptable'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[6] || (_cache[6] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.cumulativeClass1Result
                                }, null, 8, ["modelValue", "alleleSpecific", "epitopes", "name", "selectedAntibodies", "vxm"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input_group, {
                                  inputId: "vxm-summary-table-unacceptable_class2_current",
                                  modelValue: _ctx.editState.history.vxm.currentClass2UnacceptableAlleleGroup,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.history.vxm.currentClass2UnacceptableAlleleGroup) = $event)),
                                  alleleSpecific: _ctx.editState.history.vxm.currentClass2UnacceptableAlleleSpecific,
                                  alphaBeta: _ctx.editState.history.vxm.currentClass2UnacceptableAlphaBeta,
                                  disabled: true,
                                  inputClass: "hla-input hla-unacceptable border-0",
                                  name: _ctx.$t('unacceptable'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[8] || (_cache[8] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.currentClass2Result
                                }, null, 8, ["modelValue", "alleleSpecific", "alphaBeta", "name", "selectedAntibodies", "vxm"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input_group, {
                                  inputId: "vxm-summary-table-unacceptable_class2_cumulative",
                                  modelValue: _ctx.editState.history.vxm.cumulativeClass2UnacceptableAlleleGroup,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.history.vxm.cumulativeClass2UnacceptableAlleleGroup) = $event)),
                                  alleleSpecific: _ctx.editState.history.vxm.cumulativeClass2UnacceptableAlleleSpecific,
                                  alphaBeta: _ctx.editState.history.vxm.cumulativeClass2UnacceptableAlphaBeta,
                                  disabled: true,
                                  inputClass: "hla-input hla-unacceptable border-0",
                                  name: _ctx.$t('unacceptable'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[10] || (_cache[10] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.cumulativeClass2Result
                                }, null, 8, ["modelValue", "alleleSpecific", "alphaBeta", "name", "selectedAntibodies", "vxm"])
                              ])
                            ]),
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", null, _toDisplayString(_ctx.$t('indeterminate_dsa')), 1),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input_group, {
                                  inputId: "vxm-summary-table-indeterminate_class1_current",
                                  modelValue: _ctx.editState.history.vxm.currentClass1IndeterminateAlleleGroup,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.history.vxm.currentClass1IndeterminateAlleleGroup) = $event)),
                                  enableEpitopes: true,
                                  alleleSpecific: _ctx.editState.history.vxm.currentClass1IndeterminateAlleleSpecific,
                                  epitopes: _ctx.editState.history.vxm.currentClass1IndeterminateEpitopes,
                                  disabled: true,
                                  inputClass: "hla-input hla-indeterminate border-0",
                                  name: _ctx.$t('indeterminate'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[12] || (_cache[12] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.currentClass1Result
                                }, null, 8, ["modelValue", "alleleSpecific", "epitopes", "name", "selectedAntibodies", "vxm"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input_group, {
                                  inputId: "vxm-summary-table-indeterminate_class1_cumulative",
                                  modelValue: _ctx.editState.history.vxm.cumulativeClass1IndeterminateAlleleGroup,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.history.vxm.cumulativeClass1IndeterminateAlleleGroup) = $event)),
                                  enableEpitopes: true,
                                  alleleSpecific: _ctx.editState.history.vxm.cumulativeClass1IndeterminateAlleleSpecific,
                                  epitopes: _ctx.editState.history.vxm.cumulativeClass1IndeterminateEpitopes,
                                  disabled: true,
                                  inputClass: "hla-input hla-indeterminate border-0",
                                  name: _ctx.$t('indeterminate'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[14] || (_cache[14] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.cumulativeClass1Result
                                }, null, 8, ["modelValue", "alleleSpecific", "epitopes", "name", "selectedAntibodies", "vxm"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input_group, {
                                  inputId: "vxm-summary-table-indeterminate_class2_current",
                                  modelValue: _ctx.editState.history.vxm.currentClass2IndeterminateAlleleGroup,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.history.vxm.currentClass2IndeterminateAlleleGroup) = $event)),
                                  alleleSpecific: _ctx.editState.history.vxm.currentClass2IndeterminateAlleleSpecific,
                                  alphaBeta: _ctx.editState.history.vxm.currentClass2IndeterminateAlphaBeta,
                                  disabled: true,
                                  inputClass: "hla-input hla-indeterminate border-0",
                                  name: _ctx.$t('indeterminate'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[16] || (_cache[16] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.currentClass2Result
                                }, null, 8, ["modelValue", "alleleSpecific", "alphaBeta", "name", "selectedAntibodies", "vxm"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input_group, {
                                  inputId: "vxm-summary-table-indeterminate_class2_cumulative",
                                  modelValue: _ctx.editState.history.vxm.cumulativeClass2IndeterminateAlleleGroup,
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState.history.vxm.cumulativeClass2IndeterminateAlleleGroup) = $event)),
                                  alleleSpecific: _ctx.editState.history.vxm.cumulativeClass2IndeterminateAlleleSpecific,
                                  alphaBeta: _ctx.editState.history.vxm.cumulativeClass2IndeterminateAlphaBeta,
                                  disabled: true,
                                  inputClass: "hla-input hla-indeterminate  border-0",
                                  name: _ctx.$t('indeterminate'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[18] || (_cache[18] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.cumulativeClass2Result
                                }, null, 8, ["modelValue", "alleleSpecific", "alphaBeta", "name", "selectedAntibodies", "vxm"])
                              ])
                            ]),
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", null, _toDisplayString(_ctx.$t('possible_allele_specific')), 1),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input, {
                                  inputId: "vxm-summary-table-possible-allele-specific-class1-current",
                                  modelValue: _ctx.editState.history.vxm.currentClass1PossibleAlleleSpecific,
                                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editState.history.vxm.currentClass1PossibleAlleleSpecific) = $event)),
                                  disabled: true,
                                  inputClass: "hla-input hla-possible-allele-specific border-0",
                                  name: _ctx.$t('possible_allele_specific'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[20] || (_cache[20] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.currentClass1Result
                                }, null, 8, ["modelValue", "name", "selectedAntibodies", "vxm"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input, {
                                  inputId: "vxm-summary-table-possible-allele-specific-class1-cumulative",
                                  modelValue: _ctx.editState.history.vxm.cumulativeClass1PossibleAlleleSpecific,
                                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.editState.history.vxm.cumulativeClass1PossibleAlleleSpecific) = $event)),
                                  disabled: true,
                                  inputClass: "hla-input hla-possible-allele-specific border-0",
                                  name: _ctx.$t('possible_allele_specific'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[22] || (_cache[22] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.cumulativeClass1Result
                                }, null, 8, ["modelValue", "name", "selectedAntibodies", "vxm"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input, {
                                  inputId: "vxm-summary-table-possible-allele-specific-class2-current",
                                  modelValue: _ctx.editState.history.vxm.currentClass2PossibleAlleleSpecific,
                                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.editState.history.vxm.currentClass2PossibleAlleleSpecific) = $event)),
                                  disabled: true,
                                  inputClass: "hla-input hla-possible-allele-specific border-0",
                                  name: _ctx.$t('possible_allele_specific'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[24] || (_cache[24] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.currentClass2Result
                                }, null, 8, ["modelValue", "name", "selectedAntibodies", "vxm"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input, {
                                  inputId: "vxm-summary-table-possible-allele-specific-class2-cumulative",
                                  modelValue: _ctx.editState.history.vxm.cumulativeClass2PossibleAlleleSpecific,
                                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.editState.history.vxm.cumulativeClass2PossibleAlleleSpecific) = $event)),
                                  disabled: true,
                                  inputClass: "hla-input hla-possible-allele-specific border-0",
                                  name: _ctx.$t('possible_allele_specific'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[26] || (_cache[26] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.cumulativeClass2Result
                                }, null, 8, ["modelValue", "name", "selectedAntibodies", "vxm"])
                              ])
                            ]),
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", null, _toDisplayString(_ctx.$t('untested')), 1),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input, {
                                  inputId: "vxm-summary-table-untested-class1-current",
                                  modelValue: _ctx.editState.history.vxm.currentClass1Untested,
                                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.editState.history.vxm.currentClass1Untested) = $event)),
                                  disabled: true,
                                  inputClass: "hla-input hla-untested border-0",
                                  name: _ctx.$t('untested'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[28] || (_cache[28] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.currentClass1Result
                                }, null, 8, ["modelValue", "name", "selectedAntibodies", "vxm"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input, {
                                  inputId: "vxm-summary-table-untested-class1-cumulative",
                                  modelValue: _ctx.editState.history.vxm.cumulativeClass1Untested,
                                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.editState.history.vxm.cumulativeClass1Untested) = $event)),
                                  disabled: true,
                                  inputClass: "hla-input hla-untested border-0",
                                  name: _ctx.$t('untested'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[30] || (_cache[30] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.cumulativeClass1Result
                                }, null, 8, ["modelValue", "name", "selectedAntibodies", "vxm"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input, {
                                  inputId: "vxm-summary-table-untested-class2-current",
                                  modelValue: _ctx.editState.history.vxm.currentClass2Untested,
                                  "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.editState.history.vxm.currentClass2Untested) = $event)),
                                  disabled: true,
                                  inputClass: "hla-input hla-untested border-0",
                                  name: _ctx.$t('untested'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[32] || (_cache[32] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.currentClass2Result
                                }, null, 8, ["modelValue", "name", "selectedAntibodies", "vxm"])
                              ]),
                              _createElementVNode("td", null, [
                                _createVNode(_component_hla_input, {
                                  inputId: "vxm-summary-table-untested-class2-cumulative",
                                  modelValue: _ctx.editState.history.vxm.cumulativeClass2Untested,
                                  "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.editState.history.vxm.cumulativeClass2Untested) = $event)),
                                  disabled: true,
                                  inputClass: "hla-input hla-untested border-0",
                                  name: _ctx.$t('untested'),
                                  "hide-label": true,
                                  highlightSelection: true,
                                  onSelected: _cache[34] || (_cache[34] = (selectedAntibody) => _ctx.toggleSelectedAntibody(selectedAntibody)),
                                  selectedAntibodies: _ctx.editState.entries.selectedAntibodies,
                                  vxm: _ctx.editState.history.vxm.cumulativeClass2Result
                                }, null, 8, ["modelValue", "name", "selectedAntibodies", "vxm"])
                              ])
                            ]),
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", null, _toDisplayString(_ctx.$t('messages')), 1),
                              _createElementVNode("td", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.history.vxm.currentClass1Messages, (message, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    class: "hla-vxm-message",
                                    key: `vxm-summary-table-messages-class1-current-${index}`
                                  }, _toDisplayString(message), 1))
                                }), 128))
                              ]),
                              _createElementVNode("td", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.history.vxm.cumulativeClass1Messages, (message, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    class: "hla-vxm-message",
                                    key: `vxm-summary-table-messages-class1-cummulative-${index}`
                                  }, _toDisplayString(message), 1))
                                }), 128))
                              ]),
                              _createElementVNode("td", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.history.vxm.currentClass2Messages, (message, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    class: "hla-vxm-message",
                                    key: `vxm-summary-table-messages-class2-current-${index}`
                                  }, _toDisplayString(message), 1))
                                }), 128))
                              ]),
                              _createElementVNode("td", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.history.vxm.cumulativeClass2Messages, (message, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    class: "hla-vxm-message",
                                    key: `vxm-summary-table-messages-class2-cumulative-${index}`
                                  }, _toDisplayString(message), 1))
                                }), 128))
                              ])
                            ])
                          ], 64))
                    ])
                  ])
                ])
              ]))
        ]),
        "table-cell": _withCtx((props) => [
          (props.column.field == 'date')
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                title: props.row.time
              }, _toDisplayString(props.formattedRow[props.column.field]), 9, _hoisted_20))
            : (props.column.field == 'result')
              ? (_openBlock(), _createBlock(_component_hla_virtual_crossmatch_result, {
                  key: 1,
                  result: props.row.result
                }, null, 8, ["result"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createTextVNode(_toDisplayString(props.formattedRow[props.column.field]), 1)
                ], 64))
        ]),
        _: 1
      }, 8, ["title", "table-config", "onTableRowClick"]),
      _createVNode(_component_sub_section, {
        title: _ctx.$t('entries'),
        "sub-section-id": "vxm-entries"
      }, {
        contents: _withCtx(() => [
          (!_ctx.editState || !_ctx.editState.entries)
            ? (_openBlock(), _createElementBlock("fieldset", _hoisted_21, _toDisplayString(_ctx.$t('loading')), 1))
            : (_openBlock(), _createElementBlock("fieldset", _hoisted_22, [
                _createElementVNode("h5", _hoisted_23, _toDisplayString(_ctx.$t('selected_antibodies')), 1),
                _createElementVNode("nav", _hoisted_24, [
                  _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass(["btn btn-light btn-sm mt-2 mr-2", { disabled: _ctx.zeroAntibodiesSelected }]),
                    onClick: _cache[35] || (_cache[35] = _withModifiers(($event: any) => (_ctx.removeAllSelectedAntibodies()), ["prevent"])),
                    disabled: _ctx.zeroAntibodiesSelected
                  }, _toDisplayString(_ctx.$t('clear_filters')), 11, _hoisted_25),
                  (!_ctx.zeroAntibodiesSelected)
                    ? (_openBlock(), _createBlock(_component_hla_input, {
                        key: 0,
                        modelValue: _ctx.editState.entries.selectedAntibodies,
                        "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.editState.entries.selectedAntibodies) = $event)),
                        inputId: "vxm-entries-selected-antibodies",
                        inputClass: "hla-cumulative",
                        name: _ctx.$t('selected_antibodies'),
                        onSelected: _ctx.removeSelectedAntibody,
                        hideLabel: true,
                        disabled: true
                      }, null, 8, ["modelValue", "name", "onSelected"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("div", _hoisted_27, [
                    _createVNode(_component_select_input, {
                      selectId: "vxm-entries-testingmethod",
                      name: _ctx.$t('testing_method'),
                      modelValue: _ctx.editState.entries.testingMethod,
                      "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.editState.entries.testingMethod) = $event)),
                      options: _ctx.hlaTestingMethodLookup,
                      "undefined-text": _ctx.$t('all')
                    }, null, 8, ["name", "modelValue", "options", "undefined-text"])
                  ])
                ])
              ]))
        ]),
        _: 1
      }, 8, ["title"]),
      (_ctx.editState && _ctx.editState.entries)
        ? (_openBlock(), _createBlock(_component_hla_antibody_filter_table, {
            key: 0,
            id: "vxm-filter-table",
            "table-config": _ctx.vxmFilterTableConfig,
            "testing-method": _ctx.editState.entries.testingMethod,
            "selected-antibodies": _ctx.editState.entries.selectedAntibodies
          }, null, 8, ["table-config", "testing-method", "selected-antibodies"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}