export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "crossmatch_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crossmatch Detail"])},
        "save_crossmatch_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Crossmatch Detail"])},
        "new_crossmatch_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Crossmatch Detail"])},
        "selected_crossmatch_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Crossmatch Detail"])},
        "serum_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serum Date"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient ID"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
        "allo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allo"])},
        "t_cell_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T Cell Result"])},
        "b_cell_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B Cell Result"])},
        "treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment"])},
        "pronase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronase?"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "dsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DSA?"])},
        "cannot_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot save: see error messages above"])},
        "invalid_recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Recipient ID"])},
        "create_crossmatch_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Crossmatch Detail"])},
        "use_form_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use the form below to add a new Crossmatch Detail"])}
      },
      "fr": {
        "crossmatch_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail du test croisé"])},
        "save_crossmatch_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder le détail du test croisé"])},
        "new_crossmatch_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau détail du test croisé"])},
        "selected_crossmatch_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail du test croisé sélectionné"])},
        "serum_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du sérum"])},
        "recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Patient"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
        "allo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allo"])},
        "t_cell_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat des cellules T"])},
        "b_cell_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat des cellules B"])},
        "treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement"])},
        "pronase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronase"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "dsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DSA"])},
        "cannot_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de sauver : voyez les messages d'erreur ci-dessus"])},
        "invalid_recipient_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Patient incorrect"])},
        "create_crossmatch_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un détail du test croisé"])},
        "use_form_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisez le formulaire ci-dessous pour ajouter un nouveau détail du test croisé."])}
      }
    }
  })
}
