<template>
  <validation-provider 
    ref="provider"
    :rules="formRules" 
    :name="inputId" 
    :label="name"
    :vid="validationId ? validationId : inputId" 
    v-model="validatedValue"
    v-slot="{ errors }">
    <p class="p-label" :class="{ 'sr-only': hideLabel }" v-if="name">
      {{ name }}
      <!-- don't show asterisk if disabled -->
      <validation-asterisk
        v-if="!disabled"
        :rules="formRules"
        :crossValues="crossValues"
        :ruleKey="ruleKey"
      />
    </p>
    <div
      class="form-check form-check-inline"
      :class="{ 'is-invalid': errors[0] }"
      v-for="option in options" :key="option.code"
    >
      <input
        type="checkbox"
        class="form-check-input"
        :id="`${inputId}-${option.code}`"
        :name="`${inputId}-${option.code}`"
        :checked="localValue[option.code]"
        @change="onLocalValueChange(option.code)"
        :disabled="option.disabled"
      />
      <label class="form-check-label" :for="`${inputId}-${option.code}`">{{ option.value }}</label>
    </div>
    <!-- error block, not shown if disabled -->
    <div
      v-if="errors[0] && !disabled"
      class="invalid-feedback"
      :id="`${inputId}-error`"
    >
      <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
      {{ translateError(errors, name) }}
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { Getter } from 'vuex-facing-decorator';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { GenericCodeValue } from '@/store/types';

interface CheckboxGroupState {
  [key: string]: boolean;
}

@Component({
  components: {
    ValidationAsterisk,
    CheckboxInput,
  },
  emits: [
    'update:modelValue',
  ],
})
export default class CheckboxGroup extends Vue {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // V-model
  @Prop({ default: () => { return [] } }) modelValue!: string[];

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) options!: GenericCodeValue[]; // array of checkboxes

  // Optional properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) name!: string; // Optional label to prepend the checkboxes
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers
  @Prop({ default: false }) disabled!: boolean; // Sets the input to disabled

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix

  // Initialize local representation of model
  private localValue: CheckboxGroupState = {};

  private validatedValue: string[] = [];

  get getValue(): string[] {
    return this.modelValue;
  }

  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  /**
   * Map from parent v-model string array to internal input component state object
   *
   * @param valueArray string array from parent v-model
   * @returns {string[]} input component state instance
   */
  private localValueFromValueArray(valueArray: string[]): CheckboxGroupState {
    if (!valueArray || !this.options) return {};

    const result: CheckboxGroupState = {};
    (this.options || []).forEach((option: GenericCodeValue) => {
      result[option.code] = valueArray.includes(option.code);
    });
    return result;
  }

  /**
   * Map from internal input component state to external v-model state for parent
   *
   * @param localValue input component state instance
   * @returns {string[]} value array for parent v-model
   */
  private valueArrayFromLocalValue(localValue: CheckboxGroupState): string[] {
    const result: string[] = [];
    this.options.forEach((option: GenericCodeValue) => {
      if (localValue[option.code]) result.push(option.code);
    });
    return result;
  }

  mounted(): void {
    this.localValue = this.localValueFromValueArray(this.modelValue);
  }

  // Handle interaction with one of the nested checkboxes by mapping by bubbling an event to parent
  onLocalValueChange(code: string): void {
    const isChecked = !!this.localValue[code];
    this.localValue[code] = !isChecked;
    const newValue = this.valueArrayFromLocalValue(this.localValue);
    this.$emit('update:modelValue', newValue);
  }

  // Watch for changes to the prop value and update localValue to match
  @Watch('modelValue')
  private onValueChange(newValue: string[], oldValue: string[]) {
    this.localValue = this.localValueFromValueArray(this.modelValue);
  }
}
</script>
