import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "nav-block-wrap" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "nav-link" }
const _hoisted_4 = {
  key: 0,
  class: "nav flex-column"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", {
      class: _normalizeClass(["nav flex-column nav-block", { active: _ctx.isActive }])
    }, [
      _createElementVNode("li", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_router_link, {
            class: "no-scroll",
            style: {"color":"#2e2e2e !important"},
            to: _ctx.organRoute
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                class: "nav-caret",
                icon: ['fas', (_ctx.show ? 'chevron-down' : 'chevron-right')],
                "fixed-width": "",
                onClick: _withModifiers(_ctx.toggleNav, ["prevent"])
              }, null, 8, ["icon", "onClick"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(`${_ctx.organ}`)), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              (!_ctx.isTransplantCoordinator)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 0,
                    class: "nav-item",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                  }, [
                    _createElementVNode("a", {
                      class: "nav-link",
                      href: _ctx.getUrl('donor-checklist')
                    }, _toDisplayString(_ctx.$t(`checklists`)), 9, _hoisted_5)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", {
                class: "nav-item",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
              }, [
                _createElementVNode("a", {
                  class: "nav-link text-flat",
                  href: _ctx.getUrl(_ctx.isTransplantCoordinator ? 'allocation-offer-responses' : 'allocation-recommendation-listing')
                }, _toDisplayString(_ctx.$t(`allocation_eoffer`)), 9, _hoisted_6)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}