import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!

  return (_openBlock(), _createBlock(_component_validation_provider, {
    ref: "provider",
    name: _ctx.inputId
  }, {
    default: _withCtx(({ errors }) => [
      (errors[0])
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "invalid-feedback",
            id: `${_ctx.inputId}-error`
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['far', 'exclamation-circle'],
              "fixed-width": ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors)), 1)
          ], 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name"]))
}