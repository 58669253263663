<template>
  <div>
    <template>
      <page-top>
        Test Lookups
      </page-top>
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper" id='administration-area'>
            <div class="page-content" >
              <div class="col-sm-12">
                <test-component-1></test-component-1>
              </div>
              <div class="col-sm-12">
                <test-component-2></test-component-2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import PageTop from '@/components/shared/PageTop.vue';
import { Component, Vue } from 'vue-facing-decorator';
import TestComponent1 from '@/views/test/TestComponent1.vue';
import TestComponent2 from '@/views/test/TestComponent2.vue';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    PageTop,
    TestComponent1,
    TestComponent2
  },
  ...i18nMessages([
    require('@/views/_locales/Administration.json'),
  ]),
})
export default class Test extends Vue {
  // purpose of this page is to test lookup functionality
}
</script>
