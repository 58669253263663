<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-10 col-lg-7 col-xl-6">
        <div id="warning-banner" class="card shadow-none">
          <div class="card-body">
            <h2 class="login-title">{{$t('error_title')}}</h2>
            <hr>
            <div class="form-group">
              <p>{{$t("missing_configuration")}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { i18nMessages } from '@/i18n';
import { Component, Vue } from 'vue-facing-decorator';

@Component({
  ...i18nMessages([
    require('@/views/_locales/WarningBanner.json'),
  ]),
})
export default class WarningBanner extends Vue {}
</script>
