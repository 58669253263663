import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.isRequired)
      ? (_openBlock(), _createElementBlock("i", _hoisted_1, "*"))
      : _createCommentVNode("", true)
  ]))
}