<template>
  <validation-observer ref="offerValidations" autocomplete="off" tag="form" v-slot="{ handleSubmit }">
    <modal-section
      modalId="offer-modal"
      ref="offerModal"
      class="modal-sticky-header"
      @hide="(options: any) => modalEvent(options)"
      :centered="true"
      :closeButton="false"
    >
      <template v-slot:title>
        {{ $t('make_offer_to') }}
      </template>
      <template v-slot:body v-if="!editState.offer">
        {{ $t('cancel') }}
      </template>
      <template v-slot:body v-else>
        <p>
          {{ $t('select_offer_to') }}<br />
        </p>
        <template v-if="!!editState.offer.offerErrorMessage">
          <div class="alert alert-danger">
            {{ editState.offer.offerErrorMessage }}
          </div>
        </template>
        <div class="hr-break" />
        <div class="row">
          <div class="form-group col-sm-6">
            <select-input
              selectId="offer-type"
              :name="$t('offer_type')"
              rules="required"
              v-model="editState.offer.type"
              :options="availableOfferTypes"
              @change="setShowManualAllocationRationale()"
            />
          </div>
          <div class="form-group col-sm-6" v-if="showOrganSpecification">
            <select-input
              select-id="offer-organ-specification"
              :name="$t('organ_specification')"
              rules="required"
              v-model="editState.offer.organSpecification"
              :options="organSpecificationLookup"
              :disabled="editState.offer.oopHspOfferScenario"
              @change="setShowManualAllocationRationale()"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-6" v-if="showNoOfferOptions">
            <select-input
              select-id="offer-reason-category"
              :name="$t('no_offer_reason_category')"
              rules="required"
              v-model="editState.offer.reasonCategory"
              :options="noOfferReasonCategoryOptions"
              @change="clearOfferReason()"
            />
          </div>
          <div class="form-group col-sm-6" v-if="showNoOfferOptions">
            <select-input
              select-id="offer-reason"
              :name="$t('no_offer_reason')"
              rules="required"
              v-model="editState.offer.reason"
              :options="noOfferReasonOptions"
            />
          </div>
        </div>
        <div class="row" v-if="isPrimary">
          <div class="form-group col-sm-6">
            <boolean-radio-input
              input-id="re-offer-indicator"
              :labelName="$t('re-offer-indicator')"
              acceptLabel="Yes"
              declineLabel="No"
              :disabled="true"
              v-model="editState.offer.reOfferScenario"
            />
          </div>
        </div>
        <!-- Non-Intended Recipient only applicable in specific scenario -->
        <template v-if="showNonIntendedRecipient">
          <div class="hr-break" />
          <div class="row">
            <div class="form-group col-sm-6">
              <checkbox-input
                input-id="offer-non-intended-recipient"
                :labelName="$t('non_intended_recipient')"
                v-model="editState.offer.nonIntendedRecipient"
                label="Yes"
              />
            </div>
          </div>
        </template>
        <div class="hr-break" />
        <div class="row">
          <dl class="col-sm-4">
            <dt>{{ $t('offer_by') }}</dt>
            <dd>{{currentUser || '-'}}</dd>
          </dl>
        </div>
        <div class="hr-break" />
        <div class="row">
          <div class="col-12">
            <p>
              <strong>{{ $t('personal_information_notice') }}</strong>
            </p>
            <p>
              {{ $t('personal_information_notice_2') }}
            </p>
          </div>
          <div class="form-group col-sm-6">
            <text-area-input
              input-id="offer-comment"
              :name="$t('offer_comment')"
              v-model="editState.offer.comment"
            />
          </div>
        </div>
        <!-- Manual Allocation Rationale required when making primary offers out of sequence -->
        <template v-if="showManualAllocationRationale">
          <div class="hr-break" />
          <div class="row">
            <select-other-input
              rules="required"
              select-id="manual-allocation-rationale"
              :name="$t('manual_allocation_rationale')"
              v-model="editState.offer.manualAllocationRationale"
              :options="manualAllocationRationaleOptions"
              @change="onManualAllocationRationaleChanged"
              :numeric="true"
              reduceColumnWidth="form-group selectWithOther col-6"
              colStyling="form-group selectWithOther col-12"
            >
             <template v-slot:other>
              <text-input
                rules="required"
                input-id="manual_allocation_rationale_other"
                :name="$t('manual_allocation_rationale_other')"
                v-model="editState.offer.manualAllocationRationaleOther"
              />
             </template>
            </select-other-input>
          </div>
        </template>
        <template v-if="isNotificationEmailApplicable">
          <div class="hr-break" />
          <div class="row">
            <div class="form-group col-sm-6">
              <checkbox-input
                rules="required"
                input-id="send_notification_email"
                v-model="editState.offer.sendNotificationEmail"
                :label="$t('send_notification_email')"
              />
            </div>
          </div>
        </template>
        <template v-if="showNotificationEmailSection">
          <div class="hr-break" />
          <div class="row">
            <select-other-input
              select-id="notification_email"
              :name="$t('notification_email')"
              v-model="editState.offer.notificationEmail"
              :options="recipientNotificationEmails"
              @change="onNotificationEmailChanged"
              rules="required"
              reduceColumnWidth="form-group selectWithOther col-6"
              colStyling="form-group selectWithOther col-12"
            >
             <template v-slot:other>
              <text-input
                rules="required"
                input-id="notification_email_other"
                :name="$t('notification_email_other')"
                v-model="editState.offer.notificationEmailOther"
              />
             </template>
            </select-other-input>
          </div>
        </template>
        <template v-if="donorDocuments && showNotificationEmailSection">
          <div class="hr-break" />
          <div class="row">
            <div class="col-12">
              <label for="donorDocuments-table">{{ $t('donor_documents') }}</label>
              <!-- TODO: Vue3_Upgrade: tables -->
            </div>
          </div>
        </template>
      </template>
      <template v-slot:footer>
        <div class="modal-footer-body">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-secondary"
            @click="clearAllocationOfferState()"
            :disabled="isMakingOffer"
          >
            {{ $t('cancel') }}
          </button>
          <button
            class="btn btn-success"
            @click.prevent="handleSubmit(makeOffer)"
            :disabled="isMakingOffer"
          >
            {{ $t('make_offer') }}
            <span class="pl-2" v-if="isMakingOffer">
              <font-awesome-icon class="fa-spin" :icon="['far', 'spinner-third']" />
            </span>
          </button>
        </div>
      </template>
    </modal-section>
  </validation-observer>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/OfferModal.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/allocations/_locales/_CTRIntegrationWorkflows.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { AllocationErrorsMixin } from "@/mixins/allocation-errors-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import { TableConfig, CtrErrorContext } from '@/types';
import TextInput from '@/components/shared/TextInput.vue';
import { DeceasedDonor, DeceasedDonorAttachment } from '@/store/deceasedDonors/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { GenericCodeValue, NumericCodeValue } from '@/store/types';
import { Component, Vue, Watch, Prop } from 'vue-facing-decorator';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { Organ, OrganCodeValue, OrganSpecification, OfferType, OfferReasonCategory, OfferReason, OrganOfferSpecificationCodeValue } from '@/store/lookups/types';
import { AllocationRecipient, AllocationOfferTypeValues, AllocationOfferAction, AllocationOfferResponseCodeValues, Allocation, AllocationOffer, AllocationOfferRecipient, OfferOutcomeContext, ERROR_QUERYING_CTR_OFFERS, ERROR_CTR_CONNECTION_ERROR, MAKE_OFFER } from '@/store/allocations/types';
import { AttachmentCategory, RecipientNotificationEmail, OrganOfferSpecificationCodeValueToOrganCode } from '@/store/lookups/types';
import { recipients } from '@/store/recipients';

interface AllocationOfferPageState {
  recipients: AllocationOfferRecipient[];
  type: string;
  organSpecification: number;
  reasonCategory: number;
  reason: number;
  comment: string;
  responsiblePhysician?: string;
  manualAllocationRationale?: number|null;
  manualAllocationRationaleOther?: string|null;
  reOfferScenario: boolean;
  sendNotificationEmail: boolean;
  notificationEmail?: string;
  notificationEmailOther?: string;
  donorDocuments?: string[]|null;
  nonIntendedRecipient?: boolean;
}

interface TableRow {
  oid?: string;
  category?: string;
  category_code?: number;
  dateUploaded?: string;
  fileName?: string;
  fileType?: string;
  description?: string;
  uploadedBy?: string;
  selected?: boolean;
  nonIntendedRecipient?: boolean;
  oopHspOfferScenario?: boolean;
}

const OFFER_CODES_FOR_ONE_RECIPIENT = [
  AllocationOfferTypeValues.Primary,
  AllocationOfferTypeValues.ConvertToAcceptedPrimary,
];

// NOTE: this determines whether or not 'Out of Sequence Reason' is required
const OFFER_CODES_THAT_RESOLVE_ENTRIES = [
  AllocationOfferTypeValues.Primary,
  AllocationOfferTypeValues.NoOffer,
];

// NOTE: this determines whether or not 'Out of Sequence Reason' is required
const RESPONSE_CODES_THAT_RESOLVE_ENTRIES = [
  AllocationOfferResponseCodeValues.Decline,
  AllocationOfferResponseCodeValues.Withdraw,
  AllocationOfferResponseCodeValues.Cancel
];

const KIDNEY_PANCREAS_CLUSTER_ORGANS = [
  OrganCodeValue.Kidney,
  OrganCodeValue.PancreasWhole,
];

// Single Kidney specification for Out-of-province HSP Recipients. #14333
// As per addendum on 6.1.1  v4.10 #7252 as per clarification from client on #12737
const OOP_HSP_ORGAN_SPEC_DEFAULT = OrganOfferSpecificationCodeValue.SingleKidney;

// Which Offer Types do we need to show a 'Non-Intended Recipient' checkbox for?
// Only applies of Allocation already has a discontinue flagged as 'Re-Allocated to Non-Intended Recipient'
const OFFER_TYPES_APPLICABLE_FOR_NON_INTENDED = [
  AllocationOfferTypeValues.Primary,
  AllocationOfferTypeValues.Backup,
  AllocationOfferTypeValues.ConvertToAcceptedPrimary,
];

@Component({
  components: {
    TextInput,
    SelectInput,
    ModalSection,
    TextAreaInput,
    CheckboxInput,
    SelectOtherInput,
    BooleanRadioInput,
  }
})
export default class OfferModal extends mixins(DateUtilsMixin, AllocationErrorsMixin) {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.currentUser) private currentUser!: any;
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.allocations) private editState!: any;
  @State(state => state.lookups.out_of_sequence_offer_reasons) private  outOfSequenceOfferReasons!: NumericCodeValue[];
  @State(state => state.allocations.isMakingOffer) private isMakingOffer!: boolean;

  @Getter('offerTypes', { namespace: 'lookups' }) private offerTypes!: OfferType[];
  @Getter('clientId', { namespace: 'deceasedDonors' }) private donorId!: DeceasedDonor;
  @Getter('selectedAllocation', { namespace: 'allocations' }) private allocation!: Allocation;
  @Getter('intendedRecipientId', { namespace: 'allocations' }) private intendedRecipientId!: string|null;
  @Getter('hasReAllocatedToNonIntended', { namespace: 'allocations' }) private hasReAllocatedToNonIntended!: boolean;
  @Getter('parseCtrErrors', { namespace: 'allocations' }) private parseCtrErrors!: (actions: any[]) => CtrErrorContext[];
  @Getter('getRecipientsByEffectiveRank', { namespace: 'allocations' }) getRecipientsByEffectiveRank!: (recipientRanks?: number[]) => AllocationOfferRecipient[];
  @Getter('RecipientNotificationEmails', { namespace: 'lookups' }) recipientNotificationEmails!: RecipientNotificationEmail[];
  @Getter('AttachmentCategoryOptions', { namespace: 'lookups' }) attachmentCategoryOptions!: (enableDonor: boolean) => AttachmentCategory[];
  @Getter('deceasedDonorsSpecificationsByOrganCode', { namespace: 'lookups' }) private deceasedDonorsSpecificationsByOrganCode!: (organCode: string) => OrganSpecification[];
  @Getter('ctrIposHeart', { namespace: 'configuration' }) private ctrIposHeart!: boolean;

  private showManualAllocationRationale = false;

  private setShowManualAllocationRationale() {
    this.showManualAllocationRationale = this.checkForManualAllocationRationale();
  }

  get isHSPOffer(): boolean {
    return this.editState.offer.hsp;
  }

  /**
   * Determine whether to show or hide the notification email dropdown and other email input
   * based sendNotificationEmail being checked to true
   *
   * @returns {boolean} true if sendNotificationEmail checked
   */
  get showNotificationEmailSection(): boolean {
    return this.editState.offer.sendNotificationEmail || false;
  }

  /**
   * Use the document_attachment_category lookup table to convert from the code to the value (the visual label to present on the tables).
   *
   * @param categoryCode numeric code from a given attachment representing the Attachment Category
   * @returns {string|undefined} attachment's category as text
   */
  private attachmentCategoryValue(categoryCode?: number): string|undefined {
    const lookupTable = this.attachmentCategoryOptions(true);

    if (!!lookupTable) {
      const entry = lookupTable.find((category: AttachmentCategory) => category.code == categoryCode);

      if (!!entry) {
        return entry.value;
      }
    }

    return undefined;
  }

  private donorDocumentsSelected(selected: any): void {
    const selectedRows: any = selected.selectedRows || [];
    const rowIDs: string[] = selectedRows.map((row: any) => {
      if (row.oid) return row.oid;
    });
    this.editState.offer.donorDocuments = rowIDs;
  }


  // Prepare donor attachments for presentation in the historical tables.
  get tableRows(): TableRow[] {
    const donor = this.donor || {};
    const attachments = donor.attachments || [];
    const result = attachments.map((item: DeceasedDonorAttachment) => {
      return {
        oid: item._id!['$oid'],
        category: this.attachmentCategoryValue(item.category_code) || '-',
        category_code: item.category_code,
        dateUploaded: this.parseDisplayDateUiFromDateTime(item.created_at),
        fileName: item.original_filename,
        fileType: item.mime_type,
        description: item.description || '-',
        uploadedBy: item.updated_by ? item.updated_by : item.created_by,
        selected: false
      };
    });
    return result;
  }

  /**
  *
  */
  get tableConfig(): TableConfig {
    const tableConfig = [
      { label: `${this.$t('category')}`,
        field: 'category',
        width: '10%',
        sortable: false
      },
      { label: `${this.$t('fileName')}`,
        field: 'fileName',
        sortable: false
      },
      { label: `${this.$t('fileType')}`,
        field: 'fileType',
        width: '10%',
        sortable: false
      },
      { label: `${this.$t('attachment_date')}`,
        field: 'dateUploaded',
        width: '10%',
        sortable: false
      },
      { label: `${this.$t('description')}`,
        field: 'description',
        sortable: false
      },
      { label: `${this.$t('uploadedBy')}`,
        field: 'uploadedBy',
        width: '10%',
        sortable: false
      }
    ];
    return {
      data: this.tableRows,
      columns: tableConfig,
      empty: this.$t('documents_empty').toString(),
      sortOptions: {
        enabled: false
      }
    };
  }


  /**
   * Return no offer reason options (conditional on offer type)
   *
   * @returns {GenericCodeValue[]} No Offer Reason options
   */
  get noOfferReasonOptions(): OfferReason[] {
    const reasonCategory = this.editState.offer.reasonCategory;
    if (this.editState.offer.type == AllocationOfferTypeValues.NoOffer) {
      const noOfferReason = this.noOfferReasonCategoryOptions.find((item: any) => {
        return item.code == reasonCategory;
      });
      if (noOfferReason && noOfferReason.sub_tables) {
        return noOfferReason.sub_tables.no_offer_reasons;
      }
    }
    return [];
  }

  /**
   * Return available offer types, if more than one recipient remove Primary
   *
   * @returns {OfferType[]} Offer Response types
   */
  get availableOfferTypes(): OfferType[] {
    const offerRecipients: AllocationOfferRecipient[] = this.editState.offer.recipients || [];
    let available = this.offerTypes || [];
    // Some offer types can only be sent if exactly one recipient is selected
    // e.g. primary is only for one recipient at a time
    if (offerRecipients.length > 1) {
      available = this.offerTypes.filter((offerType: OfferType) => {
        return !OFFER_CODES_FOR_ONE_RECIPIENT.includes(offerType.code as AllocationOfferTypeValues);
      });
    }

    // Some offer types are only available when selected recipients have existing offers / responses
    // e.g. convert to accepted primary is only for accepted backup
    available = available.filter((offerType: OfferType) => {
      // Count selected recipients with appropriate offer type
      const numRecipientsWithRequiredOffer = offerRecipients.filter((recipientEntry: AllocationOfferRecipient) => {
        return !offerType.requires_current_offer_type || recipientEntry.offer_type_code == offerType.requires_current_offer_type;
      }).length;
      // Count selected recipients with appropriate response type
      const numRecipientsWithRequiredResponse = offerRecipients.filter((recipientEntry: AllocationOfferRecipient) => {
        return !offerType.requires_current_offer_response || recipientEntry.response_code == offerType.requires_current_offer_response;
      }).length;
      return numRecipientsWithRequiredOffer == offerRecipients.length && numRecipientsWithRequiredResponse == offerRecipients.length;
    });

    // If recipients are in HSP or HSH ranking category (i.e. ranked by CTR), disable the 'no offer' option
    // NOTE: this also applies to HSH ranking category (related to IPOS Hearts, see TPGLI-2053)
    const entriesNotApplicableForNoOffer = offerRecipients.filter((record: AllocationOfferRecipient) => {
      if (record.hsp == 'HSP') return true;
      if (this.ctrIposHeart && record.hsh == 'HSH') return true;
      return false;
    });
    available.map((offerType: OfferType) => {
      if (offerType.code == AllocationOfferTypeValues.NoOffer) { offerType.disabled = entriesNotApplicableForNoOffer.length > 0; }
    });

    return available;
  }

  /**
   * Return no offer reason category options (conditional on reason choice)
   *
   * @returns {OfferReasonCategory[]} No Offer Reason Category options
   */
  get noOfferReasonCategoryOptions(): OfferReasonCategory[] {
    if (this.editState.offer.type == AllocationOfferTypeValues.NoOffer) {
      const noOfferOptions = this.offerTypes.find((item: OfferType) => {
        return item.code == AllocationOfferTypeValues.NoOffer;
      });
      return noOfferOptions?.sub_tables?.no_offer_reason_categories || [];
    }
    return [];
  }

  // Options for Manual Allocation Rationale i.e. Out of Sequence Reason
  get manualAllocationRationaleOptions(): NumericCodeValue[] {
    if (!this.outOfSequenceOfferReasons) return [];

    return this.outOfSequenceOfferReasons;
  }

  /**
   * Return a boolean if we need to show no offer option list
   *
   * @returns {boolean} true if we need to show
   */
  get showNoOfferOptions(): boolean {
    if (this.editState.offer?.type === AllocationOfferTypeValues.NoOffer) {
      return true;
    }
    return false;
  }

  /**
   * Get a boolean result if this is a primary offer
   *
   * @returns {boolean} true when the selected offer type is primary, false otherwise
   */
  get isPrimary(): boolean {
    if (!this.editState || !this.editState.offer) return false;

    return this.editState?.offer?.type === AllocationOfferTypeValues.Primary;
  }

  /**
   * Whether or not to show the Non-Intended Recipient checkbox.
   *
   * Only applicable if:
   * - Offer Type has been set to an applicable option
   * - Deceased Donor is Out-of-Province (OOP)
   * - Offer has previously been discontinued with 'Re-Allocated to Non-Intended Recipient' ticked
   *
   * @returns {boolean} true when checkbox is applicable, false otherwise
   */
  get showNonIntendedRecipient(): boolean {
    // Check Offer Type
    const offerType = this.editState?.offer?.type;
    const isApplicableOfferType = OFFER_TYPES_APPLICABLE_FOR_NON_INTENDED.includes(offerType);
    if (!isApplicableOfferType) return false;

    // Check Deceased Donor
    const isDonorOop = this.donor?.indicators?.out_of_province;
    if (!isDonorOop) return false;

    // Check if allocation has discontinued offer that was re-allocated to non-intended
    return this.hasReAllocatedToNonIntended;
  }
  /*
   * Which Offer Type lookups have 'may_require_out_of_sequence_reason' flag
   *
   * @returns {string[]} array of offer type codes
   */
  get outOfSequenceApplicableOfferTypeCodes(): string[] {
    if (!this.offerTypes) return [];

    const types: OfferType[] = this.offerTypes.filter((offerType: OfferType) => {
      return offerType.may_require_out_of_sequence_reason;
    });
    const codes: string[] = types.map((offerType: OfferType): string => {
      return offerType.code;
    });

    return codes;
  }

  /**
   * Was an entry on the Allocation List skipped, i.e. not resolved?
   *
   * Resolved means recipient has either:
   * - Primary Offer or No Offer offer type; or,
   * - Decline, Discontinue/Cancel, or Discontinue/Withdraw response code
   *
   * NOTE: this determines whether or not 'Out of Sequence Reason' is required
   *
   * TP-10003
   * if there are any higher ranked recipients with a Backup offer that is not Declined/Discontinued
   * and who “match” the organ(s) of the recipient being offered, then an Out of Sequence Reason Code
   * (OOSRC) must be provided
   *
   * @param allocationRecipient a recipient entry from an Allocation List
   * @returns {boolean} true if recipient entry has not been 'resolved' yet
   */
  private wasSkipped(allocationRecipient: AllocationRecipient): boolean {
    // get offer type
    const offerCode = allocationRecipient?.offer?.offer_type_code || null;

    // get offer response code
    const responseCode = allocationRecipient?.offer?.response_code || null;

    // Compare offer and response against array constants
    // NOTE: this allows the definition of 'skipped' to be adjusted in the constants
    const hasOfferTypeThatResolvesEntry = OFFER_CODES_THAT_RESOLVE_ENTRIES.includes(offerCode as AllocationOfferTypeValues);
    const hasResponseThatResolvesEntry = RESPONSE_CODES_THAT_RESOLVE_ENTRIES.includes(responseCode as AllocationOfferResponseCodeValues);

    // This function return true only if specified recipient entry has been skipped
    // In other words, recipient is considred'skipped' only if neither the 'offer type' nor the 'response' match the constant
    return !hasOfferTypeThatResolvesEntry && !hasResponseThatResolvesEntry;
  }

  /**
   * Returns true if Manual Allocation Rationale is applicable i.e. Out of Sequence Reason
   *
   * When making a primary offer,
   * - hide manual rationale if no recipients behind the row being offered.
   * - If recipients exist previous to the one being offered, check all higher ranked recipients for a primary, no-offer, decline, withdraw, or cancel
   * - - if no show manual rationale
   *
   * @returns {boolean}
   */
  private checkForManualAllocationRationale(): boolean {

    // Get organ primary organ code
    let organ_code = [this.allocation.organ_code];
    const organSpecification = this.editState.offer.organSpecification || null;

    // We also need to check organ specific code and if selected translate that to an organ_code to compare against
    // why?
    // 1 is combination kidney / pancreas, pancreas being the organ offered
    // 2 is combination kidney / pancreas, pancreas being the organ offered
    // 3 is kidney / pancreas cluster
    // For 3 we select primary and then on organ specification we select pancreas,
    // as we skipped 1 & 2 we need to show the out of sequence dropdown.
    if (organSpecification) {
      const values = OrganOfferSpecificationCodeValueToOrganCode as any;
      const organ_as = values[organSpecification];
      organ_code = Array.isArray(organ_as) ? organ_as : [organ_as];
    }

    if (!this.editState || !this.editState.offer) return false;

    // This only applies when making primary offers
    if (!this.outOfSequenceApplicableOfferTypeCodes.includes(this.editState?.offer?.type)) return false;

    // Get the recipient IDs who the offer is being made to
    const offerRecipients = this.editState?.offer?.recipients || []; // Ordered list of all recipient being made an offer to
    const offerRecipientsRankArray = offerRecipients.map((r: AllocationOfferRecipient) => r.effective_rank);
    const minOfferedRecipientRank = Math.min(...offerRecipientsRankArray);
    let minOfferedRecipient: AllocationOfferRecipient|null = null;
    offerRecipients.forEach((recipient: AllocationOfferRecipient) => {
      if(recipient.effective_rank == minOfferedRecipientRank) {
        minOfferedRecipient = recipient;
      }
    });

    // Logic for figuring out which AllocationRecipient rows to look for skipping
    // First we get all recipients in that allocation
    const allocationRecipients = (this.allocation || {}).recipients || [];

    // Then we filter the list down to the ones we care about i.e. higher ranked recipients
    const allocationRecipientsWeCareAbout = allocationRecipients.filter((allocationRecipient: AllocationRecipient) => {

      const clusterCodes = allocationRecipient.cluster_organ_codes || [];
      let insideClusterCodes = false;
      clusterCodes.forEach(o => {
        insideClusterCodes = insideClusterCodes || organ_code.includes(o);
      });

      const isHigherRank = allocationRecipient.effective_rank < minOfferedRecipientRank;
      const isSameOrgan = organ_code.includes(allocationRecipient.organ_code) || insideClusterCodes;
      return isHigherRank && isSameOrgan;
    });

    // Then we figure out which of these recipients have been skipped
    // NOTE: this is delegated to a helper method, and is defined based on Offer Type, Response Code, etc.
    const skippedRecipients = allocationRecipientsWeCareAbout.filter((allocationRecipient: AllocationRecipient) => {
      return this.wasSkipped(allocationRecipient);
    });

    // Show 'Out of Sequence Reason' if we have at least one skipped entry in Allocation List
    return skippedRecipients.length > 0;
  }

  recipientIncludesKPCluster(recipient: AllocationRecipient): boolean {
    const recipientOffers = this.editState.offer.recipients || [];
    if (recipientOffers.length == 0) return false;

    const offerOrganCodes: number[] = recipientOffers.cluster_organ_codes || [];

    const applicableOrganCodes = offerOrganCodes.filter((organCode: number) => {
      return KIDNEY_PANCREAS_CLUSTER_ORGANS.includes(organCode);
    });
    return applicableOrganCodes.length === KIDNEY_PANCREAS_CLUSTER_ORGANS.length;
  }

  get donorDocuments(): any[] {
    if (!this.donor || !this.donor.attachments) return [];
    return this.donor.attachments;
  }

  /**
   * Determine whether notification emails are applicable for this offer
   *
   * @returns {boolean} true if notification emails are applicable
   */
  get isNotificationEmailApplicable(): boolean {
    if (!this.editState) return false;

    let out_of_province = false;

    // Get the recipient IDs who the offer is being made to
    const offerRecipients = this.editState?.offer?.recipients || [];
    const offerRecipientIds = offerRecipients.map((recipient: AllocationOfferRecipient) => {
      out_of_province = recipient.out_of_province;
    });

    return out_of_province;
  }

  /**
   * Whether or not to show a warning prompt about making a primary offer to a a recipient
   * who has a pending primary offer on another allocation.
   *
   * If the user is making a primary offer that includes a recipient with the boolean flag
   * 'other_offers_pending' set by the allocation service to true, then user must confirm
   * this warning prompt in order to proceed with making the eOffer.
   *
   * @returns {boolean} true if warning prompt must appear, false otherwise
   */
  get checkRecipientsForOtherOffersPending(): boolean {
    if (!this.editState) return false;

    // This only applies when making primary offers
    if (!this.isPrimary) return false;

    // Get the recipient IDs who the offer is being made to
    const offerRecipients = this.editState?.offer?.recipients || [];
    const offerRecipientIds = offerRecipients.map((recipient: AllocationOfferRecipient): string => {
      return recipient.id;
    });

    const allocationRecipients = (this.allocation || {})?.recipients || [];

    // Find any recipients with matching ID who have 'other_offers_pending' information from allocation
    const recipientsWithOtherPrimaryOffersPending = allocationRecipients.filter((recipient: AllocationRecipient) => {
      return offerRecipientIds.includes(recipient._id) && recipient.other_offers_pending;
    });

    // Return true if there are any recipients who have other accepted or pending primary offers
    return recipientsWithOtherPrimaryOffersPending.length > 0;
  }

  /**
   * Whether or not to show a warning prompt about making a primary offer to a a recipient
   * who has already accepted a primary offer on another allocation.
   *
   * If the user is making a primary offer that includes a recipient with the boolean flag
   * 'other_offers_accepted' set by the allocation service to true, then user must confirm
   * this warning prompt in order to proceed with making the eOffer.
   *
   * @returns {boolean} true if warning prompt must appear, false otherwise
   */
  get checkRecipientsForOtherOffersAccepted(): boolean {
    if (!this.editState) return false;

    // This only applies when making primary offers
    if (!this.isPrimary) return false;

    // Get the recipient IDs who the offer is being made to
    const offerRecipients = this.editState?.offer?.recipients || [];
    const offerRecipientIds = offerRecipients.map((recipient: AllocationOfferRecipient): string => {
      return recipient.id;
    });

    const allocationRecipients = (this.allocation || {})?.recipients || [];

    // Find any recipients with matching ID who have 'other_offers_accepted' information from allocation
    const recipientsWithOtherPrimaryOffersAccepted = allocationRecipients.filter((recipient: AllocationRecipient) => {
      return offerRecipientIds.includes(recipient._id) && recipient.other_offers_accepted;
    });

    // Return true if there are any recipients who have other accepted or pending primary offers
    return recipientsWithOtherPrimaryOffersAccepted.length > 0;
  }

  /**
   * Return true if organ is a Liver or Lung or Kidney and offer type isn't No Offer
   *
   * Kidney will only return if the recipient offer is for a K/P Cluster
   *
   * @returns {boolean} true if Liver, Lung or Kidney
   */
  get showOrganSpecification(): boolean {
    const isLiverOrLung = [OrganCodeValue.Lung, OrganCodeValue.Liver].includes(this.allocation?.organ_code);
    const isKidney = [OrganCodeValue.Kidney].includes(this.allocation?.organ_code);

    // Return if Kidney and recipient is a KPCluster and offer type isn't No Offer
    if (isKidney) return this.isKPClusters && !this.showNoOfferOptions;

    // Return if Liver/Lung and offer type isn't No Offer
    return isLiverOrLung && !this.showNoOfferOptions;
  }

  /**
   * Get a string representation the organ_code
   *
   * @returns {string} organ_code param as a string
   */
  get organCode(): string {
    return this.$route.params.organ_code ? this.$route.params.organ_code.toString() : '';
  }

  /**
   * Returns an array of options for Organ Specification
   *
   * Fetches the organ specification subtable from the appropriate organ lookup table
   *
   * @returns {OrganSpecification[]} options for organ specification
   */
  get organSpecificationLookup(): OrganSpecification[] {
    const offerOrganSpec = this.deceasedDonorsSpecificationsByOrganCode(this.organCode);

    if (offerOrganSpec.length == 0) return [];

    // Filter options if this is a Kidney
    if (this.allocation.organ_code === OrganCodeValue.Kidney) {
      // Single and Double Kidney have different options
      return offerOrganSpec.filter((item: OrganSpecification) => {
        // Always include the Pancreas option
        if (item.value === 'Pancreas') return true;
        // TODO: Remove fallback option when lookup value exists (TP-10323 data ticket)
        if ('double_kidney' in item) {
          // Filter based on a lookup value for double_kidney
          return this.allocation.donor.double_kidney === item.double_kidney;
        } else {
          // Fallback option - filter based on a string
          return this.allocation.donor.double_kidney ? item.value.includes('Double') : !item.value.includes('Double');
        }
      });
    }

    // Translate organ specification options
    const translated = offerOrganSpec.map((option: OrganSpecification): OrganSpecification => {
      return {
        ...option,
        value: this.$t(option.value).toString(),
      };
    });

    // Return appropriate options sub table
    return translated;
  }

  /**
   * Returns is the recipient offers for a Kidney/Pancreas Cluster
   *
   * @returns {boolean} true when all offered recipient cluster_organ_code matches K/P Cluster
   */
  get isKPClusters(): boolean {
    const recipientOffers = this.editState.offer.recipients || [];
    if (recipientOffers.length == 0) return false;

    // Check which selected entries are for a cluster for Kidney and Pancreas
    const kidneyPancreasClusterOffers = recipientOffers.filter((recipientOffer: AllocationOfferRecipient) => {
      const offerOrganCodes: number[] = recipientOffer.cluster_organ_codes || [];
      const applicableOrganCodes = offerOrganCodes.filter((organCode: number) => {
        return KIDNEY_PANCREAS_CLUSTER_ORGANS.includes(organCode);
      });
      return applicableOrganCodes.length === KIDNEY_PANCREAS_CLUSTER_ORGANS.length;
    });

    // Check if every single selected entry in Allocation List is for cluster containing at least Kidney and Pancreas
    return kidneyPancreasClusterOffers.length === recipientOffers.length;
  }

  /**
   * Populates state with AllocationOfferRecipient[] and opens the offer modal
   */
  public initializeAllocationOffer(recipientRanks: number[]): void {
    // Verify the Recipients exist in the Allocation and return AllocationOfferRecipient[]
    const recipients = this.getRecipientsByEffectiveRank(recipientRanks);
    // Build state from valid ids
    this.buildAllocationOfferState(recipients);
    // Open modal only if we have Recipients
    if (recipients.length > 0) this.openModal();
  }

  /**
   * Builds the Allocation Offer state if we have Recipients
   *
   * @param recipientOffers array of AllocationOfferRecipient
   */
  private buildAllocationOfferState(recipientOffers?: AllocationOfferRecipient[]): void {
    // Clear the state first
    this.clearAllocationOfferState();
    // Reset any existing errors
    (this.$refs.offerValidations as any).setErrors({});
    // If we have Recipients add them to the state
    if (recipientOffers) {
      // Look at all recipients and see if any have a re-offer-scenario flag
      const isReOfferScenario: boolean = recipientOffers.flatMap((recipient: AllocationOfferRecipient) => {
        return recipient.re_offer_scenario;
      }).includes(true);

      // Check if selection includes Out-of-province recipient in Highly-Sensitized Patient ranking category
      const isOOPHSPOfferScenario: boolean = recipientOffers.flatMap((recipient: AllocationOfferRecipient) => {
        return !!recipient.out_of_province && recipient.hsp == 'HSP';
      }).includes(true);
      if (isOOPHSPOfferScenario) this.setOOPHSPDefaults();

      // Add our recipients
      this.editState.offer.recipients = recipientOffers;

      // Add our Re-Offer and/or HSP scenario flags
      this.editState.offer.reOfferScenario = isReOfferScenario;
      this.editState.offer.oopHspOfferScenario = isOOPHSPOfferScenario;
    }
  }

  // If offer is made to OOP HSP recipient there are certain defaults TP#14333
  // As per addendum on 6.1.1  v4.10 #7252 as per clarification from client on #12737
  private setOOPHSPDefaults(): void {
    this.editState.offer.organSpecification = OOP_HSP_ORGAN_SPEC_DEFAULT;
  }

  /**
   * Gets a patch object representing form edit state for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing offer details
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.offer) {
      return {};
    }
    return this.extractAllocationOfferPatch(this.editState.offer);
  }

  // PRVATE

  // Clear pageState when a modal event occurs
  private modalEvent(options: any) {
    // clear allocation state
    this.clearAllocationOfferState();
  }

  // Open modal
  private openModal(): void {
    const targetModal = this.$refs.offerModal as ModalSection;
    targetModal.toggleStaticModal();
  }

  // Close modal
  private closeModal(): void {
    const targetModal = this.$refs.offerModal as ModalSection;
    targetModal.hideModal();
  }

  // Clear pageState
  private clearAllocationOfferState(): void {
    // clear offer state
    this.editState.offer = {
      recipients: [],
      type: undefined,
      organSpecification: undefined,
      reasonCategory: undefined,
      reason: undefined,
      comment: undefined,
      manualAllocationRationale: undefined,
      manualAllocationRationaleOther: undefined,
      sendNotificationEmail: undefined,
      notificationEmail: undefined,
      notificationEmailOther: undefined,
      donorDocuments: [],
      nonIntendedRecipient: true,
    };
    // clear table selections in vue good table
    const donorDocumentsTable = this.$refs.offerModalDonorDocuments as any;
    if (donorDocumentsTable) {
      donorDocumentsTable.unselectAllInternal(); // reset table selections
    }
    // clear error message
    this.editState.offer.offerErrorMessage = '';
    // close modal
    const targetModal = this.$refs.offerModal as ModalSection;
    this.$emit('closeModal');
  }

  // Clear reason when changing reason category
  private clearOfferReason(): void {
    this.editState.offer.reason = undefined;
  }

  // Clear other rationale text when manual allocation rationale dropdown changed
  private onManualAllocationRationaleChanged(): void {
    this.editState.offer.manualAllocationRationaleOther = undefined;
  }

  // Clear other rationale text when manual allocation rationale dropdown changed
  private onNotificationEmailChanged(): void {
    this.editState.offer.notificationEmailOther = undefined;
  }

  /**
   * Return an AllocationOfferAction patch
   *
   * Prepare patch for API with Allocation Offer details
   *
   * @param offer offer pageState containing offer details
   * @returns {AllocationOfferAction} patch object containing offer details
   */
  private extractAllocationOfferPatch(offer: AllocationOfferPageState): AllocationOfferAction {
    let organSpecification: number|undefined = undefined;
    let reasonCategory: number|undefined = undefined;
    let reason: number|undefined = undefined;
    // add organ spec if liver or lung and not a no offer
    if (this.showOrganSpecification && !this.showNoOfferOptions) {
      organSpecification = offer.organSpecification;
    }
    // only add reason and reason category if this is a no offer
    switch (offer.type) {
      case AllocationOfferTypeValues.NoOffer:
        reasonCategory = offer.reasonCategory;
        reason = offer.reason;
        break;
    }
    // build patch
    const offerPatch: AllocationOfferAction = {
      recipients: offer.recipients,
      type: offer.type,
      reason_category: reasonCategory,
      reason_code: reason,
      organ_specification_code: organSpecification,
      comments: offer.comment,
    };
    // only add manual allocation rationale if it is needed
    if (this.showManualAllocationRationale) {
      offerPatch.out_of_sequence_offer_reason_code = offer.manualAllocationRationale;
      offerPatch.out_of_sequence_offer_reason_other = offer.manualAllocationRationaleOther;
    }

    if (this.showNotificationEmailSection) {
      const notificationEmailAddress = this.recipientNotificationEmails.find((item: any) => {
        return item.code == offer.notificationEmail;
      });

      // use notificationEmailAddress or notificationEmailOther if other selected
      offerPatch.notification_email = notificationEmailAddress && !notificationEmailAddress.other_selected ? notificationEmailAddress.value : offer.notificationEmailOther;
    }

    // if oop applicable, get donor documents user selected
    if (this.isNotificationEmailApplicable) {
      // add donor document details
      offerPatch.send_email = offer.sendNotificationEmail || false;
      offerPatch.attachment_ids = offer.donorDocuments || [];
    }

    // only add 'reallocated from intended recipient' flag if applicable
    if (this.showNonIntendedRecipient) {
      offerPatch.reallocated_from_intended_recipient_id = offer.nonIntendedRecipient ? this.intendedRecipientId : null;
    }
    return offerPatch;
  }

  /**
   * Attempt to make an offer they can't refuse
   *
   * Prepares create offer payload for selected Allocation, dispatches create, and handle errors.
   */
  private makeOffer(): void {
    // check for primary offers to recipients who have pending primary offers from other allocations
    // NOTE: do not show this 'pending' prompt if the 'accepted' prompt will also be shown (TPGLI-6563)
    if (this.checkRecipientsForOtherOffersPending && !this.checkRecipientsForOtherOffersAccepted) {
      // if so, we need to prompt the user to confirm this before proceeding to make the offers
      const confirmationText = this.$t('confirm_other_offers_pending_warning_message');
      const confirmed = confirm(confirmationText.toString());
      if (!confirmed) return;
    }

    // check for primary offers to recipients who have already accepted primary offers from other allocations
    if (this.checkRecipientsForOtherOffersAccepted) {
      // if so, we need to prompt the user to confirm this before proceeding to make the offers
      const confirmationText = this.$t('confirm_other_offers_accepted_warning_message');
      const confirmed = confirm(confirmationText.toString());
      if (!confirmed) return;
    }

    // Generate payload from editState
    const payload = {
      clientId: this.donorId,
      organCode: this.organCode,
      allocationId: this.allocation._id,
      offerDetails: this.extractPatch()
    };
    // Dispatch save action and show response
    this.$store.dispatch('allocations/makeOffer', payload).then((success: any) => {
      this.clearAllocationOfferState();
      (this.$refs.offerValidations as any).setErrors({});
      this.closeModal();
      // Do we need to show a secondary warning outcome popup?
      if (this.isOutcomeNotificationRequired(success)) this.displayOutcomeNotification(success);
    }).catch(({ error, error_code}) => {
      // Check if we need to show a CTR error workflow popup for a hard stop error
      if (this.isErrorWorkflowRequired(error)) {
        // Hide this Make Offer modal
        this.clearAllocationOfferState();
        (this.$refs.offerValidations as any).setErrors({});
        this.closeModal();
        // Then show the CTR Error Workflow modal
        setTimeout(() => {
          this.displayErrorWorkflow(error);
        }, 200);
        return;
      }
      // All errors
      const errors = error || {};
      // Single string error back
      if (typeof errors === 'string') {
        // if ipos & heart translate error code, otherwise return string
        const errorKey = `${this.allocation.organ_code}.${error_code}`;
        const errorMessage = this.$te(errorKey) ? this.$t(errorKey).toString() : errors;
        this.editState.offer.offerErrorMessage = errorMessage;
        return;
      }
      // Error when trying to offer to 2 different organ_codes
      if ('must_offer_single_organ_code' in error) {
        this.editState.offer.offerErrorMessage = this.$t('must_offer_single_organ_code');
        return;
      }
      this.editState.offer.offerErrorMessage = errors;
    });
  }

  /*
   * Check if we need to display the CTR error workflow popup, e.g. when CTR is down when
   * attemptying to query CTR offers during Make Offer (see B#15727)
   *
   * NOTE: this function handles hard stop error strings generated by webapp API
   *
   * @param error context from rejected offer promise
   * @return {boolean} true only if we need to display CTR error workflow
   */
  private isErrorWorkflowRequired(error: any): boolean {
    if (typeof error !== 'string') return false;

    const result = error.match(ERROR_QUERYING_CTR_OFFERS) !== null;
    return result;
  }

  // Is the specified error related to an infrastructure-level connection reset/timeout?
  private includesConnectionError(ctrErrors: CtrErrorContext[]): boolean {
    const result = ctrErrors.some((ctrError: CtrErrorContext) => {
      return ctrError.ctr_error_id.match(ERROR_CTR_CONNECTION_ERROR);
    });
    return result;
  }

  // Display outcome notification based on hard stop error string from webapp API
  private displayErrorWorkflow(rawError: any): void {
    // Get the information we need from the hard stop error
    const errorString = rawError.toString();
    const errorDetails = errorString.match(ERROR_QUERYING_CTR_OFFERS);
    if (errorDetails === null) return;

    const ctrErrorId = errorDetails[1];
    const ctrErrorMessage = errorDetails[2];
    // Mock an eOffer-style CTR error action data structure (to hook into 'parseCtrErrors' pattern)
    const mockErrorResponse = {
      data: {
        offer: {
          actions: [
            {
              ctr_error_id: ctrErrorId,
              ctr_error_message: ctrErrorMessage,
            }
          ]
        }
      }
    };

    // NOTE: passing 'true' as the second argument here activates 'hard stop error' messaging and styles
    this.displayOutcomeNotification(mockErrorResponse, true);
  }

  /*
   * Whether or not we need to show the 'outcome notification' popup after saving
   *
   * In general, this is only needed if the offer saved but some sort of secondary
   * issue persists. Usually an error will prevent the offer from saving entirely,
   * but for some situations (e.g. CTR sync error) the offer will be saved to the
   * database as if nothing happened... But there is still an issue that may need
   * user attention, so we close this modal and open another to show a 'warning'.
   *
   * @param response the response payload received from API after posting the offer
   * @returns {boolean} true only if the offer needs an outcome notification
   */
  private isOutcomeNotificationRequired(response: any): boolean {
    // Only show this if we see a CTR sync error in the response
    const ctrErrors = this.parseCtrErrors(response?.data?.offer?.actions || []);
    return ctrErrors.length > 0;
  }

  // Define what is needed for the outcome modal and emit an event
  private displayOutcomeNotification(response: any, isHardStopError?: boolean): void {
    // Outcome warnings are based on CTR Error IDs e.g. attempting to sync HSP offer to CTR
    const ctrErrors = this.parseCtrErrors(response?.data?.offer?.actions || []);
    const warningMessages = ctrErrors.map((warning: CtrErrorContext): string => {
      const warningKey = `warning.${this.allocationIposProgram}.${warning.ctr_error_id}`;
      return this.$te(warningKey) ? this.$t(warningKey).toString() : warning.ctr_error_message;
    });
    // Fetch CTR workflow instructions if there are any
    const instructionsTemplates = ctrErrors.map((ctrError: CtrErrorContext): string => {
      const instructionsKey = `instructions.${this.allocationIposProgram}.${ctrError.ctr_error_id}`;
      return this.$te(instructionsKey) ? this.$t(instructionsKey).toString() : this.$t('instructions.generic').toString();
    });

    // Check if the error is an infrastructure-level connection reset/timeout
    const isConnectionError = this.includesConnectionError(ctrErrors);

    const context: OfferOutcomeContext = {
      actionId: MAKE_OFFER,
      ctrErrors,
      warningMessages,
      instructionsTemplates,
      isHardStopError,
      isConnectionError,
    };

    this.$emit('display-outcome-notification', context);
  }
}
</script>
