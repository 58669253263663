<template>
  <base-side-nav :class="{ closed: sideNavClosed }">
    <template v-slot:toolbar />
    <template v-slot:side-nav-content>
      <side-nav-toolbar @toggle="toggleSideNav">
        <template v-slot:return-link>
          <router-link class="nav-link no-scroll" title="Return to previous page" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: 'edit-deceased-donor', params: { id: clientId } }">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
             {{$t('return_to_previous_page.span')}}
            </span>
          </router-link>
        </template>
      </side-nav-toolbar>
      <div class="side-nav-container">
        <selected-deceased-donor-organ-links
          v-for="organ in selectedDonorConsentedOrganList"
          :key="organ.id"
          @toggle="toggleSideNav" 
          :organ="organ.display_name"
          :organId="organ.id"
          :organCode="organ.organ_code"
          :organRoute="organ.route"
        />
        <hr>
        <selected-deceased-donor-profile-links @toggle="toggleSideNav">
          <template v-slot:profile-return-link>
            <router-link class="nav-link no-scroll open" :to="{ name: 'edit-deceased-donor', params: { id: clientId } }">
              <font-awesome-icon :icon="['fas', 'chevron-down']" class="nav-caret" fixed-width />
             {{$t('profile')}}
            </router-link>
          </template>
        </selected-deceased-donor-profile-links>
        <hr>
        <selected-deceased-donor-hla-links @toggle="toggleSideNav"/>
      </div>
    </template>      
  </base-side-nav>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Component, Vue } from 'vue-facing-decorator';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import { DeceasedDonorAllocationSummary } from '@/store/deceasedDonors/types';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import SelectedDeceasedDonorHlaLinks from "@/components/deceasedDonors/side-nav/SelectedDeceasedDonorHlaLinks.vue";
import SelectedDeceasedDonorOrganLinks from "@/components/deceasedDonors/side-nav/SelectedDeceasedDonorOrganLinks.vue";
import SelectedDeceasedDonorProfileLinks from "@/components/deceasedDonors/side-nav/SelectedDeceasedDonorProfileLinks.vue";
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedDeceasedDonorHlaLinks,
    SelectedDeceasedDonorOrganLinks,
    SelectedDeceasedDonorProfileLinks,
  },
  ...i18nMessages([
    require('@/components/_locales/common.json'),
    require('../_locales/SideNav.json'),
  ]),
})
export default class SideNavDonorOrgan extends Vue {
  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string|undefined;
  @Getter('selectedDonorConsentedOrganList', { namespace: 'deceasedDonors'}) private selectedDonorConsentedOrganList!: DeceasedDonorAllocationSummary[];

  public sideNavClosed = false;

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
</script>
