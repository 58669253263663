<template>
  <div class="dropdown" v-if="showAddOrgan">
    <a id="new-organ" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle" :class="{ 'btn btn-light': button, 'nav-link' : navlink }">
      {{$t('add_required_organ')}}
    </a> 
    <div aria-labelledby="selectOrgan" class="dropdown-menu dropdown-menu-right">
      <router-link
        class="dropdown-item"
        v-for="addOrganOption in addOrganOptions"
        :key="`new-organ-${addOrganOption.code}`"
        :to="{ name: 'new-organ', params: { id: recipientId, organ_code: addOrganOption.code } }"
      >
        {{ $t(addOrganOption.value) }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { Recipient } from '@/store/recipients/types';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { NumericCodeValue } from '@/store/types';
import { i18nMessages } from '@/i18n';

@Component({
  ...i18nMessages([
    require('../_locales/Organs.json'),
    require('@/components/recipients/_locales/OrganReferrals.json'),
    require('@/components/shared/_locales/single.json'),
  ]),
})
export default class NewOrganLink extends Vue {
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;

  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('organOptions', { namespace: 'lookups' }) organOptions!: (type?: string) => NumericCodeValue[];
  @Getter('nonExpiredOrganOptions', { namespace: 'lookups' }) nonExpiredOrganOptions!: (type?: string) => NumericCodeValue[];

  @Prop({ default: false }) button!: boolean;
  @Prop({ default: false }) navlink!: boolean;

  get showAddOrgan(): boolean {
    return this.checkAllowed('/recipients/:recipient_id/journeys', 'POST');
  }

  /**
   * Gets a string representation of the Recipient Client ID to use for routing
   * 
   * @returns {string}
   */
  get recipientId(): string {
    if (!this.recipient || !this.recipient.client_id) {
      return '';
    }
    return this.recipient.client_id.toString();
  }

  /**
   * Returns an array of options for Add new required organ
   * 
   * @returns {Organ[]} organ options
   */
  get addOrganOptions(): NumericCodeValue[] {
    // Build options list for organs with non-expired entry in the organ lookup
    return this.nonExpiredOrganOptions('single');
  }
}
</script>
