export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "make_offer_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Offer(s) to Selected Recipients"])},
        "select_offer_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select offer details for recipient(s)"])},
        "offer_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Type"])},
        "organ_specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Specification"])},
        "no_offer_reason_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Offer Reason Category"])},
        "no_offer_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Offer Reason"])},
        "re-offer-indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Offer Indicator"])},
        "non_intended_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Intended Recipient"])},
        "offer_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Made"])},
        "offer_expiry_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Expiry Time (EST)"])},
        "offer_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offered By"])},
        "offer_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Comment"])},
        "responsible_physician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible Physician"])},
        "make_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Offer(s)"])},
        "must_offer_single_organ_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All offers must be for the same organ"])},
        "manual_allocation_rationale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Sequence Reason"])},
        "manual_allocation_rationale_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "confirm_other_offers_pending_warning_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This recipient has a primary offer on a different allocation, do you want to continue?"])},
        "confirm_other_offers_accepted_warning_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This recipient has an accepted primary offer on a different allocation, do you want to continue?"])},
        "offerdwl.error.makeoffer.validation.cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. There is an HSP recipient with a higher ranking on the CTR allocation list."])},
        "offerdwl.error.makeoffer.validation.duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSP recipient already has an active offer for the same organ on the CTR allocation list."])},
        "notification_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Email"])},
        "notification_email_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "donor_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Documents"])},
        "documents_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No documents available"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "attachment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment Date"])},
        "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Name"])},
        "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Type"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "uploadedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded By"])},
        "send_notification_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Notification Email"])},
        "personal_information_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not include Personal Information, including Personal Health Information, in the section below."])},
        "personal_information_notice_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any unauthorized collection, use or disclosure of Personal Information is strictly prohibited and subject to applicable laws and penalties"])}
      },
      "fr": {
        "make_offer_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire une ou plusieurs offres aux receveurs sélectionnés"])},
        "select_offer_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les détails de l’offre pour le(s) receveur(s)"])},
        "offer_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d’offre"])},
        "organ_specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécification de l’organe"])},
        "no_offer_reason_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de motif de l’absence d’offre"])},
        "no_offer_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif de l’absence d’offre"])},
        "re-offer-indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicateur de ré-offre"])},
        "non_intended_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Intended Recipient"])},
        "offer_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre effectuée"])},
        "offer_expiry_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date limite de l’offre (HNA)"])},
        "offer_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offert par"])},
        "offer_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire de l’offre"])},
        "responsible_physician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecin responsable"])},
        "make_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire une ou plusieurs offres"])},
        "must_offer_single_organ_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les offres doivent être pour le même orgue"])},
        "manual_allocation_rationale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Sequence Reason"])},
        "manual_allocation_rationale_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "confirm_other_offers_pending_warning_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This recipient has a primary offer on a different allocation, do you want to continue?"])},
        "confirm_other_offers_accepted_warning_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This recipient has an accepted primary offer on a different allocation, do you want to continue?"])},
        "offerdwl.error.makeoffer.validation.cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de synchroniser l'offre avec le CTR. Il y a un destinataire HSP avec un rang plus élevé sur la liste d'attribution CTR."])},
        "offerdwl.error.makeoffer.validation.duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le destinataire HSP a déjà une offre active pour le même organe sur la liste d'attribution CTR."])},
        "notification_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Email"])},
        "notification_email_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "donor_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Documents"])},
        "documents_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No documents available"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "attachment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment Date"])},
        "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Name"])},
        "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Type"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "uploadedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded By"])},
        "send_notification_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Notification Email"])}
      },
      "en_tgln": {
        "offerdwl.error.makeoffer.validation.cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload offer to CTR. There is an HSP recipient with a higher ranking on the CTR allocation list."])}
      },
      "fr_tgln": {
        "offerdwl.error.makeoffer.validation.cannotOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de synchroniser l'offre avec le CTR. Il y a un destinataire HSP avec un rang plus élevé sur la liste d'attribution CTR."])}
      }
    }
  })
}
