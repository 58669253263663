import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.canAccessUsers)
    ? (_openBlock(), _createElementBlock("ul", {
        key: 0,
        class: _normalizeClass(["nav nav-block flex-column", { 'active': _ctx.active }])
      }, [
        _createElementVNode("li", _hoisted_1, [
          _createVNode(_component_router_link, {
            class: "nav-link no-scroll open",
            to: { name: 'Users' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'chevron-right'],
                class: "nav-caret",
                "fixed-width": ""
              }),
              _createTextVNode(" Users ")
            ]),
            _: 1
          })
        ])
      ], 2))
    : _createCommentVNode("", true)
}