<template>
  <div>
    <div v-if="!journey">
      <page-top>
        <span class="skeleton-box" style="width: 200px" />
      </page-top>
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <loading-side-nav />
            <div class="page-content">
              <loading-donor-page />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- Page Top -->
      <page-top>
        <router-link :to="{ name: 'list-recipients' }">
          {{$t('recipients')}}
        </router-link>
        /
        <router-link :to="{ name: 'edit-recipient', params: { id: recipientId} }">
          {{recipientDisplayName}}
        </router-link>
        / {{organDescription}}
      </page-top>
      <recipient-sticky-summary />
      <!-- Page wrap -->
      <div class="content-wrap">
        <!-- Page Container  -->
        <div class="container-fluid">
          <!-- Nav wrapper -->
          <div class="nav-wrapper">
            <!-- Sidebar Navigation -->
            <side-nav-journey />
            <!-- Organ Content -->
            <div class="page-content">
              <recipient-summary />
              <validation-observer ref="validations" @submit.prevent>
                <component
                  ref="organComponent"
                  :new-journey="false"
                  v-bind:is="organComponent"
                  :disabled="!canSaveGetter(false)"
                  @loaded="(ref: string) => loaded(ref)"
                  @sectionsToLoad="(ref: string[]) => sectionsToLoad(ref)"
                />
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Component, Watch } from 'vue-facing-decorator';
import { mixins } from "vue-facing-decorator";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { organCodeLookup } from '@/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { Recipient } from '@/store/recipients/types';
import { PostTransplantFollowUp, RecipientJourney } from '@/store/recipientJourney/types';
import Vca from '@/components/organs/vca/Vca.vue';
import Lung from '@/components/organs/lung/Lung.vue';
import Heart from '@/components/organs/heart/Heart.vue';
import Liver from '@/components/organs/liver/Liver.vue';
import Kidney from '@/components/organs/kidney/Kidney.vue';
import SmallBowel from '@/components/organs/bowel/SmallBowel.vue';
import PancreasWhole from '@/components/organs/pancreas/PancreasWhole.vue';
import PancreasIslets from '@/components/organs/pancreas/PancreasIslets.vue';
import PageTop from '@/components/shared/PageTop.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SideNavJourney from '@/components/organs/shared/side-nav/SideNavJourney.vue';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';
import RecipientSummary from '@/components/recipients/RecipientSummary.vue';
import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
import LoadingDonorPage from '@/components/shared/LoadingDonorPage.vue';
import { i18nMessages } from '@/i18n';
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  components: {
    Vca,
    Lung,
    Heart,
    Liver,
    Kidney,
    SmallBowel,
    PancreasWhole,
    PancreasIslets,
    PageTop,
    SaveToolbar,
    SideNavJourney,
    RecipientSummary,
    RecipientStickySummary,
    LoadingSideNav,
    LoadingDonorPage
  },
  ...i18nMessages([
    require('@/components/_locales/Organs.json'),
    require('@/views/_locales/organs.json'),
  ]),
})
export default class EditOrgan extends mixins(ValidationUtilsMixin) {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedPostTransplantFollowUp) selectedPostTransplantFollowUp!: PostTransplantFollowUp;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('recipientDisplayName', { namespace: 'recipients' } ) private recipientDisplayName!: string;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter("includeStomach", { namespace: "recipients" }) includeStomach!: (journeyId?: string) => boolean;
  @Getter("isTransplantDetailsApplicable", { namespace: "journeyState" }) isTransplantDetailsApplicable!: boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;

  private sectionsLoaded = new Set();
  private sectionsLoading = new Set();
  private allSectionsLoaded = false;

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  private async mounted(): Promise<void> {
    const organId = this.$route.params.organId;
    const recipientId = this.recipientId;
    const journeyId = this.journey?._id?.$oid;

    const currentPageStore = useCurrentPageStore();
    const uiJourney = currentPageStore.currentJourney;
    if (uiJourney) await uiJourney.load();

    Promise.all([
      this.setPageTitle(),
      this.$store.dispatch('validations/loadEdit', { view: `recipients/${recipientId}/journeys`, action: 'edit', clientId: journeyId }),
      this.$store.dispatch('validations/loadOrganSpecificEditValidations', { recipientId, journeyId }),
    ]).finally(() => {
      this.$store.dispatch('utilities/scrollBehavior');
    });
  }

  /**
   * Return true if all subsections are loaded
   *
   * @returns {boolean} true if the page is loaded
   */
  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  // Organ details section will let us know what sections need to be loaded
  public sectionsToLoad(ref: string[]): void {
    // Incoming array sections we're loading from the top level organ details component
    this.sectionsLoading = new Set(ref);
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === this.sectionsLoading.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }

  /**
   * Clear sections loaded when the organ_id changes
   *
   * @listens $route.params.organ_id
   */
  private clearSectionsLoaded(): void {
    this.sectionsLoaded.clear();
    this.sectionsLoading.clear();
  }

  /**
   * Return titlized organ name
   *
   * @returns {string} organ name titlized
   */
  get organDescription(): string {
    let organName = this.organComponent;
    if (this.includeStomach(this.journeyId) && this.journey.organ_code == OrganCodeValue.SmallBowel) {
      return organName = this.$t(`${organName} + Stomach`).toString();
    } else {
      return this.$t(organName).toString();
    }
  }

  /**
   * Gets the current journey as lower case
   *
   * Using the organ code, return the lower case organ name
   *
   * @returns {string} organ as lower case
   */
  get organComponent(): string {
    return organCodeLookup[`${this.journey ? this.journey.organ_code : ''}`] || '';
  }

  // PRIVATE

  /**
   * Update journey when organ_id changes
   *
   * @listens $route.params.organ_id
   */
  @Watch('$route.params.organ_id')
  private updateJourney() {
    this.clearSectionsLoaded();
    this.$store.dispatch('journeyState/getJourney', this.$route.params.organ_id);
    this.setPageTitle();
  }

  /**
   * Gets a Boolean result for the selected journey.
   *
   * Converts the selected journey into a Boolean for easy reference.
   * A new journey returns true, an existing journey would be false.
   *
   * @returns {string} organ as lowercase string or empty string
   */
  @Watch('organDescription')
  private setPageTitle(): void {
    this.$store.commit('setPageTitle', `${this.$t('recipients')} / ${this.recipientDisplayName} / ${this.organDescription}`);
  }
}
</script>
