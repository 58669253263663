import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, toHandlers as _toHandlers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "form-text text-muted",
  title: "Calculated"
}
const _hoisted_3 = { class: "mobile-spacing-wrapper" }
const _hoisted_4 = ["id"]
const _hoisted_5 = {
  key: 1,
  class: "input-group mb-3"
}
const _hoisted_6 = ["id", "readonly", "placeholder", "aria-label"]
const _hoisted_7 = { class: "input-group-append" }
const _hoisted_8 = { class: "input-group-text" }
const _hoisted_9 = ["id"]
const _hoisted_10 = ["id", "readonly", "placeholder", "aria-label"]
const _hoisted_11 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_asterisk = _resolveComponent("validation-asterisk")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(_component_validation_provider, {
    ref: "provider",
    rules: _ctx.formRules,
    name: _ctx.inputId,
    label: _ctx.name,
    vid: _ctx.validationId ? _ctx.validationId : _ctx.inputId,
    modelValue: _ctx.validatedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.validatedValue) = $event))
  }, {
    default: _withCtx(({ field, errors }) => [
      _createElementVNode("label", {
        for: _ctx.inputId,
        class: _normalizeClass({ 'sr-only': _ctx.hideLabel })
      }, [
        _createTextVNode(_toDisplayString(_ctx.label || _ctx.name) + " ", 1),
        (!_ctx.disabled)
          ? (_openBlock(), _createBlock(_component_validation_asterisk, {
              key: 0,
              rules: _ctx.formRules,
              crossValues: _ctx.crossValues,
              ruleKey: _ctx.ruleKey
            }, null, 8, ["rules", "crossValues", "ruleKey"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "link")
      ], 10, _hoisted_1),
      (_ctx.calculated)
        ? (_openBlock(), _createElementBlock("small", _hoisted_2, [
            _createVNode(_component_font_awesome_icon, {
              class: "text-grey",
              icon: ['far', 'exclamation-circle'],
              "fixed-width": ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.calculatedText), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, [
        (_ctx.isMasked)
          ? (_openBlock(), _createElementBlock("input", _mergeProps({
              key: 0,
              type: "text",
              class: { 'is-invalid': !_ctx.disabled && errors[0], 'form-control': !_ctx.isReadOnly(_ctx.readonly), 'form-control-plaintext': _ctx.isReadOnly(_ctx.readonly) },
              id: _ctx.inputId,
              disabled: true,
              readonly: true
            }, field), null, 16, _hoisted_4))
          : (_ctx.append)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _withDirectives(_createElementVNode("input", _mergeProps({
                  id: _ctx.inputId,
                  type: "text",
                  class: { 'is-invalid': !_ctx.disabled && errors[0], 'form-control': !_ctx.isReadOnly(_ctx.readonly), 'form-control-plaintext': _ctx.isReadOnly(_ctx.readonly) }
                }, field, {
                  readonly: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled)
                }, _toHandlers(_ctx.maskedInputEvents(), true), {
                  placeholder: _ctx.timeFormatDisplay,
                  "aria-label": _ctx.timeFormatAriaLabel
                }), null, 16, _hoisted_6), [
                  [_directive_maska, _ctx.timeFormatMask]
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.appendText), 1)
                ]),
                (errors[0])
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "invalid-feedback",
                      id: `${_ctx.inputId}-error`
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['far', 'exclamation-circle'],
                        "fixed-width": ""
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
                    ], 8, _hoisted_9))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _withDirectives(_createElementVNode("input", _mergeProps({
                  id: _ctx.inputId,
                  type: "text",
                  class: { 'is-invalid': !_ctx.disabled && errors[0], 'form-control': !_ctx.isReadOnly(_ctx.readonly), 'form-control-plaintext': _ctx.isReadOnly(_ctx.readonly) }
                }, field, {
                  readonly: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled)
                }, _toHandlers(_ctx.maskedInputEvents(), true), {
                  placeholder: _ctx.timeFormatDisplay,
                  "aria-label": _ctx.timeFormatAriaLabel
                }), null, 16, _hoisted_10), [
                  [_directive_maska, _ctx.timeFormatMask]
                ]),
                (errors[0] && !_ctx.disabled)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "invalid-feedback",
                      id: `${_ctx.inputId}-error`
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['far', 'exclamation-circle'],
                        "fixed-width": ""
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
                    ], 8, _hoisted_11))
                  : _createCommentVNode("", true)
              ], 64))
      ])
    ]),
    _: 3
  }, 8, ["rules", "name", "label", "vid", "modelValue"]))
}