export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "primary_offer_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRIMARY OFFER TIME:"])},
        "primary_offer_time_aria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countdown clock for allocation time remaining"])},
        "offer_expires_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OFFER EXPIRES IN:"])}
      },
      "fr": {
        "primary_offer_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEMPS D’OFFRE PRIMAIRE :"])},
        "primary_offer_time_aria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte à rebours pour le temps d’attribution restant"])},
        "offer_expires_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’OFFRE EXPIRE DANS :"])}
      }
    }
  })
}
