export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation ID"])},
        "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital"])},
        "hospital_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Hospital..."])}
      },
      "fr": {
        "allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation ID"])},
        "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital"])},
        "hospital_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Hospital..."])}
      }
    }
  })
}
