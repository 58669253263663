import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-wrap" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  class: "nav-wrapper",
  id: "administration-area"
}
const _hoisted_4 = { class: "page-content" }
const _hoisted_5 = { class: "col-sm-12" }
const _hoisted_6 = { class: "col-sm-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!
  const _component_test_component_1 = _resolveComponent("test-component-1")!
  const _component_test_component_2 = _resolveComponent("test-component-2")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("template", null, [
      _createVNode(_component_page_top, null, {
        default: _withCtx(() => [
          _createTextVNode(" Test Lookups ")
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_test_component_1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_test_component_2)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}