import { SaveResult } from '@/types';
import { buildErrorResult } from '@/utils';

export class UIError {
  public sourceIdentifier: string|null;
  public errorResult: SaveResult|null = null;

  // Create UI Error view model based on a source identifier and, if available, an API response containing error(s)
  public constructor(sourceIdentifier: string, errorResponse?: any) {
    this.sourceIdentifier = sourceIdentifier;

    if (errorResponse) {
      this.errorResult = buildErrorResult(errorResponse?.data?.errors || errorResponse?.response?.data?.error || {}, sourceIdentifier);
    }
  }
}
