import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "aria-labelledby"]
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = { "aria-hidden": "true" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.modalId,
    "aria-labelledby": _ctx.labelId,
    class: "modal fade",
    tabindex: "-1",
    role: "dialog",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.modalStyle),
      role: "document"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.modalClass)
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", {
            id: _ctx.labelId,
            class: "modal-title"
          }, [
            _renderSlot(_ctx.$slots, "title")
          ], 8, _hoisted_3),
          (_ctx.closeButton)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                tabindex: "-1",
                type: "button",
                class: "close",
                "aria-label": "Close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideModal()))
              }, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('close_modal')), 1),
                _createElementVNode("span", _hoisted_5, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['far', 'times-circle'],
                    class: "text-red",
                    "fixed-width": ""
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "body")
        ]),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "footer")
        ])
      ], 2)
    ], 2)
  ], 8, _hoisted_1))
}