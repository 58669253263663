import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "table-responsive-md" }
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hla_antibody_modal = _resolveComponent("hla-antibody-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("fieldset", {
          id: `${_ctx.id}-entries-table`
        }, null, 8, _hoisted_4)
      ])
    ]),
    _createVNode(_component_hla_antibody_modal, {
      id: "vxm-antibody-modal",
      ref: "vxmAntibodyModal",
      "filtered-antibody-labs": _ctx.filteredRows
    }, null, 8, ["filtered-antibody-labs"])
  ]))
}