import { APISerologyInterface, APISerologyMarkerInterface } from '@/store/recipients/types';
import { UIRecipient } from '@/UIModels/recipient';
import { SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APISaveSerologyResponse } from '@/types';
import { parseDateUiFromDateTime, parseTimeUiFromDateTime, sanitizeDateTimeApi } from '@/utilities/date-utils';
import { useCurrentPageStore } from '@/stores/currentPage';
import vuexStore from '@/store'; // for gradual conversion, see fullUserDetails
import { VirologyCode } from '@/store/lookups/types';

export class UISerologyMarker {
  public apiSource: APISerologyMarkerInterface|null = null;

  public id: string|null = null;
  public test: string|null = null;
  public result: string|null = null;
  public comments: string|null = null;
  public date: string|null = null;
  public time: string|null = null;
  public isNew = false;

  // Define new UI view model structure
  public constructor(apiSerologyMarker: APISerologyMarkerInterface|null = null) {
    if (apiSerologyMarker) this.updateFromAPIResponse(apiSerologyMarker);

    if (!apiSerologyMarker) {
      this.isNew = true;
      // make unique id for 'new' marker
      // convert . in 1718909625675.1636 to - so validation is not broken 
      this.id = ((Date.now() + Math.random()).toString()).replace('.', '-');
    }
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiSerologyMarker: APISerologyMarkerInterface) {
    this.apiSource = apiSerologyMarker;
    this.id = apiSerologyMarker._id?.$oid || null;
    this.test = apiSerologyMarker.code || null;
    this.result = apiSerologyMarker.result || null;
    this.date = parseDateUiFromDateTime(apiSerologyMarker.result_received_datetime) || null;
    this.time = parseTimeUiFromDateTime(apiSerologyMarker.result_received_datetime) || null;
    this.comments = apiSerologyMarker.comments || null;
  }

  public get isEmpty() {
    // start off with true, flip if any data found. 
    let isEmpty = true;
    // we don't care about id's as they'll be re-generated so only check data
    if (this.test) isEmpty = false;
    if (this.result) isEmpty = false;
    if (this.date) isEmpty = false;
    if (this.time) isEmpty = false;
    if (this.comments) isEmpty = false;
    return isEmpty;
  }
}

export class UISerology {
  public apiSource: APISerologyInterface|null = null;

  public id: string|null = null;
  public collection_date: string|null = null;
  public collection_time: string|null = null;
  public collection_comments: string|null = null;
  public markers: UISerologyMarker[] = [];

  public permitted_actions: string[] = []; 

  // Define new UI view model structure
  public constructor(apiSerology: APISerologyInterface|null = null) {
    if (apiSerology) this.updateFromAPIResponse(apiSerology);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiSerology: APISerologyInterface) {
    this.apiSource = apiSerology;
    this.id = apiSerology._id?.$oid || null;
    this.collection_date = parseDateUiFromDateTime(apiSerology.sample_datetime) || null;
    this.collection_time = parseTimeUiFromDateTime(apiSerology.sample_datetime) || null;
    this.collection_comments = apiSerology.sample_comment || null;
    this.markers = [];
    if (apiSerology.results) {
      // append markers
      const importedMarkers: UISerologyMarker[] = apiSerology.results.map((item: APISerologyMarkerInterface) => {
        return new UISerologyMarker(item);
      });

      this.markers = this.orderMarkers(importedMarkers);
    }

    this.permitted_actions = apiSerology.permitted_actions || [];
  }

  // Is this an unsaved New Record?
  get isNew(): boolean {
    return !this.id;
  }

  // Save UI Serology edit state to the backend
  public save(opts: { id: string|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      if (!recipientId) reject((new UIError('patient_serology')));

      let method: any;
      let ep: string;
      if (opts.id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.serology.update, [[':recipient_id', recipientId as string], [':id', opts.id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.serology.create, [[':recipient_id', recipientId as string]]);
      }
      const payload = {
        lab_virology_recipient: this.extractPatch()
      };
      method(ep, payload).then((response: APISaveSerologyResponse) => {
        if (response.data.errors) {
          reject((new UIError('patient_serology', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('patient_serology', errorResponse)).errorResult);
      });
    });
  }

  // Generate request payload parameters to provide to API as part of Create or Update activity
  private extractPatch(): APISerologyInterface {
    const currentPageStore = useCurrentPageStore();
    const systemTimezone = currentPageStore?.configuration?.systemTimezone;

    const result: APISerologyInterface = {
      sample_datetime: sanitizeDateTimeApi(this.collection_date, this.collection_time),
      sample_comment: this.collection_comments || null,
      results: []
    };

    // generate results from markers
    if (this.markers) {
      const results: APISerologyMarkerInterface[] = this.markers.map((marker: UISerologyMarker) => {
        const content: APISerologyMarkerInterface = {
          code: marker.test,
          result: marker.result,
          comments: marker.comments,
          result_received_datetime: sanitizeDateTimeApi(marker.date, marker.time),
        };
        return content;
      });
      // append results
      result.results = results;
    }

    return result;
  }

  // Build a copy of the view model
  public copyViewModel() {
    const apiSerology = this.apiSource as APISerologyInterface;
    if (!apiSerology) return new UISerology();

    return new UISerology(apiSerology);
  }

  public filterOutBlankMarkers(markers: UISerologyMarker[]): UISerologyMarker[] {
    return markers.filter((marker: UISerologyMarker) => {
      if (!marker.isEmpty) return marker;
    });
  }

  public removeBlankMarkers() {
    // remove markers without content
    this.markers = this.filterOutBlankMarkers(this.markers);
  }

  // return an array of virology codes used in the markers
  public get getVirologyCodesUsed() {
    return this.filterOutBlankMarkers(this.markers).map((marker: UISerologyMarker) => {
      return marker.test;
    });
  }

  // order markers given by virology codes lookup
  public orderMarkers(markers: UISerologyMarker[]) {
    const orderedList: UISerologyMarker[] = [];
    this.getVirologyCodesCombined.map((virologyCode: VirologyCode) => {
      markers.map((marker: UISerologyMarker) => {
        if (virologyCode.code === marker.test) {
          orderedList.push(marker);
        }
      });
    });
    return orderedList;
  }

  public get getVirologyCodesCombined(): VirologyCode[] {
    return vuexStore.state.lookups?.virology_codes_combined || [];
  }
}
