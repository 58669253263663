<template>
  <sub-section subSectionId="organ-yield-utilization-report">
    <template v-slot:contents v-if="editState">
      <div class="card tip-card">
        <div class="card-header">
          <div class="media align-items-center">
            <div class="media-left">
              <font-awesome-icon :icon="['far', 'file-chart-line']" fixed-width />
            </div>
            <div class="media-body flex-row align-items-center">
              <h3 class="tip-report-name">
                {{$t('organ_yield_report')}}
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="content-wrap">
            <div class="container-fluid">
              <div class="row">
                <div class="standard-form-group">
                  <date-input
                    inputId="organYieldReport-from_date_1"
                    :name="$t('from_date_1')"
                    v-model="editState.fromDate1"
                    rules="required"
                  />
                </div>
                <div class="standard-form-group">
                  <date-input
                    inputId="organYieldReport-to_date_1"
                    :name="$t('to_date_1')"
                    v-model="editState.toDate1"
                    rules="required"
                  />
                </div>
                <div class="standard-form-group">
                  <select-input
                    selectId="organYieldReport-hospital"
                    :name="$t('donor_hospital')"
                    rules="required"
                    v-model="editState.hospital"
                    :options="hospitalOptions"
                  />
                </div>
              </div>

              <div class="form-row">
                <save-toolbar
                  class="form-inline col-sm-12 action-row"
                  buttonClass="btn btn-success"
                  ref="generateReport"
                  :savingText="$t('saving_text')"
                  :successText="$t('success_text')"
                  :defaultErrorText="$t('default_error_text')"
                  @save="generateReport()"
                >
                  <template v-slot:label>
                    <font-awesome-icon :icon="['far', 'file-chart-line']" class="mr-2" /> {{$t('generate_organ_yield_report')}}
                  </template>
                </save-toolbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </sub-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/OrganYieldUtilizationReport.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { Component, Vue } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import { SaveResult } from '@/types';
import { GenericCodeValue } from '@/store/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { OrganYieldUtilizationReportState, OrganYieldUtilizationReportQuery } from '@/store/reports/types';

@Component({
  components: {
    SaveToolbar,
    DateInput,
    SelectInput,
    SubSection
  }
})

export default class OrganYieldUtilizationReport extends Vue {
  // State
  @State(state => state.pageState.currentPage.organYieldUtilizationReport) editState!: OrganYieldUtilizationReportState;

  @Getter('organTransplantHospitals', { namespace: 'hospitals' }) hospitalOptions!: GenericCodeValue[];
  @Getter('allOptionsPlaceholderValue', { namespace: 'reports' }) allOptionsPlaceholderValue!: (state: any) => string;

  public mounted(): void {
    this.$store.dispatch('hospitals/load').then(() => {
      this.$store.commit('pageState/set', {
        pageKey: 'organYieldUtilizationReport',
        value: this.buildOrganYieldUtilizationPageState(),
      });
    });
  }

  public buildOrganYieldUtilizationPageState(): OrganYieldUtilizationReportState {
    // Default Value of From Date: Beginning of the current fiscal year (April 1)
    // Default Value of To Date: End of the previous whole month
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    // April getMonth() value = 3
    const fiscalYear = currentMonth <= 3 ? currentYear - 1 : currentYear;
    const beginningOfCurrentFiscalYear = `${fiscalYear}-04-01`;

    const endOPfPreviousMonth = new Date();
    // setDate(dayValue) dayValue: An integer representing the day of the month.
    // 0 would be the last day of previous month
    endOPfPreviousMonth.setDate(0);

    return {
      fromDate1: beginningOfCurrentFiscalYear,
      toDate1: endOPfPreviousMonth.toJSON().slice(0,10),
      hospital: []
    };
  }

  public allOptionsPlaceholderText(state: any) {
    return this.allOptionsPlaceholderValue(state);
  }

  public defaultAllOptions(options: GenericCodeValue[]): string[] {
    return options.map((option: GenericCodeValue) => {
      return option.code;
    });
  }

  public buildOrganYieldUtilizationReportQuery(): OrganYieldUtilizationReportQuery {
    if (!this.editState) {
      return {};
    }

    const result = {
      from_date_1: this.editState.fromDate1 || undefined,
      to_date_1: this.editState.toDate1 || undefined,
      hospital: this.editState.hospital
    };

    return result;
  }

  public generateReport(): void {
    this.clear();
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    saveToolbar.startSaving();
    // Submit query
    const organYieldUtilizationQuery = this.buildOrganYieldUtilizationReportQuery();
    this.$store.dispatch('reports/printOrganYieldUtilizationReport', organYieldUtilizationQuery).then((result: SaveResult) => {
      // Get the filename
      const fileName = result.responseData.report;
      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
      }
      saveToolbar.stopSaving(result);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      saveToolbar.stopSaving(error);
    });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'from_date_1' : 'organYieldReport-from_date_1',
    'to_date_1'   : 'organYieldReport-to_date_1',
    'hospital'    : 'organYieldReport-hospital'
  }

  // Emit event to parent so it can clear validations
  private clear() {
    this.$emit('clear');
  }
}
</script>
