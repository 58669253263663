<template>
  <div>
    <page-top>
      {{$t('administration')}}
    </page-top>
    <div class="content-wrap">
      <div class="container-fluid">
        <div class="nav-wrapper" id='administration-area'>
          <side-nav-admin></side-nav-admin>
          <div class="page-content" >
            <div class="col-sm-12">
              {{$t('administration')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import PageTop from '@/components/shared/PageTop.vue';
import { Component, Vue } from 'vue-facing-decorator';
import SideNavAdmin from '@/components/administration/side-nav/SideNavAdmin.vue';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    PageTop,
    SideNavAdmin
  },
  ...i18nMessages([
    require('@/views/_locales/Administration.json'),
  ]),
})
export default class Administration extends Vue {
}
</script>
