export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "organ_yield_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Yield Utilization Report"])},
        "from_date_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Date"])},
        "to_date_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Date"])},
        "donor_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Hospital"])},
        "generate_organ_yield_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Organ Yield Utilization Report"])}
      },
      "fr": {
        "organ_yield_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Yield Utilization Report"])},
        "from_date_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Date"])},
        "to_date_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Date"])},
        "donor_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Hospital"])},
        "generate_organ_yield_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Organ Yield Utilization Report"])}
      }
    }
  })
}
