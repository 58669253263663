import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "hla-input-wrapper" }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["id", "value", "disabled"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["aria-label"]
const _hoisted_8 = ["aria-label"]
const _hoisted_9 = {
  key: 2,
  class: "hla-tag"
}
const _hoisted_10 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_asterisk = _resolveComponent("validation-asterisk")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_vue_tags_input = _resolveComponent("vue-tags-input")!
  const _component_validation_provider = _resolveComponent("validation-provider")!

  return (_ctx.isVerbose && _ctx.isVxm && _ctx.isEmpty)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.isUntested ? _ctx.$t('untested_vxm_result_short') : _ctx.$t('empty_vxm_result')), 1))
    : (_openBlock(), _createBlock(_component_validation_provider, {
        key: 1,
        ref: "provider",
        as: "div",
        class: "hla-input-validation-wrapper",
        rules: _ctx.formRules,
        name: _ctx.inputId,
        label: _ctx.name,
        vid: _ctx.validationId ? _ctx.validationId : _ctx.inputId
      }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", {
              for: _ctx.inputId,
              class: _normalizeClass({ 'sr-only': _ctx.hideLabel })
            }, [
              _createTextVNode(_toDisplayString(_ctx.label || _ctx.name) + " ", 1),
              (!_ctx.disabled)
                ? (_openBlock(), _createBlock(_component_validation_asterisk, {
                    key: 0,
                    rules: _ctx.formRules,
                    crossValues: _ctx.crossValues,
                    ruleKey: _ctx.ruleKey
                  }, null, 8, ["rules", "crossValues", "ruleKey"]))
                : _createCommentVNode("", true)
            ], 10, _hoisted_3),
            (_ctx.calculated)
              ? (_openBlock(), _createElementBlock("small", {
                  key: 0,
                  class: "form-text text-muted",
                  title: _ctx.$t(_ctx.calculatedHoverText)
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "text-grey",
                    icon: ['far', 'exclamation-circle'],
                    "fixed-width": ""
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.calculatedText)), 1)
                ], 8, _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.showEmpty())
              ? (_openBlock(), _createElementBlock("input", {
                  key: 1,
                  id: _ctx.inputId,
                  value: _ctx.disabledEmptyText,
                  type: "text",
                  class: "form-control",
                  disabled: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled)
                }, null, 8, _hoisted_5))
              : (_ctx.isReadOnly(_ctx.readonly))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    id: _ctx.inputId,
                    class: _normalizeClass(_ctx.inputClassObject(errors, 'form-control'))
                  }, [
                    _createElementVNode("div", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (antibody, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: `${_ctx.inputId}-antibody-${index}`,
                          class: _normalizeClass({ 'badge': true, 'badge-titag': true,  'mr-1': true, 'hla-tag-invalid': _ctx.invalidAntibodies.includes(antibody) })
                        }, _toDisplayString(antibody), 3))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alleleSpecific, (antibody, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: `${_ctx.inputId}-allelespecific-${index}`,
                          class: "badge badge-titag hla-antibody-highlight mr-1"
                        }, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['far', 'dot-circle'],
                            "fixed-width": "",
                            title: _ctx.$t('associated_with_allele_specific_antibody')
                          }, null, 8, ["title"]),
                          _createTextVNode(" " + _toDisplayString(antibody), 1)
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alphaBetaSpecific, (antibody, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: `${_ctx.inputId}-alphabeta-${index}`,
                          class: "badge badge-titag hla-antibody-highlight mr-1"
                        }, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['far', 'link'],
                            rotation: "90",
                            "fixed-width": "",
                            title: _ctx.$t('associated_with_alpha_beta_antibody')
                          }, null, 8, ["title"]),
                          _createTextVNode(" " + _toDisplayString(antibody), 1)
                        ]))
                      }), 128))
                    ])
                  ], 10, _hoisted_6))
                : (_openBlock(), _createBlock(_component_vue_tags_input, {
                    key: 3,
                    modelValue: _ctx.tagInput,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tagInput) = $event)),
                    id: _ctx.inputId,
                    class: _normalizeClass(_ctx.inputClassObject(errors)),
                    disabled: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled),
                    placeholder: _ctx.placeholder,
                    tags: _ctx.tagObjects,
                    "add-on-key": [13, ' '],
                    separators: [' ', ','],
                    "allow-edit-tags": !_ctx.disabled,
                    validation: _ctx.tagValidationObject(),
                    "max-tags": _ctx.maxTags,
                    onTagClicked: _cache[1] || (_cache[1] = (clickedTag) => _ctx.tagClicked(clickedTag)),
                    onTagsChanged: _cache[2] || (_cache[2] = (updatedTagObjects) => _ctx.tagsChanged(updatedTagObjects)),
                    onBeforeAddingTag: _cache[3] || (_cache[3] = (newTagObject) => _ctx.beforeAddingTag(newTagObject)),
                    onBeforeSavingTag: _cache[4] || (_cache[4] = (updatedTagObject) => _ctx.beforeSavingTag(updatedTagObject))
                  }, {
                    "tag-center": _withCtx((props) => [
                      (props.tag.alleleSpecific)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: "hla-tag",
                            "aria-label": _ctx.$t('associated_with_allele_specific_antibody')
                          }, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: ['far', 'dot-circle'],
                              "fixed-width": "",
                              title: _ctx.$t('associated_with_allele_specific_antibody')
                            }, null, 8, ["title"]),
                            _createTextVNode(" " + _toDisplayString(props.tag.text), 1)
                          ], 8, _hoisted_7))
                        : (props.tag.alphaBetaSpecific)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 1,
                              class: "hla-tag",
                              "aria-label": _ctx.$t('associated_with_alpha_beta_antibody')
                            }, [
                              _createVNode(_component_font_awesome_icon, {
                                icon: ['far', 'link'],
                                "fixed-width": "",
                                rotation: "90",
                                title: _ctx.$t('associated_with_alpha_beta_antibody')
                              }, null, 8, ["title"]),
                              _createTextVNode(" " + _toDisplayString(props.tag.text), 1)
                            ], 8, _hoisted_8))
                          : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(props.tag.text), 1))
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "id", "class", "disabled", "placeholder", "tags", "allow-edit-tags", "validation", "max-tags"]))
          ]),
          (errors[0] && !_ctx.disabled)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "invalid-feedback",
                id: `${_ctx.inputId}-error`
              }, [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['far', 'exclamation-circle'],
                  "fixed-width": ""
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
              ], 8, _hoisted_10))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["rules", "name", "label", "vid"]))
}