import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-4 col-lg-3 col-xl-2" }
const _hoisted_3 = { id: "allocation-id-label" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "col-md-4 col-lg-3 col-xl-2" }
const _hoisted_6 = { class: "col-md-4 col-lg-3 col-xl-2" }
const _hoisted_7 = { class: "col-md-4 col-lg-3 col-xl-2" }
const _hoisted_8 = {
  key: 0,
  class: "col-md-4 col-lg-3 col-xl-2"
}
const _hoisted_9 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: _ctx.$t('allocation_details'),
    "sub-section-id": "allocation-details",
    "save-button": false
  }, _createSlots({ _: 2 }, [
    (_ctx.livingDonor && _ctx.allocation)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createElementVNode("fieldset", null, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("dl", _hoisted_2, [
                  _createElementVNode("dt", _hoisted_3, _toDisplayString(_ctx.$t('allocation_id')), 1),
                  _createElementVNode("dd", {
                    title: _ctx.$t('allocation_id'),
                    "aria-labelledby": "allocation-id-label"
                  }, _toDisplayString(_ctx.allocation.client_id), 9, _hoisted_4)
                ]),
                _createElementVNode("dl", _hoisted_5, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('allocation_stage_field')), 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.$t(_ctx.allocationStage)), 1)
                ]),
                _createElementVNode("dl", _hoisted_6, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('allocation_run_datetime')), 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.allocationTime), 1)
                ]),
                _createElementVNode("dl", _hoisted_7, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('organ')), 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.$t(`${_ctx.organName}`)), 1)
                ]),
                (_ctx.allocation.disabled_program_rules.length > 0)
                  ? (_openBlock(), _createElementBlock("dl", _hoisted_8, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('disabled_program_rules')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.allocation.disabled_program_rules.join(', ')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.specialConsiderations.length > 0)
                  ? (_openBlock(), _createElementBlock("dl", _hoisted_9, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('special_considerations')), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specialConsiderations, (consideration) => {
                        return (_openBlock(), _createElementBlock("dd", {
                          key: consideration.index
                        }, [
                          _createElementVNode("span", {
                            innerHTML: _ctx.translateContext(consideration)
                          }, null, 8, _hoisted_10)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["title"]))
}