import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "table-responsive-md" }
const _hoisted_2 = { class: "table table-bordered table-hover" }
const _hoisted_3 = {
  scope: "col",
  width: "15%"
}
const _hoisted_4 = {
  scope: "col",
  class: "text-center",
  width: "10.625%"
}
const _hoisted_5 = {
  scope: "col",
  class: "text-center",
  width: "10.625%"
}
const _hoisted_6 = {
  scope: "col",
  class: "text-center",
  width: "10.625%"
}
const _hoisted_7 = {
  scope: "col",
  class: "text-center",
  width: "10.625%"
}
const _hoisted_8 = {
  scope: "col",
  class: "text-center",
  width: "10.625%"
}
const _hoisted_9 = {
  scope: "col",
  class: "text-center",
  width: "10.625%"
}
const _hoisted_10 = {
  scope: "col",
  class: "text-center",
  width: "10.625%"
}
const _hoisted_11 = {
  scope: "col",
  class: "text-center",
  width: "10.625%"
}
const _hoisted_12 = { scope: "row" }
const _hoisted_13 = { class: "td-check" }
const _hoisted_14 = { class: "checklist border-0" }
const _hoisted_15 = { class: "form-check" }
const _hoisted_16 = ["for"]
const _hoisted_17 = { class: "sr-only" }
const _hoisted_18 = ["id", "onUpdate:modelValue", "onClick", "disabled"]
const _hoisted_19 = { class: "td-check" }
const _hoisted_20 = { class: "checklist border-0" }
const _hoisted_21 = { class: "form-check" }
const _hoisted_22 = ["for"]
const _hoisted_23 = { class: "sr-only" }
const _hoisted_24 = ["id", "aria-checked", "onUpdate:modelValue", "aria-labelledby", "onClick", "disabled"]
const _hoisted_25 = { class: "td-check" }
const _hoisted_26 = { class: "checklist border-0" }
const _hoisted_27 = { class: "form-check" }
const _hoisted_28 = ["for"]
const _hoisted_29 = { class: "sr-only" }
const _hoisted_30 = ["id", "aria-checked", "onUpdate:modelValue", "aria-labelledby", "onClick", "disabled"]
const _hoisted_31 = { class: "td-check" }
const _hoisted_32 = { class: "checklist border-0" }
const _hoisted_33 = { class: "form-check" }
const _hoisted_34 = ["for"]
const _hoisted_35 = { class: "sr-only" }
const _hoisted_36 = ["id", "aria-checked", "onUpdate:modelValue", "aria-labelledby", "onClick", "disabled"]
const _hoisted_37 = { class: "td-check" }
const _hoisted_38 = { class: "checklist border-0" }
const _hoisted_39 = { class: "form-check" }
const _hoisted_40 = ["for"]
const _hoisted_41 = { class: "sr-only" }
const _hoisted_42 = ["id", "aria-checked", "onUpdate:modelValue", "aria-labelledby", "onClick", "disabled"]
const _hoisted_43 = { class: "td-check" }
const _hoisted_44 = { class: "checklist border-0" }
const _hoisted_45 = { class: "form-check" }
const _hoisted_46 = ["for"]
const _hoisted_47 = { class: "sr-only" }
const _hoisted_48 = ["id", "aria-labelledby", "onUpdate:modelValue", "onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "organ-packaging-forms",
    ref: "packagingForms",
    "save-button": true,
    disabled: !(_ctx.canSave && _ctx.isOneSelected),
    "save-button-text": `${_ctx.$t('create_forms')}`,
    onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.savePatch())),
    onLoaded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('organ_packaging_forms')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        title: _ctx.$t('available_forms'),
        "sub-section-id": "available-forms"
      }, _createSlots({ _: 2 }, [
        (_ctx.editState)
          ? {
              name: "contents",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("table", _hoisted_2, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t('organ_packaging_organ_consents')), 1),
                        _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t('organ_packaging_ODSIF')), 1),
                        _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t('organ_packaging_cooler_sheet')), 1),
                        _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('organ_packaging_interior_organ_labels')), 1),
                        _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t('organ_packaging_exterior_organ_labels')), 1),
                        _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t('organ_packaging_all')), 1),
                        _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t('organ_packaging_intended_research')), 1),
                        _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t('organ_packaging_transplant_program')), 1),
                        _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.$t('organ_packaging_recipient_id')), 1)
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState, (row, idx) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: idx,
                          class: "tr"
                        }, [
                          _createElementVNode("th", _hoisted_12, _toDisplayString(row.organLabel), 1),
                          _createElementVNode("td", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("label", {
                                  for: `forms-osdif-${idx}`,
                                  class: "form-check-label"
                                }, [
                                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('toggle_selection')), 1),
                                  _withDirectives(_createElementVNode("input", {
                                    id: `forms-osdif-${idx}`,
                                    type: "checkbox",
                                    class: "form-check-input",
                                    "onUpdate:modelValue": ($event: any) => ((row.osdif) = $event),
                                    onClick: ($event: any) => (_ctx.checkRow($event, row)),
                                    disabled: !_ctx.isGroupWriteable('organ_packaging_odsif')
                                  }, null, 8, _hoisted_18), [
                                    [_vModelCheckbox, row.osdif]
                                  ])
                                ], 8, _hoisted_16)
                              ])
                            ])
                          ]),
                          _createElementVNode("td", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("label", {
                                  for: `forms-cooler-${idx}`,
                                  class: "form-check-label"
                                }, [
                                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('toggle_selection')), 1),
                                  _withDirectives(_createElementVNode("input", {
                                    id: `forms-cooler-${idx}`,
                                    type: "checkbox",
                                    class: "form-check-input",
                                    role: "checkbox",
                                    "aria-checked": row.coolerSheet,
                                    tabindex: "0",
                                    "onUpdate:modelValue": ($event: any) => ((row.coolerSheet) = $event),
                                    "aria-labelledby": `forms-cooler-${idx}`,
                                    onClick: ($event: any) => (_ctx.checkRow($event, row)),
                                    disabled: _ctx.isVesselsForBanking(row) || !_ctx.isGroupWriteable('organ_packaging')
                                  }, null, 8, _hoisted_24), [
                                    [_vModelCheckbox, row.coolerSheet]
                                  ])
                                ], 8, _hoisted_22)
                              ])
                            ])
                          ]),
                          _createElementVNode("td", _hoisted_25, [
                            _createElementVNode("div", _hoisted_26, [
                              _createElementVNode("div", _hoisted_27, [
                                _createElementVNode("label", {
                                  for: `forms-interior-${idx}`,
                                  class: "form-check-label"
                                }, [
                                  _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t('toggle_selection')), 1),
                                  _withDirectives(_createElementVNode("input", {
                                    id: `forms-interior-${idx}`,
                                    type: "checkbox",
                                    class: "form-check-input",
                                    role: "checkbox",
                                    "aria-checked": row.interiorLabel,
                                    tabindex: "0",
                                    "onUpdate:modelValue": ($event: any) => ((row.interiorLabel) = $event),
                                    "aria-labelledby": `forms-interior-${idx}`,
                                    onClick: ($event: any) => (_ctx.checkRow($event, row)),
                                    disabled: !_ctx.isGroupWriteable('organ_packaging')
                                  }, null, 8, _hoisted_30), [
                                    [_vModelCheckbox, row.interiorLabel]
                                  ])
                                ], 8, _hoisted_28)
                              ])
                            ])
                          ]),
                          _createElementVNode("td", _hoisted_31, [
                            _createElementVNode("div", _hoisted_32, [
                              _createElementVNode("div", _hoisted_33, [
                                _createElementVNode("label", {
                                  for: `forms-exterior-${idx}`,
                                  class: "form-check-label"
                                }, [
                                  _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.$t('toggle_selection')), 1),
                                  _withDirectives(_createElementVNode("input", {
                                    id: `forms-exterior-${idx}`,
                                    type: "checkbox",
                                    class: "form-check-input",
                                    role: "checkbox",
                                    "aria-checked": row.exteriorLabel,
                                    tabindex: "0",
                                    "onUpdate:modelValue": ($event: any) => ((row.exteriorLabel) = $event),
                                    "aria-labelledby": `forms-exterior-${idx}`,
                                    onClick: ($event: any) => (_ctx.checkRow($event, row)),
                                    disabled: !_ctx.isGroupWriteable('organ_packaging')
                                  }, null, 8, _hoisted_36), [
                                    [_vModelCheckbox, row.exteriorLabel]
                                  ])
                                ], 8, _hoisted_34)
                              ])
                            ])
                          ]),
                          _createElementVNode("td", _hoisted_37, [
                            _createElementVNode("div", _hoisted_38, [
                              _createElementVNode("div", _hoisted_39, [
                                _createElementVNode("label", {
                                  for: `forms-all-${idx}`,
                                  class: "form-check-label"
                                }, [
                                  _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.$t('toggle_selection')), 1),
                                  _withDirectives(_createElementVNode("input", {
                                    id: `forms-all-${idx}`,
                                    type: "checkbox",
                                    class: "form-check-input",
                                    role: "checkbox",
                                    "aria-checked": row.all,
                                    tabindex: "0",
                                    "onUpdate:modelValue": ($event: any) => ((row.all) = $event),
                                    "aria-labelledby": `forms-all-${idx}`,
                                    onClick: ($event: any) => (_ctx.checkRow($event, row)),
                                    disabled: !_ctx.isGroupWriteable('organ_packaging')
                                  }, null, 8, _hoisted_42), [
                                    [_vModelCheckbox, row.all]
                                  ])
                                ], 8, _hoisted_40)
                              ])
                            ])
                          ]),
                          _createElementVNode("td", _hoisted_43, [
                            _createElementVNode("div", _hoisted_44, [
                              _createElementVNode("div", _hoisted_45, [
                                _createElementVNode("label", {
                                  for: `for-research-${idx}`,
                                  class: "form-check-label"
                                }, [
                                  _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.$t('toggle_selection')), 1),
                                  _withDirectives(_createElementVNode("input", {
                                    id: `for-research-${idx}`,
                                    type: "checkbox",
                                    class: "form-check-input",
                                    role: "checkbox",
                                    "aria-checked": "false",
                                    tabindex: "0",
                                    "aria-labelledby": `for-research-${idx}`,
                                    "onUpdate:modelValue": ($event: any) => ((row.forResearch) = $event),
                                    onClick: ($event: any) => (_ctx.checkRow($event, row)),
                                    disabled: !_ctx.isConsentedForResearch && !_ctx.isGroupWriteable('organ_packaging')
                                  }, null, 8, _hoisted_48), [
                                    [_vModelCheckbox, row.forResearch]
                                  ])
                                ], 8, _hoisted_46)
                              ])
                            ])
                          ]),
                          _createElementVNode("td", null, _toDisplayString(row.transplantProgram), 1),
                          _createElementVNode("td", null, _toDisplayString(row.recipientClientId), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["title"])
    ]),
    _: 1
  }, 8, ["disabled", "save-button-text"]))
}