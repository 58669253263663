export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "make_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Offer"])},
        "discontinue_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue Offer"])},
        "discontinue_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue Allocation"])},
        "respond_to_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respond to Offer"])},
        "run_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run Allocation Recommendation"])},
        "rerun_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Run Allocation Recommendation"])},
        "run_expedited_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run Expedited Allocation Recommendation"])},
        "rerun_expedited_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Run Expedited Allocation Recommendation"])},
        "offer_outcome_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR error workflow"])},
        "offer_outcome_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
        "offer_outcome_ctr_errors_heading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["An error was raised by CTR"]), _normalize([_interpolate(_named("count")), " errors were raised by CTR"])])},
        "ctr_error_integration_context": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["The Canadian Transplant Registry (CTR) system raised an error while attempting to ", _interpolate(_named("operationPerformed")), "."]), _normalize(["The Canadian Transplant Registry (CTR) system raised ", _interpolate(_named("count")), " errors while attempting to ", _interpolate(_named("operationPerformed")), "."])])},
        "offer_outcome_warning_messages_heading": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Meaning of the error"]), _normalize(["Meaning of the errors"])])},
        "offer_outcome_consequences_heading": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Consequence of the error"]), _normalize(["Consequence of the errors"])])},
        "ctr_unexpected_error_occurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occured."])},
        "no_offer_persisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offer was <em>not created</em> in Afflo or CTR."])},
        "offer_persisted_but_not_sent_to_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offer was created in Afflo, but <em>was not sent to CTR.</em>"])},
        "offer_persisted_but_cannot_detect_offer_sent_to_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offer was created in Afflo, but <em>the CTR system failed to confirm whether or not the offer was sent.</em>"])},
        "ctr_error_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Error ID"])},
        "ctr_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Message"])},
        "ctr_error_workflow_instructions": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Corrective Action"]), _normalize(["Corrective Actions"])])},
        "checkbox_label_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrective Action Confirmation"])},
        "checkbox_selected_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acknowledge"])},
        "unable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to initialize Offer Outcome Modal"])}
      },
      "fr": {
        "make_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire une offre"])},
        "discontinue_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinuer l'offre"])},
        "discontinue_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinuer la prescription d'attribution"])},
        "respond_to_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondre à l'offre"])},
        "run_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exécuter la prescription d'attribution"])},
        "rerun_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ré-Exécuter la prescription d'attribution"])},
        "run_expedited_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exécuter la prescription d’attribution accélérée"])},
        "rerun_expedited_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ré-Exécuter la prescription d’attribution accélérée"])},
        "offer_outcome_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow d'erreur de CTR"])},
        "offer_outcome_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
        "offer_outcome_ctr_errors_heading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Une erreur a été soulevée par CTR"]), _normalize([_interpolate(_named("count")), " erreurs ont été soulevées par CTR"])])},
        "ctr_error_integration_context": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Le système du Registre canadien des greffes (RCT) a soulevé une erreur en tentant de ", _interpolate(_named("operationPerformed")), "."]), _normalize(["Le système du Registre canadien des greffes (RCT) a soulevé ", _interpolate(_named("count")), " erreurs lors de la tentative de ", _interpolate(_named("operationPerformed")), "."])])},
        "offer_outcome_warning_messages_heading": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Signification de l'erreur"]), _normalize(["Signification des l'erreures"])])},
        "offer_outcome_consequences_heading": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Conséquence de l'erreur"]), _normalize(["Conséquence des l'erreures"])])},
        "ctr_unexpected_error_occurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur inattendue s'est produite."])},
        "no_offer_persisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'offre n'a <em>pas été créée</em> dans Afflo ou CTR."])},
        "offer_persisted_but_not_sent_to_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'offre a été créée dans Afflo, mais <em>n'a pas été envoyée au CTR.</em>"])},
        "offer_persisted_but_cannot_detect_offer_sent_to_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offer was created in Afflo, but <em>the CTR system failed to confirm whether or not the offer was sent.</em>"])},
        "ctr_error_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No d'erreur CTR"])},
        "ctr_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message CTR"])},
        "ctr_error_workflow_instructions": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Action corrective"]), _normalize(["Actions correctives"])])},
        "checkbox_label_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de l'action corrective"])},
        "checkbox_selected_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconnaître"])},
        "unable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'initialiser la modale de résultat de l'offre"])}
      },
      "en_tgln": {
        "no_offer_persisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offer was <em>not created</em> in OATS or CTR."])},
        "offer_persisted_but_not_sent_to_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offer was created in OATS, but <em>was not sent to CTR.</em>"])},
        "offer_persisted_but_cannot_detect_offer_sent_to_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offer was created in OATS, but <em>the CTR system failed to confirm whether or not the offer was sent.</em>"])}
      },
      "fr_tgln": {
        "no_offer_persisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'offre n'a <em>pas été créée</em> dans OATS ou CTR."])},
        "offer_persisted_but_not_sent_to_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'offre a été créée dans OATS, mais <em>n'a pas été envoyée au CTR.</em>"])},
        "offer_persisted_but_cannot_detect_offer_sent_to_ctr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offer was created in OATS, but <em>the CTR system failed to confirm whether or not the offer was sent.</em>"])}
      }
    }
  })
}
