export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donors"])},
        "add_new_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Donor"])},
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System ID"])},
        "deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])},
        "hospital_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital"])},
        "national_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National ID"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
        "cause_of_death": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause of Death"])},
        "donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Type"])},
        "referral_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Type"])},
        "organ_consents_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consented Organs"])},
        "ecd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECD"])},
        "referral_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Date"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
        "rh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RH"])},
        "mrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRN"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
      },
      "fr": {
        "donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donateurs"])},
        "add_new_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un Nouveau Donateur"])},
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID système"])},
        "deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo"])},
        "hospital_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôpital"])},
        "national_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité nationale du donneur"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "cause_of_death": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause du décès"])},
        "donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de donneur"])},
        "referral_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de référence"])},
        "organ_consents_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organes consentis"])},
        "ecd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECD"])},
        "referral_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de référence"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe sanguin"])},
        "rh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RH"])},
        "mrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRN"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])}
      },
      "en_tgln": {
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OATS ID"])},
        "deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID"])}
      },
      "fr_tgln": {
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du OATS"])},
        "deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])}
      },
      "en_tq": {
        "deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])}
      },
      "fr_tq": {
        "deceased_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])}
      }
    }
  })
}
