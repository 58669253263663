import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-flat" }
const _hoisted_2 = {
  key: 0,
  class: "loading-data w-100",
  style: {"padding":"2rem 2rem","text-align":"center","opacity":"50%"}
}
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "nav action-row mt-0" }
const _hoisted_5 = { class: "dropdown" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_8 = { class: "dropdown-menu" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_allocation_details = _resolveComponent("allocation-details")!
  const _component_donor_checklist = _resolveComponent("donor-checklist")!
  const _component_allocation_offer_response = _resolveComponent("allocation-offer-response")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "donor-allocation-eoffer",
    lookupsToLoad: _ctx.lookupsToLoad
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('eoffer_responses')), 1)
    ]),
    body: _withCtx(() => [
      (_ctx.isLoadingAllocation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_font_awesome_icon, {
              class: "fa-5x fa-spin",
              icon: ['far', 'spinner-third']
            })
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isLoadingAllocations && _ctx.allocation && !_ctx.isLoadingAllocation && !_ctx.isInitializing)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.allowedAllocationReport)
              ? (_openBlock(), _createBlock(_component_sub_section, {
                  key: 0,
                  title: _ctx.$t('allocation_controls'),
                  "sub-section-id": "allocation-controls",
                  "save-button": false
                }, _createSlots({ _: 2 }, [
                  (_ctx.editState)
                    ? {
                        name: "contents",
                        fn: _withCtx(() => [
                          _createElementVNode("fieldset", { disabled: _ctx.isLoadingAllocation }, [
                            _createElementVNode("nav", _hoisted_4, [
                              _createElementVNode("div", _hoisted_5, [
                                (_ctx.allowedAllocationReport)
                                  ? (_openBlock(), _createElementBlock("button", {
                                      key: 0,
                                      type: "button",
                                      "data-toggle": "dropdown",
                                      disabled: _ctx.isLoadingAllocation || _ctx.isCreatingAllocation || _ctx.isGeneratingAllocationReport,
                                      class: "btn btn-wide btn-lg btn-primary btn-sm mt-2 mr-2 dropdown-toggle"
                                    }, [
                                      _createTextVNode(_toDisplayString(_ctx.allocationReportButtonText) + " ", 1),
                                      (_ctx.isGeneratingAllocationReport)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                            _createVNode(_component_font_awesome_icon, {
                                              class: "fa-1x fa-spin",
                                              icon: ['far', 'spinner-third']
                                            })
                                          ]))
                                        : _createCommentVNode("", true)
                                    ], 8, _hoisted_6))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_8, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allowedAllocationReportHospitals, (row, idx) => {
                                    return (_openBlock(), _createElementBlock("a", {
                                      class: "dropdown-item",
                                      key: idx,
                                      onClick: ($event: any) => (_ctx.downloadAllocationReport(row.code))
                                    }, _toDisplayString(row.value), 9, _hoisted_9))
                                  }), 128))
                                ])
                              ])
                            ])
                          ], 8, _hoisted_3)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["title"]))
              : _createCommentVNode("", true),
            (_ctx.showAllocationComponents)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_allocation_details, { ref: "allocationDetails" }, null, 512),
                  (_ctx.canReadChecklists)
                    ? (_openBlock(), _createBlock(_component_donor_checklist, {
                        key: 0,
                        canSave: false
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.allocation)
                    ? (_openBlock(), _createBlock(_component_allocation_offer_response, {
                        key: 1,
                        ref: "allocationOffers",
                        combined: true
                      }, null, 512))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}