import Vue from 'vue';
import { MutationTree } from 'vuex';
import { DecisionsState, ListDecisions, Decision, ListAttachments, Attachment, ListResponses, Response, ListFinalDecisions, FinalDecision } from '@/store/decisions/types';

export const mutations: MutationTree<DecisionsState> = {
  setDecision(state, decision) {
    // Clone data and sanitize the clone
    const sanitizedData: Decision = Object.assign({}, decision);

    // Store sanitized clone
    state.selectedDecision = sanitizedData;
  },
  setListDecisions(state, decisions) {
    state.listDecisions = decisions;
  },

  setAttachment(state, attachment) {
    // Clone data and sanitize the clone
    const sanitizedData: Attachment = Object.assign({}, attachment);

    // Store sanitized clone
    state.selectedAttachment = sanitizedData;
  },
  setListAttachments(state, attachments) {
    state.listAttachments = attachments;
  },

  setResponse(state, response) {
    // Clone data and sanitize the clone
    const sanitizedData: Response = Object.assign({}, response);

    // Store sanitized clone
    state.selectedResponse = sanitizedData;
  },
  setListResponses(state, responses) {
    state.listResponses = responses;
  },

  setFinalDecision(state, finalDecision) {
    // Clone data and sanitize the clone
    const sanitizedData: FinalDecision = Object.assign({}, finalDecision);

    // Store sanitized clone
    state.selectedFinalDecision = sanitizedData;
  },
  setListFinalDecisions(state, finalDecisions) {
    state.listFinalDecisions = finalDecisions;
  },
  // clear children lists
  flushChildren(state) {
    state.listAttachments = [];
    state.listResponses = {};
    state.listFinalDecisions = {};
  }
};
