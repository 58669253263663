import { GenericCodeValue } from '@/store/types';
import { APIRecipient } from '@/store/recipients/types';
import { IdLookup } from '@/store/validations/types';
import { APIJourney } from './store/recipientJourney/types';
import { APITransplantDetailsInterface } from './APIModels/journey/types';

export interface TableConfig {
  data: any[];
  columns: any[];
  empty?: string;
  createButton?: boolean;
  createText?: string;
  groupOptions?: any;
  pagination?: boolean;
  paginationOptions?: any;
  sortOptions?: any;
  searchOptions?: any;
}

export interface ColumnOption {
  field: string;
  filterOptions?: {
    enabled: boolean;
    custom?: boolean;
    type?: string;
    placeholder?: string;
    filterDropdownItems?: GenericCodeValue[];
  }
  label: string;
  sortable: boolean;
  width: string;
  hidden?: boolean;
  thClass?: string;
  tdClass?: string;
  formatFn?: (value: any) => string|undefined;
  html?: boolean;
  type?: string;
  class?: string;
}

export enum SortType {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface SaveableSection {
  savePatch: (duplicate?: boolean) => void;
  extractPatch: () => any;
  resetSaveToolbar: () => void;
}

export interface SaveProvider {
  performSave: (duplicate?: boolean) => void;
  registerSaveResult: (result: SaveResult) => void;
  resetSaveToolbar: () => void;
  parseErrors?: (errors: SaveResult) => void;
}
export interface IdLookupProvider {
  idLookup: () => IdLookup;
}

export interface RulesQuery {
  [key: string]: string;
}

export enum SaveState {
  Idle,
  Saving,
  Success,
  Error
}

export interface SaveResult {
  success: boolean;
  errorMessages?: string[];
  validationErrors?: any;
  responseData?: any;
  data?: any;
  warning?: boolean;
  warningMessage?: string;
  warningExceptions?: any;
}

export interface DismissableNotification {
  text: string;
  dismissed: boolean;
}

export interface ClassObject {
  [key: string]: boolean;
}

export interface CtrErrorContext {
  ctr_error_id: string;
  ctr_error_message: string;
  parsedValue?: string;
}

export const UNKNOWN = 'unknown';

// Define regular expressions used to extract key details from CTR error message
// NOTE: affects Allocation 'Special Considerations' and 'CTR error workflow' offer outcome popup
export const CTR_ERROR_MESSAGE_PARSERS: { ctrMethod: string; ctrErrorId: string; ctrErrorMessageParser: RegExp; }[] = [
  {
    ctrMethod: 'runHSPMatchOnDonor',
    ctrErrorId: 'hsp.missingTest',
    ctrErrorMessageParser: /^Donor (.*)$/,
  },
  {
    ctrMethod: 'runHSPMatchOnDonor',
    ctrErrorId: 'hsp.invalidSerologyResult',
    ctrErrorMessageParser: /^(.*) has no value of Positive, Negative or Indeterminate$/,
  },
  {
    ctrMethod: 'runHSPMatchOnDonor',
    ctrErrorId: 'hsp.pendingFound',
    ctrErrorMessageParser: /(.*) cannot be "Pending"$/,
  },
  {
    ctrMethod: 'runHSPMatchOnDonor',
    ctrErrorId: "data.error.invalidNationalID",
    ctrErrorMessageParser: /^National patient ID (.*) is not valid$/,
  },
  {
    ctrMethod: 'getHSPMatchforDonor',
    ctrErrorId: "data.error.invalidNationalID",
    ctrErrorMessageParser: /^National patient ID (.*) is not valid$/,
  },
  {
    ctrMethod: 'runHSPMatchOnDonor',
    ctrErrorId: "data.error.patientnotexist",
    ctrErrorMessageParser: /^Patient with national ID (.*) does not exist in the registry$/,
  },
  {
    ctrMethod: 'getHSPMatchforDonor',
    ctrErrorId: "patient.error.patientType",
    ctrErrorMessageParser: /^Patient with ID \[(.*)\] is not a DeceasedDonor.$/,
  },
  {
    ctrMethod: 'getHSPMatchforDonor',
    ctrErrorId: "data.error.patientnotexist",
    ctrErrorMessageParser: /^Patient with national ID (.*) does not exist in the registry$/,
  },
  {
    ctrMethod: 'runHsHeartMatch',
    ctrErrorId: 'hsp.missingTest',
    ctrErrorMessageParser: /^Donor (.*)$/,
  },
  {
    ctrMethod: 'runHsHeartMatch',
    ctrErrorId: 'hsp.invalidSerologyResult',
    ctrErrorMessageParser: /^(.*) has no value of Positive, Negative or Indeterminate$/,
  },
  {
    ctrMethod: 'runHsHeartMatch',
    ctrErrorId: 'hsp.pendingFound',
    ctrErrorMessageParser: /(.*) cannot be "Pending"$/,
  },
  {
    ctrMethod: 'runHsHeartMatch',
    ctrErrorId: "data.error.invalidNationalID",
    ctrErrorMessageParser: /^National patient ID (.*) is not valid$/,
  },
  {
    ctrMethod: 'getHsHeartMatchResults',
    ctrErrorId: "data.error.invalidNationalID",
    ctrErrorMessageParser: /^National patient ID (.*) is not valid$/,
  },
  {
    ctrMethod: 'runHsHeartMatch',
    ctrErrorId: "data.error.patientnotexist",
    ctrErrorMessageParser: /^Patient with national ID (.*) does not exist in the registry$/,
  },
  {
    ctrMethod: 'getHsHeartMatchResults',
    ctrErrorId: "patient.error.patientType",
    ctrErrorMessageParser: /^Patient with ID \[(.*)\] is not a DeceasedDonor.$/,
  },
  {
    ctrMethod: 'getHsHeartMatchResults',
    ctrErrorId: "data.error.patientnotexist",
    ctrErrorMessageParser: /^Patient with national ID (.*) does not exist in the registry$/,
  },
  {
    ctrMethod: 'getHsHeartMatchResults',
    ctrErrorId: "data.error.StringFormat",
    ctrErrorMessageParser: /^Data element \[(.*)\] error \[Patient with nationalDonorId \[(.*)\] does not exist.\]$/,
  },
];

export const organCodeLookup: {[key: string]: string} = {
  '1'  : 'liver',
  '2'  : 'heart',
  '3'  : 'kidney',
  '4'  : 'lung',
  '6'  : 'pancreas-whole',
  '6.5': 'pancreas-islets',
  '7'  : 'small-bowel',
  '7.5': 'vca'
};

export const organTransplantDetails: {[key: string]: string} = {
  '1': 'liver-transplant-details',
  '2': 'heart-transplant-details',
  '3': 'kidney-transplant-details',
  '4': 'lung-transplant-details',
  '6': 'pancreas-whole-transplant-details',
  '6.5': 'pancreas-islets-transplant-details',
  '7': 'small-bowel-transplant-details',
  '7.5': 'vca-transplant-details',
};

export interface ModalContent {
  style?: string;
  body?: string;
}

export interface ApplicationLoadingTracker {
  [key: string]: {
    currentState: 'loading' | 'loaded',
    details?: any;
  }
}

export interface TranslationContext {
  key: string;
  values?: {
    [key: string]: string;
  };
}

export interface ResponseError {
  error: boolean;
  error_message?: string;
  error_code?: string;
}

export enum APIPermittedActions {
  // Get
  New = 'new',
  Edit = 'edit',
  Show = 'show',
  Index = 'index',
  // Post
  Create = 'create',
  // Patch
  Update = 'update',
  // Put
  Archive = 'archive',
  Restore = 'restore',
}


// API can return an array of 'permitted_actions'
// E.g. enable a form if the array has 'update'
export interface APIResponseWithPermittedActions {
  permitted_actions: APIPermittedActions[];
}

// API can return a structured 'rules' object
// E.g. field-level form validations
export interface APIResponseWithRules {
  rules: APIRules,
}

// API rules object contains property names based on contract
// TODO: remove 'may_update' in favour of 'permitted_actions'
export interface APIRules {
  [key: string]: any;
  may_update?: boolean; // deprecated in favour of permitted_actions
}

// API returns response payload nested in 'data'
export interface APIShowResponse<T> {
  data: T;
}

export interface APIActivityErrorData {
  errors: string;
}

export interface APIFieldLevelValidationErrorData {
  [key: string]: string[];
}

export interface APISaveResponse {
  data: APIActivityErrorData|APIFieldLevelValidationErrorData;
}

// ENTITIES

// API 'Show Recipient' activity returns permitted actions, rules, and the 'recipient' document
export interface APIShowRecipientData extends APIResponseWithPermittedActions, APIResponseWithRules {
  recipient: APIRecipient
}

export interface APIShowJourneyData extends APIResponseWithPermittedActions, APIResponseWithRules {
  journey: APIJourney
}

export interface APISaveContactDetailResponse extends APISaveResponse {}

export interface APISaveAddressResponse extends APISaveResponse {}

export interface APISaveBloodTypeResponse extends APISaveResponse {}

export interface APISaveSerologyResponse extends APISaveResponse {}

export interface APISaveDeathResponse extends APISaveResponse {}

export interface APISaveChecklistResponse extends APISaveResponse {}

export interface APISaveChecklistTaskResponse extends APISaveResponse {}

export interface APISaveMeasurementResponse extends APISaveResponse {}

export interface APISaveJourneyResponse extends APISaveResponse {}

export interface APITransplantActivityData {
  transplant_attributes: APITransplantDetailsInterface;
}

export interface APISaveTransplantResponse {
  data: APIActivityErrorData|APIFieldLevelValidationErrorData|APITransplantActivityData;
}

export interface APIPaginatedSearchResult<T> {
  page_number: number;
  count: number;
  page_size: number;
  page_count: number;
  sort_name: string;
  sort_direction: string;
  entries: T[];
}
