<template>
  <div :configId="configId" v-if="modelValue">
    <div class="dropdown menu-big show">
      <a
        id="customizeCols"
        href="#"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        class="btn btn-primary btn-sm dropdown-toggle"
      >
        {{$t('columns')}}
      </a>
      <div aria-labelledby="customizeCols" class="dropdown-menu keep-open dropdown-menu-left">
        <div class="container">
          <!-- Form element prevents the dropdown area from being dismissed when the checkboxes are clicked -->
          <form>
            <div class="row">
              <div class="col-sm-12">
                <h3 class="menu-big-heading">
                  {{$t('available_columns')}}
                  <button
                    tabindex="-1"
                    type="button"
                    class="close"
                    aria-label="Close"
                    @click="dismiss"
                  >
                    <span aria-hidden="true">
                      <font-awesome-icon :icon="['far', 'times-circle']" class="text-red" fixed-width />
                    </span>
                  </button>
                </h3>
              </div>
              <!-- Five list items at a time (i.e. iterate through "quintuples" of checkboxes) -->
              <div
                  v-for="quintuple in quintuples" :key="quintuple.key"
                  class="col-md-4"
                >
                <ul class="nav flex-column">
                  <li v-for="option in quintuple.options" :key="option.code">
                    <column-config-checkbox-input
                      :inputId="`${configId}-${option[fieldKey]}`"
                      :label="option.label"
                      v-model="configState[option[fieldKey]]"
                      @change="onCheckboxChange(option[fieldKey], $event)"
                    />
                  </li>
                </ul>
              </div>
              <div class="sub-divider"></div>
              <div class="col-sm-12" v-if="errorMessage">
                <p>{{errorMessage}}</p>
              </div>
              <div class="sub-divider"></div>
              <div class="col-sm-12">
                <div class="big-menu-actions">
                  <button
                    type="button"
                    class="btn btn-sm btn-success mt-2 mb-3"
                    title="save_columns"
                    @click="saveColumns()"
                  >
                    {{$t('save_columns')}}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { uniqueElements } from '@/utils';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import ColumnConfigCheckboxInput from '@/components/shared/ColumnConfigCheckboxInput.vue';
import { SaveResult, ColumnOption } from '@/types';
import { i18nMessages } from '@/i18n';

interface Quintuple {
  key: string;
  options: any[];
}
interface ColumnConfigState {
  [key: string]: boolean;
}

@Component({
  components: {
    ColumnConfigCheckboxInput
  },
  ...i18nMessages([
    require('./_locales/columnConfig.json'),
  ]),
  emits: [
    'update:modelValue',
  ],
})
export default class ColumnConfig extends Vue {
  @State(state => state.users.user.preferences) private preferences!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getColumnPreferences', { namespace: 'users'}) getColumnPreferences!: (columnKey: string, optionDefaults: string[]) => string[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  // V-model - array containing the codes from options that have been selected
  @Prop() modelValue: string[] = [];

  // Required props
  @Prop({ required: true }) columnKey!: string; // Key to read/write column preferences
  @Prop({ required: true }) optionDefaults!: string[]; // Possible options to choose from
  @Prop({ required: true }) configId!: string; // HTML ID
  @Prop({ required: true }) options!: ColumnOption[]; // Enumerable data for building the options - i.e. all possible checkboxex

  // Optional props
  @Prop({ default: 'label' }) labelKey!: string; // Key for text displayed as checkbox label
  @Prop({ default: 'field' }) fieldKey!: string; // Key for field associated with option
  @Prop({ default: undefined }) hiddenOptions!: string[]; // Options to keep hidden from display

  public errorMessage = null;

  /**
   * Give the ability to hide/show options based on the hiddenFields property.
   * 
   * @returns {ColumnOption[]} options from options property and filtered by hiddeOptions property
   */
  get filteredOptions(): ColumnOption[] {
    if (!this.hiddenOptions) return this.options;
    const filtered = this.options.filter((option: ColumnOption) => {
      if (!this.hiddenOptions.includes(option.field)) return option;
    });
    return filtered;
  }

  private onCheckboxChange(key: any, ticked: boolean, b = false): void {
    const newValue = Array.from(this.modelValue);
    if (ticked) {
      // Add ticked field to overall model value if not already present
      const index = newValue.indexOf(key);
      if (index == -1) {
        newValue.push(key);
      }
    } else {
      // Remove unticked checkbox field from overall model value if present
      const index = newValue.indexOf(key);
      if (index > -1) {
        newValue.splice(index, 1);
      }
    }
    // Sanitize value and report the change
    const result = uniqueElements(newValue);
    this.$emit('update:modelValue', result);
  }

  get configState(): ColumnConfigState {
    if (!this.modelValue) {
      return {};
    }
    const newState: ColumnConfigState = {};
    this.filteredOptions.forEach((option: any) => {
      const key = option[this.fieldKey];
      const selected = this.modelValue.includes(key);
      newState[key] = selected;
    });
    return newState;
  }

  get quintuples(): Quintuple[] {
    if (!this.filteredOptions) {
      return [];
    }
    // Store options in groups of five i.e. "quintuples"
    const quintuples: { options: any[] }[] = [];
    let options: any[] = [];
    const itemsByGroup = Math.floor(this.filteredOptions.length / 3);
    this.filteredOptions.forEach((option: any) => {
      // Cache one item
      options.push(option);
      // When five have been cached, store them as a quintuple
      if (options.length > itemsByGroup) {
        quintuples.push({ options });
        // Reset cache for next quintuple
        options = [];
      }
    });
    // If any options remain in the cache, store them as a partial quintuple
    if (options.length > 0) {
      quintuples.push({ options });
    }
    // Generate a key for each quintuple based on its options' field keys
    let key: string;
    let codes: string[];
    const result = quintuples.map((quintuple: { options: any[] }): Quintuple => {
      codes = quintuple.options.map((option: any): string => {
        const field = option[this.fieldKey];
        return field;
      });
      key = codes.join('-');
      return {
        key: `${key}`,
        options: quintuple.options,
      };
    });
    return result;
  }

  extractColumnSettings() : any {
    const newPreferences = this.getPreferences; // get preferences
    newPreferences[this.columnKey] = this.modelValue; // apply value to this section of preferences
    return newPreferences; // return altered preferences
  }

  saveColumns(): void {
    const newState = this.extractColumnSettings();

    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.errorMessage = null;
      this.dismiss();
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#customizeCols").dropdown('toggle');
  }
}

</script>
