<template>
  <modal-section
    modalId="discontinue-modal"
    ref="discontinueModal"
    class="modal-sticky-header"
    @hide="(options: any) => modalEvent(options)"
    :centered="true"
    :closeButton="false"
  >
    <template v-slot:title>
      {{ $t('discontinue_offer_to_selected_recipients') }}
    </template>
    <template v-slot:body v-if="!editState">
      {{ $t('loading') }}
    </template>
    <template v-slot:body v-else>
      <p v-if="!errorState">
        {{ $t('select_discontinue_details_recipient') }}<br />
      </p>
      <template v-if="!!modalErrorMessage">
        <div class="alert alert-danger mt-2">
          {{modalErrorMessage}}
        </div>
      </template>
      <div class="hr-break" v-if="!errorState" />
      <validation-observer ref="discontinueValidations" autocomplete="off" tag="form">
        <div class="row" v-if="!errorState">
          <div class="form-group col-sm-4">
            <select-input
              selectId="discontinue-type"
              :name="$t('response_type')"
              rules="required"
              v-model="editState.type"
              :options="responseOptions"
              @change="clearStateValues(['reason', 'reasonCategory'])"
            />
          </div>
          <div class="form-group col-sm-4">
            <select-input
              select-id="discontinue-reason-category"
              :name="$t('reason_category')"
              rules="required"
              v-model="editState.reasonCategory"
              @change="clearStateValues(['reason'])"
              :options="reasonCategoryOptions"
            />
          </div>
          <div class="form-group col-sm-4" >
            <select-input
              select-id="discontinue-reason"
              :name="$t('reason')"
              rules="required"
              v-model="editState.reason"
              :options="reasonOptions"
            />
          </div>
        </div>
      </validation-observer>
    </template>
    <template v-slot:footer>
      <div 
        class="modal-footer-body " 
        :class="{ 'justify-content-end': errorState }"
      >
        <button
          type="button"
          data-dismiss="modal"
          class="btn btn-secondary"
          @click="closeModal()"
          :disabled="isLoadingAllocation"
          v-if="!errorState"
        >
          {{ $t('cancel') }}
        </button>
        <button
          class="btn btn-success"
          @click="discontinueOffer()"
          :disabled="isLoadingAllocation || isDiscontinuingOneOffer"
          v-if="!errorState"
        >
          {{ $t('discontinue_offer') }}
          <span class="pl-2" v-if="isDiscontinuingOneOffer">
            <font-awesome-icon class="fa-spin" :icon="['far', 'spinner-third']" />
          </span>
        </button>
        <button
          type="button"
          data-dismiss="modal"
          class="btn btn-success"
          @click="closeModal()"
          v-else
        >
          {{ $t('ok') }}
        </button>
      </div>
    </template>
  </modal-section>
</template>

<i18n src="@/components/allocations/_locales/common.json"></i18n>
<i18n src="@/components/allocations/_locales/_DiscontinueModal.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { GenericCodeValue } from '@/store/types';
import { LivingDonor } from '@/store/livingDonors/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { Organ, OrganSpecification } from '@/store/lookups/types';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { Allocation, AllocationRecipient, AllocationOfferAction, AllocationOfferResponseCodeValues, AllocationOfferRecipient } from '@/store/allocations/types';

interface AllocationDiscontinuePageState {
  recipients: AllocationOfferRecipient[];
  type: string;
  reasonCategory?: number;
  reason?: number;
}

@Component({
  components: {
    SelectInput,
    ModalSection,
    TextAreaInput,
  }
})
export default class DiscontinueModal extends Vue {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.currentUser) private currentUser!: any;
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.pageState.currentPage.livingAllocations.discontinue) private editState!: AllocationDiscontinuePageState;
  @State(state => state.livingAllocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.livingAllocations.isDiscontinuingOneOffer) private isDiscontinuingOneOffer!: boolean;

  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('offerResponses', { namespace: 'lookups' }) private offerResponses!: GenericCodeValue[];
  @Getter('selectedAllocation', { namespace: 'livingAllocations' }) private allocation!: Allocation;
  @Getter('recipientOffer', { namespace: 'livingAllocations' }) private recipientOffer!: AllocationRecipient;
  @Getter('openBackupOffers', { namespace: 'livingAllocations' }) private openBackupOffers!: AllocationRecipient[];
  @Getter('openPrimaryOffers', { namespace: 'livingAllocations' }) private openPrimaryOffers!: AllocationRecipient[];
  @Getter('closedBackupOffers', { namespace: 'livingAllocations' }) private closedBackupOffers!: AllocationRecipient[];
  @Getter('closedPrimaryOffers', { namespace: 'livingAllocations' }) private closedPrimaryOffers!: AllocationRecipient[];
  @Getter('getRecipientsByEffectiveRank', { namespace: 'livingAllocations' }) getRecipientsByEffectiveRank!: (recipientRanks?: number[]) => AllocationOfferRecipient[];

  public errorState = false;
  public modalErrorMessage = '';
  public DISCONTINUE_CONFIRM = 'You are about to discontinue selected offer(s).  Are you sure?';

  /**
   * Get a string representation the organ_code
   *
   * @returns {string} organ_code param as a string
   */
  get organCode(): string {
    return this.$route.params.organ_code ? this.$route.params.organ_code.toString() : '';
  }

  /**
   * Returns the number of open offers for the selected recipients
   *
   * Check all recipient offers for any offer_type_code values
   *
   * @returns {number} number of open offers
   */
  get openOffers(): number {
    if (!this.editState?.recipients) {
      return 0;
    }
    // are there any open primary offers
    const primaryOffers = this.openPrimaryOffers.filter((e: AllocationRecipient) => {
      return this.editState.recipients.find((recipient: AllocationOfferRecipient) => {
        return recipient.effective_rank === e.effective_rank;
      });
    });
    // are there any open backup offers
    const backupOffers = this.openBackupOffers.filter((e: AllocationRecipient) => {
      return this.editState.recipients.find((recipient: AllocationOfferRecipient) => {
        return recipient.effective_rank === e.effective_rank;
      });
    });
    return primaryOffers.length + backupOffers.length;
  }

  /**
   * Returns the number of closed offers for the selected recipients
   *
   * Check all recipient offers for any offer_type_code values
   *
   * @returns {number} number of closed offers
   */
  get closedOffers(): number {
    if (!this.editState?.recipients) {
      return 0;
    }
    // are there any closed primary offers
    const primaryOffers = this.closedPrimaryOffers.filter((e: AllocationRecipient) => {
      return this.editState.recipients.find((recipient: AllocationOfferRecipient) => {
        return recipient.effective_rank === e.effective_rank;
      });
    });
    // are there any closed backup offers
    const backupOffers = this.closedBackupOffers.filter((e: AllocationRecipient) => {
      return this.editState.recipients.find((recipient: AllocationOfferRecipient) => {
        return recipient.effective_rank === e.effective_rank;
      });
    });
    return primaryOffers.length + backupOffers.length;
  }

  /**
   * Compare the number of recipients with the number of open offers
   *
   * A withdraw can only be made on open offers.
   *
   * @returns {boolean} true if we're in a withdraw state
   */
  get isWithdraw(): boolean {
    if (!this.editState?.recipients) {
      return false;
    }
    return this.openOffers === this.editState.recipients.length;
  }

  /**
   * Compare the number of recipients with the number of closed offers
   *
   * A Cancel can only be made on closed offers.
   *
   * @returns {boolean} true if we're in a cancel state
   */
  get isCancel(): boolean {
    if (!this.editState?.recipients) {
      return false;
    }
    return this.closedOffers === this.editState.recipients.length;
  }

  /**
   * Return filtered offer_responses: Withdraw and Cancel
   *
   * @returns {GenericCodeValue[]} Response options
   */
  get responseOptions(): GenericCodeValue[] {
    if (this.offerResponses && this.offerResponses.length <= 0) {
      return [];
    }
    const filteredOfferResponses = this.offerResponses.filter((item: any) => {
      // Only return cancel options if we're in cancel state
      if (this.isCancel) {
        return item.code == AllocationOfferResponseCodeValues.Cancel;
      }
      // Only return withdraw options if we're in withdraw state
      if (this.isWithdraw) {
        return item.code == AllocationOfferResponseCodeValues.Withdraw;
      }
    });
    return filteredOfferResponses;
  }

  /**
   * Return Reason Category options based on selected type
   *
   * Conditionals for Cancel require a further sub set of options
   * based on the organ_code.
   *
   * @returns {GenericCodeValue[]} Reason Category options
   */
  get reasonCategoryOptions(): any {
    // Withdraw
    if (this.editState.type == AllocationOfferResponseCodeValues.Withdraw) {
      const reasonCategoryOptions = this.offerResponses.find((item: any) => {
        return item.code == AllocationOfferResponseCodeValues.Withdraw;
      });
      return reasonCategoryOptions?.sub_tables?.offer_reason_categories;
    }
    // Cancel provides organ specific options
    if (this.editState.type == AllocationOfferResponseCodeValues.Cancel) {
      // Get response type lookup value
      const offerResponseType = this.offerResponses.find((item: any) => {
        return item.code == AllocationOfferResponseCodeValues.Cancel;
      });
      // Get organ specific sub table
      const organReasonCategories = offerResponseType?.sub_tables?.organ_specific_categories_reasons;
      if (!organReasonCategories || organReasonCategories.length <= 0) {
        return [];
      }
      // Get organ specific reason categories
      const organReasonCategoryOptions = offerResponseType?.sub_tables?.organ_specific_categories_reasons.find((item: any) => {
        return item.code == this.organCode;
      });
      return organReasonCategoryOptions.sub_tables.offer_reason_categories;
    }
    return [];
  }

  /**
   * Return Reason options for the selected category
   *
   * @returns {GenericCodeValue[]} Reason options
   */
  get reasonOptions(): any {
    if (!this.editState?.reasonCategory) {
      return [];
    }
    const reasonOptions = this.reasonCategoryOptions.find((item: any) => {
      return item.code == this.editState.reasonCategory;
    });
    return reasonOptions.sub_tables.offer_reasons;
  }

  /**
   * Populates state with recipient ids and opens the offer modal
   */
  public initializeDiscontinueModal(recipientRanks?: number[]): void {
    // clear any error message
    this.modalErrorMessage = '';
    this.errorState = false;
    // Verify the Recipients exist in the Allocation and return AllocationOfferRecipient[] 
    const recipients = this.getRecipientsByEffectiveRank(recipientRanks || []);
    // build state from valid ids
    this.$store.commit('pageState/set', {
      pageKey: 'livingAllocations',
      componentKey: 'discontinue',
      value: this.buildDiscontinueState(recipients)
    });
    // reset the form
    (this.$refs.discontinueValidations as any).setErrors({});
    // are we in an error state
    if (!this.isWithdraw && !this.isCancel) {
      this.errorState = true;
      this.modalErrorMessage = 'There was an error with the recipients you selected, please close and try again';
    }
    // do we have recipients
    if (this.editState.recipients.length > 0) {
      this.openModal();
    }
  }

  /**
   * Gets a patch object representing the editState
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing offer details
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.recipients) {
      return {};
    }
    return this.extractDiscontinuePatch(this.editState);
  }

  // PRIVATE

  /**
   * Return the Allocation Discontinue state from recipientIds
   *
   * @param recipientIds array of recpient _ids
   */
  private buildDiscontinueState(recipients?: AllocationOfferRecipient[]): any {
    return {
      recipients: recipients || [],
      type: undefined,
      reasonCategory: undefined,
      reason: undefined,
    };
  }

  // Listen for modal hide event so we can clear the modal
  private modalEvent(options: any) {
    this.$emit("closeModal");
    this.initializeDiscontinueModal();
  }

  // Toggle modal
  private openModal(): void {
    (this.$refs.discontinueModal as ModalSection).toggleStaticModal();
  }

  // Close modal
  private closeModal(): void {
    const targetModal = this.$refs.discontinueModal as ModalSection;
    targetModal.hideModal();
  }

  // Clear state values from an array keys
  private clearStateValues(keys: string[]): void {
    // TODO: TECH_DEBT: remove 'any' in favour of strict typing
    keys.forEach(i => (this.editState as any)[i] = undefined);
  }

  /**
   * Gets form edit state as an Allocation Discontinue patch
   *
   * Prepare patch for API with Allocation Discontinue details
   *
   * @param discontinue form edit state containing discontinue details
   * @returns {AllocationOfferAction} patch object containing discontinue details
   */
  private extractDiscontinuePatch(discontinue: AllocationDiscontinuePageState): AllocationOfferAction {
    return {
      recipients: discontinue.recipients,
      type: discontinue.type,
      reason_category: discontinue.reasonCategory,
      reason_code: discontinue.reason,
    };
  }

  /**
   * Attempt to make an offer they can't refuse
   *
   * Prepares create offer payload for selected Allocation, dispatches create, and handle errors.
   */
  private discontinueOffer(): void {
    // Before discontinue, provide an alert/confirm dialogue
    const confirmed = confirm(this.DISCONTINUE_CONFIRM);
    if (!confirmed) {
      return;
    }
    // Generate payload from editState
    const payload = {
      clientId: this.clientId,
      organCode: this.organCode,
      allocationId: this.allocation._id,
      discontinueDetails: this.extractPatch()
    };
    // clear any error message
    this.modalErrorMessage = '';
    // Dispatch save action and show response
    this.$store.dispatch('livingAllocations/discontinueOffer', payload).then((success: any) => {
      this.initializeDiscontinueModal();
      this.closeModal();
    }).catch((error: any) => {
      this.modalErrorMessage = error;
    });
  }
}
</script>
