import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { "aria-hidden": "true" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.truncated)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.sanitizedText) + _toDisplayString(_ctx.clamp), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.sanitizedText), 1)),
    (_ctx.truncatable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "btn btn-sm btn-white",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
        }, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true)
  ]))
}