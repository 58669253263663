export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "living_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donors"])},
        "add_new_living_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Living Donor"])},
        "living_donor_registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donor Registration Date"])},
        "living_donor_to_donate_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donor To Donate To"])},
        "preselected_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-selected Recipient"])},
        "to_be_allocated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Be Allocated"])},
        "link_to_recipient_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Link Date"])},
        "link_to_recipient_relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship To Recipient"])},
        "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])},
        "rh_factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RH Factor"])},
        "insurance_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Type"])},
        "insurance_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Plan"])},
        "insurance_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Number"])},
        "ethnicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethnicity"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System ID"])},
        "hospital_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital"])},
        "national_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National ID"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
        "cause_of_death": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause of Death"])},
        "donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Type"])},
        "referral_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Type"])},
        "organ_consents_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referred Organs"])},
        "ecd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECD"])},
        "referral_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Date"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
        "mrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRN"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
      },
      "fr": {
        "living_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donors"])},
        "add_new_living_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Living Donor"])},
        "living_donor_registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donor Registration Date"])},
        "living_donor_to_donate_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donor To Donate To"])},
        "preselected_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-selected Recipient"])},
        "to_be_allocated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Be Allocated"])},
        "link_to_recipient_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Link Date"])},
        "link_to_recipient_relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship To Recipient"])},
        "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo"])},
        "rh_factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RH Factor"])},
        "insurance_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Type"])},
        "insurance_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Plan"])},
        "insurance_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Number"])},
        "ethnicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethnicity"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID système"])},
        "hospital_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôpital"])},
        "national_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité nationale du donneur"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "cause_of_death": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause du décès"])},
        "donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de donneur"])},
        "referral_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de référence"])},
        "organ_consents_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referred Organs"])},
        "ecd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECD"])},
        "referral_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de référence"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe sanguin"])},
        "mrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRN"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])}
      },
      "en_tgln": {
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID"])},
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OATS ID"])}
      },
      "fr_tgln": {
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])},
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du OATS"])}
      },
      "en_tq": {
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])}
      },
      "fr_tq": {
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])}
      }
    }
  })
}
