import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-check form-check-inline" }
const _hoisted_2 = ["id", "name", "value", "aria-labelledby", "disabled"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id", "name", "value", "aria-labelledby", "disabled"]
const _hoisted_5 = ["for"]
const _hoisted_6 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_asterisk = _resolveComponent("validation-asterisk")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!

  return (_openBlock(), _createBlock(_component_validation_provider, {
    ref: "provider",
    rules: _ctx.formRules,
    name: _ctx.inputId,
    label: _ctx.labelName,
    vid: _ctx.validationId ? _ctx.validationId : _ctx.inputId
  }, {
    default: _withCtx(({ errors }) => [
      (_ctx.labelName)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(["p-label", { 'sr-only': _ctx.hideLabel }])
          }, [
            _createTextVNode(_toDisplayString(_ctx.labelName) + " ", 1),
            (!_ctx.disabled)
              ? (_openBlock(), _createBlock(_component_validation_asterisk, {
                  key: 0,
                  rules: _ctx.formRules,
                  crossValues: _ctx.crossValues,
                  ruleKey: _ctx.ruleKey
                }, null, 8, ["rules", "crossValues", "ruleKey"]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", _mergeProps({
          id: `${_ctx.inputId}-${_ctx.acceptId}`,
          name: `${_ctx.inputId}-${_ctx.acceptId}`,
          type: "radio",
          class: "form-check-input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
          value: _ctx.BOOLEAN_RADIO_STATE.True,
          "aria-labelledby": `${_ctx.inputId}-${_ctx.acceptId}`,
          disabled: _ctx.isReadOnly(_ctx.disabled)
        }, _toHandlers(_ctx.inputEvents(), true)), null, 16, _hoisted_2), [
          [_vModelRadio, _ctx.localValue]
        ]),
        _createElementVNode("label", {
          class: "form-check-label",
          for: `${_ctx.inputId}-${_ctx.acceptId}`
        }, _toDisplayString(_ctx.acceptLabel || _ctx.acceptId), 9, _hoisted_3)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["form-check form-check-inline", { 'is-invalid': errors[0] }])
      }, [
        _withDirectives(_createElementVNode("input", _mergeProps({
          id: `${_ctx.inputId}-${_ctx.declineId}`,
          name: `${_ctx.inputId}-${_ctx.declineId}`,
          type: "radio",
          class: "form-check-input",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue) = $event)),
          value: _ctx.BOOLEAN_RADIO_STATE.False,
          "aria-labelledby": `${_ctx.inputId}-${_ctx.declineId}`,
          disabled: _ctx.isReadOnly(_ctx.disabled)
        }, _toHandlers(_ctx.inputEvents(), true)), null, 16, _hoisted_4), [
          [_vModelRadio, _ctx.localValue]
        ]),
        _createElementVNode("label", {
          class: "form-check-label",
          for: `${_ctx.inputId}-${_ctx.declineId}`
        }, _toDisplayString(_ctx.declineLabel || _ctx.declineId), 9, _hoisted_5)
      ], 2),
      (errors[0] && !_ctx.disabled)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "invalid-feedback",
            id: `${_ctx.inputId}-error`
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['far', 'exclamation-circle'],
              "fixed-width": ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.labelName)), 1)
          ], 8, _hoisted_6))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["rules", "name", "label", "vid"]))
}