export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation ID"])},
        "serology_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serology Results"])},
        "discontinue_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue Donor"])},
        "discontinue_all_allocations_for_this_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue all allocations for this donor"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
        "ctr_integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Integration"])},
        "insurance_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Plan"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])}
      },
      "fr": {
        "allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l’attribution"])},
        "serology_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats sérologiques"])},
        "discontinue_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinuer le donneur"])},
        "discontinue_all_allocations_for_this_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompre toutes les allocations pour ce donateur"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue"])},
        "ctr_integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration CTR"])},
        "insurance_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan d'assurance"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de transplantation"])}
      }
    }
  })
}
