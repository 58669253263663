import Vue from 'vue';
import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { HospitalsState, Hospital } from '@/store/hospitals/types';
import { isMasked } from '@/utils';

export const actions: ActionTree<HospitalsState, RootState> = {
  load({ state, commit, rootGetters }, hospitalType): Promise<void> {
    const type = hospitalType ? hospitalType : 'all';
    return new Promise<void>((resolve, reject) => {
      if(state.loadingTracker[type] &&
        (state.loadingTracker[type].currentState === 'loading'
          || state.loadingTracker[type].currentState === 'loaded')) {
        resolve();
        return;
      }

      // Begin fetching index of hospitals with parameters limiting the response to the specified hospital type
      
      // FIXME remove when api side switched away from ontario declaration
      const url_type = type == 'region_transplant' ? 'ontario_transplant' : type;

      const url = APIRoute(EP.hospitals.index, [[':hospital_type', url_type || '']]);

      commit('startLoadingTracking', type);
      axios.get(url).then((response: any) => {
        // Set the specified hospitals state to the list of hospitals in the response data
        const hospitals: Hospital[] = response.data.hospitals || [];
        (state as any)[type] = hospitals;
        commit('finishLoadingTracking', type);
        resolve();
      }).catch((error: any) => {
        commit('resetLoadingTracking', type);
        console.warn(error);
        reject();
      });
    });
  },

  get({ commit }, hospitalId): Promise<void> {
    const hospitals: Hospital[] = [];
    return new Promise<void>((resolve, reject) => {
      hospitalId.forEach((id: string) => {
        if (isMasked(id)) { resolve(); } // resolve without error
        else {
          const url = APIRoute(EP.hospitals.show, [[":id", id]]);
          axios.get(url).then((response: any) => {
            const hospital: Hospital = response.data.hospital;
            hospitals.push(hospital);
            commit("setTransplantProgram", hospitals);
            resolve();
          }).catch((error: any) => {
            reject();
          });  
        }
      });
    });
  },
};
