<template>
  <div class="nav-block-wrap">
    <ul class="nav flex-column nav-block"
       :class="{ active: organId === $route.params.organ_id }">
      <li class="nav-item">
          <router-link class="nav-link no-scroll"
                     :to="{ name: 'living-donor-organ', params: { organ_code: organCode } }">
          <font-awesome-icon class="nav-caret"
                             :icon="['fas', 'chevron-right']"
                             fixed-width />
          {{ $t(`${organ}`) }}
        </router-link>
        <template v-if="show">
          <ul class="nav flex-column">
            <li class="nav-item" v-if="!isTransplantCoordinator" @click="toggleSideNav">
              <a class="nav-link" :href="getUrl('donor-checklist')">{{ $t(`checklists`) }}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav">
              <a class="nav-link text-flat" :href="getUrl(isTransplantCoordinator ? 'allocation-offer-responses' : 'allocation-recommendation-listing')">{{ $t(`allocation_eoffer`) }}</a>
            </li>
          </ul>
        </template>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { i18nMessages } from '@/i18n';

@Component({
  ...i18nMessages([
    require('@/components/_locales/Organs.json'),
    require('@/components/livingAllocations/side-nav/_locales/SelectedAllocationLinks.json'),
  ]),
})
export default class SelectedLivingDonorOrganLinks extends Vue {
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;

  @Prop() organId!: string;
  @Prop() organ!: string;
  @Prop() organCode!: string;
  @Prop() organRoute!: any;

  show = false;

  public selectedJourney = '';

  // Hide organ specific deatils from side nav
  public ORGAN_CODES_TO_EXCLUDE = ['7'];

  public toggleNav() {
    this.show = !this.show;
  }

  public mounted() {
    this.show = this.isActive;
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange() {
    this.show = this.isActive;
  }

  get isActive(): boolean {
    return this.organCode == this.$route.params.organ_code
      && this.organRoute.params.option == this.$route.params.option;
  }

  get showOrganSpecificDetails(): boolean {
   return true;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) {
      this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.isActive) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'living-donor-organ', params: { organ_code: this.organCode }, hash: hash });
      return location.href;
    }
  }
}
</script>
