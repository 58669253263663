<template>
  <fieldset>
    <template v-if="editState">
      <div class="row">
        <div class="standard-form-group">
          <text-input
            ruleKey="patient_contact_detail.phone_number"
            inputId="contact-detail-phone-number"
            :name="$t('contact_detail_field.phone_number')"
            v-model="editState.phoneNumber"
            :mask="getMaskFromRules('patient_contact_detail.phone_number')"
          />
        </div>
        <div class="standard-form-group">
          <checkbox-input
            inputId="contact-detail-preferred"
            :labelName="$t('contact_detail_field.preferred')"
            v-model="editState.preferred"
            :label="$t('yes')"
          />
        </div>
      </div>
      <div class="row">
        <div class="standard-form-group">
          <checkbox-group
            ruleKey="patient_contact_detail.permitted_actions"
            inputId="contact-detail-okay-to"
            :name="$t('contact_detail_field.okay_to')"
            v-model="editState.okayTo"
            :options="okayToOptions"
          />
        </div>
        <div class="standard-full-width-group">
          <text-area-input
            ruleKey="patient_contact_detail.comments"
            inputId="contact-detail-comments"
            :name="$t('contact_detail_field.comments')"
            v-model="editState.comments"
          />
        </div>
      </div>
    </template>
  </fieldset>
</template>

<script lang="ts">
import { Getter, State } from "vuex-facing-decorator";
import { IdLookup } from '@/store/validations/types';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import { UIContactDetail, CONTACT_DETAIL_OKAY_TO } from "@/UIModels/contactDetail";
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import CheckboxGroup from '@/components/shared/CheckboxGroup.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { IdLookupProvider } from '@/types';
import { GenericCodeValue } from "@/store/types";
import { ContactDetailCategoryLookup } from '@/store/lookups/types';
import { i18nMessages } from "@/i18n";

@Component({
  components: {
    SubSection,
    SelectInput,
    TextInput,
    CheckboxInput,
    CheckboxGroup,
    TextAreaInput,
  },
  ...i18nMessages([
    require('@/components/_locales/common.json'),
    require('@/components/recipients/contactInformation/_locales/PatientContactDetails.json'),
  ]),
})
export default class PatientPhoneFormat extends Vue implements IdLookupProvider {
  @State(state => state.lookups.contact_detail_categories) contactDetailCategories!: ContactDetailCategoryLookup[];

  @Getter('getMaskFromRules', { namespace: 'validations' }) private getMaskFromRules!: (ruleKey: string) => string|null;

  @Prop({ required: true }) editState!: UIContactDetail;

  // Options for "Okay to" wil be disabled if not include in the selected category lookup "actions" array
  get okayToOptions(): GenericCodeValue[] {
    return [
      CONTACT_DETAIL_OKAY_TO.Call,
      CONTACT_DETAIL_OKAY_TO.Voicemail,
      CONTACT_DETAIL_OKAY_TO.Text,
    ].map((okayToOption: string): GenericCodeValue => {
      return {
        code: okayToOption,
        value: this.$t(`contact_detail_okay_to.${okayToOption}`).toString(),
        disabled: !this.selectedCategoryOkayToOptions.includes(okayToOption),
      };
    });
  }

  // Which "Okay to" options are associated with the currently selected "Category"?
  get selectedCategoryOkayToOptions(): string[] {
   if (!this.editState) return [];

    return this.contactDetailCategoryActions(this.editState.categoryType);
  }

  // Process change to enabled/disabled "Okay to" options
  @Watch('selectedCategoryOkayToOptions')
  private handleOkayToOptionsChange(newActions: string[], oldActions?: string[]): void {
    const currentSelections = this.editState.okayTo || [];
    const filteredSelections = currentSelections.filter((selection: string) => {
      return newActions.includes(selection);
    });
    this.editState.okayTo = filteredSelections;
  }

  // Extract 'actions' string array from specified category lookup specified by the lookup entry code
  private contactDetailCategoryActions(categoryType: string|null): string[] {
    if (!categoryType || !this.contactDetailCategories) return [];

    const categoryLookup = this.contactDetailCategories.find((categoryLookup: ContactDetailCategoryLookup) => {
      return categoryLookup.code === categoryType;
    });
    if (!categoryLookup) return [];

    return categoryLookup.actions || [];
  }

  // Validation mapping
  public idLookup(): IdLookup {
    return {
      'patient_contact_detail.phone_number':      'contact-detail-phone-number',
      'patient_contact_detail.preferred':         'contact-detail-preferred',
      'patient_contact_detail.permitted_actions': 'contact-detail-okay-to',
      'patient_contact_detail.comments':          'contact-detail-comments',
    };
  }
}
</script>
