import { UIFeatureConfig } from '@/UIModels/configuration/feature';
import { APIConfiguration } from '@/store/configuration/types';
import { FormatDefinition, Format } from '@/store/utilities/types';
import i18n from '@/i18n';

export class UIConfiguration {
  public systemTimezone: string;
  public systemTimezoneAbbreviation: string;
  public dateFormatCode = 'iso';

  public features: UIFeatureConfig;

  public constructor(apiConfiguration: APIConfiguration) {
    if (!apiConfiguration) throw new Error('Cannot initialize configuration without API source');

    if (!apiConfiguration.app) throw new Error('Cannot initialize configuration without App configuration');
    this.systemTimezone = apiConfiguration.app.time_zone;
    this.systemTimezoneAbbreviation = apiConfiguration.app.time_zone_abbreviation;
    this.dateFormatCode = apiConfiguration.app.localization.date_format;

    if (!apiConfiguration.features) throw new Error('Cannot initialize configuration without Features configuration');
    this.features = new UIFeatureConfig(apiConfiguration.features);
  }

  get dateFormatDefinition(): FormatDefinition {
    return Format(this.dateFormatCode);
  }

  get getTimezoneAbbreviation(): string {
    return i18n.tc(this.systemTimezoneAbbreviation);
  }

  public replaceTimezoneAbbreviation(key: string) {
    const timezoneAbbr = this.getTimezoneAbbreviation || '';
    return key.replaceAll('__timezone__', timezoneAbbr);
  }
}
