import { isMasked } from '@/utils';
import { format, parse, isValid } from 'date-fns';
import { useCurrentPageStore } from '@/stores/currentPage';
import { FormatDefinition, Format } from '@/store/utilities/types';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';

// Internal system format for editable v-models (e.g. DateInput, TimeInput) 
export const UI_MODEL_DATE = 'yyyy-MM-dd';
export const UI_MODEL_TIME = 'HH:mm';

// Internal system format for saving valid dates or datetimes to API
export const API_SAVE_VALID_DATE = 'yyyy-MM-dd';
export const API_SAVE_VALID_DATETIME = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx';

// Internal system format to ensure invalid or partial datetime is rejected by API
export const API_SAVE_INVALID_DATETIME = 'Invalid';

// Get system timezone from configuration in pinia store
function systemTimeZone(): string {
  const currentPageStore = useCurrentPageStore();
  if (!currentPageStore.configuration) throw Error('Cannot get System Timezone without Configuration');

  return currentPageStore.configuration.systemTimezone;
}

// Get date/time format from configuration in pinia store
function dateFormatDefinition(): FormatDefinition {
  const currentPageStore = useCurrentPageStore();
  if (!currentPageStore.configuration) return Format(null);

  return currentPageStore.configuration.dateFormatDefinition;
}

/**
 * Convert API date into format useable in extract methods for UI date input
 * @param apiDate string representation of date-only value from API
 * @returns {string|undefined} string representation of UI date-only format if valid date, undefined if invalid
 */
export function parseDateUi(apiDate?: string|null): string|undefined {
  if (!apiDate) { return undefined; } // if no value, return undefined
  if (isMasked(apiDate)) { return apiDate; } // if masked return masked value to trigger masked layout in date-input

  const parsed = parse(apiDate, UI_MODEL_DATE, new Date());
  if (!isValid(parsed)) return undefined;

  return format(parsed, UI_MODEL_DATE);
}

/**
 * Convert API date into format useable in extract methods for UI date input
 * @param apiDate string representation of date-only value from API
 * @returns {string|undefined} string representation of UI date-only format if valid date, undefined if invalid
 */
export function parseDateUiFromDateTime(apiDateTime?: string|null): string|undefined {
  if (!apiDateTime) { return undefined; } // if no value, return undefined
  if (isMasked(apiDateTime)) { return apiDateTime; } // if masked return masked value to trigger masked layout in date-input

  return formatInTimeZone(apiDateTime, systemTimeZone(), UI_MODEL_DATE);

}

/**
 * Convert API date into format useable in extract methods for UI date input
 * @param apiDateTime string representation of date-only value from API
 * @returns {string|undefined} string representation of UI date-only format if valid date, undefined if invalid
 */
export function parseTimeUiFromDateTime(apiDateTime?: string|null): string|undefined {
  if (!apiDateTime || apiDateTime == '-') { return undefined; } // if no value, return undefined
  if (isMasked(apiDateTime)) { return apiDateTime; } // if masked return masked value to trigger masked layout in date-input

  return formatInTimeZone(apiDateTime, systemTimeZone(), UI_MODEL_TIME);
}

/**
 * Convert date and time into a valid ISO 8601 datetime for API
 *
 * Will return 'Invalid' string if combined datetime can't be parsed so API can generate an error
 *
 * @param date string containing a date
 * @param time string containing a time
 * @param timeZone string representation of time-zone value from API
 * @returns {string|null} ISO 8601 datetime if valid date/time, 'Invalid' if invalid date/time, null if both date/time missing
 */
export function sanitizeDateTimeApi(date?: string|null, time?: string|null): string|null {
  // If both values are blank, then return null.
  // API will permit a null date only if it is an optional property
  if (!date && !time) return null;

  // If only date or only time are provided, then return invalid 'Invalid' string to generate API error
  // API must reject this value, because a date/time field is valid only if both date and time are provided.
  if (!date || !time) return 'Invalid';

  // build date/time string
  const datetimeString = `${date}T${time}`;

  // Verify date & time
  if (isNaN(Date.parse(datetimeString))) return 'Invalid';

  const newDateTime = new Date(datetimeString);
  const zonedDateTime = fromZonedTime(newDateTime, systemTimeZone());
  return format(zonedDateTime, API_SAVE_VALID_DATETIME);
}

/**
 * Convert API date into readable date string for plain-text display in UI template
 * @param apiDate string representation of date-only value from API
 * @returns {string|undefined} string representation of UI date-only format if valid date, undefined if invalid
 */
export function parseDisplayDateUi(apiDate?: string|null): string|undefined {
  if (!apiDate) { return undefined; } // if no value, return undefined
  if (isMasked(apiDate)) { return undefined; } // if masked return masked value to trigger masked layout in date-input

  const parsed = parse(apiDate, UI_MODEL_DATE, new Date());
  if (!isValid(parsed)) return undefined;

  return format(parsed, dateFormatDefinition().DISPLAY_DATE);
}

/**
 * Convert API dat/timee into readable date-only string for plain-text display in UI template
 * @param apiDateTime string representation of combined date/time value from API
 * @returns {string|undefined} string representation of UI date-only format if valid date, undefined if invalid
 */
export function parseDisplayDateUiFromDateTime(apiDateTime?: string|null): string|undefined {
  if (!apiDateTime) return undefined;
  if (isMasked(apiDateTime)) return undefined;

  return formatInTimeZone(apiDateTime, systemTimeZone(), dateFormatDefinition().DISPLAY_DATE);
}

/**
 * Convert date into a valid date-only string for API
 * Will return null if date can't be parsed so API can generate an error
 * @param uiDate string containing a date
 * @returns {string|null} date-only string, null if date is missing
 */
export function sanitizeDateApi(uiDate?: string|null): string|null {
  // API will permit a null date only if it is an optional property
  if (!uiDate) return null;
  if (isNaN(Date.parse(uiDate))) return API_SAVE_INVALID_DATETIME;
  if (uiDate.length < 10) return API_SAVE_INVALID_DATETIME;

  const parsed = parse(uiDate, API_SAVE_VALID_DATE, new Date());
  if (!isValid(parsed)) return API_SAVE_INVALID_DATETIME;

  return format(parsed, API_SAVE_VALID_DATE);
}
