<template>
  <section id="sumSticky" :class="{ active: isVisible }" class="summary-sticky d-none d-md-block" aria-hidden="true">
   
    <div class="summary-header">
         
      <nav class="navbar">
        <ul class="navbar-nav nav-sticky" role="navigation">

            <li class="nav-item">
               <router-link :to="{ name: 'index' }" exact>
                 <font-awesome-icon :icon="['fas', 'home']" 
                  fixed-width/>
              </router-link>
          </li>

          <li class="nav-item dropdown">
            <a
              id="summaryMenuDropdown"
              href="#"
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              title="View main navigation"
            >
             <font-awesome-icon :icon="['far', 'bars']" fixed-width/>
            </a>
            <div class="dropdown-menu" aria-labelledby="summaryMenuDropdown" role="navigation">
              <router-link class="nav-link active d-inline-block d-md-none" :to="{ name: 'index' }" exact>{{$t('dashboard')}}</router-link>
              <a class="nav-link" href="/recipients/">{{$t('recipients')}}</a>
            </div>
          </li>
        </ul>
        <!-- Summary stuff -->
        <!-- "summaryContent"" should be LI elements with classes 'navbar-text' or 'nav-item' -->
        <div class="nav-sticky summary-content">
          <slot name="summaryContent" />
        </div>
        <ul class="navbar-nav nav-sticky" role="navigation">
          <li>
           <!-- User Menu -->
            <user-menu />
          </li>
        </ul>
      </nav>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import UserMenu from '@/components/shared/site-header/UserMenu.vue';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    UserMenu,
  },
  ...i18nMessages([
    require('./_locales/MainMenu.json'),
  ])
})
export default class StickySummary extends Vue {
  // Properties for optional configuration
  @Prop({ default: 150 }) scrollThreshold!: number; // number of pixels below which the summary is visible

  // Component state
  private isVisible = false; // toggles whether or not the sticky summary can be seen

  // Lifecycle events
  private mounted(): void {
    window.addEventListener('scroll', this.handleScroll);
  }

  // Event listeners
  private handleScroll(): void {
    const lastPosition = window.pageYOffset;
    if (lastPosition > this.scrollThreshold) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  }
}
</script>
