import { Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.journey)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
  ]))
}