import Vue from 'vue';
import { MutationTree } from 'vuex';
import { HospitalsState } from './types';

export const mutations: MutationTree<HospitalsState> = {
  setTransplantProgram(state, hospitals) {
    state.transplantProgram = hospitals;
  },
  startLoadingTracking(state, type: string) {
    state.loadingTracker.type = { currentState: 'loading' };
  },
  finishLoadingTracking(state, type: string) {
    state.loadingTracker.type = { currentState: 'loaded' };
  },
  resetLoadingTracking(state, type: string) {
    delete state.loadingTracker.type;
  }
};
