import { Getter } from "vuex-facing-decorator";
import { Component, Vue } from 'vue-facing-decorator';
import { TranslationContext } from '@/types';
import { GenericCodeValue } from '@/store/types';

@Component
export class TranslationUtilsMixin extends Vue {
  @Getter('i18nEnabled', { namespace: 'configuration' }) private i18nEnabled!: boolean;
  @Getter('getDefaultLocale', { namespace: 'configuration' }) private getDefaultLocale!: string;
  @Getter('getAvailableLocales', { namespace: 'configuration' }) public getAvailableLocales!: string[];

  get yesNoOptions(): GenericCodeValue[] {
    return [
      { code: "1", value: this.$t("yes").toString() },
      { code: "0", value: this.$t("no").toString() },
    ];
  }

  /**
   * Translate context key as well as individually-translated values
   *
   * @param context object containing i18n translation key and optional template values
   * @returns {string} translation result
   */
  public translateContext(context: TranslationContext): string {
    const translatedValues: { [key: string]: string } = {};
    const contextValues = context.values || {};
    Object.keys(contextValues).forEach((contextValueKey: string) => {
      const contextValue = contextValues[contextValueKey];
      translatedValues[contextValueKey] = this.$te(contextValue) ? this.$t(contextValue).toString() : this.$t(contextValue).toString();
      });
    return this.$t(context.key, translatedValues).toString();
  }

  /**
   * Convenience method for array of contexts
   *
   * @param contexts array of objects containing i18n translation key and optional template values
   * @returns {string[]} translation results
   */
  public translateContexts(contexts: TranslationContext[]): string[] {
    const result = contexts.map((context: TranslationContext): string => {
      return this.translateContext(context);
    });
    return result;
  }

  /**
   * Handle translations for Wait Time
   *
   * @param waitTimeDays numeric value for Wait Time result, or null if no result
   * @returns {string} translation result
   */
  public translateWaitTime(waitTimeDays: number|null): string {
    // NOTE: here we use double-equals to ensure that zero 0 is NOT displayed as dash '-'
    if (waitTimeDays == null) return '-';

    return this.$tc('wait_time_days', waitTimeDays).toString();
  }

  public get currentLocale(): string {
    if (!this.i18nEnabled) { return this.getDefaultLocale; }

    return localStorage.getItem('locale') || this.getDefaultLocale;
  }

  setupI18n() {
    const defaultLocale = this.getDefaultLocale;
    const availableLocales = this.getAvailableLocales;

    const localeFromStorage = localStorage.getItem('locale');

    if (this.i18nEnabled) {
      // check for bad locale and replace with default
      if (localeFromStorage == 'undefined' || localeFromStorage == null || !availableLocales.includes(localeFromStorage)) {
        this.$i18n.locale = defaultLocale;
        localStorage.setItem('locale', defaultLocale);  
      } else {
        this.$i18n.locale = localeFromStorage;
      }
    } else {
      // if i18n disabled, overwrite current locale with default
      this.$i18n.locale = defaultLocale;
      localStorage.setItem('locale', defaultLocale);
    }
  }
}
