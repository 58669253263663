import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_provider = _resolveComponent("validation-provider")!

  return (_openBlock(), _createBlock(_component_validation_provider, {
    ref: "provider",
    name: _ctx.inputId,
    vid: _ctx.validationId
  }, {
    default: _withCtx(() => [
      _createElementVNode("input", {
        id: _ctx.inputId,
        type: "hidden",
        value: _ctx.modelValue
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["name", "vid"]))
}