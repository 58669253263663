export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "policy_exemptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Exemptions"])},
        "request_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Type"])},
        "request_type_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Type"])},
        "request_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date / Time of Request"])},
        "request_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Request"])},
        "request_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of Request"])},
        "requesting_hospital_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requesting Transplant Centre"])},
        "requesting_hospital_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requesting Transplant Centre - Other"])},
        "requesting_doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physician"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Comments"])},
        "current_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Decision"])},
        "create_policy_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Policy Exemption"])},
        "save_policy_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Policy Request"])},
        "new_policy_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Policy Exemption"])},
        "selected_policy_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Policy Exemption"])},
        "use_form_below_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the form below to add a new Policy Exemption"])},
        "use_form_below_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the form below to add a new Policy Attachment"])},
        "use_form_below_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the form below to add a new Policy Sub Decision"])},
        "use_form_below_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the form below to add a new Policy Final Decision"])},
        "new_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Attachment"])},
        "selected_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Attachment"])},
        "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
        "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])},
        "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Name"])},
        "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Type"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "date_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Added"])},
        "added_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added By"])},
        "create_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Attachment"])},
        "upload_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Attachment"])},
        "save_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Attachment"])},
        "download_this_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download this attachment"])},
        "edit_this_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit this attachment"])},
        "remove_this_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove this attachment"])},
        "new_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Sub Decision"])},
        "selected_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Sub Decision"])},
        "create_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Sub Decision"])},
        "save_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Sub Decision"])},
        "sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Decision"])},
        "sub_decisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Decisions"])},
        "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response"])},
        "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responses"])},
        "date_of_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date / Time of Response"])},
        "response_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Response"])},
        "response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of Response"])},
        "authorized_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized User"])},
        "responding_transplant_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responding Transplant Centre"])},
        "physician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physician"])},
        "suggested_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested Score"])},
        "general_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Comments"])},
        "final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Decision"])},
        "final_decisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Decisions"])},
        "new_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Final Decision"])},
        "selected_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Final Decision"])},
        "create_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Final Decision"])},
        "save_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Final Decision"])},
        "datetime_of_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date / Time of Final Decision"])},
        "date_of_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Final Decision"])},
        "time_of_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of Final Decision"])},
        "medical_director_consulted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Director Consulted"])},
        "physician_consulted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physician Consulted"])},
        "error_generating_download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error generating download link"])},
        "delete_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Attachment"])},
        "delete_attachment_confirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete Attachment? Are you sure you want to delete '", _interpolate(_named("fileName")), "'?"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "fr": {
        "policy_exemptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Exemptions"])},
        "request_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de demande"])},
        "request_type_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Type"])},
        "request_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/heure de la demande"])},
        "request_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la demande"])},
        "request_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure de la demande"])},
        "requesting_hospital_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CH de demande"])},
        "requesting_hospital_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CH de demande - Autre"])},
        "requesting_doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du médecin"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire général"])},
        "current_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Decision"])},
        "create_policy_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Policy Exemption"])},
        "save_policy_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Policy Request"])},
        "new_policy_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Policy Exemption"])},
        "selected_policy_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Policy Exemption"])},
        "use_form_below_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the form below to add a new Policy Exemption"])},
        "use_form_below_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the form below to add a new Policy Attachment"])},
        "use_form_below_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the form below to add a new Policy Sub Decision"])},
        "use_form_below_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the form below to add a new Policy Final Decision"])},
        "new_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Attachment"])},
        "selected_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Attachment"])},
        "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
        "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier"])},
        "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du fichier"])},
        "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Type"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "date_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'ajout"])},
        "added_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usager d'ajout"])},
        "create_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Attachment"])},
        "upload_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Attachment"])},
        "save_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Attachment"])},
        "download_this_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download this attachment"])},
        "edit_this_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit this attachment"])},
        "remove_this_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove this attachment"])},
        "new_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Sub Decision"])},
        "selected_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Sub Decision"])},
        "create_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Sub Decision"])},
        "save_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Sub Decision"])},
        "sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Decision"])},
        "sub_decisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Decisions"])},
        "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
        "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responses"])},
        "date_of_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/heure de la réponse"])},
        "response_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Response"])},
        "response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of Response"])},
        "authorized_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur autorisé"])},
        "responding_transplant_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CH de réponse"])},
        "physician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du médecin"])},
        "suggested_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested Score"])},
        "general_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire général"])},
        "final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision finale"])},
        "final_decisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Decisions"])},
        "new_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Final Decision"])},
        "selected_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Final Decision"])},
        "create_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Final Decision"])},
        "save_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Final Decision"])},
        "datetime_of_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/heure de la décision finale"])},
        "date_of_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Final Decision"])},
        "time_of_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of Final Decision"])},
        "medical_director_consulted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction médicale consulté"])},
        "physician_consulted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du médecin consulté"])},
        "error_generating_download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error generating download link"])},
        "delete_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Attachment"])},
        "delete_attachment_confirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete Attachment? Are you sure you want to delete '", _interpolate(_named("fileName")), "'?"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "en_tq": {
        "policy_exemptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derogation"])},
        "create_policy_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Derogation"])},
        "new_policy_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Derogation"])},
        "selected_policy_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Derogation"])},
        "request_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date - Time"])},
        "request_type_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derogation Type"])},
        "requesting_hospital_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requesting Centre"])},
        "save_policy_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Derogation Request"])},
        "create_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Response"])},
        "new_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Response"])},
        "sub_decisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responses"])},
        "date_of_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date - Time"])},
        "responding_transplant_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Center"])},
        "save_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Response"])},
        "final_decisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Decision"])},
        "create_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Final Decision"])},
        "datetime_of_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date - Time"])},
        "new_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Final Decision"])}
      },
      "fr_tq": {
        "policy_exemptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derogation"])},
        "create_policy_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Derogation"])},
        "new_policy_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Derogation"])},
        "selected_policy_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Derogation"])},
        "request_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date - Time"])},
        "request_type_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derogation Type"])},
        "requesting_hospital_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requesting Centre"])},
        "save_policy_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Derogation Request"])},
        "sub_decisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responses"])},
        "date_of_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date - Time"])},
        "responding_transplant_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Center"])},
        "save_sub_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Response"])},
        "final_decisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Decision"])},
        "create_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Final Decision"])},
        "datetime_of_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date - Time"])},
        "new_final_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Final Decision"])}
      }
    }
  })
}
