<template>
  <button
    @click="clicked"
    type="button"
    class="btn btn-white btn-sm"
    :aria-label="`${ value ? untoggleLabel : toggleLabel }`"
    :title="`${ value ? untoggleLabel : toggleLabel }`"
  >
    <font-awesome-icon :icon="['fas', 'angle-down']" class="accordion-icon" :class="{ rotate: !value }" />
  </button>
</template>

<script lang="ts">
import { Component, Vue, Model, Prop } from 'vue-facing-decorator';

@Component({
  components: {
  }
})
export default class ToggleButton extends Vue {
  // V-model
  @Model({ name: 'change' }) value!: string;

  // Props
  @Prop({ required: true }) toggleLabel?: string; // aria label and hover title when not yet toggled
  @Prop({ required: true }) untoggleLabel?: string; // aria label and hover title when not yet toggled


  // Event handlers
  private clicked(event: any): void {
    this.$emit('change', !this.value);
  }
}
</script>
