import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hla_perform_specified_virtual_crossmatch = _resolveComponent("hla-perform-specified-virtual-crossmatch")!
  const _component_hla_view_virtual_crossmatch_history = _resolveComponent("hla-view-virtual-crossmatch-history")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "hla-vxm",
    "lookups-to-load": _ctx.lookupsToLoad,
    onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('virtual_crossmatch')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_hla_perform_specified_virtual_crossmatch, { "recipient-id": _ctx.recipientId }, null, 8, ["recipient-id"]),
      _createVNode(_component_hla_view_virtual_crossmatch_history)
    ]),
    _: 1
  }, 8, ["lookups-to-load"]))
}