import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args))),
    type: "button",
    class: "btn btn-white btn-sm",
    "aria-label": `${ _ctx.value ? _ctx.untoggleLabel : _ctx.toggleLabel }`,
    title: `${ _ctx.value ? _ctx.untoggleLabel : _ctx.toggleLabel }`
  }, [
    _createVNode(_component_font_awesome_icon, {
      icon: ['fas', 'angle-down'],
      class: _normalizeClass(["accordion-icon", { rotate: !_ctx.value }])
    }, null, 8, ["class"])
  ], 8, _hoisted_1))
}