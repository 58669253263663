import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dropdown"
}
const _hoisted_2 = {
  "aria-labelledby": "selectOrgan",
  class: "dropdown-menu dropdown-menu-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.showAddOrgan)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("a", {
          id: "new-organ",
          href: "#",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
          class: _normalizeClass(["dropdown-toggle", { 'btn btn-light': _ctx.button, 'nav-link' : _ctx.navlink }])
        }, _toDisplayString(_ctx.$t('add_required_organ')), 3),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addOrganOptions, (addOrganOption) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              class: "dropdown-item",
              key: `new-organ-${addOrganOption.code}`,
              to: { name: 'new-organ', params: { id: _ctx.recipientId, organ_code: addOrganOption.code } }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(addOrganOption.value)), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}