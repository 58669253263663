<template>
 <div class="row">
   <div class="col-sm-12">
    <nav class="action-row">
      <span class="skeleton-box skeleton-button" /><br/>
    </nav><br/><br/><br/>
    <div class="skeleton-container">
    <table>
      <thead>
        <tr>
          <th>  
            <span class="skeleton-box w-50" />
          </th>  
          <th>  
            <span class="skeleton-box w-100" />
          </th>  
          <th>  
            <span class="skeleton-box w-100" />
          </th>  
          <th>  
            <span class="skeleton-box w-50" />
          </th>  
          <th>  
            <span class="skeleton-box w-100" />
          </th>  
          <th>  
            <span class="skeleton-box w-100" />
          </th>  
          <th>  
            <span class="skeleton-box w-50" />
          </th>  
          <th>  
            <span class="skeleton-box w-100" />
          </th>  
          <th>  
            <span class="skeleton-box w-50" />
          </th>
          <th>  
            <span class="skeleton-box w-100" />
          </th>  
          <th>  
            <span class="skeleton-box w-100" />
          </th>          
          <th>  
            <span class="skeleton-box w-50" />
          </th>  
          <th>  
            <span class="skeleton-box w-100" />
          </th> 
          <th>  
            <span class="skeleton-box w-100" />
          </th>   
          <th>  
            <span class="skeleton-box w-100" />
          </th>   
        </tr>
      </thead>
    </table>           <div v-for="index in 8"
                          :key="index"
                          class="loading_row_container"
                        >
                        <div class="loading_row"></div>
                      </div> 
    </div>
   </div>
 </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class LoadingListView extends Vue {}
</script>
