import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "legend-title" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "standard-form-group-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_ctx.editState)
    ? (_openBlock(), _createBlock(_component_sub_section, {
        key: 0,
        "sub-section-id": "journey-state",
        title: _ctx.$t('journey_state')
      }, {
        guiding_text: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('journey_state_guiding_text')), 1)
        ]),
        contents: _withCtx(() => [
          _createVNode(_component_table_toolbar, {
            createButton: _ctx.canSave && !_ctx.newJourney,
            createText: _ctx.$t('create_new_entry'),
            onTableCreateRow: _ctx.prepareNewEntry
          }, null, 8, ["createButton", "createText", "onTableCreateRow"]),
          _createVNode(_component_table_list, {
            "table-id": "journey-table-list",
            ref: "journeyTableList",
            tabbableColumn: "effective_datetime_display",
            "table-config": _ctx.journeyStateTableConfig,
            onTableRowClick: _ctx.handleTableRowClick,
            highlightSelection: true,
            onOnPageNumber: _cache[0] || (_cache[0] = ($event: any) => {_ctx.onPageNumber($event); _ctx.loadData()}),
            onOnPageSize: _cache[1] || (_cache[1] = ($event: any) => {_ctx.onPageSize($event); _ctx.loadData()}),
            mode: "remote",
            rowStyleClass: _ctx.rowStyleClass,
            isLoading: _ctx.isLoading,
            totalRecords: _ctx.totalRecords
          }, null, 8, ["table-config", "onTableRowClick", "rowStyleClass", "isLoading", "totalRecords"]),
          _createVNode(_component_validation_observer, { ref: "validations" }, {
            default: _withCtx(() => [
              _createVNode(_component_form_layout, {
                disabled: !_ctx.canSave || _ctx.newJourney || _ctx.isDisabled,
                "form-id": "journey-sub-section-form"
              }, {
                title: _withCtx(() => [
                  _createElementVNode("legend", null, [
                    _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.isEditing ? _ctx.$t('selected_entry') : _ctx.$t('new_entry')), 1)
                  ]),
                  (_ctx.newJourney || !_ctx.isDisabled)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.isEditing ? _ctx.$t('selected_guiding_text') : _ctx.$t('new_guiding_text')), 1))
                    : _createCommentVNode("", true)
                ]),
                contents: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_select_input, {
                        selectId: "journey-state-phase",
                        ruleKey: "journeyState.phase",
                        name: _ctx.$t('phase'),
                        modelValue: _ctx.editState.phase,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.phase) = $event)),
                        options: _ctx.getPhaseOptions,
                        onChange: _ctx.resetJourneyStatus
                      }, null, 8, ["name", "modelValue", "options", "onChange"])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_select_input, {
                        selectId: "journey-state-status",
                        ruleKey: "journeyState.status",
                        name: _ctx.$t('status'),
                        modelValue: _ctx.editState.status,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.status) = $event)),
                        options: _ctx.getStatusOptions,
                        disabled: _ctx.getStatusOptions.length === 0,
                        onChange: _ctx.resetJourneyReason
                      }, null, 8, ["name", "modelValue", "options", "disabled", "onChange"])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_select_input, {
                        selectId: "journey-state-reason",
                        ruleKey: "journeyState.reason",
                        name: _ctx.$t('reason'),
                        modelValue: _ctx.editState.reason,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.reason) = $event)),
                        options: _ctx.getReasonOptions,
                        disabled: !_ctx.editState.status || _ctx.getReasonOptions.length === 0
                      }, null, 8, ["name", "modelValue", "options", "disabled"])
                    ]),
                    (_ctx.isWorkflowStepEnabled)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_select_input, {
                            selectId: "journey-state-workflow-step",
                            name: _ctx.$t('workflow_step'),
                            modelValue: _ctx.editState.step,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.step) = $event)),
                            options: _ctx.workflowStepLookup
                          }, null, 8, ["name", "modelValue", "options"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_date_input, {
                        inputId: "journey-state-effective-date",
                        name: _ctx.$t('effective_date'),
                        ruleKey: "journeyState.effective_datetime",
                        modelValue: _ctx.editState.effectiveDate,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.effectiveDate) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_time_input, {
                        inputId: "journey-state-effective-time",
                        name: _ctx.$t('effective_time'),
                        ruleKey: "journeyState.effective_datetime",
                        modelValue: _ctx.editState.effectiveTime,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.effectiveTime) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_text_area_input, {
                        "input-id": "journey-state-comment",
                        name: _ctx.$t('comment'),
                        ruleKey: "journeyState.comment",
                        modelValue: _ctx.editState.comment,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.comment) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ])
                  ])
                ]),
                save: _withCtx(() => [
                  (_ctx.canSave && !_ctx.newJourney && !_ctx.isDisabled)
                    ? (_openBlock(), _createBlock(_component_save_toolbar, {
                        key: 0,
                        ref: "journeySaveToolbar",
                        label: _ctx.$t('save_journey_state'),
                        cancelButton: true,
                        onSave: _cache[9] || (_cache[9] = ($event: any) => (_ctx.savePatch())),
                        onCancel: _cache[10] || (_cache[10] = ($event: any) => (_ctx.cancelPatch()))
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}