import Vue from 'vue';
import { MutationTree } from 'vuex';
import { AlcoholicLiverDiseaseProgram } from '@/store/organSpecificDetails/types';
import { JourneyState, ReferralDecision, AssessmentDecision, ConsultationDecision, 
  JourneyDurations, WaitlistDecision, PostTransplantFollowUp, WaitDaysAdjustmentEvent,
  ClinicalNoteModel } from '@/store/recipientJourney/types';

export const mutations: MutationTree<JourneyState> = {
  setJourney(state, journey) {
    state.selectedJourney = journey;
  },
  clearJourney(state) {
    state.selectedJourney = undefined;
  },
  setOrganSpecificDetails(state, organSpecificDetails) {
    state.selectedJourney!.organ_specific_details = organSpecificDetails;
  },
  selectReferralDecision(state: JourneyState, referralDecision: ReferralDecision): void {
    state.selectedReferralDecision = referralDecision;
  },
  clearReferralDecision(state: JourneyState): void {
    state.selectedReferralDecision = undefined;
  },
  selectAssessmentDecision(state: JourneyState, assessmentDecision: AssessmentDecision): void {
    state.selectedAssessmentDecision = assessmentDecision;
  },
  clearAssessmentDecision(state: JourneyState): void {
    state.selectedAssessmentDecision = undefined;
  },
  selectConsultationDecision(state: JourneyState, consultationDecision: ConsultationDecision): void {
    state.selectedConsultationDecision = consultationDecision;
  },
  clearConsultationDecision(state: JourneyState): void {
    state.selectedConsultationDecision = undefined;
  },
  setJourneyDurations(state: JourneyState, journeyDurations: JourneyDurations): void {
    state.journeyDurations = journeyDurations;
  },
  clearJourneyDurations(state: JourneyState): void {
    state.journeyDurations = undefined;
  },
  clearWaitlistDecisions(state: JourneyState): void {
    state.waitlistDecisions = undefined;
    state.selectedWaitlistDecision = undefined;
  },
  setWaitlistDecisions(state: JourneyState, decisions: WaitlistDecision[]): void {
    state.waitlistDecisions = decisions;
  },
  clearWaitlistDecision(state: JourneyState): void {
    state.selectedWaitlistDecision = undefined;
  },
  selectWaitlistDecision(state: JourneyState, decision: WaitlistDecision): void {
    state.selectedWaitlistDecision = decision;
  },
  setTransplantDetails(state, transplantDetails): void {
    state.transplantDetails = transplantDetails;
  },
  setPostTransplantFollowUps(state, postTransplantFollowUps) {
    state.postTransplantFollowUps = postTransplantFollowUps;
  },
  selectPostTransplantFollowUp(state: JourneyState, postTransplantFollowUp: PostTransplantFollowUp): void {
    state.selectedPostTransplantFollowUp = postTransplantFollowUp;
  },
  clearPostTransplantFollowUp(state: JourneyState): void {
    state.selectedPostTransplantFollowUp = undefined;
  },
  clearAldProgram(state: JourneyState): void {
    state.selectedAldProgram = undefined;
  },
  selectAldProgram(state: JourneyState, aldProgram: AlcoholicLiverDiseaseProgram): void {
    state.selectedAldProgram = aldProgram;
  },
  clearWaitTimeOverrideEvents(state: JourneyState): void {
    state.waitTimeOverrideEvents = undefined;
  },
  setWaitTimeOverrideEvents(state: JourneyState, events: WaitDaysAdjustmentEvent[]): void {
    state.waitTimeOverrideEvents = events;
  },
  selectWaitTimeOverrideEvent(state: JourneyState, event: WaitDaysAdjustmentEvent): void {
    state.selectedWaitTimeOverrideEvent = event;
  },
  setValidationErrors(state, errors) {
    state.validationErrors = errors;
  },
};
