<template>
  <div class="mb-4" v-if="tableConfig && tableConfig.columns"
  :class="getStyleClass()">
    <!-- primevue table -->
    <div v-if="showTopScrollBar" class="adaptive-top-scrollbar" ref="adaptiveTopScrollbarVertical">
      <div></div>
    </div>
    <DataTable
      :id="tableId"
      :ref="tableId"
      :showGridlines="true" 
      :value="this.tableConfig.data || []"
      :loading="isLoading"
      rowKey='id'
      :lazy="isLazy"
      :class="getTableClass"
      :totalRecords="getNumberOfRecords"
      :selectionMode="`${highlightSelection ? 'single' : null}`"
      :expandedRows="this.tableConfig.data || []"
      :rowGroupMode="getGroupMode"
      @scroll="handleScroll"
      :groupRowsBy="getGroupBy"
      :filter-display="filterDisplay"
      :show-filter-menu="false"
      sort-mode="single"
      :removable-sort="true"
      @sort="onSort"
      >
      <template v-for="(col) of tableConfig.columns">
        <Column
          v-if="!col.expanded && !col.hidden"
          :field="col.field"
          :class="col.class"
          :header="col.label"
          :sortable="col.sortable || false"
          :key="col.field"
          :style="buildColumnStyle(col)"
          :bodyClass="col.tdClass"
          :frozen="col.frozen || false"
          alignFrozen="left"
        >
          <template #filter>
            <div v-if="col.filterOptions">
              <text-input
                v-if="col.filterOptions.type == 'text'"
                :input-id="`${col.field}_filter`"
                :name="col.label"
                :hide-label="true"
                :model-value="searchParams[col.field]"
                @update:model-value="(event: any) => onFilter(event, col)"
              />
              <select-input
                v-else-if="col.filterOptions.type == 'dropdown'"
                :select-id="`${col.field}_filter`"
                :name="col.label"
                :hide-label="true"
                :model-value="searchParams[col.field]"
                @update:model-value="(event: any) => onFilter(event, col)"
                :options="col.filterOptions.filterDropdownItems"
                :undefinedText="col.filterOptions.placeholder"
              />
            </div>
          </template>
          <template #header>
            <span  v-if="col.frozen" ref="frozen-col" :title="col.label"> {{col.label}}</span>
            <div v-else class="t-clickable-column" :class="getColumnClass(col.field)" label="Clear" outlined> 
              <ToolTipControl v-if="col.collection_comments" :isFormELement="false" :toolTipText="col.collection_comments"/>
              <a href=""  @mouseover="onColumnHover(col)" @mouseleave="clearHover()"  @click.prevent="onColumnClick(col)">
                <span>{{col.date}}</span>
                <span>{{col.time}}</span>
              </a>
            </div>
          </template>
          <template #body="slotProps">
            <span v-if="isMobile" class="p-column-title">{{col.label}}</span>
            <span v-if="col.html" v-html="slotProps.data[col.field]" />
            <span @mouseover="onColumnHover(col)" @mouseleave="clearHover()" v-if="col.marker" :class="`serology-marker ${generateSerologyMarkerCss(col.field, slotProps.data)} ${getColumnClass(col.field)}`">              
              <ToolTipControl v-if="getToolTipForMarker(col.field, slotProps.data)" :isFormELement="false" :toolTipText="getToolTipForMarker(col.field, slotProps.data)" />
              <span v-if="slotProps.data[col.field]" class="marker-name">
                {{ slotProps.data[col.field] }}</span>
              <span v-else>-</span>
            </span>
             <span :title="slotProps.data[col.field]" ref="frozen-col" v-else>
              <span class="text-label">{{ slotProps.data[col.field] }}</span></span>
          </template>
        </Column>
      </template>
      <template #groupheader="">
        <span aria-hidden="true">&nbsp;</span>
      </template>
      <template #expansion="slotProps" v-if="getNameOfExpandedField">
        <div>
          <more-less :descriptionText="slotProps.data[getNameOfExpandedField]" />
        </div>
      </template>
      <template #empty>
        <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
        {{ tableConfig.empty || $t('use_form_below_new_row') }}
      </template>
      <!-- second row -->
    </DataTable>
  </div>
</template>

<script lang="ts">
import { TableConfig } from '@/types';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import MoreLess from '@/components/shared/MoreLessComponent.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import SelectInput from '@/components/shared/SelectInput.vue';
import { i18nMessages } from '@/i18n';
import { ColumnOption } from '@/types';
import TextInput from "@/components/shared/TextInput.vue";
import ToolTipControl from '@/components/shared/ToolTipControl.vue';

interface SortParameters {
  sort_by: string|null;
  sort_type: string|null;
}

interface SearchParameters {
  [key: string]: string;
}

@Component({
  components: {
    DataTable,
    Column,
    MoreLess,
    SelectInput,
    TextInput,
    ToolTipControl,
  },
  ...i18nMessages([
    require('@/components/shared/_locales/TableList.json'),
  ]),
  emits: [
    'on-column-filter',
    'on-sort-change',
    'table-col-click',
    'table-row-click',
    'table-row-download',
  ],
})
export default class VerticalTableList extends Vue {
  @Prop({ required: true }) tableId!: string;
  @Prop({ required: true }) tableConfig!: TableConfig;
  @Prop({ required: false }) mode?: string;
  @Prop({ default: false }) highlightSelection!: boolean; // when true, highlight selected rows
  @Prop({ default: false}) isLoading!: boolean;

  // row grouping params
  @Prop({ default: null }) groupBy!: string; // group mode (options are 'subheader' or null)
  @Prop({ default: null }) groupMode!: string; // row item to group by (e.g. field name)

  // Lookup properties
  @Prop({ default: () => { return []; } }) lookupsToLoad!: string[];
  @Prop({ default: () => { return []; } }) laboratoriesToLoad!: string[];
  @Prop({ default: () => { return []; } }) hospitalsToLoad!: string[];
  @Prop({ default: false }) horizontalScroll!: boolean;
  @Prop({ default: false}) showTopScrollBar!: boolean;

  public  getStyleClass() {
    return { "spaced-list-items": this.isMobile, 'horizontal-view': this.horizontalScroll};
  } 

  public checkwidth() {
    if (!this.horizontalScroll && window.innerWidth < 960) {
      this.isMobile = window.innerWidth < 960;
    } else {
      this.isMobile = false;
      if (this.showTopScrollBar) {
        this.setupScrollBarStyle((this.tableRef as any).rootEl);
      }
    }
  }

  private get tableRef(): VerticalTableList {
    return this.$refs[this.tableId] as VerticalTableList;
  }

  public setupScrollBarStyle(element: any) {
    // make reference to scrollbar
    let scrollbar = (this.$refs.adaptiveTopScrollbarVertical as any);

    // set overflow style
    const scrollbarStyle = scrollbar?.style;
    if (scrollbarStyle) {
      scrollbarStyle.overflowY = 'hidden';
    }
  
    // set child width
    if (scrollbar && scrollbar.children) {
      const scrollbarChild = scrollbar.children[0];
      scrollbarChild.style.width = element.scrollWidth+'px';
    }
  }

  public setupScrollBarEvents(element: any) {
    // make reference to scrollbar
    let scrollbar = (this.$refs.adaptiveTopScrollbarVertical as any);

    // add scroll events
    scrollbar.onscroll = function() {
      element.scrollLeft = scrollbar.scrollLeft;
    };
    element.onscroll = function() {
      scrollbar.scrollLeft = element.scrollLeft;
    };    
  }

  // Private attributes
  private selectedIds: string[] = [];

  private hoveredIds: string[] = [];
  // Zero-relative number of the first row to be displayed

  private isMobile = false;

  private get getNumberOfRecords(): number {
    return this.tableConfig.data.length || 0;
  }

  private getToolTipForMarker(id: string, marker: any) {
    return marker[`${id}_comments`] || null;
  }

  private delayAmount = 1500;

  private handleScroll (el: any) {
    let refs: any = this.$refs['frozen-col'];
    if (el.srcElement.scrollLeft > 0) { 
      refs.map((ref: any) => {
        ref.classList.add('addShadowBox');
      });
    } else {
      refs.map((ref: any) => {
        ref.classList.remove('addShadowBox');
      });
    }
  }

  private get getTableClass(): string {
    return this.tableConfig.data.length > 0 ? 'scrollbox d-vertical-table d-vertical-table-with-data' : 'scrollbox d-vertical-table d-vertical-table-empty';
  }

  // sorting and search
  public sortParams: SortParameters = { sort_by: null, sort_type: null };
  private searchParams: SearchParameters = {};

  private generateSerologyMarkerCss(id: string, marker: any): string {
    const key = `${id}_virology_result_code` || null;
    const className = key ? marker[key] : null;
    return className ? `marker-${className}` : 'marker-empty';
  }

  public getColumnClass(id: any) { 
    return this.hoveredIds.includes(id) || this.selectedIds.includes(id) ? 'p-column-selected': '';
  } 

  get getGroupMode(): string {
    return this.isMobile ? 'subheader' : this.groupMode; 
  }

  get getGroupBy(): string {
    return this.isMobile ? 'id' : this.groupBy; 
  }

  get filterDisplay(): string|null {
    if (!this.tableConfig) return null;

    const hasFilters = (this.tableConfig?.columns || []).some((col: ColumnOption) => {
      return !!col.filterOptions?.enabled;
    });
    return hasFilters ? 'row' : null;
  }

  get isLazy(): boolean { return this.mode === 'remote'; }

  get getNameOfExpandedField(): string|null {
    if (!this.tableConfig) return null;
    const found = this.tableConfig.columns.find((item: any) => {
      return item.expanded === true;
    });
    return found ? found.field : null;
  }

  mounted(): void {
  
    if(!this.showTopScrollBar) {
      return;
    }

    this.setupScrollBarEvents((this.tableRef as any).rootEl);

    // run checkwidth when page written
    setTimeout(() => {
      this.checkwidth();
    }, 250);

    window.addEventListener('resize', this.checkwidth);
    this.initializeSearchParameters();
  }

  initializeSearchParameters(): void {
    const params: SearchParameters = {};
    this.tableConfig.columns.forEach((col: any) => {
      if (col.filterOptions?.enabled && col.field) {
        params[col.field] = '';
      }
    });
    this.searchParams = params;
  }

  labelMatchesTabbableColumn(label: string, tabbable: string|undefined): boolean {
    if (!tabbable) { return false; }
    if (!label) { return false; }
    return label.replace(/\s/g, '') === tabbable.replace(/\s/g, '');
  }


  // Extract ID from row
  // NOTE: assumes row has 'id' or '_id.$oid'
  private idFromRow(row: any): string {
    if (!row) return '';
    if (row.id) return row.id;
    if (row._id?.$oid) return row._id.$oid;
    return '';
  }

  public onColumnClick(col: any) {
    // event.prevent
    const row = col;
    // Update internal sense of which rows are selected (only used for styling purposes)
    const id = row.field || null;
    this.selectedIds = [id];
    // Emit event to parent, which will handle functional logic separate from styling
    this.$emit('table-col-click', { id: id });
  }

  public onColumnHover(col: any) {
    // event.prevent
    const row = col;
    // Update internal sense of which rows are selected (only used for styling purposes)
    const id = row.field || null;
    this.hoveredIds = [id];
  }

  public clearHover() {
     this.hoveredIds = [];
  }

  // Event handlers
  public onRowClick(event: any) {
    const row = event.data;
    // Update internal sense of which rows are selected (only used for styling purposes)
    const id = this.idFromRow(row);
    this.selectedIds = [id];
    // Emit event to parent, which will handle functional logic separate from styling
    this.$emit('table-row-click', { row: row });
  }

  private buildSortType(sortOrder: number|null): string|null {
    switch (sortOrder) {
      case 1:
        return 'asc';
      case -1:
        return 'desc';
      default:
        return null;
    }
  }

  public onSort(event: { sortField: string|null, sortOrder: number|null }) {
    const fieldName = event.sortField;
    this.sortParams = { sort_by: null, sort_type: null  };
    if (fieldName && event.sortOrder != null) {
      this.sortParams.sort_by = fieldName;
      this.sortParams.sort_type = this.buildSortType(event.sortOrder);
    }
    const sortChangeEvent = {
      sortParams: this.sortParams,
      searchParams: this.searchParams,
    };
    this.$emit('on-sort-change', sortChangeEvent);
  }

  private searchDebounce: any;

  private onFilter(value: string, col: ColumnOption) {
    const fieldName = col.field;
    this.searchParams[fieldName] = value;

    if (this.searchDebounce) clearTimeout(this.searchDebounce);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _vm = this;
    this.searchDebounce = setTimeout(() => {
      const columnFilterEvent = {
        sortParams: this.sortParams,
        searchParams: this.searchParams,
      };
      _vm.$emit('on-column-filter', columnFilterEvent);
    }, this.delayAmount);
  }

  public resetSelection(): void {
    this.selectedIds = [];
  }

  private onDownloadClick(params: any): void {
    this.$emit('table-row-download', params);
  }

  private buildColumnStyle(col: ColumnOption): string|undefined {
    const styleParts = [];
    if (col.width) {
      styleParts.push(`width: ${col.width}`);
      styleParts.push(`min-width: ${col.width}`);
      styleParts.push(`max-width: ${col.width}`);
    }

    styleParts.push('overflow: hidden');
    styleParts.push('text-overflow: ellipsis');

    return styleParts.join('; ');
  }
}
</script>
