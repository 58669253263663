import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-sm-12"
}
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "content-wrap" }
const _hoisted_4 = { class: "container-fluid" }
const _hoisted_5 = { class: "nav-wrapper" }
const _hoisted_6 = { class: "page-content" }
const _hoisted_7 = { class: "form-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page_top = _resolveComponent("page-top")!
  const _component_potential_duplicate_modal = _resolveComponent("potential-duplicate-modal")!
  const _component_living_donor_sticky_summary = _resolveComponent("living-donor-sticky-summary")!
  const _component_side_nav_living_donor = _resolveComponent("side-nav-living-donor")!
  const _component_living_donor_summary = _resolveComponent("living-donor-summary")!
  const _component_demographics = _resolveComponent("demographics")!
  const _component_contact_information = _resolveComponent("contact-information")!
  const _component_link_to_recipient = _resolveComponent("link-to-recipient")!
  const _component_donation_information = _resolveComponent("donation-information")!
  const _component_general_clinical_information = _resolveComponent("general-clinical-information")!
  const _component_virology_results = _resolveComponent("virology-results")!
  const _component_recovery_information = _resolveComponent("recovery-information")!
  const _component_hla_typing = _resolveComponent("hla-typing")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dispatchEventsComplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("loading")), 1)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: { name: 'list-living-donors' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("living_donors")), 1)
                ]),
                _: 1
              }),
              _createTextVNode(" / " + _toDisplayString(_ctx.livingDonorDisplayName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_potential_duplicate_modal, {
            ref: "potentialDuplicateModal",
            onSavePatch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.performSave(true))),
            onSaveDemographics: _ctx.handleContinueDemographics,
            onSaveContactInfo: _ctx.handleContinueContactInfo,
            onSaveGeneralClinicalInformation: _ctx.handleContinueGeneralClinicalInformation
          }, null, 8, ["onSaveDemographics", "onSaveContactInfo", "onSaveGeneralClinicalInformation"]),
          _createVNode(_component_living_donor_sticky_summary),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_side_nav_living_donor),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_validation_observer, {
                    ref: "validations",
                    autocomplete: "off",
                    tag: "form"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_living_donor_summary),
                      _createVNode(_component_demographics, {
                        ref: "demographics",
                        onLoaded: _cache[1] || (_cache[1] = (ref) => _ctx.loaded(ref)),
                        onSave: _cache[2] || (_cache[2] = (sectionSaved) => _ctx.handleSectionSave(sectionSaved)),
                        onHandleErrors: _cache[3] || (_cache[3] = (errors) => _ctx.handleErrors(errors)),
                        canSave: _ctx.isGroupWriteable('living_donor_personal')
                      }, null, 8, ["canSave"]),
                      _createVNode(_component_contact_information, {
                        ref: "contactInfo",
                        onLoaded: _cache[4] || (_cache[4] = (ref) => _ctx.loaded(ref)),
                        onSave: _cache[5] || (_cache[5] = (sectionSaved) => _ctx.handleSectionSave(sectionSaved)),
                        onHandleErrors: _cache[6] || (_cache[6] = (errors) => _ctx.handleErrors(errors)),
                        canSave: _ctx.isGroupWriteable('living_donor_personal')
                      }, null, 8, ["canSave"]),
                      _createVNode(_component_link_to_recipient, {
                        ref: "linkToRecipient",
                        onLoaded: _cache[7] || (_cache[7] = (ref) => _ctx.loaded(ref)),
                        onSave: _cache[8] || (_cache[8] = (sectionSaved) => _ctx.handleSectionSave(sectionSaved)),
                        onHandleErrors: _cache[9] || (_cache[9] = (errors) => _ctx.handleErrors(errors)),
                        canSave: _ctx.isGroupWriteable('living_donor_personal') && !_ctx.isLockRecoveryAndDonorInformation,
                        onReload: _ctx.reload
                      }, null, 8, ["canSave", "onReload"]),
                      _createVNode(_component_donation_information, {
                        ref: "donationInformation",
                        onLoaded: _cache[10] || (_cache[10] = (ref) => _ctx.loaded(ref)),
                        onSave: _cache[11] || (_cache[11] = (sectionSaved) => _ctx.handleSectionSave(sectionSaved)),
                        onHandleErrors: _cache[12] || (_cache[12] = (errors) => _ctx.handleErrors(errors)),
                        canSave: _ctx.isGroupWriteable('living_donor_personal') && !_ctx.isLockRecoveryAndDonorInformation,
                        onClear: _ctx.resetValidationErrors,
                        onReload: _ctx.reload
                      }, null, 8, ["canSave", "onClear", "onReload"]),
                      _createVNode(_component_general_clinical_information, {
                        ref: "generalClinical",
                        onLoaded: _cache[13] || (_cache[13] = (ref) => _ctx.loaded(ref)),
                        onSave: _cache[14] || (_cache[14] = (sectionSaved) => _ctx.handleSectionSave(sectionSaved)),
                        onHandleErrors: _cache[15] || (_cache[15] = (errors) => _ctx.handleErrors(errors)),
                        onClear: _ctx.resetValidationErrors,
                        canSave: _ctx.isGroupWriteable('living_donor_personal')
                      }, null, 8, ["onClear", "canSave"]),
                      _createVNode(_component_virology_results, {
                        ref: "virology",
                        newRecord: false,
                        virologyType: _ctx.virologyType,
                        onLoaded: _cache[16] || (_cache[16] = (ref) => _ctx.loaded(ref)),
                        onHandleErrors: _cache[17] || (_cache[17] = (errors) => _ctx.handleErrors(errors)),
                        onClear: _ctx.resetValidationErrors,
                        canSave: _ctx.isGroupWriteable('living_donor_personal')
                      }, null, 8, ["virologyType", "onClear", "canSave"]),
                      (_ctx.showRecoveryInformation)
                        ? (_openBlock(), _createBlock(_component_recovery_information, {
                            key: 0,
                            ref: "recoveryInformation",
                            newRecord: false,
                            onLoaded: _cache[18] || (_cache[18] = (ref) => _ctx.loaded(ref)),
                            onHandleErrors: _cache[19] || (_cache[19] = (errors) => _ctx.handleErrors(errors)),
                            onClear: _ctx.resetValidationErrors,
                            canSave: _ctx.isGroupWriteable('living_donor_recovery_details'),
                            onReload: _ctx.reload
                          }, null, 8, ["onClear", "canSave", "onReload"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_hla_typing, {
                        ref: "hlaTypingLivingDonor",
                        enableDonor: true,
                        livingDonor: true,
                        onLoaded: _cache[20] || (_cache[20] = (ref) => _ctx.loaded(ref)),
                        onSave: _cache[21] || (_cache[21] = (sectionSaved) => _ctx.handleSectionSave(sectionSaved)),
                        onHandleErrors: _cache[22] || (_cache[22] = (errors) => _ctx.handleErrors(errors)),
                        canSave: _ctx.checkAllowed('/living_donors/:living_donor_id/hla_typing', 'POST')
                      }, null, 8, ["canSave"]),
                      _createElementVNode("div", _hoisted_7, [
                        (_ctx.canSaveLivingDonor)
                          ? (_openBlock(), _createBlock(_component_save_toolbar, {
                              key: 0,
                              ref: "saveLivingDonor",
                              label: _ctx.$t('save_living_donor'),
                              onSave: _cache[23] || (_cache[23] = ($event: any) => (_ctx.performSave()))
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }, 512)
                ])
              ])
            ])
          ])
        ], 64))
  ]))
}