export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "save_donor_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Donor Information"])},
        "donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Type"])},
        "affimative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "donationInformation-ventilator_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vent Status"])},
        "donationInformation-ventilator_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vent Type"])},
        "donationInformation-ventilator_type_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Vent Type"])},
        "donationInformation-exceptional_distribution": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution"])},
          "donationInformation-exd_reason_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution Reason(s)"])},
          "donationInformation-add-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Exceptional Distribution Reason"])},
          "donationInformation-exd_reason_details_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution Other Reason"])},
          "donationInformation-exd_reason_details_travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution Travel Reason"])},
          "donationInformation-exd_reason_details_transmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increased Risk of Transmission"])}
        },
        "donationInformation-post_release": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-Release"])},
          "donationInformation-post_release_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post Release Reason"])},
          "donationInformation-results_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Received"])},
          "donationInformation-results_received_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Received Date"])},
          "donationInformation-results_received_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Received Time"])},
          "donationInformation-results_received_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Received Updated by User"])},
          "donationInformation-results_reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Reported"])},
          "donationInformation-results_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Reason"])},
          "donationInformation-results_reported_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Reported Date"])},
          "donationInformation-results_reported_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Reported Time"])},
          "donationInformation-results_reported_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Reported Updated by User"])}
        },
        "donationInformation-ecd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extended Criteria Donor"])},
        "donationInformation-double-kidney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double Kidney"])},
        "consented-organs": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consented Organs"])},
          "consented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consented Date"])}
        },
        "donor-consented-for-research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consented for Research?"])}
      },
      "fr": {
        "save_donor_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les Informations sur les Donateurs"])},
        "donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de donneur"])},
        "affimative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "donationInformation-ventilator_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État de la Ventilation"])},
        "donationInformation-ventilator_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de Ventilation"])},
        "donationInformation-ventilator_type_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre Type de Ventilation"])},
        "donationInformation-exceptional_distribution": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Exceptionnelle"])},
          "donationInformation-exd_reason_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison(s) de Distribution Exceptionnelle"])},
          "donationInformation-add-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une raison de distribution exceptionnelle"])},
          "donationInformation-exd_reason_details_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Exceptionnelle Autre Raison"])},
          "donationInformation-exd_reason_details_travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison de Voyage de Distribution Exceptionnelle"])},
          "donationInformation-exd_reason_details_transmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risque Accru de Transmission"])}
        },
        "donationInformation-post_release": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après-la-Libération"])},
          "donationInformation-post_release_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après la libération Raison"])},
          "donationInformation-results_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Received"])},
          "donationInformation-results_received_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Received Date"])},
          "donationInformation-results_received_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Received Time"])},
          "donationInformation-results_received_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Received Updated by User"])},
          "donationInformation-results_reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats Rapportés"])},
          "donationInformation-results_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison des Résultats"])},
          "donationInformation-results_reported_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Reported Date"])},
          "donationInformation-results_reported_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Reported Time"])},
          "donationInformation-results_reported_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Reported Updated by User"])}
        },
        "donationInformation-ecd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donateur à Critères Étendus"])},
        "donationInformation-double-kidney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double Rein"])},
        "consented-organs": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organes de Consentement"])},
          "consented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du Coonsentement"])}
        },
        "donor-consented-for-research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti à la Recherche?"])}
      }
    }
  })
}
