<template>
  <validation-provider 
    ref="provider"
    :rules="formRules" 
    :name="inputId" 
    :label="labelName"
    :vid="validationId ? validationId : inputId" 
    v-slot="{ errors }">
    <p class="p-label" :class="{ 'sr-only': hideLabel }" v-if="labelName">
      {{labelName}}
      <!-- don't show asterisk if disabled -->
      <validation-asterisk
        v-if="!disabled"
        :rules="formRules"
        :crossValues="crossValues"
        :ruleKey="ruleKey"
      />
    </p>
    <div class="form-check form-check-inline">
      <input
        :id="`${inputId}-${acceptId}`"
        :name="`${inputId}-${acceptId}`"
        type="radio"
        class="form-check-input"
        v-model="localValue"
        :value="BOOLEAN_RADIO_STATE.True"
        :aria-labelledby="`${inputId}-${acceptId}`"
        :disabled="isReadOnly(disabled)"
        v-on="inputEvents()" />
        <label class="form-check-label" :for="`${inputId}-${acceptId}`">{{acceptLabel || acceptId}}</label>
    </div>
    <div class="form-check form-check-inline" :class="{ 'is-invalid': errors[0] }">
      <input
        :id="`${inputId}-${declineId}`"
        :name="`${inputId}-${declineId}`"
        type="radio"
        class="form-check-input"
        v-model="localValue"
        :value="BOOLEAN_RADIO_STATE.False"
        :aria-labelledby="`${inputId}-${declineId}`"
        :disabled="isReadOnly(disabled)"
        v-on="inputEvents()" />
        <label class="form-check-label" :for="`${inputId}-${declineId}`">{{declineLabel || declineId}}</label>
    </div>
    <!-- error block, not shown if disabled -->
    <div
      v-if="errors[0] && !disabled"
      class="invalid-feedback"
      :id="`${inputId}-error`"
    >
      <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
      {{ translateError(errors, labelName) }}
    </div>
  </validation-provider>
</template>

<script lang="ts">
import '@/vee-validate-rules.ts';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { Getter } from 'vuex-facing-decorator';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';

// Declare an enum to use within the same component
enum BooleanRadioState {
  False,
  True,
}

@Component({
  components: {
    ValidationAsterisk
  },
  emits: [
    'update:modelValue',
  ],
})
export default class BooleanRadioInput extends Vue {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // V-model
  @Prop() modelValue!: string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'

  // Optional properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) labelName!: string; // Optional label to prepend the radio buttons
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers
  @Prop({ default: 'accept'}) acceptId!: string; // HTML ID for the accepted/true value
  @Prop({ default: 'decline'}) declineId!: string; // HTML ID for the declined/false value
  @Prop({ default: 'Accept'}) acceptLabel!: string; // Text label displayed for the accepted/true value
  @Prop({ default: 'Decline'}) declineLabel!: string; // Text label displayed for the declined/false value
  @Prop({ default: false }) disabled!: boolean; // Sets the input to disabled

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix

  // Initialize local twin of model as null to ensure it exists during template render
  public localValue: BooleanRadioState|null = null;
  // Expose enumeration to the template
  public BOOLEAN_RADIO_STATE = BooleanRadioState;

  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  // Set local twin of model when mounting to DOM
  public mounted(): void {
    if (this.modelValue != null) {
      this.localValue = this.modelValue ? this.BOOLEAN_RADIO_STATE.True : this.BOOLEAN_RADIO_STATE.False;
    } else {
      this.localValue = null;
    }
  }

  // Forward events to the parent component
  public inputEvents(): any {
    const _vm = this as BooleanRadioInput;
    return Object.assign({},
      // parent listeners
      this.$attrs.listeners,
      {
        // custom listeners
        change(event: any) {
          // Need to convert the enum value to a string to compare
          const isTrue = (event.target.value === _vm.BOOLEAN_RADIO_STATE.True.toString());
          // Emit updated value for v-model
          _vm.$emit('update:modelValue', isTrue);
        }
      }
    );
  }

  // Watch for changes to the prop value and update localValue to match
  @Watch('modelValue')
  private onValueChange(newValue: boolean, oldValue: boolean) {
    if (this.modelValue != null) {
      this.localValue = this.modelValue ? this.BOOLEAN_RADIO_STATE.True : this.BOOLEAN_RADIO_STATE.False;
    } else {
      this.localValue = null;
    }
  }
}
</script>
