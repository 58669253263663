import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "nav-block-wrap" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = ["to"]
const _hoisted_4 = {
  key: 0,
  class: "nav flex-column"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", {
      class: _normalizeClass(["nav nav-block flex-column", { active: _ctx.active }])
    }, [
      _createElementVNode("li", _hoisted_2, [
        (_ctx.clientId)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              class: "nav-link no-scroll open",
              to: { name: 'edit-deceased-donor', params: { id: _ctx.clientId }, hash: '#summary' }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['fas', (_ctx.show ? 'chevron-down' : 'chevron-right')],
                  class: "nav-caret",
                  "fixed-width": "",
                  onClick: _withModifiers(_ctx.toggleNav, ["prevent"])
                }, null, 8, ["icon", "onClick"]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('profile')), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: "#",
              class: "nav-link no-scroll open",
              to: { name: _ctx.getDonorsUrl, hash: '#summary'}
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', (_ctx.show ? 'chevron-down' : 'chevron-right')],
                class: "nav-caret",
                "fixed-width": "",
                onClick: _withModifiers(_ctx.toggleNav, ["prevent"])
              }, null, 8, ["icon", "onClick"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('profile')), 1)
            ], 8, _hoisted_3))
      ]),
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_ctx.showDonorDataHistory)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: "nav-item",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('donor-data-history')
                  }, _toDisplayString(_ctx.$t(`donor_data_history`)), 9, _hoisted_5)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
            }, [
              _createElementVNode("a", {
                class: "nav-link",
                href: _ctx.getUrl('referral-information')
              }, _toDisplayString(_ctx.$t(`referral_information`)), 9, _hoisted_6)
            ]),
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
            }, [
              _createElementVNode("a", {
                class: "nav-link",
                href: _ctx.getUrl('donation-information')
              }, _toDisplayString(_ctx.$t(`donor_information`)), 9, _hoisted_7)
            ]),
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
            }, [
              _createElementVNode("a", {
                class: "nav-link",
                href: _ctx.getUrl('clinical-information')
              }, _toDisplayString(_ctx.$t(`clinical_information`)), 9, _hoisted_8)
            ]),
            (_ctx.groupExists('donor_attachments') && !_ctx.newDonor)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 1,
                  class: "nav-item",
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('donor-documents')
                  }, _toDisplayString(_ctx.$t(`donor_documents`)), 9, _hoisted_9)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
            }, [
              _createElementVNode("a", {
                class: "nav-link",
                href: _ctx.getUrl('virology')
              }, _toDisplayString(_ctx.$t('serology_results')), 9, _hoisted_10)
            ]),
            (_ctx.donorOrganPackagingForms.length > 0 && (_ctx.groupExists('organ_packaging') || _ctx.groupExists('organ_packaging_odsif')) && !_ctx.newDonor)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 2,
                  class: "nav-item",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('organ-packaging-forms')
                  }, _toDisplayString(_ctx.$t('organ-packaging-forms')), 9, _hoisted_11)
                ]))
              : _createCommentVNode("", true),
            (_ctx.groupExists('donor_recovery') && !_ctx.newDonor || _ctx.groupExists('donor_recovery_date') && !_ctx.newDonor)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 3,
                  class: "nav-item",
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('recovery-details')
                  }, _toDisplayString(_ctx.$t('recovery-details')), 9, _hoisted_12)
                ]))
              : _createCommentVNode("", true),
            (_ctx.showMonitorRecoveryOutcomes && !_ctx.newDonor)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 4,
                  class: "nav-item",
                  onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('monitor-recovery-outcomes')
                  }, _toDisplayString(_ctx.$t('monitor-recovery-outcomes')), 9, _hoisted_13)
                ]))
              : _createCommentVNode("", true),
            (_ctx.showExdDisposition  && !_ctx.newDonor)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 5,
                  class: "nav-item",
                  onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                }, [
                  _createElementVNode("a", {
                    class: "nav-link",
                    href: _ctx.getUrl('exceptional-distribution-disposition')
                  }, _toDisplayString(_ctx.$t('exceptional-distribution-disposition')), 9, _hoisted_14)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}