export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "discontinue_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue Allocation Recommendation"])},
        "confirm_discontinue_all_allocations_for_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm that you want to discontinue all allocations for this donor."])},
        "discontinue_donor_allocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue Donor Allocations(s)"])},
        "DISCONTINUE_CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to discontinue all allocations(s), are you sure?"])},
        "discontinue_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue Reason"])},
        "DISCONTINUE_DONOR_CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to discontinue selected offer(s). Are you sure?"])}
      },
      "fr": {
        "discontinue_allocation_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompre la prescription d’attribution"])},
        "confirm_discontinue_all_allocations_for_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez que vous souhaitez interrompre toutes les attributions à ce donneur."])},
        "discontinue_donor_allocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompre l’attribution du donneur"])},
        "DISCONTINUE_CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point d'interrompre toutes les allocations, en êtes-vous sûr?"])},
        "discontinue_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinuer la raison"])},
        "DISCONTINUE_DONOR_CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to discontinue selected offer(s). Are you sure?"])}
      }
    }
  })
}
