import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-sm-12"
}
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "content-wrap" }
const _hoisted_4 = { class: "container-fluid" }
const _hoisted_5 = { class: "nav-wrapper" }
const _hoisted_6 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page_top = _resolveComponent("page-top")!
  const _component_side_nav_recipient_profile = _resolveComponent("side-nav-recipient-profile")!
  const _component_demographics = _resolveComponent("demographics")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dispatchEventsComplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('loading')), 1)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: { name: 'list-recipients' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('recipients')), 1)
                ]),
                _: 1
              }),
              _createTextVNode(" / " + _toDisplayString(_ctx.$t('new')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_side_nav_recipient_profile, { newRecipient: true }),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_demographics, {
                    ref: "demographics",
                    newRecipient: true,
                    canSave: _ctx.isGroupWriteable('recipient_personal')
                  }, null, 8, ["canSave"])
                ])
              ])
            ])
          ])
        ], 64))
  ]))
}