import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patient_contact_details = _resolveComponent("patient-contact-details")!
  const _component_patient_address = _resolveComponent("patient-address")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "contactinfo",
    disabled: !_ctx.canSave,
    onLoaded: _ctx.handleLoaded,
    lookupsToLoad: _ctx.lookupsToLoad,
    showLoading: _ctx.isLoading
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('contact_information')), 1)
    ]),
    body: _withCtx(() => [
      (_ctx.isPatientContactDetailsEnabled)
        ? (_openBlock(), _createBlock(_component_patient_contact_details, {
            key: 0,
            ref: "patientContactDetails",
            onErrors: _ctx.handleErrors,
            onSave: _ctx.handleSave,
            onClear: _ctx.handleClear,
            newRecipient: _ctx.newRecipient,
            canSave: _ctx.canSave
          }, null, 8, ["onErrors", "onSave", "onClear", "newRecipient", "canSave"]))
        : _createCommentVNode("", true),
      (_ctx.isPatientAddressEnabled)
        ? (_openBlock(), _createBlock(_component_patient_address, {
            key: 1,
            ref: "patientAddress",
            onErrors: _ctx.handleErrors,
            onSave: _ctx.handleSave,
            onClear: _ctx.handleClear,
            newRecipient: _ctx.newRecipient,
            canSave: _ctx.canSave
          }, null, 8, ["onErrors", "onSave", "onClear", "newRecipient", "canSave"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["disabled", "onLoaded", "lookupsToLoad", "showLoading"]))
}