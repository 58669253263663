export class UIListFormSelection {
  public id: string|null;
  public selectedAt: Date;

  public constructor(id: string|null = null) {
    this.id = id;
    this.selectedAt = new Date();
  }

  get isNew(): boolean {
    return !this.id;
  }
}
