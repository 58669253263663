export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "checklists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklists"])},
        "create_pre_allocation_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Pre-Allocation Checklist"])},
        "create_re_allocation_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Re-Allocation Checklist"])},
        "create_post_allocation_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Post-Allocation Checklist"])},
        "checklist_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist History"])},
        "identifying_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifying Information"])},
        "allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation ID"])},
        "allocation_run_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Run Date and Time (EST)"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor ID"])},
        "consented_organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consented Organ"])},
        "donor_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor First Name"])},
        "donor_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donor Last Name"])},
        "csc_1_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSC 1 Confirmation"])},
        "i_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Confirm"])},
        "csc_2_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSC 2 Confirmation"])},
        "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "pre_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-Allocation"])},
        "re_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Allocation"])},
        "post_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-Allocation"])},
        "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re"])},
        "pre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre"])},
        "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
        "right_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Afflo ID"])},
        "input_abo_height_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right ABO, Height and Weight"])},
        "right_abo_height_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right ABO, Height and Weight"])},
        "right_donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Donor Type"])},
        "right_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Region"])},
        "review_offering_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Offering Plan"])},
        "right_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Program"])},
        "right_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Recipient"])},
        "right_allocation_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Allocation (date and time)"])},
        "no_new_high_status_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No New High-Status Recipients"])},
        "tips_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPs confirmed"])},
        "ontario_hsp_kidney_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontario HSP Kidneys at Export Threshold?"])},
        "verify_special_considerations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Special Considerations"])},
        "checklist_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist Date"])},
        "checklist_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist Type"])},
        "checklist_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist Status"])},
        "csc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSC 1"])},
        "csc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSC 2"])},
        "csc_signoff_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSC 2 date stamp signoff"])},
        "csc_signoff_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSC 1 date stamp signoff"])},
        "use_form_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the form below to add a new Checklist"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "default_ontario_hsp_kidney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A - No HSP Patients Identified"])},
        "default_out_of_province_hsp_kidney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A - Out of Province Donor"])},
        "transplant_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transplant in progress"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Checklist"])}
      },
      "fr": {
        "checklists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes de contrôle"])},
        "create_pre_allocation_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer la liste de contrôle pré-attribution"])},
        "create_re_allocation_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer la liste de contrôle ré-attribution"])},
        "create_post_allocation_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer la liste de contrôle post-attribution"])},
        "checklist_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des listes"])},
        "identifying_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations d'identifications"])},
        "allocation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No de l'attribution"])},
        "allocation_run_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure d'exécuter de l'attribution (EST)"])},
        "donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Donneur"])},
        "consented_organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organe consenti"])},
        "donor_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom du donneur"])},
        "donor_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du donneur"])},
        "csc_1_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La première vérification"])},
        "i_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je confirme"])},
        "csc_2_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La seconde vérification"])},
        "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complet"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
        "pre_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré-attribution"])},
        "re_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ré-attribution"])},
        "post_allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-attribution"])},
        "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ré"])},
        "pre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pré"])},
        "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["post"])},
        "right_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon No Afflo"])},
        "input_abo_height_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon ABO, taille et poids"])},
        "right_abo_height_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon ABO, taille et poids"])},
        "right_donor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon type de donneur"])},
        "right_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonne région"])},
        "review_offering_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examiner le plan d'offre"])},
        "right_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon programme"])},
        "right_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon patient"])},
        "right_allocation_date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonne attribution (date et heure)"])},
        "no_new_high_status_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nouveau patient à statut élevé"])},
        "tips_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPs confirmés"])},
        "ontario_hsp_kidney_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les reins HSP de l'Ontario au seuil d'exportation ?"])},
        "verify_special_considerations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier les considérations spéciales"])},
        "checklist_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de liste"])},
        "checklist_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de liste"])},
        "checklist_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de liste"])},
        "csc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier Vérificateur"])},
        "csc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deuxième vérificateur"])},
        "csc_signoff_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la seconde vérification"])},
        "csc_signoff_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la première vérification"])},
        "use_form_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez le formulaire ci-dessous pour ajouter une nouvelle liste de contrôle"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "default_ontario_hsp_kidney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A - No HSP Patients Identified"])},
        "default_out_of_province_hsp_kidney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A - Out of Province Donor"])},
        "transplant_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transplantation en cours"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder la liste de contrôle"])},
        "allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attribution"])}
      },
      "en_tgln": {
        "right_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right TGLN#"])}
      },
      "fr_tgln": {
        "right_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon No TGLN"])}
      },
      "en_tq": {
        "right_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right TQ#"])}
      },
      "fr_tq": {
        "right_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon No TQ"])}
      }
    }
  })
}
