<template>
  <modal-section
    modalId="national-organ-waitlist-modal"
    ref="nationalOrganWaitlistModal"
    class="modal-sticky-header"
  >
    <template v-slot:title>
      {{ $t('national_organ_waitlist') }}
    </template>

    <template v-slot:body>
      <div @click.prevent="(event) => event.preventDefault()">
        <sub-section
          sub-section-id="now-list-section"
          :title="$t('national_organ_waitlist_records')"
          :table-config="nowListTableConfig"
          @on-column-filter="filterList($event)"
          @on-sort-change="filterList($event)"
        />
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal-footer-body">
        <button type="button" data-dismiss="modal" :aria-label="$t('close')" class="btn btn-secondary" @click="closeModal()">
          {{ $t('close') }}
        </button>
      </div>
    </template>
  </modal-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import { Component } from 'vue-facing-decorator';
import { TableConfig } from '@/types';
import SubSection from '@/components/shared/SubSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { NowListItem } from '@/store/deceasedDonors/types';
import { NumericCodeValue } from '@/store/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { i18nMessages } from "@/i18n";

interface NowListRow {
  facility_name: string;
  national_recipient_id: string;
  local_recipient_id: string;
  transplant_type: string;
  organ: string;
  list_date: string;
  organ_medical_status: string;
  status: string;  //HSP or HSH
  organ_request_status: string;
  blood_group: string;
  age: number;
  height: number;
  weight: number;
  organ_cpra: number;
  comments: string
}

@Component({
  components: {
    SubSection,
    ModalSection,
  },
  ...i18nMessages([
    require('./_locales/common.json'),
    require('./_locales/NationalOrganWaitlist.json'),
  ])
})
export default class NationalOrganWaitlistModal extends mixins(DateUtilsMixin) {
  @State(state => state.pageState.currentPage) private currentPage!: any;
  @State(state => state.pageState.currentPage.nowLists) private editState!: any;
  @State(state => state.deceasedDonors.nowLists) nowLists!: NowListItem[];

  @Getter('organOptions', { namespace: 'lookups' }) organOptions!: (type?: string) => NumericCodeValue[];

  public currentPageTable = 1;
  public perPage = 10;

  /**
   * Getter for the columns.
   *
   */
  get nowListColumns(): any[] {
    return [
        {
          label: `${this.$t('odo')}`,
          field: 'facility_name',
          sortable: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('national_patient_id')}`,
          field: 'national_recipient_id',
          sortable: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('local_id')}`,
          field: 'local_recipient_id',
          sortable: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('tx_type')}`,
          field: 'transplant_type',
          sortable: false,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('organ')}`,
          field: 'organ',
          sortable: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('list_date')}`,
          field: 'list_date',
          sortable: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('status')}`,
          field: 'status',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: ' ',
            type: 'text'
          }
        },
        {
          label: `${this.$t('medical_status')}`,
          field: 'organ_medical_status',
          sortable: false,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('state')}`,
          field: 'organ_request_status',
          sortable: false,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('abo')}`,
          field: 'blood_group',
          sortable: false,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('age')}`,
          field: 'age',
          sortFn: this.sortBy,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('height')}`,
          field: 'height',
          sortFn: this.sortBy,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('weight')}`,
          field: 'weight',
          sortFn: this.sortBy,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('cpra')}`,
          field: 'organ_cpra',
          sortFn: this.sortBy,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('comments')}`,
          field: 'comments',
          sortable: false,
          filterOptions: {
            enabled: false,
            custom: false,
            type: 'text'
          }
        },
      ];
  }

  /**
   * Get table configuration details for the subsection
   *
   * @returns {TableConfig} Now List table configuration
   */
  get nowListTableConfig(): TableConfig {
    return {
      data: this.nowListRows,
      columns: this.nowListColumns,
      empty: this.emptyMessage,
      pagination: true,
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'national_recipient_id', type: 'asc'}
      },
      paginationOptions: {
        enabled: true,
        mode: 'records',
        perPage: this.perPage,
        perPageDropdown: [10, 25, 50],
        setCurrentPage: this.currentPageTable,
        dropdownAllowAll: true,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('table.results'),
      },
    };
  }

  // Show loading state or empty message
  get emptyMessage(): string {
    if (this.nowLists?.length > 0) {
      return `${this.$t('loading')}`;
    } else {
      return `${this.$t('empty_now_lists')}`;
    }
  }

  /**
   * Get all rows for table data
   *
   * @returns {NowListRow[]} Now List data
   */
  get nowListRows(): NowListRow[] {

    // Our state isn't set yet
    if (!this.editState || !this.editState.nowLists) return [];
    // We have no data in the nowlists
    if (this.editState.nowLists.length <= 0) return [];


    const results: NowListRow[] = [];

    this.editState.nowLists.forEach((record: NowListItem) => {
      const acceptance_criteria = this.buildComments(record.acceptance_criteria);

      const row: NowListRow = {
        facility_name: record.opo.facility_code,
        national_recipient_id: record.national_recipient_id,
        local_recipient_id: record.local_recipient_id,
        transplant_type: record.transplant_type,
        organ: record.organ,
        list_date: this.parseDateUiFromDateTime(record.list_date) || '',
        status: this.calculateStatus(record),
        organ_medical_status: record.organ_medical_status,
        organ_request_status: record.organ_request_status,
        blood_group: record.blood_group,
        age: record.age.years,
        height: record.height,
        weight: record.weight,
        organ_cpra: record.organ_cpra,
        comments: acceptance_criteria.join(', ')

      };
      results.push(row);
    });
    return results;
  }

  public calculateStatus(record: NowListItem) {
    const hspEligibile = [OrganCodeValue.Kidney].includes(Number(record.organ_code)) && record.hsx_eligible;
    const hshEligibile = [OrganCodeValue.Heart].includes(Number(record.organ_code)) && record.hsx_eligible;
    let statuses: string[] = [];

    if(hspEligibile) {
      statuses.push('HSP');
    }

    if(hshEligibile) {
      statuses.push('HSH');
    }

    if(record.high_status) {
      statuses.push('HS');
    }
    return statuses.join(',');
  }

  public buildComments(acceptance_criteria: any){
    let comments: string[] = [];
    Object.keys(acceptance_criteria).map((key: string) => {
      const value = acceptance_criteria[key];
      const type = typeof value;
      if (value) comments.push(type === 'boolean' ? key.replace(/_/g, ' ') : `${key.replace(/_/g, ' ')} = ${value}`);
    });
    return comments;
  }

  /**
   * Sets the search terms
   *
  */
  public filterList(event: any) {
    let search_params = event?.searchParams;

    let filteredList = this.filterNowList(search_params);

   if(filteredList) {
    this.editState.nowLists = filteredList;
   }
  }

  public filterNowList(search_params: { [x: string]: string; }) {
    let filteredList = this.nowLists;

    Object.keys(search_params).map((key:string) => {
      if(search_params[key]!= ""){
        switch(key) {
          case 'transplant_type':
            filteredList = filteredList.filter(list => list.transplant_type.toLowerCase().includes(search_params[key].toLowerCase()));
            break;
          case 'national_recipient_id':
            filteredList = filteredList.filter(list => list.national_recipient_id.toLowerCase().includes(search_params[key].toLowerCase()));
            break;
          case 'local_recipient_id':
            filteredList = filteredList.filter(list => list.local_recipient_id.includes(search_params[key]));
            break;
          case 'facility_name':
            filteredList = filteredList.filter(list => list.opo.facility_code.toLowerCase().includes(search_params[key].toLowerCase()));
            break;
          case 'organ':
            filteredList = filteredList.filter(list => list.organ.toLowerCase().includes(search_params[key].toLowerCase()));
            break;
          case 'list_date':
            filteredList = filteredList.filter(list => list.list_date.toLowerCase().includes(search_params[key].toLowerCase()));
            break;
          case 'status':
            filteredList = filteredList.filter(list => list.hsx_eligible == (['hsp','hs'].includes(search_params[key].toLowerCase()) ? true : false));            break;
          case 'organ_medical_status':
            filteredList = filteredList.filter(list => list.organ_medical_status.includes(search_params[key]));
            break;
          case 'organ_request_status':
            filteredList = filteredList.filter(list => list.organ_request_status.toLowerCase().includes(search_params[key].toLowerCase()));
            break;
          case 'blood_group':
            filteredList = filteredList.filter(list => list.blood_group.toLowerCase() == search_params[key]);
            break;
          case 'age':
            filteredList = filteredList.filter(list => list.age.years.toString() == search_params[key]);
            break;
          case 'height':
            filteredList = filteredList.filter(list => list.height.toString() == search_params[key]);
            break;
          case 'weight':
            filteredList = filteredList.filter(list => list.weight.toString() == search_params[key]);
            break;
          case 'organ_cpra':
            filteredList = filteredList.filter(list => list.organ_cpra.toString() == search_params[key]);
            break;
          default:
            break;
        }
      }
    });
	  return filteredList;
  }

  public loadData(search='') {
    const payload = { search };
    this.currentPage.nowLists = { nowLists: null };

    this.$store.dispatch('deceasedDonors/getNowList', {
       search_params: search!='' ? { payload } : '' ,
    }).then(()=>{
      this.editState.nowLists = this.nowLists;
    });
  }

  // Initialize
  public initialize() {
   (this.$refs.nationalOrganWaitlistModal as ModalSection).toggleModal();
    // Set our initial state
    this.loadData();
  }

  private sortBy(aValue: string, bValue: string, column: any, aRow: any, bRow: any): number {
    const aNumber = aRow[column.field] || 0;
    const bNumber = bRow[column.field] || 0;

    // Sort numerically and return
    return Math.sign(aNumber - bNumber);
  }

  // Toggle modal
  private closeModal(): void {
    (this.$refs.nationalOrganWaitlistModal as ModalSection).hideModal();
  }
}
</script>
