export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "view_allocation_audit_messages_hover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View audit-related warning and error messages for this allocation recommendation"])},
        "view_allocation_audit_messages_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Allocation Audit Messages"])},
        "allocation_audit_messages_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Audit Messages"])},
        "allocation_audit_messages_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following messages were generated as part of this allocation recommendation for audit purposes."])},
        "allocation_warning_messages_heading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Warning Message (", _interpolate(_named("count"))]), _normalize(["Warning Messages (", _interpolate(_named("count")), ")"])])},
        "no_allocation_audit_warning_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "allocation_error_messages_heading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Error Message (", _interpolate(_named("count"))]), _normalize(["Error Messages (", _interpolate(_named("count")), ")"])])},
        "no_allocation_audit_error_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])}
      },
      "fr": {
        "view_allocation_audit_messages_hover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View audit-related warning and error messages for this allocation recommendation"])},
        "view_allocation_audit_messages_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Allocation Audit Messages"])},
        "allocation_audit_messages_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Audit Messages"])},
        "allocation_audit_messages_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following messages were generated as part of this allocation recommendation for audit purposes."])},
        "allocation_warning_messages_heading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Warning Message (", _interpolate(_named("count"))]), _normalize(["Warning Messages (", _interpolate(_named("count")), ")"])])},
        "no_allocation_audit_warning_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "allocation_error_messages_heading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Error Message (", _interpolate(_named("count"))]), _normalize(["Error Messages (", _interpolate(_named("count")), ")"])])},
        "no_allocation_audit_error_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])}
      }
    }
  })
}
