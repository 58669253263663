<template>
  <validation-observer ref="loginValidations" autocomplete="off" tag="form" v-if="editState">
      <form :action="getPostUrl" method="post">
        <template v-if="showUserSelect">
          <div class="form-group">
            <select-input
              rules="required"
              select-id="selected-user"
              :name="$t('select_user')"
              :multiple="true"
              :max-tags="1"
              :options="getFormattedList"
              v-model="editState.user"
              textKey="title"
              valueKey="value"
              v-on:update:modelValue="(newValue: any) => updateUserId(newValue)"
              :isLoadingSelectionValues="!getFormattedList"
            />
          </div>
          <input type="hidden" id="access_token" name="access_token" :value="userToken" v-if="fusionAuth"/>
          <input type="hidden" id="id" name="id" :value="selectedUserId"/>
          <div class="form-group left">
            <input type="submit" :value="$t('login')" class="btn btn-primary login" :disabled="!checkForUser()"/>
          </div>
          <login-one-id v-if="hasAccessTokenURL && oneid"/>
        </template>
      </form>
    </validation-observer>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { Component, Prop } from 'vue-facing-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import { APIRoute, EP } from '@/api-endpoints';
import { UserDetails } from '@/store/userAccounts/types';
import { mixins } from 'vue-facing-decorator';
import { UrlUtilsMixin } from "@/mixins/url-utils-mixin";
import LoginOneId from '@/components/shared/LoginOneId.vue';
import { i18nMessages } from '@/i18n';

interface LoginState {
  user: string|null;
}

interface User {
  title: string;
  value: string;
  code: string;
}

@Component({
  components: {
    SelectInput,
    LoginOneId
  },
  ...i18nMessages([
      require('./_locales/common.json'),
  ]),
})
export default class UserList extends mixins(UrlUtilsMixin) {
  @Prop({ default: false }) fusionAuth!: boolean;
  @Prop({ default: undefined }) userToken!: boolean;

  @State(state => state.userAccounts.userList) private userList!: any;
  @State(state => state.pageState.currentPage.LoginState) private editState!: LoginState;

  @Getter('getUserEntries', { namespace: 'userAccounts' }) private getUserEntries!: UserDetails[]|undefined|null;
  @Getter('showUserSelect', { namespace: 'configuration' }) private showUserSelect!: boolean;
  @Getter('getEHealthURL', { namespace: 'userAccounts' }) private getEHealthURL!: string;
  @Getter('oneid', { namespace: 'configuration' }) private oneid!: boolean;

  selectedUserId: string|null = '';

  /**
   * show oneid login only on /access_token/fusionauth
   *
   * @returns boolean
   */
  get hasAccessTokenURL(): boolean { return window.location.href.indexOf('access_token/fusionauth') > -1; }

  /**
   * update local value with user id
   *
   * @returns {string}
   */
  updateUserId(newValue: string) {
    const sanitizedValue = Array.isArray(newValue) ? newValue[0] : newValue;
    this.selectedUserId = sanitizedValue;
  }

  /**
   * check for user selected
   *
   * @returns {boolean}
   */
  checkForUser() {
    return this.editState.user && this.editState.user.length > 0 ? true : false;
  }

  /**
   * get post url
   *
   * @returns {string}
   */
  get getPostUrl(): string {
    return APIRoute(EP.fusionAuth.user_select);
  }

  mounted(): void {
    if (this.showUserSelect) {
      const payload = this.fusionAuth ? this.userToken : undefined;
      this.initializeForm();
      setTimeout(()=>{
        this.$store.dispatch('userAccounts/getUserLogins', payload);
      }, 2000);
    } else {
      this.initializeForm();
    }
  }

  /**
   * get user list
   *
   * @returns any[]
   */
  get getFormattedList(): User[]|null {
    if (!this.getUserEntries) return null;
    // build list of users with value to send back to api as id/openid
    const users = this.getUserEntries.map((item: any) => {
      const fullName: any = `${item.first_name} ${item.last_name}`;
      const id: any = item._id ? item._id.$oid : null;
      return { title: fullName, value: fullName, code: id };
    });
    return users;
  }

  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'LoginState',
      value: {
        user: null
      }
    });
  }
}

</script>
